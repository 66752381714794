import ContributionBillBatchIcon from '@material-ui/icons/ViewList';

import ContributionBillBatchList from './ContributionBillBatchList';
import ContributionBatchCreate from './ContributionBatchCreate';
import ContributionBillBatchEdit from './ContributionBillBatchEdit';

export default {
  list: ContributionBillBatchList,
  create: ContributionBatchCreate,
  edit: ContributionBillBatchEdit,
  icon: ContributionBillBatchIcon,
};
