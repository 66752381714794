import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const genders = [
  {id: 'DELIVERY_ONLY', name: 'kiotType.DELIVERY_ONLY'},
  {id: 'SELL_AT_STORE', name: 'kiotType.SELL_AT_STORE'},
  {id: 'SELL_AT_VINAID', name: 'kiotType.SELL_AT_VINAID'},
  {id: 'SELL_WITH_SCHEDULE', name: 'kiotType.SELL_WITH_SCHEDULE'},
]

export const KiotTypeField = (props) => (
    <SelectField source={props.source} choices={genders} {...props} />
)

export const KiotTypeInput = (props) => (
    <SelectInput source={props.source} choices={genders} {...props} label="resources.kiotConfigs.fields.type"/>
)
