import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {ReferenceInput, required, Title} from 'react-admin';
import {
    HocKiInput,
    NotifyForm,
    schoolYears,
    showErrorNotify,
    showSuccessNotify,
    TreeCheckboxInput
} from '../../components';
import axiosInstance from "../../api/axios";

class CauHinhCongBoDiem extends React.Component {
    state = {
        loading: false,
        unitIds: [],
        schoolYearId: schoolYears[0].id,
        hocKy: ''
    };

    saveItems = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        this.setState({
            loading: true
        })
        const data = {
            schoolYearId: this.state.schoolYearId,
            hocKy: this.state.hocKy,
            unitIds: this.state.unitIds
        }
        axiosInstance.post(`/api/congBoDiemBatch`, data)
            .then((rs) => {
                if (rs && rs.data && rs.data.success) {
                    showSuccessNotify('Đã lưu thành công.')
                } else {
                    showErrorNotify('Lưu không thành công.')
                }
                this.setState({
                    loading: false
                })
            }).catch(() => {
            showErrorNotify('Có lỗi khi lưu.')
            this.setState({
                loading: false
            })
        })
    }

    validate = (values) => {
        const errors = {};

        this.setState({
            unitIds: values.unitIds,
            hocKy: values.hocKy
        })

        return errors;
    }

    render() {
        return (
            <Card>
                <Title title="resources.cauHinhCongBoDiem.name"/>
                <CardContent>
                    <NotifyForm params={this.state}
                                validate={this.validate}
                                saveBtn={this.saveItems}
                                isLoading={this.state.loading}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <HocKiInput style={{width: '25%'}} label="resources.cauHinhCongBoDiem.hocKy"
                                            source="hocKy" validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label>Danh sách lớp *</label>
                                <div style={{maxHeight: '450px', overflow: 'auto'}}>
                                    <ReferenceInput source="unitIds"
                                                    reference="units"
                                                    perPage={999}
                                                    filter={{
                                                        clazzWithParents: true,
                                                        schoolYear: this.state.schoolYearId
                                                    }}
                                                    {...this.props} >
                                        <TreeCheckboxInput optionValue="id" optionText="name"
                                                           expandTopLevel={true}/>
                                    </ReferenceInput>
                                </div>
                            </Grid>
                        </Grid>
                    </NotifyForm>

                </CardContent>
            </Card>
        )
    }
}

export default CauHinhCongBoDiem

