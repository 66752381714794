import React from 'react';
import { List, Datagrid, Filter,  TextField, TextInput, SelectField } from 'react-admin';
import {FoodTypeField} from "./FoodType";

const FoodFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const FoodList = props => (
  <List filters={<FoodFilter />} {...props}  perPage={25}  exporter={false} >
    <Datagrid rowClick="edit" >
      <TextField source="id" />
      <TextField source="name" />
      <FoodTypeField source="type" />
    </Datagrid>
</List>
);

export default FoodList
