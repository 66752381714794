import CalendarIcon from '@material-ui/icons/ViewList';

import CalendarList from './CalendarList';
import CalendarCreate from './CalendarCreate';
import CalendarEdit from './CalendarEdit';
import CalendarShow from './CalendarShow';

export default {
  list: CalendarList,
  create: CalendarCreate,
  edit: CalendarEdit,
  show: CalendarShow,
  icon: CalendarIcon,
};
