import React, {Fragment} from 'react';
import {
    ChipField,
    CreateButton,
    Datagrid,
    Filter,
    List,
    ReferenceArrayField,
    SingleFieldList,
    TextField,
    TextInput
} from 'react-admin';
import DownloadUnitProductBtn from "./DownloadUnitProductBtn";

const PostActions = ({
                         basePath,
                     }) => (
    <Fragment>
        <DownloadUnitProductBtn/>
        <CreateButton basePath={basePath}/>
    </Fragment>
);

const UnitProductFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const UnitProductList = props => (
    <List filters={<UnitProductFilter/>} {...props} actions={<PostActions/>} perPage={25} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="unitName" sortBy="unit_id" style={{width: 150}}/>
            <ReferenceArrayField reference="billProducts" source="productIds">
                <SingleFieldList>
                    <ChipField source="name"/>
                </SingleFieldList>
            </ReferenceArrayField>
        </Datagrid>
    </List>
);

export default UnitProductList
