import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const genders = [
  {id: 'IN_LATE', name: 'attendanceStatus.IN_LATE'},
  {id: 'MISSING_CARD', name: 'attendanceStatus.MISSING_CARD'},
  {id: 'ABSENT', name: 'attendanceStatus.ABSENT'},
  {id: 'ABSENT_WITH_PERMISSION', name: 'attendanceStatus.ABSENT_WITH_PERMISSION'},
]

export const AttendanceStatusField = (props) => (
    <SelectField source={props.source} choices={genders} {...props} />
)

export const AttendanceStatusInput = (props) => (
    <SelectInput source={props.source} choices={genders} {...props} label="Trạng thái điểm danh"/>
)
