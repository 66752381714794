import React from 'react';
import {Datagrid, DateField, Filter, List, TextField, TextInput} from 'react-admin';
import {SmsChannelField, SmsMessageCategoryField, SmsStatusField} from "../../components/SmsMessageCategory";

const MessagingLogFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const SmsMessageLogList = props => (
    <List filters={<MessagingLogFilter/>} {...props} exporter={true} bulkActions={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <SmsChannelField source="channel"/>
            <SmsMessageCategoryField source="category"/>
            <TextField source="phone"/>
            <TextField source="customerName"/>
            <TextField source="excerpt"/>
            <DateField source="createdOn" showTime/>
            <SmsStatusField source="status"/>
            <TextField source="errMessage"/>
        </Datagrid>
    </List>
);

export default SmsMessageLogList
