import StudentEnrollmentBatchIcon from '@material-ui/icons/ViewList';

import StudentEnrollmentBatchList from './StudentEnrollmentBatchList';
import StudentEnrollmentBatchCreate from './StudentEnrollmentBatchCreate';
import StudentEnrollmentBatchEdit from './StudentEnrollmentBatchEdit';

export default {
  list: StudentEnrollmentBatchList,
  create: StudentEnrollmentBatchCreate,
  edit: StudentEnrollmentBatchEdit,
  icon: StudentEnrollmentBatchIcon,
};
