import React, {useState} from 'react';
import {schoolYears} from "../../components";
import {
    AutocompleteInput,
    Datagrid,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
} from "react-admin";


const DataFilter = (props) => {
    const [schoolYear, setSchoolYear] = useState(schoolYears?.[0].id)

    const onSchoolYearChanged = (e, value) => {
        setSchoolYear(value)
    }

    return <Filter {...props}>
        <SelectInput label="resources.schoolYears.name" source="schoolYear" choices={schoolYears}
                     onChange={onSchoolYearChanged}
                     alwaysOn allowEmpty={false}
        />
        <ReferenceInput source="unitId" reference="clazzes" alwaysOn allowEmpty
                        {...props}
                        filter={{schoolYearId: schoolYear}}
                        sort={{field: 'name', order: 'ASC'}}
                        perPage={999}
        >
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
}

const RELATIONSHIP_TYPE = {
    FATHER: 'Bố',
    MOTHER: 'Mẹ',
    PARENT: 'Phụ huynh',
    GUARDIAN: 'Người giám hộ',
    MANAGER: 'Quản lý',
    CHILD: 'Con'
}

const RelationshipField = ({record}) => {
    return <div>
        {record.relationships.map((item, idx) => <div key={idx}>
             {item.customerId} - {item.customerName} <b>({RELATIONSHIP_TYPE[item.relationshipType]}</b>)
            {!!item.phone && `: ${item.phone}`}
        </div>)}
    </div>
}

const StudentRelationshipList = (props) => {
    return <List{...props}
                filters={<DataFilter {...props}/>}
                actions={null}
                perPage={25}
                filterDefaultValues={{schoolYear: (schoolYears && schoolYears.length > 0) ? schoolYears[0].id : null}} {...props}
                bulkActionButtons={false}
    >
        <Datagrid rowClick={''}>
            <TextField source="customerId"/>
            <TextField source="customerName"/>
            <ReferenceField source="unitId" reference="baseUnits" allowEmpty>
                <TextField source="name"/>
            </ReferenceField>

            <RelationshipField label='Phụ huynh'/>

        </Datagrid>
    </List>
};

export default StudentRelationshipList;
