import React from 'react';
import {Edit, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import CustomerHealthFields from './CustomerHealthFields'

export const CustomerHealthEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <Grid container spacing={8}>
                <CustomerHealthFields/>
            </Grid>
        </SimpleForm>
    </Edit>
);

export default CustomerHealthEdit
