import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, refreshView,} from 'react-admin';
import {showErrorNotify, showSuccessNotify} from './ShowNotify'
import Swal from 'sweetalert2'
import './popup.css';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import axiosInstance from "../api/axios";

const styles = {
  redColor: {
    color: 'red',
  }
};

class DeleteBillButton extends Component {
  state = {
    showBtn: true,
    billId: 0,
  };

  constructor(props) {
    super(props);
    const record = props.record;
    if (record) {
      this.state.billId =  record.id;
    }
  }

  handleClick = () => {
    Swal.fire({
      title: "Bạn chắc chắn muốn xoá hoá đơn?",
      text: "Không thể hoàn tác sau khi xoá.",
      type: "warning",
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      focusConfirm: false,
    })
      .then((result) => {
        if (result.value) {

          this.doDeleteBill()
            .then(({data}) => {
              if (data == null || !data.success) {
                showErrorNotify('Xoá hoá đơn không thành công, vui lòng kiểm tra lại hoặc liên hệ quản trị viên!')
              } else {
                // return is list payment ids
                showSuccessNotify('xoá thành công!')
                this.props.refreshView();
              }

            })
            .catch(error => {
              showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!")
            })
            .finally(() => {

            });
        }
      });
  };

  doDeleteBill() {
    const url = `/api/billViews/` + this.state.billId;
    return axiosInstance.delete(url);
  }

  render() {
    const {classes} = this.props;
    return (
      <div>
        {this.state.showBtn && <Button onClick={this.handleClick} label="ra.action.delete" className={classes.redColor}>
          <DeleteIcon/>
        </Button>}
      </div>
    );
  }
}

export default compose(
  connect(undefined, { refreshView }),
  withStyles(styles)
)(DeleteBillButton)
