import React from 'react';
import Grid from '@material-ui/core/Grid'
import {BooleanInput, maxLength, required, SelectInput, TextInput} from 'react-admin';
import {schoolYears} from "../../components";

const AdmissionCategoryFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={[required(), maxLength(100, 'Không quá 100 ký tự')]} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="active" defaultValue={true} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <SelectInput source="schoolYearId" choices={schoolYears} defaultValue={schoolYears[0].id} label='Niên khoá'
                         validate={required()}/>
        </Grid>
    </React.Fragment>
)

export default AdmissionCategoryFields
