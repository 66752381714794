import React from 'react';
import { List, Datagrid, Filter, TextField, TextInput, SelectField, ReferenceField } from 'react-admin';
import {
  EditButtonWithAuthStatus,
  DownloadFileContentButton, DateTimeField,
} from "../../components";

const BankStatementFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const BankStatementList = props => (
  <List filters={<BankStatementFilter />} {...props} exporter={false}>
    <Datagrid >
      <TextField source="id" />
      <TextField source="description" />
      <ReferenceField source="bankStatementTemplateId" reference="bankStatementTemplates" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <SelectField source="authStatus" choices={[
        { id: 'DRAFT', name: 'Bản nháp' },
        { id: 'AUTHORIZED', name: 'Đã duyệt' },
      ]} />
      <DateTimeField source="authorizedDate" format="DD-MM-YYYY HH:mm" />
      <DownloadFileContentButton  />
      <EditButtonWithAuthStatus />
    </Datagrid>
</List>
);

export default BankStatementList
