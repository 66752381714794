import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';

import Button from '@material-ui/core/Button';
import { SimpleForm, Toolbar, showNotification, translate } from 'react-admin';

const buttonStyle = {
  margin: '0 8px',
  width: 150
}

const onCancel = e =>{
  window.history.back();
}

const styles = {
  form: {
    // "& > div > div > div ": {
    //   width: '100%',
    // }
  },
};

const noop = () => {}

class MigrateButton extends React.Component {
  handleClick = e => {
    const {
      handleSubmitWithRedirect,
      invalid,
      redirect,
      showNotification,
      onClick,
    } = this.props;
    if (invalid) {
      showNotification('ra.message.invalid_form', 'warning');
    }

    if (e) {
      e.preventDefault();
    }
    const errors = handleSubmitWithRedirect(redirect)();
    if (!errors){
      if (typeof onClick === 'function') {
        onClick();
      }
    }
  }
  
  render() {
    const { label, translate, isDisabledSave } = this.props;
    return (
      <Button disabled={isDisabledSave} color="secondary" variant="raised" style={buttonStyle} onClick={this.handleClick} >
        { label && translate(label, { _: label }) }
      </Button>
    )
  }
}

const MigrateToolbar = ({ moveEnroll, copyEnroll, save, saveDisabled, ...props }) => (
  <Toolbar {...props} >

    { moveEnroll &&
      <MigrateButton
        label='app.label.moveEnroll'
        onClick={moveEnroll}
        {...props}
      />
    }

    { copyEnroll &&
      <MigrateButton
        label='app.label.copyEnroll'
        onClick={copyEnroll}
        {...props}
      />
    }

    { save &&
    <MigrateButton
        label='app.label.save'
        onClick={save}
        isDisabledSave={saveDisabled}
        {...props}
    />
    }

    <Button variant="raised" style={buttonStyle} onClick={onCancel}>
      Hủy
    </Button>
  </Toolbar>
)

const MigrateForm = ({params, validate, children, classes, ...props}) => (
  <SimpleForm 
    toolbar={<MigrateToolbar {...props} />}
    record={params} 
    validate={validate}
    save={noop} 
    className={classes.form} 
    {...props}
  >
    { children }
  </SimpleForm>
)

export default compose(
  translate,
  connect(undefined, { showNotification }),
  withStyles(styles),
)(MigrateForm);
