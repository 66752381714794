import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import ArchiveMessageLogFields from './ArchiveMessageLogFields'

export const ArchiveMessageLogEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar viewOnly={true} />}>
      <Grid container spacing={8}>
        <ArchiveMessageLogFields />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default ArchiveMessageLogEdit
