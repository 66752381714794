import React from 'react';
import {Show, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import AdmissionChangeLogFields from './AdmissionChangeLogFields'

export const AdmissionChangeLogShow = props => (
    <Show {...props}>
        <SimpleForm toolbar={<CustomToolbar viewOnly {...props}/>}>
            <Grid container spacing={8}>
                <AdmissionChangeLogFields/>
            </Grid>
        </SimpleForm>
    </Show>
);

export default AdmissionChangeLogShow
