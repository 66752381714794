import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const cardApplicationTypes = [
    {id: 'HS_C1', name: 'cardApplicationType.HS_C1'},
    {id: 'STUDENT', name: 'cardApplicationType.STUDENT'}
]

export const BatchTypeField = (props) => (
    <SelectField source={props.source} choices={cardApplicationTypes} {...props} />
)

export const BatchTypeInput = (props) => (
    (
        <>
            <SelectInput source={props.source} choices={cardApplicationTypes} {...props} />
            <div>
                <p>Vui lòng chọn theo mẫu mở thẻ đã sử dụng</p>
                <p>Lưu ý:</p>
                <p>Lô học sinh: xử lý thông tin theo cột trạng thái học sinh</p>
                <p>Lô sinh viên: trạng thái học sinh sẽ là đang học</p>
            </div>
        </>
    )

)
