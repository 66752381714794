import React from 'react';
import env from "../env";
import GetAppIcon from '@material-ui/icons/GetApp';
import {compose} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {Button, showNotification} from 'react-admin';

const styles = {
    button: {
        margin: 1,
        width: 180
    },
};
const iframeStyle = {
    width: '1px', height: '1px',
    visibility: 'hidden',
};

const DownloadTemplateFileButtonV2 = (props) => {

    const {basePath, btnLabel} = props

    const handleClick = (e) => {
        e.stopPropagation();
        document.querySelector('#downFile').src = '';
        document.querySelector('#downFile').src = env.baseURL + basePath;
    };

    return (
        <React.Fragment>
            <iframe id="downFile" style={iframeStyle}></iframe>
            <Button color="primary" style={styles.button}
                    onClick={handleClick} label={btnLabel}>
                <GetAppIcon/>
            </Button>
        </React.Fragment>
    )
};

export default compose(
    connect(null, {showNotification}),
    withStyles(styles)
)(DownloadTemplateFileButtonV2)
