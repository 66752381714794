import SchoolIcon from '@material-ui/icons/ViewList';

import SchoolList from './SchoolList';
import SchoolCreate from './SchoolCreate';
import SchoolEdit from './SchoolEdit';

export default {
  list: SchoolList,
  create: SchoolCreate,
  edit: SchoolEdit,
  icon: SchoolIcon,
};
