import React from 'react';
import {List, Datagrid, Filter, TextField, TextInput, CardActions, CreateButton, ReferenceField} from 'react-admin';
import {
    EditButtonWithAuthStatus,
    DownloadFileContentButton,
    DateTimeField, BillAuthStatusField, DownloadTemplateFileButton
} from "../../components";

const BillAccountBatchFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

const PostActions = ({
                         bulkActions,
                         basePath,
                         displayedFilters,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter,
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <DownloadTemplateFileButton basePath="/api/billAccountBatches/getTemplate" btnLabel="ra.action.downloadTemplate" />
        <CreateButton basePath={basePath}/>
    </CardActions>
);

export const BillAccountBatchList = props => (
    <List filters={<BillAccountBatchFilter/>} {...props} actions={<PostActions/>} bulkActionButtons={false} exporter={false}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="description"/>
            <BillAuthStatusField source="authStatus"/>
            <DateTimeField source="authorizedDate" format="DD-MM-YYYY HH:mm"/>
            <ReferenceField source="authorizedId" reference="users" allowEmpty>
                <TextField source="fullName"/>
            </ReferenceField>
            <DownloadFileContentButton/>
            <EditButtonWithAuthStatus/>
        </Datagrid>
    </List>
);

export default BillAccountBatchList
