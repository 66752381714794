import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {AutocompleteArrayInput, BooleanInput, LongTextInput, ReferenceArrayInput, Title, translate} from 'react-admin';
import {NotifyForm, showErrorNotify, showInfoNotify, showSuccessNotify} from '../../components';
import axiosInstance from "../../api/axios";
import utils from "../../utils/utils";
import TreeCheckBoxCustomInput from "../../components/TreeCheckBoxCustomInput";
import cachedApis from "../../api/cachedApis";

class TBHocPhiTheoKeHoach extends React.Component {
    state = {
        displayDueDate: true,
        isLoading: false,
        customers: [],
        customerIds: [],
        params: {
            batchIds: [],
            note: '',
        }
    };

    componentDidMount() {
        cachedApis.getCustomerUnits(false).then(rs => {
            this.setState({customers: rs})
        })
    }

    notifyAllStudentsUrl = () => {
        if (!this.state.params.note) {
            this.state.params.note = '';
        }
        this.setState({isLoading: true})
        showInfoNotify("Đang gửi thông báo. Vui lòng đợi trong giây lát!");
        const data = {
            note: this.state.params.note,
            displayDueDate: this.state.params.displayDueDate,
            batchIds: this.state.params.batchIds,
            customerIds: this.state.customerIds
        }

        return axiosInstance.post('/api/billBatches/send_notify_by_batch', data);
    };


    notifyAllStudents = () => {
        if (!this.state.customerIds || this.state.customerIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách ngườhận thông báo")
            return;
        }
        if (!this.state.params.batchIds || this.state.params.batchIds.length === 0) {
            showErrorNotify("Vui lòng chọn kế hoạch")
            return;
        }

        this.notifyAllStudentsUrl().then(rs => {
            this.setState({isLoading: false})
            if (rs.data) {

                showSuccessNotify("Gửi thông báo thành công!");
                this.state.showBtn = false;
                this.forceUpdate()
            } else {
                showErrorNotify("Gửi thông báo không thành công. Vui lòng kiểm tra hoặc liên hệ quản trị viên!");
            }
        }, err => {
            this.setState({isLoading: false})
            showErrorNotify(err.message);
        });
    };

    validate = (values) => {
        const errors = {};

        // Copy input values to params
        this.setState({params: values})

        return errors;
    }

    render() {
        return (
            <Card>
                <Title title="resources.tbHocPhiTheoKeHoach.name"/>
                <CardContent>
                    <NotifyForm validate={this.validate}
                                allClazzes={this.notifyAllStudents}
                                label={this.props.translate('resources.thongBaoHocPhi.sendNotify')}
                                isLoading={this.state.isLoading}
                                params={this.state}
                    >
                        <Grid container spacing={8}>
                            <ReferenceArrayInput
                                source="batchIds"
                                label="resources.billBatches.name"
                                reference="billBatches"  {...this.props}
                            >
                                <AutocompleteArrayInput optionText={'displayDescription'}/>
                            </ReferenceArrayInput>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <BooleanInput label="resources.tbHocPhiTheoKeHoach.displayDueDate"
                                              source="displayDueDate" {...this.props}  />
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <LongTextInput label="resources.tbHocPhiTheoKeHoach.note"
                                               rows={5}
                                               style={{maxWidth: '90%'}}
                                               multiline
                                               source="note" {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label><b>Danh sách lớp *</b></label>
                                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                                    <TreeCheckBoxCustomInput data={this.state.customers}
                                                             expandTopLevel={true}
                                                             onChange={(e) => this.setState({customerIds: utils.extractCustomerIdsV2(e)})}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </NotifyForm>
                </CardContent>
            </Card>
        )
    }
}

export default translate(TBHocPhiTheoKeHoach)

