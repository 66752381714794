import React from 'react';
import {Edit, ReferenceInput, SelectInput, SimpleForm} from "react-admin";
import {CustomToolbar, Grid, TreeCheckboxInput} from "../../components";

const UploadPhotoPermissionEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm toolbar={<CustomToolbar />}>
                <Grid container spacing={8}>
                    <React.Fragment>
                        <Grid item xs={12} sm={6} >
                            <ReferenceInput label='Tài khoản' source="userId" reference="helpDeskUsers" disabled>
                                <SelectInput optionText={({userName, fullName}) => `${userName} (${fullName || ''})`}/>
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <label>Danh sách lớp *</label>
                            <ReferenceInput source="unitIds"
                                            reference="units"
                                            perPage={99999}
                                            filter={{all: true}}
                                            {...props}>
                                <TreeCheckboxInput optionValue="id" optionText="name"/>
                            </ReferenceInput>
                        </Grid>
                    </React.Fragment>
                </Grid>
            </SimpleForm>
        </Edit>
    );
};

export default UploadPhotoPermissionEdit;
