import React from 'react';
import {BooleanField, Datagrid, Filter, List, TextField, TextInput} from 'react-admin';

const DisciplinePlanFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const DisciplinePlanList = props => (
    <List filters={<DisciplinePlanFilter/>} {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="startDate"/>
            <TextField source="endDate"/>
            <TextField source="basePoint"/>
            <BooleanField source="active"/>
        </Datagrid>
    </List>
);

export default DisciplinePlanList
