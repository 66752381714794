import EventUnitIcon from '@material-ui/icons/ViewList';

// import EventUnitList from './EventUnitList';
import EventUnitCreate from './EventUnitCreate';
import EventUnitEdit from './EventUnitEdit';

export default {
  // list: EventUnitList,
  create: EventUnitCreate,
  edit: EventUnitEdit,
  icon: EventUnitIcon,
};
