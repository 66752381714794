import React from 'react';
import Grid from '@material-ui/core/Grid'
import {BooleanInput, LongTextInput, maxLength} from 'react-admin';

const AdmissionReportTemplateFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={12}>
            <LongTextInput source="title" multiline rows={3} style={{width: '100%'}}
                           validate={[maxLength(100, 'Không quá 100 ký tự')]} {...props} />
        </Grid>
        <Grid item xs={12} sm={12}>
            <LongTextInput source="paragraph1" multiline rows={3} style={{width: '100%'}}
                           validate={[maxLength(255, 'Không quá 255 ký tự')]} {...props} />
        </Grid>
        <Grid item xs={12} sm={12}>
            <LongTextInput source="paragraph2" multiline rows={3} style={{width: '100%'}}
                           validate={[maxLength(255, 'Không quá 255 ký tự')]} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="active" defaultValue={true} {...props} />
        </Grid>
    </React.Fragment>
)

export default AdmissionReportTemplateFields
