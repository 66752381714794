import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const cardApplicationTypes = [
    {id: 'EMPLOYEE', name: 'cardApplicationType.EMPLOYEE'},
    {id: 'STUDENT', name: 'cardApplicationType.STUDENT'},
    {id: 'HS_C1', name: 'cardApplicationType.HS_C1'},
    {id: 'TUYEN_SINH', name: 'cardApplicationType.TUYEN_SINH'},
]

export const CardApplicationTypeField = (props) => (
    <SelectField source={props.source} choices={cardApplicationTypes} {...props} />
)

export const CardApplicationTypeInput = (props) => (
    (
        <>
            <SelectInput source={props.source} choices={cardApplicationTypes} {...props} />
            <div>
                <p>Lô nhân viên: Số điện thoại sẽ gán cho chủ thẻ</p>
                <p>Lô sinh viên: Số điện thoại sẽ gán cho chủ thẻ</p>
                <p>Lô học sinh: Số điện thoại liên hệ sẽ gán cho PH hoặc giám hộ nếu PH hoặc giám hộ chưa khai báo SDT
                    trong file</p>
                <p>Lô tuyển sinh: Số điện thoại liên hệ sẽ gán cho giám hộ, tên giám hộ sẽ được đặt là "PH " + Tên học
                    sinh</p>
            </div>
        </>
    )

)
