import React from 'react';
import {Edit, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import AdmissionCategoryFields from './AdmissionCategoryFields'

export const AdmissionCategoryEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <Grid container spacing={8}>
                <AdmissionCategoryFields/>
            </Grid>
        </SimpleForm>
    </Edit>
);

export default AdmissionCategoryEdit
