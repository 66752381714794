import React, {useEffect, useState} from "react";
import {
    Datagrid,
    fetchEnd,
    fetchStart,
    NumberField,
    ReferenceManyField,
    refreshView,
    required,
    SaveButton,
    TextField,
    TextInput
} from "react-admin";
import DetailRecordEditingButton from "../../components/DetailRecordEditingButton";
import Grid from "../../components/CustomGrid";
import axiosInstance from "../../api/axios";
import {showErrorNotify, showSuccessNotify} from "../../components";
import {InputLabel, MenuItem, Select, TextField as TextFieldMU} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CancelIcon from '@material-ui/icons/Cancel';
import {compose} from "redux";
import {connect} from "react-redux";
import {change, submit} from "redux-form";

const BillItemFields = props => (
    <Grid container spacing={8}>
        <p style={{fontWeight: 'bold'}}>
            Vui lòng cập nhật đầy đủ thông tin số tiền, số lượng, miễn giảm, thành tiền theo đúng thông tin mong muốn
        </p>
        <Grid item xs={12} sm={6}>
            <TextInput source="productName" InputProps={{readOnly: true}}
                       label={'resources.billItems.fields.productName'}/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="quantity" validate={required()} label={'resources.billItems.fields.quantity'}/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="price" validate={required()} label={'resources.billItems.fields.price'}/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="discountAmount" validate={required()}
                       label={'resources.billItems.fields.discountAmount'}/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="subtotal" validate={required()} label={'resources.billItems.fields.subtotal'}/>
        </Grid>
    </Grid>
)

const BillViewField = props => {
    const [billItemId, setBillItemId] = useState(null)
    const [showDialog, setShowDialog] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [selectedItem, setSelectedItem] = useState({})
    const [selectedProduct, setSelectedProduct] = useState({})

    const [products, setProducts] = useState([])

    useEffect(() => {
        setBillItemId(props.record.id)
        loadProducts()
    }, [])

    const loadProducts = async () => {
        const res = await axiosInstance.get('/api/billViewEditByMonth/loadProducts')
        if (!res.data) {
            return
        }
        setProducts(res.data)
    }

    const showAddProduct = () => {
        setSelectedItem({})
        setShowDialog(true)
    }

    const isNullOrUndefined = (value) => {
        return value === undefined || value === null;
    }

    const handleSaveClick = async () => {
        if (isNullOrUndefined(selectedItem.productName) || isNullOrUndefined(selectedItem.price) || isNullOrUndefined(selectedItem.discountAmount) || isNullOrUndefined(selectedItem.quantity) || isNullOrUndefined(selectedItem.subtotal)) {
            showErrorNotify('Vui lòng nhập đầy đủ giá trị')
            return
        }
        setIsSubmitting(true)
        const url = `/api/billItems/` + billItemId;
        const data = {
            productId: selectedItem.productId,
            productName: selectedItem.productName,
            price: selectedItem.price,
            quantity: selectedItem.quantity,
            discountAmount: selectedItem.discountAmount,
            subtotal: selectedItem.subtotal
        }
        const res = await axiosInstance.post(url, data)
        if (res && res.data && res.data.success) {
            showSuccessNotify('Đã thêm thành công')
            props.refreshView();
        } else {
            showErrorNotify('Có lỗi, vui lòng liên hệ quản trị viên')
        }
        setIsSubmitting(false)
        handleCloseClick()
    }

    const handleCloseClick = () => {
        setSelectedItem({})
        setSelectedProduct({})
        setShowDialog(false)
        setIsSubmitting(false)
    }

    const changeValue = (value, field) => {
        setSelectedItem({...selectedItem, [field]: value});
    }

    const selectProductName = (e) => {
        const value = e.target.value
        setSelectedItem({
            productId: value.id,
            productName: value.name,
            price: value.price,
            quantity: 1,
            discountAmount: 0,
            subtotal: value.price
        })
        setSelectedProduct(value)
    }

    return (
        <>
            <Button
                style={{marginBottom: '10px'}} variant="contained" color={'primary'} component="span"
                onClick={showAddProduct}>
                Thêm khoản thu
            </Button>
            <Dialog
                style={{zIndex: 10}}
                fullWidth={true}
                disableBackdropClick={true}
                maxWidth='sm'
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>{'Thêm khoản thu'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={8}>
                        <p style={{fontWeight: 'bold'}}>
                            Vui lòng cập nhật đầy đủ thông tin số tiền, số lượng, miễn
                            giảm, thành tiền theo đúng thông tin mong muốn
                        </p>
                        <Grid item xs={12} sm={6}>
                            <div
                                style={{width: '100%', padding: '0 10px 10px 0px'}}>
                                <InputLabel>Tên khoản thu</InputLabel>
                                <Select InputLabelProps={{
                                    shrink: true,
                                }}
                                        shrink
                                        style={{width: '100%'}}
                                        value={selectedProduct}
                                        onChange={selectProductName}
                                >
                                    {products && products.map(p => <MenuItem
                                        key={p.name} value={p}>
                                        {p.name}
                                    </MenuItem>)}
                                </Select>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldMU type="number"
                                         InputLabelProps={{
                                             shrink: true,
                                         }} label={'Số lượng'}
                                         value={selectedItem.quantity}
                                         onChange={(event) => changeValue(event.target.value, 'quantity')}
                                         required/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldMU type="number"
                                         InputLabelProps={{
                                             shrink: true,
                                         }} label={'Số tiền'} value={selectedItem.price}
                                         onChange={(event) => changeValue(event.target.value, 'price')}
                                         required/>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextFieldMU type="number"
                                         InputLabelProps={{
                                             shrink: true,
                                         }} label={'Miễn giảm'}
                                         value={selectedItem.discountAmount}
                                         onChange={(event) => changeValue(event.target.value, 'discountAmount')}
                                         required/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldMU type="number"
                                         InputLabelProps={{
                                             shrink: true,
                                         }} label={'Thành tiền'}
                                         value={selectedItem.subtotal}
                                         onChange={(event) => changeValue(event.target.value, 'subtotal')}
                                         required/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SaveButton saving={isSubmitting} onClick={handleSaveClick}/>
                    <Button label="ra.action.cancel" onClick={handleCloseClick}>
                        <CancelIcon/>
                    </Button>
                </DialogActions>
            </Dialog>
            <ReferenceManyField
                label="Khoản thu"
                reference="billItems"
                target="billId"
                basePath={props.basePath}
                record={props.record}
            >
                <Datagrid>
                    <TextField source="id"/>
                    <TextField source="productName"/>
                    <NumberField source="quantity" locales="vi-VI"/>
                    <NumberField source="price" locales="vi-VI" options={{style: 'currency', currency: 'VND'}}/>
                    <NumberField source="discountAmount" locales="vi-VI"
                                 options={{style: 'currency', currency: 'VND'}}/>
                    <NumberField source="subtotal" locales="vi-VI" options={{style: 'currency', currency: 'VND'}}/>

                    <DetailRecordEditingButton
                        detailResource="billItems"
                        title="Cập nhật"
                        showBtn={true}
                    >
                        <BillItemFields/>
                    </DetailRecordEditingButton>
                </Datagrid>
            </ReferenceManyField>
        </>
    )
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    submit,
    refreshView
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    BillViewField
);
