import React from 'react';
import {SimpleForm, showNotification} from 'react-admin';
import {connect} from 'react-redux';
import {compose} from 'redux';

import DayOffFields from './DayOffFields'
import {Grid} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import axiosInstance from "../../api/axios";
import utils from "../../utils/utils";

let timeout = null;
export const DayOffEdit = props => {
  const [values, setValues] = React.useState({
    id: '',
    customerIds: null,
    name: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    saveDisabled: false,
    isFistTimeCheck: true,
    isRedirect: false,
    cuzIds: []
  });

  React.useEffect(() => {
    if (props.id && values.isFistTimeCheck) {
      getDayOffDetails();
    }
  }, []);

  function getDayOffDetails() {
    const url = `/api/dayOffs/` + props.id;
    axiosInstance.get(url).then(rs => {
      const {data} = rs;
      setValues({
        ...values,
        isFistTimeCheck: false,
        name: data.name,
        startDate: data.startDate,
        startTime: data.startTime,
        endDate: data.endDate,
        endTime: data.endTime,
        customerIds: data.customerIds,
        cuzIds: data.customerIds,
        id: data.id
      });
    });
  }

  function save() {
    if (!validate(values, true)) {
      return;
    }
    const url = `/api/dayOffs/` + props.id;
    const data = {
      id: values.id,
      customerIds: utils.extractCustomerIds(values.cuzIds),
      name: values.name,
      startDate: values.startDate,
      endDate: values.endDate,
      startTime: values.startTime,
      endTime: values.endTime
    }
    axiosInstance.put(url, data).then(rs => {
      props.showNotification('Sửa ngày nghỉ thành công');
      setValues({isRedirect: true});
    }, err => {
      props.showNotification(err.message, 'warning');
    });
  }

  const handleChange = prop => e => {
    let val = null;
    if (prop === 'startDate' || prop === 'endDate') {
      val = Object.values(e).join('').substr(0, 10);
    } else {
      if (prop === 'cuzIds' || prop === 'savedDisabled') {
        val = e;
      } else {
        val = e.target.value;
      }
    }
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setValues({...values, [prop]: val});
    }, 500);
  };

  function validate() {
    if (values.startDate > values.endDate) {
      props.showNotification('Ngày bắt đầu phải nhỏ hơn ngày kết thúc', 'warning');
      return false;
    }
    if (!values.name) {
      props.showNotification('Bắt buộc nhập tên ngày nghỉ', 'warning');
      return false;
    }
    if (!values.startDate) {
      props.showNotification('Bắt buộc nhập ngày bắt đầu', 'warning');
      return false;
    }
    if (!values.endDate) {
      props.showNotification('Bắt buộc nhập ngày kết thúc', 'warning');
      return false;
    }
    return true;
  }

  return (
      <SimpleForm toolbar={null}>
        <Grid container spacing={8}>
          <DayOffFields defaultValue={values}
                        onChange={handleChange}
                        save={save}/>
        </Grid>
      </SimpleForm>
  )
};

export default compose(
    connect(null, { showNotification }),
    withStyles(null)
)(DayOffEdit)
