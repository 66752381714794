import React from 'react';
import { List, Datagrid, Filter, EditButton, ReferenceArrayField, SingleFieldList, ChipField, TextField, TextInput } from 'react-admin';

const CalendarFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const CalendarList = props => (
  <List filters={<CalendarFilter />} {...props} exporter={false}>
    <Datagrid rowClick="show" >
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceArrayField source="defaultDeviceIdList" reference="accessDevices" >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <EditButton />
    </Datagrid>
</List>
);

export default CalendarList
