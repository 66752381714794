import React, {useEffect, useState} from 'react';
import {SaveButton, Title} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import InfiniteScroll from "react-infinite-scroll-component";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';
import axiosInstance from "../../api/axios";
import EditIcon from '@material-ui/icons/Edit';
import Grid from "../../components/CustomGrid";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import {showErrorNotify, showSuccessNotify} from "../../components";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 180,
    },
    select: {
        maxHeight: 320
    },
    dialog: {
        zIndex: '1 !important'
    }
});

const Filter = withStyles(styles)(({
                                       searchParams,
                                       setSearchParams,
                                       setItems,
                                       billMonths,
                                       classes
                                   }) => {
    const [loading, setLoading] = useState(false)

    const onSelectChange = (e) => {
        setItems([])
        setSearchParams(params => ({...params, [e.target.name]: e.target.value}))
    }

    return <div style={{padding: '0 16px 8px 24px'}}>
        <FormControl className={classes.formControl}>
            <InputLabel shrink>Tháng dự thu</InputLabel>
            <Select
                value={searchParams.billMonth}
                onChange={onSelectChange}
                name='billMonth'
                MenuProps={{classes: {paper: classes.select}}}
            >
                {billMonths && billMonths.map(month => <MenuItem key={month} value={month}>
                    {month}
                </MenuItem>)}
            </Select>
        </FormControl>

    </div>
})
const BillViewEditByMonth = props => {
    const [items, setItems] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isCreate, setIsCreate] = useState(false)
    const [selectedItem, setSelectedItem] = useState({})
    const [selectedProduct, setSelectedProduct] = useState({})
    const [title, setTitle] = useState('Cập nhật')
    const [searchParams, setSearchParams] = useState({
        billMonth: '',
    })

    const [products, setProducts] = useState([])
    const [billMonths, setBillMonths] = useState([])

    useEffect(() => {
        loadProducts()
        fetchCardApplicationBatches()
    }, [])

    useEffect(() => {
        fetchMoreData(true)
    }, [searchParams])

    const loadProducts = async () => {
        const res = await axiosInstance.get('/api/billViewEditByMonth/loadProducts')
        if (!res.data) {
            return
        }
        setProducts(res.data)
    }
    const fetchCardApplicationBatches = async () => {
        const res = await axiosInstance.get('/api/billViewEditByMonth/loadBillMonths')
        if (!res.data) {
            return
        }
        setBillMonths(res.data)
    }

    const fetchMoreData = async (append) => {
        const params = new URLSearchParams(searchParams)
        const url = `/api/billViewEditByMonth/loadBillItems?${params}`;
        const res = await axiosInstance.get(url)
        if (!res.data) {
            setHasMore(false)
            return
        }
        if (append) {
            setItems(items.concat(res.data))
        } else {
            setItems(res.data)
        }

    }
    const showEditProduct = (item) => {
        setSelectedItem(item)
        setShowDialog(true)
        setIsCreate(false)
    }
    const showAddProduct = () => {
        setSelectedItem({})
        setShowDialog(true)
        setIsCreate(true)
    }
    const isNullOrUndefined = (value) => {
        return value === undefined || value === null;
    }
    const handleSaveClick = async () => {
        if (isNullOrUndefined(selectedItem.productName) || isNullOrUndefined(selectedItem.price) || isNullOrUndefined(selectedItem.discountAmount) || isNullOrUndefined(selectedItem.quantity) || isNullOrUndefined(selectedItem.subtotal)) {
            showErrorNotify('Vui lòng nhập đầy đủ giá trị')
            return
        }
        const url = `/api/billViewEditByMonth/changeItem`;
        const data = {
            billMonth: searchParams.billMonth,
            productId: selectedItem.productId,
            productName: selectedItem.productName,
            price: selectedItem.price,
            quantity: selectedItem.quantity,
            discountAmount: selectedItem.discountAmount,
            subtotal: selectedItem.subtotal
        }
        if (isCreate) {
            const res = await axiosInstance.post(url, data)
            if (res && res.data && res.data.success) {
                showSuccessNotify('Đã thêm thành công')
                setItems([])
                fetchMoreData(false)
            } else {
                showErrorNotify('Có lỗi, vui lòng liên hệ quản trị viên')
            }
        } else {
            const res = await axiosInstance.put(url, data)
            if (res && res.data && res.data.success) {
                showSuccessNotify('Đã cập nhật thành công')
                setItems([])
                fetchMoreData(false)
            } else {
                showErrorNotify('Có lỗi, vui lòng liên hệ quản trị viên')
            }
        }
        handleCloseClick()
    }

    const handleCloseClick = () => {
        setSelectedItem({})
        setSelectedProduct({})
        setShowDialog(false)
    }

    const changeValue = (value, field) => {
        setSelectedItem({...selectedItem, [field]: value});
    }

    const selectProductName = (e) => {
        const value = e.target.value
        setSelectedItem({
            productId: value.id,
            productName: value.name,
            price: value.price,
            quantity: 1,
            discountAmount: 0,
            subtotal: value.price
        })
        setSelectedProduct(value)
    }

    return <Card>
        <Title title="Sửa phiếu thu theo tháng"/>
        <CardContent>
            <div>
                <Filter searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        setItems={setItems}
                        billMonths={billMonths}
                />
                {items && items.length > 0 &&
                <Button onClick={showAddProduct} variant="outlined">
                    <AddIcon/> Thêm khoản thu cho tháng
                </Button>
                }
                <InfiniteScroll
                    style={{marginTop: 10}}
                    dataLength={items.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    height={window.innerHeight - 250}
                    loader={<CircularProgress style={{marginLeft: 10}} color="secondary"/>}
                >
                    <Table>
                        <TableHead style={{position: 'sticky', top: 0, zIndex: 1}}>
                            <TableRow>
                                <TableCell>Tên khoản thu</TableCell>
                                <TableCell>Số tiền</TableCell>
                                <TableCell>Số lượng</TableCell>
                                <TableCell>Miển giảm</TableCell>
                                <TableCell>Thành tiền</TableCell>
                                <TableCell padding='none'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell>{row.productName}</TableCell>
                                    <TableCell>{new Intl.NumberFormat().format(row.price)}</TableCell>
                                    <TableCell>{row.quantity}</TableCell>
                                    <TableCell>{new Intl.NumberFormat().format(row.discountAmount)}</TableCell>
                                    <TableCell>{new Intl.NumberFormat().format(row.subtotal)}</TableCell>
                                    <TableCell width={100}>
                                        <React.Fragment>
                                            <Button onClick={() => showEditProduct(row)} variant="outlined">
                                                <EditIcon/> Cập nhật
                                            </Button>

                                        </React.Fragment>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </InfiniteScroll>
            </div>
            <Dialog
                style={{zIndex: 10}}
                fullWidth={true}
                disableBackdropClick={true}
                maxWidth='sm'
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={8}>
                        <p style={{fontWeight: 'bold'}}>
                            Vui lòng cập nhật đầy đủ thông tin số tiền, số lượng, miễn
                            giảm, thành tiền theo đúng thông tin mong muốn
                        </p>
                        <Grid item xs={12} sm={6}>
                            {isCreate ?
                                <div
                                    style={{width: '100%', padding: '0 10px 10px 0px'}}>
                                    <InputLabel>Tên khoản thu</InputLabel>
                                    <Select InputLabelProps={{
                                        shrink: true,
                                    }}
                                            shrink
                                            style={{width: '100%'}}
                                            value={selectedProduct}
                                            onChange={selectProductName}
                                    >
                                        {products && products.map(p => <MenuItem
                                            key={p.name} value={p}>
                                            {p.name}
                                        </MenuItem>)}
                                    </Select>
                                </div>
                                :
                                <TextField label={'Tên khoản thu'}
                                           value={selectedItem.productName} readOnly/>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="number"
                                       InputLabelProps={{
                                           shrink: true,
                                       }} label={'Số lượng'}
                                       value={selectedItem.quantity}
                                       onChange={(event) => changeValue(event.target.value, 'quantity')}
                                       required/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="number"
                                       InputLabelProps={{
                                           shrink: true,
                                       }} label={'Số tiền'} value={selectedItem.price}
                                       onChange={(event) => changeValue(event.target.value, 'price')}
                                       required/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="number"
                                       InputLabelProps={{
                                           shrink: true,
                                       }} label={'Miễn giảm'}
                                       value={selectedItem.discountAmount}
                                       onChange={(event) => changeValue(event.target.value, 'discountAmount')}
                                       required/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField type="number"
                                       InputLabelProps={{
                                           shrink: true,
                                       }} label={'Thành tiền'}
                                       value={selectedItem.subtotal}
                                       onChange={(event) => changeValue(event.target.value, 'subtotal')}
                                       required/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SaveButton saving={isSubmitting} onClick={handleSaveClick}/>
                    <Button label="ra.action.cancel" onClick={handleCloseClick}>
                        <CancelIcon/>
                    </Button>
                </DialogActions>
            </Dialog>
        </CardContent>
    </Card>

}


export default BillViewEditByMonth
