import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm} from '../../components';
import env from '../../env';
import moment from "moment";

class SuatAnBanTru extends React.Component {
    state = {
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
    };

    getReportUrl = () => {
        return `${env.baseURL}/reports/suatAnBanTru?` +
            `fromDate=` + this.state.fromDate +
            `&toDate=` + this.state.toDate;
    };


    excelReport = () => {
        downloadFile(this.getReportUrl(), 'bao_cao_suat_an_ban_tru.xlsx')
    };


    validate = (values) => {
        const errors = {};
        if (values.fromDate && values.toDate && (values.fromDate > values.toDate)) {
            errors.toDate = 'To date must be greater than From date'
        }
        // Copy input values to params
        this.setState(values)

        return errors;
    }

    render() {
        return (
            <Card>
                <Title title="resources.suatAnBanTru.name"/>
                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                excelReport={this.excelReport}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.fromDate" source="fromDate" validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.toDate" source="toDate" validate={required()} {...this.props} />
                            </Grid>
                        </Grid>
                    </ReportForm>

                </CardContent>
            </Card>
        )
    }
}

export default SuatAnBanTru

