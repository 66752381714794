import React, {useEffect, useState} from 'react';
import env from "../../env";
import GetAppIcon from '@material-ui/icons/GetApp';
import {compose} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {Button, showNotification} from 'react-admin';
import Swal from "sweetalert2";
import {downloadFile} from "../../components";
import {get} from "axios";

const styles = {
    button: {
        margin: 1,
        width: 180
    },
};
const DownloadFoodProductMenuBatchButton = (props) => {


    const [kiots, setKiots] = useState([])

    useEffect(() => {
        loadKiotConfigs()
    }, [])

    const loadKiotConfigs = () => {
        const url = `${env.kiotUrl}/api/kiotConfigs/search/findSeller`;
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': token
            }
        };

        get(url, config).then(rs => {
            if (rs.data) {
                const data = rs.data

                const result = data.reduce((acc, curr) => {
                    acc[curr.id] = curr.name;
                    return acc;
                }, {});

                setKiots(result)
            }
        });
    }

    const handleClick = async (e) => {
        e.stopPropagation();
        const {value: kiotConfigId} = await Swal.fire({
            title: "Chọn quầy hàng",
            input: "select",
            inputOptions: kiots,
            inputPlaceholder: "Chọn quầy hàng",
            showCancelButton: true
        });
        if (kiotConfigId) {
            downloadFile(`${env.kiotUrl}/api/kiotProductMenuBatches/getTemplate?kiotConfigId=${kiotConfigId}`, 'mau_file.xlsx')
        }
    };

    return (
        <React.Fragment>
            <Button color="primary" style={styles.button}
                    onClick={handleClick} label={'ra.action.downloadTemplate'}>
                <GetAppIcon/>
            </Button>
        </React.Fragment>
    )
};

export default compose(
    connect(null, {showNotification}),
    withStyles(styles)
)(DownloadFoodProductMenuBatchButton)
