import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {AutocompleteInput, DateInput, ReferenceInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm, schoolYears, TreeCheckboxInput} from '../../components';
import env from '../../env';
import moment from "moment";

class BaoCaoDiemDanhLopTheoThang extends React.Component {
    state = {
        month: moment().format('YYYY-MM-DD'),
        unitIds: [],
        eventId: '',
        changeSchoolYear: false,
        schoolYearId: schoolYears[0].id
    };

    getReportUrl = () => {
        return `${env.baseURL}/reports/phieuDiemDanhThang?` +
            `month=` + this.state.month +
            `&schoolYearId=` + this.state.schoolYearId +
            `&unitIds=` + this.state.unitIds +
            `&eventId=` + this.state.eventId;
    };


    excelReport = () => {
        downloadFile(this.getReportUrl(), 'phieu_diem_danh_thang.xlsx')
    };



    validate = (values) => {
        const errors = {};

        this.setState(values);

        if (this.state.changeSchoolYear) {
            this.setState({
                unitIds: [],
                changeSchoolYear: false
            })
        } else {
            this.setState({
                unitIds: values.unitIds
            })
        }

        return errors;
    };
    onSchoolYearChanged(e, value) {
        this.setState({
            schoolYearId: value,
            changeSchoolYear: true
        });
    }

    render() {
        return (
            <Card>
                <Title title="resources.phieuDiemDanhThang.name"/>
                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                excelReport={this.excelReport}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.month" source="month" validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ReferenceInput source="eventId"
                                                reference="events"
                                                perPage={50}
                                                filter={{filterPath: 'filterSimple'}}
                                                validate={required()}
                                                {...this.props} >
                                    <AutocompleteInput source="name" />
                                </ReferenceInput>
                            </Grid>
                            <ReferenceInput source="schoolYearId"
                                            reference="schoolYears"
                                            label="resources.schoolYears.name"
                                            perPage={50}
                                            validate={required()}
                                            onChange={(e, value) => this.onSchoolYearChanged(e, value)}
                                            {...this.props} >
                                <AutocompleteInput source="name" />
                            </ReferenceInput>
                            <Grid item xs={12} sm={6}>
                                <label>Danh sách lớp</label>
                                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                                    <ReferenceInput source="unitIds"
                                                    reference="units"
                                                    perPage={999}
                                                    filter={{clazzWithParents: true, schoolYear: this.state.schoolYearId}}
                                                    {...this.props} >
                                        <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                                    </ReferenceInput>
                                </div>
                            </Grid>
                        </Grid>
                    </ReportForm>

                </CardContent>
            </Card>
        )
    }
}

export default BaoCaoDiemDanhLopTheoThang

