import React from 'react';
import {connect} from 'react-redux';
import {change, submit} from 'redux-form';
import {fetchEnd, fetchStart, refreshView, showNotification} from 'react-admin';
import {Button, Dialog, DialogActions, DialogTitle} from '@material-ui/core';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import StopIcon from '@material-ui/icons/Stop';
import axiosInstance from "../../api/axios";
import moment from 'moment'

const styles = {
  suggestDialog: {
    zIndex: '1 !important',
  },
  width100: {
    width: '100%',
  }
};

const FinalizeButton = (props) => {

  const [values, setValues] = React.useState({
    showDialog: false
  })

  React.useEffect(() => {
  }, []);

  function showFinalizeDialog() {
    setValues({showDialog: true});
  }

  function onSubmit() {
    setValues({showDialog: false});
    const url = `/api/manualAttendance/bulk-upload`;
    const data = [];
    props.selectedIds.forEach(i => {
      data.push({
        customerId: i,
        dateTime: moment().format('YYYYMMDDHHmmss')
      });
    })
    axiosInstance.post(url, {data: data}).then(rs => {
      if (rs.data.success) {
        props.showNotification('Gửi dữ liệu thành công');
      }
    }, err => {
      props.showNotification(err.message, 'warning');
    });
  }

  function onClose() {
    setValues({showDialog: false});
  }

  return (
      <React.Fragment>
        <Button color="primary" variant="contained"
                onClick={showFinalizeDialog}>
          <StopIcon/>
          Hoàn tất
        </Button>
        <Dialog open={values.showDialog}
                maxWidth={'md'}
                scroll={'paper'}
                onBackdropClick={onClose}
                aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            <div>Gửi dữ liệu điểm danh</div>
            {!values.error &&
            <div style={{fontSize: 10, color: 'red'}}>(*) Dữ liệu điểm danh sẽ
              được gửi lên hệ thống, sau khi ghi nhận những học sinh đã điểm
              danh (đã check) sẽ không thể chỉnh sửa
            </div>
            }
            {values.error &&
            <div style={{fontSize: 10, color: 'red'}}>
              {values.error}
            </div>
            }
          </DialogTitle>
          <DialogActions>
            <Button onClick={onClose} color="primary" variant="contained">
              Cancel
            </Button>
            <Button onClick={onSubmit} color="primary" variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
  )
}

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  submit,
  refreshView,
  showNotification
};

export default compose(connect(null, mapDispatchToProps),
    withStyles(styles))(
    FinalizeButton
);
