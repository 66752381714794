import React, {useEffect, useState} from 'react';
import {
    AutocompleteInput,
    Button,
    DateInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    Title
} from 'react-admin';
import {Card, CardContent, CircularProgress, Grid, TextField} from '@material-ui/core';
import axios from "../../api/axios";
import axiosInstance from "../../api/axios";
import {PaymentMethodInput, printContent, showErrorNotify, showSuccessNotify} from "../../components";
import NumberFormat from "react-number-format";
import env from "../../env";


const QuickBillPayment = () => {
    const [saving, setSaving] = useState(false)
    const [showGateway, setShowGateway] = useState(false)
    const [params, setParams] = useState({paymentAmount: 0, paymentMethod: 'CASH'})
    const [products, setProducts] = useState([])
    const [batches, setBatches] = useState([])

    useEffect(() => {
        loadProducts()
        loadBillBatches()
    }, [])

    const loadProducts = async () => {
        const {data} = await axios.get('/api/billProducts/search/findByEnableOrderByPriorityDesc?enable=true')
        setProducts(data.content)
    }

    const loadBillBatches = async () => {
        const {data} = await axios.get('/api/billBatches/search/draftBillBatches')
        if (data?.length > 0) {
            const arr = []
            for (let i = 0; i < data.length; i++) {
                arr.push({
                    id: data[i].id,
                    name: data[i].fullDescription ?? data[i].description
                })
            }
            setBatches(arr)
        }
    }

    const changePaymentMethod = (e, method) => {
        if ('BANK_TRANSFER' === method) {
            setShowGateway(true)
            setParams({...params, paymentMethod: method})
        } else {
            setShowGateway(false)
            setParams({...params, paymentMethod: method})
        }
    }
    const processChangeBillBatch = (e, value) => {
        if (value) {
            setParams({...params, billBatchId: value})
        } else {
            setParams({...params, billBatchId: 0})
        }
    }
    const changeAddress = (e) => {
        setParams({...params, payerAddress: e.target?.value})
    }
    const changePaymentDate = (e, value) => {
        setParams({...params, paymentDate: value})
    }
    const changeGateway = (e, value) => {
        setParams({...params, gateway: value})
    }
    const processChangePaymentInfo = (e, value) => {
        if (value) {
            getPaymentInfo(value).then(({data}) => {
                const method = data.paymentMethod ? data.paymentMethod : 'BANK_TRANSFER'
                setShowGateway(method === 'BANK_TRANSFER')
                data.paymentMethod = method
                data.gateway = '';
                data.paymentDate = '';
                data.customerId = value;
                data.payerName = params.payerName;
                data.payerAddress = params.payerAddress;
                data.billBatchId = params.billBatchId;
                data.paymentAmount = params.paymentAmount;
                setParams(data)
            });
        } else {
            setParams({paymentAmount: 0, paymentMethod: 'CASH'})
        }
    }

    const getPaymentInfo = (value) => {
        const url = `/api/payments/getPaymentInfo?customerId=` + value;
        return axiosInstance.get(url);
    }
    const formatNumber = (num) => {
        return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
    }

    const changeQuantity = (index, quantity) => {
        const arr = [...products]
        if (quantity) {
            arr[index].quantity = quantity
            arr[index].total = quantity * arr[index].price
        } else {
            arr[index].quantity = 0
            arr[index].total = 0
        }
        let total = 0;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].total) {
                total += arr[i].total
            }
        }
        setParams({...params, paymentAmount: total})
        setProducts(arr)
    }
    const validate = () => {
        if (!params.billBatchId || params.billBatchId <= 0) {
            showErrorNotify('Vui lòng chọn kế hoạch thu');
            return false;
        }
        if (!params.customerId || params.customerId <= 0) {
            showErrorNotify('Vui lòng chọn học sinh');
            return false;
        }
        if (!params.paymentAmount || params.paymentAmount <= 0) {
            showErrorNotify('Vui lòng kiểm tra lại số tiền thanh toán');
            return false;
        }
        return true;
    }

    const sendPayment = () => {
        const url = `/api/payments/tao-nhanh-thanh-toan`;
        params.products = products.filter(p => p.total > 0)
        return axiosInstance.post(url, params);
    }

    const getPrintElectronicBillUrl = (paymentId) => {
        return `${env.baseURL}/api/paymentViews/print-both-receipt?` +
            `paymentId=${paymentId}`;
    };

    const getPaymentConfirmUrl = (paymentId) => {
        return `${env.baseURL}/api/paymentViews/print-confirm?` +
            `paymentId=${paymentId}`;
    };
    const resetInput = () => {
        setParams({paymentAmount: 0, paymentMethod: 'CASH'})
        setShowGateway(false)
        let arr = [...products]
        for (let i = 0; i < arr.length; i++) {
            arr[i].quantity = 0
            arr[i].total = 0
        }
        setProducts(arr)
    };
    const handlePaymentOnly = () => {
        if (!validate()) {
            return;
        }
        setSaving(true)
        sendPayment()
            .then(({data}) => {
                if (data == null || !data.paymentId) {
                    showErrorNotify('Thanh toán không thành công, vui lòng kiểm tra lại số tiền hoặc liên hệ quản trị viên!')
                    setSaving(false)
                } else {
                    showSuccessNotify('Thanh toán thành công!')
                    const url = `/api/paymentViews/print-both-receipt?` +
                        `paymentId=${data.paymentId}`;
                    axiosInstance.get(url);
                    setSaving(false)
                }
            })
            .catch(error => {
                showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!")
                setSaving(false)
            })
    };
    const handlePaymentAndPrintConfirmClick = () => {
        if (!validate()) {
            return;
        }
        setSaving(true)
        sendPayment()
            .then(({data}) => {
                if (data == null || !data.paymentId) {
                    showErrorNotify('Thanh toán không thành công, vui lòng kiểm tra lại số tiền hoặc liên hệ quản trị viên!')
                    setSaving(false)
                } else {
                    showSuccessNotify('Thanh toán thành công!')
                    const url = `/api/paymentViews/print-both-receipt?` +
                        `paymentId=${data.paymentId}`;
                    axiosInstance.get(url);
                    setTimeout(() => {
                        printContent(getPaymentConfirmUrl(data.paymentId))
                        setSaving(false)
                    }, 1000)
                }
            })
            .catch(error => {
                showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!")
                setSaving(false)
            })
    };
    const saveAndPrintHddtAndBienLai = () => {
        if (!validate()) {
            return;
        }

        setSaving(true)

        sendPayment()
            .then(({data}) => {
                if (data == null || !data.paymentId) {
                    showErrorNotify('Thanh toán không thành công, vui lòng kiểm tra lại số tiền hoặc liên hệ quản trị viên!')
                    setSaving(false)
                } else {
                    showSuccessNotify('Thanh toán thành công!')
                    setTimeout(() => {
                        if (params.enableShowPrint) {
                            printContent(getPrintElectronicBillUrl(data.paymentId))
                        } else {
                            const url = `/api/paymentViews/print-both-receipt?` +
                                `paymentId=${data.paymentId}`;
                            axiosInstance.get(url)
                        }
                        setSaving(false)
                    }, 1000)
                }
            })
            .catch(error => {
                showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!")
                setSaving(false)
            })
    };

    const labelPrefix = 'resources.tao-nhanh-thanh-toan.fields'
    return (
        <Card>
            <Title title={`resources.tao-nhanh-thanh-toan.name`}/>
            <CardContent>
                <SimpleForm
                    record={params}
                    form="payment-quick-create"
                    toolbar={null}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={3}>
                            <SelectInput label={`${labelPrefix}.billBatchId`} source="billBatchId"
                                         choices={batches}
                                         validate={required()} onChange={processChangeBillBatch}>
                                <AutocompleteInput optionText="name"/>
                            </SelectInput>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <ReferenceInput label={`${labelPrefix}.customerId`} source="customerId"
                                            reference="customers"
                                            validate={required()} onChange={processChangePaymentInfo}>
                                <AutocompleteInput optionText="displayName"/>
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextInput source="relationshipName" disabled={true}
                                       label="resources.paymentViews.fields.payerName"/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextInput source="relationshipAddress" disabled={true}
                                       label="resources.paymentViews.fields.payerAddress"/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <NumberFormat thousandSeparator={true} allowNegative={false}
                                          customInput={TextField}
                                          value={params.paymentAmount}
                                          label="Số tiền thanh toán"
                                          disabled={true}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <PaymentMethodInput source="paymentMethod"
                                                onChange={changePaymentMethod}/>
                        </Grid>
                        {showGateway ?
                            <Grid item xs={12} sm={3}>
                                <SelectInput source='gateway' choices={params.gateways} onChange={changeGateway}
                                             label="resources.paymentViews.fields.gateway"/>
                            </Grid>
                            : ''
                        }
                        {showGateway ?
                            <Grid item xs={12} sm={3}>
                                <DateInput label="resources.paymentViews.fields.paymentDate"
                                           onChange={changePaymentDate}
                                           source="paymentDate"/>
                            </Grid>
                            : ''
                        }

                        <Grid container spacing={8} style={{
                            borderBottomStyle: 'solid',
                            borderBottomWidth: '0.1px',
                            borderBottomColor: '#c7c7c7',
                            marginTop: 20,
                            paddingTop: 10,
                            paddingBottom: 10
                        }}>
                            <Grid item xs={12} sm={3}>
                                {'Khoản thu'}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                {'Đơn giá'}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                {'Số lượng'}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                {'Thành tiền'}
                            </Grid>
                        </Grid>
                        {products && products.map((item, idx) => (
                            <Grid container spacing={8} style={{
                                borderBottomStyle: 'solid',
                                borderBottomWidth: '0.1px',
                                borderBottomColor: '#c7c7c7',
                                paddingTop: 16,
                                paddingBottom: 16
                            }}>
                                <Grid item xs={12} sm={3}>
                                    {item.name}
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    {formatNumber(item.price)}
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <input value={item.quantity ?? 0} style={{border: 'none', padding: 5}}
                                           onChange={(e) => changeQuantity(idx, e.target.value)}/>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    {item.total ? formatNumber(item.total) : 0}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid container spacing={8} style={{marginTop: 26}}>
                        <Button style={{marginRight: 10}} variant={'contained'} label="ra.action.payAndPrintConfirm"
                                onClick={handlePaymentAndPrintConfirmClick} disabled={saving}/>
                        <Button style={{marginRight: 10}} variant={'contained'} label={'Thanh toán và in HĐ + Biên lai'}
                                onClick={saveAndPrintHddtAndBienLai} disabled={saving}/>
                        <Button style={{marginRight: 10}} variant={'contained'} label={'Chỉ thanh toán'}
                                onClick={handlePaymentOnly} disabled={saving}/>
                        <Button style={{marginRight: 10}} variant={'contained'} label={'Tạo mới'}
                                onClick={resetInput} disabled={saving}/>
                        {
                            saving && <CircularProgress style={{marginLeft: 10}} color="secondary"/>
                        }
                        <iframe id="pdf-frame" style={{
                            width: '1px', height: '1px',
                            visibility: 'hidden',
                        }}></iframe>
                    </Grid>
                </SimpleForm>
            </CardContent>
        </Card>

    );
};

export default QuickBillPayment
