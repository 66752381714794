import React from 'react';
import {Datagrid, DateField, Filter, List, NumberField, TextField, TextInput} from 'react-admin';
import {DateTimeField} from "../../components";


const BillViewFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);


export const BillViewList = props => (
  <List filters={<BillViewFilter />} {...props} bulkActionButtons={false} >
    <Datagrid rowClick="show" >
      <TextField source="billNo" />
      <TextField source="customerId" />
      <TextField source="customerName" />
      <TextField source="unitName" />
      <DateField source="billMonth" options={{year: 'numeric', month: '2-digit' }}/>
      <DateTimeField source="createdOn" format="DD-MM-YYYY HH:mm" />
      <NumberField source="billBalance" locales="vi-VI" options={{ style: 'currency', currency: 'VND' }} />
    </Datagrid>
</List>
);

export default BillViewList
