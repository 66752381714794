import MonTuChonIcon from '@material-ui/icons/ViewList';

import AdmissionCategoryList from './AdmissionCategoryList';
import AdmissionCategoryCreate from './AdmissionCategoryCreate';
import AdmissionCategoryEdit from './AdmissionCategoryEdit';

export default {
  list: AdmissionCategoryList,
  create: AdmissionCategoryCreate,
  edit: AdmissionCategoryEdit,
  icon: MonTuChonIcon,
};
