import React from 'react';
import { SelectField, SelectInput } from 'react-admin';

const authStatuses = [
  { id: 'A_NOT_READY', name: 'cardApplicationStatus.NOT_READY' },
  { id: 'A_DIFF_DDCN',   name: 'cardApplicationStatus.A_DIFF_DDCN' },
  { id: 'A_DIFF_PHONE', name: 'cardApplicationStatus.A_DIFF_PHONE' },
  { id: 'A_SAME_DDCN', name: 'cardApplicationStatus.A_SAME_DDCN' },
  { id: 'A_SAME_PHONE',   name: 'cardApplicationStatus.A_SAME_PHONE' },
  { id: 'B_READY',   name: 'cardApplicationStatus.READY' },
  { id: 'B_TEMP_READY',   name: 'cardApplicationStatus.B_TEMP_READY' },
  { id: 'E_IN_REVIEW',   name: 'cardApplicationStatus.IN_REVIEW' },
  { id: 'L_REVIEW_REJECTED',   name: 'cardApplicationStatus.REVIEW_REJECTED' },
  { id: 'M_WAIT_SYNC',   name: 'cardApplicationStatus.WAIT_SYNC' },
  { id: 'O_ACCEPTED',   name: 'cardApplicationStatus.REVIEW_ACCEPTED' },
  { id: 'R_IN_PRINTING',   name: 'cardApplicationStatus.IN_PRINTING' },
  { id: 'Y_DELIVERED',   name: 'cardApplicationStatus.DELIVERED' },
]

export const CardApplicationStatusField = (props) => (
  <SelectField source={props.source} choices={authStatuses} {...props} />
)
export const CardApplicationStatusInput = (props) => (
    <SelectInput source={props.source} choices={authStatuses} {...props} />
)

const status1 = [
  { id: 'A_NOT_READY', name: 'cardApplicationStatus.NOT_READY' },
  { id: 'B_TEMP_READY',   name: 'cardApplicationStatus.B_TEMP_READY' },
  { id: 'B_READY',   name: 'cardApplicationStatus.READY' },
]
export const CardApplicationStatusInput1 = (props) => (
    <SelectInput source={props.source} choices={status1} {...props} />
)

const status2 = [
  { id: 'A_DIFF_DDCN',   name: 'cardApplicationStatus.A_DIFF_DDCN' },
  { id: 'A_DIFF_PHONE', name: 'cardApplicationStatus.A_DIFF_PHONE' },
  { id: 'A_SAME_DDCN', name: 'cardApplicationStatus.A_SAME_DDCN' },
  { id: 'A_SAME_PHONE',   name: 'cardApplicationStatus.A_SAME_PHONE' },
]
export const CardApplicationStatusInput2 = (props) => (
    <SelectInput source={props.source} choices={status2} {...props} />
)

const status3 = [
  { id: 'O_ACCEPTED', name: 'cardApplicationStatus.REVIEW_ACCEPTED' },
  { id: 'R_IN_PRINTING', name: 'cardApplicationStatus.IN_PRINTING' },
  { id: 'Y_DELIVERED',   name: 'cardApplicationStatus.DELIVERED' },
]
export const CardApplicationStatusInput3 = (props) => (
    <SelectInput source={props.source} choices={status3} {...props} />
)
