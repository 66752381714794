import EmployeeEnrollmentIcon from '@material-ui/icons/ViewList';

import ResidentEnrollmentList from './ResidentEnrollmentList';
import ResidentEnrollmentCreate from './ResidentEnrollmentCreate';
import ResidentEnrollmentEdit from './ResidentEnrollmentEdit';

export default {
  list: ResidentEnrollmentList,
  create: ResidentEnrollmentCreate,
  edit: ResidentEnrollmentEdit,
  icon: EmployeeEnrollmentIcon,
};
