import React, {useEffect, useState} from 'react';
import {refreshView, showNotification, SimpleForm, translate} from 'react-admin';

import {Grid} from '../../components'
import AdmissionResultTemplateFields from './AdmissionResultTemplateFields'
import {compose} from "redux";
import {connect} from "react-redux";
import withStyles from '@material-ui/core/styles/withStyles';
import axiosInstance from "../../api/axios";

const styles = {
    item: {
        "& > table ": {
            width: '95%',
        },
        "& > table td:nth-child(2)": {
            textAlign: 'right',
        },
        "& > table thead": {    // hide the header
            display: 'none',
        },
    },
    select: {
        marginTop: '20px',
    },
    button: {
        // marginTop: '-20px',
        marginLeft: '10px'
    },
}
let timeout = null;
export const AdmissionResultTemplateEdit = ({
                                                translate,
                                                classes,
                                                showToolbar = true,
                                                redirect = "list",
                                                noCancelButton,
                                                event,
                                                ...props
                                            }) => {
    const [values, setValues] = useState({
        merged: null,
        id: '',
        name: '',
        isRedirect: false,
        saveDisabled: false,
        isFistTimeCheck: true,
        ready: false,

    });
    useEffect(() => {
        if (props.id && values.isFistTimeCheck) {
            getDetails();
        }
    }, [values.isFistTimeCheck]);

    function getDetails() {
        const url = `/api/admissionResultTemplates/` + props.id;
        axiosInstance.get(url).then(rs => {
            const {data} = rs;
            const merged = Object.assign(values, data);
            setValues({...values, merged, ready: true, isFistTimeCheck: false});
        });
    }

    const handleChange = prop => e => {
        if (!e) {
            return
        }
        const value = e.target.value ? e.target.value : ''

        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            setValues({...values, [prop]: value});
        }, 100);
    };

    function validate() {
        return true;
    }

    function save() {
        if (!validate()) {
            return;
        }
        const data = Object.assign({}, values);
        setValues({
            ...values, isRedirect: true
        });
        const url = `/api/admissionResultTemplates/` + props.id;
        axiosInstance.put(url, data).then(rs => {
            props.showNotification('Cập nhật thành công');
        }, err => {
            props.showNotification(err.message, 'warning');
        })
    }

    const changeContent = val => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            setValues({...values, description: val});
        }, 100);
    };
    return (
        <SimpleForm
            toolbar={null}
            record={values}
        >
            <Grid container spacing={8}>
                <AdmissionResultTemplateFields save={save} defaultValue={values} onChange={handleChange}
                                               changeContent={changeContent}/>
            </Grid>
        </SimpleForm>
    )
}

export default compose(
    connect(undefined, {refreshView, showNotification}),
    translate,
    withStyles(styles)
)(AdmissionResultTemplateEdit)

