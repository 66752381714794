import React, {useRef, useState} from 'react';
import {
    ClickAwayListener,
    Divider,
    Grow,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    Paper,
    Popper,
    Typography
} from '@material-ui/core';
import {ChatboxEllipsesOutline} from "./ChatBoxEllipsesOutlineIcon";
import {PhoneFillIcon} from "./PhoneFillIcon";
import {EarthIcon} from "./EarthIcon";

const QuizzToolbar = () => {
    const [open, setOpen] = useState(false);
    const anchorEl = useRef(null);

    const handleToggle = (event) => {
        anchorEl.current = event.currentTarget
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event) => {
        event.stopPropagation()
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <React.Fragment>
                <IconButton
                    variant={"fab"}
                    aria-owns={open ? 'menu-list-grow' : null}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    size={'small'}
                    style={open ? {backgroundColor: 'rgba(0, 0, 0, 0.08)'} : {}}
                >
                    <img src='images/quiz-icon.svg'/>
                </IconButton>
                <Popper open={open} anchorEl={anchorEl.current} transition style={{zIndex: 1000}} placement='bottom-end'>
                    {({TransitionProps}) => (
                        <Grow {...TransitionProps} id="menu-list-grow">
                            <Paper style={{padding: 16, margin: 8}}>
                                <div style={{
                                    width: 360,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '16px 0',
                                }}>
                                    <Typography variant='headline'>Bạn cần trợ giúp?</Typography>

                                    <Typography variant='subheading' style={{color: "grey"}}>Vui lòng liên hệ với bộ
                                        phận Chăm Sóc Khách Hàng của VinaID qua các kênh sau:</Typography>

                                    <List component="nav" style={{width: '100%'}}>
                                        <ListItem button
                                                  onClick={() => window.open('https://zalo.me/231667867670292511')}>
                                            <ListItemIcon>
                                                <ChatboxEllipsesOutline style={{color: '#347aeb', fontSize: 24}}/>
                                            </ListItemIcon>
                                            <ListItem disableGutters>
                                                <Typography>
                                                    Zalo: <span
                                                    style={{color: '#347aeb'}}>0837 27 27 28</span> (Mycitypay
                                                    Healpdesk)
                                                </Typography>
                                            </ListItem>
                                        </ListItem>
                                        <Divider/>
                                        <ListItem button onClick={() => window.open('tel:1900272728')}>
                                            <ListItemIcon>
                                                <PhoneFillIcon style={{color: '#347aeb', fontSize: 24}}/>
                                            </ListItemIcon>
                                            <ListItem disableGutters>
                                                <Typography>
                                                    Điện thoại: <span
                                                    style={{color: '#347aeb'}}>1900 27 27 28</span> (08h00-17h00)
                                                </Typography>
                                            </ListItem>
                                        </ListItem>
                                        <Divider/>
                                        <ListItem button onClick={() => window.open('https://facebook.com/VinaID.vn')}>
                                            <ListItemIcon>
                                                <img src='images/facebook.png' style={{width: 24}}/>
                                            </ListItemIcon>
                                            <ListItem disableGutters>
                                                <Typography>
                                                    Fanpage: <span style={{color: '#347aeb'}}>fb.com/VinaID.vn</span>
                                                </Typography>
                                            </ListItem>
                                        </ListItem>
                                        <Divider/>
                                        <ListItem button onClick={() => window.open('https://vinaid.vn')}>
                                            <ListItemIcon>
                                                <EarthIcon style={{color: '#347aeb', fontSize: 24}}/>
                                            </ListItemIcon>
                                            <ListItem disableGutters>
                                                <Typography>
                                                    Hỗ trợ trực tuyến trên các website <span
                                                    style={{color: '#347aeb'}}>VinaID.vn</span> và <span
                                                    style={{color: '#347aeb'}}>myCityPay.com</span> (có nút liên lạc hỗ
                                                    trợ, không cần tài khoản Facebook).
                                                </Typography>
                                            </ListItem>
                                        </ListItem>
                                    </List>
                                    <Divider/>
                                </div>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </React.Fragment>
        </ClickAwayListener>

    )
};

export default QuizzToolbar;
