import React from 'react';
import {ImageField, ImageInput, required, SaveButton, TextInput, Toolbar, translate} from "react-admin";
import Grid from '@material-ui/core/Grid'
import {CancelButton} from "../../components";

const LinkingAppField = ({translate, onSave, saving, history}) => {
    const onCancel = () => {
        history.replace("/linking-apps")
    }
    return (
        <React.Fragment>
            <Grid item xs={12} sm={6}>
                <TextInput source='name' label={translate('resources.linking-apps.fields.name')} validate={required()}
                           style={{minWidth: 350}}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput source='href' label={translate('resources.linking-apps.fields.href')} fullWidth
                           validate={required()}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <ImageInput source="logo" accept={'image/*'} validate={required()}>
                    <ImageField source="src" title="title"/>
                </ImageInput>
            </Grid>
            <Grid xs={12} item>
                <Toolbar>
                    <SaveButton onClick={onSave} disabled={saving}/>

                    <CancelButton onClick={onCancel}/>

                </Toolbar>
            </Grid>
        </React.Fragment>
    );
}

export default translate(LinkingAppField)
