import SettingIcon from '@material-ui/icons/ViewList';

import SettingList from './SettingList';
import SettingCreate from './SettingCreate';
import SettingEdit from './SettingEdit';

export default {
  list: SettingList,
  create: SettingCreate,
  edit: SettingEdit,
  icon: SettingIcon,
};
