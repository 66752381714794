import React from 'react';
import { translate, CheckboxGroupInput } from 'react-admin';

const DaysOfWeekCheckboxInput = ({translate, source, ...props}) => {
  const daysOfWeek = [
    { id: 'MONDAY',     name: translate('daysOfWeek.MONDAY') },
    { id: 'TUESDAY',    name: translate('daysOfWeek.TUESDAY') },
    { id: 'WEDNESDAY',  name: translate('daysOfWeek.WEDNESDAY') },
    { id: 'THURSDAY',   name: translate('daysOfWeek.THURSDAY') },
    { id: 'FRIDAY',     name: translate('daysOfWeek.FRIDAY') },
    { id: 'SATURDAY',   name: translate('daysOfWeek.SATURDAY') },
    { id: 'SUNDAY',     name: translate('daysOfWeek.SUNDAY') },
  ]
  
  return (
    <CheckboxGroupInput source={source} choices={daysOfWeek} {...props} />
  )
}

export default translate(DaysOfWeekCheckboxInput)