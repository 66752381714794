import React, {useEffect, useState} from 'react';
import {Title, translate} from "react-admin";
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    NativeSelect,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@material-ui/core';
import axiosInstance from "../../api/axios";
import moment from "moment/moment";
import {CancelButton} from "../../components";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash'
import {withStyles} from '@material-ui/core/styles';


const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    description: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
});

const Filter = translate(({translate, filterParams = {}, onChangeFilter}) => {
    const [units, setUnits] = useState([])

    useEffect(() => {
        axiosInstance.get(`/api/customer-discipline/units`).then(rs => {
            const units = rs.data || []
            setUnits(units)
        })
    }, [])

    return <Grid container spacing={24} alignItems={'flex-end'}>
        <Grid item>
            <FormControl>
                <InputLabel shrink>{translate("resources.customer-discipline.fields.units")}</InputLabel>
                <NativeSelect name="units"
                              onChange={e => onChangeFilter('unitId', e.target.value)}
                >
                    <option value="">Tất cả</option>
                    {units.map(unit => <option key={unit.id} value={unit.id}>{unit.name}</option>)}
                </NativeSelect>
            </FormControl>
        </Grid>
        <Grid item>
            <TextField type={'date'}
                       label={translate("resources.customer-discipline.fields.checkDate")}
                       value={filterParams['checkDate']}
                       onChange={e => onChangeFilter('checkDate', e.target.value)}
                       inputProps={{max: moment().format('YYYY-MM-DD')}}
            />
        </Grid>
    </Grid>
})

const DisciplineDescription = withStyles(styles)(({items = [], classes}) => {
    return items.map((item, idx) => {
        const totalPoint = item.totalPlusPoint - item.totalMinusPoint;
        return <Typography className={classes.description} key={idx}>
            - {item.disciplineName} ({totalPoint > 0 ? `+${totalPoint}` : totalPoint}) <br/>
        </Typography>
    })
})

const CustomerDisciplineDetailPanel = withStyles(styles)(({items = [], classes}) => {
    const itemMap = _.groupBy(items, 'customerId');
    return Object.keys(itemMap).map(key => {
        const items = itemMap[key]
        const {customerId, customerName} = items[0]

        const totalPlusPoint = items.reduce((total, current) => total + current.totalPlusPoint, 0)
        const totalMinusPoint = items.reduce((total, current) => total + current.totalMinusPoint, 0)

        return <React.Fragment key={key}>
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                    <div>
                        <Typography className={classes.heading}>{customerId} - {customerName}</Typography>
                        <Typography className={classes.description}>
                            Điểm cộng: {totalPlusPoint} - Điểm trừ: {totalMinusPoint}
                        </Typography>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div>
                        <DisciplineDescription items={items}/>
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </React.Fragment>
    })
})

const UnitDisciplineDetailPanel = withStyles(styles)(({items = [], classes}) => {

    if (items.length === 0)
        return <></>
    const totalPlusPoint = items.reduce((total, current) => total + current.totalPlusPoint, 0)
    const totalMinusPoint = items.reduce((total, current) => total + current.totalMinusPoint, 0)

    return <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
            <div>
                <Typography className={classes.heading}>Điểm thi đua lớp</Typography>
                <Typography className={classes.description}>
                    Điểm cộng: {totalPlusPoint} - Điểm trừ: {totalMinusPoint}
                </Typography>
            </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <div>
                <DisciplineDescription items={items}/>
            </div>
        </ExpansionPanelDetails>
    </ExpansionPanel>

})

const UnitDisciplineDetailDialog = translate(({open, onClose, params = {}}) => {

    const [data, setData] = useState({
        unitDiscipline: [],
        customerDiscipline: []
    })
    const {unitId, summaryDate} = params
    useEffect(() => {
        if (!unitId || !summaryDate)
            return

        axiosInstance.get(`/api/unit-discipline/report/detail?unitId=${unitId}&summaryDate=${summaryDate}`).then(rs => setData(rs.data))
    }, [unitId, summaryDate]);


    return <React.Fragment>
        <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth>
            <DialogTitle>
                CHI TIẾT ĐIỂM THI ĐUA LỚP {params.unitName} - NGÀY {moment(summaryDate).format('DD/MM/YYYY')}
            </DialogTitle>
            <DialogContent style={{padding: 0}}>
                <UnitDisciplineDetailPanel items={data.unitDiscipline}/>
                <CustomerDisciplineDetailPanel items={data.customerDiscipline}/>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={onClose}/>
            </DialogActions>
        </Dialog>

    </React.Fragment>
})

const DataTable = translate(({translate, filterParams = {}, permissions = []}) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const {unitId, checkDate} = filterParams
    const [selectedRow, setSelectedRow] = useState()

    const onOpenDialog = (row) => {
        setSelectedRow(row)
    }

    const onClose = () => {
        setSelectedRow(undefined)
    }

    useEffect(() => {
        let params = new URLSearchParams({checkDate, unitIds: unitId})

        if (!unitId) {
            params = new URLSearchParams({checkDate})
        }
        setLoading(true)
        axiosInstance.get(`/api/unit-discipline/report?${params.toString()}`)
            .then(rs => setData(rs.data))
            .finally(() => setLoading(false))
    }, [unitId, checkDate]);

    return <div style={{marginTop: 16}}>
        <UnitDisciplineDetailDialog open={!!selectedRow} onClose={onClose} params={selectedRow}/>


        {loading && <LinearProgress/>}
        <Table>
            <TableHead>
                <TableRow style={{backgroundColor: "#f5f5f5"}}>
                    <TableCell style={{padding: '4px 24px'}} width={70}>
                        {translate("resources.discipline-report.fields.unitId")}
                    </TableCell>
                    <TableCell style={{padding: '4px 24px'}} width={150}>
                        {translate("resources.discipline-report.fields.unitName")}
                    </TableCell>
                    <TableCell style={{padding: '4px 24px'}}>
                        {translate("resources.discipline-report.fields.planName")}
                    </TableCell>
                    <TableCell style={{padding: '4px 24px'}} width={70}>
                        {translate("resources.discipline-report.fields.summaryDate")}
                    </TableCell>
                    <TableCell style={{padding: '4px 24px', textAlign: 'right'}} width={70}>
                        {translate("resources.discipline-report.fields.minusPoint")}
                    </TableCell>
                    <TableCell style={{padding: '4px 24px', textAlign: 'right'}} width={70}>
                        {translate("resources.discipline-report.fields.plusPoint")}
                    </TableCell>
                    <TableCell style={{padding: '4px 24px', textAlign: 'right'}} width={70}>
                        {translate("resources.discipline-report.fields.remainPoint")}
                    </TableCell>
                    <TableCell width={70}></TableCell>
                </TableRow>
            </TableHead>
        </Table>

        <div style={{overflow: 'auto', height: 600}}>
            <Table>
                <TableBody>
                    {data.map((row, idx) => (
                        <TableRow key={idx}>
                            <TableCell style={{padding: '4px 24px'}} width={70}>
                                {row.unitId}
                            </TableCell>
                            <TableCell style={{padding: '4px 24px'}} width={150}>
                                {row.unitName}
                            </TableCell>
                            <TableCell style={{padding: '4px 24px'}}>
                                {row.planName}
                            </TableCell>
                            <TableCell style={{padding: '4px 24px'}} width={70}>
                                {moment(row.summaryDate).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell style={{padding: '4px 24px', textAlign: 'right'}} width={70}>
                                {row.minusPoint}
                            </TableCell>
                            <TableCell style={{padding: '4px 24px', textAlign: 'right'}} width={70}>
                                {row.plusPoint}
                            </TableCell>
                            <TableCell style={{padding: '4px 24px', textAlign: 'right'}} width={70}>
                                {row.remainPoint}
                            </TableCell>
                            <TableCell width={70}>
                                <Button size="small" color='primary' onClick={() => onOpenDialog(row)}>
                                    Chi tiết
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    </div>
})

const DisciplineReport = (props) => {
    const [filterParams, setFilterParams] = useState({
        checkDate: moment().format('YYYY-MM-DD')
    })

    const onChangeFilter = (field, value) => {
        setFilterParams(params => ({...params, [field]: value}))
    }

    return <Card>
        <Title title="resources.discipline-report.name"/>
        <CardContent>
            <Filter {...{filterParams, onChangeFilter}}/>
            <DataTable filterParams={filterParams} permissions={props.permissions}/>
        </CardContent>
    </Card>
};

export default DisciplineReport
