import DepartmentIcon from '@material-ui/icons/ViewList';

import DepartmentList from './DepartmentList';
import DepartmentCreate from './DepartmentCreate';
import DepartmentEdit from './DepartmentEdit';

export default {
  list: DepartmentList,
  create: DepartmentCreate,
  edit: DepartmentEdit,
  icon: DepartmentIcon,
};
