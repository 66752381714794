import React from 'react';
import {
    Datagrid,
    Filter,
    List,
    TextField,
    TextInput,
    ReferenceInput,
    NumberField,
    AutocompleteInput,
    CardActions
} from 'react-admin';
import { DateTimeField } from '../../components'
import {PrintReceiptButton, PrintPhieuThuButton} from "../../components";
const iframeStyle = {
    width: '1px', height: '1px',
    visibility: 'hidden',
};
const PostActions =  () => (
    <CardActions>
        <iframe id="pdf-frame" style={iframeStyle}></iframe>
    </CardActions>
);

class PartnerPaymentViewFilter extends React.Component {
  render() {
    return (
      <Filter {...this.props}>
        <ReferenceInput label="resources.partnerPaymentViews.fields.tenantId"
                        source="tenantId" reference="partnerSchools"
                        alwaysOn allowEmpty {...this.props} >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
      </Filter>
    )
  }
}

export const PartnerPaymentViewList = props => (
  <List filters={<PartnerPaymentViewFilter />} {...props} bulkActionButtons={false} actions={<PostActions />} >
    <Datagrid rowClick="show" >
      <TextField source="paymentNo" />
      <TextField source="tenantName"/>
      <TextField source="payerAddress" />
      <TextField source="customerId" />
      <TextField source="payerName" />
      <TextField source="note" />
      <NumberField source="paymentAmount" locales="vi-VI" options={{ style: 'currency', currency: 'VND' }}/>
      <TextField source="electronicBillNum" />
      <DateTimeField source="electronicBillDate" format="DD-MM-YYYY HH:mm" />
      <PrintReceiptButton subPath="abc" />
      <PrintPhieuThuButton />
    </Datagrid>
</List>
);

export default PartnerPaymentViewList
