import AuthorizedAttendanceHistoryIcon from '@material-ui/icons/ViewList';

import AuthorizedAttendanceHistoryList from './AuthorizedAttendanceHistoryList';
import AuthorizedAttendanceHistoryEdit from './AuthorizedAttendanceHistoryEdit';

export default {
  list: AuthorizedAttendanceHistoryList,
  edit: AuthorizedAttendanceHistoryEdit,
  icon: AuthorizedAttendanceHistoryIcon,
};
