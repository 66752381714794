import React from 'react';
import {CardActions, DateField, Filter, List, NumberField, TextField, TextInput} from 'react-admin';
import {DateTimeField, DeleteBillButton, PostQuickPayBillButton} from "../../components";
import {BaseCustomDatagrid} from "../../components/BaseCustomDatagrid";

const iframeStyle = {
    width: '1px', height: '1px',
    visibility: 'hidden',
}

const BillViewFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

const PostActions = () => (
    <CardActions>
        <iframe id="pdf-frame" style={iframeStyle}></iframe>
    </CardActions>
);

export const BillViewList = props => (
    <List filters={<BillViewFilter/>} {...props} bulkActionButtons={false} actions={<PostActions/>} exporter={false}>
        <BaseCustomDatagrid showSelectBox={false}>
            <TextField source="billNo" width={'6%'}/>
            <TextField source="customerId" width={'6%'}/>
            <TextField source="customerName" width={'18%'}/>
            <TextField source="unitName" width={'10%'}/>
            <DateField source="billMonth" options={{year: 'numeric', month: '2-digit'}} width={'10%'}/>
            <DateTimeField source="createdOn" format="HH:mm DD-MM-YYYY" width={'12%'}/>
            <NumberField source="billBalance" locales="vi-VI" options={{style: 'currency', currency: 'VND'}}
                         width={'9%'}/>
            <NumberField source="totalUnpaid" locales="vi-VI" options={{style: 'currency', currency: 'VND'}}
                         width={'9%'}/>
            <PostQuickPayBillButton label="Hành động"/>
            <DeleteBillButton/>
        </BaseCustomDatagrid>
    </List>
);

export default BillViewList
