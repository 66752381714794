import React, {Fragment} from 'react';
import {
    AutocompleteInput,
    Datagrid,
    DatagridBody,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    TextField,
    TextInput
} from 'react-admin';
import {GenderField} from "../../components/Gender";
import {DateTimeField, schoolYears} from "../../components";
import {CardApplicationStatusField, CardApplicationStatusInput2} from "../../components/CardApplicationStatus";
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ExportReportButton from "../CardApplication/ExportReportButton";
import ImageUploadWrapperWithRefresh from "../CardApplication/ImageUploadWrapperWithRefresh";
import CardApplicationActions from "./CardApplicationActions";

const CustomDatagridRow = ({record, resource, id, onToggleItem, children, selected, basePath}) => (
    <TableRow key={id}>
        <TableCell style={{width: "2%"}} padding="none">
            {(record.status === 'B_READY' || record.status === 'L_REVIEW_REJECTED') &&
            <Checkbox
                checked={selected}
                onClick={() => onToggleItem(id)}
            />
            }
        </TableCell>
        {React.Children.map(children, field => (
            <TableCell style={{width: field.props.width, padding: '4px 10px 4px 10px'}}
                       key={`${id}-${field.props.source}`}>
                <span style={{ height: 'auto'}}>
                    {React.cloneElement(field, {
                        record,
                        basePath,
                        resource,
                    })}

                </span>

            </TableCell>
        ))}
    </TableRow>
);

const CardApplicationFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
        <ReferenceInput source="cardApplicationBatchId" reference="cardApplicationBatches" alwaysOn allowEmpty
                        sort={{field: 'name', order: 'ASC'}}
                        perPage={999}
        >
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput source="unitId" reference="baseUnits" alwaysOn allowEmpty
                        filter={{schoolYearId: schoolYears[0].id}}
                        sort={{field: 'name', order: 'ASC'}}
                        perPage={999}
        >
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <CardApplicationStatusInput2 source="status" alwaysOn allowEmpty/>
    </Filter>
);
const CustomDatagridBody = props => <DatagridBody {...props} row={<CustomDatagridRow/>}/>;
const CustomDatagrid = props => <Datagrid {...props} body={<CustomDatagridBody/>}/>;

const PostBulkActionButtons = props => (
    <Fragment>
        <ExportReportButton {...props} />
    </Fragment>
);

export const CardApplicationNeedConfirmList = props => (
    <List filters={<CardApplicationFilter/>} {...props} exporter={false}
          bulkActionButtons={<PostBulkActionButtons/>} sort={{field: 'status', order: 'ASC'}} perPage={25}>
        <CustomDatagrid
            selectable={record => ( record.status === 'B_READY' || record.status === 'L_REVIEW_REJECTED')}>
            <TextField source="id"/>
            <ImageUploadWrapperWithRefresh {...props} url={'/api/photo/cardApplication/'} width={'20%'} label={'Hình thẻ'}/>
            <TextField source="existCustomerId"/>
            <TextField source="displayPhone"/>
            <TextField source="customerName"/>
            <DateTimeField source="dob" format="DD-MM-YYYY"/>
            <GenderField source="gender"/>
            <ReferenceField source="unitId" reference="baseUnits">
                <TextField source="name"/>
            </ReferenceField>
            <CardApplicationStatusField source="status"/>
            <TextField source="reason"/>
            <CardApplicationActions width={'6%'}/>
        </CustomDatagrid>
    </List>
);

export default CardApplicationNeedConfirmList
