import React from 'react';
import {connect} from 'react-redux';
import env from "../env";
import {refreshView} from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import {downloadFile} from "./FileDownload";


const styles = {
    button: {
        margin: 1,
        width: 180
    },
}

class DownloadFileButton extends React.Component {
    state = {
        id: null,
        basePath: '',
        fileExtension: '.xlsx',
    };

    constructor(props) {
        super(props);
        if (props.record) {
            this.state.id = props.record.id;
            this.state.basePath = props.basePath;
            const extension = props.extension;
            if (extension) {
                this.state.fileExtension = extension
            }
        }
    }

    handleClick = (e) => {
        e.stopPropagation();
        downloadFile(this.getDownloadUrl(), this.state.id + this.state.fileExtension)
    };

    getDownloadUrl() {
        return `${env.baseURL}/api` + this.state.basePath + '/getContent?id=' + this.state.id;
    };

    render() {
        return (
            <Button color="primary" onClick={this.handleClick}>
                <GetAppIcon/> File
            </Button>
        )
    }

}

export default connect(null, {refreshView})(DownloadFileButton);
