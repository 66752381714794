import React from 'react';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid'
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    CheckboxGroupInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SelectInput,
    TextInput,
    translate
} from 'react-admin';

const styles = {
    checkboxGroup: {
        // background: 'red',
        "& > fieldset > div ": {
            flexDirection: 'column',
        }
    }
};

const userTypes = [
    {id: 'INTERNAL', name: 'WEB'},
    {id: 'CUSTOMER', name: 'APP'},
];

const UserFields = ({classes, ...props}) => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="userName" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="fullName" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput type="password" source="password" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="enabled" {...props} />
        </Grid>

        <Grid item xs={12} sm={6}>
            <ReferenceInput source="customerId" reference="customers" {...props} allowEmpty>
                <AutocompleteInput optionText="displayName"/>
            </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="defaultAccessDeviceId" reference="accessDevices" {...props} allowEmpty>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6}>
            <SelectInput source="userType" choices={userTypes} {...props} validate={required()}/>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.checkboxGroup}>
            <ReferenceArrayInput source="kiotConfigIds" reference="kiotConfigs"
                                 sort={{field: 'name', order: 'ASC'}} {...props} >
                <AutocompleteArrayInput optionText="name" {...props} />
            </ReferenceArrayInput>
        </Grid>
        <Grid item xs={12} sm={12}>
            <hr style={{width: '100%', marginTop: '20px', marginBottom: '0', color: 'lightblue'}}/>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.checkboxGroup}>
            <ReferenceArrayInput source="roleIds" reference="roles"
                                 sort={{field: 'name', order: 'ASC'}} {...props} >
                <CheckboxGroupInput optionText="description" {...props} />
            </ReferenceArrayInput>
        </Grid>
    </React.Fragment>
)

export default compose(
    translate,
    withStyles(styles)
)(UserFields)
