import React from 'react';
import {
  ChipField,
  Datagrid,
  Filter,
  List,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  TextInput
} from 'react-admin';

const ExemptionFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const ExemptionList = props => (
  <List filters={<ExemptionFilter />} {...props}  exporter={false} >
    <Datagrid rowClick="edit">
      <TextField source="customerId" style={{width: 100}}/>
      <TextField source="customerName" style={{width: 300}}/>
      <ReferenceArrayField reference="exemptionPolicies" source="exemptionPolicyIds">
        <SingleFieldList>
          <ChipField source="name"/>
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
</List>
);

export default ExemptionList
