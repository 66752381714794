import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const type = [
  {id: 'Kinh', name: 'Kinh'},
  {id: 'Tày', name: 'Tày'},
  {id: 'Thái', name: 'Thái'},
  {id: 'Hoa', name: 'Hoa'},
  {id: 'Khơ-me', name: 'Khơ-me'},
  {id: 'Mường', name: 'Mường'},
  {id: 'Nùng', name: 'Nùng'},
  {id: 'Hmông', name: 'Hmông'},
  {id: 'Dao', name: 'Dao'},
  {id: 'Gia-rai', name: 'Gia-rai'},
  {id: 'Ngái', name: 'Ngái'},
  {id: 'Ê-đê', name: 'Ê-đê'},
  {id: 'Ba-na', name: 'Ba-na'},
  {id: 'Xơ-đăng', name: 'Xơ-đăng'},
  {id: 'Sán Chay', name: 'Sán Chay'},
  {id: 'Cơ-ho', name: 'Cơ-ho'},
  {id: 'Chăm', name: 'Chăm'},
  {id: 'Sán Dìu', name: 'Sán Dìu'},
  {id: 'Hrê', name: 'Hrê'},
  {id: 'Mnông', name: 'Mnông'},
  {id: 'Ra-glai', name: 'Ra-glai'},
  {id: 'Xtiêng', name: 'Xtiêng'},
  {id: 'Bru-Vân Kiều', name: 'Bru-Vân Kiều'},
  {id: 'Thổ', name: 'Thổ'},
  {id: 'Giáy', name: 'Giáy'},
  {id: 'Cơ-tu', name: 'Cơ-tu'},
  {id: 'Gié-Triêng', name: 'Gié-Triêng'},
  {id: 'Mạ', name: 'Mạ'},
  {id: 'Khơ-mú', name: 'Khơ-mú'},
  {id: 'Co', name: 'Co'},
  {id: 'Ta-ôi', name: 'Ta-ôi'},
  {id: 'Chơ-ro', name: 'Chơ-ro'},
  {id: 'Kháng', name: 'Kháng'},
  {id: 'Xinh-mun', name: 'Xinh-mun'},
  {id: 'Hà Nhì', name: 'Hà Nhì'},
  {id: 'Chu-ru', name: 'Chu-ru'},
  {id: 'Lào', name: 'Lào'},
  {id: 'La Chi', name: 'La Chi'},
  {id: 'La Ha', name: 'La Ha'},
  {id: 'Phù Lá', name: 'Phù Lá'},
  {id: 'La Hủ', name: 'La Hủ'},
  {id: 'Lự', name: 'Lự'},
  {id: 'Lô Lô', name: 'Lô Lô'},
  {id: 'Chứt', name: 'Chứt'},
  {id: 'Mảng', name: 'Mảng'},
  {id: 'Pà Thẻn', name: 'Pà Thẻn'},
  {id: 'Cơ Lao', name: 'Cơ Lao'},
  {id: 'Cống', name: 'Cống'},
  {id: 'Bố Y', name: 'Bố Y'},
  {id: 'Si La', name: 'Si La'},
  {id: 'Pu Péo', name: 'Pu Péo'},
  {id: 'Brâu', name: 'Brâu'},
  {id: 'Ơ Đu', name: 'Ơ Đu'},
  {id: 'Rơ-măm', name: 'Rơ-măm'},
  {id: 'Người nước ngoài', name: 'Người nước ngoài'}
]

export const NationTypeField = (props) => (
    <SelectField source={props.source} choices={type} {...props} />
)

export const NationTypeInput = (props) => (
    <SelectInput source={props.source} choices={type} {...props} />
)
