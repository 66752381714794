import React, {useState} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {AutocompleteArrayInput, DateInput, ReferenceArrayInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm, showWarningNotify} from '../../components';
import env from '../../env';
import moment from "moment";

const BcDuyetNghi = (props) => {
    const [state, setState] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        eventIds: [],
    });

    const getReportUrl = () => {
        return `${env.baseURL}/reports/bcDuyetNghi?fromDate=` + moment(state.fromDate).format('YYYY-MM-DD')
            + '&toDate=' + moment(state.toDate).format('YYYY-MM-DD')
            + '&eventIds=' + state.eventIds;
    };

    const excelReport = () => {
        if (!state.eventIds || state.eventIds.length === 0) {
            showWarningNotify('Vui lòng chọn sự kiện')
            return;
        }
        downloadFile(getReportUrl(), 'bc_duyet_nghi.xlsx')
    };

    const validate = (values) => {
        const errors = {};
        setState(values);
        return errors;
    };

    return (
        <Card>
            <Title title="resources.bcDuyetNghi.name"/>
            <CardContent>
                <ReportForm params={state}
                            validate={validate}
                            excelReport={excelReport}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="fromDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.toDate" source="toDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ReferenceArrayInput
                                label={"resources.events.name"}
                                source="eventIds"
                                reference="events"
                                perPage={999}
                                filter={{filterPath: 'filterSimple'}}
                                {...props}
                                validate={required()}>
                                <AutocompleteArrayInput/>
                            </ReferenceArrayInput>
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default BcDuyetNghi

