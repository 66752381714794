import AccessDeviceIcon from '@material-ui/icons/ViewList';

import CustomerInfoBatchList from './CustomerInfoBatchList';
import IntroductionFileCreate from './CustomerInfoBatchCreate';

export default {
  list: CustomerInfoBatchList,
  create: IntroductionFileCreate,
  icon: AccessDeviceIcon,
};
