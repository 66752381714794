import React from 'react';
import Grid from '@material-ui/core/Grid'
import {
    AutocompleteArrayInput,
    BooleanInput,
    maxLength,
    ReferenceArrayInput,
    required,
    SelectInput,
    TextInput
} from 'react-admin';
import {schoolYears} from "../../components";
import {EAdmissionSubjectInput} from "../../components/EAdmissionSubject";

const AdmissionSubjectFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={[required(), maxLength(50, 'Không quá 50 ký tự')]} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <ReferenceArrayInput source="categoryIds"
                                 reference="admissionCategories" {...props}
                                 alwaysOn allowEmpty={true}>
                <AutocompleteArrayInput/>
            </ReferenceArrayInput>
        </Grid>
        <Grid item xs={12} sm={6}>
            <EAdmissionSubjectInput source="type" defaultValue={'ELECTIVE'}
                                    validate={[required()]} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="forced" defaultValue={false} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="active" defaultValue={true} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <SelectInput source="schoolYearId" choices={schoolYears} defaultValue={schoolYears[0].id} label='Niên khoá'
                         validate={required()}/>
        </Grid>

    </React.Fragment>
)

export default AdmissionSubjectFields
