import React, {useState} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, ReferenceInput, required, SelectInput, Title} from 'react-admin';
import {downloadFile, ReportForm} from '../../components';
import moment from "moment";
import env from "../../env/env";

const BcNguTruaBT = (props) => {
    const [state, setState] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        eventId: ''
    });
    const [loading, setLoading] = useState(false);

    const excelReport = async () => {
        setLoading(true)
        await downloadFile(`${env.baseURL}/reports/bcNguTruaBanTru?eventId=${state.eventId}&fromDate=${moment(state.fromDate).format('YYYY-MM-DD')}&toDate=${moment(state.toDate).format('YYYY-MM-DD')}`, 'bc_ngu_trua_bt.xlsx')
        setLoading(false)
    };

    const validate = (values) => {
        const errors = {};
        setState(values);
        return errors;
    };

    return (
        <Card>
            <Title title="resources.bcNguTruaBanTru.name"/>
            <CardContent>
                <ReportForm params={state}
                            validate={validate}
                            excelReport={excelReport}
                            isLoading={loading}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="fromDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.toDate" source="toDate"
                                       validate={required()} {...props} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <ReferenceInput source="eventId"
                                            {...props}
                                            validate={required()}
                                            reference="events"
                                            label="resources.events.name"
                                            sort={{field: 'name', order: 'ASC'}}
                                            filter={{filterPath: 'filterSimple', type: 'NAP'}}>
                                <SelectInput optionText="name"/>
                            </ReferenceInput>
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default BcNguTruaBT

