import React from 'react';
import Grid from '@material-ui/core/Grid';
import {ReferenceInput, SelectInput} from 'react-admin';

const privileges = [
    { id: 'READ', name: 'XEM' },
    { id: 'CREATE', name: 'TẠO' },
  { id: 'UPDATE', name: 'SỬA' },
  { id: 'DELETE', name: 'XÓA' },
];

const RolePrivilegesFields = props => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <ReferenceInput source="roleId" reference="roles" allowEmpty {...props} >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Grid>
    <Grid item xs={12} sm={6} >
      <SelectInput source="privilege" choices={privileges} />
    </Grid>
  </React.Fragment>
)

export default RolePrivilegesFields
