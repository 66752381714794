import React, {useEffect, useState} from 'react';
import {Button, FormControl, FormControlLabel, Grid, MenuItem, Select, Switch, Tab, Tabs} from '@material-ui/core'
import {
    ArrayInput,
    DateInput,
    LongTextInput,
    maxLength,
    minLength,
    NumberInput,
    regex,
    required,
    showNotification,
    SimpleFormIterator,
    TextInput,
    translate
} from 'react-admin';
import {schoolYears, showWarningNotify} from "../../components";
import axiosInstance from "../../api/axios";
import {compose} from "redux";
import {connect} from "react-redux";
import withStyles from '@material-ui/core/styles/withStyles';
import {GenderInput} from "../../components/Gender";
import {Redirect} from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import cloneDeep from 'lodash/cloneDeep';
import {InsuranceTypeInput} from "../../components/InsuranceType";
import {Exemption81TypeInput} from "../../components/Exemption81Type";
import {ExemptionNationTypeInput} from "../../components/ExemptionNationType";
import {ReligionTypeInput} from "../../components/ReligionType";
import {NationTypeInput} from "../../components/NationType";
import {NationalityTypeInput} from "../../components/NationalityType";
import {SwimTypeInput} from "../../components/SwimType";

const styles = {
    button: {
        margin: 1,
    },
    selectArray: {
        minWidth: '90%',
    },
    width90: {
        width: '90%',
    },
    label: {fontWeight: 'bold', fontSize: 16},
    cancelBtn: {
        marginLeft: 20
    },
    checkboxGroup: {
        "& > div ": {
            flexDirection: 'column',
        }
    },
    customCheckBox: {
        "& > div > label ": {
            width: '100%'
        }
    }
};

const Address = translate(({prefixField = '', onChange, translate}) => {
    return <Grid container>
        <Grid item xs={12}>
            <span style={styles.label}>{translate(`resources.admissions.fields.${prefixField}`)}</span>
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextInput source={`${prefixField}HouseNo`} label={`resources.admissions.fields.houseNo`}
                       onChange={onChange(`${prefixField}HouseNo`)} style={styles.width90}
                       validate={[required(), maxLength(50, 'Không quá 50 ký tự')]}/>
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextInput source={`${prefixField}Street`} label="resources.admissions.fields.street"
                       onChange={onChange(`${prefixField}Street`)} style={styles.width90}
                       validate={[required(), maxLength(50, 'Không quá 50 ký tự')]}/>
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextInput source={`${prefixField}Cluster`} label="resources.admissions.fields.cluster"
                       onChange={onChange(`${prefixField}Cluster`)} style={styles.width90}
                       validate={[required(), maxLength(50, 'Không quá 50 ký tự')]}/>
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextInput source={`${prefixField}Ward`} label="resources.admissions.fields.ward"
                       onChange={onChange(`${prefixField}Ward`)} style={styles.width90}
                       validate={[required(), maxLength(50, 'Không quá 50 ký tự')]}/>
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextInput source={`${prefixField}District`} label="resources.admissions.fields.district"
                       onChange={onChange(`${prefixField}District`)} style={styles.width90}
                       validate={[required(), maxLength(50, 'Không quá 50 ký tự')]}/>
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextInput source={`${prefixField}City`} label="resources.admissions.fields.city"
                       onChange={onChange(`${prefixField}City`)} style={styles.width90}
                       validate={[required(), maxLength(50, 'Không quá 50 ký tự')]}/>
        </Grid>
    </Grid>
})

const AdmissionFields = props => {
    const [values, setValues] = React.useState({
        isRedirect: false,
        refreshList: false,
        openWhiteList: false,
        units: [],
        selectedElectives: []
    });
    const {
        defaultValue,
        save,
        allProgramServices,
        programServices,
        setProgramServices,
        schoolServices,
        setSchoolServices,
        selectedElectives, setSelectedElectives,
        selectedGraduations, setSelectedGraduations,
        selectedPhysicals, setSelectedPhysicals,
        useCategory, categories,
        selectedCategory,
        setSelectedCategory,
        regisGraduation,
        regisExemption,
        templateVersion,
        selectedJobs, setSelectedJobs,

    } = props;

    const [electives, setElectives] = useState([])
    const [physicals, setPhysicals] = useState([])
    const [jobs, setJobs] = useState([])
    const [graduations, setGraduations] = useState([])

    const [tabIndex, setTabIndex] = React.useState(0);

    useEffect(() => {
        if (!defaultValue.isRedirect) {
            setValues({...values})
        } else {
            setValues({isRedirect: true, refreshList: true});
        }
    }, [defaultValue.isRedirect]);

    useEffect(() => {
        if (defaultValue.ready) {
            loadElectives();
        }
    }, [defaultValue.ready]);

    const loadElectives = () => {
        axiosInstance.get(`/api/admissionSubjects/search/findBySchoolYearAndActive?schoolYearId=${schoolYears[0].id}`).then((rs) => {
            if (rs.data) {
                const content = rs.data.content
                if (templateVersion == 'GDTX') {
                    if (useCategory) {
                        if (defaultValue.categoryId) {
                            const arr1 = categories.filter(c => c.id == defaultValue.categoryId)
                            if (arr1.length > 0) {
                                const category = arr1[0]
                                setSelectedCategory(category)
                                const subjects = category.subjects
                                setElectives(subjects.filter(s => !s.forced))
                            }
                        }
                    } else {
                        const arr1 = content.filter(p => p.type === 'ELECTIVE')
                        setElectives(arr1)
                    }

                    const arr2 = content.filter(p => p.type === 'JOB')
                    setJobs(arr2)

                } else {
                    if (useCategory) {
                        if (defaultValue.categoryId) {
                            const arr1 = categories.filter(c => c.id == defaultValue.categoryId)
                            if (arr1.length > 0) {
                                const category = arr1[0]
                                setSelectedCategory(category)
                                const subjects = category.subjects
                                setElectives(subjects.filter(s => !s.forced))
                            }
                        }
                    } else {
                        const arr1 = content.filter(p => p.type === 'ELECTIVE')
                        setElectives(arr1)
                    }

                    const arr2 = content.filter(p => p.type === 'PHYSICAL')
                    setPhysicals(arr2)

                    const arr3 = content.filter(p => p.type === 'GRADUATION')
                    for (let i = 0; i < arr3.length; i++) {
                        arr3[i].admissionSubjectId = arr3[i].id
                    }
                    for (let i = 0; i < defaultValue.selectedElectives?.length; i++) {
                        const e = defaultValue.selectedElectives[i]
                        if (e.name) {
                            e.type = 'ELECTIVE'
                            e.forced = false
                            arr3.push(e)
                        }
                    }
                    setGraduations(arr3)
                }
            }
        })
    }
    const handleChangeCategory = async (event) => {
        const itemValue = event.target.value
        const prev = selectedCategory.id
        if (prev != itemValue) {
            const category = categories.filter(c => c.id == itemValue)[0]
            if (category) {
                setSelectedCategory(category)
                const subjects = category.subjects
                setElectives(subjects)
                const forcedSubjects = subjects.filter(s => s.forced)
                const removeSubjectIds = []
                const newSelectedElectives = [...selectedElectives]
                const graduations2 = [...graduations]
                for (let i = 0; i < selectedElectives.length; i++) {
                    const elective = selectedElectives[i]
                    if (elective.admissionSubjectId) {
                        removeSubjectIds.push(selectedElectives[i].admissionSubjectId)
                    }
                    if (i < forcedSubjects.length) {
                        const clone = cloneDeep(forcedSubjects[i])
                        clone.type = 'ELECTIVE'
                        clone.admissionSubjectId = itemValue
                        clone.displayIndex = i + 1
                        newSelectedElectives[i] = clone

                        const cloneGraduation = cloneDeep(forcedSubjects[i])
                        cloneGraduation.type = 'ELECTIVE'
                        cloneGraduation.admissionSubjectId = itemValue
                        cloneGraduation.forced = false
                        const idx = graduations2.findIndex(p => p.name == clone.name)
                        if (idx < 0) {
                            graduations2.push(cloneGraduation)
                        }
                    } else {
                        newSelectedElectives[i] = {
                            admissionSubjectId: '',
                            type: 'ELECTIVE',
                            displayIndex: i + 1
                        }
                    }
                }
                setSelectedElectives(newSelectedElectives)
                if (removeSubjectIds.length > 0) {
                    const newGraduations = [...selectedGraduations]
                    for (let i = 0; i < removeSubjectIds.length; i++) {
                        const selectedGraduationIdx = selectedGraduations.findIndex(p => p.admissionSubjectId == removeSubjectIds[i])
                        if (selectedGraduationIdx >= 0) {
                            newGraduations[selectedGraduationIdx] = {
                                ...newGraduations[selectedGraduationIdx],
                                admissionSubjectId: '',
                                name: ''
                            }
                        }
                    }
                    setSelectedGraduations(newGraduations)
                    setGraduations(graduations2.filter(p => removeSubjectIds.indexOf(p.admissionSubjectId) < 0))
                    setProgramServices(programServices.filter(p => !p.admissionSubjectId || removeSubjectIds.indexOf(p.admissionSubjectId) < 0))
                } else {
                    setGraduations(graduations2.filter(p => removeSubjectIds.indexOf(p.admissionSubjectId) < 0))
                }
            }
        }
    }

    const handleChangeElective = (event, index) => {
        const itemValue = event.target.value
        handleChangeElectiveByValue(itemValue, index, electives)
    }

    const handleChangeElectiveByValue = (itemValue, index, electives) => {
        const prev = selectedElectives[index]
        if (prev.admissionSubjectId != itemValue) {
            const idx1 = selectedElectives.findIndex(p => p.admissionSubjectId && p.admissionSubjectId === itemValue)
            if (idx1 >= 0) {
                showWarningNotify('Vui lòng đăng ký môn tự chọn khác nhau.')
                return
            }
            const chose = electives.filter(e => e.id == itemValue)[0]
            const clone = cloneDeep(chose)
            clone.type = 'ELECTIVE'
            clone.admissionSubjectId = itemValue
            clone.displayIndex = index + 1
            const existIdx = graduations.findIndex(p => p.name == clone.name)
            if (existIdx < 0) {
                const cloneGraduation = cloneDeep(chose)
                cloneGraduation.type = 'ELECTIVE'
                cloneGraduation.admissionSubjectId = itemValue
                cloneGraduation.forced = false
                const idx = graduations.findIndex(p => p.name == prev.name)
                if (idx >= 0) {
                    graduations[idx] = cloneGraduation
                } else {
                    graduations.push(cloneGraduation)
                }
            }
            const newArr1 = [...selectedElectives]
            newArr1[index] = clone
            setSelectedElectives(newArr1)

            const arr5 = [...selectedGraduations]
            const selectedGraduationIdx = arr5.findIndex(p => !p.admissionSubjectId && p.admissionSubjectId == prev.admissionSubjectId)
            if (selectedGraduationIdx >= 0) {
                arr5[selectedGraduationIdx] = {
                    ...arr5[selectedGraduationIdx],
                    admissionSubjectId: '',
                    name: ''
                }
                setSelectedGraduations(arr5)
            }
            changeDependentProgramServices([...newArr1, ...arr5]);
        }
    }
    const handleChangePhysical = (event, index) => {
        const itemValue = event.target.value
        const prev = selectedPhysicals[index]
        if (prev.admissionSubjectId != itemValue) {
            const idx1 = selectedPhysicals.findIndex(p => p.admissionSubjectId && p.admissionSubjectId == itemValue)
            if (idx1 >= 0) {
                showWarningNotify('Vui lòng đăng ký môn thể chất khác nhau.')
                return
            }
            const chose = physicals.filter(e => e.id == itemValue)[0]
            const clone = cloneDeep(chose)
            clone.type = 'PHYSICAL'
            clone.admissionSubjectId = itemValue
            clone.displayIndex = index + 1
            const newArr1 = [...selectedPhysicals]
            newArr1[index] = clone
            setSelectedPhysicals(newArr1)
        }
    }
    const handleChangeJob = (event, index) => {
        const itemValue = event.target.value
        const prev = selectedJobs[index]
        if (prev.admissionSubjectId != itemValue) {
            const idx1 = selectedPhysicals.findIndex(p => p.admissionSubjectId && p.admissionSubjectId == itemValue)
            if (idx1 >= 0) {
                showWarningNotify('Vui lòng đăng ký nghề khác nhau.')
                return
            }
            const chose = jobs.filter(e => e.id == itemValue)[0]
            const clone = cloneDeep(chose)
            clone.type = 'JOB'
            clone.admissionSubjectId = itemValue
            clone.displayIndex = index + 1
            const newArr1 = [...selectedJobs]
            newArr1[index] = clone
            setSelectedJobs(newArr1)
        }
    }
    const handleChangeGraduation = (event, index) => {
        const itemValue = event.target.value
        const prev = selectedGraduations[index]
        if (prev.admissionSubjectId != itemValue) {
            const idx1 = selectedGraduations.findIndex(p => p.admissionSubjectId && p.admissionSubjectId == itemValue)
            if (idx1 >= 0) {
                showWarningNotify('Vui lòng đăng ký môn tốt nghiệp khác nhau.')
                return
            }
            const chose = graduations.filter(e => e.admissionSubjectId == itemValue)[0]
            if (chose) {
                const clone = cloneDeep(chose)
                clone.admissionSubjectId = itemValue
                clone.displayIndex = index + 1
                const newArr1 = [...selectedGraduations]
                newArr1[index] = clone
                setSelectedGraduations(newArr1)
                changeDependentProgramServices([...selectedElectives, ...newArr1]);
            }
        }
    }

    function changeDependentProgramServices(arr) {
        const serviceArr = allProgramServices.filter(p => !p.admissionSubjectId || arr.findIndex(s => p.admissionSubjectId == s.admissionSubjectId) >= 0)
        if (serviceArr.length > 0) {
            let existArr = [...programServices]
            const newArr = []
            for (let i = 0; i < serviceArr.length; i++) {
                const ser = serviceArr[i]
                const arr2 = existArr.filter(s => (!s.admissionSubjectId && s.id == ser.id) && (ser.admissionSubjectId == s.admissionSubjectId))
                if (arr2.length == 0) {
                    ser.selected = ser.defaultValue
                    newArr.push(ser)
                } else {
                    newArr.push(arr2[0])
                }
            }
            setProgramServices(newArr)
        } else {
            setProgramServices([])
        }
    }

    const changeProgramService = (index) => {
        const arr = [...programServices]
        arr[index].selected = !arr[index].selected
        setProgramServices(arr)
    }
    const changeSchoolService = (index) => {
        const arr = [...schoolServices]
        arr[index].selected = !arr[index].selected
        setSchoolServices(arr)
    }
    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
    };

    function cancel() {
        setValues({isRedirect: true, refreshList: false});
    }

    if (values.isRedirect) {
        return <Redirect to={'/admissions?refreshView=' + values.refreshList}/>;
    }
    return (
        <React.Fragment>
            <Grid item xs={12} sm={12}>
                <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="basic tabs example">
                    <Tab label="Tuyển sinh"/>
                    <Tab label="Lý lịch"/>
                    {templateVersion != 'GDTX' &&
                    <Tab label="Năng khiếu"/>
                    }
                </Tabs>
            </Grid>

            {tabIndex === 0 &&
            <React.Fragment>
                <Grid item xs={12} sm={12}>
                    <span style={styles.label}>A) Thông tin học sinh</span>
                </Grid>

                <Grid item xs={12} sm={4}>
                    {templateVersion == 'GDTX' ?
                        <TextInput source="sbd" label="resources.admissions.fields.sbd"
                                   onChange={props.onChange('sbd')}/>
                        :
                        <TextInput source="sbd" label="resources.admissions.fields.sbd" disabled/>
                    }
                </Grid>
                <Grid item xs={12} sm={4}>
                    <NumberInput source="stt" label="resources.admissions.fields.stt" onChange={props.onChange('stt')}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="customerName" label="resources.admissions.fields.customerName" disabled/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="dob" label="resources.admissions.fields.dob" disabled/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <GenderInput source="gender" disabled/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="monVan" label="resources.admissions.fields.monVan" disabled/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="monToan" label="resources.admissions.fields.monToan" disabled/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="monAnh" label="resources.admissions.fields.monAnh" disabled/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <span style={styles.label}>B) Các môn tự chọn</span>
                </Grid>
                {useCategory ?
                    <>
                        <FormControl fullWidth>
                            <span>Ban học*</span>
                            <Select
                                labelId={"mon-4"}
                                id={"ban-hoc-select"}
                                value={selectedCategory.id}
                                label={false}
                                onChange={(e) => handleChangeCategory(e)}
                            >
                                {categories.map((el, index) => (
                                    <MenuItem value={el.id}>{el.name}</MenuItem>
                                ))
                                }
                            </Select>
                        </FormControl>
                        {selectedCategory && selectedCategory.id && selectedElectives.map((item, index) => (
                            <Grid item xs={12} sm={6}>
                                {item.forced ?
                                    <span>{'Môn tự chọn ' + (index + 1) + ': ' + item.name}</span>
                                    :
                                    <FormControl fullWidth>
                                        <span>{'Môn tự chọn ' + (index + 1) + "*"}</span>
                                        <Select
                                            id={"mon-tu-chon-select" + index}
                                            value={item.admissionSubjectId}
                                            label={false}
                                            onChange={(e) => handleChangeElective(e, index)}
                                        >
                                            {electives.map((el, index) => (
                                                <MenuItem value={el.id}>{el.name}</MenuItem>
                                            ))
                                            }
                                        </Select>
                                    </FormControl>
                                }
                            </Grid>
                        ))
                        }
                    </>
                    :
                    <>
                        {selectedElectives.map((item, index) => (
                            <Grid key={index} item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <span>{'Môn tự chọn ' + (index + 1) + "*"}</span>
                                    <Select
                                        id={"mon-tu-chon-select" + index}
                                        value={item.admissionSubjectId}
                                        label={false}
                                        onChange={(e) => handleChangeElective(e, index)}
                                    >
                                        {electives.map((el, index) => (
                                            <MenuItem value={el.id}>{el.name}</MenuItem>
                                        ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        ))
                        }
                    </>

                }

                {templateVersion == 'GDTX' ?
                    <>
                        <Grid item xs={12} sm={12}>
                            <span style={styles.label}>C) Các môn Trung cấp nghề</span>
                        </Grid>
                        {selectedJobs.map((item, index) => (
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <span>{'Môn nghề ' + (index + 1) + "*"}</span>
                                    <Select
                                        id={"mon-nghe-select" + index}
                                        value={item.admissionSubjectId}
                                        label={false}
                                        onChange={(e) => handleChangeJob(e, index)}
                                    >
                                        {jobs.map((el, index) => (
                                            <MenuItem value={el.id}>{el.name}</MenuItem>
                                        ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        ))
                        }
                    </>
                    :
                    <>
                        <Grid item xs={12} sm={12}>
                            <span style={styles.label}>C) Môn học Giáo dục thể chất tự chọn</span>
                        </Grid>
                        {selectedPhysicals.map((item, index) => (
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <span>{'Môn thể chất ' + (index + 1) + "*"}</span>
                                    <Select
                                        id={"mon-the-chat-select" + index}
                                        value={item.admissionSubjectId}
                                        label={false}
                                        onChange={(e) => handleChangePhysical(e, index)}
                                    >
                                        {physicals.map((el, index) => (
                                            <MenuItem value={el.id}>{el.name}</MenuItem>
                                        ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        ))
                        }
                        {regisGraduation &&
                        <>
                            <Grid item xs={12} sm={12}>
                                <span style={styles.label}>D) Các môn dự kiến thi Tốt nghiệp</span>
                            </Grid>
                            {selectedGraduations.map((item, index) => (
                                <Grid item xs={12} sm={6}>
                                    {item.forced ?
                                        <span>{'Môn tốt nghiệp ' + (index + 1) + ': ' + item.name}</span>
                                        :
                                        <FormControl fullWidth>
                                            <span>{'Môn tốt nghiệp ' + (index + 1) + "*"}</span>
                                            <Select
                                                id={"mon-tot-nghiep-select" + index}
                                                value={item.admissionSubjectId}
                                                label={false}
                                                onChange={(e) => handleChangeGraduation(e, index)}
                                            >
                                                {graduations.map((el, index) => (
                                                    el.forced ? <></> :
                                                        <MenuItem value={el.admissionSubjectId}>{el.name}</MenuItem>
                                                ))
                                                }
                                            </Select>
                                        </FormControl>
                                    }
                                </Grid>
                            ))
                            }
                        </>
                        }
                        <Grid item xs={12} sm={12}>
                        <span
                            style={styles.label}>{(regisGraduation ? 'E' : 'D') + ') Đăng ký chương trình với nhà trường'}</span>
                        </Grid>
                        {programServices.map((item, index) => (
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    labelPlacement="start"
                                    control={<Switch checked={item.selected}
                                                     onChange={() => !item.forced && changeProgramService(index)}/>}
                                    label={item.name}
                                />
                            </Grid>
                        ))
                        }
                        <Grid item xs={12} sm={12}>
                                <span
                                    style={styles.label}>{(regisGraduation ? 'F' : 'E') + ') Các dịch vụ giáo dục'}</span>
                        </Grid>
                        {schoolServices.map((item, index) => (
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    labelPlacement="start"
                                    control={<Switch checked={item.selected}
                                                     onChange={() => !item.forced && changeSchoolService(index)}/>}
                                    label={item.name}
                                />
                            </Grid>
                        ))
                        }
                    </>
                }
            </React.Fragment>
            }
            {tabIndex === 1 &&
            <React.Fragment>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <span
                                style={styles.label}>{props.translate(`resources.admissions.fields.studentInfo`)}</span>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="customerName" label="resources.admissions.fields.customerName" disabled/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <GenderInput source="gender" disabled/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="dob" label="resources.admissions.fields.dob" disabled/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="pob" label="resources.admissions.fields.pob"
                                       onChange={props.onChange('pob')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="documentNumber" label="resources.admissions.fields.documentNumber"
                                       onChange={props.onChange('documentNumber')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <DateInput source="documentIssueDate"
                                       label="resources.admissions.fields.documentIssueDate"
                                       onChange={(e, v) => props.onDirectChange('documentIssueDate', v)}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="documentOrigin" label="resources.admissions.fields.documentOrigin"
                                       onChange={props.onChange('documentOrigin')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NationTypeInput source="nation" label="resources.admissions.fields.nation" required
                                             onChange={(e, v) => props.onDirectChange('nation', v)}
                                             style={styles.width90}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NationalityTypeInput source="nationality" label="resources.admissions.fields.nationality"
                                                  onChange={(e, v) => props.onDirectChange('nationality', v)}
                                                  style={styles.width90}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <ReligionTypeInput source="religion" label="resources.admissions.fields.religion"
                                               onChange={(e, v) => props.onDirectChange('religion', v)}
                                               style={styles.width90}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="customerPhone" label="resources.admissions.fields.customerPhone"
                                       onChange={props.onChange('customerPhone')} style={styles.width90}
                                       validate={[maxLength(10, 'Không quá 10 ký tự'), minLength(10, 'Không ít hơn 10 ký tự'), regex(/^(\d+)$/, 'SĐT không hợp lệ')]}/>
                        </Grid>
                        {templateVersion === 'GDTX' ? <Grid item xs={12} sm={4}>
                                <LongTextInput source="address" label="resources.admissions.fields.address" disabled
                                               rows={5} style={{maxWidth: '90%'}}/>
                            </Grid>
                            :
                            <Grid item xs={12} sm={4}>
                                <TextInput source="address" label="resources.admissions.fields.address"
                                           onChange={props.onChange('address')} style={styles.width90}
                                           validate={maxLength(255, 'Không quá 255 ký tự')}/>
                            </Grid>}
                    </Grid>
                </Grid>

                {templateVersion === 'GDTX' && <Grid item xs={12}>
                    <Address onChange={props.onChange} prefixField='permanentAddress'/>
                    <Address onChange={props.onChange} prefixField='temporaryAddress'/>
                </Grid>}

                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <span
                                style={styles.label}>{props.translate(`resources.admissions.fields.fatherInfo`)}</span>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="fatherName" label="resources.admissions.fields.fatherName"
                                       onChange={props.onChange('fatherName')} style={styles.width90}
                                       validate={maxLength(50, 'Không quá 50 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="fatherPhone" label="resources.admissions.fields.fatherPhone"
                                       onChange={props.onChange('fatherPhone')} style={styles.width90}
                                       validate={[maxLength(10, 'Không quá 10 ký tự'), minLength(10, 'Không ít hơn 10 ký tự'), regex(/^(\d+)$/, 'SĐT không hợp lệ')]}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="fatherDob" label="resources.admissions.fields.fatherDob"
                                       onChange={props.onChange('fatherDob')} style={styles.width90}
                                       validate={maxLength(6, 'Không quá 6 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="fatherJob" label="resources.admissions.fields.fatherJob"
                                       onChange={props.onChange('fatherJob')} style={styles.width90}
                                       validate={maxLength(50, 'Không quá 50 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="fatherTitle" label="resources.admissions.fields.fatherTitle"
                                       onChange={props.onChange('fatherTitle')} style={styles.width90}
                                       validate={maxLength(50, 'Không quá 50 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="fatherCompany" label="resources.admissions.fields.fatherCompany"
                                       onChange={props.onChange('fatherCompany')} style={styles.width90}
                                       validate={maxLength(100, 'Không quá 100 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="fatherCompanyAddress"
                                       label="resources.admissions.fields.fatherCompanyAddress"
                                       onChange={props.onChange('fatherCompanyAddress')} style={styles.width90}
                                       validate={maxLength(255, 'Không quá 255 ký tự')}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container>
                        <Grid container xs={12}>
                            <span
                                style={styles.label}>{props.translate(`resources.admissions.fields.motherInfo`)}</span>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="motherName" label="resources.admissions.fields.motherName"
                                       onChange={props.onChange('motherName')} style={styles.width90}
                                       validate={[maxLength(50, 'Không quá 50 ký tự')]}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="motherDob" label="resources.admissions.fields.motherDob"
                                       onChange={props.onChange('motherDob')} style={styles.width90}
                                       validate={maxLength(6, 'Không quá 6 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="motherPhone" label="resources.admissions.fields.motherPhone"
                                       onChange={props.onChange('motherPhone')} style={styles.width90}
                                       validate={[maxLength(10, 'Không quá 10 ký tự'), minLength(10, 'Không ít hơn 10 ký tự'), regex(/^(\d+)$/, 'SĐT không hợp lệ')]}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="motherJob" label="resources.admissions.fields.motherJob"
                                       onChange={props.onChange('motherJob')} style={styles.width90}
                                       validate={maxLength(50, 'Không quá 50 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="motherTitle" label="resources.admissions.fields.motherTitle"
                                       onChange={props.onChange('motherTitle')} style={styles.width90}
                                       validate={maxLength(50, 'Không quá 50 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="motherCompany" label="resources.admissions.fields.motherCompany"
                                       onChange={props.onChange('motherCompany')} style={styles.width90}
                                       validate={maxLength(100, 'Không quá 100 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="motherCompanyAddress"
                                       label="resources.admissions.fields.motherCompanyAddress"
                                       onChange={props.onChange('motherCompanyAddress')} style={styles.width90}
                                       validate={maxLength(255, 'Không quá 255 ký tự')}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <span
                                style={styles.label}>{props.translate(`resources.admissions.fields.guardianInfo`)}</span>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextInput source="guardianName" label="resources.admissions.fields.guardianName"
                                       onChange={props.onChange('guardianName')} style={styles.width90}
                                       validate={maxLength(50, 'Không quá 50 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="guardianDob" label="resources.admissions.fields.guardianDob"
                                       onChange={props.onChange('guardianDob')} style={styles.width90}
                                       validate={maxLength(6, 'Không quá 6 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="guardianPhone" label="resources.admissions.fields.guardianPhone"
                                       onChange={props.onChange('guardianPhone')} style={styles.width90}
                                       validate={[maxLength(10, 'Không quá 10 ký tự'), minLength(10, 'Không ít hơn 10 ký tự'), regex(/^(\d+)$/, 'SĐT không hợp lệ')]}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="guardianJob" label="resources.admissions.fields.guardianJob"
                                       onChange={props.onChange('guardianJob')} style={styles.width90}
                                       validate={maxLength(50, 'Không quá 50 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="guardianTitle" label="resources.admissions.fields.guardianTitle"
                                       onChange={props.onChange('guardianTitle')} style={styles.width90}
                                       validate={maxLength(50, 'Không quá 50 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="guardianCompany" label="resources.admissions.fields.guardianCompany"
                                       onChange={props.onChange('guardianCompany')} style={styles.width90}
                                       validate={maxLength(100, 'Không quá 100 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="guardianCompanyAddress"
                                       label="resources.admissions.fields.guardianCompanyAddress"
                                       onChange={props.onChange('guardianCompanyAddress')} style={styles.width90}
                                       validate={maxLength(255, 'Không quá 255 ký tự')}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <span style={styles.label}>Thông tin khác</span>
                </Grid>
                {templateVersion != 'GDTX' &&
                <>
                    <Grid item xs={12} sm={3}>
                        <SwimTypeInput source="canSwim"
                                       label="resources.admissions.fields.canSwim"
                                       onChange={(e, v) => props.onDirectChange('canSwim', v)}
                                       style={styles.width90}/>
                    </Grid>
                    {defaultValue.canSwim == 'Có' ?
                        <>
                            <Grid item xs={12} sm={3}>
                                <TextInput source="swimCertificate"
                                           label="resources.admissions.fields.swimCertificate"
                                           onChange={props.onChange('swimCertificate')} style={styles.width90}
                                           validate={[required(), maxLength(50, 'Không quá 50 ký tự')]}/>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextInput source="swimPlaceOfCertificate"
                                           label="resources.admissions.fields.swimPlaceOfCertificate"
                                           onChange={props.onChange('swimPlaceOfCertificate')}
                                           style={styles.width90}
                                           validate={[required(), maxLength(50, 'Không quá 50 ký tự')]}/>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <DateInput source="swimDateOfCertificate"
                                           label="resources.admissions.fields.swimDateOfCertificate"
                                           validate={[required()]}
                                           onChange={(e, v) => props.onDirectChange('swimDateOfCertificate', v)}/>
                            </Grid>
                        </>
                        :
                        <>
                            <Grid item xs={12} sm={4}>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            </Grid>
                        </>
                    }
                </>
                }

                {templateVersion == 'GDTX' ?
                    <>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="oldClazz" label="resources.admissions.fields.oldClazz"
                                       onChange={props.onChange('oldClazz')} style={styles.width90}
                                       validate={maxLength(50, 'Không quá 50 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="oldSchoolYear" label="resources.admissions.fields.oldSchoolYear"
                                       onChange={props.onChange('oldSchoolYear')} style={styles.width90}
                                       validate={maxLength(50, 'Không quá 50 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="oldSchoolName" label="resources.admissions.fields.oldSchoolName"
                                       onChange={props.onChange('oldSchoolName')} style={styles.width90}
                                       validate={maxLength(50, 'Không quá 100 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="oldSchoolDistrict"
                                       label="resources.admissions.fields.oldSchoolDistrict"
                                       onChange={props.onChange('oldSchoolDistrict')} style={styles.width90}
                                       validate={maxLength(50, 'Không quá 50 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="oldSchoolCity" label="resources.admissions.fields.oldSchoolCity"
                                       onChange={props.onChange('oldSchoolCity')} style={styles.width90}
                                       validate={maxLength(50, 'Không quá 50 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="hocLuc" label="resources.admissions.fields.hocLuc"
                                       onChange={props.onChange('hocLuc')} style={styles.width90}
                                       validate={maxLength(50, 'Không quá 50 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextInput source="hanhKiem" label="resources.admissions.fields.hanhKiem"
                                       onChange={props.onChange('hanhKiem')} style={styles.width90}
                                       validate={maxLength(50, 'Không quá 50 ký tự')}/>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item xs={12} sm={3}>
                            <InsuranceTypeInput source="otherInsurance"
                                                label="resources.admissions.fields.otherInsurance"
                                                onChange={(e, v) => props.onDirectChange('otherInsurance', v)}
                                                style={styles.width90}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            {defaultValue.otherInsurance && defaultValue.otherInsurance != 'Không có' &&
                            <TextInput source="insuranceNumber" label="resources.admissions.fields.insuranceNumber"
                                       onChange={props.onChange('insuranceNumber')} style={styles.width90}
                                       validate={maxLength(20, 'Không quá 20 ký tự')}/>
                            }
                        </Grid>
                    </>
                }
                {regisExemption &&
                <>
                    <Grid item xs={12} sm={4}>
                        <Exemption81TypeInput source="exemption81" label="resources.admissions.fields.exemption81"
                                              onChange={(e, v) => props.onDirectChange('exemption81', v)}
                                              style={styles.width90}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <ExemptionNationTypeInput source="exemptionNation"
                                                  label="resources.admissions.fields.exemptionNation"
                                                  onChange={(e, v) => props.onDirectChange('exemptionNation', v)}
                                                  style={styles.width90}/>
                    </Grid>
                </>

                }

            </React.Fragment>
            }
            {tabIndex === 2 &&
            <React.Fragment>
                <Grid item xs={12} sm={12}>
                    <span style={styles.label}>Văn hoá - Nghệ thuật</span>
                </Grid>
                <ArrayInput source="cultures" label={false}>
                    <SimpleFormIterator inline disableAdd={true} disableClear={true} disableRemove={true}
                                        disableReordering={true}>
                        <TextInput source="name" label="resources.admissions.fields.giftedName2"
                                   style={styles.width90}
                                   onChange={props.handleChangeArray('cultures', 'name', 0)}
                                   validate={maxLength(50, 'Không quá 200 ký tự')}/>
                        <TextInput source="achievement" label="resources.admissions.fields.giftedAchievement"
                                   style={styles.width90}
                                   onChange={props.handleChangeArray('cultures', 'achievement', 0)}
                                   validate={maxLength(50, 'Không quá 200 ký tự')}/>
                        <TextInput source="note" label="resources.admissions.fields.giftedNote"
                                   style={styles.width90}
                                   onChange={props.handleChangeArray('cultures', 'note', 0)}
                                   validate={maxLength(50, 'Không quá 200 ký tự')}/>
                    </SimpleFormIterator>
                </ArrayInput>
                <Grid item xs={12} sm={12}>
                    <span style={styles.label}>Thể dục thể thao</span>
                </Grid>
                <ArrayInput source="sports" label={false}>
                    <SimpleFormIterator inline disableAdd={true} disableClear={true} disableRemove={true}
                                        disableReordering={true}>
                        <TextInput source="name" label="resources.admissions.fields.giftedName"
                                   style={styles.width90}
                                   onChange={props.handleChangeArray('sports', 'name', 0)}
                                   validate={maxLength(50, 'Không quá 200 ký tự')}/>
                        <TextInput source="achievement" label="resources.admissions.fields.giftedAchievement"
                                   style={styles.width90}
                                   onChange={props.handleChangeArray('sports', 'achievement', 0)}
                                   validate={maxLength(50, 'Không quá 200 ký tự')}/>
                        <TextInput source="note" label="resources.admissions.fields.giftedNote"
                                   style={styles.width90}
                                   onChange={props.handleChangeArray('sports', 'note', 0)}
                                   validate={maxLength(50, 'Không quá 200 ký tự')}/>
                    </SimpleFormIterator>
                </ArrayInput>

                <Grid item xs={12} sm={12}>
                    <span style={styles.label}>Công tác Đoàn đội - Phong trào học sinh</span>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="team.name" label="resources.admissions.fields.team"
                               onChange={props.onChange('team.name')} style={styles.width90}
                               validate={maxLength(50, 'Không quá 200 ký tự')}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="team.achievement" label="resources.admissions.fields.giftedAchievement"
                               onChange={props.onChange('team.achievement')} style={styles.width90}
                               validate={maxLength(50, 'Không quá 200 ký tự')}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="team.note" label="resources.admissions.fields.giftedNote"
                               onChange={props.onChange('team.note')} style={styles.width90}
                               validate={maxLength(50, 'Không quá 200 ký tự')}/>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <span style={styles.label}>Năng khiếu khác</span>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="otherGifted.name" label="resources.admissions.fields.otherGifted"
                               onChange={props.onChange('otherGifted.name')} style={styles.width90}
                               validate={maxLength(50, 'Không quá 200 ký tự')}/>
                </Grid>
            </React.Fragment>
            }

            <Grid item xs={12} sm={12} style={{marginTop: 15}}>
                <Button variant="contained"
                        color="primary"
                        style={styles.button}
                        disabled={defaultValue.saveDisabled}
                        onClick={save}>
                    <SaveIcon/> Lưu
                </Button>
                <Button variant="contained" style={styles.cancelBtn}
                        onClick={cancel}>
                    <CancelIcon/> Hủy
                </Button>
            </Grid>
        </React.Fragment>
    )
}

export default compose(translate, connect(null, {showNotification}), withStyles(styles))(AdmissionFields)
