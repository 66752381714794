import React, {Fragment, useState} from 'react';
import {Button} from 'react-admin';
import BillDetailDialog from "./BillDetailDialog";
import axiosInstance from "../api/axios";
import VisibilityIcon from '@material-ui/icons/Visibility';

const ViewBillDetailButton = (props) => {
    const {record, basePath} = props;
    const [showDialogDetail, setShowDialogDetail] = useState(false);
    const [billDetail, setBillDetail] = useState({});

    const getPaymentDetail = () => {
        const url = `/api${basePath}/paymentDetails?paymentId=${record.id}`;
        return axiosInstance.get(url);
    }

    const handleClick = () => {
        getPaymentDetail().then(({data}) => {
            setShowDialogDetail(true)
            setBillDetail(data)
        });
    };
    const handleCloseDialogDetail = () => {
        setShowDialogDetail(false)
        setBillDetail({})
    };

    return (
        <Fragment>
            <Button onClick={handleClick} label="ra.action.detail" style={{fontSize: '0.7rem'}}>
                <VisibilityIcon/>
            </Button>
            <BillDetailDialog showDialogDetail={showDialogDetail} billDetail={billDetail}
                              handleCloseDialogDetail={handleCloseDialogDetail}/>
        </Fragment>
    );
}


export default ViewBillDetailButton;
