import React from 'react';
import {
    Button,
    CardActions,
    CreateButton,
    Datagrid,
    Filter,
    List,
    ReferenceField,
    TextField,
    TextInput
} from 'react-admin';
import {
    BillAuthStatusField,
    downloadFile,
    DownloadFileContentButton,
    EditButtonWithAuthStatus,
    HocKiField
} from "../../components";
import DownloadTemplateFileButtonV2 from "../../components/DownloadTemplateFileButtonV2";
import env from "../../env";
import GetAppIcon from '@material-ui/icons/GetApp';

const IntroductionFileFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

const PostActions = ({
                         bulkActions,
                         basePath,
                         displayedFilters,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <CreateButton basePath={basePath}/>
    </CardActions>
);
export const CustomerHealthBatchList = props => (
    <List filters={<IntroductionFileFilter/>} {...props} exporter={false} actions={<PostActions/>}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="description"/>
            <TextField source='ordinal' />
            <ReferenceField source="schoolYearId" reference="schoolYears" allowEmpty>
                <TextField source="name"/>
            </ReferenceField>
            <BillAuthStatusField source="authStatus"/>
            <DownloadFileContentButton/>
            <EditButtonWithAuthStatus/>
        </Datagrid>
    </List>
);

export default CustomerHealthBatchList
