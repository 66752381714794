import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

const classes = {
    root: {
        width: '100%',
        marginTop: 10,
    },
    paper: {
        marginTop: 3,
        width: '100%',
        overflowX: 'auto',
        marginBottom: 2,
    },
    table: {
        minWidth: 650,
    },
    icon: {
        fontSize: 18,
    },
};

class InsuranceBatchValidation extends Component {

    render() {
        return (
            <div style={classes.root}>
                <Paper style={classes.paper}>
                    <Table style={classes.table} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>STT</TableCell>
                                <TableCell>Lỗi</TableCell>
                                <TableCell align="right">Số điện thoại</TableCell>
                                <TableCell align="right">Mã học sinh</TableCell>
                                <TableCell>Họ Tên</TableCell>
                                <TableCell>Giới tính</TableCell>
                                <TableCell>Ngày sinh</TableCell>
                                <TableCell>Tên lớp</TableCell>
                                <TableCell>Tên trường</TableCell>
                                <TableCell>Ngày hiệu lực</TableCell>
                                <TableCell>Ngày hết hạn</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.data.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell component="th" scope="row">
                                        {row.order}
                                    </TableCell>
                                    <TableCell style={{color: 'red'}}>{row.error}</TableCell>
                                    <TableCell align="right">{row.phoneNumber}</TableCell>
                                    <TableCell align="right">{row.customerId}</TableCell>
                                    <TableCell>{row.fullName}</TableCell>
                                    <TableCell>{row.sex}</TableCell>
                                    <TableCell>{row.dob}</TableCell>
                                    <TableCell>{row.className}</TableCell>
                                    <TableCell>{row.schoolName}</TableCell>
                                    <TableCell>{row.effectiveDate}</TableCell>
                                    <TableCell>{row.expireDate}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={this.props.total}
                        rowsPerPage={this.props.rowsPerPage}
                        page={this.props.page}
                        backIconButtonprops={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonprops={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.props.handleChangePage}
                        onChangeRowsPerPage={this.props.onChangeRowsPerPage}
                    />
                </Paper>
            </div>
        );
    }
}

export default InsuranceBatchValidation;
