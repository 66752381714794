import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {ReferenceInput, Title} from 'react-admin';
import {downloadFile, ReportForm, schoolYears, showErrorNotify, TreeCheckboxInput} from '../../components';
import env from '../../env';

class DsTkDinhDanh extends React.Component {
    state = {
        params: {
            unitIds: [],
        }
    };

    getReport = () => {
        if (!this.state.params.unitIds || this.state.params.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        downloadFile(`${env.baseURL}/reports/tk-dinh-danh?unitIds=${this.state.params.unitIds}`, 'ds_tk_dinh_danh.xlsx')
    };

    validate = (values) => {
        const errors = {};

        // Copy input values to params
        this.setState({params: values})

        return errors;
    }

    render() {
        return (
            <Card>
                <Title title="resources.dsTkDinhDanh.name"/>
                <CardContent>
                    <ReportForm params={this.state.params}
                                validate={this.validate}
                                excelReport={this.getReport}
                    >
                        <Grid item xs={12} sm={6}>
                            <label>Danh sách lớp *</label>
                            <div style={{maxHeight: '300px', overflow: 'auto'}}>
                                <ReferenceInput source="unitIds"
                                                reference="units"
                                                perPage={999}
                                                filter={{clazzWithParents: true, schoolYear: schoolYears[0].id}}
                                                {...this.props} >
                                    <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                                </ReferenceInput>
                            </div>
                        </Grid>
                    </ReportForm>
                </CardContent>
            </Card>
        )
    }
}

export default DsTkDinhDanh

