import React, {Component} from 'react';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import env from '../../env';
import './style.css';

class VotingInfo extends Component {

  pollId = 0;
  totalParticipant = 0;
  stompClient;
  serverUrl = env.wsURL;
  votingResults = [];
  votingSummary = {};

  componentWillMount() {
    let root = document.documentElement;
    const styleCode = this.props.match.params.styleCode;
    if (styleCode === '1') {
      const bg = 'url("https://api.vinaid.vn/files/vote/' + '2v' + localStorage.getItem('tenantId') + '.jpg")';
      root.style.setProperty('--url-bg', bg);
      root.style.setProperty('--color-font', '#FFFFFF');
    } else {
      const bg = 'url("https://api.vinaid.vn/files/vote/' + 'v' + localStorage.getItem('tenantId') + '.jpg")';
      root.style.setProperty('--url-bg', bg);
      root.style.setProperty('--color-font', '#FFD966');
    }
    this.pollId = this.props.match.params.pollId;
    this.totalParticipant = this.props.match.params.totalParticipant;

    this.openWS();
  }

  openWS() {
    const token = localStorage.getItem('token');
    const config = this.getConfig();
    const socket = new SockJS(
        `${this.serverUrl}?token=${encodeURIComponent(token)}`
    );
    this.stompClient = Stomp.over(socket);
    this.stompClient.debug = null;
    const that = this;
    this.stompClient.connect({
      name: this.pollId,
      Authorization: token
    }, function (frame) {
      that.stompClient.send('/app/getVotingInfo', config, JSON.stringify({
        'pollId': that.pollId,
        'totalEnroll': that.totalParticipant
      }));
      that.stompClient.subscribe('/user/queue/returnVotingInfo', message => {
        if (message.body) {
          const votingInroResponse = JSON.parse(message.body);
          that.votingResults = votingInroResponse.choiceInfos;
          that.votingSummary = votingInroResponse.summaryInfo;
          that.forceUpdate()
        }
      }, {Authorization: token});
    });
  }

  getConfig() {
    const token = localStorage.getItem('token');
    return {
      headers: {
        'content-type': 'application/json',
        'Authorization': token
      }
    };
  }

  validate = (values) => {
    const errors = {};

    // Validate
    if (this.pollId == null) {
      errors.pollId = 'event  id must not be null'
    }

    return errors;
  };

  renderVotingResults() {
    let rows = [];
    for (let i = 0; i < this.votingResults.length; i++) {
        const voting = this.votingResults[i];
      rows.push(<tr key={`${voting.idx} _tr`}><td>{voting.idx}</td><td style={{textAlign: 'left'}}>{voting.question}</td><td>{voting.yesCount}</td><td>{voting.yesPercent}</td><td>{voting.noCount}</td><td>{voting.noPercent}</td></tr>);
    }
    return rows;
  }

  render() {
    return (
      <div className="votingContainer">
        <div className="votingWelcome">
          <span>KẾT QUẢ BIỂU QUYẾT CÁC CHỈ TIÊU</span>
        </div>
        <div className="votingSummaryContainer">
          <table id="votingSummaryTbl">
            <tr>
              <td></td>
              <td>Số lượng</td>
              <td>Tỉ lệ %</td>
            </tr>
            <tr>
              <td style={{textAlign: 'right'}}>Tổng số đại biểu tham dự Đại hội</td>
              <td>{this.totalParticipant}</td>
              <td></td>
            </tr>
            <tr>
              <td style={{textAlign: 'right'}}>Số đại biểu đã biểu quyết</td>
              <td>{this.votingSummary.votedCount}</td>
              <td>{this.votingSummary.votedPercent}</td>
            </tr>
            <tr>
              <td style={{textAlign: 'right'}}>Số đại biểu chưa biểu quyết</td>
              <td>{this.votingSummary.notVoteCount}</td>
              <td>{this.votingSummary.notVotePercent}</td>
            </tr>
          </table>
        </div>

        <div className="votingTblContainer">
          <table id="votingTbl">
            <thead>
            <tr>
              <th rowSpan="2" style={{width: '3%'}}>STT</th>
              <th rowSpan="2">Nội dung chỉ tiêu</th>
              <th colSpan="2" style={{width: '20%'}}>Đồng ý</th>
              <th colSpan="2" style={{width: '20%'}}>Không Đồng ý</th>
            </tr>
            <tr>
              <td>Số phiếu</td>
              <td>Tỉ lệ %</td>
              <td>Số phiếu</td>
              <td>Tỉ lệ %</td>
            </tr>
            </thead>
          <tbody>
          {this.renderVotingResults()}
          </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default VotingInfo

