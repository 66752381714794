import EventProductIcon from '@material-ui/icons/ViewList';

import EventProductList from './EventProductList';
import EventProductCreate from './EventProductCreate';
import EventProductEdit from './EventProductEdit';

export default {
  list: EventProductList,
  create: EventProductCreate,
  edit: EventProductEdit,
  icon: EventProductIcon,
};
