import React from 'react';
import {Create, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import CardApplicationBatchFields from './CardApplicationBatchFields'

const defaultValue = {
    type: localStorage.getItem("tenantType") === 'SCHOOL' ? 'HS_C1' : 'EMPLOYEE'
}

export const CardApplicationBatchCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<CustomToolbar/>} redirect="list" defaultValue={defaultValue}>
            <Grid container spacing={8}>
                <CardApplicationBatchFields/>
            </Grid>
        </SimpleForm>
    </Create>
);

export default CardApplicationBatchCreate
