import React, {useEffect, useState} from 'react';
import {Button, FormControl, FormControlLabel, Grid, MenuItem, Select, Switch} from '@material-ui/core'
import {DateInput, maxLength, required, showNotification, TextInput, translate} from 'react-admin';
import {Redirect} from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import {compose} from "redux";
import {connect} from "react-redux";
import withStyles from '@material-ui/core/styles/withStyles';
import {SwimTypeInput} from "../../components/SwimType";

const styles = {
    button: {
        margin: 1,
    },
    selectArray: {
        minWidth: '90%',
    },
    width90: {
        width: '90%',
    },
    label: {fontWeight: 'bold', fontSize: 16},
    cancelBtn: {
        marginLeft: 20
    },
    checkboxGroup: {
        "& > div ": {
            flexDirection: 'column',
        }
    },
    customCheckBox: {
        "& > div > label ": {
            width: '100%'
        }
    }
};
const banXaHoi = 'Ban Xã hội (Địa, GD Kinh tế và PL)';
const nguyenVong1s = [
    'Cận chuyên Toán',
    'Cận chuyên Lý',
    'Cận chuyên Hoá',
    'Cận chuyên Tin',
    'Cận chuyên Văn',
    'Cận chuyên Anh',
    'Tích hợp',
    'Không'
]
const nguyenVong2s = [
    'Ban Tự nhiên 1 (Lý, Hoá)',
    'Ban Tự nhiên 2 (Hoá, Sinh)',
    'Ban Tự nhiên 3 (Lý, Sinh)',
    banXaHoi
]
const nguyenVong3s = [
    'Toán',
    'Lý',
    'Hoá - Sinh',
    'Không'
]

const chuyenDeHocTapTNs = [
    'Nhóm CCĐ1 Toán-Lý-Hoá',
    'Nhóm CCĐ2 Toán-Lý-Tin',
    'Nhóm CCĐ3 Toán-Hoá-Sinh',
    'Nhóm CCĐ4 Toán-Lý-Sinh',
    'Nhóm CCĐ5 Toán-Văn-Sử',
    'Nhóm CCĐ6 Toán-Văn-Địa',
    'Nhóm CCĐ7 Toán-Văn-GDKT&PL'
]
const chuyenDeHocTapXHs = [
    'Nhóm CCĐ5 Toán-Văn-Sử',
    'Nhóm CCĐ6 Toán-Văn-Địa',
    'Nhóm CCĐ7 Toán-Văn-GDKT&PL'
]
const nhomMon3Options = [
    'Địa',
    'GDKT&PL',
    'Lý',
    'Hoá',
    'Sinh',
    'Công nghệ (CN)'
]

const nhomMon4Options = [
    'Tin học (+ICDL)',
    'Mỹ thuật',
    'Âm nhạc'
]
const AdmissionSubjectRegistered2Fields = props => {
    const [values, setValues] = React.useState({
        isRedirect: false,
        refreshList: false,
        openWhiteList: false,
        units: [],
        selectedElectives: []
    });
    const {
        defaultValue,
        save,
        programServices,
        schoolServices,
        setSchoolServices,
        setProgramServices, nhomMon3, nhomMon4, chuyenDeHocTap, haveTinHoc
    } = props;

    const [canSwim, setCanSwim] = useState(false)

    useEffect(() => {
        if (!defaultValue.isRedirect) {
            setValues({...values})
        } else {
            setValues({isRedirect: true, refreshList: true});
        }
    }, [defaultValue.isRedirect]);
    useEffect(() => {
        if (defaultValue.ready) {
            setCanSwim(defaultValue.canSwim)
        }
    }, [defaultValue.ready]);
    const changeProgramService = (index) => {
        const arr = [...programServices]
        arr[index].selected = !arr[index].selected
        setProgramServices(arr)
    }

    const changeSchoolService = (index) => {
        const arr = [...schoolServices]
        arr[index].selected = !arr[index].selected
        setSchoolServices(arr)
    }

    const changeCanSwim = () => {
        setCanSwim(!canSwim)
        props.onDirectChange('canSwim', !canSwim)
    }

    function cancel() {
        setValues({isRedirect: true, refreshList: false});
    }

    if (values.isRedirect) {
        return <Redirect to={'/admissionChuyens?refreshView=' + values.refreshList}/>;
    } else {
        return (
            <React.Fragment>
                <Grid item xs={12} sm={4}>
                    <TextInput source="sbd" label="resources.admissionChuyens.fields.sbd" disabled/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="customerName" label="resources.admissionChuyens.fields.customerName"
                               disabled/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="loaiTrungTuyen"
                               label="resources.admissionChuyens.fields.loaiTrungTuyen" disabled/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="monChuyen"
                               label="resources.admissionChuyens.fields.monChuyen" disabled/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="diemChuyen"
                               label="resources.admissionChuyens.fields.diemChuyen" disabled/>
                </Grid>
                <Grid item xs={12} sm={4}>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="hsGioiTp" label="resources.admissionChuyens.fields.hsGioiTp"
                               onChange={props.onChange('hsGioiTp')} style={styles.width90}
                               validate={maxLength(50, 'Không quá 50 ký tự')}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="giaiHsGioi" label="resources.admissionChuyens.fields.giaiHsGioi"
                               onChange={props.onChange('giaiHsGioi')} style={styles.width90}
                               validate={maxLength(50, 'Không quá 50 ký tự')}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="documentNumber" label="resources.admissions.fields.documentNumber"
                               onChange={props.onChange('documentNumber')}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <DateInput source="documentIssueDate"
                               label="resources.admissions.fields.documentIssueDate"
                               onChange={(e, v) => props.onDirectChange('documentIssueDate', v)}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="documentOrigin" label="resources.admissions.fields.documentOrigin"
                               onChange={props.onChange('documentOrigin')}/>
                </Grid>

                {defaultValue.loaiTrungTuyen == 'TICHHOP' ?
                    <>
                        <Grid item xs={12} sm={3}>
                            <TextInput source="diemToan"
                                       label="resources.admissionChuyens.fields.diemToan"
                                       onChange={props.onChange('diemToan')} style={styles.width90}
                                       validate={maxLength(5, 'Không quá 5 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextInput source="diemLy" label="resources.admissionChuyens.fields.diemLy"
                                       onChange={props.onChange('diemLy')} style={styles.width90}
                                       validate={maxLength(5, 'Không quá 5 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextInput source="diemHoa"
                                       label="resources.admissionChuyens.fields.diemHoa"
                                       onChange={props.onChange('diemHoa')} style={styles.width90}
                                       validate={maxLength(5, 'Không quá 5 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextInput source="diemSinh"
                                       label="resources.admissionChuyens.fields.diemSinh"
                                       onChange={props.onChange('diemSinh')} style={styles.width90}
                                       validate={maxLength(5, 'Không quá 5 ký tự')}/>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <span style={styles.label}>Nhóm môn lựa chọn 4*</span>
                        </Grid>
                        {nhomMon4.map((item, index) => (
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <span>{'Ưu tiên ' + (index + 1)}</span>
                                    <Select
                                        labelId={"mon-4" + index}
                                        id={"mon-4-select" + index}
                                        value={item.subjectName}
                                        label={false}
                                        onChange={(e) => props.changeNhomMon4(index, e.target.value)}
                                    >
                                        {nhomMon4Options.map((el, index) => (
                                            <MenuItem value={el}>{el}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        ))
                        }
                    </>
                    :
                    <>
                        {!defaultValue.loaiTrungTuyen &&
                        <>
                            <FormControl fullWidth>
                                <span style={styles.label}>Nguyện vọng 1*</span>
                                <Select
                                    labelId={"nv1"}
                                    id={"nv1-select"}
                                    value={defaultValue.nguyenVong1}
                                    onChange={(e) => props.onDirectChange('nguyenVong1', e.target.value)}
                                >
                                    {nguyenVong1s.map((el, index) => (
                                        <MenuItem value={el}>{el}</MenuItem>
                                    ))
                                    }
                                </Select>
                            </FormControl>
                            <FormControl fullWidth>
                                <span style={styles.label}>Nguyện vọng 2*</span>
                                <Select
                                    labelId={"nv2"}
                                    id={"nv2-select"}
                                    value={defaultValue.nguyenVong2}
                                    onChange={(e) => props.onDirectChange('nguyenVong2', e.target.value)}
                                >
                                    {nguyenVong2s.map((el, index) => (
                                        <MenuItem value={el}>{el}</MenuItem>
                                    ))
                                    }
                                </Select>
                            </FormControl>
                        </>
                        }
                        <FormControl fullWidth>
                            <span style={styles.label}>Nguyện vọng 3 (Chỉ dành cho Ban Tự nhiên)</span>
                            <Select
                                labelId={"nv3"}
                                id={"nv3-select"}
                                value={defaultValue.nguyenVong3}
                                onChange={(e) => props.onDirectChange('nguyenVong3', e.target.value)}
                            >
                                {nguyenVong3s.map((el, index) => (
                                    <MenuItem value={el}>{el}</MenuItem>
                                ))
                                }
                            </Select>
                        </FormControl>
                        {defaultValue.nguyenVong3 != 'Không' &&
                        <>
                            <Grid item xs={12} sm={3}>
                                <TextInput source="diemToan"
                                           label="resources.admissionChuyens.fields.diemToan"
                                           onChange={props.onChange('diemToan')} style={styles.width90}
                                           validate={maxLength(5, 'Không quá 5 ký tự')}/>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextInput source="diemLy" label="resources.admissionChuyens.fields.diemLy"
                                           onChange={props.onChange('diemLy')} style={styles.width90}
                                           validate={maxLength(5, 'Không quá 5 ký tự')}/>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextInput source="diemHoa"
                                           label="resources.admissionChuyens.fields.diemHoa"
                                           onChange={props.onChange('diemHoa')} style={styles.width90}
                                           validate={maxLength(5, 'Không quá 5 ký tự')}/>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextInput source="diemSinh"
                                           label="resources.admissionChuyens.fields.diemSinh"
                                           onChange={props.onChange('diemSinh')} style={styles.width90}
                                           validate={maxLength(5, 'Không quá 5 ký tự')}/>
                            </Grid>
                        </>
                        }
                        <Grid item xs={12} sm={12}>
                            <span style={styles.label}>Nhóm môn lựa chọn 3*</span>
                        </Grid>
                        {nhomMon3.map((item, index) => (
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <span>{'Ưu tiên ' + (index + 1)}</span>
                                    <Select
                                        labelId={"mon-3" + index}
                                        id={"mon-3-select" + index}
                                        value={item.subjectName}
                                        label={false}
                                        onChange={(e) => props.changeNhomMon3(index, e.target.value)}
                                    >
                                        {nhomMon3Options.map((el, index) => (
                                            <MenuItem value={el}>{el}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        ))
                        }
                        <Grid item xs={12} sm={12}>
                            <span style={styles.label}>Nhóm môn lựa chọn 4*</span>
                        </Grid>
                        {nhomMon4.map((item, index) => (
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <span>{'Ưu tiên ' + (index + 1)}</span>
                                    <Select
                                        labelId={"mon-4" + index}
                                        id={"mon-4-select" + index}
                                        value={item.subjectName}
                                        label={false}
                                        onChange={(e) => props.changeNhomMon4(index, e.target.value)}
                                    >
                                        {nhomMon4Options.map((el, index) => (
                                            <MenuItem value={el}>{el}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        ))
                        }
                        <Grid item xs={12} sm={12}>
                            <span style={styles.label}>Nhóm Cụm chuyên đề học tập*</span>
                        </Grid>
                        {chuyenDeHocTap.map((item, index) => (
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth>
                                    <span>{'Ưu tiên ' + (index + 1)}</span>
                                    {defaultValue.nguyenVong2 == 'Ban Xã hội (Địa, GD Kinh tế và PL)' ?
                                        <Select
                                            labelId={"chuyen_de" + index}
                                            id={"chuyen_de-select" + index}
                                            value={item.name}
                                            label={false}
                                            onChange={(e) => props.changeChuyenDeHocTap(index, e.target.value)}
                                        >
                                            {chuyenDeHocTapXHs.map((el, index) => (
                                                <MenuItem value={el}>{el}</MenuItem>
                                            ))}
                                        </Select>
                                        :
                                        <Select
                                            labelId={"chuyen_de" + index}
                                            id={"chuyen_de-select" + index}
                                            value={item.name}
                                            label={false}
                                            onChange={(e) => props.changeChuyenDeHocTap(index, e.target.value)}
                                        >
                                            {chuyenDeHocTapTNs.map((el, index) => (
                                                <MenuItem value={el}>{el}</MenuItem>
                                            ))}

                                        </Select>
                                    }
                                </FormControl>
                            </Grid>
                        ))
                        }
                    </>
                }
                <Grid item xs={12} sm={12}>
                        <span
                            style={styles.label}>{'Đăng ký chương trình với nhà trường'}</span>
                </Grid>
                {programServices.map((item, index) => (
                    <>
                        {defaultValue.loaiTrungTuyen == 'TICHHOP' ?
                            <>
                                {!item.name.toLowerCase().startsWith('tiếng anh') &&
                                <>
                                    {item.name.toLowerCase().startsWith('tin học') ?
                                        <Grid item xs={12} sm={4}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={<Switch checked={item.selected || haveTinHoc}
                                                                 onChange={() => !haveTinHoc && changeProgramService(index)}/>}
                                                label={item.name}
                                            />
                                        </Grid>
                                        :
                                        <Grid item xs={12} sm={4}>
                                            <FormControlLabel
                                                labelPlacement="start"
                                                control={<Switch checked={item.selected}
                                                                 onChange={() => !item.forced && changeProgramService(index)}/>}
                                                label={item.name}
                                            />
                                        </Grid>
                                    }
                                </>
                                }
                            </>
                            :
                            <>
                                {item.name.toLowerCase().startsWith('tin học') ?
                                    <Grid item xs={12} sm={4}>
                                        <FormControlLabel
                                            labelPlacement="start"
                                            control={<Switch checked={item.selected || haveTinHoc}
                                                             onChange={() => !haveTinHoc && changeProgramService(index)}/>}
                                            label={item.name}
                                        />
                                    </Grid>
                                    :
                                    <Grid item xs={12} sm={4}>
                                        <FormControlLabel
                                            labelPlacement="start"
                                            control={<Switch checked={item.selected}
                                                             onChange={() => !item.forced && changeProgramService(index)}/>}
                                            label={item.name}
                                        />
                                    </Grid>
                                }
                            </>
                        }
                    </>
                ))
                }
                <Grid item xs={12} sm={12}>
                    <span style={styles.label}>{'Các dịch vụ giáo dục'}</span>
                </Grid>
                {schoolServices.map((item, index) => (
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            labelPlacement="start"
                            control={<Switch checked={item.selected}
                                             onChange={() => !item.forced && changeSchoolService(index)}/>}
                            label={item.name}
                        />
                    </Grid>
                ))
                }
                <Grid item xs={12} sm={12}>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SwimTypeInput source="canSwim"
                                   label="resources.admissions.fields.canSwim"
                                   onChange={(e, v) => props.onDirectChange('canSwim', v)}
                                   style={styles.width90}/>
                </Grid>
                {defaultValue.canSwim == 'Có' &&
                <>
                    <Grid item xs={12} sm={3}>
                        <TextInput source="swimCertificate"
                                   label="resources.admissionChuyens.fields.swimCertificate"
                                   onChange={props.onChange('swimCertificate')} style={styles.width90}
                                   validate={[required(), maxLength(50, 'Không quá 50 ký tự')]}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextInput source="swimPlaceOfCertificate"
                                   label="resources.admissionChuyens.fields.swimPlaceOfCertificate"
                                   onChange={props.onChange('swimPlaceOfCertificate')} style={styles.width90}
                                   validate={[required(), maxLength(50, 'Không quá 50 ký tự')]}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <DateInput source="swimDateOfCertificate"
                                   label="resources.admissions.fields.swimDateOfCertificate"
                                   validate={[required()]}
                                   onChange={(e, v) => props.onDirectChange('swimDateOfCertificate', v)}/>
                    </Grid>
                </>
                }

                <Grid item xs={12} sm={12} style={{marginTop: 15}}>
                    <Button variant="contained"
                            color="primary"
                            style={styles.button}
                            disabled={defaultValue.saveDisabled}
                            onClick={save}>
                        <SaveIcon/> Lưu
                    </Button>
                    <Button variant="contained" style={styles.cancelBtn}
                            onClick={cancel}>
                        <CancelIcon/> Hủy
                    </Button>
                </Grid>
            </React.Fragment>
        )
    }
}

export default compose(translate, connect(null, {showNotification}), withStyles(styles))(AdmissionSubjectRegistered2Fields)
