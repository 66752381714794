import React from 'react';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid'
import {
    AutocompleteInput,
    BooleanInput,
    CheckboxGroupInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    TextInput,
    translate,
    NumberInput
} from 'react-admin';

const styles = {
  checkboxGroup: {
    // background: 'red',
    "& > fieldset > div ": {
      flexDirection: 'column',
    }
  }
}

const SuperAdminUserFields = ({classes, record, ...props}) => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <TextInput source="userName" validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <TextInput source="fullName" {...props} />
    </Grid>

    <Grid item xs={12} sm={6} >
      <ReferenceInput source="tenantId" reference="tenants" allowEmpty {...props} >
          <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </Grid>
    <Grid item xs={12} sm={6} >
        <TextInput source="customerId" {...props}/>
    </Grid>

    <Grid item xs={12} sm={6} >
      <TextInput type="password" source="password" {...props} />
    </Grid>

    <Grid item xs={12} sm={6} >
        <TextInput source="deviceToken" disabled {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <BooleanInput source="enabled" {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <BooleanInput source="locked" {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <NumberInput source="failOtpCount" {...props} />
    </Grid>

    <Grid item xs={12} sm={12} >
      <hr style={{width: '100%', marginTop: '20px', marginBottom: '0', color: 'lightblue'}}/>
    </Grid>
    <Grid item xs={12} sm={6} className={classes.checkboxGroup} >
      <ReferenceArrayInput source="roleIds" reference="roles" 
            sort={{ field: 'name', order: 'ASC' }} 
            filter={{ userId: record.id }}
            {...props} >
        <CheckboxGroupInput optionText="description" {...props} />
      </ReferenceArrayInput>
    </Grid>
  </React.Fragment>
)

export default compose(
  translate,
  withStyles(styles)
)(SuperAdminUserFields)
