// Use axios instead of fetch?
import axios from 'axios';
import env from "../env";

const axiosInstance = axios.create({
    baseURL: env.baseURL,
})

//Default error when network does not work
let err = {
    message: 'Không có tín hiệu trả về từ hệ thống, vui lòng kiểm tra hệ thống mạng'
};

axiosInstance.interceptors.request.use(async config => {
    if (Object.keys(config.headers).length === 0 || !config.headers['Authorization']) {
        config.headers = {
            'content-type': 'application/json',
            'Authorization': localStorage.getItem('token')
        }
    }
    if (config.newBaseUrl) {
        config.url = config.newBaseUrl + config.url;
    } else {
        config.url = config.baseURL + config.url;
    }
    return config;
}, error => {
    // handle the error
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(response => {
    if (response && response.data && response.data.error) {//Normal error was threw by server
        //TODO: check error code and convert message to Vietnamese before reject
        if (response.data.error.code) {
            switch (response.data.error.code) {
                case 'MCP0006':
                    err.message = 'Dữ liệu không hợp lệ';
                    break;
                case 'MCP0017':
                    err.message = response.data.error.message;
                    break;
                case 'MCP0020':
                    err.message = 'Mật khẩu cũ không đúng';
                    break;
                default:
                    err.message = "Mã lỗi: " + response.data.error.code + ", liên hệ 1900 27 27 28 để được hỗ trợ";
                    break;
            }
        } else if (response.data.error.message) {
            err.message = response.data.error.message
        }

        return Promise.reject(err);
    }
    return response;
}, (error) => {
    if (!error.response) {
        err.message = 'Không có tín hiệu trả về từ hệ thống, vui lòng kiểm tra hệ thống mạng';
    }
    if (error && error.response) {
        if (error.response.status === 401) {//Unauthorized
            err.message = "Thông tin đăng nhập không đúng hoặc không tồn tại, vui lòng đăng nhập lại";
        }
        if (error.response.status === 403) {//Forbidden
            err.message = "Truy cập đến hệ thống bị cấm, liên hệ 1900 27 27 28 để được hỗ trợ";
        }
        if (error.response.status === 404) {//Not found
            err.message = "Không thể kết nối đến hệ thống";
        }
        if (error.response.status === 400) {//Bad request
            if (error.response.data && error.response.data.length > 0) {
                let msg = ''
                for (let i = 0; i < error.response.data.length; i++) {
                    msg += error.response.data[i] + ', '
                }
                err.message = msg.substring(0, msg.length - 2);
            } else {
                err.message = "Truy cập hệ thống không hợp lệ, liên hệ 1900 27 27 28 để được hỗ trợ";
            }
        }
        if (error.response.status === 500) {//Internal server error
            if (error.response.data.message) {
                switch (error.response.data.message) {
                    case 'MCP0013':
                        err.message = 'Không có quyền đọc dữ liệu, liên hệ 1900 27 27 28 để được hỗ trợ';
                        break;
                    case 'MCP0014':
                        err.message = 'Không có quyền tạo dữ liệu, liên hệ 1900 27 27 28 để được hỗ trợ';
                        break;
                    case 'MCP0015':
                        err.message = 'Không có quyền sửa dữ liệu, liên hệ 1900 27 27 28 để được hỗ trợ';
                        break;
                    case 'MCP0016':
                        err.message = 'Không có quyền xóa dữ liệu, liên hệ 1900 27 27 28 để được hỗ trợ';
                        break;
                    case 'MCP0017':
                        err.message = 'Thiếu quyền truy cập api, liên hệ 1900 27 27 28 để được hỗ trợ';
                        break;
                    default:
                        err.message = "Xuất hiện lỗi từ hệ thống, liên hệ 1900 27 27 28 để được hỗ trợ";
                        break;
                }
            } else {
                err.message = "Xuất hiện lỗi từ hệ thống, liên hệ 1900 27 27 28 để được hỗ trợ";
            }
        }
    }
    //If none of errors happened from server side, default will be return
    return Promise.reject(err);
});

export default axiosInstance;
