import React from 'react';

import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, ReferenceArrayInput, required, Title, translate} from 'react-admin';
import {downloadFile, ReportForm, schoolYears, TreeCheckboxInput} from '../../components';
import env from '../../env';
import {compose} from 'redux';

const BcChamThe = ({...props}) => {

    const [values, setValues] = React.useState({
        unitIds: '',
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
    });

    const getReportUrl = () => {
        return `${env.baseURL}/reports/bcChamThe?fromDate=` + values.fromDate +
            `&toDate=` + values.toDate +
            `&unitIds=` + values.unitIds;
    }

    const excelReport = () => {
        downloadFile(getReportUrl(), 'bc_cham_the.xlsx')
    };

    const validate = (val) => {
        const errors = {};

        if (val.fromDate && val.toDate && (val.fromDate > val.toDate)) {
            errors.toDate = 'To date must be greater than From date'
        }

        setValues({
            unitIds: val.unitIds,
            fromDate: val.fromDate,
            toDate: val.toDate
        })

        return errors;
    };

    return (
        <Card>
            <Title title="resources.bcChamThe.name"/>
            <CardContent>
                <ReportForm params={values}
                            validate={validate}
                            excelReport={excelReport}
                >
                    <span>
                     Mô tả: Báo cáo dựa trên dữ liệu chạm thẻ
                    </span>
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="fromDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.toDate" source="toDate"
                                       validate={required()} {...props} />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <label>Danh sách lớp/phòng ban</label>
                            <div style={{maxHeight: '300px', overflow: 'auto'}}>
                            <ReferenceArrayInput source="unitIds" reference="baseUnits"
                                                 filter={{schoolYearId: schoolYears.length > 0 ? schoolYears[0].id : ''}}
                                                 perPage={999} {...props} >
                                <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}
                                                   onChange={(e) => props.onChange(e)}/>
                            </ReferenceArrayInput>
                            </div>
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default compose(
    translate
)(BcChamThe)

