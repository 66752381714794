import React from 'react';
import {QuestionComponent} from "./QuestionComponent";
import {Button, Card, CardContent, Grid, TextField} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import axiosInstance from "../../api/axios";
import moment from "moment";
import {Redirect} from 'react-router-dom';
import {Title,} from 'react-admin';
import MultipleSelectComponent from "../../components/MultipleSelectComponent";

const styles = {
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: 400,
  },
  textField2: {
    marginLeft: 40,
    width: 400,
  },
  button: {
    margin: 1,
  },
  cancelBtn: {
    marginLeft: 20
  },
  icon: {
    backgroundColor: 'blue',
    width: 35,
    height: 35,
    color: 'white',
    marginBottom: 10
  },
  border: {
    marginTop: 10,
  }
};

class PollCreateOrUpdate extends React.Component {

  state = {
    pollName: '',
    pollId: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    unitIds: [],
    deviceIds: [],
    questions: [
      {
        questionText: '',
        choices: [{
          choiceText: ''
        }]
      }
    ],
    devices: [],
    units: [],
    isRedirect: false
  }

  componentDidMount() {
    if (this.props.id) {//handle update poll
      this.getPoll(this.props.id).then(rs => {
        const data = rs.data.data;
        this.setState({
          pollName: data.pollName,
          pollId: data.pollId,
          startDate: data.startDate,
          endDate: data.endDate,
          startTime: data.startTime,
          endTime: data.endTime,
          questions: data.questions,
          deviceIds: data.deviceIds,
          unitIds: data.unitIds
        });
      })
    }
    this.getDevices();
    this.getUnits();
  }

  getPoll(pollId) {
    const url = `/api/polls/` + pollId;
    return axiosInstance.get(url);
  }

  getDevices = () => {
    const url = `/api/accessDevices?page=0&size=100`;
    axiosInstance.get(url).then(rs => {
      const devices = [];
      rs.data.content.map(device => {
        devices.push({
          key: device.id,
          value: device.name
        })
      })
      this.setState({
        devices: devices
      })
    });
  }

  getUnits = () => {
    const url = `/api/units?page=0&size=999`;
    axiosInstance.get(url).then(rs => {
      const units = [];
      console.log(rs.data.content);
      rs.data.content.map(unit => {
        units.push({
          key: unit.id,
          value: unit.name
        })
      })
      this.setState({
        units: units
      })
    });
  }

  addQuestion = () => {
    const questions = this.state.questions;
    const newQuestion = {
      questionText: '',
      choices: [
        {
          choiceText: ''
        }
      ]
    };
    questions.push(newQuestion);
    this.setState({
      questions: questions
    });
  }

  deleteQuestion = (questionIdx) => {
    this.setState(prevState => ({
      questions: prevState.questions.filter(
          (question, idx) => idx !== questionIdx)
    }));
  }

  onQuestionTextChanged = (e, questionIdx) => {
    const value = e.target.value;
    this.setState(prevState => ({
      questions: prevState.questions.map((question, index) => {
        if (index === questionIdx) {
          question.questionText = value;
        }
        return question;
      })
    }));
  }

  submit = () => {
    if (!this.state.pollName || !this.state.questions || !this.state.startDate
        || !this.state.endDate || !this.state.startTime || !this.state.endTime) {
      return;
    }
    const questions = this.state.questions;
    for (let i = 0; i < questions.length; i++) {
      if (!questions[i].questionText) {
        return;
      }
      const choices = questions[i].choices;
      if (!choices) {
        return;
      }
      for (let j = 0; j < choices.length; j++) {
        if (!choices[j].choiceText) {
          return;
        }
      }
    }
    const data = {
      data: this.state
    }
    this.createOrUpdatePoll(data).then(rs => {
      if (rs.data.success) {
        this.setState({
          isRedirect: true
        });
      }
    });
  }

  cancel = () => {
    this.setState({
      isRedirect: true
    });
  }

  createOrUpdatePoll(data) {
    if (data.data.pollId) {//update poll
      const url = `/api/polls/update`;
      return axiosInstance.put(url, data);
    }
    const url = `/api/polls/create`;
    return axiosInstance.post(url, data);
  }

  onPollNameChanged = (e) => {
    const pollName = e.target.value;
    this.setState({
      pollName: pollName,
    });
  }

  onStartDateChanged = (e) => {
    const startDate = moment(e.target.value).format("YYYY-MM-DD");
    this.setState({
      startDate: startDate
    });
  }

  onEndDateChanged = (e) => {
    const endDate = moment(e.target.value).format("YYYY-MM-DD");
    this.setState({
      endDate: endDate
    });
  }

  onStartTimeChanged = (e) => {
    this.setState({
      startTime: e.target.value
    });
  }
  onEndTimeChanged = (e) => {
    this.setState({
      endTime: e.target.value
    });
  }
  addChoice = (questionIdx) => {
    const newChoice = {
      choiceText: ''
    };

    this.setState(prevState => ({
      questions: prevState.questions.map((question, index) => {
        if (index === questionIdx) {
          question.choices.push(newChoice);
        }
        return question;
      })
    }));
  }

  onChoiceTextChanged = (e, questionIdx, choiceIdx) => {
    const value = e.target.value;
    this.setState(prevState => ({
      questions: prevState.questions.map((question, index) => {
        if (index === questionIdx) {
          question.choices.map((choice, idx) => {
            if (idx === choiceIdx) {
              choice.choiceText = value
            }
            return choice;
          });
        }
        return question;
      })
    }));
  }

  deleteChoice = (questionIdx, choiceIdx) => {
    this.setState(prevState => ({
      questions: prevState.questions.map((question, index) => {
        if (index === questionIdx) {
          question.choices = question.choices.filter(
              (choice, idx) => idx !== choiceIdx);
        }
        return question;
      })
    }));
  }

  deviceChanged = (e) => {
    const deviceIds = Object.values(e);
    this.setState({
      deviceIds: deviceIds
    });
  }

  unitChanged = (e) => {
    this.setState({
      unitIds: e
    });
  }

  render() {
    if (this.state.isRedirect) {
      return <Redirect to='/polls'/>;
    }
    console.log('rendering');
    return (
        <Card>
          <Title title="Quản lý biểu quyết"/>
          <CardContent>
            <Grid id="top-row" container spacing={8}>
              <Grid item xs={12} sm={6} >
                <TextField
                    id="standard-name"
                    label="Tên"
                    required={true}
                    value={this.state.pollName}
                    onChange={(e) => this.onPollNameChanged(e)}
                    margin="normal"
                />
              </Grid>

              <Grid item xs={12} sm={6} >
                <MultipleSelectComponent
                    label={"Thiết bị"}
                    marginLeft={0}
                    data={this.state.devices}
                    selectedData={this.state.deviceIds}
                    onChange={this.deviceChanged}/>
              </Grid>

              <Grid item xs={12} sm={6} >
                <TextField
                    id="dateFrom"
                    label="Từ ngày"
                    type="date"
                    required={true}
                    value={this.state.startDate}
                    onChange={(e) => this.onStartDateChanged(e)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <TextField
                id="dateTo"
                label="Đến ngày"
                type="date"
                required={true}
                value={this.state.endDate}
                onChange={(e) => this.onEndDateChanged(e)}
                InputLabelProps={{
                shrink: true,
                }}
                />
              </Grid>

              <Grid item xs={12} sm={6} >
                <TextField
                  id="startTime"
                  label="Giờ bắt đầu"
                  type="time"
                  required={true}
                  value={this.state.startTime}
                  onChange={(e) => this.onStartTimeChanged(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} >
                <TextField
                  id="endTime"
                  label="Giờ kết thúc"
                  type="time"
                  required={true}
                  value={this.state.endTime}
                  onChange={(e) => this.onEndTimeChanged(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} >
                <MultipleSelectComponent
                    label={"Phòng ban"}
                    marginLeft={0}
                    data={this.state.units}
                    selectedData={this.state.unitIds}
                    onChange={this.unitChanged}/>
              </Grid>
            </Grid>
            <div style={styles.border}>
              {this.state.questions.map((question, index) => (
                  <QuestionComponent key={index}
                                     questionIdx={index}
                                     questionText={question.questionText}
                                     onQuestionTextChanged={this.onQuestionTextChanged}
                                     onChoiceTextChanged={this.onChoiceTextChanged}
                                     deleteQuestion={this.deleteQuestion}
                                     choices={question.choices}
                                     addChoice={this.addChoice}
                                     deleteChoice={this.deleteChoice}/>
              ))}
            </div>
            <br/>
            <IconButton aria-label="add" style={styles.icon}
                        onClick={this.addQuestion}>
              <AddIcon/>
            </IconButton>
            <br/>
            <Button variant="contained" color="primary" style={styles.button}
                    onClick={this.submit}>
              <SaveIcon/> Lưu
            </Button>
            <Button variant="contained" style={styles.cancelBtn}
                    onClick={this.cancel}>
              <CancelIcon/> Hủy
            </Button>
          </CardContent>
        </Card>
    );
  }
}

export default PollCreateOrUpdate
