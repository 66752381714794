import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {CircularProgress} from '@material-ui/core';
import {Title} from 'react-admin';
import {FileUploadComponent} from '../../components';
import env from '../../env';
import {processZipFile} from "../../components/FileDownload";

class UploadLoHinhMoThe extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            isLoading: false,
        };
        this.onFileUpload = this.onFileUpload.bind(this);
    }

    async onFileUpload(e) {
        e.preventDefault(); // Stop form submit
        const {file} = this.state
        if (!file) {
            return;
        }
        this.setState({
            isLoading: true
        });
        const url = `${env.baseURL}/api/cardApplicationPhotoBatch/upload`;
        await processZipFile(url, file)
        this.setState({
            isLoading: false
        })
    }

    render() {
        return (
            <Card>
                <Title title="resources.cardApplicationPhotoBatches.name"/>
                <CardContent>
                    <Grid container spacing={8}>
                        <FileUploadComponent onFileChanged={(e) => this.setState({file: e.target.files[0]})}
                                             onFileUpload={this.onFileUpload}
                                             accept={".zip"}
                                             loading={this.state.isLoading}
                                             showValidateBtn={false}
                        />
                        {
                            this.state.isLoading && <CircularProgress color="secondary"/>
                        }
                    </Grid>
                </CardContent>
            </Card>
        )
    }
}

export default UploadLoHinhMoThe;

