import React from 'react';
import Grid from '@material-ui/core/Grid'
import {ReferenceInput, required, SelectInput, TextInput} from 'react-admin';
import {CardApplicationTypeInput} from "../../components/CardApplicationType";
import {CardApplicationCompanyTypeInput} from "../../components/CardApplicationCompanyType";

const CardApplicationBatchFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} {...props} />
        </Grid>
        {localStorage.getItem("tenantType") === 'SCHOOL' &&
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="schoolYearId" reference="schoolYears" validate={required()} {...props} >
                <SelectInput optionText="name"/>
            </ReferenceInput>
        </Grid>
        }
        <Grid item xs={12} sm={6}>
            {localStorage.getItem("tenantType") === 'SCHOOL' ?
                <CardApplicationTypeInput source="type" validate={required()}/>
                :
                <CardApplicationCompanyTypeInput source="type" validate={required()}/>
            }
        </Grid>
    </React.Fragment>
)

export default CardApplicationBatchFields
