import React, {useEffect, useState} from 'react';
import {refreshView, showNotification, SimpleForm, translate} from 'react-admin';

import {Grid} from '../../components'
import {connect} from "react-redux";
import axiosInstance from "../../api/axios";
import {compose} from "redux";
import withStyles from '@material-ui/core/styles/withStyles';
import AdmissionFields from "./AdmissionChuyenFields";
import cloneDeep from 'lodash/cloneDeep';

const styles = {
    item: {
        "& > table ": {
            width: '95%',
        },
        "& > table td:nth-child(2)": {
            textAlign: 'right',
        },
        "& > table thead": {    // hide the header
            display: 'none',
        },
    },
    select: {
        marginTop: '20px',
    },
    button: {
        // marginTop: '-20px',
        marginLeft: '10px'
    },
}
let timeout = null;
export const AdmissionChuyenEdit = ({
                                        translate,
                                        classes,
                                        showToolbar = true,
                                        redirect = "list",
                                        noCancelButton,
                                        event,
                                        ...props
                                    }) => {

    const [values, setValues] = useState({
        id: null,
        selectedElectiveIds: [],
        selectedPhysicalIds: [],
        selectedGraduationIds: [],
        selectedElectives: [],
        isRedirect: false,
        saveDisabled: false,
        isFistTimeCheck: true,
        ready: false
    });
    const [programServices, setProgramServices] = useState([])
    const [schoolServices, setSchoolServices] = useState([])
    const [nhomMon3, setNhomMon3] = useState([])
    const [nhomMon4, setNhomMon4] = useState([])
    const [chuyenDe, setChuyenDe] = useState([])
    const [haveTinHoc, setHaveTinHoc] = useState(false)

    useEffect(() => {
        if (props.id && values.isFistTimeCheck) {
            getDetails();
        }
    }, [values.isFistTimeCheck]);

    function getDetails() {
        const url = `/api/admissionChuyens/` + props.id;
        axiosInstance.get(url).then(rs => {
            const {data} = rs;
            const clone = cloneDeep(data)
            clone.isFistTimeCheck = false;
            clone.ready = true;

            if (clone.additionalServices && clone.additionalServices.length > 0) {
                setProgramServices(clone.additionalServices.filter(p => p.type === 'PROGRAM'))
                setSchoolServices(clone.additionalServices.filter(p => p.type === 'SERVICE'))
            }
            let nhomMon3 = data.nhomMon3
            if (!nhomMon3) {
                nhomMon3 = [{nguyenVong: 3, priority: 1}, {nguyenVong: 3, priority: 2}, {nguyenVong: 3, priority: 3}]
            }
            setNhomMon3(nhomMon3)
            let nhomMon4 = data.nhomMon4
            if (!nhomMon4) {
                nhomMon4 = [{nguyenVong: 4, priority: 1}, {nguyenVong: 4, priority: 2}, {nguyenVong: 4, priority: 3}]
            }
            for (let i = 0; i < nhomMon4.length; i++) {
                if (nhomMon4[i].subjectName && nhomMon4[i].subjectName.toLowerCase().startsWith('tin học')) {
                    setHaveTinHoc(true)
                    break;
                }
            }
            setNhomMon4(nhomMon4)

            let chuyenDeHocTap = data.chuyenDeHocTap
            if (!chuyenDeHocTap) {
                chuyenDeHocTap = [{priority: 1}, {priority: 2}, {priority: 3}]
            }
            setChuyenDe(chuyenDeHocTap)
            setValues({...clone, merged: clone});
        });
    }

    const handleChange = prop => e => {
        if (!e) {
            return
        }
        const value = e.target.value ? e.target.value : ''

        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            if (prop.includes('\.')) {
                const arr = prop.split('\.')
                setValues({...values, [arr[0]]: {...values[[arr[0]]], [arr[1]]: value}});
            } else {
                setValues({...values, [prop]: value});
            }
        }, 100);
    };

    const changeNhomMon3 = (index, value) => {
        const arr = [...nhomMon3]
        arr[index].subjectName = value
        setNhomMon3(arr)
    };
    const changeNhomMon4 = (index, value) => {
        const arr = [...nhomMon4]
        arr[index].subjectName = value
        setHaveTinHoc(false)
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].subjectName && arr[i].subjectName.toLowerCase().startsWith('tin học')) {
                setHaveTinHoc(true)
                break;
            }
        }
        setNhomMon4(arr)
    };

    const changeChuyenDeHocTap = (index, value) => {
        const arr = [...chuyenDe]
        arr[index].name = value
        setChuyenDe(arr)
    };

    function validate() {
        if (values.canSwim) {
            if (values.canSwim == 'Có') {
                if (!values.swimCertificate || !values.swimDateOfCertificate || !values.swimPlaceOfCertificate) {
                    props.showNotification('Vui lòng nhập thông tin chứng chỉ bơi.', 'warning');
                    return;
                }
            }
        } else {
            props.showNotification('Vui lòng chọn Biết bơi.', 'warning');
            return;
        }
        return true;
    }

    function save() {
        if (!validate()) {
            return;
        }
        const data = Object.assign({}, values);
        setValues({
            ...values, isRedirect: true
        });
        if (data.canSwim != 'Có') {
            data.swimCertificate = ''
            data.swimDateOfCertificate = ''
            data.swimPlaceOfCertificate = ''
        }
        if (data.loaiTrungTuyen == 'TICHHOP') {
            for (let i = 0; i < programServices.length; i++) {
                const p = programServices[i]
                if (p.name.toLowerCase().startsWith('tiếng anh')) {
                    p.selected = false
                }
            }
        }
        if (haveTinHoc) {
            for (let i = 0; i < programServices.length; i++) {
                const p = programServices[i]
                if (p.name.toLowerCase().startsWith('tin học')) {
                    p.selected = true
                }
            }
        }
        data.additionalServices = [...programServices, ...schoolServices]
        data.nhomMonTuChons = [...nhomMon3, ...nhomMon4]
        data.chuyenDeHocTap = [...chuyenDe]

        const url = `/api/admissionChuyens/` + props.id;
        axiosInstance.put(url, data).then(rs => {
            props.showNotification('Cập nhật thành công');
        }, err => {
            props.showNotification(err.message, 'warning');
        })
    }

    function onDirectChange(prop, val) {
        setValues({...values, [prop]: val});
    }

    return (
        <SimpleForm
            toolbar={null}
            record={values}
        >
            <Grid container spacing={8} alignItems="flex-start" classes={classes}>
                <AdmissionFields onChange={handleChange}
                                 defaultValue={values}
                                 save={save}
                                 programServices={programServices}
                                 schoolServices={schoolServices}
                                 setProgramServices={setProgramServices}
                                 setSchoolServices={setSchoolServices}
                                 nhomMon3={nhomMon3}
                                 nhomMon4={nhomMon4}
                                 chuyenDeHocTap={chuyenDe}
                                 haveTinHoc={haveTinHoc}
                                 onDirectChange={onDirectChange}
                                 changeNhomMon3={changeNhomMon3}
                                 changeNhomMon4={changeNhomMon4}
                                 changeChuyenDeHocTap={changeChuyenDeHocTap}
                />
            </Grid>
        </SimpleForm>
    )
}

export default compose(
    connect(undefined, {refreshView, showNotification}),
    translate,
    withStyles(styles)
)(AdmissionChuyenEdit)
