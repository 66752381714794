import React from 'react';
import {loadCSS} from 'fg-loadcss';
import {Button, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow} from '@material-ui/core';
import {Grid} from '../../components'
import {withStyles} from "@material-ui/core/styles";

const classes = theme => ({
    root: {
        width: 'auto',
        marginTop: 10,
        overflowX: 'auto'
    },
    paper: {
        marginTop: 3,
        width: 'auto',
        overflowX: 'hide',
        marginBottom: 2,
    },
    table: {
        marginTop: 10,
        [theme.breakpoints.only('xs')]: {
            minWidth: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: '100%',
        },
    },
    tableCell: {
        paddingLeft: 2,
        paddingRight: 2,
    },
    icon: {
        fontSize: 10,
    },
    button: {
        height: 10,
        width: 10,
        fontSize: 10,
        paddingTop: '10px !important',
        paddingBottom: '10px !important',
        minWidth: 0,
        minHeight: 0
    },
    button2: {
        height: 10,
        fontSize: 12,
        paddingTop: '10px !important',
        paddingBottom: '10px !important',
        minWidth: 0,
        minHeight: 0
    },
    button3: {
        fontSize: 13,
        padding: 0,
        textTransform: 'none',
        textAlign: 'left',
    },
    tablePagination: {
        paddingLeft: 0
    },
    tablePaginationAction: {
        marginLeft: 0
    }
});

const ATTENDANCE_STATUS_CONVERTER = {
    IN_LATE: 'TR',
    ON_TIME: 'DG',
    ABSENT: 'KP',
    MISSING_CARD: 'KT',
    ABSENT_WITH_PERMISSION: 'CP',
    OFF: 'OFF',
    CM: 'CM'
}

const AttendanceResultValidation = (props) => {

    React.useEffect(() => {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
            document.querySelector('#font-awesome-css'),
        );
    }, []);

    return (
        <Grid item xs={12}>
            <Paper className={props.classes.paper}>
                <Table className={props.classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className={props.classes.tableCell}>STT</TableCell>
                            <TableCell className={props.classes.tableCell} align="right">MSHS</TableCell>
                            <TableCell className={props.classes.tableCell} align="right">Mã VinaID</TableCell>
                            <TableCell className={props.classes.tableCell} align="right">Họ Tên</TableCell>
                            <TableCell className={props.classes.tableCell} align="right">Trạng thái</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data && props.data.map((row, idx) => (
                            <TableRow key={idx}>
                                {row &&
                                <TableCell className={props.classes.tableCell} component="th" style={{width: 50}}
                                           scope="row">
                                    {1 + props.page * props.rowsPerPage + idx}
                                </TableCell>
                                }
                                {row &&
                                <TableCell className={props.classes.tableCell} style={{width: 200}} align="right">
                                    {row.studentNo}
                                </TableCell>
                                }
                                {row &&
                                <TableCell className={props.classes.tableCell} style={{width: 150}} align="right">
                                    {row.customerId}
                                </TableCell>
                                }
                                {row &&
                                <TableCell className={props.classes.tableCell} style={{width: 550}} align="right">
                                    {row.prevTimeIn ?
                                        <Button
                                            style={{color: 'black'}}
                                            disabled={!props.enabled}
                                            onClick={e => props.openPopupChangePrevStatus(row, idx)}
                                            className={props.classes.button3}>
                                            {row.customerName}
                                            <br/>
                                            {(row.prevTimeIn || '').split(' ').reverse()[0]}
                                        </Button>
                                        :
                                        row.customerName
                                    }
                                </TableCell>
                                }
                                {row &&
                                <TableCell className={props.classes.tableCell} style={{paddingRight: 0}} align="right">
                                    {ATTENDANCE_STATUS_CONVERTER[row.statusIn] === 'DG' ?
                                        <Button
                                            disabled={!props.enabled}
                                            variant="outlined" className={props.classes.button2}
                                            style={{color: 'black', background: '#548235'}}
                                            onClick={e => props.openPopupChangeStatusHaveTime(row, 'DG', idx)}>
                                            <span style={{color: '#fff'}}>ĐG</span>
                                            <span style={{
                                                color: '#fff',
                                                marginLeft: 10
                                            }}> {(row.timeIn || '').split(' ').reverse()[0]}</span>
                                        </Button>
                                        :
                                        <>
                                            {ATTENDANCE_STATUS_CONVERTER[row.statusIn] === 'TR' ?
                                                <Button disabled={!props.enabled}
                                                        variant="outlined" className={props.classes.button2}
                                                        style={{color: 'black', background: '#C5E0B4'}}
                                                        onClick={e => props.openPopupChangeStatusHaveTime(row, 'TR', idx)}>
                                                    <span>TR</span>
                                                    <span
                                                        style={{marginLeft: 10}}> {(row.timeIn || '').split(' ').reverse()[0]}</span>
                                                </Button>
                                                :
                                                <>
                                                    {ATTENDANCE_STATUS_CONVERTER[row.statusIn] === 'CM' ?
                                                        <Button variant="contained" style={{background: '#64eb34'}}
                                                                className={props.classes.button}>
                                                            <span>CM</span>
                                                        </Button> :
                                                        <Button variant="outlined"
                                                                disabled={!props.enabled}
                                                                onClick={e => props.onChangeStatusIn(row, 'CM')}
                                                                className={props.classes.button}>
                                                            <span>CM</span>
                                                        </Button>
                                                    }
                                                    {row.prevTimeIn ?
                                                        <spam style={{paddingLeft: 16, paddingRight: 16}}></spam>
                                                        :
                                                        <>
                                                            {ATTENDANCE_STATUS_CONVERTER[row.statusIn] === 'KT' ?
                                                                <Button variant="contained"
                                                                        style={{background: 'yellow'}}
                                                                        className={props.classes.button}>
                                                                    <span>KT</span>
                                                                </Button> :
                                                                <Button variant="outlined"
                                                                        disabled={!props.enabled}
                                                                        onClick={e => props.onChangeStatusIn(row, 'KT')}
                                                                        className={props.classes.button}>
                                                                    <span>KT</span>
                                                                </Button>
                                                            }
                                                        </>
                                                    }

                                                    {ATTENDANCE_STATUS_CONVERTER[row.statusIn] === 'CP' ?
                                                        <Button variant="contained" style={{background: 'orange'}}
                                                                className={props.classes.button}>
                                                            <span>CP</span>
                                                        </Button> :
                                                        <Button variant="outlined"
                                                                disabled={!props.enabled}
                                                                onClick={e => props.onChangeStatusIn(row, 'CP')}
                                                                className={props.classes.button}>
                                                            <span>CP</span>
                                                        </Button>
                                                    }
                                                    {ATTENDANCE_STATUS_CONVERTER[row.statusIn] === 'KP' ?
                                                        <Button variant="contained" style={{background: 'red'}}
                                                                className={props.classes.button}>
                                                            <span>KP</span>
                                                        </Button> :
                                                        <Button variant="outlined"
                                                                disabled={!props.enabled}
                                                                onClick={e => props.onChangeStatusIn(row, 'KP')}
                                                                className={props.classes.button}>
                                                            <span>KP</span>
                                                        </Button>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </TableCell>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    classes={{
                        toolbar: props.classes.tablePagination,
                        actions: props.classes.tablePaginationAction
                    }}
                    rowsPerPageOptions={[100]}
                    component="div"
                    count={props.total}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={props.handleChangePage}
                    onChangeRowsPerPage={props.onChangeRowsPerPage}
                />
            </Paper>
        </Grid>
    );
}

export default withStyles(classes)(AttendanceResultValidation);
