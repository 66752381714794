import React from 'react';
import {Create, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import TeacherClazzFields from './TeacherClazzFields'

export const TeacherClazzCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<CustomToolbar/>} redirect="list">
            <Grid container spacing={8}>
                <TeacherClazzFields/>
            </Grid>
        </SimpleForm>
    </Create>
);

export default TeacherClazzCreate
