import React, {useState} from 'react';
import {Button, fetchEnd, fetchStart, refreshView} from 'react-admin';
import env from "../env";
import PrintIcon from '@material-ui/icons/Print';
import {printContent, showErrorNotify} from '../components'
import {connect} from 'react-redux';
import {compose} from 'redux';
import {change, submit} from "redux-form";
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {};

const PrintFileButton = (props) => {
    const {record, basePath} = props
    const [loading, setLoading] = useState(false)

    const handleClick = () => {
        if (record) {
            setLoading(true)
            printContent(`${env.baseURL}/api` + basePath + `/print-file?id=` + record.id)
                .then(() => {
                    props.refreshView();
                    setLoading(false)
                })
                .catch(() => {
                    props.refreshView();
                    setLoading(false)
                })
        } else {
            showErrorNotify("Vui lòng dữ liệu")
        }
    };

    return (
        <Button disabled={loading} onClick={handleClick} label="ra.action.printFile">
            <PrintIcon/>
        </Button>
    );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    submit,
    refreshView
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(
    PrintFileButton
);
