import React from 'react';
import {Edit, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import AdmissionSubjectFields from './AdmissionSubjectFields'

export const AdmissionSubjectEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <Grid container spacing={8}>
                <AdmissionSubjectFields/>
            </Grid>
        </SimpleForm>
    </Edit>
);

export default AdmissionSubjectEdit
