import React from 'react';
import {Datagrid, Filter, List, SelectField, TextField, TextInput} from 'react-admin';
import UserChannelRoles from "./UserChannelRoles";

const BlockFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const UserChannelList = props => (
    <List filters={<BlockFilter/>} {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="displayName"/>
            <TextField source="channelName"/>
            <SelectField source="channelRole" choices={UserChannelRoles} {...props} />
        </Datagrid>
    </List>
);

export default UserChannelList
