import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Icon from '@material-ui/core/Icon';

const classes = {
    root: {
        width: '100%',
        marginTop: 10,
    },
    paper: {
        marginTop: 3,
        width: '100%',
        overflowX: 'auto',
        marginBottom: 2,
    },
    table: {
        minWidth: 650,
    },
    icon: {
        fontSize: 18,
    },
};

class EventBatchValidation extends Component {

    render() {
        return (
            <div style={classes.root}>
                <Paper style={classes.paper}>
                    <Table style={classes.table} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>STT</TableCell>
                                <TableCell align="right">Hợp Lệ</TableCell>
                                <TableCell align="right">Mô tả lỗi</TableCell>
                                <TableCell align="right">Lớp</TableCell>
                                <TableCell align="right">Mã lớp</TableCell>
                                <TableCell align="right">Giáo viên</TableCell>
                                <TableCell align="right">Khối</TableCell>
                                <TableCell align="right">Mã học phần</TableCell>
                                <TableCell align="right">Tên học phần</TableCell>
                                <TableCell align="right">Tên nhóm HP</TableCell>
                                <TableCell align="right">Thứ</TableCell>
                                <TableCell align="right">Ngày bắt đầu</TableCell>
                                <TableCell align="right">Ngày kết thúc</TableCell>
                                <TableCell align="right">Tiết bắt đầu</TableCell>
                                <TableCell align="right">Tiết kết thúc</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.data.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell component="th" scope="row">
                                        {row.order}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.valid &&
                                        <Icon style={classes.icon} className='fa fa-check' color="primary"/>
                                        }
                                        {!row.valid &&
                                        <Icon style={classes.icon} className='fa fa-times' color="error"/>
                                        }
                                    </TableCell>
                                    <TableCell align="right">{row.errorMessage}</TableCell>
                                    <TableCell align="right">{row.clazzName}</TableCell>
                                    <TableCell align="right">{row.clazzId ? row.clazzId : 'Tạo mới'}</TableCell>
                                    <TableCell align="right">{row.gvcnName}</TableCell>
                                    <TableCell align="right">{row.gradeName}</TableCell>
                                    <TableCell align="right">{row.maHocPhan}</TableCell>
                                    <TableCell align="right">{row.tenHocPhan}</TableCell>
                                    <TableCell align="right">{row.tenNhomHocPhan}</TableCell>
                                    <TableCell align="right">{row.dow}</TableCell>
                                    <TableCell align="right">{row.startDate}</TableCell>
                                    <TableCell align="right">{row.endDate}</TableCell>
                                    <TableCell align="right">{row.startPeriod}</TableCell>
                                    <TableCell align="right">{row.endPeriod}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={this.props.total}
                        rowsPerPage={this.props.rowsPerPage}
                        page={this.props.page}
                        backIconButtonprops={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonprops={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.props.handleChangePage}
                        onChangeRowsPerPage={this.props.onChangeRowsPerPage}
                    />
                </Paper>
            </div>
        );
    }
}

export default EventBatchValidation;
