import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const foodMenus = [
  {id: 'COM', name: 'foodMenu.COM'},
  {id: 'CHAY', name: 'foodMenu.CHAY'},
  {id: 'CHAO', name: 'foodMenu.CHAO'},
  {id: 'NGHI', name: 'foodMenu.NGHI'},
]

export const EFoodMenuField = (props) => (
    <SelectField source={props.source} choices={foodMenus} {...props} />
)

export const EFoodMenuInput = (props) => (
    <SelectInput source={props.source} choices={foodMenus} {...props} />
)

