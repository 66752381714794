import React from 'react';
import { DateField, DateInput, Edit, SimpleForm, ReferenceInput, ReferenceManyField, Pagination , SelectInput, Datagrid, TextInput, TextField } from 'react-admin';

import { CustomToolbar, Grid, DateTimeField } from '../../components'

import AttendanceResultStatuses from './AttendanceResultStatuses'
const noop = () => false;

export const AttendanceResultSummaryEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar viewOnly={true} />}>
      <Grid container spacing={8}>
        <Grid item xs={12} sm={6} >
          <DateInput source="summaryDate" disabled />
        </Grid>
        <Grid item xs={12} sm={6} >
          <ReferenceInput source="unitId" reference="units" >
            <SelectInput source="name" disabled />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6} >
          <TextInput source="name" disabled {...props} />
        </Grid>
        <Grid item xs={12} sm={6} >
          <SelectInput source="authStatus" choices={AttendanceResultStatuses} disabled />
        </Grid>

        <Grid item xs={12} sm={12} >
          <ReferenceManyField reference="attendanceResultDetailses" target="attendanceResultSummaryId" 
                label={false} perPage={25} pagination={<Pagination rowsPerPageOptions={[]} />} >
            <Datagrid rowClick={noop} >
              <TextField source="id" />
              <TextField source="customerName" />
              {/* <DateField source="timeIn" showTime /> */}
              <DateTimeField source="timeIn" format="kk:mm DD-MM-YYYY" />
              <TextField source="statusIn" />
              <DateTimeField source="timeOut" format="kk:mm DD-MM-YYYY" />
              <TextField source="statusOut" />
            </Datagrid>
          </ReferenceManyField>
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
);

export default AttendanceResultSummaryEdit
