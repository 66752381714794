import EventDeviceIcon from '@material-ui/icons/ViewList';

// import EventDeviceList from './EventDeviceList';
import EventDeviceCreate from './EventDeviceCreate';
import EventDeviceEdit from './EventDeviceEdit';

export default {
  // list: EventDeviceList,
  create: EventDeviceCreate,
  edit: EventDeviceEdit,
  icon: EventDeviceIcon,
};
