import React from 'react';
import {Datagrid, EditButton, Filter, List, TextField, TextInput} from 'react-admin';

import {AuthStatusField, DateTimeField, DeleteReceiptButton} from '../../components'
import {MessageCategoryField} from "../../components/MessageCaategory";

const MessageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const VinaIDMessageList = props => (
    <List filters={<MessageFilter/>} {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="excerpt"/>
            <MessageCategoryField source="category"/>
            <AuthStatusField source="authStatus"/>
            <DateTimeField source="lastUpdatedOn" format="DD/MM/YYYY HH:mm"/>
            <TextField source="lastUpdatedBy"/>
            <EditButton label={""} variant="outlined" {...props}/>
            <DeleteReceiptButton title={'Không thể hoàn tác sau khi xoá, Bạn chắc chắn muốn xoá tin nhắn?'}
                                 path={'/api/vinaIDMessages'}/>
        </Datagrid>
</List>
);

export default VinaIDMessageList
