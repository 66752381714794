import React from 'react';
import {Edit, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import DisciplineCategoryFields from './DisciplineCategoryFields'

export const DisciplineCategoryEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <Grid container spacing={8}>
                <DisciplineCategoryFields/>
            </Grid>
        </SimpleForm>
    </Edit>
);

export default DisciplineCategoryEdit
