import React from 'react';
import {connect} from 'react-redux'
import {change} from 'redux-form'
import {REDUX_FORM_NAME, TextInput} from 'react-admin';
import env from "../env";
import {get} from "axios/index";
import './electron-popup.css';
import {showWarningNotify} from "./ShowNotify";

const electron = window.electron || (window.require ? window.require("electron") : null)

class GuestCreateUidInput extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (electron && electron.ipcRenderer) {
            electron.ipcRenderer.on('uid', this.onUID)
        }
    }

    componentWillUnmount() {
        if (electron && electron.ipcRenderer) {
            electron.ipcRenderer.removeListener('uid', this.onUID)
        }
    }

    onUID = (event, uid) => {
        const {dispatch, source} = this.props;
        this.checkExistGuestCardUid(uid).then(rs => {
            if (rs.data === true) {
                dispatch(change(REDUX_FORM_NAME, source, uid));
            } else {
                showWarningNotify('Thẻ không hợp lệ hoặc đã được sử dụng cho khách khác!');
                // Swal.fire({
                //   timer: 3000,
                //   icon: 'error',
                //   title: "Thẻ không hợp lệ hoặc đã được sử dụng cho khách khác!",
                //   customClass: {
                //     container: 'popup-z-index',
                //     popup: 'warning-bg',
                //     title: 'popup-text'
                //   }
                // });
                dispatch(change(REDUX_FORM_NAME, source, ''));
            }
        });

    };

    checkExistGuestCardUid(uid) {
        const url = `${env.baseURL}/api/guests/checkCardUid?cardUid=` + uid;
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': token
            }
        };
        return get(url, config);
    }

    render() {
        const {...props} = this.props;

        return (
            <TextInput {...props} disabled/>
        )
    }
}

export default connect(null, null)(GuestCreateUidInput)
