import React from 'react';
import {ReferenceInput, Create, required, SimpleForm, ReferenceArrayInput, AutocompleteArrayInput} from 'react-admin';

import {CustomToolbar, Grid, TreeCheckboxInput} from '../../components'

export const EventProductCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="list" >
      <Grid container spacing={8}>
        <React.Fragment>
          <Grid item xs={12} sm={6} >
            <ReferenceArrayInput source="eventIds"
                                 reference="events" {...props} allowEmpty>
              <AutocompleteArrayInput/>
            </ReferenceArrayInput>
          </Grid>
          <Grid item xs={12} sm={6} >
            <label>Danh sách phí *</label>
            <ReferenceInput source="productIds"
                            reference="billProducts"
                            perPage={999}
                            validate={required()}
                            {...props} >
              <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
            </ReferenceInput>
          </Grid>

        </React.Fragment>
      </Grid>
    </SimpleForm>
  </Create>
);

export default EventProductCreate
