import React from 'react';
import {Edit, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import KiotProductMenuFields from './KiotProductMenuFields'

export const KiotProductMenuEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <Grid container spacing={8}>
                <KiotProductMenuFields/>
            </Grid>
        </SimpleForm>
    </Edit>
);

export default KiotProductMenuEdit
