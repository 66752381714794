import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {AutocompleteInput, DateInput, ReferenceInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm, schoolYears, showErrorNotify, TreeCheckboxInput} from '../../components';
import env from '../../env';
import moment from 'moment';

class PaidByMonthsReport extends React.Component {
  state = {
    changeSchoolYear: false,
    unitIds: [],
    schoolYearId: schoolYears[0].id,
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD')
  };

  getReportUrl = () => {
    return `${env.baseURL}/reports/payments/paidByMonths` +
        `?pFromDate=` + this.state.fromDate +
        `&pToDate=` + this.state.toDate +
        `&pSchoolYearId=` + this.state.schoolYearId +
        `&pUnitIds=` +  this.state.unitIds;
  }

  excelReport = () => {
    if (! this.state.unitIds ||  this.state.unitIds.length === 0) {
      showErrorNotify("Vui lòng chọn danh sách lớp")
      return;
    }
    downloadFile(this.getReportUrl(), 'bao_cao_HS_da_dong_tien_theo_thang.xlsx')
  }

  validate = (values) => {
    const errors = {};
    // Validate
    if (values.fromDate && values.toDate && (values.fromDate > values.toDate) ){
      errors.toDate = 'To date must be greater than From date'
    }
    // Copy input values to params
    this.setState({
      fromDate: values.fromDate,
      toDate: values.toDate,
      schoolYearId: values.schoolYearId
    });
    if (this.state.changeSchoolYear) {
      this.setState({
        unitIds: [],
        changeSchoolYear: false
      })
    } else {
      this.setState({
        unitIds: values.unitIds
      })
    }
    return errors;
  }

  onSchoolYearChanged(e, value) {
    this.setState({
      schoolYearId: value,
      changeSchoolYear: true
    });
  }

  render() {
    return (
      <Card>
        <Title title="resources.paidByMonthsReport.name"/>
        <CardContent>
          <ReportForm params={this.state}
                      validate={this.validate}
                      excelReport={this.excelReport}
          >
              <Grid container spacing={8}>
                <Grid item xs={12} sm={6} >
                  <DateInput label="resources.common.fromDate" source="fromDate" validate={required()} {...this.props} />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <DateInput label="resources.common.toDate" source="toDate" validate={required()} {...this.props} />
                </Grid>

              </Grid>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6}>
                <ReferenceInput source="schoolYearId"
                                reference="schoolYears"
                                label="resources.schoolYears.name"
                                perPage={50}
                                validate={required()}
                                onChange={(e, value) => this.onSchoolYearChanged(e, value)}
                                {...this.props} >
                  <AutocompleteInput source="name" />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <label>Danh sách lớp *</label>
                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                  <ReferenceInput source="unitIds"
                                  reference="units"
                                  perPage={999}
                                  filter={{clazzWithParents: true, schoolYear: this.state.schoolYearId}}
                                  {...this.props} >
                    <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                  </ReferenceInput>
                </div>
              </Grid>
            </Grid>
          </ReportForm>
        </CardContent>
      </Card>
    )
  }
}

export default PaidByMonthsReport

