import DepartmentIcon from '@material-ui/icons/ViewList';

import DisciplineCategoryList from './DisciplineCategoryList';
import DisciplineCategoryCreate from './DisciplineCategoryCreate';
import DisciplineCategoryEdit from './DisciplineCategoryEdit';

export default {
  list: DisciplineCategoryList,
  create: DisciplineCategoryCreate,
  edit: DisciplineCategoryEdit,
  icon: DepartmentIcon,
};
