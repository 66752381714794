import React from 'react';
import Grid from '@material-ui/core/Grid'
import {AutocompleteInput, NumberInput, ReferenceInput, required, SelectInput} from 'react-admin';
import {TreeCheckboxInput} from "../../components";

const styles = {
    container: {
        width: 400,
    }
}

const ExemptionPolicyDetailFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="exemptionPolicyId" reference="exemptionPolicies"
                            validate={required()} allowEmpty {...props} >
                <AutocompleteInput source="name"/>
            </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="productId" reference="billProducts"
                            validate={required()} {...props} >
                <AutocompleteInput source="name"/>
            </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6}>
            <NumberInput source="value" validate={required()}  {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <SelectInput style={styles.container} source="reduceType" choices={[{
                id: 'PERCENT',
                name: 'Phần trăm'
            }, {
                id: 'DIRECT',
                name: 'Trực tiếp'
            }]}
                         validate={required()}  {...props}/>
        </Grid>
        {/*<Grid item xs={12} sm={6}>*/}
        {/*    <label>Miễn giảm phụ thuộc</label>*/}
        {/*    <ReferenceInput source="dependentPolicyIds"*/}
        {/*                    reference="exemptionPolicies"*/}
        {/*                    perPage={999}*/}
        {/*                    {...props} >*/}
        {/*        <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>*/}
        {/*    </ReferenceInput>*/}
        {/*</Grid>*/}
    </React.Fragment>
)

export default ExemptionPolicyDetailFields
