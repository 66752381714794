import CheckboxTree from "react-checkbox-tree";
import React from "react";
import {addField} from 'ra-core';

const CheckboxTreeInput = addField(props => {
    const {input, label, meta, ...rest} = props;
    const onCheck = (values, node) => {
        if (node.checked) {
            // onChange not update touched.
            input.onBlur(values)
        } else {
            input.onChange(values)
        }
    }

    const nodes = [{
        label: 'Chọn tất cả',
        value: -9999,
        children: rest.nodes
    }]

    return <>
        <label style={{color: (meta.touched && !!meta.error) ? 'red' : ''}}>{label}</label>
        <CheckboxTree {...rest} nodes={nodes} onCheck={onCheck} expanded={[-9999]} checked={input.value}/>
        <div style={{color: '#f44336', fontSize: '0.75rem'}}>{meta.touched && meta.error}</div>
    </>
})

export default CheckboxTreeInput