import React from 'react';
import Grid from '@material-ui/core/Grid'
import { BooleanInput, ReferenceInput, SelectInput, TextInput, required } from 'react-admin';

const VduGatewayConfigurationFields = props => (
  <React.Fragment>
    <Grid item xs={12} sm={6}>
      <ReferenceInput source="tenantId"
                      reference="tenants"
                      sort={{field: 'name', order: 'ASC'}}
                      validate={required()}
                      perPage={150}>
        <SelectInput source="name"/>
      </ReferenceInput>
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextInput source="baseUrl" validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextInput source="sn" validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextInput source="reportEmail" {...props} />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextInput source="connectionKey" validate={required()} {...props} />
    </Grid>
  </React.Fragment>
)

export default VduGatewayConfigurationFields
