import React, {Component, Fragment} from 'react';
import get from 'lodash/get';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import CheckboxTree from 'react-checkbox-tree';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconCancel from '@material-ui/icons/Cancel';
import {change, isSubmitting, submit} from 'redux-form';
import {connect} from 'react-redux';
import {Button, fetchEnd, fetchStart, NumberInput, refreshView, SaveButton, SimpleForm,} from 'react-admin';

const styles = {
  suggestDialog: {
    zIndex: '1 !important',
  },
  width100: {
    width: '100%',
  }
};

export class TreeCheckboxInputWithDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nodes: this.props.choices,
      selectedIds: [],
      showDialog: false,
      currentNode: null,
      expanded: [],
      params: {
        numOfDays: 0,
      }
    }

  }

  handleChange = (selectedValues, targetNode) => {
    if (targetNode.checked) {
      this.setState({showDialog: true, currentNode: targetNode})
    } else {
      this.setState({showDialog: false, currentNode: null})
    }
    this.props.input.onChange(selectedValues);
    if (this.props.onChecked) {
      this.props.onChecked(selectedValues);
    }
    this.setState({selectedIds: selectedValues})
  };

  dedupChoices = (choices) => {
    const {
      optionValue = "id",
    } = this.props;

    // De-duplicate items
    for (let i = 0; i < choices.length; ++i) {
      for (let j = i + 1; j < choices.length; ++j) {
        if (get(choices[j], optionValue) === get(choices[i], optionValue)) {
          choices.splice(i--, 1);
        }
      }
    }
  }

  findByParentId = (choices, parentId) => {
    const nodes = choices.filter(choice => choice.parentId === parentId )

    if (nodes.length > 0) {
      nodes.map(node => {
        node.children = this.findByParentId(choices, node.id)
        return false
      })
    }
    const nodeParents = choices.filter(choice => choice.parentId === undefined )
    for (let i = 0; i < nodeParents.length; i++) {
      nodes.push(nodeParents[i])
    }
    return nodes;
  }

  /**
   * CheckboxTree uses (value, label) tube, so we need convert (id, name) ==> (value, label)
   */
  toTreeCheckboxNode = (choice, optionValue, optionText) => {
    if (choice.children === undefined || choice.children.length === 0) {
      let numOfDays = get(choice, "numOfDays");
      if (!numOfDays) {
        numOfDays = 0;
      }
      return {
        value: get(choice, optionValue) + '_' + numOfDays,
        label: get(choice, optionText) + ' - Ngày thu: ' + numOfDays,
      }
    }
    return {
      value: get(choice, optionValue) + '',
      label: get(choice, optionText) + '',
      children: choice.children.map(child => {
        return this.toTreeCheckboxNode(child, optionValue, optionText)
      }),
    }

  };

  validate = (values) => {
    const errors = {};
    // Validate
    if (values.numOfDays === undefined || values.numOfDays < 0) {
      errors.numOfDays = 'Vui lòng nhập số ngày'
    }
    // Copy input values to params
    this.state.params = values;

    return errors;
  };

  handleCloseClick = () => {
    this.setState({showDialog: false, currentNode: null})
  };

  handleSaveClick = () => {
    const {submit} = this.props;
    submit('numOfDay-quick-create');
  };

  handleSubmit = () => {
    if (this.state.currentNode && this.state.currentNode.checked) {
      this.setChoice(this.state.nodes, this.state.currentNode)
    }
    this.setState({showDialog: false});
    let updatedValues = [];
    for (let i = 0; i <  this.state.nodes.length; i++) {
      let child = this.state.nodes[i];
      this.findTargetNode(child, updatedValues);
    }

    const mergeValues = this.mergeValues(this.state.selectedIds, updatedValues);
    this.props.input.onChange(mergeValues);
    if (this.props.onChecked) {
      this.props.onChecked(mergeValues);
    }
  };

  mergeValues = (beforeList, afterList) => {
    let arrB4 = [];
    for (let i = 0; i < beforeList.length; i++) {
      arrB4.push(parseInt(beforeList[i].split('_')[0]))
    }
    let arrAfter = [];
    for (let i = 0; i < afterList.length; i++) {
      arrAfter.push(parseInt(afterList[i].split('_')[0]))
    }

    let mergeList = [];
    for (let i = 0; i < arrB4.length; i++) {
      const itemB4 = arrB4[i];
      if (arrAfter.includes(itemB4)) {
        mergeList.push(afterList[arrAfter.indexOf(itemB4)])
      } else {
        mergeList.push(beforeList[i])
      }
    }
    return mergeList;
  }

  findTargetNode = (choices, selectedValues) => {
    if (choices.id == this.state.currentNode.value.split('_')[0]) {
      this.setCheckedNodes(choices, selectedValues);
    }
    if (choices.children === undefined || choices.children.length === 0) {
      return;
    }
    choices.children.map(child => {
      this.findTargetNode(child, selectedValues)
    })
  }

  setCheckedNodes(choice, selectedValues) {
    if (choice.children === undefined || choice.children.length === 0) {
      const value = choice.id + '_' + this.state.params.numOfDays;
      if (!selectedValues.includes(value)) {
        selectedValues.push(value);
      }
      return;
    }
    choice.children.map(child => {
      return this.setCheckedNodes(child, selectedValues)
    })
  }

  setNumOfDay = (choices) => {
    if (choices.children === undefined || choices.children.length === 0) {
      choices.numOfDays = this.state.params.numOfDays
    } else {
      choices.children.map(child => {
        return this.setNumOfDay(child)
      })
    }
  };

  setChoice = (choices, choice) => {
    for (let i = 0; i < choices.length; i++) {
      let child = choices[i];
      const realValue = choice.value.split('_')[0];
      if (child.id == realValue) {
        this.setNumOfDay(child);
        break;
      }
    }
  };

  componentWillReceiveProps(nextProps, nextContext) {
    // temp fix when units length different
    if (this.props.schoolYear === nextProps.schoolYear
     && nextProps.choices.length !== this.state.nodes.length) {
      this.setState({
        nodes: nextProps.choices,
        selectedIds: [],
        showDialog: false,
        currentNode: null,
        expanded: [],
        params: {
          numOfDays: 0,
        }
      });
    }
  }

  render() {
    const {
      isSubmitting,
      classes,
      optionValue = "id", 
      optionText = "name",
    } = this.props;
    const {showDialog} = this.state;

    this.dedupChoices(this.state.nodes);
    const choices = this.findByParentId(this.state.nodes, null)
    const nodes = choices.map(choice => {
      return this.toTreeCheckboxNode(choice, optionValue, optionText)
    });

    const topLevel = nodes.map(node => node.value)
    const expanded = this.props.expandTopLevel ? this.state.expanded.concat(topLevel) : this.state.expanded

    return (
      <Fragment>
        <CheckboxTree
          nodes={nodes}
          checked={this.props.input.value || []}
          onCheck={this.handleChange}

          expanded={expanded}
          onExpand={expanded => this.setState({expanded})}

          showNodeIcon={false}
        />
        <Dialog
          className={classes.suggestDialog}
          fullWidth={true}
          maxWidth='sm'
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Nhập số ngày thu dự kiến"
        >
          <DialogTitle>Nhập số ngày thu dự kiến</DialogTitle>
          <DialogContent>
            <SimpleForm
              form="numOfDay-quick-create"
              onSubmit={this.handleSubmit}
              toolbar={null}
              validate={this.validate}
            >
              <NumberInput label="resources.billBatchTemplate.numOfDays" source="numOfDays"/>
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton
              saving={isSubmitting}
              onClick={this.handleSaveClick}
            />
            <Button
              label="ra.action.cancel"
              onClick={this.handleCloseClick}
            >
              <IconCancel/>
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting('numOfDay-quick-create')(state)
});

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  submit,
  refreshView
};


export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(
  TreeCheckboxInputWithDialog
);