import React, {useRef, useState} from 'react';
import {DateInput, required, SelectInput, Title} from "react-admin";
import {downloadFile, ReportForm, schoolYears} from "../../components";
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import env from "../../env";
import moment from "moment";

const ExemptionPolicyReport = (props) => {
    const ref = useRef({
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        schoolYear: schoolYears[0]?.id
    })

    const [loading, setLoading] = useState(false)
    const excelReport = () => {
        const {fromDate, toDate, schoolYear} = ref.current
        setLoading(true)
        downloadFile(`${env.baseURL}/reports/exemption?fromDate=${fromDate}&toDate=${toDate}&schoolYearId=${schoolYear}`, 'bao_cao_mien_giam.xlsx').then(() => {
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    };

    const validate = (values) => {
        const errors = {};
        // Validate
        if (values.fromDate && values.toDate && (values.fromDate > values.toDate)) {
            errors.toDate = 'To date must be greater than From date'
        }
        // Copy input values to params
        ref.current = values
        return errors;
    };

    return (
        <div>
            <Title title="resources.exemption-policy-report.name"/>
            <CardContent>
                <ReportForm params={ref.current}
                            validate={validate}
                            excelReport={excelReport}
                            isLoading={loading}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="fromDate" validate={required()}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.toDate" source="toDate" validate={required()} />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <SelectInput label="resources.schoolYears.name" source="schoolYear" choices={schoolYears}/>
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </div>
    )
};

export default ExemptionPolicyReport;
