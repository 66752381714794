import React from 'react';
import {AutocompleteInput, Datagrid, DateInput, Filter, List, ReferenceInput, TextField, TextInput} from 'react-admin';
import {EFoodMenuField} from "../../components/EFoodMenu";
import {DateTimeField, schoolYears} from "../../components";

const MealRegistrationFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Tìm tên" source="q" alwaysOn/>
        <ReferenceInput label="Lớp" source="unitId" reference="baseUnits" alwaysOn allowEmpty
                        {...props}
                        filter={{schoolYear: schoolYears[0].id, isFullDay: true}}
                        sort={{field: 'name', order: 'ASC'}}
                        perPage={999}
        >
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <DateInput label="Ngày" source="eatDate" alwaysOn allowEmpty/>
    </Filter>
);

export const MealRegistrationList = props => {
    return (
        <List filters={<MealRegistrationFilter/>}
              bulkActions={false}
              exporter={false}
              perPage={20}
              {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id"/>
                <TextField source="customerId"/>
                <TextField source="customerName"/>
                <TextField source="baseUnitName"/>
                <TextField source="unitName"/>
                <DateTimeField source="eatDate" format="DD-MM-YYYY"/>
                <EFoodMenuField source="regisFoodMenu"/>
                <EFoodMenuField source="takenFoodMenu"/>
                <EFoodMenuField source="changedFoodMenu"/>
            </Datagrid>
        </List>
    )
}

export default MealRegistrationList
