import React from 'react';
import {Datagrid, List, TextField} from 'react-admin';

export const BillItemList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id"/>
        </Datagrid>
    </List>
);

export default BillItemList
