import React, {Component} from 'react';
import {connect} from 'react-redux'
import {change} from 'redux-form'
import {
  Datagrid, DateField, Filter, List, TextField, TextInput, Create,
  REDUX_FORM_NAME,
  SimpleForm,
} from 'react-admin';
import {CustomToolbar, Grid, showErrorNotify, showSuccessNotify} from '../../components'
import GuestOutFields from './GuestOutFields'
import './style.css';
import env from "../../env";
import {get, post} from "axios";

const GuestFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn/>
  </Filter>
);

const electron = window.electron || (window.require ? window.require("electron") : null)

class GuestOutList extends Component {
  guestCardUid = null;
  guestId = null;
  responseMessage = 'Không tìm thấy thông tin khách.';
  showGuestInfo = false;
  showResponseMessage = false;

  constructor(props) {
    super(props);
    this.onSubmitRefresh = this.onSubmitRefresh.bind(this);
  }

  componentDidMount() {
    if (electron) {
      electron.ipcRenderer.on('uid', this.onUID)
    }
  }

  componentWillUnmount() {
    this.guestCardUid = null;
    this.guestId = null;
    if (electron) {
      electron.ipcRenderer.removeListener('uid', this.onUID)
    }
  }

  onUID = (event, uid) => {
    const {dispatch} = this.props;
    console.log('receive guest out uid from electron ' + uid);
    this.getGuestOutData(uid).then(rs => {
      if (rs.data) {
        this.showGuestInfo = true;
        this.guestId = rs.data.id;
        this.guestCardUid = uid;
        this.forceUpdate();
        dispatch(change(REDUX_FORM_NAME, "id", rs.data.id));
        dispatch(change(REDUX_FORM_NAME, "name", rs.data.name));
        dispatch(change(REDUX_FORM_NAME, "idNo", rs.data.idNo));
        dispatch(change(REDUX_FORM_NAME, "workDescription", rs.data.workDescription));
        dispatch(change(REDUX_FORM_NAME, "contactName", rs.data.contactName));
        dispatch(change(REDUX_FORM_NAME, "cardUid", uid));
      } else {
        this.showGuestInfo = false;
        this.responseMessage = "Không tìm thấy thông tin khách.";
        this.forceUpdate();
      }

    });
  };

  getGuestOutData(cardUid) {
    const url = `${env.baseURL}/api/guestOuts/findByCardUid?cardUid=` + cardUid;
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'content-type': 'application/json',
        'Authorization': token
      }
    };
    return get(url, config);
  }

  onSubmitRefresh(e) {
    e.preventDefault();
    const data = {};
    data['id'] = this.guestId;
    data['cardUid'] = this.guestCardUid;
    this.saveGuestOut(data).then(rs => {
      if (rs.status === 200) {
        showSuccessNotify("Lưu Khách ra thành công!");
        this.setState({
          showGuestInfo: false
        });
        setTimeout(
          function() {
            window.location.reload()
          }
            .bind(this),
          3000
        );
      } else {
        showErrorNotify("Lưu Khách ra không thành công!");
      }
    });
  }

  saveGuestOut(data) {
    const url = `${env.baseURL}/api/guestOuts`;
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'content-type': 'application/json',
        'Authorization': token
      }
    };
    return post(url, data, config)
  }

  render() {
    return (
      <>
        { this.showGuestInfo &&
          <Create title=' ' {...this.props}>
            <SimpleForm toolbar={<CustomToolbar viewOnly={true} refresh={true} onSubmitRefresh={this.onSubmitRefresh} />} >
              <Grid container spacing={8}>
                <GuestOutFields/>
              </Grid>
            </SimpleForm>
          </Create>
         }

        <List filters={<GuestFilter/>} {...this.props} exporter={false}>
          <Datagrid>
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="idNo"/>
            <TextField source="unitName"/>
            <DateField source="inDateTime" showTime/>
          </Datagrid>
        </List>
      </>
    );
  }
}

export default connect(null, null)(GuestOutList)
