import React from 'react';
import {Create, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import CustomerSubjectResultBatchFields from './CustomerSubjectResultBatchFields'

const defaultValue = {
    hocKi: 'GK1'
}
export const CustomerSubjectResultBatchCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<CustomToolbar/>} redirect="list" defaultValue={defaultValue}>
            <Grid container spacing={8}>
                <CustomerSubjectResultBatchFields/>
            </Grid>
        </SimpleForm>
    </Create>
);

export default CustomerSubjectResultBatchCreate
