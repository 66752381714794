import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {BooleanInput, DateInput, LongTextInput, ReferenceInput, required, Title} from 'react-admin';
import {NotifyForm, schoolYears, showErrorNotify, TreeCheckboxInput} from '../../components';
import env from '../../env';
import moment from "moment";
import {downloadFilePost} from "../../components/FileDownload";
import TreeCheckBoxCustomInput from "../../components/TreeCheckBoxCustomInput";
import cachedApis from "../../api/cachedApis";
import utils from "../../utils/utils";
import axiosInstance from "../../api/axios";

class BillBatchItemNotify extends React.Component {
    state = {
        loading: false,
        displayDueDate: true,
        displayDept: true,
        billMonth: moment().format('YYYY-MM-DD'),
        customers: [],
        params: {
            customerIds: [],
            note: '',
        }
    };
    componentDidMount(prevProps, prevState, snapshot) {
        axiosInstance
            .get('/api/customersByUnits?studentOnly=true')
            .then(rs => this.setState({customers: rs.data.content || []}))
    }

    getReportUrl = (fullDay, allStudent) => {
        this.setState({loading: true})
        if (!this.state.params.note) {
            this.state.params.note = '';
        }
        const data = {
            fullDayOnly: fullDay,
            allStudent: allStudent,
            displayDueDate: this.state.params.displayDueDate,
            displayDept: this.state.params.displayDept,
            note: this.state.params.note,
            billMonthStr: this.state.params.billMonth,
            customerIds: this.state.params.customerIds
        }
        downloadFilePost(`${env.baseURL}/reports/billBatchItemNotify`, 'ke_hoach_dong_gop.xlsx', data)
            .then(() => {
                this.setState({loading: false})
            })
            .catch(() => {
                this.setState({loading: false})
            })
    }

    notifyAllStudents = () => {
        if (this.state.params.customerIds?.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách học sinh")
            return;
        }
        this.getReportUrl(false, true)
    };

    fullDayOnly = () => {
        if (this.state.params.customerIds?.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách học sinh")
            return;
        }
        this.getReportUrl(true, false)
    };

    notFullDayOnly = () => {
        if (this.state.params.customerIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách học sinh")
            return;
        }
        if (!this.state.params.note) {
            this.state.params.note = '';
        }
        this.getReportUrl(false, false)
    };

    validate = (values) => {
        const errors = {};

        // Copy input values to params
        this.setState({params: {...this.state.params, ...values}})

        return errors;
    };

    render() {
        return (
          <Card>
              <Title title="resources.billBatchItemNotify.name" />
              <CardContent>
                  <NotifyForm validate={this.validate}
                              allClazzes={this.notifyAllStudents}
                              fullDayClazzes={this.fullDayOnly}
                              notFullDayClazzes={this.notFullDayOnly}
                              params={this.state}
                              isLoading={this.state.loading}
                  >
                      <Grid container spacing={8}>
                          <Grid item xs={12} sm={6} >
                              <DateInput label="resources.common.month" source="billMonth" validate={required()} {...this.props} />
                          </Grid>
                          <Grid item xs={12} sm={6} >
                          </Grid>

                      </Grid>
                      <Grid container spacing={8}>
                          <Grid item xs={12} sm={6} >
                              <BooleanInput label="resources.billBatchItemNotify.displayDueDate" source="displayDueDate" {...this.props}  />
                          </Grid>
                          <Grid item xs={12} sm={6} >
                              <BooleanInput label="resources.billBatchItemNotify.displayDept" source="displayDept" {...this.props} />
                          </Grid>

                      </Grid>
                      <Grid container spacing={8}>
                          <Grid item xs={12} sm={6}>
                              <LongTextInput label="resources.billBatchItemNotify.note"
                                             rows={5}
                                             style={{maxWidth: '90%'}}
                                             multiline
                                             source="note" {...this.props} />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <label><b>Danh sách học sinh</b></label>
                              <div style={{width: 500, maxHeight: '300px', overflow: 'auto', marginTop: 10}}>
                                  <TreeCheckBoxCustomInput data={this.state.customers} expandTopLevel={true}
                                                           onChange={(value) => this.setState({
                                                               params: {
                                                                   ...this.state.params,
                                                                   customerIds: utils.extractCustomerIdsV2(value)
                                                               }
                                                           })}/>
                              </div>
                          </Grid>
                      </Grid>
                  </NotifyForm>

              </CardContent>
          </Card>
        )
    }
}

export default BillBatchItemNotify

