import BlockIcon from '@material-ui/icons/ViewList';

import AdmissionResultTemplateList from './AdmissionResultTemplateList';
import AdmissionResultTemplateCreate from './AdmissionResultTemplateCreate';
import AdmissionResultTemplateEdit from './AdmissionResultTemplateEdit';

export default {
  list: AdmissionResultTemplateList,
  create: AdmissionResultTemplateCreate,
  edit: AdmissionResultTemplateEdit,
  icon: BlockIcon,
};
