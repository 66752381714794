import React from 'react';
import Grid from '@material-ui/core/Grid'
import {required, TextInput} from 'react-admin';
import {FoodTypeInput} from "./FoodType";

const FoodsFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <FoodTypeInput source="type"
                           validate={required()}
                           label="resources.foods.fields.type"
            />
        </Grid>
    </React.Fragment>
)

export default FoodsFields
