import TuyenSinhInfoIcon from '@material-ui/icons/ViewList';

import AdmissionChuyenList from './AdmissionChuyenList';
import AdmissionEdit from './AdmissionChuyenEdit';

export default {
  list: AdmissionChuyenList,
  edit: AdmissionEdit,
  icon: TuyenSinhInfoIcon,
};
