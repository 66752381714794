import React, {Component} from 'react';
import {AutocompleteInput, Edit, REDUX_FORM_NAME, ReferenceInput, required, SimpleForm, TextInput} from 'react-admin';
import {CircularProgress} from '@material-ui/core';
import {
  CustomToolbar,
  FileUploadComponent,
  Grid,
  HocKiInput, schoolYears,
  showErrorNotify,
  showInfoNotify,
  showSuccessNotify,
  showWarningNotify
} from '../../components'
import env from "../../env";
import {post} from "axios";
import CustomerSubjectResultBatchValidation from "./CustomerSubjectResultBatchValidation";
import {connect} from 'react-redux';
import {change} from 'redux-form'
import Swal from 'sweetalert2'

export class CustomerSubjectResultBatchEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      batchId: props.id,
      showValidateBtn: false,
      showValidationData: false,
      validationData: [],
      page: 0,
      rowsPerPage: 25,
      showData: [],
      subjects: [],
      isLoading: false,
      showUploadFile: true,
    };
    this.onFileUpload = this.onFileUpload.bind(this);
    this.setShowData = this.setShowData.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onFileUpload(e) {
    e.preventDefault(); // Stop form submit
    if (this.state.file == null) {
      return;
    }
    this.setState({
      isLoading: true
    });
    showInfoNotify("Đang xử lí file, vui lòng chờ trong giây lát!");
    this.fileUpload(this.state.file).then((response) => {
      if (response.data.success) {
        const responseData = response.data;
        this.setState({
          showValidationData: true,
          showValidateBtn: true,
          validationData: responseData.data,
          subjects: responseData.subjects,
          isLoading: false
        });
        // one of record is not valid
        if (!responseData.valid) {
          // show noti  fication here
          showWarningNotify("Có dữ liệu lỗi hoặc chưa đúng thông tin, vui lòng kiểm tra lại thông tin dữ liệu!");
          this.setState({
            showValidateBtn: false,
          });
        } else {
          showSuccessNotify("Toàn bộ dữ liệu đều đúng, ấn nút Duyệt để lưu!");
        }
        const startIdx = this.state.page * this.state.rowsPerPage;
        const endIdx = (this.state.page + 1) * this.state.rowsPerPage;
        this.setShowData(startIdx, endIdx, null);
      } else {
        this.setState({
          isLoading: false,
          showValidationData: false,
          validationData: [],
          subjects: []
        });
        showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
      }
    })
      .catch(error => {
      showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
      this.setState({
        isLoading: false,
        showValidationData: false,
        validationData: [],
        subjects: []
      })
    });
  }

  fileUpload(file) {
    const url = `${env.baseURL}/api/customerSubjectResultBatches/validate-item`;
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', this.state.batchId);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': token
      }
    }
    return post(url, formData, config)
  }

  handleChangePage(event, newPage) {
    const startIdx = newPage * this.state.rowsPerPage;
    const endIdx = (newPage + 1) * this.state.rowsPerPage;
    this.setShowData(startIdx, endIdx);
    this.setState({page: newPage});
  }

  handleChangeRowsPerPage(event) {
    this.setState({rowsPerPage: event.target.value});
    const startIdx = this.state.page * event.target.value;
    const endIdx = (this.state.page + 1) * event.target.value;
    this.setShowData(startIdx, endIdx);
  }

  setShowData(startIdx, endIdx) {
    const data = [];
    let filteredData = this.state.validationData;
    if (endIdx > filteredData.length) {
      endIdx = filteredData.length;
    }
    for (let i = startIdx; i < endIdx; i++) {
      data.push(filteredData[i]);
    }

    this.setState({showData: data});
  }

  onSubmit(e) {
    e.preventDefault();
    const rq = {
      data: this.state.validationData.filter(e => e.valid),
      batchId: this.state.batchId
    };
    Swal.fire({
      title: "Bạn chắc chắn muốn duyệt kết quả này?",
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      focusConfirm: false,
    })
      .then((result) => {
        if (result.value) {
          this.setState({
            isLoading: true
          });
          showInfoNotify("Đang duyệt, vui lòng đợi trong giây lát!");
          this.state.showValidateBtn = false;
          this.forceUpdate()
          this.saveBatchItem(rq).then(rs => {
            if (rs.data.success) {
              const {dispatch} = this.props;
              dispatch(change(REDUX_FORM_NAME, 'authStatus', 'AUTHORIZED'));
              showSuccessNotify("Đã duyệt file thành công!");
              this.setState({
                showUploadFile: false,
                isLoading: false,
                showValidationData: false,
                validationData: [],
                showData: []
              })
            }
          })
            .catch(error => {
              showErrorNotify("Duyệt file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
              this.setState({
                isLoading: false,
              })
            });
        }
      });
  }

  saveBatchItem(data) {
    const url = `${env.baseURL}/api/customerSubjectResultBatches/save-item`;
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'content-type': 'application/json',
        'Authorization': token
      }
    }
    return post(url, data, config)
  }

  render() {
    return (
      <Edit {...this.props}>
        <SimpleForm toolbar={<CustomToolbar/>}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={6}>
              <ReferenceInput source="unitId"
                              reference="clazzes"
                              label="resources.clazzes.name"
                              filter={{schoolYear: schoolYears[0].id}}
                              perPage={10}
                              validate={required()}
                              >
                <AutocompleteInput source="name"/>
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6} >
              <HocKiInput source="hocKi" validate={required()} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput source="description" />
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            {
              this.state.showUploadFile && <Grid item xs={12} sm={6}>
                <FileUploadComponent onFileChanged={(e) => this.setState({file: e.target.files[0]})}
                                     onFileUpload={this.onFileUpload} showValidateBtn={this.state.showValidateBtn}
                                     onSubmit={this.onSubmit}
                />
                {
                  this.state.isLoading && <CircularProgress color="secondary"/>
                }
              </Grid>
            }

            {
              this.state.showValidationData && <CustomerSubjectResultBatchValidation
                data={this.state.showData}
                subjects={this.state.subjects}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                handleChangePage={this.handleChangePage}
                total={this.state.validationData.length}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}/>
            }
          </Grid>
        </SimpleForm>
      </Edit>
    )
  }
}

export default connect(null, null)(CustomerSubjectResultBatchEdit);
