import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const type = [
  {id: 'Chăm', name: 'Chăm'},
  {id: 'Khơ-me', name: 'Khơ-me'},
]

export const ExemptionNationTypeField = (props) => (
    <SelectField source={props.source} choices={type} {...props} />
)

export const ExemptionNationTypeInput = (props) => (
    <SelectInput source={props.source} choices={type} {...props} />
)
