import React, {useEffect, useState} from 'react';

import {EditButton} from 'react-admin';

const EditButtonWithAuthStatus = (props) => {
    const [showBtn, setShowBtn] = useState(false)
    useEffect(() => {
        setShowBtn(props.record.status === 'DRAFT' || props.record.authStatus === 'DRAFT' || props.record.authStatus === 'UNAUTHORIZED')
    }, [props.record])

    return (
        <>
            {showBtn &&
            <EditButton {...props} />
            }
        </>
    )
}

export default EditButtonWithAuthStatus
