import React, {Component} from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { translate, withDataProvider, GET_MANY_REFERENCE } from 'react-admin';

export const schoolYears = JSON.parse(localStorage.getItem("schoolYears"));

const CHANGE_SCHOOL_YEAR = 'CHANGE_SCHOOL_YEAR'

export const getSelectedSchoolYear = () => {
  return localStorage.getItem('schoolYear')
}

const setSelectedSchoolYear = (schoolYear) => {
  localStorage.setItem('schoolYear', schoolYear);
}

export const schoolYearReducer = ((previousState = getSelectedSchoolYear(), { type, payload }) => {
  if (type === CHANGE_SCHOOL_YEAR) {
    return payload;
  }
  return previousState;
})

export const changeSchoolYear = schoolYear => ({
  type: CHANGE_SCHOOL_YEAR,
  payload: schoolYear,
});


class SchoolYearSelectInput extends Component {
  state = {
    selectedSchoolYear: getSelectedSchoolYear(),
    schoolYears: [],
  };

  componentDidMount() {
    this.loadSchoolYears()
  }

  loadSchoolYears = () => {
    const { dataProvider } = this.props;

    const options = {
      target: 'category',
      id: 'SCHOOL_YEAR',
      pagination: { page: 1, perPage: 100 },
      sort: {field: 'name', order: 'DESC'},
      filter: {},
    }

    dataProvider(GET_MANY_REFERENCE, `settings`, options)
    .then((response) => {
      const schoolYears = response.data.map(setting => ({
        name: setting.name,
        value: setting.stringValue,
      }))
      let selectedSchoolYear = this.state.selectedSchoolYear
      if (!selectedSchoolYear && schoolYears.length > 0) {
        selectedSchoolYear = schoolYears[0].name
      }
      this.setState({ selectedSchoolYear, schoolYears })
    })
  }

  handleChange = (event) => {
    const selectedSchoolYear = event.target.value;
    setSelectedSchoolYear(selectedSchoolYear)
    this.setState({ selectedSchoolYear })
    this.props.changeSchoolYear(selectedSchoolYear)
  }

  render() {
    return (
      <Select value={this.state.selectedSchoolYear} onChange={this.handleChange} >
        {this.state.schoolYears.map(setting => (<MenuItem value={setting.name}>{setting.value}</MenuItem>))}
      </Select>
    )
  }
}

export default compose(
  translate,
  withDataProvider,
  connect(undefined, { changeSchoolYear }),
)(SchoolYearSelectInput);

