import React from 'react';

import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {AutocompleteInput, DateInput, ReferenceInput, required, Title} from 'react-admin';
import {downloadFile, IFrameHtmlReport, NotifyForm, schoolYears, TreeCheckboxInput} from '../../components';
import env from '../../env';

class DeptStudentsReport extends React.Component {
  state = {
    changeSchoolYear: false,
    unitIds: [],
    schoolYearId: schoolYears[0].id,
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
  };

  getReportUrl = (type, fullDay) => {
    return `${env.reportURL}/reports/payments/studentsInDept` +
      `?pSchoolYearId=` + this.state.schoolYearId +
      `&pUnitIds=` + this.state.unitIds +
      `&pFromDate=` + this.state.fromDate +
      `&pToDate=` + this.state.toDate +
      `&fullDayOnly=` + fullDay;
  }

  inDeptAllClassType = () => {
    downloadFile(this.getReportUrl('XLS', false), 'bc_HS_chua_dong_hoc_phi.xlsx')
  }

  fullDayOnly = () => {
    downloadFile(this.getReportUrl('XLS', true), 'bc_HS_chua_dong_hoc_phi.xlsx')
  }

  validate = (values) => {
    const errors = {};
    // Validate
    if (values.fromDate && values.toDate && (values.fromDate > values.toDate) ){
      errors.toDate = 'To date must be greater than From date'
    }
    // Copy input values to params
    this.setState({
      fromDate: values.fromDate,
      toDate: values.toDate
    });
    if (this.state.changeSchoolYear) {
      this.setState({
        unitIds: [],
        changeSchoolYear: false
      })
    } else {
      this.setState({
        unitIds: values.unitIds
      })
    }

    return errors;
  }

  onSchoolYearChanged(e, value) {
    this.setState({
      schoolYearId: value,
      changeSchoolYear: true
    });
  }

  render() {
    return (
      <Card>
        <Title title="resources.deptStudentsReport.name" />
        <CardContent>
          <div>Báo cáo danh sách học sinh chưa đóng tiền theo kế hoạch: ngày bắt đầu kế hoạch trong khoảng thời gian
            được chọn
          </div>
          <NotifyForm params={this.state}
            validate={this.validate}
                      allClazzes={this.inDeptAllClassType}
                      fullDayClazzes={this.fullDayOnly}
          >
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6} >
                <DateInput label="resources.common.fromDate" source="fromDate" validate={required()} {...this.props} />
              </Grid>
              <Grid item xs={12} sm={6} >
                <DateInput label="resources.common.toDate" source="toDate" validate={required()} {...this.props} />
              </Grid>
              <Grid container spacing={8}>
                <Grid item xs={12} sm={6}>
                  <ReferenceInput source="schoolYearId"
                                  reference="schoolYears"
                                  label="resources.schoolYears.name"
                                  perPage={50}
                                  validate={required()}
                                  onChange={(e, value) => this.onSchoolYearChanged(e, value)}
                                  {...this.props} >
                    <AutocompleteInput source="name" />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Danh sách lớp</label>
                  <div style={{maxHeight: '300px', overflow: 'auto'}}>
                    <ReferenceInput source="unitIds"
                                    reference="units"
                                    perPage={999}
                                    filter={{clazzWithParents: true, schoolYear: this.state.schoolYearId}}
                                    {...this.props} >
                      <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                    </ReferenceInput>
                  </div>
                </Grid>
              </Grid>

            </Grid>
          </NotifyForm>

          {
            this.state.openHtmlReport &&
            <IFrameHtmlReport src={this.state.htmlReportUrl} />
          }
        </CardContent>
      </Card>
    )
  }
}

export default DeptStudentsReport;

