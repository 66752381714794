import React, {useState} from 'react';
import {Create, showNotification, SimpleForm} from 'react-admin';

import {Grid, showErrorNotify, showInfoNotify, showSuccessNotify} from '../../components'
import MessageFields from './SmsMessageFields'
import utils from "../../utils/utils";
import axiosInstance from "../../api/axios";
import {connect} from 'react-redux';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';

const defaultValue = {
    authStatus: 'DRAFT',
    hocKy: null,
    templates: []
}
let timeout;
export const SmsMessageCreate = props => {
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({
        hocKy: null,
        freeText: null,
        authStatus: defaultValue.authStatus,
        customerIds: [],
        category: defaultValue.category,
        cuzIds: [],
        isRedirect: false,
        forceSms: false,
    });
    const [templates, setTemplates] = useState([]);

    const handleSelectChange = (prop, e) => {
        if (!e) {
            return
        }
        timeout = setTimeout(() => {
            setValues({...values, [prop]: e});
            if (prop === 'category') {
                const arr = templates.filter(s => s.id === e)
                console.log('aaa', arr)
            }
        }, 500);
    }
    const handleChange = prop => e => {
        if (!e) {
            return
        }

        let value
        if (['cuzIds'].includes(prop)) {
            value = e;
        } else {
            if (e.target) {
                value = e.target.value ? e.target.value : e.target.checked;
            } else {
                value = e
            }
        }
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            setValues({...values, [prop]: value});
        }, 500);
    };

    function onDirectChange(prop, val) {
        setValues({...values, [prop]: val});
    }

    function save() {
        if (!validate()) {
            return;
        }
        setLoading(true)
        values.customerIds = utils.extractCustomerIds(values.cuzIds);
        const data = {
            customerIds: values.customerIds,
            category: values.category,
            hocKy: values.hocKy,
            freeText: values.freeText,
            forceSms: values.forceSms
        }
        const url = `/api/smsMessages`;
        axiosInstance.post(url, data).then(rs => {
            setLoading(false)
            props.showNotification('Tạo thành công');
            setValues({...values, isRedirect: true});
        }, err => {
            setLoading(false)
            props.showNotification(err.message, 'warning');
        })
    }

    function authorize() {
        if (!validate()) {
            return;
        }
        setLoading(true)
        values.customerIds = utils.extractCustomerIds(values.cuzIds);

        const data = {
            customerIds: values.customerIds,
            category: values.category,
            hocKy: values.hocKy,
            forceSms: values.forceSms,
            freeText: values.freeText,
            authStatus: 'AUTHORIZED'
        }
        const url = `/api/smsMessages`;
        showInfoNotify('Đang gửi SMS, vui lòng chờ trong giây lát')
        axiosInstance.post(url, data).then(rs => {
            setLoading(false)
            showSuccessNotify('Gửi tin nhắn thành công')
            setValues({...values, isRedirect: true});
        }, err => {
            setLoading(false)
            showErrorNotify(err.message)
        })
    }

    const validate = () => {
        if (!values.category) {
            props.showNotification('Cần chọn loại sms', 'warning');
            return false;
        }
        if (values.category !== 'KQHT' && !values.hocKy) {
            values.hocKy = null;
        }
        if (values.category === 'KQHT' && !values.hocKy) {
            props.showNotification('Cần chọn học kỳ', 'warning');
            return false;
        }
        if (values.category === 'FREE_TEXT' && !values.freeText) {
            props.showNotification('Cần nhập nội dung', 'warning');
            return false;
        }
        if (!values.cuzIds || values.cuzIds.length === 0) {
            props.showNotification('Cần chọn danh sách người nhận', 'warning');
            return false;
        }
        return true
    }

    return (
        <Create {...props}>
            <SimpleForm toolbar={null}>
                <Grid container spacing={8}>
                    <MessageFields onChange={handleChange}
                                   handleSelectChange={handleSelectChange}
                                   defaultValue={values}
                                   permissions={props.permissions}
                                   save={save}
                                   files={values.files}
                                   authorize={authorize}
                                   onDirectChange={onDirectChange} loading={loading}/>
                </Grid>
            </SimpleForm>
        </Create>
    )
}

export default compose(
    connect(null, {showNotification}),
    withStyles(null)
)(SmsMessageCreate)
