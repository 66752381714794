import React, {useState} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm} from '../../components';
import env from '../../env';
import moment from "moment";

const MealRegistration = (props) => {
    const [state, setState] = useState({
        eatDate: new Date()
    });

    const getReportUrl = () => {
        return `${env.baseURL}/api/meal-registration/report${props.reportNumber || ''}?eatDate=` + moment(state.eatDate).format('YYYY-MM-DD');
    };

    const excelReport = () => {
        downloadFile(getReportUrl(), 'so_luong_suat_an.xlsx')
    };

    const validate = (values) => {
        const errors = {};
        setState(values);
        return errors;
    };

    return (
        <Card>
            <Title title={`resources.mealRegistration${props.reportNumber}.name`}/>
            <CardContent>
                <ReportForm params={state}
                            validate={validate}
                            excelReport={excelReport}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label={`resources.mealRegistration${props.reportNumber}.eatDate`}
                                       source="eatDate"
                                       validate={required()} {...props} />
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default MealRegistration

