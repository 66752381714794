import React from 'react';
import {BooleanField, Datagrid, Filter, List, TextField, TextInput} from 'react-admin';
import {schoolYears} from "../../components";
import {EAdmissionSubjectField} from "../../components/EAdmissionSubject";

const MonTuChonFilter = (props) => (
    <Filter {...props} >
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const AdmissionSubjectList = props => (
    <List filters={<MonTuChonFilter/>} {...props}
          exporter={false} bulkActions={false}
          filterDefaultValues={{schoolYearId: (schoolYears && schoolYears.length > 0) ? schoolYears[0].id : null}}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <BooleanField source="active"/>
            <EAdmissionSubjectField source="type"/>
        </Datagrid>
    </List>
);

export default AdmissionSubjectList
