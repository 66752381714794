import React from 'react';
import Grid from '@material-ui/core/Grid'
import { BooleanInput, ReferenceInput, SelectInput, TextInput, required } from 'react-admin';

const StudentsByUnitsFields = props => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <TextInput source="name" validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <TextInput source="id" {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <ReferenceInput source="parentId" reference="studentsByUnits" disabled allowEmpty {...props} >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Grid>
  </React.Fragment>
)

export default StudentsByUnitsFields
