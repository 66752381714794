import React, {Component} from 'react';
import {connect} from 'react-redux'
import {change} from 'redux-form'
import {
    Create,
    Datagrid,
    DateField,
    Filter,
    List,
    REDUX_FORM_NAME,
    SimpleForm,
    TextField,
    TextInput,
} from 'react-admin';
import {Grid, showErrorNotify, showSuccessNotify, showWarningNotify} from '../../components'
import GuestOutFields from './GuestOutFields'
import './style.css';
import env from "../../env";
import {get, post} from "axios";
import GuestInFields from "./GuestInFields";
import GuestCustomToolbar from "../../components/GuestCustomToolbar";

const GuestFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

const electron = window.electron || (window.require ? window.require("electron") : null)

class GuestList extends Component {
    guestCardUid = null;
    guestId = null;
    responseMessage = 'Không tìm thấy thông tin khách.';

    showResponseMessage = false;
    state = {
        error: false,
        showDialog: false,
        showGuestOutInfo: false,
        showGuestInInfo: false,
        showBtn: false,
        cardUid: null,
        cardNumber: null,
        name: '',
        idNo: '',
        workDescription: '',
        contactId: 0,
        unitName: '',
        eventIds: [],
    };

    constructor(props) {
        super(props);
        this.onSubmitGuestOut = this.onSubmitGuestOut.bind(this);
        this.onSubmitGuestIn = this.onSubmitGuestIn.bind(this);
        this.validate = this.validate.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
    }

    componentDidMount() {
        if (electron && electron.ipcRenderer) {
            electron.ipcRenderer.on('uid', this.onUID)
        }
    }

    componentWillUnmount() {
        this.guestCardUid = null;
        this.guestId = null;
        if (electron && electron.ipcRenderer) {
            electron.ipcRenderer.removeListener('uid', this.onUID)
        }
    }

    onUID = (event, uid) => {
        const {dispatch} = this.props;
        console.log('receive guest out uid from electron ' + uid);
        this.checkGuestData(uid).then(rs => {
            if (rs.data) {
                const data = rs.data
                if (data.valid) {
                    if (data.guestOut) {
                        this.setState({
                            showGuestOutInfo: true,
                            showGuestInInfo: false
                        })
                        this.guestId = data.id;
                        this.guestCardUid = uid;
                        this.forceUpdate();
                        dispatch(change(REDUX_FORM_NAME, "id", data.id));
                        dispatch(change(REDUX_FORM_NAME, "name", data.name));
                        dispatch(change(REDUX_FORM_NAME, "idNo", data.idNo));
                        dispatch(change(REDUX_FORM_NAME, "workDescription", data.workDescription));
                        dispatch(change(REDUX_FORM_NAME, "contactName", data.contactName));
                        dispatch(change(REDUX_FORM_NAME, "cardUid", uid));
                        dispatch(change(REDUX_FORM_NAME, "cardNumber", data.cardNumber));
                    } else {
                        this.setState({
                            showGuestInInfo: true,
                            showGuestOutInfo: false
                        })
                        this.forceUpdate();
                        dispatch(change(REDUX_FORM_NAME, "id", ''));
                        dispatch(change(REDUX_FORM_NAME, "name", ''));
                        dispatch(change(REDUX_FORM_NAME, "idNo", ''));
                        dispatch(change(REDUX_FORM_NAME, "workDescription", ''));
                        dispatch(change(REDUX_FORM_NAME, "contactName", ''));
                        dispatch(change(REDUX_FORM_NAME, "cardUid", uid));
                        dispatch(change(REDUX_FORM_NAME, "cardNumber", data.cardNumber));
                    }
                } else {
                    showWarningNotify('Thẻ không hợp lệ.');
                }
            } else {
                this.setState({
                    showGuestInInfo: true,
                    showGuestOutInfo: false
                })
                showWarningNotify('Có lỗi, vui lòng liên hệ quản trị viên.');
            }
        });
    };

    checkGuestData(cardUid) {
        const url = `${env.baseURL}/api/guests/checkCardUidV2?cardUid=` + cardUid;
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': token
            }
        };
        return get(url, config);
    }

    onSubmitGuestOut(e) {
        e.preventDefault();
        const data = {};
        data['id'] = this.guestId;
        data['cardUid'] = this.guestCardUid;
        this.saveGuestOut(data).then(rs => {
            if (rs.data) {
                showSuccessNotify("Lưu Khách ra thành công!");
                setTimeout(
                    function () {
                        window.location.reload()
                    }
                        .bind(this),
                    1000
                );
            } else {
                showErrorNotify("Lưu Khách ra không thành công!");
            }
        });
    }

    onClickCancel(e) {
        e.preventDefault()
        this.setState({
            showGuestInInfo: false,
            showGuestOutInfo: false
        })
    }

    onSubmitGuestIn(e) {
        e.preventDefault();
        if (!this.state.name || !this.state.cardUid) {
            showErrorNotify('Vui lòng nhập đủ dữ liệu yêu cầu');
            return;
        }
        const data = {};
        data['cardUid'] = this.state.cardUid;
        data['name'] = this.state.name;
        data['idNo'] = this.state.idNo;
        data['workDescription'] = this.state.workDescription;
        data['contactId'] = this.state.contactId;
        data['unitName'] = this.state.unitName;
        data['eventIds'] = this.state.eventIds;
        this.saveGuestIn(data).then(rs => {
            if (rs.data) {
                showSuccessNotify("Lưu Khách vào thành công!");
                this.setState({
                    showGuestInfo: false
                });
                setTimeout(
                    function () {
                        window.location.reload()
                    }
                        .bind(this),
                    1000
                );
            } else {
                showErrorNotify("Lưu Khách vào không thành công!");
            }
        });
    }

    saveGuestOut(data) {
        const url = `${env.baseURL}/api/guestOuts`;
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': token
            }
        };
        return post(url, data, config)
    }

    saveGuestIn(data) {
        const url = `${env.baseURL}/api/guests`;
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': token
            }
        };
        return post(url, data, config)
    }

    validate = (values) => {
        const errors = {};

        if (!values.cardUid) {
            errors.cardUid = 'Vui lòng nhập dữ liệu'
        }
        if (!values.name) {
            errors.name = 'Vui lòng nhập dữ liệu'
        }

        this.setState({
            cardUid: values.cardUid,
            name: values.name,
            idNo: values.idNo,
            workDescription: values.workDescription,
            contactId: values.contactId,
            unitName: values.unitName,
            eventIds: values.eventIds,
        })

        return errors;
    }

    render() {
        const {showGuestOutInfo, showGuestInInfo} = this.state
        return (
            <>
                {showGuestOutInfo &&
                <Create title=' ' {...this.props}>
                    <SimpleForm toolbar={<GuestCustomToolbar saveLabel={'Trả thẻ'} onClickCancel={this.onClickCancel}
                                                             onSubmitRefresh={this.onSubmitGuestOut}/>}>
                        <h2>Thông tin khách ra</h2>
                        <Grid container spacing={8}>
                            <GuestOutFields/>
                        </Grid>
                    </SimpleForm>
                </Create>
                }
                {showGuestInInfo &&
                <Create title=' ' {...this.props}>
                    <SimpleForm validate={this.validate}
                                toolbar={<GuestCustomToolbar onClickCancel={this.onClickCancel}
                                                             onSubmitRefresh={this.onSubmitGuestIn}/>}>
                        <h2>Thông tin khách vào</h2>
                        <Grid container spacing={8}>
                            <GuestInFields/>
                        </Grid>
                    </SimpleForm>
                </Create>
                }
                <List filters={<GuestFilter/>} {...this.props} exporter={false}>
                    <Datagrid>
                        <TextField source="id"/>
                        <TextField source="cardNumber"/>
                        <TextField source="name"/>
                        <TextField source="idNo"/>
                        <TextField source="workDescription"/>
                        <DateField source="inDateTime" showTime/>
                        <DateField source="outDateTime" showTime/>
                    </Datagrid>
                </List>
            </>
        );
    }
}

export default connect(null, null)(GuestList)
