import React, {useState} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {DateInput, ReferenceInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm, showWarningNotify, TreeCheckboxInput} from '../../components';
import env from '../../env';
import moment from "moment";

const ThongKeLuotChamThe = (props) => {
    const [state, setState] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        deviceIds: [],
        isLoading: false
    });

    const getReportUrl = () => {
        return `${env.baseURL}/reports/thongKeLuotChamThe?fromDate=${moment(state.fromDate).format('YYYY-MM-DD')}`
            + `&toDate=${moment(state.toDate).format('YYYY-MM-DD')}`
            + `&deviceIds=${state.deviceIds}`;
    };

    const excelReport = () => {
        if (!state.deviceIds || state.deviceIds.length === 0) {
            showWarningNotify('Vui lòng chọn thiết bị')
            return;
        }
        setState({...state, isLoading: true})
        downloadFile(getReportUrl(), 'thong_ke_luot_cham_the.xlsx')
            .then(() => {
                setState({...state, isLoading: false})
            })
            .catch(() => {
                setState({...state, isLoading: false})
            })
    };

    const validate = (values) => {
        const errors = {};
        setState(values);
        return errors;
    };

    return (
        <Card>
            <Title title="resources.thongKeLuotChamThe.name"/>
            <CardContent>
                <ReportForm params={state}
                            validate={validate}
                            excelReport={excelReport}
                            isLoading={state.isLoading}
                >
                    <span>
                     Mô tả: Báo cáo thống kê số lượt chạm thẻ tại thiết bị và chi tiết các lần chạm thẻ theo khoảng thời gian
                    </span>
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="fromDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.toDate" source="toDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ReferenceInput source="deviceIds"
                                            reference="accessDevices"
                                            filter={{filterPath: 'findByEnabledIsTrue'}}
                                            perPage={999}
                                            validate={required()}
                                            {...props} >
                                <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                            </ReferenceInput>

                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default ThongKeLuotChamThe

