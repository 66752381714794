import React from 'react';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid'

import { DateInput, TextInput, required, translate , BooleanInput} from 'react-admin';

const styles = {
  checkboxGroup: {
    "& > fieldset > div ": {
      flexDirection: 'column',
    }
  }
}

const ContributionBillBatchFields = ({classes, ...props}) => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <TextInput source="description" validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <TextInput source="fullDescription" {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <DateInput source="month" validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <DateInput source="dueDate" validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <BooleanInput source="active"  {...props} />
    </Grid>

  </React.Fragment>
)

export default  compose(
  translate,
  withStyles(styles)
)(ContributionBillBatchFields)
