import React from 'react';
import {Create, SimpleForm} from 'react-admin';

import {Grid} from '../../components'
import GroupFields from './GroupFields'
import SimpleToolbar from "../../components/SimpleToolbar";

export const GroupCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<SimpleToolbar {...props} />} redirect="list" >
      <Grid container spacing={8}>
        <GroupFields />
      </Grid>
    </SimpleForm>
  </Create>
);

export default GroupCreate
