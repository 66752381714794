import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import {Title} from 'react-admin';

const classes = {
  formControl: {
    margin: 1,
    width: 500,
  },
  button: {
    width: 100,
    marginTop: 40,
    marginRight: 10
  },
  row: {
    display: 'flex'
  },
  fromDateTextField: {
    marginLeft: 1,
    marginTop: 10,
    width: 250,
  },
  toDateTextField: {
    marginLeft: 10,
    marginTop: 10,
    width: 250,
  },
};

const REPORTS = [
  {
    value: 'attendance_in_out_report',
    name: 'Báo cáo vào ra'
  },
  {
    value: 'attendance_time_keeping_report',
    name: 'Báo cáo chấm công'
  },
  {
    value: 'voting_report',
    name: 'Báo cáo kết quả biểu quyết'
  },
  {
    value: 'missing_voting_report',
    name: 'Báo cáo danh sách biểu quyết'
  },
  {
    value: 'absent_report',
    name: 'Báo cáo điểm danh vắng'
  }
];

const REPORT_MAPPING_COMPONENT = {
}

class Report extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      report: ''
    };

    // This binding is necessary to make `this` work in the callback
    this.handleReportChanged = this.handleReportChanged.bind(this);
  }

  handleReportChanged(event) {
    this.setState(() => ({
      report: event.target.value
    }));
  }

  render() {
    return (
        <Card>
          <Title title="Report"/>
          <CardContent>
            <FormControl style={classes.formControl}>
              <InputLabel htmlFor="report">Chọn báo cáo</InputLabel>
              <Select
                  value={this.state.report}
                  onChange={this.handleReportChanged}
                  input={<Input name="reportName" id="report"/>}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {
                  REPORTS.map(report => (
                      <MenuItem key={'report' + report.value}
                                value={report.value}>{report.name}</MenuItem>
                  ))
                }
              </Select>
              {REPORT_MAPPING_COMPONENT[this.state.report]}
            </FormControl>
          </CardContent>
        </Card>
    );
  }
}

export default Report;
