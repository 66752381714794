import React from 'react';
import {downloadFile, ReportForm} from "../../components";
import {Grid, Card, CardContent} from '@material-ui/core';
import {DateInput, ReferenceInput, required, Title, AutocompleteInput} from 'react-admin';
import moment from 'moment'
import env from "../../env";

class DateKeepingReport extends React.Component {

  state = {
    unitId: '',
    eventId: '',
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD')
  }

  excelReport = () => {
    downloadFile(this.getReportUrl(), 'bao_cao_diem_danh_theo_ngay.xlsx')
  }

  getReportUrl = () => {
    return `${env.baseURL}/reports/date-keeping-report?` +
        `unitId=` + this.state.unitId +
        `&eventId=` + this.state.eventId +
        `&fDate=` + this.state.fromDate +
        `&tDate=` + this.state.toDate;
  }

  validate = (values) => {
    const errors = {};

    // Validate
    if (values.fromDate && values.toDate && (values.fromDate > values.toDate)) {
      errors.toDate = 'To date must be greater than From date'
    }
    values.eventId = this.state.eventId;

    this.setState({
      unitId: values.unitId,
      fromDate: values.fromDate,
      toDate: values.toDate
    })

    return errors;
  }

  onUnitChanged(e, value) {
    this.setState({
      eventId: ''
    })
  }

  onEventChanged(e, value) {
    this.setState({
      eventId: value
    })
  }

  render() {
    return (
        <Card>
          <Title title="Báo cáo điểm danh theo ngày" />
          <CardContent>
            <ReportForm params={this.state}
                        validate={this.validate}
                        excelReport={this.excelReport}
            >
              <Grid container spacing={8}>
                <Grid item xs={12} sm={6} >
                  <ReferenceInput source="unitId"
                                  reference="units"
                                  filter={{includeEvent: true}}
                                  sort={{ field: 'name', order: 'ASC' }}
                                  validate={required()}
                                  onChange={(e, value) => this.onUnitChanged(e, value)}
                                  perPage={150}>
                    <AutocompleteInput optionText="name" />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={6} >
                  <ReferenceInput source="eventId"
                                  reference="events"
                                  validate={required()}
                                  filter={{filterPath: 'filterSimple', unitId: this.state.unitId, accessControlOnly: false}}
                                  onChange={(e, value) => this.onEventChanged(e, value)}
                                  sort={{ field: 'name', order: 'ASC' }}
                                  perPage={50}>
                    <AutocompleteInput source="name" />
                  </ReferenceInput>
                </Grid>

                <Grid item xs={12} sm={6} >
                  <DateInput source="fromDate" validate={required()} {...this.props} />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <DateInput source="toDate" validate={required()} {...this.props} />
                </Grid>
              </Grid>
            </ReportForm>
          </CardContent>
        </Card>
    )
  }
}

export default DateKeepingReport;
