import FoodMenuIcon from '@material-ui/icons/ViewList';

import KiotProductMenuList from './KiotProductMenuList';
import KiotProductMenuCreate from './KiotProductMenuCreate';
import KiotProductMenuEdit from './KiotProductMenuEdit';

export default {
  list: KiotProductMenuList,
  create: KiotProductMenuCreate,
  edit: KiotProductMenuEdit,
  icon: FoodMenuIcon,
};
