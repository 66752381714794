import React from 'react';
import compose from 'recompose/compose';

import {Toolbar, translate} from 'react-admin';
import {Button} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

const RoleCustomToolbar = ({
                               basePath,
                               record,
                               resource,
                               undoable,
                               translate,
                               redirect,
                               permissions,
                               onSave,
                               ...props
                           }) => (
    <Toolbar record={record} {...props}>
        {props.save &&
        <Button variant="contained"
                onClick={(e) => props.save(e)}
                component="span" color={"primary"}>
            <SaveIcon/> Lưu
        </Button>
        }

        <Button variant="contained" component="span"
                style={{marginLeft: 10}} onClick={props.cancel}>
            <CancelIcon/> Hủy
        </Button>
    </Toolbar>
);

export default compose(
    translate,
)(RoleCustomToolbar);
