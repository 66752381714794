import React, {useCallback, useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import axiosInstance from "../../api/axios";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';
import ImageProfile from "../ProfileChangeLog/ImageProfile";
import {DateTimeField, schoolYears} from "../../components";
import {CardApplicationStatusField} from "../../components/CardApplicationStatus";
import debounce from "debounce";
import {withStyles} from '@material-ui/core/styles';
import AuthorizeCardApplicationButton from "./AuthorizeCardApplicationButton";
import {Title} from "react-admin";


const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 180,
    },
    select: {
        maxHeight: 320
    }
});

const authStatuses = [
    {id: '', name: ''},
    {id: 'O_ACCEPTED', name: 'Đã duyệt'},
    {id: 'R_IN_PRINTING', name: 'Đang in thẻ'},
    {id: 'Y_DELIVERED', name: 'Đã giao thẻ'},
]

const Filter = withStyles(styles)(({
                                       searchParams,
                                       setSearchParams,
                                       setNextPage,
                                       setItems,
                                       units,
                                       cardApplicationBatches,
                                       countCanAddToCart,
                                       countInCart,
                                       classes
                                   }) => {
    const onFilter = useCallback(debounce((value) => {
        setItems([])
        setNextPage(0)
        setSearchParams(params => ({...params, q: value}))
    }, 500), [])

    const onSelectChange = (e) => {
        setItems([])
        setNextPage(0)
        setSearchParams(params => ({...params, [e.target.name]: e.target.value}))
    }

    return <Card>
        <Title title={'Tạo yêu cầu cấp thẻ. Tổng: ' + countInCart}/>
        <CardContent>
            <div style={{padding: '0 16px 8px 24px'}}>
                <TextField
                    label="Tìm kiếm"
                    onChange={(e) => onFilter(e.target.value)}
                    InputLabelProps={{shrink: true}}
                />

                <FormControl className={classes.formControl}>
                    <InputLabel shrink>Lô thông tin</InputLabel>
                    <Select
                        displayEmpty
                        value={searchParams.cardApplicationBatchId}
                        onChange={onSelectChange}
                        name='cardApplicationBatchId'
                        MenuProps={{classes: {paper: classes.select}}}
                    >
                        <MenuItem key={'u_empty'} value={''}>{''}</MenuItem>
                        {cardApplicationBatches.map(batch => <MenuItem key={batch.id} value={batch.id}>
                            {batch.id} - {batch.name}
                        </MenuItem>)}
                    </Select>
                </FormControl>

                <FormControl className={classes.formControl}>
                    <InputLabel shrink>Lớp/Phòng ban</InputLabel>
                    <Select
                        value={searchParams.unitId}
                        onChange={onSelectChange}
                        displayEmpty
                        inputProps={{
                            name: 'unitId',
                        }}
                        MenuProps={{classes: {paper: classes.select}}}
                    >
                        <MenuItem key={'u_empty'} value={''}>{''}</MenuItem>
                        {units.map(unit => <MenuItem key={unit.id} value={unit.id}>{unit.name}</MenuItem>)}
                    </Select>
                </FormControl>

                <FormControl className={classes.formControl}>
                    <InputLabel shrink>Trạng thái</InputLabel>
                    <Select
                        value={searchParams.status}
                        onChange={onSelectChange}
                        displayEmpty
                        inputProps={{
                            name: 'status',
                        }}
                    >
                        {authStatuses.map(status => <MenuItem key={status.id}
                                                              value={status.id}>{status.name}</MenuItem>)}
                    </Select>
                </FormControl>

                <AuthorizeCardApplicationButton searchParams={searchParams} countCanAddToCart={countCanAddToCart}
                                                countInCart={countInCart}/>
            </div>
        </CardContent>
    </Card>
})

export const ProfileChangeLogReadyList = props => {
    const [items, setItems] = useState([])
    const [nextPage, setNextPage] = useState(0)
    const [cartItems, setCartItems] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [searchParams, setSearchParams] = useState({
        q: '',
        status: '',
    })

    const [units, setUnits] = useState([])
    const [cardApplicationBatches, setCardApplicationBatches] = useState([])
    const [countInCart, setCountInCart] = useState(0)
    const [countCanAddToCart, setCountCanAddToCart] = useState(0)

    useEffect(() => {
        fetchCountInfo()
        fetchUnits()
        fetchCardApplicationBatches()
    }, [])

    useEffect(() => {
        fetchMoreData()
    }, [searchParams])

    const fetchCardApplicationBatches = async () => {
        const res = await axiosInstance.get('/api/cardApplicationBatches?page=0&size=999&sort=id%2CDESC&')
        if (!res.data) {
            return
        }

        setCardApplicationBatches(res.data.content)
    }
    const fetchMoreData = async () => {
        const params = new URLSearchParams(searchParams)
        const pageSize = 25;
        const url = `/api/profileChangeLogReadys?page=${nextPage}&size=${pageSize}&sort=status%2CDESC&${params}`;
        const res = await axiosInstance.get(url)
        if (!res.data || !res.data.success) {
            return
        }

        const content = res.data.content;
        if (content.length === 0) {
            return
        }

        if (content.length < pageSize) {
            setHasMore(false)
        } else {
            setHasMore(true)
        }

        setNextPage(currentPage => currentPage + 1)
        setItems(items.concat(content))
        setCartItems(cartItems.concat(content.filter(item => !!item.cartUserId).map(item => item.id)))
    };

    const fetchUnits = async () => {
        const url = `/api/baseUnits/search/filter?page=0&q=&schoolYearId=` + (schoolYears?.length > 0 ? schoolYears[0]?.id : '') + `&size=999&sort=name%2CASC&`;
        const res = await axiosInstance.get(url)
        if (!res.data) {
            return
        }
        setUnits(prev => [...prev, ...res.data.content])
    }

    const addToCart = async id => {
        const url = `/api/profileChangeLogReadys/cart`;
        setCartItems(prev => [...prev, id])
        await axiosInstance.post(url, [id])
        fetchCountInfo()
    }

    const removeCartItem = async id => {
        const url = `/api/profileChangeLogReadys/cart/${id}`;
        setCartItems(cardIds => cardIds.filter(cardId => cardId !== id))
        await axiosInstance.delete(url)
        fetchCountInfo()
    }

    const fetchCountInfo = () => {
        const url = `/api/profileChangeLogReadys/cart/info`;
        axiosInstance.get(url).then((res) => {
            if (res.data) {
                setCountInCart(res.data.countInCart)
                setCountCanAddToCart(res.data.countCanAddToCart)
            }
        })
    }

    return <div>
        <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            height={window.innerHeight - 250}
            loader={<CircularProgress style={{marginLeft: 10}} color="secondary"/>}
        >
            <Filter searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    setNextPage={setNextPage}
                    setItems={setItems}
                    units={units}
                    cardApplicationBatches={cardApplicationBatches}
                    countInCart={countInCart}
                    countCanAddToCart={countCanAddToCart}
            />

            <Table>
                <TableHead style={{position: 'sticky', top: 0, zIndex: 1}}>
                    <TableRow>
                        <TableCell padding='none'>ID</TableCell>
                        <TableCell>Hình ảnh</TableCell>
                        <TableCell padding='none'>Mã VinaID</TableCell>
                        <TableCell>Họ Tên</TableCell>
                        <TableCell>Lớp/Phòng ban</TableCell>
                        <TableCell>Ngày sinh</TableCell>
                        <TableCell padding='none'>Mã lô</TableCell>
                        <TableCell>Ngày gửi in</TableCell>
                        <TableCell>Ngày giao</TableCell>
                        <TableCell>Trạng thái</TableCell>
                        <TableCell padding='none'></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row, idx) => (
                        <TableRow key={idx}>
                            <TableCell padding='none' width={50}>{row.id}</TableCell>
                            <TableCell><ImageProfile record={{...row}}/></TableCell>
                            <TableCell padding='none' width={75}>{row.existCustomerId}</TableCell>
                            <TableCell>{row.customerName}</TableCell>
                            <TableCell>{units.find(unit => unit.id === row.unitId)?.name}</TableCell>
                            <TableCell width={75}>
                                <DateTimeField record={{...row}} source='dob' format='DD/MM/YYYY'/>
                            </TableCell>
                            <TableCell padding='none' width={50}>{row.internalBatchId}</TableCell>
                            <TableCell width={75}>
                                <DateTimeField record={{...row}} source='sentRequestDate' format='DD/MM/YYYY'/>
                            </TableCell>
                            <TableCell width={75}>
                                <DateTimeField record={{...row}} source='deliveryDate' format='DD/MM/YYYY'/>
                            </TableCell>
                            <TableCell width={100}>
                                <CardApplicationStatusField record={{...row}} source="status"/>
                            </TableCell>
                            <TableCell padding={'none'} width={130}>
                                {
                                    (row.status === 'O_ACCEPTED' || row.status === 'Y_DELIVERED')
                                        ? cartItems.includes(row.id)
                                        ? <Button style={{maxWidth: 130}} variant="contained"
                                                  onClick={() => removeCartItem(row.id)}>LOẠI KHỎI LÔ</Button>
                                        : <Button style={{maxWidth: 130}} variant="contained" color="primary"
                                                  onClick={() => addToCart(row.id)}>Thêm vào lô</Button>
                                        : <></>
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </InfiniteScroll>
    </div>
}
export default ProfileChangeLogReadyList
