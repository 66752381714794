import React from 'react';
import {Datagrid, Filter, List, TextField, TextInput} from 'react-admin';

const SubjectFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const SubjectList = props => (
    <List filters={<SubjectFilter/>}{...props} >
        <Datagrid rowClick="edit">
            <TextField source="name"/>
            <TextField source="priority"/>
        </Datagrid>
    </List>
);

export default SubjectList
