import React from 'react';
import {Datagrid, Filter, List, NumberField, TextField, TextInput} from 'react-admin';
import {EditButtonWithAuthStatus, PaymentMethodField} from "../../components";
import DeleteReceiptButton from "../../components/DeleteRecordButton";
import {ContributionBillField} from "../../components/ContributionBillStatus";

const ProductFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const ContributionBillList = props => (
    <List filters={<ProductFilter/>} {...props} perPage={25} bulkActionButtons={false} exporter={false}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="customerId"/>
            <TextField source="customerName"/>
            <TextField source="unitName"/>
            <NumberField source="amount" locales="vi-VI" options={{style: 'currency', currency: 'VND'}}/>
            <PaymentMethodField source="paymentMethod"/>
            <TextField source="note"/>
            <TextField source="contributionDate"/>
            <ContributionBillField source="status"/>
            <TextField source="reason"/>
            <EditButtonWithAuthStatus/>
            <DeleteReceiptButton title={'Bạn chắc chắn muốn huỷ khoản đóng góp? Phiếu thu đã xuất sẽ bị huỷ.'} path={'/api/contributionBills'}/>
        </Datagrid>
    </List>
);

export default ContributionBillList
