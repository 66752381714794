import React from 'react';
import {
    AutocompleteInput,
    Button,
    Datagrid,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    TextField,
    TextInput
} from 'react-admin';
import axios from "../../api/axios";
import env from "../../env/env";
import {CircularProgress} from "@material-ui/core";

class KiotProductFilter extends React.Component {
    state = {
        kiotConfigId: null,
        loading: false
    }
    onKiotChanged = (e, value) => {
        this.setState({kiotConfigId: value})
    }

    handleClick = () => {
        this.setState({loading: true})
        axios.post(`/api/kiotProducts/sync?${this.state.kiotConfigId ? 'kiotConfigId=' + this.state.kiotConfigId : ''}`, {}, {newBaseUrl: env.kiotUrl}).then((rs) => {
                window.location.reload();
            }
        ).catch((e) => {
            this.setState({loading: false})
        })
    };

    render() {
        return (
            <Filter {...this.props}>
                <ReferenceInput source="kiotConfigId" reference="kiotConfigs" alwaysOn allowEmpty
                                label={"resources.kiotProducts.fields.kiotConfigId"}
                                {...this.props}
                                disabled={this.state.loading}
                                onChange={this.onKiotChanged}
                                sort={{field: 'name', order: 'ASC'}}
                                perPage={999}
                >
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
                <TextInput label="ra.action.search" source="q" alwaysOn disabled={this.state.loading}/>
                <Button onClick={this.handleClick} label="resources.kiotProducts.sync" alwaysOn
                        disabled={this.state.loading}>
                </Button>
                {
                    this.state.loading && <CircularProgress color="secondary"/>
                }
            </Filter>
        )
    }
}

export const KiotProductList = props => (
    <List filters={<KiotProductFilter/>} {...props} perPage={25} exporter={false}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="code"/>
            <ReferenceField source="kiotConfigId" reference="kiotConfigs" style={{width: 150}}>
                <TextField source="name"/>
            </ReferenceField>
        </Datagrid>
    </List>
);

export default KiotProductList
