import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const type = [
  {id: 'Chính sách 1', name: 'Chính sách 1'},
  {id: 'Chính sách 2', name: 'Chính sách 2'},
  {id: 'Chính sách 2', name: 'Chính sách 3'},
  {id: 'Chính sách 2', name: 'Chính sách 4'},
]

export const Exemption81TypeField = (props) => (
    <SelectField source={props.source} choices={type} {...props} />
)

export const Exemption81TypeInput = (props) => (
    <SelectInput source={props.source} choices={type} {...props} />
)
