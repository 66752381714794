import React, {useState} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {ReportForm} from "../../components";
import {SelectInput, Title} from 'react-admin';
import env from "../../env/env";
import {downloadFilePost} from "../../components/FileDownload";

const statuses = [
    {id: 'null', name: 'Tất cả'},
    {id: 'false', name: 'Khoá'},
    {id: 'true', name: 'Không khoá'},
]

const BcTrangThaiTuyenSinh = () => {
    const [state, setState] = useState({
        status: 'null'
    });
    const [loading, setLoading] = useState(false);

    const excelReport = async () => {
        setLoading(true)
        await downloadFilePost(`${env.baseURL}/reports/bcTrangThaiTuyenSinh${state.status === 'null' ? '' : `?status=` + state.status}`, 'bc_trang_thai_tuyen_sinh.xlsx', {})
        setLoading(false)
    };

    const validate = (values) => {
        const errors = {};
        setState(values);
        return errors;
    };

    return (
        <Card>
            <Title title="resources.bcTrangThaiTuyenSinh.name"/>
            <CardContent>
                <ReportForm params={state}
                            validate={validate}
                            excelReport={excelReport}
                            isLoading={loading}
                >
                    <Grid container spacing={8}>
                        <SelectInput source={'status'} choices={statuses} label='Trạng thái'/>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default BcTrangThaiTuyenSinh

