import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const genders = [
  {id: 'CLAZZ', name: 'disciplineType.CLAZZ'},
  {id: 'STUDENT', name: 'disciplineType.STUDENT'},
]

export const DisciplineTypeField = (props) => (
    <SelectField source={props.source} choices={genders} {...props} />
)

export const DisciplineTypeInput = (props) => (
    <SelectInput source={props.source} choices={genders} {...props} label="Phân loại"/>
)
