import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Icon from '@material-ui/core/Icon';

const classes = {
  root: {
    width: '100%',
    marginTop: 10,
  },
  paper: {
    marginTop: 3,
    width: '100%',
    overflowX: 'auto',
    marginBottom: 2,
  },
  table: {
    minWidth: 650,
  },
  icon: {
    fontSize: 18,
  },
};

class KiotProductMenuBatchValidation extends Component {

  render() {
    return (
        <div style={classes.root}>
          <Paper style={classes.paper}>
            <Table style={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="right">Hợp Lệ</TableCell>
                  <TableCell align="right">Mô tả lỗi</TableCell>
                  <TableCell align="right">Ngày bán</TableCell>
                  <TableCell align="right">Giờ giao</TableCell>
                  <TableCell align="right">Ngày đặt trước</TableCell>
                  <TableCell align="right">Giờ đăt trươc</TableCell>
                  <TableCell align="right">Ngày huỷ trước</TableCell>
                  <TableCell align="right">Giờ huỷ trươc</TableCell>
                  <TableCell align="right">Sản phẩm chính</TableCell>
                  <TableCell align="right">Sản phẩm khác</TableCell>


                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.data.map((row, idx) => (
                    <TableRow key={idx}>
                      <TableCell align="right">
                        {row.valid &&
                        <Icon style={classes.icon} className='fa fa-check' color="primary"/>
                        }
                        {!row.valid &&
                        <Icon style={classes.icon} className='fa fa-times' color="error"/>
                        }
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.errorMessage}
                      </TableCell>
                      <TableCell align="right">{row.sellDate}</TableCell>
                      <TableCell align="right">{row.deliveryTime}</TableCell>
                      <TableCell align="right">{row.orderBefore}</TableCell>
                      <TableCell align="right">{row.orderBeforeTime}</TableCell>
                      <TableCell align="right">{row.cancelBeforeDate}</TableCell>
                      <TableCell align="right">{row.cancelBeforeTime}</TableCell>
                      <TableCell align="right">{row.primaryProductName}</TableCell>
                      <TableCell align="right">{row.productNames}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={this.props.total}
                rowsPerPage={this.props.rowsPerPage}
                page={this.props.page}
                backIconButtonprops={{
                  'aria-label': 'Trang trước',
                }}
                nextIconButtonprops={{
                  'aria-label': 'Trang kế',
                }}
                onChangePage={this.props.handleChangePage}
                onChangeRowsPerPage={this.props.onChangeRowsPerPage}
            />
          </Paper>
        </div>
    );
  }
}

export default KiotProductMenuBatchValidation;
