import React from 'react';
import {BooleanField, Datagrid, Filter, List, TextField, TextInput} from 'react-admin';
import {DisciplineTypeField} from "./DisciplineType";
import {AttendanceStatusField} from "./AttendanceStatus";

const DepartmentFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const DisciplineCategoryList = props => (
    <List filters={<DepartmentFilter/>} {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="minusCompetePoint"/>
            <TextField source="plusCompetePoint"/>
            <TextField source="minusConductPoint"/>
            <TextField source="plusConductPoint"/>
            <DisciplineTypeField source="type"/>
            <AttendanceStatusField source="attendanceStatus"/>
            <BooleanField source="active"/>
        </Datagrid>
    </List>
);

export default DisciplineCategoryList
