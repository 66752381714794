import ClazzSubjectIcon from '@material-ui/icons/ViewList';

import ClazzSubjectList from './ClazzSubjectList';
import ClazzSubjectCreate from './ClazzSubjectCreate';
import ClazzSubjectEdit from './ClazzSubjectEdit';

export default {
  list: ClazzSubjectList,
  create: ClazzSubjectCreate,
  edit: ClazzSubjectEdit,
  icon: ClazzSubjectIcon,
};
