import React, {useEffect, useState} from 'react';
import {refreshView, showNotification, SimpleForm, translate} from 'react-admin';

import {Grid} from '../../components'
import {connect} from "react-redux";
import axiosInstance from "../../api/axios";
import {compose} from "redux";
import withStyles from '@material-ui/core/styles/withStyles';
import AdmissionFields from "./AdmissionFields";
import cloneDeep from 'lodash/cloneDeep';

const styles = {
    item: {
        "& > table ": {
            width: '95%',
        },
        "& > table td:nth-child(2)": {
            textAlign: 'right',
        },
        "& > table thead": {    // hide the header
            display: 'none',
        },
    },
    select: {
        marginTop: '20px',
    },
    button: {
        // marginTop: '-20px',
        marginLeft: '10px'
    },
}
let timeout = null;
export const AdmissionEdit = ({
                                  translate,
                                  classes,
                                  showToolbar = true,
                                  redirect = "list",
                                  noCancelButton,
                                  event,
                                  ...props
                              }) => {

    const [values, setValues] = useState({
        id: null,
        selectedElectiveIds: [],
        selectedPhysicalIds: [],
        selectedGraduationIds: [],
        selectedElectives: [],
        isRedirect: false,
        saveDisabled: false,
        isFistTimeCheck: true,
        ready: false
    });
    const [programServices, setProgramServices] = useState([])
    const [categories, setCategories] = useState([])
    const [schoolServices, setSchoolServices] = useState([])
    const [selectedElectives, setSelectedElectives] = useState([])
    const [selectedGraduations, setSelectedGraduations] = useState([])
    const [selectedPhysicals, setSelectedPhysicals] = useState([])
    const [allProgramServices, setAllProgramServices] = useState([])
    const [useCategory, setUseCategory] = useState(false)
    const [regisGraduation, setRegisGraduation] = useState(false)
    const [regisExemption, setRegisExemption] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState({});
    const [templateVersion, setTemplateVersion] = useState({});
    const [selectedJobs, setSelectedJobs] = useState([]);
    useEffect(() => {
        if (props.id && values.isFistTimeCheck) {
            getDetails();
        }
    }, [values.isFistTimeCheck]);

    function getDetails() {
        const url = `/api/admissions/` + props.id;
        axiosInstance.get(url).then(rs => {
            const {data} = rs;
            setSelectedElectives(data.selectedElectives)
            setSelectedGraduations(data.selectedGraduationSubjects)
            setSelectedPhysicals(data.selectedPhysicals)
            setSelectedJobs(data.selectedJobs)
            setCategories(data.categories)
            setUseCategory(data.useCategory)
            setRegisGraduation(data.regisGraduation)
            setRegisExemption(data.regisExemption)
            setTemplateVersion(data.templateVersion)
            const clone = cloneDeep(data)
            clone.isFistTimeCheck = false;
            clone.ready = true;

            if (clone.additionalServices && clone.additionalServices.length > 0) {
                const arr = clone.additionalServices.filter(p => p.type === 'PROGRAM')
                setAllProgramServices(arr)
                setProgramServices(arr.filter(p => !p.admissionSubjectId || (p.admissionSubjectId && clone.selectedElectives.findIndex(s => s.admissionSubjectId === p.admissionSubjectId) >= 0)
                    || (p.admissionSubjectId && clone.selectedGraduationSubjects.findIndex(s => s.admissionSubjectId === p.admissionSubjectId) >= 0)))
                setSchoolServices(clone.additionalServices.filter(p => p.type === 'SERVICE'))
            }

            const gifteds = clone.gifteds
            const sports = gifteds.filter(p => p.type === 'SPORT')
            if (!sports || sports.length === 0) {
                clone.sports = [{name: '', achievement: '', note: '', type: 'SPORT'}]
            } else {
                clone.sports = sports
            }
            const cultures = gifteds.filter(p => p.type === 'CULTURE')
            if (!cultures || cultures.length === 0) {
                clone.cultures = [{name: '', achievement: '', note: '', type: 'CULTURE'}]
            } else {
                clone.cultures = cultures
            }
            const teams = gifteds.filter(p => p.type === 'TEAM')
            if (!teams || teams.length === 0) {
                clone.team = {name: '', achievement: '', note: '', type: 'TEAM'}
            } else {
                clone.team = teams[0]
            }
            const otherGifteds = gifteds.filter(p => p.type === 'OTHER')
            if (!otherGifteds || otherGifteds.length === 0) {
                clone.otherGifted = {name: '', type: 'OTHER'}
            } else {
                clone.otherGifted = otherGifteds[0]
            }

            setValues({...clone, merged: clone});
        });
    }

    const handleChange = prop => e => {
        if (!e) {
            return
        }
        const value = e.target.value ? e.target.value : ''

        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            if (prop.includes('\.')) {
                const arr = prop.split('\.')
                setValues({...values, [arr[0]]: {...values[[arr[0]]], [arr[1]]: value}});
            } else {
                setValues({...values, [prop]: value});
            }
        }, 100);
    };

    const handleChangeArray = (prop, field, index) => e => {
        if (!e) {
            return
        }
        const value = e.target.value ? e.target.value : e.target.checked;
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            const arr = [...values[prop]]
            arr[index] = {...arr[index], [field]: value}
            setValues({...values, [prop]: arr});
        }, 100);
    };

    function validate() {
        if (!values.customerPhone && !values.fatherPhone && !values.motherPhone && !values.guardianPhone) {
            props.showNotification('Vui lòng nhập ít nhất 1 số điện thoại.', 'warning');
            return false;
        }
        if (templateVersion === 'GDTX') {
            if (!values.permanentAddressHouseNo?.trim()) {
                props.showNotification('Vui lòng nhập số nhà (địa chỉ thường trú).', 'warning');
                return;
            }
            if (!values.permanentAddressStreet.trim()) {
                props.showNotification('Vui lòng nhập đường (địa chỉ thường trú).', 'warning');
                return;
            }
            if (!values.permanentAddressCluster?.trim()) {
                props.showNotification('Vui lòng nhập thôn/tổ/phố (địa chỉ thường trú).', 'warning');
                return;
            }
            if (!values.permanentAddressWard?.trim()) {
                props.showNotification('Vui lòng nhập phường (xã) (địa chỉ thường trú).', 'warning');
                return;
            }
            if (!values.permanentAddressDistrict?.trim()) {
                props.showNotification('Vui lòng nhập quận (huyện) (địa chỉ thường trú).', 'warning');
                return;
            }
            if (!values.permanentAddressCity?.trim()) {
                props.showNotification('Vui lòng nhập tỉnh (tp) (địa chỉ thường trú).', 'warning');
                return;
            }

            if (!values.temporaryAddressHouseNo?.trim()) {
                props.showNotification('Vui lòng nhập số nhà (địa chỉ tạm trú).', 'warning');
                return;
            }
            if (!values.temporaryAddressStreet.trim()) {
                props.showNotification('Vui lòng nhập đường (địa chỉ tạm trú).', 'warning');
                return;
            }
            if (!values.temporaryAddressCluster?.trim()) {
                props.showNotification('Vui lòng nhập thôn/tổ/phố (địa chỉ tạm trú).', 'warning');
                return;
            }
            if (!values.temporaryAddressWard?.trim()) {
                props.showNotification('Vui lòng nhập phường (xã) (địa chỉ tạm trú).', 'warning');
                return;
            }
            if (!values.temporaryAddressDistrict?.trim()) {
                props.showNotification('Vui lòng nhập quận (huyện) (địa chỉ tạm trú).', 'warning');
                return;
            }
            if (!values.temporaryAddressCity?.trim()) {
                props.showNotification('Vui lòng nhập tỉnh (tp) (địa chỉ tạm trú).', 'warning');
                return;
            }
        } else {
            if (values.otherInsurance) {
                if (values.otherInsurance != 'Không có' && !values.insuranceNumber) {
                    props.showNotification('Vui lòng nhập Số bảo hiểm.', 'warning');
                    return;
                }
            } else {
                props.showNotification('Vui lòng chọn bảo hiểm.', 'warning');
                return;
            }
            if (values.canSwim) {
                if (values.canSwim == 'Có') {
                    if (!values.swimCertificate || !values.swimDateOfCertificate || !values.swimPlaceOfCertificate) {
                        props.showNotification('Vui lòng nhập thông tin chứng chỉ bơi.', 'warning');
                        return;
                    }
                }
            } else {
                props.showNotification('Vui lòng chọn Biết bơi.', 'warning');
                return;
            }
        }

        for (let i = 0; i < selectedElectives.length; i++) {
            if (!selectedElectives[i].name) {
                props.showNotification('Vui lòng chọn môn tự chọn.', 'warning');
                return;
            }
        }
        for (let i = 0; i < selectedJobs.length; i++) {
            if (!selectedJobs[i].name) {
                props.showNotification('Vui lòng chọn môn nghề.', 'warning');
                return;
            }
        }
        for (let i = 0; i < selectedPhysicals.length; i++) {
            if (!selectedPhysicals[i].name) {
                props.showNotification('Vui lòng chọn môn giáo dục thể chất.', 'warning');
                return;
            }
        }
        if (regisGraduation) {
            for (let i = 0; i < selectedGraduations.length; i++) {
                if (!selectedGraduations[i].name) {
                    props.showNotification('Vui lòng chọn môn thi tốt nghiệp.', 'warning');
                    return;
                }
            }
        }
        return true;
    }

    function save() {
        if (!validate()) {
            return;
        }
        const data = Object.assign({}, values);
        setValues({
            ...values, isRedirect: true
        });
        if (data.otherInsurance == 'Không có') {
            data.insuranceNumber = ''
        }
        if (data.canSwim != 'Có') {
            data.swimCertificate = ''
            data.swimDateOfCertificate = ''
            data.swimPlaceOfCertificate = ''
        }
        data.additionalServices = [...programServices, ...schoolServices]
        data.selectedElectives = selectedElectives
        data.selectedJobs = selectedJobs
        data.selectedPhysicals = selectedPhysicals
        data.selectedGraduationSubjects = selectedGraduations
        data.gifteds = [...values.cultures, ...values.sports, values.team, values.otherGifted]
        data.categoryId = selectedCategory?.id
        const url = `/api/admissions/` + props.id;
        axiosInstance.put(url, data).then(rs => {
            props.showNotification('Cập nhật thành công');
        }, err => {
            props.showNotification(err.message, 'warning');
        })
    }

    function onDirectChange(prop, val) {
        setValues({...values, [prop]: val});
    }

    return (
        <SimpleForm
            toolbar={null}
            record={values}
        >
            <Grid container spacing={8} alignItems="flex-start" classes={classes}>
                <AdmissionFields onChange={handleChange}
                                 defaultValue={values}
                                 save={save}
                                 allProgramServices={allProgramServices}
                                 programServices={programServices}
                                 schoolServices={schoolServices}
                                 setProgramServices={setProgramServices}
                                 useCategory={useCategory}
                                 categories={categories}
                                 setSchoolServices={setSchoolServices}
                                 onDirectChange={onDirectChange}
                                 handleChangeArray={handleChangeArray}
                                 selectedElectives={selectedElectives}
                                 setSelectedElectives={setSelectedElectives}
                                 selectedGraduations={selectedGraduations}
                                 setSelectedGraduations={setSelectedGraduations}
                                 selectedPhysicals={selectedPhysicals}
                                 setSelectedPhysicals={setSelectedPhysicals}
                                 selectedCategory={selectedCategory}
                                 setSelectedCategory={setSelectedCategory}
                                 regisExemption={regisExemption}
                                 regisGraduation={regisGraduation}
                                 templateVersion={templateVersion}
                                 selectedJobs={selectedJobs}
                                 setSelectedJobs={setSelectedJobs}
                />
            </Grid>
        </SimpleForm>
    )
}

export default compose(
    connect(undefined, {refreshView, showNotification}),
    translate,
    withStyles(styles)
)(AdmissionEdit)
