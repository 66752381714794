import React from 'react';
import compose from 'recompose/compose';

import { Toolbar, SaveButton, DeleteButton, translate } from 'react-admin';
import CancelButton from './CancelButton'

const SimpleToolbar = ({
  viewOnly,
  noCancelButton,
  basePath,
  record,
  resource,
  undoable,
  translate, 
  redirect,
  permissions,
  ...props
}) => (
  <Toolbar record={record} {...props}>
    { !viewOnly && <SaveButton redirect={redirect || "list"}/> }
    { !noCancelButton && <CancelButton /> }
  </Toolbar>
);

export default compose(
  translate,
)(SimpleToolbar);
