import React from 'react';
import Button from '@material-ui/core/Button';

const ImageUploadComponent2 = (props) => (
    <form>
        <div style={{flexDirection: 'row'}}>
            <input id="contained-button-file"
                   type="file" accept="image/*"
                   onChange={props.onFileChanged}/>
            {props.showBtn &&
            <Button variant="outlined" component="span"
                    disabled={props.loading}
                    onClick={props.onFileUpload}>
                Cập nhật
            </Button>
            }
        </div>
    </form>
)

export default ImageUploadComponent2;
