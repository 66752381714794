const utils = {

}

utils.extractCustomerIds = (unitCustomerIds) => {
  let _rs = [];
  unitCustomerIds.forEach(i => {
    const temp = "" + i;
    let zeroIdx = temp.indexOf("0000");
    let lastZeroIdx = -1;
    while (zeroIdx >= 0) {
      lastZeroIdx = zeroIdx;
      zeroIdx = temp.indexOf("0000", zeroIdx + 1);
    }
      if (lastZeroIdx != -1 && lastZeroIdx < temp.length - 1) {
          _rs.push(Number(temp.substr(lastZeroIdx + 4)));
      } else {
          _rs.push(Number(temp));
      }
  });
    return _rs;
}

utils.extractCustomerIdsV2 = (unitCustomerIds) => {
    const regex = /(0000)([1-9]\d+)/g;

    const customerIds = []

    unitCustomerIds.forEach(unitCustomerId => {
        let m;
        while ((m = regex.exec(unitCustomerId)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }
            if (!isNaN(m[2]))
                customerIds.push(+m[2])
        }
    });
    return customerIds;
}

utils.parseSearchQueryURL = (query) => {
    let vars = query.split("&");
    let query_string = {};
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        let key = decodeURIComponent(pair[0]);
        let value = decodeURIComponent(pair[1]);
        // If first entry with this name
        if (typeof query_string[key] === "undefined") {
      query_string[key] = decodeURIComponent(value);
      // If second entry with this name
    } else if (typeof query_string[key] === "string") {
      let arr = [query_string[key], decodeURIComponent(value)];
      query_string[key] = arr;
      // If third or later entry with this name
    } else {
      query_string[key].push(decodeURIComponent(value));
    }
  }
  return query_string;
}

utils.formatNumber = (num) => {
    return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
}
export default utils;
