import TuyenSinhInfoIcon from '@material-ui/icons/ViewList';

import AdmissionList from './AdmissionList';
import AdmissionEdit from './AdmissionEdit';

export default {
  list: AdmissionList,
  edit: AdmissionEdit,
  icon: TuyenSinhInfoIcon,
};
