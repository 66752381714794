import jwt_decode from 'jwt-decode'
import {AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT} from 'react-admin';
import env from "../env";
import {get} from "axios";

export default (loginURL) => {
    return (type, params) => {
        // called when the user attempts to log in
        if (type === AUTH_LOGIN) {
            const {username, password} = params;
            const request = new Request(loginURL, {
                method: 'POST',
                body: JSON.stringify({username, password}),
                headers: new Headers({'Content-Type': 'application/json'}),
            })

            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                }).then(json => {
                    let token = json.token;
                    const decoded = jwt_decode(token);
                    localStorage.setItem('token', token);
                    localStorage.setItem('tenant', decoded.tenant);
                    localStorage.setItem('tenantId', decoded.tenantId);
                    localStorage.setItem('tid', decoded.tid);
                    const tenantType = decoded.tenantType;
                    localStorage.setItem('tenantType', tenantType);
                    const showInfo = sessionStorage.getItem('showInfo');
                    if (tenantType === 'SCHOOL') {
                        const url = `${env.baseURL}/api/schoolYears?sort=enable,desc,name,desc`;
                        const config = {
                            headers: {
                                'content-type': 'application/json',
                                'Authorization': token
                            }
                        };
                        get(url, config).then(rs => {
                            if (rs.data) {
                                localStorage.setItem('schoolYears', JSON.stringify(rs.data.content))
                                localStorage.setItem('schoolYear', rs.data.content[0]);
                                if (showInfo !== '1') {
                                    window.location.reload()
                                }
                            }
                        }, err => {
                        });
                    }
                    if (showInfo === '1') {
                        sessionStorage.removeItem('showInfo')
                        window.location.href = sessionStorage.getItem('showInfoUrl')
                    }

                    return token;
                })
        }

        // called when the user clicks on the logout button
        if (type === AUTH_LOGOUT) {
            localStorage.clear();// Should clear all cached items when logout to prevent mix data from multi-tenant
            return Promise.resolve();
        }

        // called when the API returns an error
        if (type === AUTH_ERROR) {
            const status = params.status;
            if (status === 401) {               // Un-Authenticated
                localStorage.clear();
                return Promise.reject();
            }

            if (status === 403) {
                // return Promise.reject({ redirectTo: '/no-access' })
                return Promise.resolve();
            }

            return Promise.resolve();
        }

        // called when the user navigates to a new location
        if (type === AUTH_CHECK) {
            return localStorage.getItem('token') ? Promise.resolve()
                : Promise.reject();
        }

        if (type === AUTH_GET_PERMISSIONS) {
            const token = localStorage.getItem('token');
            if (token) {
                const decoded = jwt_decode(token);
                if (decoded.roles && Array.isArray(decoded.roles)) {
                    return Promise.resolve(decoded.roles)
                }
            }

            // return Promise.reject()
            return Promise.resolve('GUEST')
        }

        console.warn('Unknown type', type)
        // return Promise.resolve();
        return Promise.reject();
    }
}
