import React from 'react';
import {Create, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import KiotConfigFields from './KiotConfigFields'

const defaultValue = {
    seller: false,
    discountInvoice: false
}
export const KiotConfigCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<CustomToolbar/>} redirect="list" defaultValue={defaultValue}>
            <Grid container spacing={8}>
                <KiotConfigFields/>
            </Grid>
        </SimpleForm>
    </Create>
);

export default KiotConfigCreate
