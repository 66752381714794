import React from 'react';
import {downloadFile} from "../../components";
import env from "../../env";
import {Button} from 'react-admin';

const DownloadUnitProductBtn = () => {
    const excelReport = () => {
        downloadFile(getReportUrl(), 'ds_lop_va_phi.xlsx')
    }
    const getReportUrl = () => {
        return `${env.baseURL}/reports/unit-products`;
    }
    return (
        <div>
            <Button variant="outlined" label="Xuất DS" onClick={excelReport}/>
        </div>
    );
}
export default DownloadUnitProductBtn;
