import React from 'react';
import {
  ChipField,
  Datagrid,
  Filter,
  List,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextField,
  TextInput
} from 'react-admin';
import {DateTimeField} from "../../components";

const AccessDeviceFilter = (props) => (
    <Filter {...props}>
      <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const KiotProductMenuList = props => (
    <List filters={<AccessDeviceFilter/>} {...props} perPage={25} exporter={false}>
      <Datagrid rowClick="edit">
        <TextField source="id"/>
        <TextField source="sellDate" style={{width: 100}}/>
        <TextField source="orderBeforeDate" style={{width: 100}}/>
        <TextField source="orderBeforeTime" style={{width: 70}}/>
        <TextField source="cancelBeforeDate" style={{width: 100}}/>
        <TextField source="cancelBeforeTime" style={{width: 70}}/>
        <ReferenceField source="kiotConfigId" reference="kiotConfigs" style={{width: 150}}>
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceField source="primaryProductId" reference="kiotProducts" style={{width: 150}}>
          <TextField source="name"/>
        </ReferenceField>
        <ReferenceArrayField reference="kiotProducts" source="productIds">
          <SingleFieldList>
            <ChipField source="name"/>
          </SingleFieldList>
        </ReferenceArrayField>
        <DateTimeField source="lastUpdatedOn" format="DD-MM-YYYY HH:mm" style={{width: 150}}/>
        <TextField source="lastUpdatedBy" style={{width: 100}}/>
      </Datagrid>
    </List>
);

export default KiotProductMenuList
