import React, {useCallback, useEffect, useState} from "react";
import axiosInstance from "../../api/axios";
import {DateTimeField, schoolYears, showErrorNotify, showSuccessNotify} from "../../components";
import InfiniteScroll from "react-infinite-scroll-component";
import ImageProfile from "../../pages/ProfileChangeLog/ImageProfile";
import {CardApplicationStatusField,} from "../../components/CardApplicationStatus";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import debounce from "debounce";
import {Title} from "react-admin";
import Swal from "sweetalert2";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 180,
    },
    select: {
        maxHeight: 320
    }
});

const authStatuses = [
    {id: '', name: ''},
    {id: 'O_ACCEPTED', name: 'Đã duyệt'},
    {id: 'Y_DELIVERED', name: 'Đã giao thẻ'},
]

const Filter = withStyles(styles)(({
                                       searchParams,
                                       setSearchParams,
                                       setNextPage,
                                       setItems,
                                       units,
                                       cardApplicationBatches,
                                       classes,
                                       totalElements
                                   }) => {
    const [loading, setLoading] = useState(false)

    const onFilter = useCallback(debounce((value) => {
        setItems([])
        setNextPage(0)
        setSearchParams(params => ({...params, q: value}))
    }, 500), [])

    const onSelectChange = (e) => {
        setItems([])
        setNextPage(0)
        setSearchParams(params => ({...params, [e.target.name]: e.target.value}))
    }

    const capThe = async () => {
        setLoading(true)
        try {
            const res = await axiosInstance.post(`/api/profileChangeLogReadys/cart/cardRequest`)
            if (res.status === 200) {
                showSuccessNotify("Yêu cầu cấp thẻ thành công!");
                setTimeout(() => {
                    window.location.reload()
                }, 1500)
            } else {
                setLoading(false)
                showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!");
            }
        } catch (e) {
            setLoading(false)
            showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!");
        }
    }

    const onRemoveAll = async () => {
        Swal.fire({
            title: 'Xác nhận',
            text: `Bạn muốn loại tất cả yêu cầu cấp thẻ?`,
            type: "warning",
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
            focusConfirm: false,
        })
            .then(async (result) => {
                if (result.value) {
                    const url = `/api/profileChangeLogReadys/cart/all`;
                    const res = await axiosInstance.delete(url)
                    if (res.status === 200) {
                        showSuccessNotify("Xoá tất cả vào lô thành công!");
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000)
                    } else {
                        showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!");
                    }
                }
            })
    }

    return <div style={{padding: '0 16px 8px 24px'}}>
        <TextField
            label="Tìm kiếm"
            onChange={(e) => onFilter(e.target.value)}
            InputLabelProps={{shrink: true}}
        />

        <FormControl className={classes.formControl}>
            <InputLabel shrink>Lô thông tin</InputLabel>
            <Select
                value={searchParams.cardApplicationBatchId}
                onChange={onSelectChange}
                name='cardApplicationBatchId'
                MenuProps={{classes: {paper: classes.select}}}
            >
                {cardApplicationBatches.map(batch => <MenuItem key={batch.id} value={batch.id}>
                    {batch.id} - {batch.name}
                </MenuItem>)}
            </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
            <InputLabel shrink>Lớp/Phòng ban</InputLabel>
            <Select
                value={searchParams.unitId}
                onChange={onSelectChange}
                displayEmpty
                inputProps={{
                    name: 'unitId',
                }}
                MenuProps={{classes: {paper: classes.select}}}
            >
                {units.map(unit => <MenuItem key={unit.id} value={unit.id}>{unit.name}</MenuItem>)}
            </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
            <InputLabel shrink>Trạng thái</InputLabel>
            <Select
                value={searchParams.status}
                onChange={onSelectChange}
                displayEmpty
                inputProps={{
                    name: 'status',
                }}
            >
                {authStatuses.map(status => <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>)}
            </Select>
        </FormControl>
        <Button color="primary" variant={'outlined'}
                disabled={totalElements === 0 || loading}
                style={{float: 'right', marginLeft: 10, marginTop: 20}}
                onClick={capThe}>
            CẤP THẺ ĐÃ CHỌN
        </Button>
        <Button color="primary" variant={'outlined'}
                disabled={totalElements === 0 || loading}
                style={{float: 'right', marginTop: 20}}
                onClick={onRemoveAll}>
            LOẠI TOÀN BỘ
        </Button>
    </div>
})

const ProfileChangeLogCart = props => {
    const [items, setItems] = useState([])
    const [nextPage, setNextPage] = useState(0)
    const [hasMore, setHasMore] = useState(true)
    const [totalElements, setTotalElements] = useState(0)
    const [searchParams, setSearchParams] = useState({
        q: '',
        status: '',
    })

    const [units, setUnits] = useState([])
    const [cardApplicationBatches, setCardApplicationBatches] = useState([])

    useEffect(() => {
        fetchUnits()
        fetchCardApplicationBatches()
    }, [])

    useEffect(() => {
        fetchMoreData()
    }, [searchParams])

    const fetchUnits = async () => {
        const url = `/api/baseUnits/search/filter?page=0&q=&schoolYearId=` + (schoolYears?.length > 0 ? schoolYears[0]?.id : '') + `&size=999&sort=name%2CASC&`;
        const res = await axiosInstance.get(url)
        if (!res.data) {
            return
        }
        setUnits(prev => [...prev, ...res.data.content])
    }

    const fetchCardApplicationBatches = async () => {
        const res = await axiosInstance.get('/api/cardApplicationBatches?page=0&size=999&sort=id%2CDESC&')
        if (!res.data) {
            return
        }

        setCardApplicationBatches(res.data.content)
    }

    const fetchMoreData = async () => {
        const params = new URLSearchParams(searchParams)
        const pageSize = 25;
        const url = `/api/profileChangeLogReadys/cart?page=${nextPage}&size=25&sort=status&${params}`;
        const res = await axiosInstance.get(url)
        if (!res.data || !res.data.success) {
            setHasMore(false)
            return
        }

        const content = res.data.content
        setTotalElements(res.data.page.totalElements)
        if (content.length === 0) {
            setHasMore(false)
            return
        }

        if (content.length < pageSize) {
            setHasMore(false)
        } else {
            setHasMore(true)
        }
        setNextPage(currentPage => currentPage + 1)
        setItems(items.concat(content))
    }

    const removeCartItem = async id => {
        setItems(items => items.filter(item => item.id !== id))
        setTotalElements(prev => prev - 1)

        const url = `/api/profileChangeLogReadys/cart/${id}`;
        await axiosInstance.delete(url)
    }

    return <Card>
        <Title title="Danh sách yêu cầu cấp thẻ đã chọn"/>
        <CardContent>
            <div>
                <Filter searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        setNextPage={setNextPage}
                        setItems={setItems}
                        units={units}
                        cardApplicationBatches={cardApplicationBatches}
                        totalElements={totalElements}
                />
                <div style={{marginBottom: 8}}>Tổng cộng: {totalElements}</div>
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    height={window.innerHeight - 250}
                    loader={<CircularProgress style={{marginLeft: 10}} color="secondary"/>}
                >
                    <Table>
                        <TableHead style={{position: 'sticky', top: 0, zIndex: 1}}>
                            <TableRow>
                                <TableCell padding='none'>ID</TableCell>
                                <TableCell>Hình ảnh</TableCell>
                                <TableCell padding='none'>Mã VinaID</TableCell>
                                <TableCell>Họ Tên</TableCell>
                                <TableCell>Lớp/Phòng ban</TableCell>
                                <TableCell>Ngày sinh</TableCell>
                                <TableCell padding='none'>Mã lô</TableCell>
                                <TableCell>Ngày gửi in</TableCell>
                                <TableCell>Ngày giao</TableCell>
                                <TableCell>Trạng thái</TableCell>
                                <TableCell padding='none'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell padding='none' width={50}>{row.id}</TableCell>
                                    <TableCell><ImageProfile record={{...row}}/></TableCell>
                                    <TableCell padding='none' width={75}>{row.existCustomerId}</TableCell>
                                    <TableCell>{row.customerName}</TableCell>
                                    <TableCell>{units.find(unit => unit.id === row.unitId)?.name}</TableCell>
                                    <TableCell width={75}>
                                        <DateTimeField record={{...row}} source='dob' format='DD/MM/YYYY'/>
                                    </TableCell>
                                    <TableCell padding='none' width={50}>{row.internalBatchId}</TableCell>
                                    <TableCell width={75}>
                                        <DateTimeField record={{...row}} source='sentRequestDate' format='DD/MM/YYYY'/>
                                    </TableCell>
                                    <TableCell width={75}>
                                        <DateTimeField record={{...row}} source='deliveryDate' format='DD/MM/YYYY'/>
                                    </TableCell>
                                    <TableCell width={100}>
                                        <CardApplicationStatusField record={{...row}} source="status"/>
                                    </TableCell>
                                    <TableCell padding={'none'} width={130}>
                                        <Button style={{maxWidth: 130}} variant="contained"
                                                onClick={() => removeCartItem(row.id)}>LOẠI KHỎI LÔ</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                </InfiniteScroll>
            </div>
        </CardContent>
    </Card>

};

export default ProfileChangeLogCart;
