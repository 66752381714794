import React from 'react';
import {downloadFile, IFrameHtmlReport, ReportForm, schoolYears} from "../../components";
import {Card, CardContent, Grid} from '@material-ui/core';
import {AutocompleteInput, DateInput, ReferenceInput, required, SelectInput, Title} from 'react-admin';
import env from "../../env";
import moment from 'moment';

class SemiBoardingMissingCardReport extends React.Component {

    state = {
        openHtmlReport: false,
        htmlReportUrl: '',
        schoolYear: schoolYears[0].id,
        unitId: '',
        date: moment().format('YYYY-MM-DD')
    };

    onSchoolYearChanged = (e, value) => {
        this.setState({schoolYear: value})
    }

    htmlReport = () => {
        const htmlReportUrl = this.getReportUrl('HTML')
        this.setState({openHtmlReport: true, htmlReportUrl})
    }

    getReportUrl = (type) => {
        const token = localStorage.getItem('token');
        return `${env.baseURL}/reports/semi-boarding-missing-card?` +
            `type=` + type +
            `&unitId=` + this.state.unitId +
            `&schoolYear=` + this.state.schoolYear +
            `&date=` + this.state.date +
            `&token=` + token;
    }

    getReportUrlToDownload = (type) => {
        return `${env.baseURL}/reports/semi-boarding-missing-card?` +
            `type=` + type +
            `&unitId=` + this.state.unitId +
            `&schoolYear=` + this.state.schoolYear +
            `&date=` + this.state.date;
    }

    validate = (values) => {
        const errors = {};

        // Copy input values to params
        this.setState({
            unitId: values.unitId,
            schoolYear: values.schoolYear,
            date: values.date,
        })

        return errors;
    }

    pdfReport = () => {
        downloadFile(this.getReportUrlToDownload('PDF'), 'bao_cao_diem_danh_khong_the_ban_tru.pdf')
    }

    excelReport = () => {
        downloadFile(this.getReportUrlToDownload('XLS'), 'bao_cao_diem_danh_khong_the_ban_tru.xlsx')
    }

    render() {
        return (
            <Card>
                <Title title="Báo cáo điểm danh không thẻ bán trú"/>

                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                htmlReport={this.htmlReport}
                                pdfReport={this.pdfReport}
                                excelReport={this.excelReport}
                    >
                        <Grid container spacing={8}>
                            <p>Báo cáo học sinh bán trú có đi học nhưng không chạm thẻ trong ngày, dùng cho các trường
                                không
                                quản lý bán trú theo TKB</p>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <SelectInput label="resources.schoolYears.name" source="schoolYear"
                                             choices={schoolYears}
                                             onChange={this.onSchoolYearChanged}
                                             alwaysOn allowEmpty={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ReferenceInput label="resources.clazzes.name" source="unitId"
                                                reference="baseUnits"
                                                filter={{schoolYearId: this.state.schoolYear, isFullDay: true}}
                                                validate={required()}
                                                sort={{field: 'name', order: 'ASC'}}
                                                perPage={999}>
                                    <AutocompleteInput source="name"/>
                                </ReferenceInput>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.date" source="date"
                                           validate={required()} {...this.props} />
                            </Grid>
                        </Grid>
                    </ReportForm>
                    {
                        this.state.openHtmlReport &&
                        <IFrameHtmlReport src={this.state.htmlReportUrl}/>
                    }
                </CardContent>
            </Card>
        )
    }
}

export default SemiBoardingMissingCardReport;
