import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import GroupFields from './GroupFields'

export const GroupEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar {...props} />}>
      <Grid container spacing={8}>
        <GroupFields />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default GroupEdit
