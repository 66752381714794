import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const type = [
  {id: 'Không có', name: 'Không có'},
  {id: 'Có', name: 'Có'}
]

export const SwimTypeField = (props) => (
    <SelectField source={props.source} choices={type} {...props} />
)

export const SwimTypeInput = (props) => (
    <SelectInput source={props.source} choices={type} {...props} />
)
