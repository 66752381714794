import React, {useState} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, required, Title} from 'react-admin';
import {ReportForm, showErrorNotify, showSuccessNotify} from '../../components';
import moment from "moment";
import axiosInstance from "../../api/axios";

const TaoDangKySuatAn = (props) => {
    const [state, setState] = useState({
        fromDate: new Date(),
        toDate: new Date()
    });
    const [loading, setLoading] = useState(false);

    const excelReport = () => {
        setLoading(true)
        axiosInstance.get(`/reports/generateTempMealRegistration?fromDate=${moment(state.fromDate).format('YYYY-MM-DD')}&toDate=${moment(state.toDate).format('YYYY-MM-DD')}`)
            .then((rs) => {
                setLoading(false)
                if (rs && rs.data.success) {
                    showSuccessNotify('Đã tạo đăng ký suất ăn dự kiến thành công')
                } else {
                    showErrorNotify('Có lỗi, vui lòng liên hệ quản trị viên')
                }
            }).catch(() => {
            setLoading(false)
            showErrorNotify('Có lỗi, vui lòng liên hệ quản trị viên')
        })
    };

    const validate = (values) => {
        const errors = {};
        setState(values);
        return errors;
    };

    return (
        <Card>
            <Title title="resources.taoDangKySuatAn.name"/>
            <CardContent>
                <ReportForm params={state}
                            validate={validate}
                            excelReport={excelReport}
                            isLoading={loading}
                >

                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={12}>
                            <b>Lưu ý: Cần có thực đơn và thời khoá biểu bán trú để tạo dữ liệu đăng ký suất ăn</b>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="fromDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.toDate" source="toDate"
                                       validate={required()} {...props} />
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default TaoDangKySuatAn

