import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import StudentsByUnitsFields from './StudentsByUnitsFields'

export const StudentsByUnitsEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar {...props} />}>
      <Grid container spacing={8}>
        <StudentsByUnitsFields />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default StudentsByUnitsEdit
