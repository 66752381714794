import React from 'react';
import {downloadFile, ReportForm, schoolYears} from "../../components";
import {Grid, Card, CardContent }from '@material-ui/core';
import {
  DateInput,
  ReferenceInput,
  required,
  AutocompleteInput,
  Title,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  translate
} from 'react-admin';
import env from "../../env";
import moment from 'moment'
import { compose } from 'redux';

const EventReport = ({...props}) => {

  const [values, setValues] = React.useState({
    unitId: '',
    eventIds: '',
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    absentOnly: false
  });

  function excelReport() {
    downloadFile(getReportUrl(), 'bao_cao_diem_danh_su_kien.xlsx')
  }

  function getReportUrl() {
    return `${env.baseURL}/reports/event-report?` +
        `unitId=` + values.unitId +
        `&eventIds=` + values.eventIds.join(',') +
        `&fDate=` + values.fromDate +
        `&tDate=` + values.toDate +
        `&absentOnly=` + values.absentOnly;
  }

  function validate(val) {
    const errors = {};

    if (val.fromDate && val.toDate && (val.fromDate > val.toDate)) {
      errors.toDate = 'To date must be greater than From date'
    }

    setValues({
      eventIds: val.eventIds,
      unitId: val.unitId,
      fromDate: val.fromDate,
      toDate: val.toDate,
      absentOnly: val.absentOnly
    })

    return errors;
  }

  return (
      <Card>
        <Title title="Báo cáo điểm danh sự kiện" />
        <CardContent>
          <ReportForm validate={validate}
                      excelReport={excelReport}
                      params={values}
          >
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6} >
                <ReferenceArrayInput source="eventIds"
                                     reference="events"
                                     validate={required()}
                                     label={'Sự kiện'}
                                     perPage={100}
                                     style={{
                                       minWidth: 200,
                                     }}
                                     sort={{ field: 'name', order: 'ASC' }}
                                     {...props}>
                  <AutocompleteArrayInput optionText="name"
                                    onChange={(e) => props.onChange(e)}/>
                </ReferenceArrayInput>
              </Grid>
              <Grid item xs={12} sm={6} >
                <ReferenceInput source="unitId" reference="units" allowEmpty
                                perPage={999}
                                filter={{schoolYear: schoolYears[0].id, eventId: values.eventId}}
                >
                  <AutocompleteInput source="name" />
                </ReferenceInput>
              </Grid>

              <Grid item xs={12} sm={6} >
                <DateInput source="fromDate" validate={required()} {...props} />
              </Grid>
              <Grid item xs={12} sm={6} >
                <DateInput source="toDate" validate={required()} {...props} />
              </Grid>

              <Grid item xs={12} sm={6} >
                <BooleanInput source="absentOnly" label="Báo cáo vắng" defaultValue={false}/>
              </Grid>
            </Grid>
          </ReportForm>
        </CardContent>
      </Card>
  )
}

export default compose(
    translate
)(EventReport)
