import React from 'react';
import {Datagrid, FunctionField, List, TextField, UrlField} from "react-admin";
import env from "../../env";

const LinkingAppList = (props) => {
    return (
        <List  {...props} exporter={false}>
            <Datagrid rowClick="edit">
                <TextField source="id"/>
                <TextField source="name"/>
                <UrlField source="href"/>
                <FunctionField
                    render={({logoPath}) => <img style={{maxWidth: 150}} src={`${env.baseURL}/${logoPath}`}/>}/>
            </Datagrid>
        </List>
    );
};

export default LinkingAppList;
