import ViewListIcon from '@material-ui/icons/ViewList';

import LinkingAppList from './LinkingAppList';
import LinkingAppCreate from './LinkingAppCreate';
import LinkingAppEdit from './LinkingAppEdit';

export default {
    list: LinkingAppList,
    create: LinkingAppCreate,
    edit: LinkingAppEdit,
    icon: ViewListIcon,
};
