import ClazzIcon from '@material-ui/icons/ViewList';

import TeacherClazzList from './TeacherClazzList';
import TeacherClazzCreate from './TeacherClazzCreate';
import TeacherClazzEdit from './TeacherClazzEdit';

export default {
  list: TeacherClazzList,
  create: TeacherClazzCreate,
  edit: TeacherClazzEdit,
  icon: ClazzIcon,
};
