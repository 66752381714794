import React from 'react';
import Grid from '@material-ui/core/Grid'
import {DateInput, LongTextInput, TextInput} from 'react-admin';

const SmsMessageLogFields = ({className, ...props}) => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <DateInput source="createdOn" disabled {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <DateInput source="sentDate" disabled {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="customerName" disabled {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="smsCount" disabled {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="channel" disabled {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="phone" disabled {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="status" disabled {...props} />
        </Grid>
        <Grid item xs={12} sm={12}>
            <LongTextInput source="text" disabled {...props} />
        </Grid>
    </React.Fragment>
)

export default SmsMessageLogFields
