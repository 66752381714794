import EventGroupIcon from '@material-ui/icons/ViewList';

import EventGroupList from './EventGroupList';
import EventGroupCreate from './EventGroupCreate';
import EventGroupEdit from './EventGroupEdit';

export default {
  list: EventGroupList,
  create: EventGroupCreate,
  edit: EventGroupEdit,
  icon: EventGroupIcon,
};
