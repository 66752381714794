import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Button, refreshView} from 'react-admin';
import axiosInstance from "../../api/axios";
import {showErrorNotify, showSuccessNotify, showWarningNotify} from "../../components";

const ProfileChangeLogButton = (props) => {
    const [id, setId] = useState(null);
    const [showBtnAccept, setShowBtnAccept] = useState(false);
    const [showBtnReject, setShowBtnReject] = useState(false);

    useEffect(() => {
        const {status, id} = props.record
        setId(id)
        setShowBtnAccept(status === 'A_IN_REvVIEW');
        setShowBtnReject(status === 'A_IN_REvVIEW');
    }, [props.record])

    function handleAccept(e) {
        e.stopPropagation();
        if (id) {
            const url = `/api/profileChangeLogs/accept`;
            axiosInstance.put(url, [id])
                .then((res) => {
                    if (res.data && res.data.success) {
                        showSuccessNotify("Duyệt dữ liệu thành công");
                        setTimeout(() => {
                            props.refreshView();
                        }, 500)
                    } else {
                        showWarningNotify("Duyệt thông tin không thành công.");
                    }
                }).catch(err => {
                console.log(err)
                showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
            })
        }
    }

    function handleReject(e) {
        e.stopPropagation();
        if (id) {
            const url = `/api/profileChangeLogs/reject`;
            axiosInstance.put(url, [id])
                .then((res) => {
                    if (res.data && res.data.success) {
                        showSuccessNotify("Từ chối duyệt thông tin thành công");
                        setTimeout(() => {
                            props.refreshView();
                        }, 500)
                    } else {
                        showWarningNotify("Từ chối duyệt thông tin không thành công.");
                    }
                }).catch(err => {
                console.log(err)
                showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
            })
        }
    }


    return (
        <>
            {showBtnAccept &&
            <Button color="primary"
                    onClick={handleAccept} label={'Duyệt'}>
            </Button>
            }
            {showBtnReject &&
            <Button color="primary"
                    onClick={handleReject} label={'Từ chối'}>
            </Button>
            }
        </>
    )
}


export default connect(undefined, {refreshView})(ProfileChangeLogButton)
