import React from 'react';
import {AutocompleteInput, ReferenceInput, required, TextInput} from 'react-admin';
import Grid from '@material-ui/core/Grid'

const GuestInFields = props => (
    <React.Fragment>

        <Grid item xs={12} sm={6}>
            <TextInput source="cardUid" {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="cardNumber" {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="idNo" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="unitName" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="workDescription" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="contactId" reference="customers" allowEmpty {...props} >
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
        </Grid>

    </React.Fragment>
)

export default GuestInFields
