import SubjectIcon from '@material-ui/icons/ViewList';

import SubjectList from './SubjectList';
import SubjectCreate from './SubjectCreate';
import SubjectEdit from './SubjectEdit';

export default {
  list: SubjectList,
  create: SubjectCreate,
  edit: SubjectEdit,
  icon: SubjectIcon,
};
