import React from 'react';
import {Create, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import AdmissionCategoryFields from './AdmissionCategoryFields'

export const AdmissionCategoryCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<CustomToolbar/>} redirect="list">
            <Grid container spacing={8}>
                <AdmissionCategoryFields/>
            </Grid>
        </SimpleForm>
    </Create>
);

export default AdmissionCategoryCreate
