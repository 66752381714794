import React, {Fragment} from 'react';
import {
    AutocompleteInput,
    CardActions,
    Datagrid, DatagridBody,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    SelectField,
    SelectInput,
    TextField,
    TextInput
} from 'react-admin';
import {DateTimeField, schoolYears} from "../../components";
import ProfileChangeLogButton from "./ProfileChangeLogButton";
import AuthorizesProfileButton from "./AuthorizeProfilesButton";
import ImageProfile from "./ImageProfile";
import {GenderField} from "../../components/Gender";
import AuthorizesSelectedProfileButton from "./AuthorizeSelectedProfilesButton";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import ExportReportButton from "../CardApplication/ExportReportButton";
const authStatuses = [
    {id: 'A_IN_REvVIEW', name: 'profileAuthStatus.A_IN_REvVIEW'},
    {id: 'L_REJECTED', name: 'profileAuthStatus.L_REJECTED'},
    {id: 'M_WAIT_SYNC', name: 'profileAuthStatus.M_WAIT_SYNC'}
]

const selectAuthStatuses = [
    {id: 'A_IN_REvVIEW', name: 'profileAuthStatus.A_IN_REvVIEW'},
    {id: 'L_REJECTED', name: 'profileAuthStatus.L_REJECTED'},
    {id: 'M_WAIT_SYNC', name: 'profileAuthStatus.M_WAIT_SYNC'},
]

export const AuthStatusInput = (props) => (
    <SelectInput source={props.source} choices={selectAuthStatuses} {...props} />
)
export const AuthStatusField = (props) => (
    <SelectField source={props.source} choices={authStatuses} {...props} />
)


const CustomDatagridRow = ({record, resource, id, onToggleItem, children, selected, basePath}) => (
    <TableRow key={id}>
        <TableCell style={{width: "2%"}} padding="none">
            {(record.status !== 'AUTHORIZED') &&
            <Checkbox
                checked={selected}
                onClick={() => onToggleItem(id)}
            />
            }
        </TableCell>
        {React.Children.map(children, field => (
            <TableCell style={{width: field.props.width, padding: '4px 10px 4px 10px'}}
                       key={`${id}-${field.props.source}`}>
                <span style={{ height: 'auto'}}>
                    {React.cloneElement(field, {
                        record,
                        basePath,
                        resource,
                    })}
                </span>

            </TableCell>
        ))}
    </TableRow>
);

const CustomDatagridBody = props => <DatagridBody {...props} row={<CustomDatagridRow/>}/>;
const CustomDatagrid = props => <Datagrid {...props} body={<CustomDatagridBody/>}/>;

const ProfileChangeLogFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
        <ReferenceInput source="unitId" reference="baseUnits" alwaysOn allowEmpty
                        filter={{schoolYearId: schoolYears?.length > 0 ? schoolYears[0]?.id : ''}}
                        sort={{field: 'name', order: 'ASC'}}
                        perPage={999}
        >
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <AuthStatusInput source="status" alwaysOn allowEmpty />
    </Filter>
);

const PostActions = ({
                         bulkActions,
                         basePath,
                         displayedFilters,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter,
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {/* Add your custom actions */}
        <AuthorizesProfileButton/>
    </CardActions>
);
const PostBulkActionButtons = props => (
    <Fragment>
        <ExportReportButton {...props} />
        <AuthorizesSelectedProfileButton {...props} />
    </Fragment>
);
export const ProfileChangeLogList = props => (
    <List filters={<ProfileChangeLogFilter/>} {...props} actions={<PostActions/>} exporter={false} filterDefaultValues={{status: 'A_IN_REvVIEW' }}
          sort={{field: 'status', order: 'DESC'}} perPage={25} bulkActionButtons={<PostBulkActionButtons/>}>
        <CustomDatagrid rowSelectable={record => record.status !== 'AUTHORIZED'}
                        selectable={record => ( record.status !== 'AUTHORIZED' )}>
            <TextField source="id"/>
            <ImageProfile  {...props} label={'Hình thẻ'}/>
            <TextField source="customerId"/>
            <TextField source="customerName"/>
            <ReferenceField source="unitId" reference="baseUnits" allowEmpty>
                <TextField source="name"/>
            </ReferenceField>
            <GenderField source="gender"/>
            <DateTimeField source="dob" format="DD-MM-YYYY"/>
            <TextField source="createdBy"/>
            <DateTimeField source="createdOn" format="DD/MM HH:mm"/>
            <AuthStatusField source="status"/>
            <TextField source="reason"/>
            <TextField source="authId"/>
            <DateTimeField source="authDate" format="DD/MM HH:mm"/>
            <ProfileChangeLogButton/>
        </CustomDatagrid>
    </List>
);

export default ProfileChangeLogList
