import React, {useEffect, useState} from "react";
import axiosInstance from "../../api/axios";
import {showErrorNotify, showSuccessNotify, showWarningNotify} from "../../components";
import {connect} from 'react-redux';
import {Button, fetchEnd, fetchStart, refreshView} from 'react-admin';
import {compose} from 'redux';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Swal from "sweetalert2";
import {change, submit} from "redux-form";
import utils from "../../utils/utils";

const AcceptTransferBtn = (props) => {
    const {record} = props;
    const [showBtn, setShowBtn] = useState(false);

    useEffect(() => {
        setShowBtn(record.id && record.status == 'DRAFT')
    }, [record])

    const handleClick = (e) => {
        e.stopPropagation();
        if (showBtn) {
            const url = `/api/bankTransferConsolidates/checkBill/` + record.id;
            axiosInstance.get(url).then((response) => {
                const res = response?.data
                if (res?.success) {
                    Swal.fire({
                        title: "Bạn chắc chắn xác nhận khoản thanh toán này?",
                        text: "Sau khi xác nhận, khoản thu của " + res.customerName + " - Kế hoạch thu: " + res.description + " Số tiền " + utils.formatNumber(res.amount) + " sẽ được thanh toán.",
                        type: 'warning',
                        showCloseButton: true,
                        showCancelButton: true,
                        cancelButtonColor: '#d33',
                        focusConfirm: false,
                    })
                        .then((result) => {
                            if (result.value) {
                                const url = `/api/bankTransferConsolidates/accept/${record.id}`;
                                axiosInstance.post(url)
                                    .then((res) => {
                                        if (res.data && res.data.success) {
                                            showSuccessNotify("Xác nhận khoản thanh toán thành công.");
                                            window.location.reload()
                                        } else {
                                            showWarningNotify("Xác nhận khoản thanh toán không thành công.");
                                        }
                                    }).catch(err => {
                                    showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
                                })
                            }
                        })
                } else {
                    showWarningNotify(res?.error?.message);
                }
            }).catch(e => {
                showWarningNotify(e.message);
            });
        }
    };

    return (
        <>
            {showBtn &&
            <Button color="primary" style={{width: 150}}
                    onClick={handleClick} label={'Xác nhận'}>
                <PlayArrowIcon/>
            </Button>
            }
        </>
    )
}


const mapStateToProps = state => ({});

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    submit,
    refreshView
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    AcceptTransferBtn
);
