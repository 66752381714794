import React from 'react';
import Grid from '@material-ui/core/Grid'
import {DateInput, required, TextInput} from 'react-admin';
import {EFoodMenuInput} from "../../components/EFoodMenu";
import moment from "moment";

const MealRegistrationFields = props => {
    const {eatDate, finalizeDate, canEdit} = props.record
    const disabled = !!finalizeDate && moment(eatDate).isSameOrBefore(moment(finalizeDate))

    return (
        <React.Fragment>
            <Grid sm={12}>
                {canEdit ?
                    <>
                        {disabled && <b style={{color: 'red'}}>
                            (*) Không thể chỉnh sửa dữ liệu đã chốt. Ngày
                            chốt: {moment(props.record.finalizeDate).format("DD-MM-YYYY")}
                        </b>
                        }
                    </>
                    :
                    <b style={{color: 'red'}}>
                        (*) Không thể chỉnh sửa dữ liệu chưa hết thời gian đăng ký thực đơn
                    </b>
                }
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput disabled source="customerName"
                           validate={required()} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput disabled source="baseUnitName"
                           validate={required()} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput disabled source="unitName"
                           validate={required()} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <EFoodMenuInput source="regisFoodMenu" disabled validate={required()}  {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <EFoodMenuInput source="takenFoodMenu" disabled {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <EFoodMenuInput source="changedFoodMenu" {...props} disabled={disabled || !canEdit}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput source="changeReason" {...props} disabled={disabled || !canEdit}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <DateInput source="eatDate" {...props} disabled/>
            </Grid>
        </React.Fragment>
    )
}

export default MealRegistrationFields
