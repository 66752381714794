import React from 'react';
import {connect} from 'react-redux';
import {Button, refreshView} from 'react-admin';
import axiosInstance from "../../api/axios";
import {showErrorNotify, showSuccessNotify} from "../../components";

const CardApplicationSendReviewButton = (props) => {

    function onSubmit() {
        const url = `/api/cardApplication/sendReview`;
        axiosInstance.post(url, props.selectedIds).then(rs => {
            if (rs.data.success) {
                showSuccessNotify("Gửi duyệt dữ liệu thành công");
                window.location.reload()
            }
        }, err => {
            showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
        });
    }

    return (
        <Button variant="outlined" label="Gửi duyệt" onClick={onSubmit}/>
    );
}

export default connect(null, {refreshView})(
    CardApplicationSendReviewButton
);

