import UserChannelIcon from '@material-ui/icons/ViewList';

import UserChannelList from './UserChannelList';
import UserChannelEdit from './UserChannelEdit';

export default {
  list: UserChannelList,
  edit: UserChannelEdit,
  icon: UserChannelIcon,
};
