import React, {useEffect, useState, useCallback} from 'react';
import {Pagination, Title} from "react-admin";
import {Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, TextField} from '@material-ui/core'
import moment from "moment";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from '@material-ui/core/';
import axiosInstance from "../../api/axios";
import debounce from "debounce";


const Toolbar = ({setQ}) => {
    const onFilter = useCallback(debounce((q) => {
        setQ(q)
    }, 300), [])


    return <Grid style={{marginBottom: 16}}>
            <TextField id="standard-basic" label="Tìm kiếm" variant="standard" onChange={(e) => onFilter(e.target.value)}/>
        </Grid>
}

const UpdateCardHistory = ({history, location}) => {
    const params = Object.fromEntries(new URLSearchParams(location.search))

    const [data, setData] = useState([])
    const [perPage, setPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [totalElements, setTotalElements] = useState(0)
    const [q, setQ] = useState(params.q || '')


    useEffect(() => {
        onFilter(page, perPage, q)
    }, [page, perPage, q])

    const onFilter = (page, size, q = '') => {
        history.replace(`/update-card-history?page=${page}&size=${size}&q=${q}`)
        axiosInstance.get(`/api/cards/statues/histories?page=${page - 1}&size=${size}&q=${q}&sort=id,desc`)
            .then(res => {
                const {content = [], page = {}} = res.data
                setData(content)
                setTotalElements(page.totalElements)
            })
    }

    const onPerPageChange = (pageSize) => {
        setPerPage(pageSize)
    }

    const onPageChange = (page) => {
        setPage(page)
    }

    return (
        <React.Fragment>
            <Card style={{overflowY: 'auto'}}>
                <Title title="resources.update-card-history.name"/>
                <CardContent>
                    <Toolbar setQ={setQ}/>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Số thẻ</TableCell>
                                <TableCell>Trạng thái cũ</TableCell>
                                <TableCell>Trạng thái mới</TableCell>
                                <TableCell>Thời gian</TableCell>
                                <TableCell>Cập nhật bởi</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.cardNumber}</TableCell>
                                    <TableCell>{item.fromStatus === 'ACTIVE' ? 'Kích hoạt' : 'Khoá'}</TableCell>
                                    <TableCell>{item.toStatus === 'ACTIVE' ? 'Kích hoạt' : 'Khoá'}</TableCell>
                                    <TableCell>{moment(item.createdOn).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                    <TableCell>{item.createdBy}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Pagination page={page} setPage={onPageChange} perPage={perPage} setPerPage={onPerPageChange} total={totalElements}/>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default UpdateCardHistory;
