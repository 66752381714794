import React from 'react';
import Grid from '@material-ui/core/Grid'
import { DateInput, required, ReferenceInput, AutocompleteInput, ReferenceArrayInput,BooleanInput, AutocompleteArrayInput } from 'react-admin';

const FoodMenuFields = props => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <DateInput source="eatDate" validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <ReferenceInput source="eventId"
                      validate={required()}
                      {...props}
                      reference="events"
                      allowEmpty
                      sort={{field: 'name', order: 'ASC'}}
                      filter={{filterPath: 'filterSimple'}}
                      perPage={999}>
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
    </Grid>
    <Grid item xs={12} sm={6} >
      <ReferenceArrayInput source="foodIds" reference="foods" validate={required()} {...props} >
        <AutocompleteArrayInput />
      </ReferenceArrayInput>
    </Grid>
  </React.Fragment>
)

export default FoodMenuFields
