import React from 'react';
import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  required,
  TextInput,
  REDUX_FORM_NAME
} from 'react-admin';
import {connect} from 'react-redux'
import {change} from 'redux-form'
import {get} from "axios/index";
import env from "../env";
import './filterEventGroup.css';

class FilterEventGroup extends React.Component {
  state = {
    eventGroupIds: [],
    currentSelectEventGroupId: null,
    allSelectedEvents: [],
    allSelectedEventIds: [],
    allEventGroups: [],
    eventsOfGroup: [],
    selectedEventIds: [],
    selectedAllEventsOfGroup: false,
    selectedAllEventGroups: false,
    countAllEvents: 0
  };

  constructor(props) {
    super(props);
    if (props.record) {
      this.state.selectedEventIds = props.record.eventIds;
    }
  }

  componentDidMount() {
    this.initEventGroupMapData();
  }

  initEventGroupMapData = () => {
    this.fetchEventGroupData().then(rs => {
      if (rs.data) {
        let countEventGroupSelect = 0;
        for (let i = 0; i < rs.data.length; i++) {
          const eventGroup = rs.data[i];
          const events = eventGroup.events;
          let countEventSelect = 0;
          this.state.countAllEvents += events.length;
          for (let j = 0; j < events.length; j++) {
            const event = events[j];
            if (this.state.selectedEventIds && this.state.selectedEventIds.indexOf(event.id) > -1) {
              event.selected = true;
              this.state.allSelectedEvents.push(event);
              countEventSelect++;
            }
          }
          if (countEventSelect === events.length) {
            eventGroup.selectedAll = true;
            eventGroup.selected = true;
          } else if (countEventSelect > 0) {
            eventGroup.selected = true;
          }

          if (eventGroup.selected) {
            countEventGroupSelect++;
          }

          this.state.allEventGroups.push(eventGroup);
        }
        this.dispatchFields();
      }
    });
  };

  fetchEventGroupData() {
    const url = `${env.baseURL}/api/eventGroups/loadEventGroupsMap`;
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'content-type': 'application/json',
        'Authorization': token
      }
    };
    return get(url, config);
  }

  selectAllEventGroups = (e) => {
    if (e.target.checked) {
      this.state.allSelectedEvents = [];
      for (let i = 0; i < this.state.allEventGroups.length; i++) {
        const eventGroup = this.state.allEventGroups[i];
        eventGroup.selectedAll = true;
        const events = eventGroup.events;
        for (let j = 0; j < events.length; j++) {
          const event = events[j];
          event.selected = true;
          this.state.allSelectedEvents.push(event);
        }
      }
      if (this.state.currentSelectEventGroupId) {
        this.state.selectedAllEventsOfGroup = true;
      }
    } else {
      this.state.allSelectedEvents = [];
      if (this.state.currentSelectEventGroupId) {
        this.state.selectedAllEventsOfGroup = false;
      }
      for (let i = 0; i < this.state.allEventGroups.length; i++) {
        const eventGroup = this.state.allEventGroups[i];
        eventGroup.selectedAll = false;
        eventGroup.selected = false;
        const events = eventGroup.events;
        for (let j = 0; j < events.length; j++) {
          const event = events[j];
          event.selected = false;
        }
      }
    }
    this.dispatchFields();
  };

  selectAllEventsOfGroup = (e) => {
    const eventGroupId = this.state.currentSelectEventGroupId;
    if (eventGroupId) {
      if (e.target.checked) {
        this.state.selectedAllEventsOfGroup = true;
        const eventGroup = this.findEventGroup();
        const events = eventGroup.events;
        for (let i = 0; i < events.length; i++) {
          const event = events[i];
          if (this.state.allSelectedEvents.indexOf(event) === -1) {
            event.selected = true;
            this.state.allSelectedEvents.push(event);
          }
        }
        eventGroup.selectedAll = true;
      } else {
        this.state.selectedAllEventsOfGroup = false;
        const eventGroup = this.findEventGroup();
        const events = eventGroup.events;
        for (let i = 0; i < events.length; i++) {
          const event = events[i];
          event.selected = false;
          const eventIdx = this.state.allSelectedEvents.indexOf(event);
          this.state.allSelectedEvents.splice(eventIdx, 1);
        }
        eventGroup.selectedAll = false;
        eventGroup.selected = false;
      }
    }
    this.dispatchFields();
  };

  findEventGroup() {
    const that = this;
    return this.state.allEventGroups.find(function (evtGroup) {
      return evtGroup.id === that.state.currentSelectEventGroupId
    });
  }

  onEventGroupChange = (e) => {
    // only change current selected event group
    this.state.currentSelectEventGroupId = parseInt(e.target.id);
    this.state.eventsOfGroup = [];
    const eventGroup = this.findEventGroup();
    const events = eventGroup.events;
    let countSelectedEvent = 0;
    for (let j = 0; j < events.length; j++) {
      const event = events[j];
      this.state.eventsOfGroup.push(event);
      if (event.selected) {
        countSelectedEvent++;
      }
    }
    this.state.selectedAllEventsOfGroup = countSelectedEvent === events.length;

    this.forceUpdate()
  };

  dispatchFields() {
    console.log('dispatchFields');
    const {dispatch} = this.props;
    this.state.allSelectedEventIds = [];
    this.state.eventGroupIds = [];
    for (let i = 0; i < this.state.allSelectedEvents.length; i++) {
      const event = this.state.allSelectedEvents[i];
      if (this.state.eventGroupIds.indexOf(event.eventGroupId) === -1) {
        this.state.eventGroupIds.push(event.eventGroupId);
      }
      this.state.allSelectedEventIds.push(event.id);
    }
    this.state.selectedAllEventGroups = this.state.eventGroupIds.length === this.state.allEventGroups.length && this.state.allSelectedEventIds.length === this.state.countAllEvents;

    dispatch(change(REDUX_FORM_NAME, 'eventGroupIds', this.state.eventGroupIds));
    dispatch(change(REDUX_FORM_NAME, 'eventIds', this.state.allSelectedEventIds));
    this.forceUpdate()
  }

  onEventChange = (e) => {
    if (!e.target.id) {
      return;
    }
    const value = parseInt(e.target.id);
    if (this.state.allSelectedEventIds.indexOf(value) === -1) {
      // add new event
      let countSelectedEvent = 0;
      for (let i = 0; i < this.state.eventsOfGroup.length; i++) {
        const event = this.state.eventsOfGroup[i];
        if (event.id === value) {
          event.selected = true;
          this.state.allSelectedEvents.push(event);
        }
        if (event.selected) {
          countSelectedEvent++;
        }
      }
      const eventGroup = this.findEventGroup()
      if (eventGroup) {
        if (countSelectedEvent === this.state.eventsOfGroup.length) {
          eventGroup.selectedAll = true;
          this.state.selectedAllEventsOfGroup = true;
        } else {
          eventGroup.selected = true;
          this.state.selectedAllEventsOfGroup = false;
        }
      }

    } else {
      // remove event
      let countSelectedEvent = 0;
      for (let i = 0; i < this.state.eventsOfGroup.length; i++) {
        const event = this.state.eventsOfGroup[i];
        if (event.id === value) {
          event.selected = false;
          for (let j = 0; j < this.state.allSelectedEvents.length; j++) {
            if (this.state.allSelectedEvents[j].id === value) {
              this.state.allSelectedEvents.splice(j, 1);
              break
            }
          }
        }
        if (event.selected) {
          countSelectedEvent++;
        }
      }
      const eventGroup = this.findEventGroup()
      if (eventGroup) {
        if (countSelectedEvent === 0) {
          eventGroup.selected = false;
          eventGroup.selectedAll = false;
        } else {
          eventGroup.selected = true;
          eventGroup.selectedAll = false;
        }
      }
      this.state.selectedAllEventsOfGroup = false;
    }
    this.dispatchFields();
  };

  render() {
    return (
      <div className="wrapper">
        <div className="firstGroup">
          <label>Khu vực</label>
          <input type="checkbox" className="checkboxEvt" checked={this.state.selectedAllEventGroups}  onClick={this.selectAllEventGroups}/>

          <div className="eventGroupDivWrapper">
            {
              this.state.allEventGroups.map(item => {
                return <div
                  className={"eventGroupDiv " + (item.id === this.state.currentSelectEventGroupId && item.selectedAll ? 'currentSelected eventGroupSelectedAll' : (item.id === this.state.currentSelectEventGroupId && item.selected) ? 'currentSelected eventGroupSelected' : item.id === this.state.currentSelectEventGroupId ? 'currentSelected' : item.selectedAll ? 'eventGroupSelectedAll' : item.selected ? 'eventGroupSelected' : '')}
                  id={item.id} key={item.id}
                  onClick={this.onEventGroupChange}>
                  {item.name}
                </div>
              })
            }
          </div>
        </div>
        <div className="secondGroup">
          <label>Sự kiện & Vị trí</label>
          <input type="checkbox" className="checkboxEvt" checked={this.state.selectedAllEventsOfGroup} onClick={this.selectAllEventsOfGroup}/>

          <div className="eventDivWrapper">
            {
              this.state.eventsOfGroup.map(item => {
                return (
                  <div className={"eventDiv " + (item.selected ? 'eventSelected' : '')} id={item.id}
                       onClick={this.onEventChange}>
                    <div className="eventDivText"  id={item.id}>
                    {item.name}
                    </div>
                    <span className="tooltips-text">
                    {item.name}
                  </span>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}


export default connect(null, null)(FilterEventGroup)