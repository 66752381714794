import ECardTemplateIcon from '@material-ui/icons/ViewList';

import ECardTemplateList from './ECardTemplateList';
import ECardTemplateCreate from './ECardTemplateCreate';
import ECardTemplateEdit from './ECardTemplateEdit';

export default {
  list: ECardTemplateList,
  create: ECardTemplateCreate,
  edit: ECardTemplateEdit,
  icon: ECardTemplateIcon,
};
