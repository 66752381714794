import AccessDeviceIcon from '@material-ui/icons/ViewList';

import KiotProductMenuBatchList from './KiotProductMenuBatchList';
import IntroductionFileCreate from './KiotProductMenuBatchCreate';

export default {
  list: KiotProductMenuBatchList,
  create: IntroductionFileCreate,
  icon: AccessDeviceIcon,
};
