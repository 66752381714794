import React from 'react';
import {Edit, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import ECardTemplateFields from './ECardTemplateFields'
import ImageUploadWrapper from "../../components/ImageUploadWrapper";

export const ECardTemplateEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <Grid container spacing={8}>
                <ECardTemplateFields/>
                <Grid item xs={12} sm={6}>
                    <ImageUploadWrapper {...props} url={'/api/photo/eCardTemplate/'}/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);

export default ECardTemplateEdit
