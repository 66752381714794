import React from 'react';
import Grid from '@material-ui/core/Grid'
import {DateInput, required, TextInput} from 'react-admin';
import {GenderInput} from "../../components/Gender";

const CardApplicationFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="customerName" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <DateInput source="dob" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <GenderInput source="gender"/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="phone" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="internalCode" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="title" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="documentNumber" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="address" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="email" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="pob" {...props} />
        </Grid>

        <Grid item xs={12} sm={6}>
            <TextInput source="fatherName" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="fatherPhone" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="fatherAddress" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="fatherEmail" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="motherName" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="motherPhone" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="motherAddress" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="motherEmail" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="guardianName" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="guardianPhone" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="guardianAddress" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="guardianEmail" {...props} />
        </Grid>
    </React.Fragment>
)

export default CardApplicationFields
