import React from 'react';
import {
    AutocompleteInput,
    CardActions,
    CreateButton,
    Datagrid,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    TextField,
    TextInput
} from 'react-admin';
import PrintAllQrInfoLinkButton from "../../components/PrintAllQrInfoLinkButton";
import PrintQrInfoLinkButton from "../../components/PrintQrInfoLinkButton";

const EmployeeEnrollmentFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="unitId" reference="departments" alwaysOn>
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

const PostActions = ({
                         bulkActions,
                         basePath,
                         displayedFilters,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter,
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {/* Add your custom actions */}
        <PrintAllQrInfoLinkButton type={'e'}/>
        <CreateButton basePath={basePath}/>
    </CardActions>
);
export const EmployeeEnrollmentList = props => (
    <List filters={<EmployeeEnrollmentFilter/>} {...props} actions={<PostActions/>}
          perPage={25}>
        <Datagrid rowClick="edit">
            <TextField source="customerId"/>
            <TextField source="customerName"/>
            <TextField source="internalCode"/>
            <TextField source="title"/>
            <ReferenceField source="unitId" reference="baseUnits" allowEmpty>
                <TextField source="name"/>
            </ReferenceField>
            <PrintQrInfoLinkButton type={'e'}/>
        </Datagrid>
    </List>
);

export default EmployeeEnrollmentList
