import React from 'react';
import {required, TextInput} from 'react-admin';
import Grid from '@material-ui/core/Grid'

const GuestFields = props => (
    <React.Fragment>

        <Grid item xs={12} sm={6}>
            <TextInput source="cardUid" {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="cardNumber" {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="idNo" {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="unitName" {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="workDescription" {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="contactName" {...props} disabled/>
        </Grid>

    </React.Fragment>
)

export default GuestFields
