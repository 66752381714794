import React from 'react';
import Grid from '@material-ui/core/Grid'
import {BooleanInput, LongTextInput, required, SelectInput, TextInput} from 'react-admin';

const AccessDeviceFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="deviceNo" validate={required()}  {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="enabled" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="sendNotification" {...props} />
        </Grid>

        <Grid item xs={12} sm={6}>
            <LongTextInput style={{width: '80%'}} source="description" rows={10} {...props}/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <SelectInput source="type"
                         label='Loại'
                         choices={[
                             {id: 'CARD', name: 'THẺ'},
                             {id: 'FACE', name: 'KHUÔN MẶT'},
                         ]}
                         defaultValue='CARD'
            />
        </Grid>
    </React.Fragment>
)

export default AccessDeviceFields
