import React, {Component} from 'react';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import env from '../../env';
import './style.css';
import {get} from "axios/index";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';

const styles = {
  wrapper: {
    padding: '0px',
    margin: '0px',
    textAlign: 'center',
  },
  liTotalContent: {
    display: 'inline-block',
    marginRight: '100px'
  },
  liContent: {
    display: 'inline-block',
    marginRight: '10px'
  },
  imgBlock: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '4px'
  }
};


class OnlineAttendance extends Component {
  eventId = 0;
  eventName = '';
  stompClient;
  serverUrl = env.wsURL;
  allUnitsOfEvent = [];
  selectedUnitIds = [];
  unitAttendanceInfos = [];
  allSelectedUnitAttendanceInfo = [];
  selectedAllUnits = false;
  total = 0;

  componentDidMount() {
    let root = document.documentElement;
    const tenantId = localStorage.getItem('tenantId');
    const bg = 'url("https://api.vinaid.vn/files/background/' + tenantId + tenantId + '.jpg")';
    root.style.setProperty('--url-bg', bg )

    this.eventId = this.props.match.params.eventId;
    this.getEventName().then(rs => {
      if (rs.data) {
        this.eventName = rs.data;
      }
    });
    this.getUnitsOfEvent().then(rs => {
      if (rs.data) {
        for (let i = 0; i < rs.data.length; i++) {
          const unit = rs.data[i];
          unit.selected = true;
          this.allUnitsOfEvent.push(unit);
          this.selectedUnitIds.push(unit.id);
        }
        this.selectedAllUnits = true;
      }
      this.openWS();
    });
  }


  renderLegend = (props) => {
    const { payload } = props;
    return (
      <div>
        <ul  className="recharts-default-legend" style={styles.wrapper}>
          <li className="recharts-legend-item legend-item-0" style={styles.liTotalContent}>
           TỔNG SỐ: {this.total}
          </li>
          {
            payload.map((entry, index) => (
              <li className="recharts-legend-item legend-item-0" key={`item-${index}`} style={styles.liContent}>
                <svg className="recharts-surface" width="14" height="14" viewBox="0 0 32 32" version="1.1" style={styles.imgBlock}>
                  <path stroke="none" fill={entry.color} d="M0,4h32v24h-32z" className="recharts-legend-icon"></path>
                </svg>
                <span className="recharts-legend-item-text"> {entry.value}</span>
              </li>
            ))
          }
        </ul>
      </div>
    )

  }
  sendNewSelectUnits() {
    const config = this.getConfig();
    this.stompClient.send('/app/getEventAttendanceInfo', config, JSON.stringify({
      'eventId': this.eventId,
      'unitIds': this.selectedUnitIds
    }));
  }
  openWS() {
    const token =  localStorage.getItem('token');
    const config = this.getConfig();
    const socket = new SockJS(
        `${this.serverUrl}?token=${encodeURIComponent(token)}`
    );
    this.stompClient = Stomp.over(socket);
    this.stompClient.debug = null;
    const that = this;
    this.stompClient.connect({
      name: this.eventId,
      Authorization: token
    }, function (frame) {
      that.stompClient.send('/app/getEventAttendanceInfo', config, JSON.stringify({
        'eventId': that.eventId,
        'unitIds': that.selectedUnitIds
      }));
      that.stompClient.subscribe('/user/queue/returnEventAttendanceInfo', message => {
          if (message.body) {
            const attendanceInfo = JSON.parse(message.body);
            const newUnitAttendanceInfos = attendanceInfo.unitAttendanceInfos;
            if (attendanceInfo.updateAll) {
              that.unitAttendanceInfos = newUnitAttendanceInfos;
            } else {
              let tempList = [];
              for (let i = 0; i < that.unitAttendanceInfos.length; i++) {
                let found = false;
                for (let j = 0; j < newUnitAttendanceInfos.length; j++) {
                  const attendanceInfo = newUnitAttendanceInfos[j];
                  if (that.unitAttendanceInfos[i].unitId === attendanceInfo.unitId) {
                    found = true;
                    tempList.push(attendanceInfo);
                    break;
                  }
                }
                if (!found) {
                  tempList.push(that.unitAttendanceInfos[i]);
                }
              }

              that.unitAttendanceInfos = [];
              for (let i = 0; i < tempList.length; i++) {
                that.unitAttendanceInfos.push(tempList[i])
              }
            }
            let totalParticipants = 0;
            let totalAbsents = 0;
            for (let i = 0; i < that.unitAttendanceInfos.length; i++) {
              const attendanceInfo = that.unitAttendanceInfos[i];
              totalParticipants += attendanceInfo.countParticipants;
              totalAbsents += attendanceInfo.countAbsent;
            }

            that.total = totalParticipants + totalAbsents;
            that.allSelectedUnitAttendanceInfo = [];
            const allUnitAttendanceInfo = {
              "unitName": "Tổng",
              "countParticipants": totalParticipants,
              "countAbsent": totalAbsents
            };
            that.allSelectedUnitAttendanceInfo.push(allUnitAttendanceInfo);

          }
          that.forceUpdate();
        }, {Authorization: token});
    });
  };

  componentWillUnmount() {
    if (this.stompClient) {
      this.stompClient.disconnect();
    }
  }

  getUnitsOfEvent() {
    const url = `${env.baseURL}/api/units/getUnitsOfEvent?eventId=` + this.eventId;
    return get(url, this.getConfig());
  }

  getConfig() {
    const token = localStorage.getItem('token');
    return {
      headers: {
        'content-type': 'application/json',
        'Authorization': token
      }
    };
  }

  getEventName() {
    const url = `${env.baseURL}/api/events/getEventName?eventId=` + this.eventId;
    return get(url, this.getConfig());
  }

  selectAllUnit = (e) => {
    this.selectedUnitIds = [];
    if (e.target.checked) {
      for (let i = 0; i < this.allUnitsOfEvent.length; i++) {
        const unit = this.allUnitsOfEvent[i];
        unit.selected = true;
        this.selectedUnitIds.push(unit.id);
      }
    } else {
      for (let i = 0; i < this.allUnitsOfEvent.length; i++) {
        const unit = this.allUnitsOfEvent[i];
        unit.selected = false;
      }
    }
    this.selectedAllUnits = this.selectedUnitIds.length === this.allUnitsOfEvent.length;
    this.sendNewSelectUnits();
  };

  findUnit(unitId) {
    return this.allUnitsOfEvent.find(function (unit) {
      return unit.id === unitId
    });
  }

  onChangeSelectedUnit = (e) => {
    const unitId = parseInt(e.target.id);
    const index = this.selectedUnitIds.indexOf(unitId);
    const unit = this.findUnit(unitId);
    if (index === -1) {
      // add to list
      this.selectedUnitIds.push(unitId);
    } else {
      // remove from lists
      this.selectedUnitIds.splice(index, 1);
    }
    unit.selected = !unit.selected;

    this.selectedAllUnits = this.selectedUnitIds.length === this.allUnitsOfEvent.length;
    this.sendNewSelectUnits();
  };

  render() {
    return (
      <div className="evtWrapper">
          <input type="checkbox" id="toggle"/>
          <label id="lblUnit" htmlFor="toggle">
            <span>+</span>
          </label>
        <div className="showHide">
          <label>Đơn vị</label>
          <input type="checkbox" className="checkboxUnit" checked={this.selectedAllUnits}
                 onClick={this.selectAllUnit}/>
          <div className="eventGroupDivWrapper">
            {
              this.allUnitsOfEvent.map(item => {
                return <div
                  className={"unitDiv " + (item.selected ? 'unitSelected' : '')}
                  id={item.id} key={item.id}
                  onClick={this.onChangeSelectedUnit}>
                  {item.name}
                </div>
              })
            }
          </div>
        </div>

        <div className="chartContainer">
          <label className="chartLabel">KẾT QUẢ ĐIỂM DANH: {this.eventName}</label>
          <div className="chartContainer2">
          <ResponsiveContainer height='70%' width='100%' minHeight={550}>
            <BarChart
              data={this.unitAttendanceInfos}
              maxBarSize={30}
            >
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis dataKey="unitName"/>
              <YAxis/>
              <Tooltip />
              <Bar dataKey="countParticipants"  name="Đã chạm thẻ" stackId="a" fill="#00c853" label={{ position: 'top', fill: '#ffffff' }}/>
              <Bar dataKey="countAbsent"  name="Chưa chạm thẻ" stackId="a" fill="#e82020" label={{ position: 'top', fill: '#e82020' }}/>
            </BarChart>
          </ResponsiveContainer>
          </div>
          <ResponsiveContainer height='20%' width='100%' minHeight={80}>
            <BarChart
              layout="vertical"
              data={this.allSelectedUnitAttendanceInfo}
              maxBarSize={30}
            >
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis type="number"/>
              <YAxis dataKey="unitName" type="category"/>
              <Tooltip />
              <Legend content={this.renderLegend}/>
              <Bar dataKey="countParticipants" name="Đã chạm thẻ" stackId="a" fill="#00c853"/>
              <Bar dataKey="countAbsent" name="Chưa chạm thẻ" stackId="a" fill="#e82020" label={{ position: 'right', fill: '#e82020' }}/>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

export default OnlineAttendance

