import EmployeeEnrollmentIcon from '@material-ui/icons/ViewList';

import EmployeeEnrollmentList from './EmployeeEnrollmentList';
import EmployeeEnrollmentCreate from './EmployeeEnrollmentCreate';
import EmployeeEnrollmentEdit from './EmployeeEnrollmentEdit';

export default {
  list: EmployeeEnrollmentList,
  create: EmployeeEnrollmentCreate,
  edit: EmployeeEnrollmentEdit,
  icon: EmployeeEnrollmentIcon,
};
