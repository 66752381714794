import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const values = [
    {id: 'ELECTIVE', name: 'eAdmissionSubject.ELECTIVE'},
    {id: 'PHYSICAL', name: 'eAdmissionSubject.PHYSICAL'},
    {id: 'GRADUATION', name: 'eAdmissionSubject.GRADUATION'},
    {id: 'JOB', name: 'eAdmissionSubject.JOB'},
]

export const EAdmissionSubjectField = (props) => (
    <SelectField source={props.source} choices={values} {...props} />
)

export const EAdmissionSubjectInput = (props) => (
    <SelectInput source={props.source} choices={values} {...props}/>
)
