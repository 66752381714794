import StudentEnrollmentBatchIcon from '@material-ui/icons/ViewList';

import TeacherAssignmentBatchList from './TeacherAssignmentBatchList';
import PupilEnrollmentBatchCreate from './TeacherAssignmentBatchCreate';
import PupilEnrollmentBatchEdit from './TeacherAssignmentBatchEdit';

export default {
    list: TeacherAssignmentBatchList,
    create: PupilEnrollmentBatchCreate,
    edit: PupilEnrollmentBatchEdit,
    icon: StudentEnrollmentBatchIcon,
};
