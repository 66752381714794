import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const type = [
    {id: 'Việt Nam', name: 'Việt Nam',},
    {id: 'Áp-ga-ni-xtan', name: 'Áp-ga-ni-xtan',},
    {id: 'An ba ni', name: 'An ba ni',},
    {id: 'An giê ri', name: 'An giê ri',},
    {id: 'Ăng -gô-la', name: 'Ăng -gô-la',},
    {id: 'A-déc-bai-gian', name: 'A-déc-bai-gian',},
    {id: 'Ac-hen-ti-na', name: 'Ac-hen-ti-na',},
    {id: 'Úc', name: 'Úc',},
    {id: 'Áo', name: 'Áo',},
    {id: 'Ba-ranh', name: 'Ba-ranh',},
    {id: 'Băng-la-đét', name: 'Băng-la-đét',},
    {id: 'Ar-mê-nia', name: 'Ar-mê-nia',},
    {id: 'Bỉ', name: 'Bỉ',},
    {id: 'BuTan', name: 'BuTan',},
    {id: 'Bô-li-nia', name: 'Bô-li-nia',},
    {id: 'Booxx-na He-rơ-xe-gô-vin-na', name: 'Booxx-na He-rơ-xe-gô-vin-na',},
    {id: 'Bốt-xoa-na', name: 'Bốt-xoa-na',},
    {id: 'B-ra-xin', name: 'B-ra-xin',},
    {id: 'Quần đảo Sô-lô-môn', name: 'Quần đảo Sô-lô-môn',},
    {id: 'B-ru-nây', name: 'B-ru-nây',},
    {id: 'Bun-ga-ri', name: 'Bun-ga-ri',},
    {id: 'My-an-ma', name: 'My-an-ma',},
    {id: 'Bu-run-đi', name: 'Bu-run-đi',},
    {id: 'Be-lo-rut-xia', name: 'Be-lo-rut-xia',},
    {id: 'Căm-Pu-Chia', name: 'Căm-Pu-Chia',},
    {id: 'Can-mơ-run', name: 'Can-mơ-run',},
    {id: 'Ca-na-đa', name: 'Ca-na-đa',},
    {id: 'CH Trung Phi', name: 'CH Trung Phi',},
    {id: 'Sri-lan-ka', name: 'Sri-lan-ka',},
    {id: 'Sat', name: 'Sat',},
    {id: 'Nam phi', name: 'Nam phi',},
    {id: 'Chi lê', name: 'Chi lê',},
    {id: 'CHND Trung Hoa', name: 'CHND Trung Hoa',},
    {id: 'Đài Loan', name: 'Đài Loan',},
    {id: 'Cô-lôm-bi-a', name: 'Cô-lôm-bi-a',},
    {id: 'Công-Kinshasa', name: 'Công-Kinshasa',},
    {id: 'Cooxxta Rica', name: 'Cooxxta Rica',},
    {id: 'Croát-chia-a', name: 'Croát-chia-a',},
    {id: 'Cuba', name: 'Cuba',},
    {id: 'Cộng hòa Séc', name: 'Cộng hòa Séc',},
    {id: 'Đan Mạch', name: 'Đan Mạch',},
    {id: 'Dominica', name: 'Dominica',},
    {id: 'Ê-cu-a-đo', name: 'Ê-cu-a-đo',},
    {id: 'En Xan-va-đo', name: 'En Xan-va-đo',},
    {id: 'Et-to-ni', name: 'Et-to-ni',},
    {id: 'Phi-Ghi', name: 'Phi-Ghi',},
    {id: 'Phần Lan', name: 'Phần Lan',},
    {id: 'Pháp', name: 'Pháp',},
    {id: 'Gru-dia', name: 'Gru-dia',},
    {id: 'Dăm-bia', name: 'Dăm-bia',},
    {id: 'Palestine', name: 'Palestine',},
    {id: 'Đức', name: 'Đức',},
    {id: 'Gha-na', name: 'Gha-na',},
    {id: 'Ki-ri-ba-ti', name: 'Ki-ri-ba-ti',},
    {id: 'Hy Lạp', name: 'Hy Lạp',},
    {id: 'Goa-tê-ma-la', name: 'Goa-tê-ma-la',},
    {id: 'Ghi nê', name: 'Ghi nê',},
    {id: 'Guy-a-na', name: 'Guy-a-na',},
    {id: 'Ha-i-ti', name: 'Ha-i-ti',},
    {id: 'Ôn-đu-rát', name: 'Ôn-đu-rát',},
    {id: 'Hồng Công', name: 'Hồng Công',},
    {id: 'Hung-ga-ri', name: 'Hung-ga-ri',},
    {id: 'Ai-xlen', name: 'Ai-xlen',},
    {id: 'Ấn Độ', name: 'Ấn Độ',},
    {id: 'In-đô-nê-xi-a', name: 'In-đô-nê-xi-a',},
    {id: 'I-ran', name: 'I-ran',},
    {id: 'I-rắc', name: 'I-rắc',},
    {id: 'A-rơ-len', name: 'A-rơ-len',},
    {id: 'I-xra-en', name: 'I-xra-en',},
    {id: 'I-ra-li-a', name: 'I-ra-li-a',},
    {id: 'Gia-mai-ca', name: 'Gia-mai-ca',},
    {id: 'Nhật Bản', name: 'Nhật Bản',},
    {id: 'Ka-dắc-xtan', name: 'Ka-dắc-xtan',},
    {id: 'Giooc-đa-ni', name: 'Giooc-đa-ni',},
    {id: 'Kê-nia', name: 'Kê-nia',},
    {id: 'Triều Tiên', name: 'Triều Tiên',},
    {id: 'Hàn Quốc', name: 'Hàn Quốc',},
    {id: 'Cô-oét', name: 'Cô-oét',},
    {id: 'Cư-rơ-gư-dơ-xtan', name: 'Cư-rơ-gư-dơ-xtan',},
    {id: 'CHDCND Lào', name: 'CHDCND Lào',},
    {id: 'Li Băng', name: 'Li Băng',},
    {id: 'Latvia', name: 'Latvia',},
    {id: 'Li-bê-ri-a', name: 'Li-bê-ri-a',},
    {id: 'Libi', name: 'Libi',},
    {id: 'Lit-ten-xten', name: 'Lit-ten-xten',},
    {id: 'Lit-va', name: 'Lit-va',},
    {id: 'Luc-xem-bua', name: 'Luc-xem-bua',},
    {id: 'Ma Cao', name: 'Ma Cao',},
    {id: 'Ma-đa-gát-xka', name: 'Ma-đa-gát-xka',},
    {id: 'Ma-la0uy', name: 'Ma-la0uy',},
    {id: 'Ma-lai-xi-a', name: 'Ma-lai-xi-a',},
    {id: 'Man-đi-vơ', name: 'Man-đi-vơ',},
    {id: 'Ma-li', name: 'Ma-li',},
    {id: 'Man-ta', name: 'Man-ta',},
    {id: 'Mau-ri-ti-út', name: 'Mau-ri-ti-út',},
    {id: 'Mê hi cô', name: 'Mê hi cô',},
    {id: 'Ma-na-cô', name: 'Ma-na-cô',},
    {id: 'Mông Cổ', name: 'Mông Cổ',},
    {id: 'Môn-đô-va', name: 'Môn-đô-va',},
    {id: 'Mông-tê-nê-groo', name: 'Mông-tê-nê-groo',},
    {id: 'Ma Rốc', name: 'Ma Rốc',},
    {id: 'Mô-dăm-bich', name: 'Mô-dăm-bich',},
    {id: 'Ô man', name: 'Ô man',},
    {id: 'Nam-mi-bia', name: 'Nam-mi-bia',},
    {id: 'Nê-Pan', name: 'Nê-Pan',},
    {id: 'Hà Lan', name: 'Hà Lan',},
    {id: 'Va-nu-a-tu', name: 'Va-nu-a-tu',},
    {id: 'Ni Di Lân', name: 'Ni Di Lân',},
    {id: 'Ni-ca-ra-goa', name: 'Ni-ca-ra-goa',},
    {id: 'No-giê', name: 'No-giê',},
    {id: 'Ni-giê-ri-a', name: 'Ni-giê-ri-a',},
    {id: 'Na Uy', name: 'Na Uy',},
    {id: 'Mi-cờ-rô-nê-xia-a', name: 'Mi-cờ-rô-nê-xia-a',},
    {id: 'Quần đảo Ma-rơ-san', name: 'Quần đảo Ma-rơ-san',},
    {id: 'Pa-Ki-xtan', name: 'Pa-Ki-xtan',},
    {id: 'Pa-na-ma', name: 'Pa-na-ma',},
    {id: 'Pa-pua Niu Ghi - nê', name: 'Pa-pua Niu Ghi - nê',},
    {id: 'Pa-ra-goay', name: 'Pa-ra-goay',},
    {id: 'Pê - ru', name: 'Pê - ru',},
    {id: 'Phi-li-pin', name: 'Phi-li-pin',},
    {id: 'Ba Lan', name: 'Ba Lan',},
    {id: 'Bồ Đào Nha', name: 'Bồ Đào Nha',},
    {id: 'Đông -ti-mo', name: 'Đông -ti-mo',},
    {id: 'Pu-ec-to Ri-cô', name: 'Pu-ec-to Ri-cô',},
    {id: 'Quatar', name: 'Quatar',},
    {id: 'Ru-ma-no', name: 'Ru-ma-no',},
    {id: 'Liên Bang Nga', name: 'Liên Bang Nga',},
    {id: 'Ruanđa', name: 'Ruanđa',},
    {id: 'Ả rập Xê út', name: 'Ả rập Xê út',},
    {id: 'Xê nê gan', name: 'Xê nê gan',},
    {id: 'Sec-bi-a', name: 'Sec-bi-a',},
    {id: 'Xi-ê-a Lê- ôn', name: 'Xi-ê-a Lê- ôn',},
    {id: 'Xi-ga-po', name: 'Xi-ga-po',},
    {id: 'Slô-vac-ki-a', name: 'Slô-vac-ki-a',},
    {id: 'Slô-ven-ni-a', name: 'Slô-ven-ni-a',},
    {id: 'Sô-ma-li', name: 'Sô-ma-li',},
    {id: 'Dim-ba-buê', name: 'Dim-ba-buê',},
    {id: 'Tây Ban Nha', name: 'Tây Ban Nha',},
    {id: 'Nam Xu-đăng', name: 'Nam Xu-đăng',},
    {id: 'Xu-đăng', name: 'Xu-đăng',},
    {id: 'Thụy Điển', name: 'Thụy Điển',},
    {id: 'Thụy Sỹ', name: 'Thụy Sỹ',},
    {id: 'Si-ri-a', name: 'Si-ri-a',},
    {id: 'Tat-gi-ki-xtan', name: 'Tat-gi-ki-xtan',},
    {id: 'Thái Lan', name: 'Thái Lan',},
    {id: 'To-go', name: 'To-go',},
    {id: 'Tôn-ga', name: 'Tôn-ga',},
    {id: 'Các Tiểu vương quốc Ả Rập Thống Nhất', name: 'Các Tiểu vương quốc Ả Rập Thống Nhất',},
    {id: 'Tuy-ni-di', name: 'Tuy-ni-di',},
    {id: 'Thổ Nhĩ Kỳ', name: 'Thổ Nhĩ Kỳ',},
    {id: 'Turkmênistan', name: 'Turkmênistan',},
    {id: 'Tu-va-lu', name: 'Tu-va-lu',},
    {id: 'U-gan-da', name: 'U-gan-da',},
    {id: 'U-krai-na', name: 'U-krai-na',},
    {id: 'Ma-xê-đô-ni-a', name: 'Ma-xê-đô-ni-a',},
    {id: 'Ai cập', name: 'Ai cập',},
    {id: 'Vương Quốc Anh', name: 'Vương Quốc Anh',},
    {id: 'Tan-da-nia', name: 'Tan-da-nia',},
    {id: 'Mỹ', name: 'Mỹ',},
    {id: 'Buốc-ki-na-pha-xô', name: 'Buốc-ki-na-pha-xô',},
    {id: 'U-ru-goay', name: 'U-ru-goay',},
    {id: 'U-dơ-bê-ki-xtan', name: 'U-dơ-bê-ki-xtan',},
    {id: 'Vê-nê-xu-ê-la', name: 'Vê-nê-xu-ê-la',},
    {id: 'Tây Sa-moa', name: 'Tây Sa-moa',},
    {id: 'Y-ê-men', name: 'Y-ê-men',},
    {id: 'Gam-bi-a', name: 'Gam-bi-a'}
]

export const NationalityTypeField = (props) => (
    <SelectField source={props.source} choices={type} {...props} />
)

export const NationalityTypeInput = (props) => (
    <SelectInput source={props.source} choices={type} {...props} />
)
