import React, {useRef, useState} from 'react';
import {Create, showNotification, SimpleForm} from "react-admin";
import {Grid, showErrorNotify, showSuccessNotify} from "../../components";
import TenantPartnerField from "./LinkingAppField";
import {compose} from "redux";
import {connect} from "react-redux";
import axiosInstance from "../../api/axios";

const LinkingAppCreate = props => {
    const ref = useRef()
    const [saving, setSaving] = useState(false)

    const onSave = () => {
        setSaving(true)
        if (!validate(ref.current)) {
            setSaving(false)
            return
        }

        const {name, href, logo} = ref.current
        const formData = new FormData();
        formData.append('name', name)
        formData.append('href', href)
        formData.append('file', logo.rawFile)
        axiosInstance.post('/api/linking-apps', formData).then(rs => {
            setSaving(false)
            showSuccessNotify('Tạo thành công');
            setTimeout(() => {
                props.history.replace("/linking-apps")
            }, 1000)
        }, err => {
            setSaving(false)
            showErrorNotify('Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên', 'warning');
        })

    }

    const validate = ({name, href, logo}) => {
        if (!name?.trim()) {
            showErrorNotify('Vui lòng nhập tên ứng dụng', 'error');
            return false
        }

        if (!href?.trim()) {
            showErrorNotify('Vui Lòng nhập đường dẫn liên kết', 'error')
            return false
        }

        if (!logo?.rawFile) {
            showErrorNotify('Vui lòng cập nhật logo', 'error')
            return false
        }

        return true
    }

    const onFormValueChange = (values = {}) => {
        ref.current = values
    }

    return (
        <Create {...props}>
            <SimpleForm redirect="list" toolbar={null} validate={onFormValueChange}>
                <Grid container spacing={8}>
                    <TenantPartnerField onSave={onSave} saving={saving} history={props.history}/>
                </Grid>
            </SimpleForm>
        </Create>
    );
};


export default compose(
    connect(null, {showNotification}),
)(LinkingAppCreate)
