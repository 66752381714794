import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const type = [
  {id: 'Không', name: 'Không'},
  {id: 'Phật giáo', name: 'Phật giáo'},
  {id: 'Công giáo', name: 'Công giáo'},
  {id: 'Tin Lành', name: 'Tin Lành'},
  {id: 'Cao Đài', name: 'Cao Đài'},
  {id: 'Phật giáo Hòa Hảo', name: 'Phật giáo Hòa Hảo'},
  {id: 'Hồi giáo', name: 'Hồi giáo'},
  {id: 'Bà-la-môn giáo', name: 'Bà-la-môn giáo'},
  {id: 'Khác', name: 'Khác'}
]

export const ReligionTypeField = (props) => (
    <SelectField source={props.source} choices={type} {...props} />
)

export const ReligionTypeInput = (props) => (
    <SelectInput source={props.source} choices={type} {...props} />
)
