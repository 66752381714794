import MonTuChonIcon from '@material-ui/icons/ViewList';

import AdmissionAdditionalServiceList from './AdmissionAdditionalServiceList';
import AdmissionAdditionalServiceCreate from './AdmissionAdditionalServiceCreate';
import AdmissionAdditionalServiceEdit from './AdmissionAdditionalServiceEdit';

export default {
  list: AdmissionAdditionalServiceList,
  create: AdmissionAdditionalServiceCreate,
  edit: AdmissionAdditionalServiceEdit,
  icon: MonTuChonIcon,
};
