import React, {useEffect, useState} from 'react';
import {
    BooleanInput,
    LongTextInput,
    required,
    SaveButton,
    SelectInput,
    showNotification,
    TextInput,
    translate
} from 'react-admin';
import {Button, Grid} from '@material-ui/core'
import {BillAuthStatusInput, HocKiInput} from '../../components'
import TreeCheckBoxCustomInput from "../../components/TreeCheckBoxCustomInput";
import {compose} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import {Redirect} from 'react-router-dom';
import AuthorizeIcon from '@material-ui/icons/Done';
import {SmsMessageCategoryInput} from "../../components/SmsMessageCategory";
import axiosInstance from "../../api/axios";
import cachedApis from "../../api/cachedApis";

const styles = {
    button: {
        margin: 1,
    },
    cancelBtn: {
        marginLeft: 20
    },
    checkboxGroup: {
        "& > div ": {
            flexDirection: 'column',
        }
    },
    customCheckBox: {
        "& > div > label ": {
            width: '100%'
        }
    }
};

const SmsMessageFields = ({className, translate, permissions, defaultValue, save, authorize, ...props}) => {
    const [values, setValues] = useState({
        isRedirect: false,
        refreshList: false,
    });
    const [customers, setCustomers] = useState([]);
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        if (!defaultValue.isRedirect) {
            getCustomersWithUnits();
        } else {
            setValues({isRedirect: true, refreshList: true});
        }
        getTemplates()
    }, [defaultValue.isRedirect]);

    function getCustomersWithUnits() {
        cachedApis.geSmsCustomerUnits(true).then(rs => {
            setCustomers(rs);
        })
    }

    function getTemplates() {
        let url = `/api/smsTemplate/sample`;
        axiosInstance.get(url).then(rs => {
            setTemplates(rs.data)
        })
    }

    function cancel() {
        setValues({isRedirect: true, refreshList: false});
    }

    if (values.isRedirect) {
        return <Redirect to={'/smsMessages?refreshView=' + values.refreshList}/>;
    } else {
        return (
            <React.Fragment>
                <Grid item xs={12} sm={12}>
                    <SelectInput source="category" label="resources.smsMessages.fields.sample"
                                 disabled style={{width: '100%'}}
                                 choices={templates}/>
                </Grid>
                <Grid container xs={12} sm={6}>
                    <Grid item xs={12} sm={6}>
                        <SmsMessageCategoryInput source="category"
                                                 label="resources.smsMessages.fields.category"
                                                 validate={required()}
                                                 defaultValue={defaultValue.category}
                                                 onChange={(e, v) => props.handleSelectChange('category', v)}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <BooleanInput source="forceSms" label="resources.smsMessages.fields.forceSms"
                                      onChange={props.onChange('forceSms')}/>
                    </Grid>
                    <Grid container xs={12} sm={12}>
                        <Grid item xs={12} sm={6}>
                            <BillAuthStatusInput source="authStatus" disabled
                                                 label="resources.messages.fields.authStatus"
                                                 defaultValue={defaultValue.authStatus}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextInput source="result" label="resources.smsMessages.fields.result" disabled
                                       defaultValue={defaultValue.result}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <HocKiInput source="hocKy" label="resources.smsMessages.fields.hocKy"
                                defaultValue={defaultValue.hocKy}
                                style={{
                                    display: defaultValue.category === 'KQHT' ? 'inline-flex' : 'none'
                                }}
                                onChange={(e, v) => props.handleSelectChange('hocKy', v)}/>
                    <LongTextInput source="freeText" label="resources.smsMessages.fields.freeText"
                                   style={{
                                       width: '100%',
                                       display: defaultValue.category === 'FREE_TEXT' ? 'inline-flex' : 'none'
                                   }}
                                   rows={5}
                                   defaultValue={defaultValue.freeText} onChange={props.onChange('freeText')}/>
                    {defaultValue.category === 'FREE_TEXT' &&
                    <span>{'Số ký tự: ' + (defaultValue.freeText ? defaultValue.freeText.length + '/160' : '0/160')}</span>
                    }
                </Grid>

                <Grid item xs={12} sm={6} style={{maxHeight: '450px', paddingRight: '16px'}}>
                    <label style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: '1rem'}}>
                        {translate('resources.messages.fields.customerIds')}
                    </label>
                    <div style={{maxHeight: '400px', overflow: 'auto'}}>
                        <TreeCheckBoxCustomInput data={customers}
                                                 selected={defaultValue.customerIds}
                                                 expandTopLevel={true}
                                                 onChange={props.onChange('cuzIds')}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} sm={12} style={{marginTop: 15}}>
                    {defaultValue.authStatus !== 'AUTHORIZED' &&
                    <>
                        <Button variant="contained"
                                color="primary"
                                style={styles.button}
                                disabled={defaultValue.saveDisabled || props.loading}
                                onClick={save}>
                            <SaveIcon/> Lưu
                        </Button>
                        {
                            permissions && permissions.includes('CHECKER') &&
                            <SaveButton
                                icon={<AuthorizeIcon/>}
                                label='ra.action.authorize'
                                style={{marginLeft: '16px'}}
                                onClick={authorize}
                                disabled={defaultValue.saveDisabled || props.loading}
                                {...props}
                            />
                        }</>
                    }

                    <Button variant="contained" style={styles.cancelBtn}
                            disabled={defaultValue.saveDisabled || props.loading}
                            onClick={cancel}>
                        <CancelIcon/> Hủy
                    </Button>
                </Grid>
            </React.Fragment>
        )
    }
}
export default compose(
    translate,
    connect(null, {showNotification}),
    withStyles(styles)
)(SmsMessageFields)
