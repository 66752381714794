import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { submit, isSubmitting } from 'redux-form';
import {
  withDataProvider,
  translate,
  fetchEnd,
  fetchStart,
  showNotification,
  Button,
  SaveButton,
  SimpleForm,
  CREATE,
} from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

class QuickAddButton extends Component {
  state = {
    error: false,
    showDialog: false
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleSaveClick = () => {
    const { submit } = this.props;

    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form
    submit('quick-create-form');
  };

  handleSubmit = values => {
    const {
      dataProvider,
      referenceResource,
      showNotification,
      onRecordAdded,
      defaultValue,
    } = this.props;

    const data = {}
    Object.assign(data, values)
    Object.assign(data, defaultValue)


    dataProvider(CREATE, referenceResource, { data: data })
      .then(() => {
        onRecordAdded && onRecordAdded();
        showNotification('ra.notification.created');
        this.setState({ showDialog: false });
      })
      .catch(error => {
        showNotification(error.message, 'error');
      })
  };

  render() {
    const { showDialog } = this.state;
    const { isSubmitting, translate, referenceResource, defaultValue, label, color, variant, children } = this.props;

    return (
      <Fragment>
        <Button 
            label={label || "ra.action.create" } 
            color={color || "secondary"} 
            variant={variant || "raised"} 
            onClick={this.handleClick} 
        >
          <AddIcon />
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label={translate(label)}
        >
          <DialogTitle>{translate(label)}</DialogTitle>
          <DialogContent>
            <SimpleForm
              defaultValue={defaultValue}
              // We override the redux-form name to avoid collision with the react-admin main form
              form="quick-create-form"
              resource={referenceResource}
              // We override the redux-form onSubmit prop to handle the submission ourselves
              onSubmit={this.handleSubmit}
              // We want no toolbar at all as we have our modal actions
              toolbar={null}
            >
              { children }
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton
              saving={isSubmitting}
              onClick={this.handleSaveClick}
            />
            <Button
              label="ra.action.cancel"
              onClick={this.handleCloseClick}
            >
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

QuickAddButton.propTypes = {
  dataProvider: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting('quick-create-form')(state)
});

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
  submit
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps), 
  translate,
  withDataProvider,
)(QuickAddButton);
