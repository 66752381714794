import React, {useState} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm} from '../../components';
import env from '../../env';
import moment from "moment";

const TongHopDonXinNghi = (props) => {
    const [state, setState] = useState({
        fromDate: new Date(),
        toDate: new Date(),
    });

    const getReportUrl = () => {
        return `${env.baseURL}/reports/tongHopDonXinNghi?fromDate=` + moment(state.fromDate).format('YYYY-MM-DD')
            + '&toDate=' + moment(state.toDate).format('YYYY-MM-DD');
    };

    const excelReport = () => {
        downloadFile(getReportUrl(), 'tong_hop_don_xin_nghi.xlsx')
    };

    const validate = (values) => {
        const errors = {};
        setState(values);
        return errors;
    };

    return (
        <Card>
            <Title title="resources.tongHopDonXinNghi.name"/>
            <CardContent>
                <ReportForm params={state}
                            validate={validate}
                            excelReport={excelReport}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="fromDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.toDate" source="toDate"
                                       validate={required()} {...props} />
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default TongHopDonXinNghi

