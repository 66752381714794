import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {ReferenceInput, Title} from 'react-admin';
import {downloadFile, ReportForm, showErrorNotify, TreeCheckboxInput} from '../../components';
import env from '../../env';

class StudentsHaveRemain extends React.Component {
  state = {
    params: {
      unitIds: [],
      note: '',
    }
  };

  getReportUrl = (type) => {
    return `${env.baseURL}/reports/payments/studentsHaveRemain?` +
      `type=` + type +
      `&pUnitIds=` + this.state.params.unitIds;
  };


  excelReport = () => {
    if (!this.state.params.unitIds || this.state.params.unitIds.length === 0) {
      showErrorNotify("Vui lòng chọn danh sách lớp")
      return;
    }

    downloadFile(this.getReportUrl('XLS'), 'bao_cao_HS_con_du_thanh_toan.xlsx')
  };


  validate = (values) => {
    const errors = {};

    // Copy input values to params
    this.setState({params: values})

    return errors;
  }

  render() {
    return (
      <Card>
        <Title title="resources.studentsHaveRemain.name"/>
        <CardContent>
          <ReportForm validate={this.validate}
                      excelReport={this.excelReport}
                      params={this.state}
          >
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6}>
                <label>Danh sách lớp *</label>
                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                  <ReferenceInput source="unitIds" reference="units" perPage={999} {...this.props} >
                    <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                  </ReferenceInput>
                </div>
              </Grid>
            </Grid>
          </ReportForm>

        </CardContent>
      </Card>
    )
  }
}

export default StudentsHaveRemain

