import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const type = [
  {id: 'Không có', name: 'Không có'},
  {id: 'Công an', name: 'Công an'},
  {id: 'Quân đội', name: 'Quân đội'},
  {id: 'Hộ nghèo', name: 'Hộ nghèo'},
  {id: 'Cận nghèo', name: 'Cận nghèo'},
]

export const InsuranceTypeField = (props) => (
    <SelectField source={props.source} choices={type} {...props} />
)

export const InsuranceTypeInput = (props) => (
    <SelectInput source={props.source} choices={type} {...props} />
)
