import React from 'react';
import {connect} from 'react-redux'
import axios from 'axios';
import {SaveButton, showNotification} from 'react-admin';
import env from '../env';

import UploadIcon from '@material-ui/icons/Done';

class CalendarUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            calendarId: props.record.id,
            file: null,
            disableUpload: false
        }
    }

    handleFileChange = (e) => {
        this.setState({
            file: e.target.files[0]
        })
    };
    handleSubmit = (e) => {
        this.setState({disableUpload: true})
        let form_data = new FormData();
        form_data.append('file', this.state.file);
        form_data.append('calendarId', this.state.calendarId);
        let url = env.baseURL + '/api/calendarUpload/';
        axios.post(url, form_data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': window.localStorage.getItem('token'),
            }
        })
            .then(res => {
                this.props.showNotification('ra.notification.created');
                this.setState({disableUpload: false})
            })
            .catch(err => {
                this.props.showNotification('ra.message.invalid_form', 'warning');
                this.setState({disableUpload: false})
            })
    };

    render() {
        return (
            <div xs="12" sm="6" spacing="8">
                <label>Chọn file lịch </label>
                <input type="file"
                       id="file"
                       name="name"
                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                       onChange={this.handleFileChange} required/>
                <SaveButton
                    disabled={this.state.disableUpload}
                            icon={<UploadIcon/>}
                            label='ra.action.upload'
                            onClick={this.handleSubmit}
                />
            </div>
        );
    }
}


export default connect(null, { showNotification })(CalendarUpload);