import React from 'react';
import {Redirect} from 'react-router-dom';
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {Card, CardContent, Grid, CircularProgress} from '@material-ui/core';
import {SelectInput, showNotification, Title} from 'react-admin';
import {schoolYears} from "../../components";
import MigrateForm from "./MigrateForm";
import axiosInstance from "../../api/axios";
import cachedApis from "../../api/cachedApis";
import TreeCheckBoxUnitInput from "./TreeCheckBoxUnitInput";
import TreeCheckBoxCustomInput from "../../components/TreeCheckBoxCustomInput";

const StudentEnrollmentMigrate = (props) => {

  const [values, setValues] = React.useState({
    oldSchoolYear: schoolYears.length > 1 ? schoolYears[1].id : schoolYears[0].id,
    newSchoolYear: schoolYears[0].id,
    studentIds: [],
    unitIds: [],
    oldUnitIds: [],
    isRedirectToListEnroll: false,
    customers: [],
    units: [],
    cuzIds: [],
    tmpUnitIds: [],
  });

  const [loadingCustomers, setLoadingCustomers] = React.useState(true);
  const [loadingUnits, setloadingUnits] = React.useState(true);

  React.useEffect(() => {
    setValues({...values});
    setLoadingCustomers(true);
    getStudentsByUnit(values.oldSchoolYear);
  }, [values.isRedirect, values.oldSchoolYear]);

  React.useEffect(() => {
    setValues({...values});
    setloadingUnits(true);
    getUnits(values.newSchoolYear);
  }, [values.newSchoolYear]);

  function getStudentsByUnit(schoolYearId) {
    cachedApis.getCustomerUnits(true, schoolYearId).then(rs => {
      setValues({...values, customers: rs});
      setLoadingCustomers(false);
    })
  }

  function getUnits(schoolYearId) {
    cachedApis.getUnits(true, schoolYearId).then(rs => {
      setValues({...values, units: rs});
      setloadingUnits(false);
    })
  }

  function onRedirect() {
    setValues({...values, isRedirectToListEnroll: true})
  }

  function moveEnroll() {
    createEnroll(values, true).then(rs => {
      if (rs.data.success) {
        cachedApis.removeCachedCustomerUnits();
        onRedirect();
      }
    }, err => {
      props.showNotification(err.message, 'warning');
    });
  }

  function copyEnroll() {
    createEnroll(values, false).then(rs => {
      if (rs.data.success) {
        cachedApis.removeCachedCustomerUnits();
        onRedirect();
      }
    }, err => {
      props.showNotification(err.message, 'warning');
    });
  }

  function createEnroll(data, isDeleteOld) {
    const url = `/api/enrollment/bulk-create?deleteOldEnroll=` + isDeleteOld;
    return axiosInstance.put(url, data);
  }

  function onStudentSelected(value) {
    const studentIds = [];
    const oldEnrollUnitIds = [];
    value.forEach(i => {
      const temp = "" + i;
      let zeroIdx = temp.indexOf("0000");
      let lastZeroIdx = -1;
      while (zeroIdx >= 0) {
        lastZeroIdx = zeroIdx;
        zeroIdx = temp.indexOf("0000", zeroIdx + 1);
      }
      if (lastZeroIdx != -1 && lastZeroIdx < temp.length - 1) {
        let stdId = Number(temp.substr(lastZeroIdx + 4));
        let unitId = Number(temp.substr(0, lastZeroIdx));
        if (!studentIds.includes(stdId)) {
          studentIds.push(stdId);
        }
        if (!oldEnrollUnitIds.includes(unitId)) {
          oldEnrollUnitIds.push(unitId);
        }
      }
    });
    setValues(
        {...values, studentIds: studentIds, oldUnitIds: oldEnrollUnitIds});
  }

  if (values.isRedirectToListEnroll) {
    return (
        <Redirect to='/studentEnrollments'/>
    )
  } else {
    return (
        <React.Fragment>
          <Card>
            <Title title="resources.studentEnrollmentMigrate.name"/>
            <CardContent>
              <MigrateForm params={values}
                           copyEnroll={copyEnroll}
              >
                <Grid container spacing={8}>
                  <Grid item xs={12} sm={6}>
                    <SelectInput source="oldSchoolYear"
                                 label="resources.studentEnrollmentMigrate.oldSchoolYear"
                                 choices={schoolYears}
                                 style={{width: 400}}
                                 onChange={(e, v) => setValues(
                                     {...values, oldSchoolYear: v})}
                                 alwaysOn allowEmpty={false}
                    />
                    <div style={{
                      maxHeight: '400px',
                      overflow: 'auto',
                      marginTop: 20,
                      width: 400
                    }}>
                      <label><b>Danh sách học sinh</b></label>
                      <div style={{
                        maxHeight: '400px',
                        overflow: 'auto',
                        marginTop: 20
                      }}>
                        {loadingCustomers ?
                            <CircularProgress color="secondary"/>
                            :
                            <TreeCheckBoxCustomInput data={values.customers}
                                                     selected={values.cuzIds}
                                                     expandTopLevel={true}
                                                     onChange={onStudentSelected}/>
                        }
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <SelectInput source="newSchoolYear"
                                 label="resources.studentEnrollmentMigrate.newSchoolYear"
                                 choices={schoolYears}
                                 style={{width: 400}}
                                 onChange={(e, v) => setValues(
                                     {...values, newSchoolYear: v})}
                                 alwaysOn allowEmpty={false}
                    />
                    <div style={{
                      maxHeight: '400px',
                      overflow: 'auto',
                      marginTop: 20,
                      width: 400
                    }}>
                      <label><b>Danh sách lớp</b></label>
                      <div style={{
                        maxHeight: '400px',
                        overflow: 'auto',
                        marginTop: 20
                      }}>
                        {loadingUnits ?
                            <CircularProgress color="secondary"/>
                            :
                            <TreeCheckBoxUnitInput data={values.units}
                                                   expandTopLevel={true}
                                                   selected={values.tmpUnitIds}
                                                   onChange={(e) => setValues(
                                                       {...values, unitIds: e})}/>
                        }
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </MigrateForm>
            </CardContent>
          </Card>
        </React.Fragment>
    );
  }
}

export default compose(
    connect(null, {showNotification})
)(StudentEnrollmentMigrate);
