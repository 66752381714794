import React from 'react';
import {InputLabel, Select, MenuItem, FormControl, Button} from '@material-ui/core';

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 252,
  },
  button: {
    height: 10,
    marginTop: 10
  }
}


export default function SchoolFeeTimeSelectComponent(props) {
  return (
      <form style={styles.root} autoComplete="off">
        <FormControl style={styles.formControl}>
          <InputLabel htmlFor="age-helper">Chọn kỳ</InputLabel>
          <Select
              value={props.selectedFeeTime}
              onChange={props.onChangeFeeTime}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {props.feeTimes.map((feeTime, idx) => (
                <MenuItem key={idx} value={feeTime}>{feeTime}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" color={'primary'} component="span"
                style={styles.button} onClick={props.onSubmit}>Gửi</Button>
      </form>
  )
}
