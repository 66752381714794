import React from 'react';
import {
  ChipField,
  Datagrid,
  Filter,
  List,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextField,
  TextInput
} from 'react-admin';
import {DateTimeField} from "../../components";

const AccessDeviceFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const FoodMenuList = props => (
  <List filters={<AccessDeviceFilter />} {...props}  perPage={25} exporter={false} >
    <Datagrid rowClick="edit" >
      <TextField source="id"/>
      <TextField source="eatDate" style={{width: 100}}/>
      <ReferenceField source="eventId" reference="events" style={{width: 150}}>
        <TextField source="name"/>
      </ReferenceField>
      <ReferenceArrayField reference="foods" source="foodIds">
        <SingleFieldList>
          <ChipField source="name"/>
        </SingleFieldList>
      </ReferenceArrayField>
      <DateTimeField source="lastUpdatedOn" format="DD-MM-YYYY HH:mm" style={{width: 150}}/>
      <TextField source="lastUpdatedBy" style={{width: 100}}/>
    </Datagrid>
</List>
);

export default FoodMenuList
