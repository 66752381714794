import axios from "axios";
import {closeNotify, showErrorNotify, showInfoNotify, showSuccessNotify, showWarningNotify} from "./ShowNotify";

export const downloadFile = (url, fileName) => {
    return new Promise(function (resolve, reject) {
        showInfoNotify("Đang tải file, vui lòng chờ trong giây lát!")
        axios({
            url: url,
            method: 'GET',
            timeout: 600000,
            responseType: 'blob', // important
            headers: {
                Authorization: localStorage.getItem('token')
            }
        }).then((res) => {
            closeNotify();
            if (res.data && res.data.size > 0) {
                let fileNameFromSv = res.headers['attachmentfilename'];
                let blob = new Blob([res.data]);
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileNameFromSv ? fileNameFromSv : fileName;
                link.click();
                resolve('true');
            } else {
                showWarningNotify("File không khả dụng, vui lòng thử lại hoặc liên hệ quản trị viên");
                resolve('true');
            }
        }).catch(err => {
            showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
            reject('false');
        })
    });
};

export const processZipFile = (url, file) => {
    return new Promise(function (resolve, reject) {
        showInfoNotify("Đang xử lí file, vui lòng chờ trong giây lát!");
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('file', file);
        axios.post(url, formData, {
            timeout: 600000,
            responseType: 'blob', // important
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': token
            },
        }).then((res) => {
            if (res.data && res.data.size > 0) {
                let fileNameFromSv = res.headers['attachmentfilename'];
                let blob = new Blob([res.data]);
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileNameFromSv ? fileNameFromSv : 'ket_qua.xlsx';
                link.click();
                showSuccessNotify("Kiểm tra file kết quả");
                resolve('true');
            } else {
                showSuccessNotify("Đã xử lí thành công toàn bộ file");
                resolve('true');
            }
        }).catch(err => {
            showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
            resolve('false');
        });
    });

};

export const downloadFilePost = (url, fileName, data) => {
    return new Promise(function (resolve, reject) {
        showInfoNotify("Đang tải file, vui lòng chờ trong giây lát!")
        axios({
            url: url,
            method: 'POST',
            data: data,
            timeout: 600000,
            responseType: 'blob', // important
            headers: {
                Authorization: localStorage.getItem('token')
            }
        },).then((res) => {
            closeNotify();
            if (res.data && res.data.size > 0) {
                let fileNameFromSv = res.headers['attachmentfilename'];
                let blob = new Blob([res.data]);
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileNameFromSv ? fileNameFromSv : fileName;
                link.click();
            } else {
                showWarningNotify("File không khả dụng, vui lòng thử lại hoặc liên hệ quản trị viên");
            }
            resolve('true');
        }).catch(err => {
            showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
            resolve('false');
        })
    });
};

export const openNewTab = (url) => {
    showInfoNotify("Đang tải file, vui lòng chờ trong giây lát!")
    axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
        headers: {
            Authorization: localStorage.getItem('token')
        }
    }).then((res) => {
        closeNotify();
        if (res.data && res.data.size > 0) {
            window.open(URL.createObjectURL(res.data));
        } else {
            showWarningNotify("File không khả dụng, vui lòng thử lại hoặc liên hệ quản trị viên");
        }
    }).catch(err => {
        showErrorNotify(err.message ? err.message : "Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
    })
};

export const printContent = (url) => {
    return new Promise(function (resolve, reject) {
        showInfoNotify("Đang tải file, vui lòng chờ trong giây lát!")
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // important
            headers: {
                Authorization: localStorage.getItem('token')
            }
        }).then((res) => {
            closeNotify();
            const myBlob = res.data
            if (myBlob) {
                let objectURL = URL.createObjectURL(myBlob);
                document.querySelector('#pdf-frame').src = '';
                document.querySelector('#pdf-frame').src = objectURL;
                URL.revokeObjectURL(myBlob);
            }
        }).then(
            function () {
                window.setTimeout(function () {
                    document.querySelector('#pdf-frame').contentWindow.print();
                    resolve('true');
                }, 1000)
            });

    });
};
