import React from 'react';
import { List, Datagrid, Filter, TextField, TextInput, ReferenceField } from 'react-admin';

const ExemptionPolicyFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const ExemptionPolicyList = props => (
  <List filters={<ExemptionPolicyFilter />} {...props} exporter={false}  >
    <Datagrid rowClick="edit" >
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField source="schoolYearId" reference="schoolYears" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
</List>
);

export default ExemptionPolicyList
