import BlockIcon from '@material-ui/icons/ViewList';

import CongBoDiemList from './CongBoDiemList';
import CongBoDiemCreate from './CongBoDiemCreate';
import CongBoDiemEdit from './CongBoDiemEdit';

export default {
  list: CongBoDiemList,
  create: CongBoDiemCreate,
  edit: CongBoDiemEdit,
  icon: BlockIcon,
};
