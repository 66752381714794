import React, {useEffect, useState} from 'react';
import {LongTextInput, required, SaveButton, showNotification, TextInput, translate} from 'react-admin';
import {Button, Grid} from '@material-ui/core'
import {AuthStatusInput} from '../../components'
import TreeCheckBoxCustomInput from "../../components/TreeCheckBoxCustomInput";
import cachedApis from "../../api/cachedApis";
import {compose} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import {Redirect} from 'react-router-dom';
import AuthorizeIcon from '@material-ui/icons/Done';
import env from "../../env";

const styles = {
    button: {
        margin: 1,
    },
    cancelBtn: {
        marginLeft: 20
    },
    checkboxGroup: {
        "& > div ": {
            flexDirection: 'column',
        }
    },
    customCheckBox: {
        "& > div > label ": {
            width: '100%'
        }

    }
};
const companyChannels = [
    {id: 'APP', name: 'APP'},
];
const schoolChannels = [
    {id: 'APP', name: 'APP'},
];


const VinaIDMessageFields = ({className, translate, permissions, defaultValue, save, authorize, ...props}) => {
    const [values, setValues] = useState({
        isRedirect: false,
        refreshList: false,
    });
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        if (!defaultValue.isRedirect) {
            getCustomersWithUnits();
        } else {
            setValues({isRedirect: true, refreshList: true});
        }
    }, [defaultValue.isRedirect]);

    function getCustomersWithUnits() {
        cachedApis.getCustomerUnits(false).then(rs => {
            setCustomers(rs);
        })
    }

    function cancel() {
        setValues({isRedirect: true, refreshList: false});
    }

    if (values.isRedirect) {
        return <Redirect to={'/vinaIDMessages?refreshView=' + values.refreshList}/>;
    } else {
        return (
            <React.Fragment>
                <Grid item xs={12} sm={6}>
                    <TextInput style={{width: '90%'}} defaultValue={defaultValue.subject}
                               inputProps={{maxLength: 100}}
                               onChange={props.onChange('subject')}
                               label="resources.messages.fields.subject"
                               source="subject"/>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <LongTextInput
                        inputProps={{maxLength: 5000}}
                        label="resources.messages.fields.text"
                        source="text" validate={required()} rows={10}
                        defaultValue={defaultValue.text}
                        onChange={props.onChange('text')}/>
                </Grid>


                <Grid item xs={12} sm={6}>
                    <Grid item xs={12} sm={12}>
                        <TextInput style={{width: '90%'}} defaultValue={defaultValue.link}
                                   onChange={props.onChange('link')}
                                   inputProps={{maxLength: 255}}
                                   label="resources.messages.fields.link"
                                   source="link"/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextInput style={{width: '90%'}} defaultValue={defaultValue.linkDisplay}
                                   onChange={props.onChange('linkDisplay')}
                                   inputProps={{maxLength: 50}}
                                   label="resources.messages.fields.linkDisplay"
                                   source="linkDisplay"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AuthStatusInput source="authStatus" disabled
                                         label="resources.messages.fields.authStatus"
                                         defaultValue={defaultValue.authStatus}
                                         onChange={props.onChange('authStatus')}/>
                    </Grid>
                    {defaultValue.id ?
                        <Grid item xs={12} sm={6}>
                            {defaultValue.authStatus !== 'AUTHORIZED' &&
                            <form style={{width: '90%', marginBottom: 10}}>
                                <div style={{flexDirection: 'row'}}>
                                    <h4>Chọn tệp (tối đa 3)</h4>
                                    <input id="contained-button-file" type="file" multiple
                                           accept=".png, .jpg, .jpeg, .xlsx, .xls, .pdf, .docx, .doc"
                                           onChange={(e) => props.changeFile(e)}/>
                                </div>
                                {props?.files && props?.files.length > 0 &&
                                <ol>
                                    {[...props?.files].map((f, i) => (
                                        <li key={i}>{f.name}</li>
                                    ))}
                                </ol>
                                }

                            </form>
                            }
                            {defaultValue.fileUris && defaultValue.fileUris.length > 0 && defaultValue.fileUris.map((f, i) => (
                                <img
                                    src={`${env.baseURL}${f}`} alt="" style={{maxWidth: '250px'}}
                                />
                            ))}
                        </Grid>
                        :
                        <Grid item xs={12} sm={12}>
                            <form style={{width: '90%'}}>
                                <div style={{flexDirection: 'row'}}>
                                    <h4>Chọn file (Tối đa 3)</h4>
                                    <input id="contained-button-file" type="file" multiple
                                           accept=".png, .jpg, .jpeg, .xlsx, .xls, .pdf, .docx, .doc"
                                           onChange={(e) => props.changeFile(e)}/>
                                </div>
                                <ol>
                                    {[...props?.files].map((f, i) => (
                                        <li key={i}>{f.name}</li>
                                    ))}
                                </ol>
                            </form>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12} sm={6} style={{maxHeight: '450px', paddingRight: '16px'}}>
                    <label style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: '1rem'}}>
                        {translate('resources.messages.fields.customerIds')}
                    </label>
                    <div style={{maxHeight: '400px', overflow: 'auto'}}>
                        <TreeCheckBoxCustomInput data={customers}
                                                 selected={defaultValue.customerIds}
                                                 expandTopLevel={true}
                                                 onChange={props.onChange('cuzIds')}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} sm={12} style={{marginTop: 15}}>
                    {defaultValue.authStatus !== 'AUTHORIZED' &&
                    <>
                        <Button variant="contained"
                                color="primary"
                                style={styles.button}
                                disabled={defaultValue.saveDisabled || props.loading}
                                onClick={save}>
                            <SaveIcon/> Lưu
                        </Button>
                        {
                            permissions && permissions.includes('CHECKER') &&
                            <SaveButton
                                icon={<AuthorizeIcon/>}
                                label='ra.action.authorize'
                                style={{marginLeft: '16px'}}
                                onClick={authorize}
                                disabled={defaultValue.saveDisabled || props.loading}
                                {...props}
                            />
                        }</>
                    }

                    <Button variant="contained" style={styles.cancelBtn}
                            disabled={defaultValue.saveDisabled || props.loading}
                            onClick={cancel}>
                        <CancelIcon/> Hủy
                    </Button>
                </Grid>
            </React.Fragment>
        )
    }
}
export default compose(
    translate,
    connect(null, {showNotification}),
    withStyles(styles)
)(VinaIDMessageFields)
