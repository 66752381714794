import React from 'react';
import Grid from '@material-ui/core/Grid'
import {TextInput} from 'react-admin';

const AdmissionChangeLogFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="admissionId"  {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="stt"  {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="sbd"  {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="customerName" {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="actionBy" {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="actionTime" {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevPob"  {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="pob"  {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevAddress"  {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="address"  {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevNation"  {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="nation"  {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevReligion"  {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="religion"  {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevCustomerPhone"  {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="customerPhone"  {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevFatherName" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="fatherName" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevFatherPhone" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="fatherPhone" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevFatherJob" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="fatherJob" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevFatherTitle" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="fatherTitle" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevFatherCompany" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="fatherCompany" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevMotherName" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="motherName" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevMotherPhone" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="motherPhone" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevMotherJob" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="motherJob" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevMotherTitle" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="motherTitle" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevMotherCompany" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="motherCompany" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevGuardianName" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="guardianName" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevGuardianPhone" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="guardianPhone" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevGuardianJob" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="guardianJob" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevGuardianTitle" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="guardianTitle" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="prevGuardianCompany" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="guardianCompany" {...props} />
        </Grid>
    </React.Fragment>
)
export default AdmissionChangeLogFields
