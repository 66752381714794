import React, {useState} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {AutocompleteInput, DateInput, ReferenceInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm} from '../../components';
import env from '../../env';
import moment from "moment";

const ChamTheNhanSuatAn = (props) => {
    const [state, setState] = useState({
        fromDate: new Date(),
        kiotId: '',
    });

    const getReportUrl = () => {
        return `${env.kiotUrl}/reports/kiot/bcChamTheNhanSuatAn?fromDate=${moment(state.fromDate).format('YYYY-MM-DD')}&kiotId=${state.kiotId}`;
    };

    const excelReport = () => {
        downloadFile(getReportUrl(), 'bc_cham_the_nhan_suat_an.xlsx')
    };

    const validate = (values) => {
        const errors = {};
        setState(values);
        return errors;
    };

    return (
        <Card>
            <Title title="resources.bcChamTheNhanSuatAn.name"/>
            <CardContent>
                <ReportForm params={state}
                            validate={validate}
                            excelReport={excelReport}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="fromDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ReferenceInput source="kiotId"
                                            validate={required()}
                                            {...props}
                                            reference="kiotConfigs"
                                            allowEmpty
                                            sort={{field: 'name', order: 'ASC'}}
                                            label="resources.kiotConfigs.name"
                                            perPage={999}>
                                <AutocompleteInput optionText="name"/>
                            </ReferenceInput>
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default ChamTheNhanSuatAn

