import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import CardApplicationFields from "../CardApplication/CardApplicationFields";

export const CardApplicationNeedConfirmEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Grid container spacing={8}>
        <CardApplicationFields />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default CardApplicationNeedConfirmEdit
