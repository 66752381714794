import React, {Fragment} from 'react';
import {fetchEnd, fetchStart, refreshView, showNotification} from 'react-admin';
import {Grid} from '../components'
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import './popup.css';
import {change, submit} from "redux-form";
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {connect} from 'react-redux';
import LocalShipping from '@material-ui/icons/LocalShipping';
import {Button,} from '@material-ui/core';
import utils from "../utils/utils";

const styles = {
    suggestDialog: {
        zIndex: '10 !important',
    }
};
const KiotOrderDetailDialog = (props) => {
    const {showDialogDetail, orderDetail, handleCloseDialogDetail, deliveredOrder, classes, loading, tab} = props;

    const countProduct = () => {
        const details = orderDetail?.orderDetails
        let quantity = 0;
        if (details) {
            for (let i = 0; i < details.length; i++) {
                quantity += details[i].quantity
            }
        }
        return quantity;
    }
    const calculateOrderProductTotal = () => {
        const details = orderDetail?.orderDetails
        let total = 0;
        if (details) {
            for (let i = 0; i < details.length; i++) {
                const d = details[i]
                total += (d.quantity * d.price)
            }
        }
        return total
    }

    return (
        <Fragment>
            <Dialog
                className={classes.suggestDialog}
                fullWidth={true}
                disableBackdropClick={false}
                maxWidth='sm'
                open={showDialogDetail}
                onClose={handleCloseDialogDetail}
                aria-label="Chi tiết"
            >
                {orderDetail &&
                <>
                    <DialogTitle>Chi tiết đơn hàng </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={0}>
                            <span style={{fontWeight: '600'}}>{'Khách hàng'}</span>
                            <div style={{width: '100%', fontWeight: '700', marginTop: 8}}>
                                {orderDetail.customerName}
                            </div>
                            <div style={{width: '100%'}}>
                                     <span style={{width: '50%', display: 'inline-block'}}>
                                         Kênh bán:
                                    </span>
                                <span style={{
                                    width: '50%',
                                    display: 'inline-block',
                                    fontWeight: '600',
                                    textAlign: 'right'
                                }}>
                                         {orderDetail.SaleChannelName}
                                    </span>
                            </div>
                            <div style={{width: '100%'}}>
                                     <span style={{width: '50%', display: 'inline-block'}}>
                                         Mã đặt hàng:
                                    </span>
                                <span style={{
                                    width: '50%',
                                    display: 'inline-block',
                                    fontWeight: '700',
                                    textAlign: 'right'
                                }}>
                                         {orderDetail.code}
                                    </span>
                            </div>
                            <div style={{width: '100%', marginTop: 16}}>
                                <span style={{fontWeight: '600'}}>{'Chi tiết đơn hàng'}</span>
                            </div>
                            <div style={{width: '100%', marginBottom: 8, marginTop: 8}}>
                                <span style={{fontWeight: '700'}}>{'Tổng số lượng hàng (' + countProduct() + ')'}</span>
                            </div>
                            {orderDetail.orderDetails && orderDetail.orderDetails.map((bi, idx) => (
                                <div style={{width: '100%', marginLeft: 20, marginTop: 6}}>
                                    <span style={{width: '70%', display: 'inline-block'}}>
                                        {bi.productName + ' (' + bi.quantity + ')'}
                                    </span>
                                    <span style={{width: '30%', display: 'inline-block', textAlign: 'right'}}>
                                        {utils.formatNumber(bi.price)}
                                    </span>
                                    <span style={{color: 'gray'}}>{bi.productCode}</span>
                                </div>
                            ))
                            }
                            <div style={{width: '100%', marginTop: 16}}>
                                     <span style={{width: '50%', display: 'inline-block'}}>
                                         Tổng tiền hàng:
                                    </span>
                                <span style={{
                                    width: '50%',
                                    display: 'inline-block',
                                    textAlign: 'right'
                                }}>
                                         {utils.formatNumber(calculateOrderProductTotal())}
                                    </span>
                            </div>
                            <div style={{width: '100%'}}>
                                     <span style={{width: '50%', display: 'inline-block'}}>
                                         Giảm giá hoá đơn:
                                    </span>
                                <span style={{
                                    width: '50%',
                                    display: 'inline-block',
                                    textAlign: 'right'
                                }}>
                                         {utils.formatNumber(orderDetail.discount)}
                                    </span>
                            </div>
                            {orderDetail?.invoiceOrderSurcharges && orderDetail?.invoiceOrderSurcharges.length > 0 && orderDetail?.invoiceOrderSurcharges.map((item, index) => (
                                <div style={{width: '100%'}}>
                                    <span style={{width: '50%', display: 'inline-block'}}>
                                        {item.surchargeName}
                                   </span>
                                    <span style={{
                                        width: '50%',
                                        display: 'inline-block',
                                        textAlign: 'right'
                                    }}>
                                {utils.formatNumber(item.surValue)}
                                    </span>
                                </div>
                            ))
                            }
                            <div style={{width: '100%'}}>
                                     <span style={{width: '50%', display: 'inline-block'}}>
                                         Khách cần trả:
                                    </span>
                                <span style={{
                                    width: '50%',
                                    display: 'inline-block',
                                    textAlign: 'right'
                                }}>
                                         {utils.formatNumber(orderDetail.total)}
                                    </span>
                            </div>
                            <div style={{width: '100%'}}>
                                     <span style={{width: '50%', display: 'inline-block'}}>
                                         Khách thanh toán:
                                    </span>
                                <span style={{
                                    width: '50%',
                                    display: 'inline-block',
                                    fontWeight: '700',
                                    textAlign: 'right'
                                }}>
                                         {utils.formatNumber(orderDetail.totalPayment)}
                                    </span>
                            </div>
                            {orderDetail?.description &&
                            <div style={{width: '100%'}}>
                                {orderDetail?.description}
                            </div>
                            }

                        </Grid>
                    </DialogContent>
                </>
                }

                <DialogActions>
                    {tab === 1 ?
                        <>
                            {(!orderDetail?.description || orderDetail?.description?.startsWith('Hàng giao đến trường lúc')) &&
                            <>
                                {(orderDetail?.status === 1 || orderDetail?.status === 5) &&
                                <Button disabled={loading} color="primary" onClick={deliveredOrder} variant="outlined">
                                    <span style={{marginRight: 6}}>{'Nhận hàng'}</span>
                                    <LocalShipping/>
                                </Button>
                                }
                            </>

                            }
                        </>
                        :
                        <>
                            {(orderDetail?.status === 1 || orderDetail?.status === 5) &&
                            <Button disabled={loading} color="primary" onClick={deliveredOrder} variant="outlined">
                                <span style={{marginRight: 6}}>{'Giao hàng'}</span>
                                <LocalShipping/>
                            </Button>
                            }
                        </>
                    }

                    {(orderDetail?.status === 3) &&
                    <span style={{marginRight: 6, fontWeight: '600'}}>Đã giao hàng</span>
                    }
                    <Button disabled={loading} onClick={handleCloseDialogDetail} variant="outlined">
                        <span style={{marginRight: 6}}>Đóng</span>
                        <IconCancel/>
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    submit,
    refreshView,
    showNotification
};
export default compose(connect(null, mapDispatchToProps),
    withStyles(styles))(
    KiotOrderDetailDialog
);
