import React from 'react';
import { Create, SimpleForm } from 'react-admin';

import {CustomToolbar, Grid, schoolYears, TreeCheckboxInput} from '../../components'
import { ReferenceInput, ReferenceArrayInput, AutocompleteArrayInput, required } from 'react-admin';

export const UnitProductCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="list" >
      <Grid container spacing={8}>
        <React.Fragment>
          <Grid item xs={12} sm={6} >
            <label>Danh sách lớp *</label>
            <ReferenceInput source="unitIds"
                            reference="units"
                            perPage={999}
                            filter={{clazzWithParents: true, schoolYear: schoolYears[0].id}}
                            {...props} >
              <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={6} >
            <label>Danh sách phí *</label>
            <ReferenceInput source="productIds"
                            reference="billProducts"
                            perPage={999}
                            validate={required()}
                            {...props} >
              <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
            </ReferenceInput>
          </Grid>

        </React.Fragment>
      </Grid>
    </SimpleForm>
  </Create>
);

export default UnitProductCreate
