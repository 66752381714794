import CustomerProductIcon from '@material-ui/icons/ViewList';

import CustomerProductList from './CustomerProductList';
import CustomerProductCreate from './CustomerProductCreate';
import CustomerProductEdit from './CustomerProductEdit';

export default {
  list: CustomerProductList,
  create: CustomerProductCreate,
  edit: CustomerProductEdit,
  icon: CustomerProductIcon,
};
