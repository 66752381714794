import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {showNotification} from 'react-admin';
import cloneDeep from 'lodash/cloneDeep';
import {SimpleForm, BooleanInput} from 'react-admin';
import {
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField
} from '@material-ui/core'

import RoleCustomToolbar from "./RoleCustomToolbar";
import axiosInstance from "../../api/axios";

const WAIT_INTERVAL = 1000;

export class RoleCreate extends React.Component {

    state = {
        roleId: '',
        roleName: '',
        description: '',
        subMenus: [],
        selectedSubMenus: [],
        enabled: true,
        isRedirect: false
    }

    componentWillMount() {
        const path = this.props.location.pathname.split('/');
        if (path.length > 0 && !isNaN(path[path.length - 1])) {
            this.setState({
                roleId: path[path.length - 1]
            })
        }
        this.timer = null;
    }

    componentDidMount() {
        this.loadSubMenus();
        if (this.state.roleId) {
            this.loadRoleDetails();
        }
    }

    loadRoleDetails = () => {
        const url = `/api/roles/` + this.state.roleId;
        axiosInstance.get(url).then(rs => {
            this.setState({
                roleName: rs.data.data.roleName,
                description: rs.data.data.description,
                enabled: rs.data.data.enabled,
                selectedSubMenus: rs.data.data.subMenuPrivileges
            })
        });
    }

    loadSubMenus = () => {
        const url = `/api/subMenus?page=0&size=999&sort=description`;
        axiosInstance.get(url).then(rs => {
            this.setState({
                subMenus: rs.data.content
            })
        });
    }

    save = () => {
        const {roleName, description, selectedSubMenus} = this.state;
        if (!roleName) {
            this.props.showNotification('Tên vai trò không được trống', 'warning');
            return;
        }
        let subMenuWithPrivileges = [];
        selectedSubMenus.forEach(i => {
            if (i.privileges && i.privileges.length > 0) {
                subMenuWithPrivileges.push({
                    subMenuId: i.subMenuId,
                    privileges: i.privileges
                })
            }
        })
        const data = {
            roleName: roleName,
            description: description,
            subMenuPrivileges: subMenuWithPrivileges
        }
        if (this.state.roleId) {//Call update api
            const url = `/api/roles/update/` + this.state.roleId;
            return axiosInstance.put(url, data).then(rs => {
                if (rs.data.success) {
                    this.cancel();
                }
            }, err => {
                this.props.showNotification(err.message, 'warning');
            });
        } else {//Call create api
            const url = `/api/roles`;
            return axiosInstance.post(url, data).then(rs => {
                if (rs.data.success) {
                    this.cancel();
                }
            }, err => {
                this.props.showNotification(err.message, 'warning');
            });
        }
    };

    cancel = (e) => {
        this.setState({
            isRedirect: true
        })
    };

    addOrRemovePrivilege = (subMenu, privilege) => {
        const selectedSubMenus = this.state.selectedSubMenus;
        let filteredSubMenu = selectedSubMenus.filter(i => {
            return (i.subMenuId === subMenu.id);
        })
        if (!filteredSubMenu[0].privileges) {
            filteredSubMenu[0].privileges = [];
        }
        let filteredData = filteredSubMenu[0].privileges.filter(i => {
            return i === privilege;
        })
        if (filteredData.length > 0) {//remove this privilege
            filteredSubMenu[0].privileges = filteredSubMenu[0].privileges.filter(i => {
                return i !== privilege;
            })
        } else {//add this privilege
            filteredSubMenu[0].privileges.push(privilege);
        }
        this.setState({
            selectedSubMenus: selectedSubMenus
        })
    }

    addOrRemoveAllPrivilege = (privilege) => {
        const {selectedSubMenus, subMenus} = this.state;
        const rolesMenu = subMenus.filter(x => {
            return x.name === 'roles'
        })[0];
        let countPrivilege = 0;
        let selectedSubMenusWithPrivilegeRemoved = cloneDeep(selectedSubMenus);
        selectedSubMenus.forEach((i, idx) => {
            if (!i.privileges) {
                i.privileges = [];
                selectedSubMenusWithPrivilegeRemoved[idx].privileges = [];
            }
            if (i.subMenuId !== rolesMenu.id) {
                selectedSubMenusWithPrivilegeRemoved[idx].privileges = selectedSubMenusWithPrivilegeRemoved[idx].privileges.filter(x => {
                    return x !== privilege;
                })
            }
            if (i.privileges.includes(privilege)) {
                countPrivilege++;
                return;
            }
            i.privileges.push(privilege);
        })
        if (countPrivilege !== selectedSubMenus.length) {//add privilege to all submenu
            this.setState({
                selectedSubMenus: selectedSubMenus
            })
        } else {
            this.setState({
                selectedSubMenus: selectedSubMenusWithPrivilegeRemoved
            })
        }
    }

    addOrRemoveSubMenu = (subMenu) => {
        let filteredData = this.state.selectedSubMenus.filter(i => {
            return (i.subMenuId === subMenu.id);
        })
        if (filteredData.length === 0) {//add menu
            this.setState(prevState => ({
                selectedSubMenus: [...prevState.selectedSubMenus, {subMenuId: subMenu.id}]
            }))
        } else {//remove privilege
            this.setState({
                selectedSubMenus: this.state.selectedSubMenus.filter(i => {
                    return (i.subMenuId !== subMenu.id);
                })
            });
        }
    }

    addOrRemoveAllSubMenus = () => {
        const {subMenus} = this.state;
        let {selectedSubMenus} = this.state;
        if (selectedSubMenus.length !== subMenus.length) {//add all but keep roles
            let mapSelectedSubMenu = selectedSubMenus.reduce((obj, item) => {
                obj[item.subMenuId] = item
                return obj;
            }, {});
            subMenus.forEach(i => {
                if (!mapSelectedSubMenu[i.id]) {
                    selectedSubMenus.push({subMenuId: i.id});
                }
            })
            this.setState({
                selectedSubMenus: selectedSubMenus
            })
        } else {//remove all but keep roles
            const rolesMenu = subMenus.filter(x => {
                return x.name === 'roles'
            })[0];
            this.setState({
                selectedSubMenus: selectedSubMenus.filter(x => {
                    return x.subMenuId === rolesMenu.id
                })
            })
        }
    }

    isSubMenuSelected = (subMenu) => {
        let filteredData = this.state.selectedSubMenus.filter(i => {
            return (i.subMenuId === subMenu.id);
        })
        return filteredData.length > 0;
    }

    isPrivilegeSelected = (subMenu, privilege) => {
        let filteredData = this.state.selectedSubMenus.filter(i => {
            return i.subMenuId === subMenu.id;
        })
        if (filteredData.length === 0 || !filteredData[0].privileges
            || filteredData[0].privileges.length === 0) {
            return false;
        }
        return filteredData[0].privileges.includes(privilege);
    }

    changeRoleName = (val) => {
        this.setState({roleName: val});
    }

    changeDescription = (val) => {
        this.setState({description: val});
    }

    render() {
        if (this.state.isRedirect) {
            return <Redirect to='/roles'/>;
        }
        const {subMenus, roleName, description, selectedSubMenus, roleId} = this.state;
        return (
            <React.Fragment>
                <Card>
                    <CardContent>
                        <SimpleForm toolbar={<RoleCustomToolbar save={this.save} cancel={this.cancel}/>}
                                    redirect="list">


                            <Grid container spacing={8}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="standard-required"
                                        label="Tên"
                                        margin="normal"
                                        value={roleName}
                                        onChange={(e) => this.changeRoleName(e.target.value)}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="standard-required"
                                        label="Mô tả"
                                        margin="normal"
                                        value={description}
                                        onChange={(e) => this.changeDescription(e.target.value)}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <BooleanInput onChange={(e) => this.setState({enabled: e.target.value})}
                                                  label="Kích hoạt" defaultValue={this.state.enabled}/>
                                </Grid>
                                <div style={{maxHeight: '600px', width: '100%', overflowX:'hidden', overflowY: 'scroll'}}>
                                    <Grid item xs={12} sm={12}>
                                        <hr style={{
                                            width: '100%',
                                            marginTop: '10px',
                                            marginBottom: '0',
                                            color: 'lightblue'
                                        }}/>
                                        <div style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: 12}}>Phân quyền theo chức
                                            năng
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        {subMenus && subMenus.length > 0 &&
                                        <FormGroup row style={{height: 30}}>
                                            <FormControlLabel style={{width: 400}}
                                                              control={
                                                                  <Checkbox
                                                                      onClick={() => this.addOrRemoveAllSubMenus()}
                                                                      checked={subMenus.length === selectedSubMenus.length}
                                                                  />
                                                              } label="Chọn/Huỷ tất cả"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox
                                                    disabled={subMenus.length !== selectedSubMenus.length}
                                                    onClick={() => this.addOrRemoveAllPrivilege('GET')}
                                                    checked={selectedSubMenus.filter(i => i.privileges && i.privileges.includes('GET')).length === subMenus.length}
                                                />} label="Xem"/>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    disabled={subMenus.length !== selectedSubMenus.length}
                                                    onClick={() => this.addOrRemoveAllPrivilege('POST')}
                                                    checked={selectedSubMenus.filter(i => i.privileges && i.privileges.includes('POST')).length === subMenus.length}
                                                />} label="Tạo"/>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    disabled={subMenus.length !== selectedSubMenus.length}
                                                    onClick={() => this.addOrRemoveAllPrivilege('PUT')}
                                                    checked={selectedSubMenus.filter(i => i.privileges && i.privileges.includes('PUT')).length === subMenus.length}
                                                />} label="Sửa"/>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    disabled={subMenus.length !== selectedSubMenus.length}
                                                    onClick={() => this.addOrRemoveAllPrivilege('DELETE')}
                                                    checked={selectedSubMenus.filter(i => i.privileges && i.privileges.includes('DELETE')).length === subMenus.length}
                                                />} label="Xoá"/>
                                        </FormGroup>
                                        }
                                        {subMenus && subMenus.map(s => (
                                            <FormGroup row key={s.id} style={{height: 30}}>
                                                <FormControlLabel style={{width: 400}}
                                                                  control={
                                                                      <Checkbox
                                                                          disabled={s.name === 'roles' && roleId}
                                                                          onClick={() => this.addOrRemoveSubMenu(s)}
                                                                          checked={this.isSubMenuSelected(s)}
                                                                          value={s.id}/>
                                                                  }
                                                                  label={s.description}
                                                />
                                                <FormControlLabel control={<Checkbox
                                                    disabled={!this.isSubMenuSelected(s) || (s.name === 'roles' && roleId)}
                                                    onClick={() => this.addOrRemovePrivilege(s,
                                                        'GET')}
                                                    checked={this.isPrivilegeSelected(s, 'GET')}
                                                />} label="Xem"/>
                                                <FormControlLabel control={<Checkbox
                                                    disabled={!this.isSubMenuSelected(s) || (s.name === 'roles' && roleId)}
                                                    onClick={() => this.addOrRemovePrivilege(s, 'POST')}
                                                    checked={this.isPrivilegeSelected(s, 'POST')}
                                                />} label="Tạo"/>
                                                <FormControlLabel control={<Checkbox
                                                    disabled={!this.isSubMenuSelected(s) || (s.name === 'roles' && roleId)}
                                                    onClick={() => this.addOrRemovePrivilege(s,
                                                        'PUT')}
                                                    checked={this.isPrivilegeSelected(s, 'PUT')}
                                                />} label="Sửa"/>
                                                <FormControlLabel control={<Checkbox
                                                    disabled={!this.isSubMenuSelected(s) || (s.name === 'roles' && roleId)}
                                                    onClick={() => this.addOrRemovePrivilege(s,
                                                        'DELETE')}
                                                    checked={this.isPrivilegeSelected(s, 'DELETE')}
                                                />} label="Xóa"/>
                                            </FormGroup>
                                        ))}
                                    </Grid>
                                </div>
                            </Grid>
                        </SimpleForm>
                    </CardContent>
                </Card>
            </React.Fragment>
        )
    }
}

export default compose(
    connect(undefined, {showNotification}),
)(RoleCreate);
