import React, {useState} from "react";
import {Button, required, SaveButton, showNotification, TextInput} from "react-admin";
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import axiosInstance from "../../api/axios";
import compose from 'recompose/compose';
import {connect} from 'react-redux';
import {showErrorNotify} from "../../components";

const FileInput = props => {
    const onChange = (event) => {
        const file = event.target.files[0]
        props.onChange('file', file)
    }
    return <div>
        <input
            type="file"
            name="myImage"
            accept="image/jpeg, image/png, application/pdf"
            onChange={onChange}
        />
    </div>
}

const EmployeeUploadFileDialog = ({classes, customerId, onAdd, ...props}) => {
    const [open, setOpen] = useState(false)
    const [saving, setSaving] = useState(false)
    const [form, setForm] = useState({})

    const onSave = async () => {
        const {file, description} = form

        if (!file || !description) {
            showErrorNotify('Vui lòng nhập dữ liệu')
            return
        }

        setSaving(true)
        const formData = new FormData();
        formData.append('file', file)
        formData.append('description', description)

        try {
            const res = await axiosInstance.post(`/api/customer-certificate/${customerId}`, formData)
            onAdd({
                description: form.description,
                ggFileId: res.data,
                link: `https://drive.google.com/file/d/${res.data}`
            })
        } catch (e) {
            props.showNotification(e.message, 'error')
        }
        setSaving(false)
        setOpen(false)
    }

    const onChange = (field, value) => {
        setForm(prev => ({...prev, [field]: value}))

        if (field === 'file' && !value) {
            setSaving(false)
        }
    }

    const onClose = () => {
        setOpen(false)
    }

    return (
        <React.Fragment>
            <Button onClick={() => setOpen(true)} label='Upload'>
                <AddIcon/>
            </Button>
            <Dialog
                fullWidth={true}
                disableBackdropClick={true}
                maxWidth='sm'
                open={open}
                onClose={onClose}
            >
                <DialogTitle>Title</DialogTitle>
                <DialogContent>
                    <Grid item xs={12} sm={6}>
                        <TextInput source='Mô tả' validate={required()} {...props}
                                   onChange={e => onChange('description', e.target.value)}
                                   InputLabelProps={{shrink: true}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FileInput onChange={onChange}/>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SaveButton saving={saving} disabled={saving} onClick={onSave}/>
                    <Button label="ra.action.cancel" onClick={onClose}>
                        <CancelIcon/>
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default compose(
    connect(undefined, {showNotification}),
)(EmployeeUploadFileDialog)
