import React from 'react';
import {
  ChipField,
  Datagrid,
  Filter,
  List,
  SingleFieldList,
  TextField,
  TextInput,
  ReferenceArrayField
} from 'react-admin';

const EventProductFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const EventProductList = props => (
  <List filters={<EventProductFilter />} {...props}  exporter={false} >
    <Datagrid rowClick="edit" >
      <TextField source="eventName"  sortBy="event_id" />
      <ReferenceArrayField reference="billProducts" source="productIds">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
</List>
);

export default EventProductList
