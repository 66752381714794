import React, {useState} from 'react';
import {required, SaveButton, SimpleForm, TextInput, Toolbar} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import jwt_decode from "jwt-decode";
import LinkingAppToolbar, {LinkingApp} from "./components/LinkingAppToolbar";
import env from "./env";
import {get} from "axios";

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import CloseIcon from '@material-ui/icons/Close';
import ChatBubble from '@material-ui/icons/ChatBubble';
import QuizzToolbar from "./components/QuizToolbar";
import {showErrorNotify} from "./components";

const APPS = [
    {
        name: 'Sổ liên lạc điện tử vnedu',
        logo: '/images/vnedu.png',
        href: 'https://vnedu.vn'
    },
    {
        name: 'Học bạ số',
        href: 'https://hocbaso.hcmtelecom.vn/',
        logo: '/images/report-card-vnedu.png'
    },
    {
        name: 'Dạy và học trực tuyến (LMS/LCMS)',
        href: 'https://lms.vnedu.vn',
        logo: '/images/lms.png'
    },
    {
        name: 'Thư viện số',
        logo: '/images/lib-vnedu.png',
        href: 'https://thuvienso.digilib.vn '
    },
    {
        name: 'Trường học số Google',
        logo: 'images/google.png',
        href: 'https://edu.google.com/'
    },
    {
        name: 'Nhà sách Phương Nam',
        logo: 'images/phuong-nam-store.png',
        href: 'https://nhasachphuongnam.com/'
    },
    {
        name: 'Samsung Việt Nam',
        logo: 'images/samsung.png',
        href: 'https://www.samsung.com/vn/'
    },
    {
        name: 'BIDV',
        logo: 'images/bidv.png',
        href: 'https://smartbanking.bidv.com.vn/dang-nhap'
    },
    {
        name: 'Vietinbank',
        logo: 'images/vietinbank.png',
        href: 'https://ipay.vietinbank.vn/login'
    },
    {
        name: 'OCB',
        logo: 'images/ocb.png',
        href: 'https://omni.ocb.com.vn/frontend-web/app/auth.html#/login'
    },
    {
        name: 'Momo',
        logo: 'images/momo.png',
        href: 'https://www.momo.vn/'
    },
]

const styles = ({
    logo: {
        background: 'url(\'https://api.vinaid.vn/files/logo.png\')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        margin: '5% 30%',
        height: 128,
        justifyContent: 'center',
        display: 'flex',
    },
    wrapper: {
        overflow: 'auto',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        backgroundImage: `url('https://api.vinaid.vn/files/bg.jpg')`,
        backgroundSize: 'cover'
    },
    formWrapper: {
        minWidth: 350,
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12'
    },
    partnerWrapper: {
        width: 720
    },
    circleBox: {},
});


const ACTIONS = [
    {
        name: 'Telephone',
        label: 'Điện thoại',
        icon: <img src={'images/phone-receiver-icon.svg'} style={{width: 28, height: 'auto'}}/>,
        href: 'tel:+841900272728',
        backgroundColor: '#fd5a00'
    },
    {
        name: 'Zalo',
        label: 'Zalo',
        icon: <img src={'images/zalo.png'} style={{width: 28, height: 'auto'}}/>,
        href: 'https://zalo.me/231667867670292511',
        backgroundColor: '#1fb1ff'
    },
    {
        name: 'Messenger',
        label: 'Tin nhắn',
        icon: <img src={'images/messenger-icon.svg'} style={{width: 28, height: 'auto'}}/>,
        href: 'https://m.me/111486140422498',
        backgroundColor: '#1f84ff'
    },
]

const SpeedDialTooltipOpen = () => {
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(current => !current)
    }
    return <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        icon={<SpeedDialIcon icon={<ChatBubble/>} openIcon={<CloseIcon/>}/>}
        onClick={handleClick}
        open={open}
        style={{position: 'absolute', right: 35, bottom: 65}}
        ButtonProps={{style: {backgroundColor: '#a11d23', marginLeft: 8}}}
    >
        {ACTIONS.map(action => (
            <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.label}
                tooltipOpen
                ButtonProps={{style: {backgroundColor: action.backgroundColor, width: 56, height: 56}}}
                onClick={() => window.open(action.href)}
            />
        ))}
    </SpeedDial>

}

const CustomLoginPage = props => {
    const [saving, setSaving] = useState(false)
    const onLogin = async (payload) => {
        setSaving(true)
        const {username, password} = payload;
        const request = new Request(`${env.baseURL}/login`, {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: new Headers({'Content-Type': 'application/json'}),
        })

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            }).then(json => {
                let token = json.token;
                const decoded = jwt_decode(token);
                localStorage.setItem('token', token);
                localStorage.setItem('tenant', decoded.tenant);
                localStorage.setItem('tenantId', decoded.tenantId);
                localStorage.setItem('tid', decoded.tid);
                const tenantType = decoded.tenantType;
                localStorage.setItem('tenantType', tenantType);

                const showInfo = sessionStorage.getItem('showInfo');
                if (tenantType === 'SCHOOL') {
                    const url = `${env.baseURL}/api/schoolYears?sort=enable,desc,name,desc`;
                    const config = {
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': token
                        }
                    };
                    get(url, config).then(rs => {
                        if (rs.data) {
                            localStorage.setItem('schoolYears', JSON.stringify(rs.data.content))
                            localStorage.setItem('schoolYear', rs.data.content[0]);
                        }
                        if (showInfo === '1') {
                            sessionStorage.removeItem('showInfo')
                            window.location.href = sessionStorage.getItem('showInfoUrl')
                        } else {
                            window.location.href = window.location.href.split("/")[0]
                        }
                    }, err => {
                        setSaving(false)
                    });
                } else {
                    if (showInfo === '1') {
                        sessionStorage.removeItem('showInfo')
                        window.location.href = sessionStorage.getItem('showInfoUrl')
                    } else {
                        window.location.href = window.location.href.split("/")[0]
                    }
                }
            })
            .catch(err => {
                showErrorNotify('Thông tin đăng nhập không đúng hoặc không tồn tại, vui lòng đăng nhập lại')
                setSaving(false)
            })
    }

    return <div style={styles.wrapper}>

        <div style={{position: 'absolute', top: 0, right: 16, display: "flex", justifyContent: 'flex-end'}}>
            <LinkingAppToolbar apps={APPS}/>
            <QuizzToolbar/>
        </div>

        <SpeedDialTooltipOpen/>

        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 48}}>
            {/*{!--FORM LOGIN --}*/}
            <div style={styles.formWrapper}>
                <div style={styles.logo}/>
                <SimpleForm save={onLogin}
                            saving={saving}
                            toolbar={<Toolbar style={{backgroundColor: ' white', margin: 0}}>
                                <SaveButton fullWidth label='Đăng nhập' icon={<></>} disabled={saving}/>
                            </Toolbar>}>
                    <TextInput source="username" validate={required()} label='Tên đăng nhập' autoFocus fullWidth/>
                    <TextInput source="password" type='password' validate={required()} label='Mật khẩu' fullWidth/>
                </SimpleForm>
            </div>

            {/*{!--PARTNER HERE --}*/}
            <div style={styles.partnerWrapper}>
                <div style={{
                    border: '1px solid whitesmoke',
                    marginTop: 32,
                    borderRadius: 100,
                    padding: '8px 0',
                    textAlign: 'center',
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                }}>
                    Hệ sinh thái VinaID
                </div>

                <div style={{display: 'flex', flexWrap: 'wrap', marginTop: 32, gap: '16px 0'}}>
                    {APPS.map((app, index) => <LinkingApp key={index} {...app}/>)}
                </div>

            </div>
        </div>


    </div>

}
export default withStyles(styles)(CustomLoginPage);
