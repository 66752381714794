import React from 'react';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';

import {refreshView, showNotification, SimpleForm, translate} from 'react-admin';

import {Grid, schoolYears} from '../../components'
import {connect} from 'react-redux';
import axiosInstance from "../../api/axios";
import DisciplinePlanFields from "./DisciplinePlanFields";
import moment from "moment";

const styles = {
    item: {
        "& > table ": {
            width: '95%',
        },
        "& > table td:nth-child(2)": {
            textAlign: 'right',
        },
        "& > table thead": {    // hide the header
            display: 'none',
        },
    },
    select: {
        marginTop: '20px',
    },
    button: {
        // marginTop: '-20px',
        marginLeft: '10px'
    },
}

let timeout = null;
export const DisciplinePlanEdit = ({
                                       translate,
                                       classes,
                                       showToolbar = true,
                                       redirect = "list",
                                       noCancelButton,
                                       event,
                                       ...props
                                   }) => {

    const [values, setValues] = React.useState({
        id: null,
        name: '',
        active: true,
        schoolYearId: schoolYears[0].id,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: null,
        basePoint: 0,
        unitIds: [],
        daysOfWeek: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'],
        isRedirect: false,
        saveDisabled: false,
        isFistTimeCheck: true
    });

    React.useEffect(() => {
        if (props.id && values.isFistTimeCheck) {
            getEventDetails();
        }
    }, [values.isFistTimeCheck]);

    function getEventDetails() {
        const url = `/api/disciplinePlans/` + props.id;
        axiosInstance.get(url).then(rs => {
            const {data} = rs;
            const merged = Object.assign(values, data);
            setValues({...values, merged});
            merged.isFistTimeCheck = false;
            merged.tmpUnitIds = data.unitIds;
            setValues(merged);
        });
    }

    const handleChange = prop => e => {
        if (!e) {
            return
        }
        let value;
        if (['recurringType', 'tmpExcludeUnitIds', 'tmpUnitIds', 'cuzIds'].includes(prop)) {
            value = e;
        } else {
            value = e.target.value ? e.target.value : e.target.checked;
        }
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            setValues({...values, [prop]: value});
        }, 500);
    };

    function save() {
        if (!validate()) {
            return;
        }
        const data = Object.assign({}, values);
        data.unitIds = values.tmpUnitIds;
        setValues({...values, isRedirect: true});
        const url = `/api/disciplinePlans/` + props.id;
        axiosInstance.put(url, data).then(rs => {
            props.showNotification('Sửa kế hoạch thi đua thành công');
        }, err => {
            props.showNotification(err.message, 'warning');
        })
    }

    function onDirectChange(prop, val) {
        setValues({...values, [prop]: val});
    }

    function validate() {
        if (values.startDate > values.endDate) {
            props.showNotification('Ngày bắt đầu phải nhỏ hơn ngày kết thúc',
                'warning');
            return false;
        }
        if (!values.name) {
            props.showNotification('Bắt buộc nhập tên event', 'warning');
            return false;
        }
        if (!values.startDate) {
            props.showNotification('Bắt buộc nhập ngày bắt đầu', 'warning');
            return false;
        }
        if (!values.endDate) {
            props.showNotification('Bắt buộc nhập ngày kết thúc', 'warning');
            return false;
        }
        return true;
    }

    return (
        <SimpleForm
            toolbar={null}
            record={values}
        >
            <Grid container spacing={8} alignItems="flex-start" classes={classes}>
                <DisciplinePlanFields onChange={handleChange}
                                      defaultValue={values}
                                      save={save}
                                      onDirectChange={onDirectChange}
                />
            </Grid>
        </SimpleForm>
    )
};

export default compose(
    connect(undefined, {refreshView, showNotification}),
    translate,
    withStyles(styles)
)(DisciplinePlanEdit)
