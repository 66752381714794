import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const cardApplicationTypes = [
    {id: 'EMPLOYEE', name: 'cardApplicationType.EMPLOYEE'}
]

export const CardApplicationTypeCompanyField = (props) => (
    <SelectField source={props.source} choices={cardApplicationTypes} {...props} />
)

export const CardApplicationCompanyTypeInput = (props) => (
    (
        <>
            <SelectInput source={props.source} choices={cardApplicationTypes} {...props} />
            <div>
                <p>Lô nhân viên: Số điện thoại sẽ gán cho chủ thẻ</p>
            </div>
        </>
    )

)
