import React, {useRef, useState} from 'react';
import {ReferenceInput, showNotification, SimpleForm, Title, Toolbar, translate} from "react-admin";
import {Card, CardContent, Grid} from '@material-ui/core'
import {downloadFile, schoolYears, TreeCheckboxInput} from "../../components";
import Button from '@material-ui/core/Button';
import {connect} from "react-redux";
import {compose} from "redux";
import env from "../../env";


const buttonStyle = {
    margin: '0 8px',
}

const CustomToolBar = ({save, disabled,...restProps}) => {
    return <Toolbar {...restProps}>
        <Button color="secondary" disabled={disabled} variant="raised" style={buttonStyle} onClick={save()}>
            Tất cả
        </Button>
        <Button color="secondary" disabled={disabled} variant="raised" style={buttonStyle} onClick={save('ACTIVE')}>
            Thẻ kích hoạt
        </Button>
        <Button color="secondary" disabled={disabled} variant="raised" style={buttonStyle} onClick={save('DEACTIVE')}>
            Thẻ bị khoá
        </Button>
    </Toolbar>
}

const UpdateCardTemplate = (props) => {
    const [disabled, setDisabled] = useState(true)

    const ref = useRef()

    const validate = (values) => {
        ref.current = values
        const {unitIds = []} = values
        setDisabled(unitIds.length === 0)
    }

    const save = (cardStatus = '') => () => {
        const params = (new URLSearchParams({...ref.current, cardStatus})).toString()

        let url = `${env.baseURL}/api/cards/update-template?${params}`;
        downloadFile(url,'mau_file_mo_khoa_the.xlsx')
    }

    const tenantType = localStorage.getItem("tenantType")
    return (
        <React.Fragment>
            <Card>
                <Title title="resources.update-card-template.name"/>
                <CardContent>
                    <SimpleForm validate={validate} toolbar={<CustomToolBar {...props} disabled={disabled} save={save}/>}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <label>Danh sách lớp *</label>
                                <div style={{maxHeight: '450px', overflow: 'auto'}}>
                                    <ReferenceInput source="unitIds"
                                                    reference="units"
                                                    perPage={999}
                                                    filter={{
                                                        clazzWithParents: tenantType === 'SCHOOL',
                                                        departmentWithParents: tenantType !== 'SCHOOL',
                                                        schoolYear: schoolYears?.length > 0 ? schoolYears[0]?.id : ''
                                                    }}>
                                        <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                                    </ReferenceInput>
                                </div>
                            </Grid>
                        </Grid>
                    </SimpleForm>

                </CardContent>
            </Card>
        </React.Fragment>
    );
};

export default compose(
    translate,
    connect(undefined, {showNotification}),
)(UpdateCardTemplate);
