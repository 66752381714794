import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {Card, CardContent, CircularProgress, Grid, TextField} from '@material-ui/core'
import {SimpleForm, Title} from 'react-admin';
import RoleCustomToolbar from "../Role/RoleCustomToolbar";
import axiosInstance from "../../api/axios";
import {FileUploadComponent, schoolYears, showInfoNotify, showSuccessNotify, showWarningNotify} from "../../components";
import {downloadFilePost} from "../../components/FileDownload";
import env from "../../env/env";

export class CustomerCertificateBatchCreate extends React.Component {

    state = {
        description: '',
        isRedirect: false,
        file: null
    }

    constructor(props) {
        super(props);
        this.state = {
            description: '',
            schoolYearId: schoolYears[0].id,
            isLoading: false,
            showUploadFile: true,
        };
        this.onFileUpload = this.onFileUpload.bind(this);
    }

    changeDescription = (val) => {
        this.setState({description: val});
    }

    changeFile = (e) => {
        this.setState({
            file: e.target.files[0]
        })
    }

    onFileUpload(e) {
        e.preventDefault(); // Stop form submit
        if (!this.validate()) {
            return;
        }
        if (this.state.file == null) {
            return;
        }

        this.setState({
            isLoading: true
        });
        showInfoNotify("Đang xử lí file, vui lòng chờ trong giây lát!");
        const {description} = this.state;

        const batchData = {
            description
        }
        const url = `/api/customerCertificateBatches`;
        return axiosInstance.post(url, batchData).then(rs => {
            const batchId = rs.data.id
            this.setState({
                batchId: batchId
            })
            const formData = new FormData();
            formData.append('file', this.state.file);
            formData.append('id', batchId);
            downloadFilePost(env.baseURL + '/api/customerCertificateBatchUpload/validate-item', 'ket_qua.xlsx', formData).then(() => {
                showSuccessNotify("Đã xử lý thành công, vui lòng kiểm tra file kết quá");
                this.setState({
                    isLoading: false,
                    isRedirect: true
                });
            })

        })
    }

    save = () => {
        if (!this.validate()) {
            return;
        }
        const {description} = this.state;

        const batchData = {
            description
        }
        const url = `/api/customerCertificateBatches`;
        return axiosInstance.post(url, batchData).then(rs => {
            this.cancel();
        }, err => {
            showWarningNotify(err.message);
        });
    };

    cancel = (e) => {
        this.setState({
            isRedirect: true
        })
    };
    validate = () => {
        const {description} = this.state;
        if (!description) {
            showWarningNotify('Vui lòng nhâp mô tả');
            return false;
        }
        return true;
    }

    render() {
        if (this.state.isRedirect) {
            return <Redirect to='/customerCertificateBatches'/>;
        }
        const {description} = this.state;
        return (
            <React.Fragment>
                <Card>
                    <Title title="Tạo lô chứng chỉ"/>
                    <CardContent>
                        <SimpleForm toolbar={<RoleCustomToolbar save={this.save} cancel={this.cancel}/>}
                                    redirect="list">
                            <Grid container spacing={8}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="standard-required"
                                        label="Mô tả"
                                        margin="normal"
                                        required={true}
                                        value={description}
                                        onChange={(e) => this.changeDescription(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <div style={{width: '100%'}}>
                                Lưu ý:
                                <p>
                                    Sử dụng cấu trúc thư mục theo "Mẫu lô thư mục chứng chỉ"
                                </p>
                                <p>
                                    Các file chứng chỉ vui lòng đặt tên không dấu, chỉ chấp nhận các file có định dạng
                                    pdf, png, jpg, jpeg
                                </p>
                            </div>
                            <Grid container spacing={8}>
                                <FileUploadComponent
                                    onFileChanged={(e) => this.setState({file: e.target.files[0]})}
                                    onFileUpload={this.onFileUpload}
                                />
                                {
                                    this.state.isLoading && <CircularProgress color="secondary"/>
                                }
                            </Grid>
                        </SimpleForm>
                    </CardContent>
                </Card>
            </React.Fragment>
        )
    }
}

export default connect(null, null)(CustomerCertificateBatchCreate);
