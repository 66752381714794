import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import SuperAdminUserFields from './SuperAdminUserFields'

export const SuperAdminUserEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Grid container spacing={8}>
        <SuperAdminUserFields />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default SuperAdminUserEdit
