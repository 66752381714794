import React from 'react';
import {Datagrid, Filter, List, TextField, TextInput} from 'react-admin';
import {DateTimeField} from "../../components";

const AdmissionFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const AdmissionChangeLogList = props => (
    <List filters={<AdmissionFilter/>} {...props} exporter={false} bulkActions={false}>
        <Datagrid rowClick="show">
            <TextField source="id"/>
            <TextField source="admissionId"/>
            <TextField source="admissionVersion"/>
            <TextField source="sbd"/>
            <TextField source="stt"/>
            <TextField source="customerName"/>
            <TextField source="actionBy"/>
            <DateTimeField source="actionTime" format="HH:mm:ss DD/MM/YYYY"/>
        </Datagrid>
    </List>
);

export default AdmissionChangeLogList
