import StudentEnrollmentBatchIcon from '@material-ui/icons/ViewList';

import PupilEnrollmentBatchV3List from './PupilEnrollmentBatchV3List';
import PupilEnrollmentBatchCreate from './PupilEnrollmentBatchV3Create';
import PupilEnrollmentBatchEdit from './PupilEnrollmentBatchV3Edit';

export default {
    list: PupilEnrollmentBatchV3List,
    create: PupilEnrollmentBatchCreate,
    edit: PupilEnrollmentBatchEdit,
    icon: StudentEnrollmentBatchIcon,
};
