import React from 'react';
import {Create, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import DisciplineCategoryFields from './DisciplineCategoryFields'

const defaultValue = {
    active: true,
    type: 'STUDENT',
    minusPoint: 0
}

export const DisciplineCategoryCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<CustomToolbar/>} redirect="list" defaultValue={defaultValue}>
            <Grid container spacing={8}>
                <DisciplineCategoryFields/>
            </Grid>
        </SimpleForm>
    </Create>
);

export default DisciplineCategoryCreate
