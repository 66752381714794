import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {Card, CardContent, CircularProgress, Grid, TextField} from '@material-ui/core'
import {SimpleForm, Title} from 'react-admin';
import RoleCustomToolbar from "../Role/RoleCustomToolbar";
import {
    closeNotify,
    FileUploadComponent,
    showErrorNotify,
    showInfoNotify,
    showSuccessNotify,
    showWarningNotify
} from "../../components";
import env from "../../env";
import axios, {post} from "axios";

export class CustomerInfoBatchCreate extends React.Component {

    state = {
        description: '',
        enable: true,
        isRedirect: false,
        file: null
    }

    constructor(props) {
        super(props);
        this.state = {
            description: '',
            isLoading: false,
            showUploadFile: true,
        };
        this.onFileUpload = this.onFileUpload.bind(this);
    }

    changeDescription = (val) => {
        this.setState({description: val});
    }

    changeKiot = (e, val) => {
        this.setState({kiotConfigId: val});
    }

    changeFile = (e) => {
        this.setState({
            file: e.target.files[0]
        })
    }

    onFileUpload(e) {
        e.preventDefault(); // Stop form submit
        if (!this.validate()) {
            return;
        }
        if (this.state.file == null) {
            return;
        }

        this.setState({
            isLoading: true
        });
        showInfoNotify("Đang xử lí file, vui lòng chờ trong giây lát!");
        // lưu lô sản phẩm
        const {description} = this.state;
        const batchData = {
            description
        }
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': token
            }
        }
        const url = `${env.baseURL}/api/customerInfoBatches`;
        post(url, batchData, config).then(rs => {
            const batchId = rs.data.id
            console.log('batchId', batchId)
            const formData = new FormData();
            formData.append('file', this.state.file);
            formData.append('batchId', batchId);
            showInfoNotify("Đang xử lý file, vui lòng chờ trong giây lát!")
            axios({
                url: `${env.baseURL}/api/customerInfoBatchUpload/validate-item`,
                method: 'POST',
                data: formData,
                timeout: 600000,
                responseType: 'blob', // important
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: localStorage.getItem('token')
                }
            },).then((res) => {
                closeNotify();
                if (res.data && res.data.size > 0) {
                    let fileNameFromSv = res.headers['attachmentfilename'];
                    let blob = new Blob([res.data]);
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileNameFromSv ? fileNameFromSv : 'kq_thong_tin_kh.xlsx';
                    link.click();
                    showSuccessNotify("Kiểm tra kết quả trong file.");
                    this.setState({
                        isRedirect: true
                    });
                } else {
                    showWarningNotify("File không khả dụng, vui lòng thử lại hoặc liên hệ quản trị viên");
                }
            }).catch(err => {
                this.setState({
                    isLoading: false
                });
                showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
            })
        }).catch(err => {
            this.setState({
                isLoading: false
            });
            showErrorNotify("Không thể tạo lô thông tin khách hàng");
        })
    }


    cancel = (e) => {
        this.setState({
            isRedirect: true
        })
    };

    validate = () => {
        const {description} = this.state;
        if (!description) {
            showWarningNotify('Vui lòng nhâp mô tả');
            return false;
        }
        return true;
    }

    render() {
        if (this.state.isRedirect) {
            return <Redirect to='/customerInfoBatches'/>;
        }
        const {description} = this.state;
        return (
            <React.Fragment>
                <Card>
                    <Title title="Tạo lô thông tin khách hàng"/>
                    <CardContent>
                        <SimpleForm toolbar={<RoleCustomToolbar cancel={this.cancel}/>}
                                    redirect="list">
                            <Grid container spacing={8}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="standard-required"
                                        label="Mô tả"
                                        margin="normal"
                                        required={true}
                                        value={description}
                                        onChange={(e) => this.changeDescription(e.target.value)}
                                    />
                                </Grid>

                                <Grid container spacing={8}>
                                    {
                                        this.state.showUploadFile && <Grid item xs={12} sm={6}>
                                            <FileUploadComponent
                                                onFileChanged={(e) => this.setState({file: e.target.files[0]})}
                                                onFileUpload={this.onFileUpload}
                                            />
                                            {
                                                this.state.isLoading && <CircularProgress color="secondary"/>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </SimpleForm>
                    </CardContent>
                </Card>
            </React.Fragment>
        )
    }
}

export default connect(null, null)(CustomerInfoBatchCreate);
