import React from 'react';
import {SelectInput} from 'react-admin';

const cardApplicationTypes = [
    {id: 'MOVE', name: 'Chuyển lớp'},
    {id: 'KEEP', name: 'Tạo và giữ lớp'}
]


export const HandleTypeInput = (props) => (
    (
        <>
            <SelectInput source={props.source} choices={cardApplicationTypes} {...props} />
            <div>
                <p>Chuyển lớp: sẽ xoá học sinh ở lớp cũ, tạo học sinh theo thông tin từ file excel</p>
                <p>Tạo và giữ lớp: sẽ giữ học sinh ở lớp cũ, tạo học sinh lớp mới theo thông tin từ file excel</p>
            </div>
        </>
    )

)
