import React, {useRef, useState} from 'react';

import axiosInstance from "../../api/axios";
import './style.css';
import {Button, CircularProgress, Grid, InputLabel, TextField} from '@material-ui/core'
import ReCAPTCHA from "react-google-recaptcha";
import env from "../../env/env";
import {showWarningNotify} from "../../components";
import withStyles from '@material-ui/core/styles/withStyles';
import {compose} from "redux";


const styles = {
    marTop16: {
        marginTop: 16
    },
    marBot16: {
        marginBottom: 16
    },
    marLeft16: {
        marginLeft: 16
    },
    marLeftRight16: {
        marginLeft: 16,
        marginRight: 16
    },
    qrImage: {
        height: 150,
        width: 150
    },
    flexRowBetween: {
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%'
    },
    width100: {
        width: '100%'
    },
    font16: {
        fontSize: 18
    },
    textBold: {
        fontWeight: 'bold'
    },
    textCenter: {
        textAlign: 'center'
    },
    bigHeader2: {
        fontSize: 20,
        fontWeight: 'bold'
    }
};

const TraCuuTuyenSinhV2 = ({selectedTenant = {}}) => {
    const [loading, setLoading] = useState(false);
    const [clickCaptcha, setClickCaptcha] = useState(false);
    const [sbd, setSbd] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [dob, setDob] = useState('');
    const [displayData, setDisplayData] = useState(false);
    const [haveData, setHaveData] = useState(null);
    const [htmlResult, setHtmlResult] = useState(null);
    const captchaRef = useRef(null)

    const submitFindAdmission = (e) => {
        e.preventDefault();
        const token = captchaRef.current.getValue();
        if (!token) {
            showWarningNotify('Vui lòng nhập Captcha')
            return
        }
        if (!sbd) {
            if (!customerName || !dob) {
                showWarningNotify('Vui lòng (Họ Tên và Ngày sinh) hoặc Số báo danh để tra cứu thông tin tuyển sinh')
                return
            }
        }
        const data = {
            token,
            dob, sbd, customerName,
            tenantId: selectedTenant.id
        }
        captchaRef.current.reset();
        setLoading(true)
        setDisplayData(false)
        const url = `/public/checkAdmissions`;
        axiosInstance.post(url, data).then(rs => {
            setLoading(false)
            setClickCaptcha(false)
            const data = rs.data
            setDisplayData(true)
            if (data && data.success) {
                setHaveData(true)
                // setHtmlResult('&lt;h2&gt;Trường THPT Nguyễn An Ninh&lt;/h2&gt;&lt;p&gt;Ch&uacute;c mừng&lt;/p&gt;&lt;p&gt;học sinh đ&atilde; suất sắc tr&uacute;ng tuyển v&agrave;o lớp 10&lt;/p&gt;&lt;p&gt;năm học 2024-2025&lt;/p&gt;&lt;p&gt;Phạm Ho&agrave;ng Quỳnh Anh Số thứ tự tuyển sinh: 1&lt;/p&gt;&lt;p&gt;M&ocirc;n Văn: 7.25 M&ocirc;n To&aacute;n: 5.25 M&ocirc;n Anh: 6.0&lt;/p&gt;&lt;p&gt;&lt;strong&gt;Phụ huynh lưu &yacute; trong c&ocirc;ng t&aacute;c tuyển sinh:&lt;/strong&gt;&lt;/p&gt;&lt;ol&gt;&lt;li&gt;Vui l&ograve;ng tải ứng dung VinaID để thực hiện đăng k&yacute; tuyển sinh thuận lợi.&lt;/li&gt;&lt;li&gt;Theo d&otilde;i c&aacute;c th&ocirc;ng b&aacute;o v&agrave; th&ocirc;ng tin của trường&amp;nbsp;qua ứng dụng VinaID v&agrave; website của trường (https://thptnguyenanninh.hcm.edu.vn/&lt;/li&gt;&lt;li&gt;Tham dự TƯ VẤN TUYỂN SINH v&agrave;o ng&agrave;y 12/07/2024 theo c&aacute;c ca như sau:&lt;/li&gt;&lt;/ol&gt;&lt;ul&gt;&lt;li&gt;Ca 1: từ số thứ tự &lt;strong&gt;001 &ndash; 200&lt;/strong&gt; (Từ 07h30 &ndash; 09h30)&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp; &amp;nbsp;&lt;/li&gt;&lt;li&gt;Ca 2: từ số thứ tự &lt;strong&gt;201 &ndash; 400&lt;/strong&gt; (Từ 09h30 &ndash; 11h30)&lt;/li&gt;&lt;li&gt;Ca 3: từ số thứ tự &lt;strong&gt;401 &ndash; 600&lt;/strong&gt; (Từ 13h00 &ndash; 15h00)&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&lt;/li&gt;&lt;li&gt;Ca 4: từ số thứ tự &lt;strong&gt;601 trở đi&lt;/strong&gt; (Từ 15h00 &ndash; 17h00)&lt;/li&gt;&lt;/ul&gt;&lt;p&gt;Tại ứng dụng VinaID ngay&amp;nbsp;&lt;a href=\\&quot;https://bit.ly/VinaIDapp\\&quot;&gt;tại đ&acirc;y&lt;/a&gt;&lt;/p&gt;&lt;p&gt;&amp;nbsp;&lt;/p&gt;')
                setHtmlResult(data.htmlContent)
                insertAsText('resultContainer', data.htmlContent)
            }
        })
    }

    function insertAsText(containerId, text) {
        document.getElementById(containerId).innerHTML = text;
        // var container = document.getElementById( containerId );
        // var textNode = document.createTextNode( text );
        // container.appendChild( textNode );
    }

    function onChangeCaptcha(value) {
        if (value) {
            setClickCaptcha(true)
        } else {
            setClickCaptcha(false)
        }
    }

    const resetForm = () => {
        setDisplayData(false)
        setHtmlResult(null)
        setCustomerName('')
        setDob('')
        setSbd('')
    }

    return (
        <Grid container className={'main-info'}>
            <Grid container className={'main-data-info'}>
                <div style={{display: 'flex', flex: 1, justifyContent: 'center', margin: '16px'}}>
                    <img style={{width: 120, height: 120}}
                         src={`${env.baseURL}${selectedTenant.logo}`}/>
                </div>
                {displayData ?
                    <Grid container>
                        {htmlResult ?
                            <Grid container>
                                <Grid container style={styles.marLeftRight16}>
                                    <div id='resultContainer'></div>
                                    <div style={{...styles.width100, ...styles.marTop16, ...styles.textCenter}}>
                                        <span>{'Tải ứng dụng VinaID ngay '}</span>
                                        <a href="https://bit.ly/VinaIDapp"><span>{'tại đây'}</span></a>
                                    </div>
                                    <div style={{...styles.width100, ...styles.textCenter}}>
                                        <img style={styles.qrImage}
                                             src={env.baseURL + '/files/qr_vinaid_app.png'}
                                             alt=""></img>
                                        {/*<img src={env.baseURL + '/files/qr_vinaid_app.png'} alt=""></img>*/}
                                    </div>
                                    <Button style={{...styles.marTop16, ...styles.marBot16}} variant="outlined"
                                            color="primary"
                                            onClick={resetForm}>
                                        Quay lại
                                    </Button>
                                </Grid>
                            </Grid>
                            :
                            <>
                                <div
                                    style={{...styles.width100, ...styles.bigHeader2, ...styles.marTop16,}}>{'Không tìm thấy thông tin học sinh. Vui lòng kiểm tra lại thông tin'}</div>
                                <Button style={{...styles.marTop16, ...styles.marBot16}} variant="outlined"
                                        color="primary"
                                        onClick={resetForm}>
                                    Quay lại
                                </Button>
                            </>
                        }
                    </Grid>
                    :
                    <Grid container style={styles.marLeftRight16}>
                        <div className={"big-header"}>ĐĂNG KÝ NHẬP HỌC</div>
                        <div className={"big-header"}>năm học 2024-2025</div>
                        <div className={"text-center"}>{`Trường học: ${selectedTenant.name}`}</div>
                        <div
                            className={"big-header2"}>{'Nhập Số báo danh hoặc (Họ Tên và Ngày sinh) để đăng ký nhập học'}</div>
                        <Grid container style={{...styles.marTop16}}>
                            <Grid item xs={4}>
                                <InputLabel>{'Số báo danh (Ưu tiên 1):'}</InputLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField value={sbd} onChange={(e) => setSbd(e.target.value)}/>
                            </Grid>
                        </Grid>
                        <Grid container style={{...styles.marTop16}}>
                            <Grid item xs={4}>
                                <InputLabel>{'Họ Tên (Ưu tiên 2):'}</InputLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField value={customerName} onChange={(e) => setCustomerName(e.target.value)}/>
                            </Grid>
                        </Grid>
                        <Grid container style={{...styles.marTop16}}>
                            <Grid item xs={4}>
                                <InputLabel>{'Ngày sinh (Ưu tiên 2):'}</InputLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField value={dob} type="date" id="birthday" name="birthday"
                                           onChange={(e) => setDob(e.target.value)}/>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} style={styles.marTop16}>
                            <ReCAPTCHA
                                sitekey={env.captchaSiteKey}
                                onChange={onChangeCaptcha}
                                ref={captchaRef}
                            />
                        </Grid>
                        <Button style={{...styles.marTop16, ...styles.marBot16}} variant="outlined" color="primary"
                                onClick={submitFindAdmission}
                                disabled={!clickCaptcha || loading}>
                            Tra cứu
                        </Button>
                    </Grid>
                }
                {loading && <Grid item xs={12}><CircularProgress color="secondary"/> </Grid>}
                <div style={{margin: '0 16px', width: '100%'}}>

                    <div style={{border: '1px solid whitesmoke', width: '100%'}}/>
                    <div style={{marginTop: 8}}>
                        <p style={{fontStyle: 'italic', fontSize: 15, color: '#0000008a'}}>© 2024 - Trang thông tin được
                            xây
                            dựng và phát triển bởi {selectedTenant.name} và <a href='https://www.facebook.com/VinaID.vn' target='_blank'
                                                                               style={{color: 'blue'}}>VinaID</a>
                        </p>
                    </div>
                </div>
            </Grid>

        </Grid>
    )
}

export default compose(
    withStyles(styles)
)(TraCuuTuyenSinhV2);
