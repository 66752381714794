import React from 'react';
import { Create, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import StudentsByUnitsFields from './StudentsByUnitsFields'

export const StudentsByUnitsCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<CustomToolbar {...props} />} redirect="list" >
      <Grid container spacing={8}>
        <StudentsByUnitsFields />
      </Grid>
    </SimpleForm>
  </Create>
);

export default StudentsByUnitsCreate
