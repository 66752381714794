import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {BooleanInput, DateInput, LongTextInput, ReferenceInput, required, Title} from 'react-admin';
import {
    NotifyForm,
    schoolYears,
    showErrorNotify,
    showInfoNotify,
    showSuccessNotify,
    TreeCheckboxInput
} from '../../components';
import env from '../../env';
import axios from "axios";
import moment from "moment";

class TBNhacNo extends React.Component {
    state = {
        displayDueDate: true,
        displayDept: true,
        isLoading: false,
        billMonth: moment().format('YYYY-MM-DD'),
        params: {
            unitIds: [],
            note: '',
        }
    };

    notifyAllStudentsUrl = (fullDayOnly, allStudent) => {
        if (!this.state.params.note) {
            this.state.params.note = '';
        }
        this.setState({isLoading: true})
        showInfoNotify("Đang gửi thông báo. Vui lòng đợi trong giây lát!");
        const data = {
            note: this.state.params.note,
            fullDayOnly: fullDayOnly,
            allStudent: allStudent,
            displayDueDate: this.state.params.displayDueDate,
            displayDept: this.state.params.displayDept,
            billMonthStr: this.state.params.billMonth,
            unitIds: this.state.params.unitIds
        }
        const url = `${env.baseURL}/api/bill-batch/send-nhac-no`
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': token
            }
        };
        return axios.post(url, data, config)
    };


    notifyAllStudents = () => {
        if (!this.state.params.unitIds || this.state.params.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }

        this.notifyAllStudentsUrl(false, true).then(rs => {
            this.setState({isLoading: false})
            if (rs.data) {
                showSuccessNotify("Gửi thông báo thành công!");
                this.state.showBtn = false;
                this.forceUpdate()
            } else {
                showErrorNotify("Gửi thông báo không thành công. Vui lòng kiểm tra hoặc liên hệ quản trị viên!");
            }
        }, err => {
            this.setState({isLoading: false})
            showErrorNotify(err.message);
        });
    };

    fullDayOnly = () => {
        if (!this.state.params.unitIds || this.state.params.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        this.notifyAllStudentsUrl(true, false).then(rs => {
            this.setState({isLoading: false})
            if (rs.data) {
                showSuccessNotify("Gửi thông báo thành công!");
                this.state.showBtn = false;
                this.forceUpdate()
            } else {
                showErrorNotify("Gửi thông báo không thành công. Vui lòng kiểm tra hoặc liên hệ quản trị viên!");
            }
        }, err => {
            this.setState({isLoading: false})
            showErrorNotify(err.message);
        });
    };

    notFullDayOnly = () => {
        if (!this.state.params.unitIds || this.state.params.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }

        this.notifyAllStudentsUrl(false, false).then(rs => {
            this.setState({isLoading: false})
            if (rs.data) {
                showSuccessNotify("Gửi thông báo thành công!");
                this.state.showBtn = false;
                this.forceUpdate()
            } else {
                showErrorNotify("Gửi thông báo không thành công. Vui lòng kiểm tra hoặc liên hệ quản trị viên!");
            }
        }, err => {
            this.setState({isLoading: false})
            showErrorNotify(err.message);
        });
    };

    validate = (values) => {
        const errors = {};

        // Copy input values to params
        this.setState({params: values})

        return errors;
    }

    render() {
        return (
            <Card>
                <Title title="resources.tbNhacNo.name"/>
                <CardContent>
                    <NotifyForm validate={this.validate}
                                allClazzes={this.notifyAllStudents}
                                fullDayClazzes={this.fullDayOnly}
                                notFullDayClazzes={this.notFullDayOnly}
                                isLoading={this.state.isLoading}
                                params={this.state}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.month" source="billMonth"
                                           validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            </Grid>

                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <BooleanInput label="resources.tbNhacNo.displayDueDate"
                                              source="displayDueDate" {...this.props}  />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <BooleanInput label="resources.tbNhacNo.displayDept"
                                              source="displayDept" {...this.props} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <Grid item xs={12} sm={6}>
                                    <LongTextInput label="resources.tbNhacNo.note"
                                                   rows={5}
                                                   style={{maxWidth: '90%'}}
                                                   multiline
                                                   source="note" {...this.props} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label>Danh sách lớp *</label>
                                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                                    <ReferenceInput source="unitIds"
                                                    reference="units"
                                                    perPage={999}
                                                    filter={{clazzWithParents: true, schoolYear: schoolYears[0].id}}
                                                    {...this.props} >
                                        <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                                    </ReferenceInput>
                                </div>
                            </Grid>
                        </Grid>
                    </NotifyForm>
                </CardContent>
            </Card>
        )
    }
}

export default TBNhacNo

