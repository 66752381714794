import React from 'react';
import Grid from '@material-ui/core/Grid'
import {BooleanInput, required, SelectInput, TextInput} from 'react-admin';
import env from "../../env";

const tenantTypes = [
    // { id: 'SUPER_ADMIN',  name: 'Super Admin' },
    {id: 'SCHOOL', name: 'Trường học'},
    {id: 'COMPANY', name: 'Tổ chức'},
    {id: 'PAYMENT_PARTNER', name: 'Đối tác thanh toán'},
    {id: 'HELPDESK', name: 'Hỗ trơ'},
    {id: 'RESIDENT', name: 'Khu dân cư'}
]

const TenantFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <SelectInput source="type" validate={required()} choices={tenantTypes} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="enablePayment" defaultValue={false} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="enableEdit" defaultValue={false} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="showAdBanner" defaultValue={false} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="requirePhoneForCustomer" defaultValue={false} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            {props.record.path &&
            <img src={`${env.baseURL}${props.record.path}`}
                 alt="" style={{maxWidth: '250px'}}
            />
            }
        </Grid>
    </React.Fragment>
)

export default TenantFields
