import CardApplicationIcon from '@material-ui/icons/ViewList';

import CardApplicationNeedConfirmList from './CardApplicationNeedConfirmList';
import CardApplicationNeedConfirmEdit from './CardApplicationNeedConfirmEdit';

export default {
  list: CardApplicationNeedConfirmList,
  edit: CardApplicationNeedConfirmEdit,
  icon: CardApplicationIcon,
};
