import React from 'react';
import {LongTextInput, TextInput, required, ReferenceInput, AutocompleteInput} from 'react-admin';
import Grid from '@material-ui/core/Grid';

const SchoolFields = props => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <TextInput source="name" validate={required()} {...props} />
    </Grid>
      <Grid item xs={12} sm={6}>
          <ReferenceInput source="homeroomTeacher" reference="employeeEnrollments" allowEmpty {...props} >
              <AutocompleteInput optionText="customerName"/>
          </ReferenceInput>
      </Grid>
    <Grid item xs={12} sm={6} >
      <LongTextInput source="address" {...props} />
    </Grid>

    <Grid item xs={12} sm={6} >
      <TextInput source="contactName" {...props} />
    </Grid>

    <Grid item xs={12} sm={6} >
      <TextInput source="contactPhone" {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <TextInput source="email" {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <TextInput source="website" {...props} />
    </Grid>
  </React.Fragment>
)

export default SchoolFields
