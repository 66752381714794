import React, {Fragment} from 'react';
import {
    AutocompleteInput,
    Datagrid,
    DatagridBody,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    SelectField,
    TextField,
    TextInput
} from 'react-admin';
import {DateTimeField, schoolYears} from "../../components";
import ImageProfile from "../ProfileChangeLog/ImageProfile";
import {CardApplicationStatusField, CardApplicationStatusInput3} from "../../components/CardApplicationStatus";
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ExportReportButton from "../CardApplication/ExportReportButton";

const authStatuses = [
    {id: 'A_IN_REvVIEW', name: 'profileAuthStatus.A_IN_REvVIEW'},
    {id: 'L_REVIEW_REJECTED', name: 'profileAuthStatus.L_REVIEW_REJECTED'},
    {id: 'O_REVIEW_ACCEPTED', name: 'profileAuthStatus.O_REVIEW_ACCEPTED'},
]

export const AuthStatusField = (props) => (
    <SelectField source={props.source} choices={authStatuses} {...props} />
)

const ProfileChangeLogFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
        <ReferenceInput source="cardApplicationBatchId" reference="cardApplicationBatches" alwaysOn allowEmpty
                        perPage={999}>
            <AutocompleteInput optionText="displayName"/>
        </ReferenceInput>
        <ReferenceInput source="unitId" reference="baseUnits" alwaysOn allowEmpty
                        filter={{schoolYearId: schoolYears?.length > 0 ? schoolYears[0]?.id : ''}}
                        sort={{field: 'name', order: 'ASC'}}
                        perPage={999}
        >
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <CardApplicationStatusInput3 source="status" alwaysOn allowEmpty/>
    </Filter>
);


const CustomDatagridRow = ({record, resource, id, onToggleItem, children, selected, basePath}) => (
    <TableRow key={id}>
        <TableCell style={{width: "2%"}} padding="none">
            <Checkbox
                checked={selected}
                onClick={() => onToggleItem(id)}
            />
        </TableCell>
        {React.Children.map(children, field => (
            <TableCell style={{width: field.props.width, padding: '4px 10px 4px 10px'}}
                       key={`${id}-${field.props.source}`}>
                <span style={{height: 'auto'}}>
                    {React.cloneElement(field, {
                        record,
                        basePath,
                        resource,
                    })}
                </span>

            </TableCell>
        ))}
    </TableRow>
);

const CustomDatagridBody = props => <DatagridBody {...props} row={<CustomDatagridRow/>}/>;
const CustomDatagrid = props => <Datagrid {...props} body={<CustomDatagridBody/>}/>;

const PostBulkActionButtons = props => (
    <Fragment>
        <ExportReportButton {...props} />
    </Fragment>
);

export const ProfileChangeLogReadyList = props => (
    <List filters={<ProfileChangeLogFilter/>} {...props} exporter={false}
          sort={{field: 'status', order: 'DESC'}} perPage={25}
          bulkActionButtons={<PostBulkActionButtons/>}>
        <CustomDatagrid>
            <TextField source="id"/>
            <ImageProfile/>
            <TextField source="existCustomerId"/>
            <TextField source="customerName"/>
            <ReferenceField source="unitId" reference="baseUnits" allowEmpty>
                <TextField source="name"/>
            </ReferenceField>
            <DateTimeField source="dob" format="DD-MM-YYYY"/>
            <TextField source="internalBatchId"/>
            <DateTimeField source="sentRequestDate" format="DD-MM-YYYY"/>
            <DateTimeField source="deliveryDate" format="DD-MM-YYYY"/>
            <CardApplicationStatusField source="status"/>
        </CustomDatagrid>
    </List>
);

export default ProfileChangeLogReadyList
