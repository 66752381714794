import React from 'react';
import {
    AutocompleteInput,
    CardActions,
    CreateButton,
    Datagrid,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput
} from 'react-admin';

import {schoolYears} from '../../components';
import PrintAllQrInfoLinkButton from "../../components/PrintAllQrInfoLinkButton";
import PrintQrInfoLinkButton from "../../components/PrintQrInfoLinkButton";

class StudentEnrollmentFilter extends React.Component {
    state = {
        schoolYear: (schoolYears && schoolYears.length > 0) ? schoolYears[0].id : null,
    }

    onSchoolYearChanged = (e, value) => {
        console.log('onSchoolYearChanged', value)
        this.setState({schoolYear: value})

        // TODO: does not work
        // this.props.setFilters({unitId: undefined})
    }

    render() {
        return (
            <Filter {...this.props}>
                <SelectInput label="resources.schoolYears.name" source="schoolYear" choices={schoolYears}
                             onChange={this.onSchoolYearChanged}
                             alwaysOn allowEmpty={false}
                />
                <ReferenceInput source="unitId" reference="clazzes" alwaysOn allowEmpty
                                {...this.props}
                                filter={{schoolYear: this.state.schoolYear}}
                                sort={{field: 'name', order: 'ASC'}}
                                perPage={999}
                >
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
                <TextInput label="ra.action.search" source="q" alwaysOn/>
            </Filter>

        )
    }
}

const PostActions = ({
                         bulkActions,
                         basePath,
                         displayedFilters,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter,
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {/* Add your custom actions */}
        <PrintAllQrInfoLinkButton type={'s'}/>
        <CreateButton basePath={basePath}/>
    </CardActions>
);

export const StudentEnrollmentList = (props) => (
    <List filters={<StudentEnrollmentFilter/>}
          filterDefaultValues={{schoolYear: (schoolYears && schoolYears.length > 0) ? schoolYears[0].id : null}} {...props}
          actions={<PostActions/>}
          perPage={25}>
        <Datagrid rowClick="edit">
            <TextField source="customerId"/>
            <TextField source="customerName"/>
            <TextField source="internalCode"/>
            <TextField source="vduStudentCode"/>
            <TextField source="vduAttendanceCode"/>
            <ReferenceField source="unitId" reference="baseUnits" allowEmpty>
                <TextField source="name"/>
            </ReferenceField>
            <PrintQrInfoLinkButton type={'s'}/>
        </Datagrid>
    </List>
);

export default StudentEnrollmentList
