import React from 'react';
import {List, Datagrid, Filter, BooleanField, TextField, TextInput} from 'react-admin';

const RoleFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const RoleList = props => (
    <List filters={<RoleFilter/>} {...props} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="description"/>
            <BooleanField source="enabled"/>
        </Datagrid>
    </List>
);

export default RoleList
