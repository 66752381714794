import React from 'react';
import {
    CardActions,
    Datagrid,
    Filter,
    List,
    NumberField,
    ReferenceField,
    SelectField,
    TextField,
    TextInput
} from 'react-admin';
import {DateTimeField} from "../../components";

const BillAccountFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);


const PostActions = ({
                         bulkActions,
                         basePath,
                         displayedFilters,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter,
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
    </CardActions>
);

export const BillAccountTransactionList = props => (
    <List filters={<BillAccountFilter/>} {...props} ctions={<PostActions/>} bulkActionButtons={false} exporter={false}>
        <Datagrid>
            <TextField source="id"/>
            <DateTimeField source="transactionDate" format="DD-MM-YYYY HH:mm:ss"/>
            <ReferenceField source="billAccountId" reference="billAccounts" allowEmpty>
                <TextField source="accountName"/>
            </ReferenceField>
            <SelectField source="transactionType" choices={[
                {id: 'CREDIT', name: 'Ghi có'},
                {id: 'DEBIT', name: 'Ghi nợ'},
            ]}/>
            <NumberField source="amount" locales="vi-VI" options={{style: 'currency', currency: 'VND'}}/>
            <TextField source="description"/>
            <TextField source="createdBy"/>
        </Datagrid>
    </List>
);

export default BillAccountTransactionList
