import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Icon from '@material-ui/core/Icon';
import NumberFormat from 'react-number-format';

const classes = {
  root: {
    width: '100%',
    marginTop: 10,
  },
  paper: {
    marginTop: 3,
    width: '100%',
    overflowX: 'auto',
    marginBottom: 2,
  },
  table: {
    minWidth: 650,
  },
  icon: {
    fontSize: 18,
  },
};

class FoodMenuBatchValidation extends Component {

  renderTableHear(foodMenus) {
    let table = [];
    for (let i = 0; i < this.props.foodTypes.length; i++) {
      let found = false;
      for (let j = 0; j < foodMenus.length; j++) {
        const foodMenu = foodMenus[j];
        if (foodMenu.type === this.props.foodTypes[i]) {
          table.push(<TableCell align="right">
            {foodMenu.name}
            </TableCell>);
          found = true;
          break;
        }
      }
      if (!found) {
        table.push(<TableCell align="right">
        </TableCell>);
      }
    }
    return table;
  }

  render() {
    return (
      <div style={classes.root}>
        <Paper style={classes.paper}>
          <Table style={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="right">Hợp Lệ</TableCell>
                <TableCell align="right">Mô tả lỗi</TableCell>
                <TableCell align="right">Ngày</TableCell>

                {this.props.foodTypes.map(productName => (
                  <TableCell align="right">{productName}</TableCell>
                ))}

              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.data.map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell align="right">
                    {row.valid &&
                    <Icon style={classes.icon} className='fa fa-check' color="primary"/>
                    }
                    {!row.valid &&
                    <Icon style={classes.icon} className='fa fa-times' color="error"/>
                    }
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.errorMessage}
                  </TableCell>
                  <TableCell align="right">{row.date}</TableCell>
                  {this.renderTableHear(row.foodMenus)}

                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={this.props.total}
            rowsPerPage={this.props.rowsPerPage}
            page={this.props.page}
            backIconButtonprops={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonprops={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.props.handleChangePage}
            onChangeRowsPerPage={this.props.onChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

export default FoodMenuBatchValidation;
