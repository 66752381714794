import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Icon from '@material-ui/core/Icon';
import NumberFormat from 'react-number-format';

const classes = {
    root: {
        width: '100%',
        marginTop: 10,
    },
    paper: {
        marginTop: 3,
        width: '100%',
        overflowX: 'auto',
        marginBottom: 2,
    },
    table: {
        minWidth: 650,
    },
    icon: {
        fontSize: 18,
    },
};

class BillBatchValidation extends Component {

    renderTableHear(productPrices, policyPrices) {
        let table = [];
        for (let i = 0; i < this.props.productNames.length; i++) {
            for (let j = 0; j < productPrices.length; j++) {
                const productPrice = productPrices[j];
                if (productPrice.productName === this.props.productNames[i]) {
                    table.push(<TableCell align="right">
                        <NumberFormat value={productPrice.price} displayType={'text'} thousandSeparator={true}/>
                    </TableCell>);
                    break;
                }
            }
        }
        if (this.props.policyNames) {
            for (let i = 0; i < this.props.policyNames.length; i++) {
                for (let j = 0; j < policyPrices.length; j++) {
                    const policyPrice = policyPrices[j];
                    if (policyPrice.policyName === this.props.policyNames[i]) {
                        table.push(<TableCell align="right">
                            <NumberFormat value={policyPrice.reduceAmount} displayType={'text'}
                                          thousandSeparator={true}/>
                        </TableCell>);
                        break;
                    }
                }
            }
        }

        return table;
    }

    render() {
        return (
            <div style={classes.root}>
                <Paper style={classes.paper}>
                    <Table style={classes.table} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>STT</TableCell>
                                <TableCell align="right">Hợp Lệ</TableCell>
                                <TableCell style={{minWidth: 200}}>Mô tả</TableCell>
                                <TableCell align="right">Mã lớp</TableCell>
                                <TableCell style={{minWidth: 100}}>Lớp</TableCell>
                                <TableCell align="right">Mã học sinh</TableCell>
                                <TableCell style={{minWidth: 200}}>Họ Tên</TableCell>
                                <TableCell align="right">Số ngày thu trong tháng</TableCell>
                                <TableCell align="right">Số ngày vắng mặt bán trú</TableCell>

                                {this.props.productNames.map(productName => (
                                    <TableCell style={{minWidth: 100}}>{productName}</TableCell>
                                ))}
                                {this.props.policyNames && this.props.policyNames.map(policyName => (
                                    <TableCell align="right">{policyName}</TableCell>
                                ))}
                                <TableCell align="right">Miễn giảm</TableCell>
                                <TableCell align="right">Tổng Tiền</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.data.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell component="th" scope="row">
                                        {row.order}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.status === 'VALID' &&
                                        <Icon style={classes.icon} className='fa fa-check' color="primary"/>
                                        }
                                        {row.status === 'INVALID' &&
                                        <Icon style={classes.icon} className='fa fa-times' color="error"/>
                                        }
                                        {row.status === 'WARNING' &&
                                            <Icon style={classes.icon} className='fa fa-warning warningInfo'/>
                                        }
                                    </TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell align="right">{row.unitId}</TableCell>
                                    <TableCell>{row.unitName}</TableCell>
                                    <TableCell align="right">{row.customerId}</TableCell>
                                    <TableCell>{row.fullName}</TableCell>
                                    <TableCell align="right">{row.numOfDayToPay}</TableCell>
                                    <TableCell align="right">{row.numOfAbsentDay}</TableCell>
                                    {this.renderTableHear(row.productPrices, row.policies)}
                                    <TableCell align="right"><NumberFormat value={row.reduceAmount} displayType={'text'}
                                                                           thousandSeparator={true}/></TableCell>
                                    <TableCell align="right"><NumberFormat value={row.totalAmount} displayType={'text'}
                                                                           thousandSeparator={true}/></TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={this.props.total}
                        rowsPerPage={this.props.rowsPerPage}
                        page={this.props.page}
                        backIconButtonprops={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonprops={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.props.handleChangePage}
                        onChangeRowsPerPage={this.props.onChangeRowsPerPage}
                    />
                </Paper>
            </div>
        );
    }
}

export default BillBatchValidation;
