import ProductIcon from '@material-ui/icons/ViewList';

import BillProductList from './BillProductList';
import BillProductCreate from './BillProductCreate';
import BillProductEdit from './BillProductEdit';

export default {
  list: BillProductList,
  create: BillProductCreate,
  edit: BillProductEdit,
  icon: ProductIcon,
};
