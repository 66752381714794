import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import UnitFields from './UnitFields'

export const UnitEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar viewOnly />}>
      <Grid container spacing={8}>
        <UnitFields />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default UnitEdit
