import vietnameseMessages from 'ra-language-vietnamese';

const messages = {
    ...vietnameseMessages,

    daysOfWeek: {
        MONDAY: 'Thứ 2',
        TUESDAY: 'Thứ 3',
        WEDNESDAY: 'Thứ 4',
        THURSDAY: 'Thứ 5',
        FRIDAY: 'Thứ 6',
        SATURDAY: 'Thứ 7',
        SUNDAY: 'Chủ nhật',
    },
    recurring: {
        DAILY: 'Hàng ngày',
        WEEKLY: 'Hàng tuần',
        MONTHLY: 'Hàng tháng',
        YEARLY: 'Hàng năm',
    },
    eAdmissionSubject: {
        GRADUATION: 'Tốt nghiệp',
        ELECTIVE: 'Tự chọn',
        PHYSICAL: 'Giáo dục thể chất',
        JOB: 'Trung cấp nghề'
    },
    authStatus: {
        DRAFT: 'Bản nháp',
        NOT_AUTHORIZED: 'Chưa duyệt',
        AUTHORIZED: 'Đã duyệt',
    },
    messageCategory: {
        SENT_MANUALLY_BY_USER: 'Thông báo',
        BAO_BAI: 'Báo bài',
        HOAT_DONG: 'Hoạt động',
        ADMISSION_NOTIFICATION: 'Tuyển sinh'
    },
    smsCategory: {
        APP_REGISTER: 'Đăng ký ứng dụng VinaID',
        KQHT: 'Kết quả học tập',
        FREE_TEXT: 'Tùy chọn',
        TUYEN_SINH: 'Đăng ký tuyển sinh'
    },
    smsChannel: {
        SMS_BRANDNAME: 'SMS',
        APP: 'APP'
    },
    smsStatus: {
        PROCESSING: 'Đang xử lý',
        SUCCESS: 'Thành công',
        FAIL: 'Thất bại'
    },
    eventType: {
        ATTENDANCE: 'Điểm danh',
        LIMIT_QUANTITY: 'Giới hạn số lượng',
        FULL_DAY_MEAL: 'Ăn bán trú',
        NAP: 'Ngủ trưa',
        KIOT_MEAL: 'Suất ăn quầy hàng',
        LEAVE: 'Điểm danh về'
    },
    clazzType: {
        NORMAL: 'Thường',
        FULLDAY: 'Bán trú',
        TERM: 'Học phần',
        EXTRA: 'Đặc thù',
        GROUP: 'Nhóm',
        TEMP: 'Tạm',
    },
    foodMenu: {
        COM: 'Ăn cơm',
        CHAY: 'Ăn chay',
        CHAO: 'Ăn cháo',
        NGHI: 'Nghỉ',
    },
    cardApplicationStatus: {
        NOT_READY: 'Chưa đủ thông tin',
        A_SAME_PHONE: 'Trùng số điện thoại',
        A_SAME_DDCN: 'Trùng định danh cá nhân',
        A_DIFF_PHONE: 'Khác số điện thoại',
        A_DIFF_DDCN: 'Khác định danh cá nhân',
        READY: 'Đủ thông tin',
        B_TEMP_READY: 'Hình tạm, đủ thông tin',
        IN_REVIEW: 'Đang duyệt',
        REVIEW_REJECTED: 'Từ chối duyệt',
        WAIT_SYNC: 'Chờ đồng bộ',
        REVIEW_ACCEPTED: 'Đã duyệt',
        IN_PRINTING: 'Đang in thẻ',
        IN_DELIVERY: 'Đang giao thẻ',
        DELIVERED: 'Đã giao thẻ',
    },
    calendarType: {
        SCHEDULE: 'Thời khoá biểu',
        EXAM: 'Lịch thi'
    },
    profileAuthStatus: {
        A_IN_REvVIEW: 'Chờ duyệt',
        L_REJECTED: 'Từ chối',
        M_WAIT_SYNC: 'Chờ đồng bộ',
        O_ACCEPTED: 'Đã duyệt',
    },
    gender: {
        MALE: 'Nam',
        FEMALE: 'Nữ',
    },
    disciplineType: {
        CLAZZ: 'Theo lớp',
        STUDENT: 'Theo học sinh',
    },
    attendanceStatus: {
        IN_LATE: 'Trễ',
        MISSING_CARD: 'Không thẻ',
        ABSENT: 'Vắng không phép',
        ABSENT_WITH_PERMISSION: 'Vắng có phép',
    },
    kiotType: {
        DELIVERY_ONLY: 'Chỉ giao nhận',
        SELL_AT_STORE: 'Bán tại cửa hàng',
        SELL_AT_VINAID: 'Bán trên VinaID',
        SELL_WITH_SCHEDULE: 'Bán trên VinaID - theo lich',
    },
    admissionAdditionalService: {
        PROGRAM: 'Chương trình học',
        SERVICE: 'Dịch vụ giáo dục',
    },
    cardApplicationType: {
        EMPLOYEE: 'Nhân viên',
        STUDENT: 'Sinh viên',
        HS_C1: 'Học sinh',
        TUYEN_SINH: 'Tuyển sinh'
    },
    app: {
        action: {
            add_device: 'Thêm thiết bị',
            add_unit: 'Thêm Đối tượng',
            add_schedule: 'Thêm thời gian',
            add_submenu: 'Thêm quyền',
        },
        label: {
            schedules: 'Thời gian',
            devices: 'Thiết bị',
            units: 'Đối tượng (Đơn vị, phòng ban)',
            viewHtmlReport: 'Xem báo cáo',
            viewPdfReport: 'Xuất PDF',
            viewExcelReport: 'Xuất Excel',
            exportZip: 'Xuất file Zip',
            viewAll: 'Toàn bộ',
            fullClazzOnly: 'Chỉ HS bán trú',
            notFullDayClazzes: 'Chỉ HS không bán trú',
            moveEnroll: 'Chuyển lớp',
            copyEnroll: 'Tạo và giữ lớp',
            save: 'Lưu',
            cardPermission: {
                name: "Tên",
                clazzName: "Lớp",
                permission: "Quyền"
            }
        },
        dashboard: {
            vinaId: 'VINA ID',
            tenantName: 'Trường THCS Lý Thánh Tông',
            home: 'Trang chủ',
            contact: 'Liên hệ',
            onTimeClasses: 'Top 5 đúng giờ tháng',
            lateClasses: 'Top 5 trễ giờ tháng',
            cardTapHistory: 'Lịch sử tap thẻ'
        },
        smsDashboard: {
            usage: 'Tổng hợp lượt sử dụng',
            sendHistory: 'Lịch sử gửi sms'
        },
        message: {
            processingFile: 'Đang xử lí file, vui lòng chờ trong giây lát!'
        },
    },

    menus: {
        school_menu: {
            title: "Quản lý học đường",
        },
        resident_menu: {
            title: "Quản lý dân cư",
        },
        hospital_report: {
            title: "Báo cáo bệnh viện",
        },
        school_reports_menu: {
            title: "Báo cáo học đường",
        },
        company_menu: {
            title: "Quản lý Tổ chức",
        },
        discipline_menu: {
            title: "Quản lý nề nếp",
        },
        access_control_menu: {
            title: "Quản lý Vào ra",
        },
        face: {
            title: "Quản lý khuôn mặt",
        },
        bill_management_menu: {
            title: "Quản lý Học phí",
        },
        access_control_reports_menu: {
            title: "Báo cáo Vào ra",
        },
        bill_management_report: {
            title: "Báo cáo Học phí",
        },
        meeting_menu: {
            title: "Quản lý hội họp",
        },
        messaging_menu: {
            title: "Tin nhắn",
        },
        user_menu: {
            title: "Quản lý Người dùng",
        },
        config_menu: {
            title: "Cấu hình",
        },
        super_user_menu: {
            title: "Quản lý Tenant",
        },
        others_menu: {
            title: "Các chức năng khác",
        },
        category_menu: {
            title: "Danh mục"
        },
        relationship_menu: {
            title: "Quản lý quan hệ"
        },
        card_application_menu: {
            title: "Quản lý yêu cầu cấp thẻ"
        },
        kiot_menu: {
            title: "Quản lý quầy hàng"
        },
        semi_boarding_management: {
            title: "Quản lý bán trú"
        },
        bill_account_management_menu: {
            title: "Quản lý tài khoản học phi"
        },
        subject_result_management: {
            title: "Quản lý điểm thi"
        },
        insurance_menu: {
            title: "Quản lý bảo hiểm"
        },
        chat_menu: {
            title: "Quản lý chat"
        },
        health_menu: {
            title: "Quản lý sức khoẻ"
        },
        e_card_menu: {
            title: "Thẻ điện tử"
        },
        bao_cao_hoc_phan_menu: {
            title: "Báo cáo học phần"
        },
        help_desk_bill_management_report: {
            title: "Báo cáo học phí"
        },
        contribution_bill_management_menu: {
            title: "Quản lý đóng góp"
        },
        sms_messaging_menu: {
            title: "Tin nhắn SMS"
        },
        card_menu: {
            title: "Quản lý thẻ"
        },
        tuyen_sinh_menu: {
            title: "Quản lý tuyển sinh"
        },
    },

    resources: {
        tenants: {
            name: 'Tenant',
            fields: {
                id: 'Mã số',
                name: 'Tên',
                type: 'Loại',
                requirePhoneForCustomer: 'Yêu cầu SĐT khi mở thẻ',
                showAdBanner: 'Hiện quảng cáo',
                enableEdit: 'Cho chỉnh sửa thông tin',
                enablePayment: 'Cho Nạp thẻ'
            }
        },
        paymentGatewayFees: {
            name: 'Cấu hình phí giao dịch',
            fields: {
                id: 'Mã số',
                name: 'Tên',
                gateway: 'Cổng thanh toán',
                fixAmount: 'Hạn mức tĩnh(vnd)',
                percentage: 'Phí giao dịch(%)'
            }
        },
        paymentGatewayConfigurations: {
            name: 'Cấu hình cổng thanh toán',
            fields: {
                id: 'Mã số',
                paymentGateway: 'Cổng thanh toán',
                merchantCode: 'Mã Tổ chức',
                tenantId: 'Tổ nghiệp/Trường',
                maskSecretKey: 'Mã kết nôí',
                secretKey: 'Mã kết nôí',
                active: 'Kích hoạt',
            }
        },
        tenantBankAccounts: {
            name: 'Cấu hình tài khoản ngân hàng',
            fields: {
                id: 'Mã số',
                accountName: 'Tên chủ tài khoản',
                bankId: 'Tên ngân hàng',
                branch: 'Chi nhánh',
                accountNumber: 'Số tài khoản',
                tenantId: 'Trường/Tổ chức',
            }
        },
        vduGatewayConfigurations: {
            name: 'Cấu hình kết nối vn_edu',
            fields: {
                id: 'Mã số',
                accountName: 'Tên chủ tài khoản',
                bankName: 'Tên ngân hàng',
                branch: 'Chi nhánh',
                accountNumber: 'Số tài khoản',
                tenantId: 'Trường/Tổ chức',
            }
        },
        schools: {
            name: 'Trường học',
            fields: {
                id: 'Mã số',
                name: 'Tên trường',
                homeroomTeacher: 'Hiệu trưởng',
                address: 'Địa chỉ',
                contactName: 'Người liên hệ',
                contactPhone: 'Số điện thoai liên hệ',
                website: 'Website',
            }
        },
        grades: {
            name: 'Khối/Khoa',
            fields: {
                id: 'Mã số',
                name: 'Tên khối',
                parentId: 'Tên trường',
            }
        },
        clazzes: {
            name: 'Lớp học',
            fields: {
                id: 'Mã số',
                name: 'Tên lớp',
                clazzType: 'Loại lớp',
                note: 'Khu vực',
                parentId: 'Tên khối',
                schoolYearId: 'Năm học',
                googleCalendarIds: 'Thời khoá biểu GG',
                calendarIds: 'Thời khoá biểu V2',
                checkerIds: 'Sửa dữ liệu điểm danh',
                viewerIds: 'Xem dữ liệu điểm danh',
                editStudentInfoIds: 'Sửa dữ liệu mở thẻ',
                authorizedStudentInfoIds: 'Duyệt dữ liệu mở thẻ',
                authorizedFullDayIds: 'Duyệt dữ liệu ăn bán trú',
                authorizedNapIds: 'Duyệt dữ liệu ngủ',
                viewOffRequestIds: 'Xem đơn nghỉ phép',
                authorizedOffRequestIds: 'Duyệt đơn nghỉ phép',
                homeroomTeacher: 'Giáo viên chủ nhiệm',
                makerIds: 'Duyệt dữ liệu điểm danh',
                fullDay: 'Lớp bán trú',
                contactViewerIds: 'Xem thông tin liên lạc'
            }
        },
        groups: {
            name: 'Nhóm',
            fields: {
                id: 'Mã nhóm',
                name: 'Tên nhóm',
                contactName: 'Nguời liên hệ',
                phone: 'Số điện thoại',
                checkerIds: 'Sửa dữ liệu điểm danh',
                viewerIds: 'Xem dữ liệu điểm danh',
                homeroomTeacher: 'Giáo viên chủ nhiệm',
                makerIds: 'Duyệt dữ liệu điểm danh',
                schoolYearId: 'Năm học',
            }
        },
        companies: {
            name: 'Tổ chức',
            fields: {
                id: 'Mã số',
                name: 'Tên tổ chức',
                homeroomTeacher: 'Giám đốc',
                address: 'Địa chỉ',
                contactName: 'Người liên hệ',
                contactPhone: 'Số điện thoai liên hệ',
                website: 'Website',
                internalInformation: 'Thông tin nội bộ',
            }
        },
        departments: {
            name: 'Phòng ban/Bộ phận',
            fields: {
                id: 'Mã số',
                name: 'Tên Phòng ban/Bộ phận',
                parentId: 'Tổ chức/Phòng ban quản lý',
                homeroomTeacher: 'Trường khoa/Bộ phận',
                editStudentInfoIds: 'Sửa dữ liệu mở thẻ',
                authorizedStudentInfoIds: 'Duyệt dữ liệu mở thẻ',
                offRequestApprover: 'Duyệt đơn nghỉ phép'
            }
        },
        accessDevices: {
            name: 'Thiết bị',
            fields: {
                name: 'Tên',
                deviceNo: 'Mã thiết bị',
                description: 'Mô tả vị trí',
                sendNotification: 'Gửi thông báo',
                enabled: 'Kích hoạt',
            }
        },
        eventGroups: {
            name: 'Nhóm sự kiện',
            fields: {
                name: 'Tên',
            }
        },
        eventLocations: {
            name: 'Vị trí sự kiện',
            fields: {
                name: 'Tên',
                detail: 'Chi tiết',
                enable: 'Bật/Tắt',
            }
        },
        shifts: {
            name: 'Ca làm việc',
            fields: {}
        },
        schedules: {
            name: 'Lịch làm việc',
            fields: {}
        },
        events: {
            name: 'Sự kiện',
            fields: {
                name: 'Tên',
                infoLink: 'Link nội dung',
                recurring: 'Lặp lại theo chu kỳ?',
                fullDay: 'Gửi suất ăn bán trú',
                authorizeFullDayMeal: 'Duyệt ăn bán trú',
                accessControlOnly: 'Quản lý ra vào?',
                authorizeAttendance: 'Duyệt điểm danh?',
                recurringType: 'Chu kỳ lặp lại',
                separationCountDaily: 'Số ngày sẽ lặp lại',
                separationCountWeekly: 'Số tuần sẽ lặp lại',
                daysOfWeek: 'Lặp lại vào các ngày trong tuần',
                startDate: 'Ngày bắt đầu',
                endDate: 'Ngày kết thúc',
                startTime: 'Giờ vào',
                endTime: 'Giờ ra',
                needCheckIn: 'Phải điểm danh lúc vào',
                needCheckOut: 'Phải điểm danh lúc ra',
                lateComePermit: 'Thời gian cho phép đến trễ',
                earlyLeavePermit: 'Thời gian cho phép về sớm',
                openForCheckIn: 'Thời gian bắt đầu điểm danh vào',
                endForCheckIn: 'Thời gian kết thúc điểm danh vào',
                openForCheckOut: 'Thời gian bắt đầu điểm danh ra',
                endForCheckOut: 'Thời gian kết thúc điểm danh ra',
                deviceIds: 'Thiết bị',
                unitIds: 'Đơn vị/Phòng ban (ưu tiên 1)',
                customerIds: 'Người tham dự (ưu tiên 3)',
                eventGroupId: 'Nhóm sự kiện',
                eventLocationId: 'Vị trí sự kiện',
                sourceEventIds: 'Dữ liệu điểm danh nguồn',
                excludeUnitIds: 'Đơn vị loại trừ (ưu tiên 2)',
                type: 'Loại sự kiện',
                quota: 'Giới hạn SL',
                deviceInIds: 'Thiết bị vào',
                deviceOutIds: 'Thiết bị ra',
                kiotConfigId: 'Quầy hàng',
                enabled: 'Kích hoạt',
            }
        },
        studentEnrollments: {
            name: 'Học sinh/Sinh viên',
            fields: {
                customerId: 'Mã VinaID',
                customerName: 'Họ tên',
                studentNo: 'Mã số HS/SV',
                internalCode: 'Mã số HS/SV',
                vduStudentCode: 'Mã HS vnEdu',
                vduAttendanceCode: 'Mã điểm danh vnEdu',
                unitId: 'Lớp',
            }
        },
        'student-relationships': {
            name: 'Thông tin học sinh',
            fields: {
                customerId: 'Mã VinaID',
                customerName: 'Họ và tên',
                unitId: 'Lớp',
                relationshipType: 'Phụ huynh'
            }
        },
        employeeEnrollments: {
            name: 'Nhân viên',
            fields: {
                customerId: 'Mã VinaID',
                customerName: 'Tên Nhân viên',
                internalCode: 'Mã số Nhân viên',
                title: 'Chức vụ',
                unitId: 'Phòng ban',
            }
        },
        residentEnrollments: {
            name: 'Cư dân',
            fields: {
                customerId: 'Tên cư dân',
                customerName: 'Tên cư dân',
                title: 'Chức vụ',
                internalCode: 'Mã số nội bộ',
                unitId: 'Căn hộ',
            }
        },
        googleCalendars: {
            name: 'TKB/Lịch Thi',
            fields: {
                name: 'Tên',
                googleCalendarId: 'Mã lịch Google',
                schoolYearId: 'Năm học',
                startDate: 'Ngày bắt đầu',
                endDate: 'Ngày kết thúc',
                type: 'Loại',
            }
        },
        guests: {
            name: 'Khách vãng lai',
            fields: {
                name: 'Họ tên',
                idNo: 'CMND/CCCD',
                cardNumber: 'Số thẻ',
                cardUid: 'Mã thẻ',
                eventIds: 'Sự kiện',
                eventGroupIds: 'Nhóm sự kiện',
                unitName: 'Đơn vị của khách',
                inDateTime: 'Ngày giờ vào',
                outDateTime: 'Ngày giờ ra',
                workDescription: 'Nội dung làm việc',
                contactId: 'Nhân sự làm việc',
                contactName: 'Nhân sự làm việc',
            }
        },
        guestOuts: {
            name: 'Khách vãng lai ra',
            fields: {
                name: 'Họ tên',
                idNo: 'CMND/CCCD',
                cardUid: 'Mã thẻ',
                eventIds: 'Sự kiện',
                unitName: 'Đơn vị của khách',
                inDateTime: 'Ngày giờ vào',
                outDateTime: 'Ngày giờ ra',
                workDescription: 'Nội dung làm việc',
                contactId: 'Nhân sự làm việc',
            }
        },
        attendanceSimu: {
            name: 'Tap thẻ điểm danh',
        },
        teacherEnrollments: {
            name: 'Giáo viên',
            fields: {}
        },
        settings: {
            name: 'Thông số hệ thống',
            fields: {
                category: 'Nhóm thông số',
                name: 'Tên thông số',
                description: 'Mô tả',
                intValue: 'Giá trị số',
                boolValue: 'Giá trị boolean',
                stringValue: 'Giá trị chuỗi',
            }
        },
        internalTenantSettings: {
            name: 'Cấu hình hệ thống nội bộ',
            fields: {
                category: 'Nhóm thông số',
                name: 'Tên thông số',
                description: 'Mô tả',
                intValue: 'Giá trị số',
                boolValue: 'Giá trị boolean',
                stringValue: 'Giá trị chuỗi',
            }
        },
        messages: {
            name: 'Tin nhắn',
            fields: {
                channel: 'Kênh',
                pin: 'Ghim thông báo',
                link: 'Đường dẫn',
                linkDisplay: 'Hiển thị đường dẫn',
                category: 'Loại',
                subject: 'Chủ đề',
                excerpt: 'Nội dung',
                text: 'Nội dung',
                customerIds: 'Người nhận *',
                unitIds: 'Đơn vị nhận',
                authStatus: 'Trạng thái',
                lastUpdatedOn: 'Ngày gửi',
                lastUpdatedBy: 'Thay đổi bởi',
            }
        },
        vinaIDMessages: {
            name: 'Thông báo từ VinaID',
            fields: {
                channel: 'Kênh',
                subject: 'Chủ đề',
                excerpt: 'Nội dung',
                text: 'Nội dung',
                customerIds: 'Người nhận',
                unitIds: 'Đơn vị nhận',
                authStatus: 'Trạng thái',
                lastUpdatedOn: 'Ngày gửi',
            }
        },
        archiveMessageLogs: {
            name: 'Nhật ký tin nhắn',
            fields: {
                createdOn: 'Ngày giờ',
                channel: 'Kênh',
                customerName: 'Người nhận',
                excerpt: 'Nội dung',
                message: 'Nội dung',
            }
        },
        attendances: {
            name: 'Dữ liệu Điểm danh',
            fields: {
                attendanceTime: 'TG chạm thẻ',
                deviceNo: 'Thiết bị',
                cardNumber: 'Số thẻ',
                customerId: 'Mã VinaID',
                customerName: 'Họ tên',
                temperature: 'Nhiệt độ',
                createdOn: 'TG gửi dữ liệu'
            }
        },
        attendanceResultSummaries: {
            name: 'Kết quả điểm danh',
            fields: {},
            statuses: {
                NOT_AUTHORIZED: 'Chưa duyệt',
                AUTHORIZED: 'Đã duyệt',
            },
        },
        attendanceResultDetailses: {
            name: 'Kết quả chi tiết',
            fields: {}
        },
        inReport: {
            name: 'Báo cáo vào',
            fields: {}
        },
        attendanceInOutReport: {
            name: 'Báo cáo vào ra',
            fields: {}
        },
        timeKeepingReport: {
            name: 'Báo cáo chấm công',
            eventId: "Sự kiện"
        },
        votingReport: {
            name: 'Báo cáo biểu quyết',
            fields: {}
        },
        missingVotingReport: {
            name: 'Báo cáo danh sách không biểu quyết',
            fields: {}
        },
        absentReport: {
            name: 'Báo cáo điểm danh vắng',
            fields: {}
        },
        polls: {
            name: 'Biểu quyết',
            fields: {
                name: 'Tên biểu quyết',
                startDate: 'Ngày bắt đầu',
                startTime: 'Giờ bắt đầu',
                endDate: 'Ngày kết thúc',
                endTime: 'Giờ kết thúc',
                deviceIds: 'Thiết bị',
                unitIds: 'Unit'
            }
        },
        users: {
            name: 'Người dùng',
            fields: {
                roleIds: 'Vai trò',
                userName: 'Tên đăng nhập',
                password: 'Mật khẩu',
                fullName: 'Họ tên',
                enabled: 'Kích hoạt',
                customerId: 'KH hệ thống',
                userType: 'Loại tài khoản',
                kiotConfigIds: 'Quầy hàng',
                defaultAccessDeviceId: 'Thiết bị điểm danh',
            },
            changePassword: 'Đổi mật khẩu'
        },
        roles: {
            name: 'Vai trò người dùng',
            fields: {
                name: 'Tên',
                description: 'Mô tả',
                enabled: 'Kích hoạt',
                subMenuIds: 'Phân quyền theo chức năng',
            }
        },
        superAdminUsers: {
            name: 'Người dùng toàn hệ thống',
            fields: {
                roleIds: 'Vai trò người dùng',
                locked: 'Bị khóa',
                enabled: 'Hoạt động',
                lockTime: 'Thời gian khóa',
                failOtpCount: 'Số lần sai otp',
            }
        },
        subMenus: {
            name: '// TODO',
            fields: {}
        },
        roleSubMenus: {
            name: '// TODO',
            fields: {
                subMenuId: 'Chức năng',
            }
        },
        calendars: {
            name: 'Lịch',
            fields: {
                defaultDeviceIdList: 'Các thiết bị mặc định',
            },
            messages: {
                today: 'Hôm nay',
                previous: 'Trước',
                next: 'Sau'
            },
            actions: {
                createEvent: 'Tạo sự kiện',
                editEvent: 'Cập nhật sự kiện',
            }
        },
        tenantSettings: {
            name: 'Quản lý cấu hình hệ thống',
            fields: {
                category: 'Nhóm thông số',
                name: 'Tên thông số',
                description: 'Mô tả',
                intValue: 'Giá trị số',
                boolValue: 'Giá trị boolean',
                stringValue: 'Giá trị chuỗi',
            }
        },
        "linking-apps": {
            name: 'Ứng dụng liên kết',
            fields: {
                name: 'Tên ứng dụng',
                href: 'Đường dẫn liên kết'
            }
        },
        reportAttendanceInOut: {
            name: 'Báo cáo vào ra',
        },
        reportStudentAttendance: {
            name: 'Báo cáo điểm danh học sinh'
        },
        guestReport: {
            name: 'Báo cáo khách vãng lai'
        },
        eventReport: {
            name: 'Báo cáo điểm danh sự kiện'
        },
        diligenceReport: {
            name: 'Báo cáo chuyên cần'
        },
        presentDeputyReport: {
            name: 'Báo cáo đại biểu có mặt'
        },
        semiBoardingAbsentReport: {
            name: 'Báo cáo điểm danh vắng bán trú'
        },
        semiBoardingMissingCardReport: {
            name: 'Báo cáo điểm danh không thẻ bán trú'
        },
        dateKeepingReport: {
            name: 'Báo cáo điểm danh theo ngày'
        },
        busAttendanceReport: {
            name: 'Báo cáo điểm danh xe bus'
        },
        semiBoardingMissingReport: {
            name: 'Báo cáo không nhận suất ăn'
        },
        mealCheckingReport: {
            name: 'Báo cáo nhận suất ăn'
        },
        weeklyReport: {
            name: 'Báo cáo tổng hợp'
        },
        reportClazzAttendance: {
            name: 'Báo cáo điểm danh lớp'
        },
        reportSchoolAttendance: {
            name: 'Báo cáo điểm danh trường'
        },
        vnEduStudentsReport: {
            name: 'Danh sách học sinh từ vn_edu'
        },
        promote: {
            name: 'Chuyển lớp học sinh'
        },
        migrateStudent: {
            name: 'Tạo và giữ lớp học sinh'
        },
        customerPermissions: {
            name: 'Cấu hình duyệt dữ liệu điểm danh'
        },
        customerPermission: 'Nguời duyệt dữ liệu điểm danh',
        updateSSCID: {
            name: 'Cập nhật ssc_id'
        },
        schoolFeeMessaging: {
            name: 'Thông báo học phí'
        },
        authorizeAttendances: {
            name: 'Duyệt điểm danh',
            fields: {
                unitId: 'Lớp',
                unitName: 'Lớp',
                present: 'Có mặt tại lớp',
                totalLates: 'TR',
                missingCard: 'KT',
                absentWithPermission: 'CP',
                absentWithoutPermission: 'KP',
                totalOffs: 'OFF',
                totalOnTimes: 'ĐG',
                totalCms: 'CM',
                authorized: 'Duyệt',
                total: 'Tổng',
                time: 'Thời gian bắt đầu'
            }
        },
        authorizedAttendanceHistory: {
            name: 'Cập nhật kết quả điểm danh',
            fields: {
                customerId: 'Mã VinaID',
                customerName: 'Tên',
                unitName: 'Lớp',
                unitId: 'Lớp',
                eventName: 'Sự kiện',
                displaySummaryDate: 'Ngày duyệt',
                displayTimeIn: 'Giờ vào',
                displayStatusIn: 'Trạng thái',
                statusIn: 'Trạng thái',
                sendNotify: 'Gửi thông báo',
                reason: 'Lý do'
            }
        },
        diligenceRanges: {
            name: 'Loại chuyên cần',
            fields: {
                fromPoint: 'Điểm bắt đầu',
                toPoint: 'Điểm kết thúc'
            }
        },
        finalizeAttendanceData: {
            name: 'Chốt dữ liệu điểm danh',
            fields: {
                id: 'Mã số',
                name: 'Tên lớp',
                schoolYearId: 'Năm học',
                finalizeAttendanceDate: 'Ngày chốt điểm danh',
                date: 'Ngày',
                time: 'Giờ',
                schoolYear: 'Năm học'
            }
        },
        finalizeMealRegistration: {
            name: 'Chốt dữ liệu thực đơn bán trú',
            fields: {
                id: 'Mã số',
                name: 'Tên lớp',
                schoolYearId: 'Năm học',
                finalizeMealRegistration: 'Ngày chốt thực đơn',
                createdFinalizeMealRegistrationBy: 'Người chốt thực đơn',
                date: 'Ngày',
                schoolYear: 'Năm học'
            },
        },
        attendanceDetailsChangedHistories: {
            name: 'Lịch sử cập nhật điểm danh',
            fields: {
                changedObjId: 'Kết quả điểm danh',
                lastUpdatedBy: 'Người đổi',
                changedDateTime: 'Thời gian đổi',
                displayOldStt: 'Trạng thái cũ',
                displayNewStt: 'Trạng thái mới',
            }
        },
        dayOffs: {
            name: 'Quản lý ngày nghỉ',
            fields: {
                id: 'ID',
                name: 'Tên',
                displayStartDateTime: 'Thời gian bắt đầu',
                displayEndDateTime: 'Thời gian kết thúc',
            }
        },
        billBatches: {
            name: 'Kế hoạch thu phí',
            fields: {
                month: 'Ngày bắt đầu',
                description: 'Mô tả',
                fullDescription: 'Chi tiết',
                sentNotifyCount: 'Số lần gửi thông báo',
                authStatus: 'Trạng thái',
                dueDate: 'Ngày đến hạn',
                active: 'Kích hoạt',
                priority: 'Độ ưu tiên',
                tenantBankAccountId: 'Tk nhận chuyển khoản (mặc định mã trường)',
            }
        },
        calendarBatches: {
            name: 'TKB/Lịch thi V2',
            fields: {
                name: 'Mô tả',
                type: 'Phân loại',
                schoolYearId: 'Niên khoá',
                startDate: 'Ngày bắt đầu',
                endDate: 'Ngày kết thúc',
                authStatus: 'Trạng thái',
                previewCalendar: 'Xem TKB'
            },
            mauTkb: 'Mẫu TKB',
            mauLichThi: 'Mẫu Lịch thi'
        },
        contributionBills: {
            name: 'Khoản đóng góp',
            fields: {
                customerId: 'Mã VinaID',
                note: 'Mô tả',
                customerName: 'Tên học sinh',
                unitName: 'Tên lớp',
                contributionDate: 'Ngày đóng góp',
                amount: 'Số tiền',
                paymentMethod: 'Phương thức thanh toán',
                status: 'Trạng thái',
            }
        },
        products: {
            name: 'Danh mục phí',
            fields: {
                name: 'Tên danh mục',
                price: 'Đơn giá',
                priority: 'Thứ tự ưu tiên',
                subscriptionType: 'Loại tính',
                enable: 'Sử dụng',
                flexible: 'Linh hoạt',
                printReceipt: 'Xuất hoá đơn'
            }
        },
        billProducts: {
            name: 'Danh mục phí',
            fields: {
                name: 'Tên danh mục',
                price: 'Đơn giá',
                priority: 'Thứ tự ưu tiên',
                subscriptionType: 'Loại tính',
                enable: 'Sử dụng',
                flexible: 'Linh hoạt',
                printType: 'Loại in phiếu',
                limitCreated: 'Số lần tạo tối đa',
                maskName: 'Tên hiển thị'
            }
        },
        contributionProducts: {
            name: 'Danh mục đóng góp',
            fields: {
                name: 'Tên danh mục',
                price: 'Đơn giá',
                priority: 'Thứ tự ưu tiên',
                enable: 'Sử dụng'
            }
        },
        customerProducts: {
            name: 'Cấu hình HS và phí',
            fields: {
                customerName: "Học sinh",
                productIds: 'Danh sách phí'
            }
        },
        unitProducts: {
            name: 'Cấu hình lớp và phí',
            fields: {
                unitName: "Lớp",
                productIds: 'Danh sách phí'
            }
        },
        eventProducts: {
            name: 'Cấu hình sự kiện và phí',
            fields: {
                eventName: "Sự kiện",
                eventIds: "Danh sách sự kiện",
                productIds: 'Danh sách phí'
            }
        },
        billBatchTemplate: {
            name: 'Mẫu kế hoạch thu phí',
            billMonth: 'Kế hoạch tháng',
            thangTinhNgayNghi: 'Tháng tính ngày nghỉ',
            numOfDays: 'Số ngày cần thu'
        },
        contributionBillBatchTemplate: {
            name: 'Mẫu kế hoạch đóng góp'
        },
        certificateBatchTemplate: {
            name: 'Mẫu lô thư mục chứng chỉ'
        },
        listPaidByProducts: {
            name: 'Bảng kê thu theo danh mục',
            unitNumber: 'Mã đơn vị quan hệ',
            accountNumber: 'Số tk chuyển',
            district: 'Kho bạc'
        },
        bangKeTungDanhMuc: {
            name: 'Bảng kê thu từng danh mục',
            billMonth: "Tháng",
        },
        partnerBillViews: {
            name: 'Thanh toán',
            fields: {
                tenantId: 'Trường',
                customerName: 'Học sinh',
                customerId: 'Mã VinaID',
                customerAddress: 'Địa chỉ',
                tenantName: 'Trường',
                unitName: 'Lớp',
                billNo: 'Mã phiếu',
                createdOn: 'Thời gian tạo',
                billTotal: 'Tiền học phí',
                billBalance: 'Cần thanh toán',
                billPaid: 'Đã thanh toán',
                billMonth: 'Hoá đơn tháng',
                billBatchId: 'Kế hoạch thu phí',
            }
        },
        partnerPaymentViews: {
            name: 'In hoá đơn',
            fields: {
                tenantId: 'Trường',
                paymentNo: 'Mã biên lai',
                payerName: 'Người thanh toán',
                payerAddress: 'Địa chỉ',
                customerName: 'Tên học sinh',
                customerId: 'Mã VinaID',
                unitName: 'Lớp',
                billNo: 'Mã hoá đơn',
                billMonth: 'Hoá đơn tháng',
                paymentAmount: 'Tiền thanh toán',
                numOfMonth: 'Số tháng thanh toán',
                paymentDate: 'Ngày thanh toán',
                note: 'Ghi chú',
                electronicBillKey: 'Mã HĐĐT',
                electronicBillNum: 'Số HĐĐT',
                electronicBillDate: 'Ngày HĐĐT'
            }
        },
        billViewEdits: {
            name: 'Sửa phiếu thu',
            fields: {
                customerName: 'Học sinh',
                customerId: 'Mã VinaID',
                customerAddress: 'Địa chỉ',
                unitName: 'Lớp',
                billNo: 'Mã phiếu',
                createdOn: 'Thời gian tạo',
                billTotal: 'Tiền học phí',
                billBalance: 'Số cần thanh toán',
                billPaid: 'Đã thanh toán',
                billMonth: 'Hoá đơn tháng',
                billBatchId: 'Kế hoạch thu phí',
            }
        },
        "bill-item-histories": {
            name: 'Lịch sử cập nhật phiếu thu',
            fields: {
                billNo: 'Mã phiếu',
                billId: 'Mã Bill',
                customerId: 'Mã VinaID',
                customerName: 'Học sinh',
                unitName: 'Lớp',
                billMonth: 'Hoá đơn tháng',
                oldValue: 'Số tiền cũ',
                newValue: 'Số tiền mới',
                lastUpdatedOn: 'Thời gian cập nhật',
                lastUpdatedBy: 'Cập nhật bởi'
            }
        },
        billViewEditByMonth: {
            name: 'Sửa phiếu thu theo tháng',
            fields: {
                customerName: 'Học sinh',
                customerId: 'Mã VinaID',
                customerAddress: 'Địa chỉ',
                unitName: 'Lớp',
                billNo: 'Mã phiếu',
                createdOn: 'Thời gian tạo',
                billTotal: 'Tiền học phí',
                billBalance: 'Số cần thanh toán',
                billPaid: 'Đã thanh toán',
                billMonth: 'Hoá đơn tháng',
                billBatchId: 'Kế hoạch thu phí',
            }
        },
        billViews: {
            name: 'Thanh toán',
            fields: {
                customerName: 'Học sinh',
                customerId: 'Mã VinaID',
                customerAddress: 'Địa chỉ',
                unitName: 'Lớp',
                billNo: 'Mã phiếu',
                createdOn: 'Thời gian tạo',
                billTotal: 'Tiền học phí',
                billBalance: 'Số cần thanh toán',
                billPaid: 'Đã thanh toán',
                billMonth: 'Hoá đơn tháng',
                totalUnpaid: 'Tổng nợ',
                billBatchId: 'Kế hoạch thu phí',
            }
        },
        billItems: {
            name: 'Chi tiết khoản thu',
            fields: {
                productName: 'Tên khoản thu',
                quantity: 'Số lượng',
                price: 'Số tiền',
                discountAmount: 'Miễn giảm',
                subtotal: 'Thành tiền',
            }
        },
        paymentViews: {
            name: 'In hoá đơn',
            fields: {
                paymentNo: 'Mã biên lai',
                payerName: 'Người thanh toán',
                payerAddress: 'Địa chỉ',
                customerName: 'Tên học sinh',
                customerId: 'Mã VinaID',
                unitName: 'Lớp',
                billNo: 'Mã hoá đơn',
                billMonth: 'Hoá đơn tháng',
                paymentAmount: 'Tiền thanh toán',
                numOfMonth: 'Số tháng thanh toán',
                transactionNumber: 'Số giao dịch ck',
                paymentDate: 'Ngày thanh toán',
                gateway: 'Kênh thanh toán',
                note: 'Ghi chú',
                electronicBillKey: 'Mã HĐĐT',
                displayPhieuThu: 'Số PT',
                electronicBillNum: 'Số HĐĐT',
                electronicBillDate: 'Ngày HĐĐT'
            }
        },
        payments: {
            name: 'Thanh toán',
            fields: {
                payerName: 'Tên người thanh toán',
                payerAddress: 'Địa chỉ người thanh toán',
                customerId: 'Tên học sinh',
                paymentAmount: 'Tiền thanh toán',
                remainAmount: 'Tiền còn lại',
            }
        },
        paymentPaidByProduct: {
            name: 'Báo cáo thu theo danh mục',
        },
        studentsHaveRemain: {
            name: 'HS còn dư thanh toán',
        },
        billBatchItemNotify: {
            name: 'In thông báo học phí',
            displayDueDate: 'Hiện ngày đến hạn',
            displayDept: 'Hiện nợ kì trước',
            note: 'Ghi chú'
        },
        billItemNotifyByBatch: {
            name: 'In TB thu theo kế hoạch',
            displayDueDate: 'Hiện ngày đến hạn',
            note: 'Ghi chú'
        },
        "in-tb-hp-gop-ke-hoach": {
            name: 'In TB thu gộp theo kế hoạch',
            displayDueDate: 'Hiện ngày đến hạn',
            month: 'Tháng kế hoạch',
            note: 'Ghi chú',
            reportNote: 'In thông báo, gộp số tiền thanh toán theo các kế hoạch đã chọn. Lưu ý chọn các kế hoạch phù hợp.'
        },
        thongBaoHocPhi: {
            name: 'Gửi thông báo học phí',
            displayDueDate: 'Hiện ngày đến hạn',
            displayDept: 'Hiện nợ kì trước',
            note: 'Ghi chú',
            sendNotify: 'Gởi thông báo'
        },
        tbNhacNo: {
            name: 'Gửi TB nhắc nợ',
            displayDueDate: 'Hiện ngày đến hạn',
            displayDept: 'Hiện nợ kì trước',
            note: 'Ghi chú'
        },
        debtReminderBatches: {
            name: 'Lịch sử nhắc nợ',
            fields: {
                reminderMonth: 'Tháng',
                reminderTime: 'Lần nhắc',
                sentDate: 'Ngày gửi',
                unitId: 'Lớp',
                lastUpdatedBy: 'Người gửi'
            }
        },
        thongKeSmsTheoNguoi: {
            name: 'Thống kê SMS theo người',
            note: 'Ghi chú'
        },
        tbHocPhiTheoKeHoach: {
            name: 'Gửi TB thu theo kế hoạch',
            displayDueDate: 'Hiện ngày đến hạn',
            note: 'Ghi chú'
        },
        schoolYears: {
            name: 'Năm học',
            fields: {
                name: 'Tên',
                enable: 'Hoạt động',
                startDate: 'Ngày bắt đầu',
                endDate: 'Ngày kết thúc'
            }
        },
        paymentByDaysReport: {
            name: 'Báo cáo thu theo ngày',
        },
        paymentSchoolReport: {
            name: 'Báo cáo thanh toán toàn trường',
        },
        exemptionPolicies: {
            name: 'Chính sách miễn giảm',
            fields: {
                name: 'Tên',
                schoolYearId: 'Năm học',
            }
        },
        receiptUsage: {
            name: 'Báo cáo sử dụng hoá đơn',
        },
        exemptionPolicyDetails: {
            name: 'Chi tiết chính sách miễn giảm',
            fields: {
                exemptionPolicyId: 'Miễn giảm',
                productId: 'Học phí',
                reduceType: 'Loại miễn giảm',
                value: 'Giá trị',
            }
        },
        exemptions: {
            name: 'Miễn giảm học sinh',
            fields: {
                customerId: 'Học sinh',
                customerName: 'Học sinh',
                exemptionPolicyIds: 'Miễn giảm',
            }
        },
        rolePrivileges: {
            name: 'Thiết lập quyền',
        },
        bankStatements: {
            name: 'Thanh toán chuyển khoản',
            fields: {
                description: 'Mô tả',
                bankStatementTemplateId: 'Mẫu sao kê',
                authorizedDate: 'Ngày duyệt',
                authStatus: 'Trạng thái'
            }
        },
        bankStatementTemplates: {
            name: 'Mẫu sao kê',
            fields: {
                name: 'Ngân hàng',
                startRowIdx: 'Dòng bắt đầu',
                transactionNumberColIdx: 'Cột số giao dịch',
                descriptionColIdx: 'Cột mô tả',
                amountColIdx: 'Cột ghi có',
                transactionDateColIdx: 'Cột ngày giờ giao dịch',
                dateTimeFormatter: 'Định dạng ngày giờ',
                haveTime: 'Bao gồm giờ trong ngày giao dịch',
                haveTransactionNumber: 'Có số giao dịch',
                formatMoneyAsString: 'Định dạng chữ',
                active: 'Sử dụng'
            }
        },
        "admissions-card-application": {
            name: 'Nhập thông tin tuyển sinh',
            fields: {
                customerName: 'Họ và tên',
                dob: 'Ngày sinh',
                gender: 'Giới tính',
                phone: 'Số điện thoại',
                internalCode: 'Mã học sinh',
                documentNumber: 'Số CCCD'
            }
        },
        "tao-nhanh-thanh-toan": {
            name: 'Tạo nhanh thanh toán',
            fields: {
                billBatchId: 'Kế hoạch thu',
                customerId: 'Họ và tên'
            }
        },
        cardApplicationBatches: {
            name: 'Lô thông tin khách hàng',
            fields: {
                name: 'Mô tả',
                type: 'Loại thẻ',
                schoolYearId: 'Năm học',
                authStatus: 'Trạng thái'
            }
        },
        cardApplicationPhotoBatches: {
            name: 'Upload lô hình theo mã HS/SV',
            fields: {
                name: 'Mô tả',
                type: 'Loại thẻ',
                schoolYearId: 'Năm học',
                authStatus: 'Trạng thái'
            }
        },
        eventBatches: {
            name: 'Lô nhóm dự kiện',
            fields: {
                name: 'Mô tả',
                authStatus: 'Trạng thái'
            }
        },
        studentEnrollmentBatches: {
            name: 'Lô sinh viên',
            fields: {
                name: 'Mô tả',
                authStatus: 'Trạng thái'
            }
        },
        pupilEnrollmentBatches: {
            name: 'Lô học sinh',
            fields: {
                name: 'Mô tả',
                authStatus: 'Trạng thái'
            }
        },
        pupilEnrollmentBatchV2s: {
            name: 'Lô HS không ngày sinh',
            fields: {
                name: 'Mô tả',
                authStatus: 'Trạng thái'
            }
        },
        pupilEnrollmentBatchV3s: {
            name: 'Lô HS V3',
            fields: {
                name: 'Mô tả',
                type: 'Loại',
                createFullDay: 'Tao bán trú',
                authStatus: 'Trạng thái'
            }
        },
        teacherAssignmentBatches: {
            name: 'Lô phân công giáo viên',
            fields: {
                name: 'Mô tả',
                authStatus: 'Trạng thái'
            }
        },
        'customer-internal-codes': {
            name: 'Mã nội bộ',
            fields: {
                customerName: 'Họ và tên',
                cardNumber: 'Mã số thẻ',
                internalCode: 'Mã nội bộ',
                customerId: 'Mã VinaID'
            }
        },
        cardApplications: {
            name: 'Chi tiết thông tin khách hàng',
            fields: {
                status: 'Trạng thái',
                existCustomerId: 'Mã VinaID',
                displayPhone: 'SĐT đại diện',
                customerName: 'Họ tên',
                address: 'Địa chỉ',
                phone: 'Số điện thoại',
                email: 'Email',
                documentNumber: 'Số định danh',
                fatherName: 'Họ tên Cha',
                fatherAddress: 'Địa chỉ Cha',
                fatherPhone: 'SĐT Cha',
                fatherEmail: 'Email Cha',
                fatherNotify: 'Đăng kí thông báo Cha',
                motherName: 'Họ tên Mẹ',
                motherAddress: 'Địa chỉ Mẹ',
                motherPhone: 'SĐT Mẹ',
                motherEmail: 'Email Mẹ',
                motherNotify: 'Đăng kí thông báo Mẹ',
                guardianName: 'Họ tên Người giám hộ',
                guardianAddress: 'Địa chỉ Người giám hộ',
                guardianPhone: 'SĐT Người giám hộ',
                guardianEmail: 'Email Người giám hộ',
                guardianNotify: 'Đăng kí thông báo Người giám hộ',
                dob: 'Ngày sinh',
                pob: 'Nơi sinh',
                gender: 'Giới tính',
                joinDate: 'Ngày vào Tổ chức',
                title: 'Chức vụ',
                notify: 'Thông báo',
                unitId: 'Lớp/Phòng ban',
                reason: 'Lý do',
                cardApplicationBatchId: 'Lô thông tin',
                parentUnitId: 'Trường/Tổ chức',
                internalCode: 'Mã nội bộ',
            }
        },
        cardApplicationNeedConfirms: {
            name: 'Thông tin KH cần xác nhận',
            fields: {
                status: 'Trạng thái',
                existCustomerId: 'Mã VinaID',
                displayPhone: 'SĐT đại diện',
                customerName: 'Họ tên',
                address: 'Địa chỉ',
                phone: 'Số điện thoại',
                email: 'Email',
                documentNumber: 'Số định danh',
                fatherName: 'Họ tên Cha',
                fatherAddress: 'Địa chỉ Cha',
                fatherPhone: 'SĐT Cha',
                fatherEmail: 'Email Cha',
                fatherNotify: 'Đăng kí thông báo Cha',
                motherName: 'Họ tên Mẹ',
                motherAddress: 'Địa chỉ Mẹ',
                motherPhone: 'SĐT Mẹ',
                motherEmail: 'Email Mẹ',
                motherNotify: 'Đăng kí thông báo Mẹ',
                guardianName: 'Họ tên Người giám hộ',
                guardianAddress: 'Địa chỉ Người giám hộ',
                guardianPhone: 'SĐT Người giám hộ',
                guardianEmail: 'Email Người giám hộ',
                guardianNotify: 'Đăng kí thông báo Người giám hộ',
                dob: 'Ngày sinh',
                pob: 'Nơi sinh',
                gender: 'Giới tính',
                employeeId: 'Mã nhân viên',
                joinDate: 'Ngày vào Tổ chức',
                title: 'Chức vụ',
                notify: 'Thông báo',
                unitId: 'Lớp/Phòng ban',
                reason: 'Lý do',
                cardApplicationBatchId: 'Lô thông tin',
                parentUnitId: 'Trường/Tổ chức',
            }
        },
        foods: {
            name: 'Món ăn',
            fields: {
                name: 'Tên',
                type: 'Loại'
            }
        },
        foodMenus: {
            name: 'Thực đơn',
            fields: {
                eatDate: 'Ngày ăn',
                eventId: 'Sự kiện',
                foodIds: 'Món ăn',
                lastUpdatedBy: 'Người thay đổi',
                lastUpdatedOn: 'Thời gian thay đổi'
            }
        },
        foodMenuBatches: {
            name: 'Lô thực đơn',
            fields: {
                description: 'mô tả',
                eventId: 'Sự kiện',
                lastUpdatedBy: 'Người thay đổi',
                lastUpdatedOn: 'Thời gian thay đổi',
                authStatus: 'Trạng thái'
            }
        },
        shiftDailies: {
            name: 'Ca làm việc',
            fields: {
                description: 'Mô tả',
            }
        },
        externalClientCounters: {
            name: 'Quầy',
            fields: {
                name: 'Tên',
            }
        },
        manualAttendance: {
            name: 'Điểm danh thủ công',
            fields: {}
        },
        manualAccessControl: {
            name: 'Kiểm soát vào ra',
            fields: {}
        },
        deptStudentsReport: {
            name: "Học sinh chưa đóng học phí",
        },
        paidFullDayStudentsReport: {
            name: "HS bán trú đã đóng tiền",
            billMonth: "Tháng",
        },
        paidByMonthsReport: {
            name: "HS đã đóng tiền theo tháng"
        },
        inOutReport: {
            name: "Báo cáo vào ra",
            eventId: "Sự kiện",
            unitIds: "Phòng ban",
        },
        studentEnrollmentPromote: {
            name: "Chuyển lớp học sinh",
            oldSchoolYear: "Năm học trước",
            newSchoolYear: "Năm học mới"
        },
        studentEnrollmentMigrate: {
            name: "Tạo và giữ lớp học sinh",
            oldSchoolYear: "Năm học trước",
            newSchoolYear: "Năm học mới"
        },
        baoCaoSuDungBienLai: {
            name: 'Báo cáo sử dụng biên lai',
        },
        soLuongAnBanTru: {
            name: 'Tổng hợp học sinh ăn thực tế (sau duyệt BT)',
            unitId: 'Lớp'
        },
        mealRegistration1: {
            name: 'Tổng hợp suất ăn HS báo lần 1',
            eatDate: 'Ngày ăn'
        },
        mealRegistration2: {
            name: 'Tổng hợp suất ăn HS báo lần 2',
            eatDate: 'Ngày ăn'
        },
        mealRegistration3: {
            name: 'Tổng hợp suất ăn sau duyệt điểm danh sáng',
            eatDate: 'Ngày ăn'
        },
        hsDaDongTienTheoDanhMuc: {
            name: 'HS đã đóng tiền theo danh mục',
        },
        PHKichHoatApp: {
            name: 'Báo cáo PH kích hoạt App VinaID',
        },
        baoCaoTamUngNapThe: {
            name: 'Tạm ứng nạp thẻ',
        },
        baoCaoHoanTamUngNapThe: {
            name: 'Hoàn tạm ứng nạp thẻ',
        },
        baoCaoSoDuTaiKhoan: {
            name: 'Số dư tài khoản',
        },
        baoCaoThanhToanVienPhi: {
            name: 'Báo cáo thanh toán viện phí',
        },
        tongHopBienLaiVaHoaDon: {
            name: 'Tổng hợp biên lai và hoá đơn',
        },
        unpaidBillNotify: {
            name: 'In thông báo nhắc nợ',
            displayDept: 'Hiện nợ kì trước',
            note: 'Ghi chú'
        },
        chiTraTienReport: {
            name: 'Báo cáo chi trả tiền',
        },
        paymentMethod: {
            name: 'Phương thức'
        },
        gender: {
            name: 'Giới tính'
        },
        baoCaoHoaDonBiHuy: {
            name: 'Báo cáo hoá đơn bị huỷ',
        },
        baoCaoBienLaiBiHuy: {
            name: 'BC biên lai bị huỷ',
        },
        tongHopKhoanThuReport: {
            name: 'Tổng hợp khoản thu',
        },
        tongHopDungThe: {
            name: 'Tổng hợp dùng thẻ',
        },
        luotKhamDungThe: {
            name: 'Lượt khám dùng thẻ',
        },
        chiTietHoaDonReport: {
            name: 'Báo cáo chi tiết hoá đơn',
        },
        baoCaoChiTietBienLai: {
            name: 'Báo cáo chi tiết biên lai',
        },
        baoCaoGiaoDichTaiKhoan: {
            name: 'Báo cáo giao dịch tài khoản',
        },
        baoCaoThuHocPhiDoiTacOnline: {
            name: 'Báo cáo Thu học phí đối tác online',
        },
        baoCaoDiemDanhGiaoVien: {
            name: 'Báo cáo điểm danh giáo viên',
        },
        suatAnBanTru: {
            name: 'Báo cáo thực đơn bán trú',
        },
        phieuDiemDanhThang: {
            name: 'Phiếu điểm danh tháng',
        },
        bcDanhSachHS: {
            name: 'Danh sách học sinh',
            showCard: 'Hiển thị số thẻ',
        },
        thongKeLuotRaVao: {
            name: 'Thống kê lượt ra vào',
        },
        thongKeSoLieuDoNhietDo: {
            name: 'Thống kê số liệu đo nhiệt độ',
        },
        chiTietNhietDoCao: {
            name: 'Chi tiết dữ liệu nhiệt độ',
        },
        chiTietNhietDoNv: {
            name: 'Chi tiết dữ liệu nhiệt độ NV',
        },
        baoCaoDiemDanhVang: {
            name: 'Báo cáo điểm danh vắng',
        },
        billAccountBatches: {
            name: 'Cập nhật tài khoản học phi',
            fields: {
                description: 'Mô tả',
                authStatus: 'Trạng thái',
                authorizedDate: 'Ngày duyệt',
                authorizedId: 'Người duyệt'
            }
        },
        billAccounts: {
            name: 'Tài khoản học phí',
            fields: {
                customerId: 'Mã VinaID',
                accountName: 'Tên tài khoản',
                unitName: 'Tên lớp',
                status: 'Trạng thái',
                balance: 'Số dư'
            }
        },
        dsTkDinhDanh: {
            name: 'Danh sách tk định danh',
        },
        dsMaQrCD: {
            name: 'Mã QR cư dân',
        },
        dsMaQrHS: {
            name: 'Mã QR học sinh',
        },
        dsMaQrNv: {
            name: 'Mã QR nhân viên',
        },
        billAccountTransactions: {
            name: 'Giao dịch tài khoản học phí',
            fields: {
                description: 'Mô tả',
                amount: 'Số tiền',
                transactionType: 'Loại giao dịch',
                transactionDate: 'Ngày giờ giao dịch',
                createdBy: 'Người thực hiện',
                billAccountId: 'Tài khoản'
            }
        },
        blocks: {
            name: 'Khu dân cư',
            fields: {
                name: 'Tên',
                parentId: "Tổ chức"
            }
        },
        floors: {
            name: 'Tầng',
            fields: {
                name: 'Tên',
                parentId: "Khu dân cư"
            }
        },
        apartments: {
            name: 'Căn hộ',
            fields: {
                name: 'Tên',
                parentId: "Tầng"
            }
        },
        apartmentViews: {
            name: 'Căn hộ',
            fields: {
                name: 'Tên',
                displayName: 'Tên',
            }
        },
        subjects: {
            name: 'Môn học',
            fields: {
                name: 'Tên',
                priority: 'Thứ tự ưu tiên',
            }
        },
        clazzSubjects: {
            name: 'Lớp và môn học',
        },
        customerSubjectResultTemplate: {
            name: 'Mẫu kết quả học tập',
        },
        customerSubjectResultBatches: {
            name: 'Nhập kết quả học tập',
            fields: {
                description: 'Mô tả',
                unitId: 'Lớp',
                lastUpdatedBy: 'Người cập nhật',
                lastUpdatedOn: 'Thời gian thay đổi',
                authStatus: 'Trạng thái'
            }
        },
        customerSubjectResultBatchV2s: {
            name: 'Nhập KQ học tập v2',
            fields: {
                description: 'Mô tả',
                lastUpdatedBy: 'Người cập nhật',
                lastUpdatedOn: 'Thời gian thay đổi',
                authStatus: 'Trạng thái'
            },
            templateC1: 'Mẫu C1',
            templateC2: 'Mẫu C2',
            templateC3: 'Mẫu C3'
        },
        insuranceBatches: {
            name: 'Lô bảo hiểm',
            fields: {
                name: 'Tên',
                status: 'Trạng thái',
            }
        },
        userChannels: {
            name: 'Thành viên kênh chat',
            fields: {
                channelName: 'Kênh chat',
                displayName: 'Tên hiển thị',
                channelRole: 'Vai trò',
            },
            roles: {
                HOST: "Chủ kênh",
                MEMBER_WRITE: "Có thể gửi tin nhắn",
                MEMBER_READ: "Chỉ xem",
            }
        },
        customerHealthBatches: {
            name: 'Lô sức khoẻ',
            fields: {
                name: 'Mô tả',
                schoolYearId: 'Niên khoá',
                authStatus: 'Trạng thái',
                authId: 'Người duyệt',
                hocKy: 'Học kỳ',
            },
            template: 'Mẫu lô',
        },
        customerHealths: {
            name: 'Thông tin sức khoẻ',
            fields: {
                customerName: 'Họ Tên',
                internalCode: 'Mã định danh Bộ GD&ĐT',
                customerId: 'Mã VinaID',
                unitName: 'Lớp',
                ordinal: 'Lần khám thứ',
                height: 'Chiều cao',
                weight: 'Cân nặng',
                bmi: 'BMI',
                nutritionalClassification: 'Phân loại dinh dưỡng',
                sight: 'Bệnh về mắt',
                earNoseThroat: "Tai mũi họng",
                dentomaxillofacial: "Răng hàm mặt",
                benhTruyenNhiem: 'Bệnh truyền nhiễm',
                benhHoHap: 'Bệnh hô hấp',
                benhNgoaiDa: 'Bệnh ngoài da',
                benhTimMach: 'Bệnh tim',
                taiNan: 'Tai nạn',
                benhCotSong: 'Bệnh cột sống',
                canSwim: 'Biết bơi',
                semesterHealth: 'Sức khỏe học kỳ',
                note: 'Ghi chú',
                lastUpdatedBy: 'Cập nhật bởi'
            }
        },
        customerHealthBatchTemplate: {
            name: 'Mẫu khám sức khoẻ',
        },
        eCardTemplates: {
            name: 'Mẫu thẻ',
            fields: {
                name: 'Mô tả',
                type: 'Loại thẻ',
            }
        },
        profileChangeLogs: {
            name: 'Duyệt thông tin khách hàng',
            fields: {
                customerId: 'Mã VinaID',
                customerName: 'Họ Tên',
                unitName: 'Lớp/Phòng ban',
                parentUnitName: 'Khối/Khoa',
                unitId: 'Lớp/Phòng ban',
                parentUnitId: 'Khối/Khoa',
                dob: 'Ngày sinh',
                gender: 'Giới tính',
                status: 'Trạng thái',
                internalCode: 'Mã nội bộ',
                authId: 'Người duyệt',
                authDate: 'Ngày duyệt',
                createdBy: 'Người tạo',
                createdOn: 'Ngày tạo',
                reason: 'Lý do'
            }
        },
        profileChangeLogReadys: {
            name: 'Tạo yêu cầu cấp thẻ',
            fields: {
                existCustomerId: 'Mã VinaID',
                customerName: 'Họ Tên',
                unitName: 'Lớp/Phòng ban',
                parentUnitName: 'Khối/Khoa',
                unitId: 'Lớp/Phòng ban',
                parentUnitId: 'Khối/Khoa',
                dob: 'Ngày sinh',
                gender: 'Giới tính',
                status: 'Trạng thái',
                internalBatchId: 'Mã lô',
                sentRequestDate: 'Ngày gửi in',
                deliveryDate: 'Ngày giao',
                internalCode: 'Mã nội bộ',
                cardApplicationBatchId: 'Lô thông tin'
            }
        },
        profileChangeLogReady2s: {
            name: 'Xem yêu cầu cấp thẻ',
            fields: {
                existCustomerId: 'Mã VinaID',
                customerName: 'Họ Tên',
                unitName: 'Lớp/Phòng ban',
                parentUnitName: 'Khối/Khoa',
                unitId: 'Lớp/Phòng ban',
                parentUnitId: 'Khối/Khoa',
                dob: 'Ngày sinh',
                gender: 'Giới tính',
                status: 'Trạng thái',
                internalBatchId: 'Mã lô',
                sentRequestDate: 'Ngày gửi in',
                deliveryDate: 'Ngày giao',
                internalCode: 'Mã nội bộ',
                cardApplicationBatchId: 'Lô thông tin'
            }
        },
        profileChangeLogCart: {
            name: 'Danh sách yêu cầu cấp thẻ đã chọn'
        },
        profileChangeLogCartHistory: {
            name: 'Lịch sử yêu cầu cấp thẻ'
        },
        introductionFiles: {
            name: 'File giới thiệu POS',
            fields: {
                description: 'Mô tả',
                enable: 'Sử dụng',
                priority: 'Độ ưu tiên xuất hiện',
                ver: 'Phiên bản'
            }
        },
        teacherClazzes: {
            name: 'Giáo viên bộ môn',
            fields: {
                customerId: 'Giáo viên',
                subject: 'Bộ môn',
                unitId: 'Lớp'
            }
        },
        contributionBillBatches: {
            name: 'Kế hoạch đóng góp',
            fields: {
                month: 'Ngày bắt đầu',
                description: 'Mô tả',
                fullDescription: 'Chi tiết',
                sentNotifyCount: 'Số lần gửi thông báo',
                authStatus: 'Trạng thái',
                dueDate: 'Ngày đến hạn',
                active: 'Kích hoạt',
            }
        },
        customerCertificateBatches: {
            name: 'Lô thư mục chứng chỉ',
            fields: {
                description: 'Mô tả',
                authStatus: 'Trạng thái'
            }
        },
        DSTheDienTu: {
            name: 'DS Thẻ điện tử',
            signTitle: 'Chức vụ người kí',
            signName: 'Họ tên người kí',
            nienKhoa: 'Niên khoá',
            effectiveDate: 'Ngày hết hạn',
            type: 'Loại thẻ',
            imageType: 'Hình thẻ',
            donViQuanLy: 'Đơn vị quản lý tổ chức',
        },
        bcChamCong: {
            name: 'Báo cáo chấm công tháng',
            eventId: 'Sự kiện'
        },
        bcChamThe: {
            name: 'BC chạm thẻ',
        },
        bcTheDienTu: {
            name: 'BC thẻ điện tử',
        },
        soDiemDanh: {
            name: 'Sổ điểm danh',
            eventId: 'Sự kiện'
        },
        uploadTheDienTuSign: {
            name: 'Upload thẻ đã kí',
            signTitle: 'Chức vụ người kí',
            signName: 'Họ tên người kí',
            nienKhoa: 'Niên khoá',
            effectiveDate: 'Ngày hết hạn',
            type: 'Loại thẻ',
            imageType: 'Hình thẻ',
            donViQuanLy: 'Đơn vị quản lý tổ chức',
        },
        bangGioDiemDanh: {
            name: 'Bảng giờ điểm danh',
            enrollmentId: 'Nhân viên',
        },
        diemDanhLopHocPhan: {
            name: 'Điểm danh lớp học phần',
        },
        tongHopDiemDanhHocPhan: {
            name: 'Tổng hợp điểm danh học phần',
        },
        chiTietThuPhiTheoNoiDung: {
            name: 'Chi tiết thu phí nội dung thu',
        },
        bcChiTietPhiVaNguoiThu: {
            name: 'BC Chi tiết nội dung và người thu',
        },
        'help-desk/bcChiTietPhiVaNguoiThu': {
            name: 'BC Chi tiết nội dung và người thu',
            tenantId: 'Tenant'
        },
        bcChiTietThu: {
            name: 'Báo cáo chi tiết thu',
        },
        'exemption-policy-report': {
            name: 'Báo cáo miễn giảm'
        },
        inNhieuHoaDon: {
            name: 'In nhiều hoá đơn và biên lai',
            type: "Loại",
            pageSize: 'Kích cỡ giấy in'
        },
        billBatchItemNotifyV2: {
            name: 'In TB học phí V2',
            displayDueDate: 'Hiện ngày đến hạn',
            note: 'Ghi chú'
        },
        'bill-batch-printing-a6': {
            name: 'In TB học phí A4 (4 phiếu)'
        },
        customerImageBatch: {
            name: 'Lô hình ảnh'
        },
        accessTerminals: {
            name: 'Thiết bị toàn hệ thống',
            fields: {
                name: 'Tên',
                deviceNo: 'Mã thiết bị',
                description: 'Mô tả vị trí',
                sendNotification: 'Gửi thông báo',
                enabled: 'Kích hoạt',
                tenantId: 'Tenant',
            }
        },
        mealRegistrations: {
            name: 'Tổng hợp đăng ký suất ăn',
            fields: {
                customerId: 'Mã VinaID',
                customerName: 'Họ Tên',
                baseUnitName: 'Lớp',
                unitName: 'Lớp BT',
                eatDate: 'Ngày ăn',
                regisFoodMenu: 'HS đăng ký',
                takenFoodMenu: 'GVBT duyệt',
                changedFoodMenu: 'Đã cập nhật',
                changeReason: 'Lý do thay đổi',
            }
        },
        mealRegistrationChangedHistories: {
            name: 'Lịch sử thay đổi đăng ký thực đơn',
            fields: {
                customerName: 'Họ Tên',
                baseUnitName: 'Lớp',
                unitName: 'Lớp BT',
                reason: 'Lý do thay đổi',
                authorizerUserName: 'Người thay đổi',
                oldFoodMenu: 'Thực đơn HS đăng ký',
                newFoodMenu: 'Thực đơn thay đổi',
                changedDateTime: 'Thời gian thay đổi',
            }
        },
        smsMessages: {
            name: 'Tin nhắn SMS',
            fields: {
                excerpt: 'Nội dung mẫu',
                authStatus: 'Trạng thái',
                category: 'Loại sms',
                hocKy: 'Học kỳ',
                forceSms: 'Bắt buộc gửi SMS',
                freeText: 'Nội dung',
                sample: 'Nội dung mẫu',
                result: 'Kết quả',
                lastUpdatedBy: 'Người thay đổi',
                lastUpdatedOn: 'Thời gian thay đổi'
            }
        },
        smsMessageLogs: {
            name: 'Lịch sử SMS',
            fields: {
                customerName: 'Họ Tên',
                sentDate: 'Ngày gửi',
                excerpt: 'Nội dung',
                createdOn: 'Ngày tạo',
                channel: 'Kênh',
                category: 'Loại',
                phone: 'SDT',
                errMessage: 'Mô tả lỗi',
                status: 'Trạng thái',
            }
        },
        smsDashboard: {
            name: 'Thống kê SMS'

        },
        soLuongAnBanTruKeToan: {
            name: 'Số lượng ăn bán trú kế toán'
        },
        tongHopDonXinNghi: {
            name: 'Tổng hợp đơn xin nghỉ'
        },
        donXinNghiHS: {
            name: 'Đơn xin nghỉ của HS'
        },
        bcDuyetNghi: {
            name: 'BC duyệt nghỉ'
        },
        tkbBanTru: {
            name: 'Mẫu TKB bán trú'
        },
        "full-day-calendar-off-template": {
            name: 'Mẫu TKB nghỉ bán trú'
        },
        tongHopDuyetDiemDanh: {
            name: 'Tổng hợp duyệt điểm danh'
        },
        thongKeLuotChamThe: {
            name: 'Thống kê lượt chạm thẻ'
        },
        diemDanhSo: {
            name: 'Điểm danh Sở',
            month: 'Tháng'
        },
        common: {
            fromDate: 'Từ ngày',
            toDate: 'Đến ngày',
            date: 'Ngày',
            month: 'Tháng'
        },
        "full-day-calendar-batch": {
            name: 'Thời khoá biểu bán trú',
            fields: {
                description: 'Mô tả',
                authStatus: 'Trạng thái',
                active: 'Kích hoạt',
            }
        },
        "full-day-calendar-off-batches": {
            name: 'TKB nghỉ bán trú',
            fields: {
                description: 'Mô tả',
                authStatus: 'Trạng thái',
                active: 'Kích hoạt',
            }
        },
        "face/create": {
            name: "Đăng ký khuôn mặt",

        },
        "face": {
            name: "Danh sách khuôn mặt",
            fields: {
                faceId: 'ID',
                name: 'Họ và tên',
                machineBoxId: 'Mã thiết bị'
            }
        },
        "face-remove": {
            name: 'Xóa người dùng khỏi thiết bị'
        },
        "history-face-checkin": {
            name: "Lịch sử checkin"
        },
        "create-attendance": {
            name: 'Tạo dữ liệu điểm danh'
        },
        "student-attendance-book-report": {
            name: 'Báo cáo sổ điểm danh VTT'
        },
        cauHinhCongBoDiem: {
            name: 'Cấu hình công bố điểm',
            hocKy: 'Học kỳ'
        },
        taoDangKySuatAn: {
            name: 'Tạo đăng ký suất ăn'
        },
        bcNguTruaBanTru: {
            name: 'BC ngủ trưa BT'
        },
        bcDiemDanhBanTru: {
            name: 'BC điểm danh BT'
        },
        congBoDiems: {
            name: 'Công bố điểm',
            fields: {
                hocKy: 'Học kỳ',
                unitId: 'Lớp',
                schoolYearId: 'Niên khoá',
                lastUpdatedBy: 'Thay đổi bởi'
            }
        },
        "employee-off-request-report": {
            name: 'Tổng hợp đơn xin nghỉ'
        },
        "employee-off-request-detail-report": {
            name: 'Đơn xin nghỉ của NV'
        },
        kiotImportOrders: {
            name: 'Nhận hàng từ nhà cung cấp'
        },
        kiotOrders: {
            name: 'Giao hàng cho người mua'
        },
        kiotDeliveredOrders: {
            name: 'Đơn hàng đã giao'
        },
        kiotConfigs: {
            name: 'Cấu hình quầy hàng',
            fields: {
                name: 'Tên',
                retailerId: 'Mã nhà cung cấp',
                clientId: 'Client Id',
                clientSecret: 'Client Secret',
                branchId: 'Mã chi nhánh',
                saleChannelId: 'Mã kênh bán',
                type: 'Loại',
                saleTenantIds: 'Tenant cho phép bán',
                priceBookId: 'Mã đơn giá',
                sellerChannel: 'Mã nhà bán',
                categoryId: 'Nhóm sản phẩm',
                fundAccount: 'Số tài khoản nhà bán',
                deliveryNote: 'Ghi chú giao hàng',
                discountInvoice: 'Giảm giá trên hoá đơn',
                numberOfProductPerDay: 'Số sản phẩm mỗi ngày',
                limitPerDay: 'Giới hạn đặt mỗi ngày',
            }
        },
        kiotProducts: {
            name: 'Sản phẩm',
            sync: 'Đồng bộ',
            fields: {
                code: 'Mã sp',
                kiotConfigId: 'Quầy hàng',
                name: 'Tên sp',
            }
        },
        customerInfoBatches: {
            name: 'Lô cập nhật thông tin',
            fields: {
                description: 'Mô tả',
                authStatus: 'Trạng thái',
                lastUpdatedBy: 'Người thay đổi',
                lastUpdatedOn: 'Thời gian thay đổi'
            }
        },
        kiotProductMenus: {
            name: 'Ds sp theo ngày',
            fields: {
                sellDate: 'Ngày bán',
                primaryProductId: 'Sản phẩm chính',
                deliveryTime: 'Giờ giao',
                orderBeforeTime: 'Giớ đặt trước',
                orderBeforeDate: 'Ngày đặt trước',
                cancelBeforeDate: 'Ngày huỷ trước',
                cancelBeforeTime: 'Giờ huỷ trước',
                kiotConfigId: 'Quầy hàng',
                productIds: 'Sản phẩm',
                lastUpdatedBy: 'Người thay đổi',
                lastUpdatedOn: 'Thời gian thay đổi'
            }
        },
        kiotProductMenuBatches: {
            name: 'Lô sp theo ngày',
            fields: {
                description: 'Mô tả',
                kiotConfigId: 'Quầy hàng',
                authStatus: 'Trạng thái',
            }
        },
        relationshipMigrationBatchTemplate: {
            name: 'Mẫu cập nhật quan hệ'
        },
        relationshipMigrationBatches: {
            name: 'Lô cập nhật quan hệ',
            fields: {
                description: 'Mô tả',
                authStatus: 'Trạng thái'
            }
        },
        "cards": {
            name: 'Danh sách thẻ'
        },
        "update-card-template": {
            name: 'Mẫu file mở/khoá thẻ'
        },
        "update-card-history": {
            name: 'Lịch sử mở/khoá thẻ'
        },
        donTuyenSinh: {
            name: 'DS đơn tuyển sinh'
        },
        bcTuyenSinh: {
            name: 'BC tuyển sinh 1'
        },
        admissionCategories: {
            name: 'Ban học',
            fields: {
                name: 'Tên',
                active: 'Sử dụng',
                schoolYearId: 'Niên khoá',
            }
        },
        admissionSubjects: {
            name: 'Môn học đăng ký',
            fields: {
                name: 'Tên',
                active: 'Sử dụng',
                type: 'Loại',
                forced: 'Bắt buộc',
                schoolYearId: 'Niên khoá',
                categoryIds: 'Ban học'
            }
        },
        bankTransferConsolidates: {
            name: 'Đối soát chuyển khoản',
            fields: {
                customerId: 'Mã VinaID',
                customerName: 'Họ Tên',
                gateway: 'Cổng thanh toán',
                amount: 'Số tiền',
                transferDate: 'Thời gian chuyển khoản',
                status: 'Trạng thái',
                actionTime: 'Thời gian thay đổi',
                actionBy: 'Tài khoản thay đổi'
            }
        },
        bankTransferTopupConsolidates: {
            name: 'Đối soát nạp thẻ chuyển khoản',
            fields: {
                customerId: 'Mã VinaID',
                customerName: 'Họ Tên',
                gateway: 'Cổng thanh toán',
                amount: 'Số tiền',
                note: 'Ghi chú',
                transferDate: 'Thời gian chuyển khoản',
                status: 'Trạng thái',
                actionTime: 'Thời gian thay đổi',
                actionBy: 'Tài khoản thay đổi'
            }
        },
        admissionAdditionalServices: {
            name: 'Dịch vụ cộng thêm',
            fields: {
                name: 'Tên',
                defaultValue: 'Mặc định',
                type: 'Loại',
                forced: 'Bắt buộc',
                admissionSubjectId: 'Môn đăng ký'
            }
        },
        admissionReportTemplates: {
            name: 'Mẫu thông tin đơn tuyển sinh',
            fields: {
                title: 'Tiêu đề',
                paragraph1: 'Đoạn 1',
                paragraph2: 'Đoạn 2',
                active: 'Sử dụng'
            }
        },
        disciplineCategories: {
            name: 'Danh mục nề nếp',
            fields: {
                name: 'Mô tả',
                type: 'Loại',
                minusCompetePoint: 'Điểm trừ thi đua',
                plusCompetePoint: 'Điểm cộng thi đua',
                minusConductPoint: 'Điểm trừ hạnh kiểm',
                plusConductPoint: 'Điểm cộng hạnh kiểm',
                attendanceStatus: 'Trạng thái điểm danh',
                active: 'Sử dụng'
            }
        },
        'customer-discipline': {
            name: 'Nề nếp HS/NV',
            fields: {
                units: 'Lớp',
                checkDate: 'Ngày',
                customerId: 'Mã VinaID',
                customerName: 'Họ và tên',
                description: 'Nội dung'
            }
        },
        'unit-discipline': {
            name: 'Nề nếp lớp',
            fields: {
                units: 'Lớp',
                checkDate: 'Ngày',
                unitId: 'Mã VinaID',
                unitName: 'Họ và tên',
                description: 'Nội dung'
            }
        },
        'discipline-report': {
            name: 'BC nề nếp',
            fields: {
                checkDate: 'Ngày',
                unitId: 'Mã VinaID',
                unitName: 'Lớp',
                planName: 'Kế hoạch thi đua',
                minusPoint: 'Điểm trừ',
                plusPoint: 'Điểm cộng',
                remainPoint: 'Tổng điểm',
                summaryDate: 'Ngày'
            }
        },
        bcNeNepTheoLop: {
            name: 'Bc nề nếp theo lớp',
        },
        bcXhThiDua: {
            name: 'Bc XH thi đua',
        },
        disciplinePlans: {
            name: 'Kế hoạch thi đua',
            fields: {
                name: 'Mô tả',
                startDate: 'Ngày bắt đầu',
                daysOfWeek: 'Ngày trong tuần',
                schoolYearId: 'Niên khoá',
                endDate: 'Ngày kết thúc',
                basePoint: 'Điểm hàng ngày',
                active: 'Sử dụng'
            }
        },
        admissionResultTemplates: {
            name: 'Mẫu KQ tra cứu tuyển sinh',
            fields: {
                name: 'Tên',
                content: 'Nội dung',
                active: 'Sử dụng'
            }
        },
        clazzBatches: {
            name: 'Lô lớp học',
            fields: {
                description: 'Mô tả',
                authStatus: 'Trạng thái',
                lastUpdatedOn: 'Cập nhật lúc',
                lastUpdatedBy: 'Người cập nhật',
            }
        },
        "admission-dashboard": {
            name: 'Thống kê tuyển sinh',
        },
        admissionChuyens: {
            name: 'Đơn đăng ký chuyên',
            fields: {
                customerName: 'Họ Tên',
                loaiTrungTuyen: 'Loại trúng tuyển',
                dob: 'Ngày sinh',
                stt: 'STT',
                customerId: ' Mã VinaID',
                sbd: 'SBD',
                diemLy: 'Điểm Lý',
                canSwim: 'Biết bơi và có chứng chỉ bơi',
                diemToan: 'Điểm Toán',
                diemHoa: 'Điểm Hoá',
                diemSinh: 'Điểm Sinh',
                hsGioiTp: 'HSG (Môn)',
                giaiHsGioi: 'Giải',
                monChuyen: 'Môn chuyên',
                diemChuyen: 'Điểm chuyên',
                swimCertificate: 'Chứng chỉ bơi',
                swimPlaceOfCertificate: 'Nơi cấp chứng chỉ bơi',
                swimDateOfCertificate: 'Ngày cấp chứng chỉ bơi',
                nguyenVong1: 'Nguyện vọng 1',
                nguyenVong2: 'Nguyện vọng 2',
                nguyenVong3: 'Nguyện vọng 3',
                lastUpdatedOn: 'Cập nhật lúc',
                lastUpdatedBy: 'Người cập nhật',
                active: 'Sử dụng'
            }
        },
        'admissions': {
            name: 'Đăng ký tuyển sinh',
            fields: {
                sbd: 'SBD',
                stt: 'STT',
                customerId: 'Mã VinaID',
                dob: 'Ngày sinh',
                gender: 'Giới tinh',
                customerPhone: 'SĐT Học sinh',
                monVan: 'Môn Văn',
                monToan: 'Môn Toán',
                monAnh: 'Môn Anh',
                customerName: 'Họ Tên',
                address: 'Địa chỉ',
                fatherName: 'Họ Tên Cha',
                fatherPhone: 'SĐT Cha',
                fatherJob: 'Nghề nghiệp Cha',
                fatherTitle: 'Chức vụ Cha',
                fatherCompany: 'Tên công ty/đơn vị Cha',
                fatherCompanyAddress: 'Địa chỉ công ty/đơn vị Cha',
                motherCompanyAddress: 'Địa chỉ công ty/đơn vị Mẹ',
                guardianCompanyAddress: 'Địa chỉ công ty/đơn vị Giám hộ',
                motherName: 'Họ Tên Mẹ',
                motherPhone: 'SĐT Mẹ',
                motherJob: 'Nghề nghiệp Mẹ',
                motherTitle: 'Chức vụ Mẹ',
                motherCompany: 'Tên công ty/đơn vị Mẹ',
                guardianName: 'Họ Tên Giám hộ',
                guardianPhone: 'SĐT Giám hộ',
                guardianJob: 'Nghề nghiệp Giám hộ',
                guardianTitle: 'Chức vụ Giám hộ',
                guardianCompany: 'Tên công ty/đơn vị Giám hộ',
                nation: 'Dân tộc',
                religion: 'Tôn giáo',
                pob: 'Nơi sinh',
                selectedElectiveIds: 'Môn tự chọn',
                selectedPhysicalIds: 'Môn thể chất',
                selectedGraduationSubjectIds: 'Môn tốt nghiệp',
                internalCode: 'Mã nội bộ',
                "team": "Chức vụ cao nhất đã đảm nhiệm",
                "giftedName2": "Môn / Loại hình",
                "giftedName": "Môn",
                "giftedAchievement": "Thành tích cao nhất",
                "giftedNote": "Thông tin khác",
                "otherGifted": "Năng khiếu khác",
                "insuranceNumber": "Mã số BHYT",
                "otherInsurance": "Đã tham gia BHYT khác",
                "exemption81": "Đối tượng miễn giảm học phí (NĐ81)",
                "exemptionNation": "Miễn giảm học phí theo dân tộc",
                "nationality": 'Quốc tịch',
                "canSwim": 'Biết bơi',
                "swimPlaceOfCertificate": 'Nơi cấp chứng chỉ',
                "swimDateOfCertificate": 'Ngày cấp',
                "oldClazz": 'Đã xong lớp',
                "oldSchoolYear": 'Năm học',
                "oldSchoolName": 'Tên trường',
                "oldSchoolDistrict": 'Quận, Huyện',
                "oldSchoolCity": 'Tỉnh, Thành phố',
                "documentNumber": 'Số CCCD',
                "documentIssueDate": 'Ngày cấp CCCD',
                "documentOrigin": 'Nơi cấp CCCD',
                "hocLuc": 'Học lực',
                "motherDob": 'Năm sinh mẹ',
                "fatherDob": 'Năm sinh cha',
                "guardianDob": "Năm sinh Giám hộ",
                "hanhKiem": "Hạnh kiểm",
                swimCertificate: 'Chứng chỉ bơi',
                lastUpdatedOn: 'Cập nhật lúc',
                lastUpdatedBy: 'Người cập nhật',
                active: 'Sử dụng',
                permanentAddress: 'Địa chỉ thường trú',
                temporaryAddress: 'Địa chỉ tạm trú trú',
                houseNo: 'Số nhà',
                street: 'Đường',
                cluster: 'Tổ/Thôn/Phố',
                ward: 'Phường (xã)',
                district: 'Quận (huyện)',
                city: 'Tỉnh (TP)',
                fatherInfo: 'Thông tin cha',
                motherInfo: 'Thông tin mẹ',
                guardianInfo: 'Thông tin người giám hộ',
                studentInfo: 'Thông tin học sinh'

            }
        },
        bcTrangThaiTuyenSinh: {
            name: 'BC Trạng thái tuyển sinh',
        },
        admissionChangeLogs: {
            name: 'Lịch sử đăng ký tuyển sinh',
            fields: {
                admissionId: 'Id tuyển sinh',
                actionBy: 'Người thay đổi',
                actionTime: 'Thời gian thay đổi',
                stt: 'STT',
                sbd: 'SBD',
                customerName: 'Họ Tên',
                prevAddress: 'Địa chỉ trước',
                prevCustomerPhone: 'SĐT học sinh trước',
                prevFatherName: 'Họ Tên Cha trước',
                prevFatherPhone: 'SĐT Cha trước',
                prevFatherJob: 'Nghề nghiệp Cha trước',
                prevFatherTitle: 'Chức vụ Cha trước',
                prevFatherCompany: 'Công ty Cha trước',
                prevMotherName: 'Họ Tên Mẹ trước',
                prevMotherPhone: 'SĐT Mẹ trước',
                prevMotherJob: 'Nghề nghiệp Mẹ trước',
                prevMotherTitle: 'Chức vụ Mẹ trước',
                prevMotherCompany: 'Công ty Mẹ trước',
                prevGuardianName: 'Họ Tên Giám hộ trước',
                prevGuardianPhone: 'SĐT Giám hộ trước',
                prevGuardianJob: 'Nghề nghiệp Giám hộ trước',
                prevGuardianTitle: 'Chức vụ Giám hộ trước',
                prevGuardianCompany: 'Công ty Giám hộ trước',
                prevPob: 'Nơi sinh trước',
                address: 'Địa chỉ',
                customerPhone: 'SĐT học sinh',
                fatherName: 'Họ Tên Cha',
                fatherPhone: 'SĐT Cha',
                fatherJob: 'Nghề nghiệp Cha',
                fatherTitle: 'Chức vụ Cha',
                fatherCompany: 'Công ty Cha',
                motherName: 'Họ Tên Mẹ',
                motherPhone: 'SĐT Mẹ',
                motherJob: 'Nghề nghiệp Mẹ',
                motherTitle: 'Chức vụ Mẹ',
                motherCompany: 'Công ty Mẹ',
                guardianName: 'Họ Tên Giám hộ',
                guardianPhone: 'SĐT Giám hộ',
                guardianJob: 'Nghề nghiệp Giám hộ',
                guardianTitle: 'Chức vụ Giám hộ',
                guardianCompany: 'Công ty Giám hộ',
                nation: 'Dân tộc',
                religion: 'Tôn giáo',
                prev_nation: 'Dân tộc trước',
                prev_religion: 'Tôn giáo trước',
                pob: 'Nơi sinh',
            }
        },
        bcTuongTacNhanXet: {
            name: 'BC tương tác nhận xét'
        },
        "upload-photo-permissions": {
            name: 'Phân quyền cập nhật ảnh thẻ HS'
        },
        "payment-gateway-configuration-history": {
            name: 'Lịch sử cấu hình cổng thanh toán',
            fields: {
                oldStatus: 'Trạng thái cũ',
                newStatus: 'Trạng thái mới',
                lastUpdatedOn: 'Ngày giờ',
                lastUpdatedBy: 'Người thay đổi'
            }
        },
        'tong-hop-dat-hang': {
            name: 'Tổng hợp đặt hàng'
        },
        'tong-hop-nhan-suat-an': {
            name: 'Tổng hợp nhận suất ăn'
        },
        'bcChamTheNhanSuatAn': {
            name: 'BC chạm thẻ nhận suất ăn'
        },
        'chamTheKhongHopLe': {
            name: 'Chạm thẻ không hợp lệ',
            fromTime: 'Từ giờ',
            toTime: 'Đến giờ',
        }
    },

    // error code
    DATA_INVALID: 'Dữ liệu không hợp lệ hoặc trùng với dữ liệu đã có trên hệ thống'
};
// không để chung vì ảnh hưởng đến translate có sẵn của thư viện
messages.ra.action.close = 'Đóng'
messages.ra.action.upload = 'Nhập dữ liệu'
messages.ra.action.authorize = 'Duyệt'
messages.ra.action.saveAndPay = 'Lưu và thanh toán'
messages.ra.action.saveRefresh = 'Lưu và làm mới'
messages.ra.action.sentNotify = 'Gửi thông báo'
messages.ra.notification.authorized = 'Duyệt thành công'
messages.ra.notification.selectDeviceNo = 'Vui lòng chọn thiết bị'
messages.ra.action.viewDetail = 'Xem chi tiết'
messages.ra.action.payAndPrintConfirm = 'Thanh toán và in xác nhận thu'
messages.ra.action.detail = 'chi tiết'
messages.ra.action.pay = 'Thanh toán'
messages.ra.action.print = 'HĐ'
messages.ra.action.printFile = 'In'
messages.ra.action.printPhieuThu = 'PT'
messages.ra.action.printBienNhan = 'BN'
messages.ra.action.printConfirmPayment = 'Xác nhận thu'
messages.ra.action.destroy = 'Huỷ'
messages.ra.action.printAllQr = 'Toàn bộ QR'
messages.ra.action.printQr = 'QR'
messages.ra.action.downloadTemplate = 'File mẫu'
messages.ra.action.downloadTemplateStudent = 'Mẫu Học sinh'
messages.ra.action.downloadTemplateTuyenSinhCardApplication = 'Mẫu tuyển sinh'
messages.ra.action.downloadTemplateEmpployeeCardApplication = 'Mẫu Nhân viên'
messages.ra.action.downloadTemplateCardApplication = 'Mẫu Học sinh C1'
messages.ra.action.downloadTemplateTeacherAssignment = 'Mẫu phân công chuyên môn'
messages.ra.action.downloadTemplateStudentCardApplication = 'Mẫu Sinh Viên'
messages.ra.action.downloadTemplateEmployee = 'Mẫu Nhân viên'
messages.ra.action.downloadTemplateResident = 'Mẫu Cư dân'


export default messages;
