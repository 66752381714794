import React from 'react';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';

import moment from 'moment';
import { translate } from 'react-admin';

const DateTimeField = ({format, className, source, record = {}, ...props}) => {
  const dateTime = get(record, source);
  const value = dateTime ? moment(dateTime).format(format) : ''

  return (
    <Typography
        component="span"
        variant="body1"
        className={className}
        {...props}
    >
        {value && typeof value !== 'string' ? JSON.stringify(value) : value}
    </Typography>

  )
}

export default translate(DateTimeField)
