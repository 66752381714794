import React from 'react';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid'

import {required, TextInput, translate} from 'react-admin';
import {HocKiInput} from "../../components";

const styles = {
    checkboxGroup: {
        "& > fieldset > div ": {
            flexDirection: 'column',
        }
    }
}

const CustomerSubjectResultBatchFields = ({classes, ...props}) => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput style={{width: '90%'}} source="description"  {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <HocKiInput source="hocKi" validate={required()}
                        {...props} />
        </Grid>
    </React.Fragment>
)

export default compose(
    translate,
    withStyles(styles)
)(CustomerSubjectResultBatchFields)
