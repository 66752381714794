import React, {useCallback, useEffect, useState} from 'react';
import {Pagination, SaveButton, Title} from "react-admin";
import {Card, CardContent, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Button} from '@material-ui/core'
import axiosInstance from "../../api/axios";
import moment from "moment";
import {showErrorNotify} from "../../components";
import debounce from "debounce";
import Grid from '@material-ui/core/Grid'
import env from "../../env";
import {post} from "axios";
import {Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText} from '@material-ui/core/';
import CancelIcon from '@material-ui/icons/Cancel';


const UploadContent = ({data = []}) => {
    return <Table>
        <TableHead>
            <TableRow>
                <TableCell>Họ và tên</TableCell>
                <TableCell>Mã VinaID</TableCell>
                <TableCell>Số thẻ</TableCell>
                <TableCell>Trạng thái thẻ</TableCell>
                <TableCell>Yêu cầu</TableCell>
                <TableCell>Mô tả</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {data.map((item, idx) => (
                <TableRow key={idx}>
                    <TableCell>{item.customerId}</TableCell>
                    <TableCell>{item.customerName}</TableCell>
                    <TableCell>{item.cardNumber}</TableCell>
                    <TableCell>{item.status === 'ACTIVE' ? 'Kích hoạt' : 'Khoá'}</TableCell>
                    <TableCell>{item.requested === 'ACTIVE' ? 'Kích hoạt' : 'Khoá'}</TableCell>
                    <TableCell style={{color: 'red'}}>{item.description}</TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
}
const UploadFile = (props) => {
    const [isOpen, setIsOpen] = useState(false)
    const [saving, setSaving] = useState(false)
    const [data, setData] = useState([])

    const onSave = async () => {
        setSaving(true)
        try {
            await axiosInstance.put(`/api/cards/statuses`, data.filter(item => item.valid))
            window.location.reload()
        } catch (e) {
            showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
        }
    }
    const onClose = () => {
        setIsOpen(false)
    }

    const onFileChange = async (e) => {
        try {
            const file = e.target.files?.[0]
            if(!file)
                return

            const url = `${env.baseURL}/api/cards/upload-status-validation`;
            const token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('file', file);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': token
                }
            }
            const {data} = await post(url, formData, config);
            setData(data)
            setIsOpen(true)
        } catch (e) {
            showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
        }
    }

    return <>
        <Dialog open={isOpen} onClose={onClose} maxWidth={false}>
            <DialogTitle>DANH SÁCH CẬP NHẬT TRẠNG THÁI THẺ</DialogTitle>
            <DialogContent>
                <UploadContent data={data}/>
            </DialogContent>
            <DialogActions>
                <Button label="ra.action.cancel" onClick={onClose} >
                    <CancelIcon />
                </Button>
                <SaveButton saving={saving} disabled={saving} onClick={onSave} />
            </DialogActions>
        </Dialog>
        <label htmlFor="file-upload" style={{border: '1px solid #ccc', padding: '6px 12px', cursor: 'pointer'}}>
            Mở / Khoá thẻ theo lô
        </label>
        <input id="file-upload"
               style={{display: 'none'}}
               type='file'
               accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
               onChange={onFileChange}
        />
    </>;
}

const Toolbar = ({setQ}) => {
    const onFilter = useCallback(debounce((q) => {
        setQ(q)
    }, 300), [])


    return <Grid container direction='row' style={{marginBottom: 16, justifyContent: 'space-between', alignItems: 'end'}}>
        <Grid item>
            <TextField id="standard-basic" label="Tìm kiếm" variant="standard" onChange={(e) => onFilter(e.target.value)}/>
        </Grid>
        <Grid item>
            <UploadFile />
        </Grid>
    </Grid>
}

const CardList = ({history, location}) => {
    const params = Object.fromEntries(new URLSearchParams(location.search))

    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [q, setQ] = useState(params.q || '')
    const [totalElements, setTotalElements] = useState(0)

    useEffect(() => {
        onFilter(page, perPage, q)
    }, [page, perPage, q])

    const onFilter = (page, size, q = '') => {
        history.replace(`/cards?page=${page}&size=${size}&q=${q}`)
        axiosInstance.get(`/api/cards?page=${page - 1}&size=${size}&q=${q}`)
            .then(res => {
                const {content = [], page = {}} = res.data
                setData(content)
                setTotalElements(page.totalElements)
            })
    }

    const onPerPageChange = (pageSize) => {
        setPerPage(pageSize)
    }

    const onPageChange = (page) => {
        setPage(page)
    }

    const onUpdateStatus = async (cardNumber, status) => {
        const beforeUpdateData = [...data]
        try {
            setData(prev => prev.map(item => ({
                ...item,
                cardStatusId: item.cardNumber === cardNumber ? status : item.cardStatusId
            })))
            await axiosInstance.put(`/api/cards/${cardNumber}/status`, status)
        } catch (e) {
            setData(beforeUpdateData)
            showErrorNotify(e.message)
        }
    }

    return (
        <React.Fragment>
            <Card style={{overflowY: 'auto'}}>
                <Title title="resources.cards.name"/>
                <CardContent>
                    <Toolbar setQ={setQ}/>
                    <Table padding='none'>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{padding: 8}}>Mã VinaID</TableCell>
                                <TableCell>Họ và tên</TableCell>
                                <TableCell>Mã nội bộ</TableCell>
                                <TableCell>Lớp / Phòng Ban</TableCell>
                                <TableCell>Số thẻ</TableCell>
                                <TableCell>Ngày cấp thẻ</TableCell>
                                <TableCell>Trạng thái thẻ</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map(item => (
                                <TableRow key={item.cardNumber}>
                                    <TableCell style={{padding: 8}} width={80}>{item.customerId}</TableCell>
                                    <TableCell>{item.customerName}</TableCell>
                                    <TableCell>{item.internalCode}</TableCell>
                                    <TableCell>{item.unitName}</TableCell>
                                    <TableCell width={160}>{item.cardNumber}</TableCell>
                                    <TableCell width={100}>
                                        {moment(item.issueDate).format('DD/MM/YYYY')}
                                    </TableCell>
                                    <TableCell>{item.cardStatusId === 'ACTIVE' ? 'Kích hoạt' : 'Khoá'}</TableCell>
                                    <TableCell width={130}>
                                        <Tooltip title={item.cardStatusId === 'DEACTIVE' && item.cardLockedFrom === 'INTERNAL' ? 'Thẻ bị khoá bởi hệ thống' : ''} placement="top-start">
                                            <div>
                                                <Button size='small' color="primary"
                                                    disabled={item.cardStatusId === 'DEACTIVE' && item.cardLockedFrom === 'INTERNAL'}
                                                    onClick={() => onUpdateStatus(item.cardNumber, item.cardStatusId === 'ACTIVE' ? 'DEACTIVE' : 'ACTIVE')}>
                                                    {item.cardStatusId === 'ACTIVE' ? 'Khoá thẻ' : 'Kích hoạt thẻ'}
                                                </Button>
                                            </div>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CardContent>
                <Pagination page={page} setPage={onPageChange} perPage={perPage} setPerPage={onPerPageChange} total={totalElements}/>
            </Card>
        </React.Fragment>
    )
};

export default CardList;
