import React from 'react';
import {CardActions, CreateButton, Datagrid, DatagridBody, Filter, List, TextField, TextInput} from 'react-admin';
import {BillAuthStatusField, DownloadFileContentButton, EditButtonWithAuthStatus} from "../../components";
import DeleteBillBatchtButton from "../../components/DeleteBillBatchButton";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const CustomDatagridRow = ({record, resource, id, children, basePath}) => (
    <TableRow key={id}>
        {React.Children.map(children, field => (
            <TableCell style={{width: field.props.width, padding: '4px 10px 4px 10px'}}
                       key={`${id}-${field.props.source}`}>
                <span style={{height: 'auto'}}>
                    {React.cloneElement(field, {
                        record,
                        basePath,
                        resource,
                    })}
                </span>

            </TableCell>
        ))}
    </TableRow>
);

const CustomDatagridBody = props => <DatagridBody {...props} row={<CustomDatagridRow/>}/>;
const CustomDatagrid = props => <Datagrid {...props} body={<CustomDatagridBody/>}/>;

const FullDayCalendarBatchFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

const PostActions = ({
                         bulkActions,
                         basePath,
                         displayedFilters,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter,
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {/* Add your custom actions */}
        <CreateButton basePath={basePath}/>
    </CardActions>
);

export const FullDayCalendarOffBatchList = props => (
    <List filters={<FullDayCalendarBatchFilter/>} {...props} actions={<PostActions/>} bulkActionButtons={false}
          exporter={false}>
        <CustomDatagrid>
            <TextField source="id"/>
            <TextField source="description" style={{width: 400}}/>
            <BillAuthStatusField source="authStatus"/>
            <DownloadFileContentButton/>
            <EditButtonWithAuthStatus/>
            <DeleteBillBatchtButton/>
        </CustomDatagrid>
    </List>
);

export default FullDayCalendarOffBatchList
