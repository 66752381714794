import React from 'react';
import {Datagrid, Filter, List, ReferenceField, SelectInput, TextField, TextInput} from 'react-admin';

import {schoolYears} from '../../components';
import {ClazzTypeField} from "../../components/ClazzType";

const ClazzFilter = (props) => (
    <Filter {...props}>
        <SelectInput label="resources.schoolYears.name" source="schoolYear" choices={schoolYears}
                     alwaysOn allowEmpty={false}
        />
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const ClazzList = ({...props}) => (
        <List filters={<ClazzFilter/>}
              filterDefaultValues={{schoolYear: (schoolYears && schoolYears.length > 0) ? schoolYears[0].id : null}} {...props}
              exporter={false}>
            <Datagrid rowClick="edit">
                <TextField source="id"/>
                <TextField source="name"/>
                <ClazzTypeField source="clazzType"/>
                <TextField source="note"/>

                <ReferenceField source="parentId" reference="grades" allowEmpty>
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField source="homeroomTeacher" reference="employeeEnrollments" allowEmpty>
                    <TextField source="customerName"/>
                </ReferenceField>
            </Datagrid>
        </List>
    )
;

export default ClazzList;
