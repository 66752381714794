import VduGatewayConfigurationIcon from '@material-ui/icons/ViewList';

import VduGatewayConfigurationList from './VduGatewayConfigurationList';
import VduGatewayConfigurationCreate from './VduGatewayConfigurationCreate';
import VduGatewayConfigurationEdit from './VduGatewayConfigurationEdit';

export default {
  list: VduGatewayConfigurationList,
  create: VduGatewayConfigurationCreate,
  edit: VduGatewayConfigurationEdit,
  icon: VduGatewayConfigurationIcon,
};
