import React from 'react';
import {Datagrid, Filter, List, TextField, TextInput} from 'react-admin';

const DayOffFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const DayOffList = props => (
  <List title={'Danh sách ngày nghỉ'} filters={<DayOffFilter />} {...props} exporter={false} >
    <Datagrid rowClick="edit" >
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="displayStartDateTime" />
      <TextField source="displayEndDateTime" />
    </Datagrid>
</List>
);

export default DayOffList
