import React, {useRef, useState} from 'react';
import {DateInput, RadioButtonGroupInput, required, TextInput, Title} from 'react-admin';
import {Card, CardContent, Grid} from '@material-ui/core';
import moment from "moment";
import axios from "../../api/axios";
import {ReportForm, showErrorNotify, showSuccessNotify} from "../../components";


const AdmissionCardApplication = () => {
    const [saving, setSaving] = useState(false)
    const ref = useRef()

    const onSave = async () => {
        setSaving(true)
        try {
            await axios.post('/api/admissions-card-application', ref.current)
            showSuccessNotify('Lưu thành công.')
            setTimeout(() => {
                window.location.reload()
            }, 3000)
        } catch (e) {
            showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
            setSaving(false)
        }
    }

    const onValidate = values => {
        ref.current = values
    }


    const labelPrefix = 'resources.admissions-card-application.fields'
    return (
        <Card>
            <Title title="Nhập thông tin tuyển sinh"/>
            <CardContent>
                <ReportForm htmlReport={onSave}
                            label={'Lưu'}
                            isLoading={saving}
                            validate={onValidate}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <TextInput source='customerName' label={`${labelPrefix}.customerName`}
                                       validate={required()}/>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <DateInput source='dob' label={`${labelPrefix}.dob`}
                                       validate={required()}
                                       inputProps={{max: moment().format('YYYY-MM-DD')}}
                            />

                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <RadioButtonGroupInput source="gender"
                                                   label={`${labelPrefix}.gender`}
                                                   choices={[{id: 'MALE', name: 'Nam'}, {id: 'FEMALE', name: 'Nữ'}]}
                                                   options={{style: {flexDirection: 'row'}}}
                                                   validate={required()}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextInput source='guardianPhone' validate={required()} label={`${labelPrefix}.phone`}/>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextInput source='documentNumber' validate={required()}
                                       label={`${labelPrefix}.documentNumber`}/>
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>

    );
};

export default AdmissionCardApplication
