import React from 'react';
import {
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
  DateField,
  NumberField,
  CardActions,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';
import {PartnerPostQuickPayBillButton} from "../../components";
const iframeStyle = {
    width: '1px', height: '1px',
    visibility: 'hidden',
}
class PartnerBillViewFilter extends React.Component {

  render() {
    return (
      <Filter {...this.props}>
        <ReferenceInput label="resources.partnerPaymentViews.fields.tenantId"
                        source="tenantId" reference="partnerSchools"
                        alwaysOn allowEmpty {...this.props} >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
      </Filter>
    )
  }
}

const PostActions =  () => (
    <CardActions>
        <iframe id="pdf-frame" style={iframeStyle}></iframe>
    </CardActions>
);

export const PartnerBillViewList = props => (
  <List filters={<PartnerBillViewFilter/>} {...props} bulkActionButtons={false} actions={<PostActions/>} >
    <Datagrid rowClick="show">
      <TextField source="billNo"/>
      <TextField source="tenantName"/>
      <TextField source="unitName"/>
      <TextField source="customerId"/>
      <TextField source="customerName"/>
      <DateField source="billMonth" options={{year: 'numeric', month: '2-digit'}}/>
      <NumberField source="billBalance" locales="vi-VI" options={{style: 'currency', currency: 'VND'}}/>
      <PartnerPostQuickPayBillButton label="Hành động"/>
    </Datagrid>
  </List>
);

export default PartnerBillViewList
