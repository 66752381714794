import React from 'react';
import Grid from '@material-ui/core/Grid'
import {AutocompleteInput, DateInput, ReferenceInput, required, TextInput} from 'react-admin';
import {PaymentMethodInput} from "../../components";
import {ContributionBillInput} from "../../components/ContributionBillStatus";

const ContributionBillFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="customerId" reference="customers" validate={required()} allowEmpty {...props} >
                <AutocompleteInput optionText="displayName"/>
            </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6}>
            <DateInput source="contributionDate" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput type="number" source="amount" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="note" validate={required()} {...props} />
        </Grid>

        <Grid item xs={12} sm={6}>
            <PaymentMethodInput source="paymentMethod" validate={required()}
                                {...props} />
        </Grid>
    </React.Fragment>
)

export default ContributionBillFields
