import React from 'react';
import {
  ChipField,
  Datagrid,
  Filter,
  List,
  SingleFieldList,
  TextField,
  TextInput,
  ReferenceArrayField
} from 'react-admin';

const CustomerProductFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const CustomerProductList = props => (
  <List filters={<CustomerProductFilter />} {...props} exporter={false} >
    <Datagrid rowClick="edit" >
      <TextField source="customerName" sortBy="customer_id" />
      <ReferenceArrayField reference="billProducts" source="productIds">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
</List>
);

export default CustomerProductList
