import React from 'react';
import Grid from '@material-ui/core/Grid'
import {
    AutocompleteArrayInput,
    BooleanInput,
    LongTextInput, NumberInput,
    ReferenceArrayInput,
    required,
    TextInput
} from 'react-admin';
import {KiotTypeInput} from "./KiotType";

const KiotConfigFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} inputProps={{maxLength: 100}} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="retailerId" validate={required()} inputProps={{maxLength: 40}} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="branchId" validate={required()} inputProps={{maxLength: 15}} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="saleChannelId" validate={required()} inputProps={{maxLength: 255}} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="clientId" validate={required()} inputProps={{maxLength: 50}} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="clientSecret" validate={required()} inputProps={{maxLength: 50}} {...props} />
        </Grid>

        <Grid item xs={12} sm={6}>
            <BooleanInput source="discountInvoice" defaultValue={false} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="categoryId" inputProps={{maxLength: 255}} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="priceBookId" inputProps={{maxLength: 50}} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="sellerChannel" inputProps={{maxLength: 50}} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <KiotTypeInput source="type" defaultValue={'DELIVERY_ONLY'} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="fundAccount" inputProps={{maxLength: 15}} {...props} />
        </Grid>

        <Grid item xs={12} sm={6}>
            <LongTextInput style={{width: '90%'}} rows={3} source="deliveryNote"
                           inputProps={{maxLength: 255}} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <ReferenceArrayInput source="saleTenantIds"
                                 reference="tenants" {...props} allowEmpty>
                <AutocompleteArrayInput/>
            </ReferenceArrayInput>
        </Grid>
        <Grid item xs={12} sm={6}>
            <NumberInput defaultValue={0} source="numberOfProductPerDay" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <NumberInput defaultValue={0} source="limitPerDay" validate={required()} {...props} />
        </Grid>
    </React.Fragment>
)

export default KiotConfigFields
