import React, {Component} from 'react';
import {Edit, required, SimpleForm, TextInput} from 'react-admin';
import {CircularProgress} from '@material-ui/core';
import {
    CustomToolbar,
    FileUploadComponent,
    Grid,
    HocKiInput,
    showErrorNotify,
    showInfoNotify,
    showSuccessNotify,
    showWarningNotify
} from '../../components'
import env from "../../env";
import {post} from "axios";
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

export class CustomerSubjectResultBatchEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            batchId: props.id,
            showValidateBtn: false,
            showValidationData: false,
            isLoading: false,
            showUploadFile: true,
            isRedirect: false
        };
        this.onFileUpload = this.onFileUpload.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onFileUpload(e) {
        e.preventDefault(); // Stop form submit
        if (this.state.file == null) {
            return;
        }
        this.setState({
            isLoading: true
        });
        showInfoNotify("Đang xử lí file, vui lòng chờ trong giây lát!");
        this.fileUpload(this.state.file).then((res) => {
            this.setState({
                isLoading: false,
            })
            if (res.data && res.data.size > 0) {
                showSuccessNotify("Xử lý file thành công. Vui lòng kiểm tra file kết quả.");
                let fileNameFromSv = res.headers['attachmentfilename'];
                let blob = new Blob([res.data]);
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileNameFromSv ? fileNameFromSv : 'ket_qua.zip';
                link.click();
                this.setState({
                    isRedirect: true
                })
            } else {
                showWarningNotify("File không khả dụng, vui lòng thử lại hoặc liên hệ quản trị viên");
            }
        })
            .catch(error => {
                showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
                this.setState({
                    isLoading: false,
                })
            });
    }

    fileUpload(file) {
        const url = `${env.baseURL}/api/customerSubjectResultBatchV2s/validate-item`;
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', this.state.batchId);
        const config = {
            timeout: 600000,
            responseType: 'blob', // important
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': token
            }
        }
        return post(url, formData, config)
    }


    onSubmit(e) {
        e.preventDefault();
    }

    render() {
        if (this.state.isRedirect) {
            return <Redirect to='/customerSubjectResultBatchV2s'/>;
        }
        return (
            <Edit {...this.props}>
                <SimpleForm toolbar={<CustomToolbar/>}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <TextInput source="description" style={{width: '90%'}}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <HocKiInput source="hocKi" validate={required()}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        {
                            this.state.showUploadFile && <Grid item xs={12} sm={6}>
                                <FileUploadComponent onFileChanged={(e) => this.setState({file: e.target.files[0]})}
                                                     accept={'.zip'}
                                                     onFileUpload={this.onFileUpload}
                                                     showValidateBtn={this.state.showValidateBtn}
                                                     onSubmit={this.onSubmit}
                                />
                                {
                                    this.state.isLoading && <CircularProgress color="secondary"/>
                                }
                            </Grid>
                        }

                    </Grid>
                </SimpleForm>
            </Edit>
        )
    }
}

export default connect(null, null)(CustomerSubjectResultBatchEdit);
