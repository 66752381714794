import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

export const types = [
  { id: 'STUDENT', name: 'Thẻ học sinh' },
  { id: 'EMPLOYEE', name: 'Thẻ Nhân viên/Giáo viên' },
]


export const ECardTypeSelectField = (props) => (
    <SelectField source={props.source} choices={types} {...props} />
)

const ECardTypeSelectInput = ({translate, source, ...props}) => {
  return (
    <SelectInput source={source} choices={types} {...props} />
  )
}

export default ECardTypeSelectInput;
