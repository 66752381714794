import React from 'react';
import {Redirect} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Button, Grid} from '@material-ui/core'
import {BooleanInput, DateInput, required, SelectInput, showNotification, TextInput, translate} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import {DaysOfWeekCheckboxInput, schoolYears,} from "../../components";
import axiosInstance from "../../api/axios";
import TreeCheckBoxUnitInput from "../StudentEnrollment/TreeCheckBoxUnitInput";

const styles = {
    button: {
        margin: 1,
    },
    cancelBtn: {
        marginLeft: 20
    },
    checkboxGroup: {
        "& > div ": {
            flexDirection: 'column',
        }
    },
    customCheckBox: {
        "& > div > label ": {
            width: '100%'
        }

    }
};

const DisciplinePlanFields = props => {
    const [values, setValues] = React.useState({
        isRedirect: false,
        refreshList: false,
        units: [],
    });
    const {defaultValue, save} = props;

    React.useEffect(() => {
        if (!defaultValue.isRedirect) {
            getUnits(defaultValue.schoolYearId);
            setValues({...values})
        } else {
            setValues({isRedirect: true, refreshList: true});
        }
    }, [defaultValue.isRedirect]);

    function changeSchoolYear(schoolYear) {
        props.onDirectChange('schoolYearId', schoolYear)
        getUnits(schoolYear)
    }

    function getUnits(schoolYear) {
        const url = `/api/units/search/filter?clazzWithParents=false&page=0&size=999&sort=id%2CASC&schoolYear=${schoolYear}`;
        return axiosInstance.get(url).then(rs => {
            setValues({...values, units: rs.data.content})
        }, err => {
            props.showNotification(err.message, 'warning');
        });
    }

    function cancel() {
        setValues({isRedirect: true, refreshList: false});
    }

    if (values.isRedirect) {
        return <Redirect to={'/disciplinePlans?refreshView=' + values.refreshList}/>;
    } else {
        return (
            <React.Fragment>
                <Grid item xs={12} sm={6}>
                    <TextInput label="resources.disciplinePlans.fields.name"
                               defaultValue={defaultValue.name}
                               source="name"
                               validate={required()}
                               style={{width: '90%'}}
                               onChange={props.onChange('name')}/>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <BooleanInput source="active"
                                  label="resources.disciplinePlans.fields.active"
                                  onChange={props.onChange('active')}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DateInput source="startDate"
                               label="resources.disciplinePlans.fields.startDate"
                               onChange={(e, v) => props.onDirectChange('startDate', v)}
                               validate={required()}/>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DateInput source="endDate"
                               validate={required()}
                               label="resources.disciplinePlans.fields.endDate"
                               onChange={(e, v) => props.onDirectChange('endDate', v)}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput type={'number'} source="basePoint"
                               label="resources.disciplinePlans.fields.basePoint"
                               validate={required()}
                               onChange={props.onChange('basePoint')}/>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DaysOfWeekCheckboxInput source="daysOfWeek" {...props}
                                             label="resources.disciplinePlans.fields.daysOfWeek"
                                             onChange={(e, v) => props.onDirectChange('daysOfWeek', v)}
                                             className={props.classes.checkboxGroup}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SelectInput source="schoolYearId"
                                 label="resources.disciplinePlans.fields.schoolYearId"
                                 choices={schoolYears}
                                 style={{width: 400}}
                                 onChange={(e, v) => changeSchoolYear(v)}
                                 alwaysOn allowEmpty={false}
                    />
                </Grid>
                <Grid item xs={12} sm={6} style={{maxHeight: '350px', marginTop: 30}}>
                    <label><b>Đơn vị tham gia</b></label>
                    <div style={{maxHeight: '300px', overflow: 'auto', marginTop: 10}}>
                        <TreeCheckBoxUnitInput data={values.units}
                                               expandTopLevel={true}
                                               selected={defaultValue.unitIds}
                                               onChange={props.onChange('tmpUnitIds')}/>
                    </div>
                </Grid>

                <Grid item xs={12} sm={12} style={{marginTop: 15}}>
                    <Button variant="contained"
                            color="primary"
                            style={styles.button}
                            disabled={defaultValue.saveDisabled}
                            onClick={save}>
                        <SaveIcon/> Lưu
                    </Button>
                    <Button variant="contained" style={styles.cancelBtn}
                            onClick={cancel}>
                        <CancelIcon/> Hủy
                    </Button>
                </Grid>
            </React.Fragment>
        )
    }
}

export default compose(translate, connect(null, {showNotification}), withStyles(styles))(DisciplinePlanFields)
