import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {Card, CardContent, CircularProgress, Grid, TextField} from '@material-ui/core'
import {REDUX_FORM_NAME, SimpleForm, Title} from 'react-admin';
import RoleCustomToolbar from "../Role/RoleCustomToolbar";
import axiosInstance from "../../api/axios";
import {
    FileUploadComponent,
    showErrorNotify,
    showInfoNotify,
    showSuccessNotify,
    showWarningNotify
} from "../../components";
import env from "../../env";
import {post} from "axios";
import Swal from "sweetalert2";
import {change} from "redux-form";
import FullDayCalendarBatchValidation from "./FullDayCalendarBatchValidation";

export class FullDayCalendarOffBatchCreate extends React.Component {

    state = {
        description: '',
        enable: true,
        isRedirect: false,
        file: null
    }

    constructor(props) {
        super(props);
        this.state = {
            description: '',
            showValidateBtn: false,
            showValidationData: false,
            validationData: [],
            page: 0,
            rowsPerPage: 25,
            showData: [],
            isLoading: false,
            showUploadFile: true,
        };
        this.onFileUpload = this.onFileUpload.bind(this);
        this.setShowData = this.setShowData.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    changeDescription = (val) => {
        this.setState({description: val});
    }

    changeFile = (e) => {
        this.setState({
            file: e.target.files[0]
        })
    }

    onFileUpload(e) {
        e.preventDefault(); // Stop form submit
        if (!this.validate()) {
            return;
        }
        if (this.state.file == null) {
            return;
        }

        this.setState({
            isLoading: true
        });
        showInfoNotify("Đang xử lí file, vui lòng chờ trong giây lát!");
        const {description} = this.state;
        const batchData = {
            description,
        }
        const url = `/api/full-day-calendar-off-batches`;
        return axiosInstance.post(url, batchData).then(rs => {
            const batchId = rs.data.id
            this.setState({
                batchId: batchId
            })
            this.fileUpload(this.state.file, batchId).then((response) => {
                if (response.data.success) {
                    const responseData = response.data;
                    this.setState({
                        showValidationData: true,
                        showValidateBtn: true,
                        validationData: responseData.data,
                        isLoading: false
                    });
                    // one of record is not valid
                    if (!responseData.valid) {
                        // show noti  fication here
                        showWarningNotify("Có dữ liệu lỗi hoặc chưa đúng thông tin, vui lòng kiểm tra lại thông tin dữ liệu!");
                        this.setState({
                            showValidateBtn: false,
                        });
                    } else {
                        showSuccessNotify("Toàn bộ dữ liệu đều đúng, ấn nút Duyệt để lưu!");
                    }
                    const startIdx = this.state.page * this.state.rowsPerPage;
                    const endIdx = (this.state.page + 1) * this.state.rowsPerPage;
                    this.setShowData(startIdx, endIdx, null);
                } else {
                    this.setState({
                        isLoading: false,
                        showValidationData: false,
                        validationData: []
                    });
                    showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
                }
            })
                .catch(error => {
                    showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
                    this.setState({
                        isLoading: false,
                        showValidationData: false,
                        validationData: []
                    })
                });

        })


    }

    fileUpload(file, batchId) {
        const url = `${env.baseURL}/api/full-day-calendar-off-batches/validate-item`;
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('file', file);
        formData.append('batchId', batchId);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': token
            }
        }
        return post(url, formData, config)
    }

    handleChangePage(event, newPage) {
        const startIdx = newPage * this.state.rowsPerPage;
        const endIdx = (newPage + 1) * this.state.rowsPerPage;
        this.setShowData(startIdx, endIdx);
        this.setState({page: newPage});
    }

    handleChangeRowsPerPage(event) {
        this.setState({rowsPerPage: event.target.value});
        const startIdx = this.state.page * event.target.value;
        const endIdx = (this.state.page + 1) * event.target.value;
        this.setShowData(startIdx, endIdx);
    }

    setShowData(startIdx, endIdx) {
        const data = [];
        let filteredData = this.state.validationData;
        if (endIdx > filteredData.length) {
            endIdx = filteredData.length;
        }
        for (let i = startIdx; i < endIdx; i++) {
            data.push(filteredData[i]);
        }

        this.setState({showData: data});
    }

    onSubmit(e) {
        e.preventDefault();
        const data = this.state.validationData
        const rq = {
            data,
            batchId: this.state.batchId
        };
        if (data.length === 0) {
            showInfoNotify("Không có dữ liệu hợp lệ để duyệt");
            return;
        }
        Swal.fire({
            title: "Bạn chắc chắn muốn duyệt lô học sinh?",
            text: "Học sinh sẽ được tạo mới vào lớp nếu chưa có trong lớp.",
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
            focusConfirm: false,
        })
            .then((result) => {
                if (result.value) {
                    this.setState({
                        isLoading: true
                    });
                    showInfoNotify("Đang duyệt, vui lòng đợi trong giây lát!");
                    this.state.showValidateBtn = false;
                    this.forceUpdate()
                    this.saveBatchItem(rq).then(rs => {
                        if (rs.data.success) {
                            const {dispatch} = this.props;
                            dispatch(change(REDUX_FORM_NAME, 'authStatus', 'AUTHORIZED'));
                            showSuccessNotify("Đã duyệt file thành công!");
                            this.setState({
                                showUploadFile: false,
                                isLoading: false,
                                showValidationData: false,
                                validationData: [],
                                showData: [],
                                isRedirect: true
                            })
                        }
                    })
                        .catch(error => {
                            showErrorNotify("Duyệt file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
                            this.setState({
                                isLoading: false,
                            })
                        });
                }
            });
    }

    saveBatchItem(data) {
        const url = `${env.baseURL}/api/full-day-calendar-off-batches/save-item`;
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': token
            }
        }
        return post(url, data, config)
    }

    save = () => {
        if (!this.validate()) {
            return;
        }
        const {description} = this.state;
        const data = {
            description,
        }

        const url = `/api/full-day-calendar-off-batches`;
        return axiosInstance.post(url, data).then(rs => {
            this.cancel();
        }, err => {
            showWarningNotify(err.message);
        });
    };

    cancel = (e) => {
        this.setState({
            isRedirect: true
        })
    };

    validate = () => {
        const {description} = this.state;
        if (!description) {
            showWarningNotify('Vui lòng nhâp mô tả');
            return false;
        }
        return true;
    }

    render() {
        if (this.state.isRedirect) {
            return <Redirect to='/full-day-calendar-off-batches'/>;
        }
        const {description} = this.state;
        return (
            <React.Fragment>
                <Card>
                    <Title title="Tạo lô TKB nghỉ bán trú"/>
                    <CardContent>
                        <SimpleForm toolbar={<RoleCustomToolbar save={this.save} cancel={this.cancel}/>}
                                    redirect="list">
                            <Grid container spacing={8}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="standard-required"
                                        label="Mô tả"
                                        margin="normal"
                                        required={true}
                                        value={description}
                                        onChange={(e) => this.changeDescription(e.target.value)}
                                    />
                                </Grid>
                                <Grid container spacing={8}>
                                    {
                                        this.state.showUploadFile && <Grid item xs={12} sm={6}>
                                            <FileUploadComponent
                                                onFileChanged={(e) => this.setState({file: e.target.files[0]})}
                                                onFileUpload={this.onFileUpload}
                                                showValidateBtn={this.state.showValidateBtn}
                                                onSubmit={this.onSubmit}
                                            />
                                            {
                                                this.state.isLoading && <CircularProgress color="secondary"/>
                                            }
                                        </Grid>
                                    }

                                    {
                                        this.state.showValidationData && <FullDayCalendarBatchValidation
                                            data={this.state.showData}
                                            rowsPerPage={this.state.rowsPerPage}
                                            page={this.state.page}
                                            handleChangePage={this.handleChangePage}
                                            total={this.state.validationData.length}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}/>
                                    }
                                </Grid>
                            </Grid>
                        </SimpleForm>
                    </CardContent>
                </Card>
            </React.Fragment>
        )
    }
}

export default connect(null, null)(FullDayCalendarOffBatchCreate);
