import React from 'react';
import {downloadFile, ReportForm} from "../../components";
import {Card, CardContent, Grid} from '@material-ui/core';
import {DateInput, required, Title} from 'react-admin';
import env from "../../env";
import moment from 'moment'

class WeeklyReport extends React.Component {

  state = {
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD')
  };

  excelReport = () => {
    downloadFile(this.getReportUrl(), 'bao_cao_tuan.xlsx')
  }

  getReportUrl = () => {
    return `${env.baseURL}/reports/weekly-report?` +
        `&fDate=` + this.state.fromDate +
        `&tDate=` + this.state.toDate;
  }

  validate = (values) => {
    const errors = {};

    // Validate
    if (values.fromDate && values.toDate && (values.fromDate > values.toDate)) {
      errors.toDate = 'To date must be greater than From date'
    }

    // Copy input values to params
    this.setState({
      fromDate: values.fromDate,
      toDate: values.toDate
    })

    return errors;
  }

  render() {
    return (
        <Card>
          <Title title="Báo cáo tuần" />
          <CardContent>
            <ReportForm params={this.state}
                        validate={this.validate}
                        excelReport={this.excelReport}
            >
              <Grid container spacing={8}>
                <Grid item xs={12} sm={6} >
                  <DateInput source="fromDate" validate={required()} {...this.props} />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <DateInput source="toDate" validate={required()} {...this.props} />
                </Grid>
              </Grid>
            </ReportForm>
          </CardContent>
        </Card>
    )
  }
}

export default WeeklyReport;
