import React from 'react';
import Grid from '@material-ui/core/Grid'
import {BooleanInput, LongTextInput, NumberInput, ReferenceInput, required, SelectInput, TextInput} from 'react-admin';

const CustomerHealthFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={3}>
            <ReferenceInput source="unitId" reference="baseUnits">
                <SelectInput source="name" disabled/>
            </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={3}>
            <TextInput source="internalCode" {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={3}>
            <TextInput source="customerId" validate={required()} {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={3}>
            <TextInput source="customerName" validate={required()} {...props} disabled/>
        </Grid>
        <Grid item xs={12} sm={3}>
            <NumberInput source="height" {...props}/>
        </Grid>
        <Grid item xs={12} sm={3}>
            <NumberInput source="weight" {...props}/>
        </Grid>
        <Grid item xs={12} sm={3}>
            <NumberInput source="bmi" {...props} />
        </Grid>
        <Grid item xs={12} sm={3}>
            <TextInput source="nutritionalClassification" {...props} />
        </Grid>
        <Grid item xs={12} sm={3}>
            <TextInput source="sight" {...props} />
        </Grid>
        <Grid item xs={12} sm={3}>
            <TextInput source="earNoseThroat" {...props} />
        </Grid>
        <Grid item xs={12} sm={3}>
            <TextInput source="dentomaxillofacial" {...props} />
        </Grid>
        <Grid item xs={12} sm={3}>
            <TextInput source="benhCotSong" {...props} />
        </Grid>
        <Grid item xs={12} sm={3}>
            <TextInput source="benhTruyenNhiem" {...props} />
        </Grid>
        <Grid item xs={12} sm={3}>
            <TextInput source="benhNgoaiDa" {...props} />
        </Grid>
        <Grid item xs={12} sm={3}>
            <TextInput source="benhTimMach" {...props} />
        </Grid>
        <Grid item xs={12} sm={3}>
            <TextInput source="benhHoHap" {...props} />
        </Grid>
        <Grid item xs={12} sm={3}>
            <TextInput source="taiNan" {...props} />
        </Grid>
        <Grid item xs={12} sm={3}>
            <BooleanInput source="canSwim" {...props} />
        </Grid>

        <Grid container item xs={12}>
            <Grid item xs={12} sm={3}>
                <LongTextInput label='Ghi chú' source='note' multiline rows={5}/>
            </Grid>
        </Grid>
    </React.Fragment>
)

export default CustomerHealthFields
