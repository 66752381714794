import React, {useEffect, useState} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Grid,
} from '@material-ui/core';
import {translate, showNotification} from 'react-admin';
import axiosInstance from "../../api/axios";

const styles = {
  textField: {
    width: 400,
  }
}

let timeout = null;

const ChangePasswordDiaglog = ({translate, classes, ...props}) => {

  const [values, setValues] = React.useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    showPassword: false,
  });
  const {open, close, userName, userId} = props;

  // useEffect(() => {
  //   if (open) {
  //   }
  // }, [open]);

  const handleChange = prop => event => {
    setValues({...values, [prop]: event.target.value});
  };

  function closeDialog() {
    close();
  }

  function changePassword() {
    if (!validChangePswRequest()) {
      return;
    }
    const data = {
      newPsw: values.newPassword,
      currentPwd: values.oldPassword,
      confirmPsw: values.confirmPassword
    }
    let url = `/api/users/change-password`;
    if (userId) {
      url += '?userId=' + userId;
    }
    axiosInstance.put(url, data).then(rs => {
      if (rs.data.success) {
        close();
        props.showNotification('Đổi mật khẩu thành công');
      }
    }, err => {
      close();
      props.showNotification(err.message, 'warning');
    });
  }

  function validChangePswRequest() {
    if (!values.oldPassword) {
      props.showNotification('Phải nhập mật khẩu cũ!', 'warning');
      return false;
    }

    if (!values.newPassword) {
      props.showNotification('Phải nhập mật khẩu mới!', 'warning');
      return false;
    }

    if (values.newPassword.length < 6) {
      props.showNotification('Mật khẩu mới phải lớn hơn 5 ký tự!', 'warning');
      return false;
    }

    if (values.newPassword !== values.confirmPassword) {
      props.showNotification('Xác nhận mật khẩu sai!', 'warning');
      return false;
    }
    return true;
  }

  const handleClickShowPassword = () => {
    setValues({...values, showPassword: !values.showPassword});
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
      <Dialog open={open}
              maxWidth={'sm'}
              scroll={'paper'}
              aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <div>Đổi mật khẩu {userName ? '' : userName}</div>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <FormControl className={classes.textField}>
              <InputLabel htmlFor="standard-adornment-password">Mật khẩu
                cũ</InputLabel>
              <Input
                  id="standard-adornment-password"
                  type={'password'}
                  onChange={handleChange('oldPassword')}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl className={classes.textField}>
              <InputLabel htmlFor="standard-adornment-password">Mật khẩu
                mới</InputLabel>
              <Input
                  id="standard-adornment-password"
                  type={'password'}
                  onChange={handleChange('newPassword')}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl className={classes.textField}>
              <InputLabel htmlFor="standard-adornment-password">Xác nhận mật
                khẩu mới</InputLabel>
              <Input
                  id="standard-adornment-password"
                  type={values.showPassword ? 'text' : 'password'}
                  onChange={handleChange('confirmPassword')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <Visibility/> :
                            <VisibilityOff/>}
                      </IconButton>
                    </InputAdornment>
                  }
              />
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={changePassword} color="primary">
            OK
          </Button>
          <Button onClick={closeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default compose(
    translate,
    withStyles(styles),
    connect(null, {showNotification})
)(ChangePasswordDiaglog)
