import React from 'react';
import {connect} from 'react-redux';
import env from "../env";
import {put} from "axios";
import { showSuccessNotify, showErrorNotify, showInfoNotify} from './ShowNotify'
import {refreshView} from 'react-admin';
import Button from '@material-ui/core/Button';

const styles = {
  button: {
    margin: 1,
    width: 180
  },
}

class AuthorizeCardApplicationButton extends React.Component {

  state = {
    id: null,
    showBtn: false
  };

  constructor(props) {
    super(props);
    if (props.record) {
      this.state.id = props.record.id;
      this.state.showBtn = props.record.authStatus === 'UNAUTHORIZED';
    }
  }

  handleClick = (e) => {
    e.stopPropagation();
    showInfoNotify("Đang gửi yêu cầu mở thẻ. Vui lòng đợi trong giây lát!");
    this.sendBillBatchNotify().then(rs => {
      if (rs.data) {
        showSuccessNotify("Gửi yêu cầu mở thẻ thành công!");
        this.state.showBtn = false;
        this.forceUpdate()
      } else {
        showErrorNotify("Gửi yêu cầu duyệt không thành công. Vui lòng kiểm tra hoặc liên hệ quản trị viên!");
      }
    }, err => {
      showErrorNotify(err.message);
    });
  };

  sendBillBatchNotify() {
    const url = `${env.baseURL}/api/cardApplicationBatches/authorized` ;
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'content-type': 'application/json',
        'Authorization': token
      }
    };
    return put(url, {'batchId': this.state.id}, config);
  }

  render() {
    return (
      <div>
        {this.state.showBtn &&
          <Button color="primary" style={styles.button}
                  onClick={this.handleClick}>
             Gửi yêu cầu
          </Button>
        }
      </div>
    )}

}

export default connect(null, {refreshView})(AuthorizeCardApplicationButton);
