import React from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {Grid} from '@material-ui/core'
import {BooleanInput, Edit, required, showNotification, SimpleForm, TextInput} from 'react-admin';
import {CustomToolbar} from "../../components";
import axiosInstance from "../../api/axios";
import Button from '@material-ui/core/Button';

const style = {
    btnAuthorize: {
        marginLeft: 10
    }
}
const IntroductionFileEdit = (props) => {

    const [file, setFile] = React.useState(null);

    const onFileUpload = (e) => {
        e.preventDefault(); // Stop form submit
        if (file == null) {
            return;
        }
        fileUpload(file).then((response) => {
            props.showNotification('Cập nhật hình thành công');
        })
    };

    const fileUpload = (file) => {
        const formData = new FormData();
        formData.append('file', file)
        formData.append('id', props.id)
        return axiosInstance.post('/api/introductionFiles-file', formData);
    }

    return (
        <Edit {...props}>
            <SimpleForm toolbar={<CustomToolbar/>}>
                <React.Fragment>
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <TextInput source="description" validate={required()} {...props} />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <BooleanInput source="enable" {...props} />
                        </Grid>

                    </Grid>
                </React.Fragment>
                <React.Fragment>
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <TextInput source="priority" validate={required()}  type={'number'} {...props} />
                        </Grid>


                    </Grid>
                </React.Fragment>
                <form style={{width: '100%'}}>
                    <div style={{flexDirection: 'row'}}>
                        <h4>Chọn tệp</h4>
                        <input id="contained-button-file" type="file" accept="video/*,image/*"
                               onChange={(e) => setFile(e.target.files[0])}/>
                        {file &&
                        <Button style={style.btnAuthorize} variant="contained" component="span"
                                onClick={onFileUpload}>
                            Cập nhật tệp
                        </Button>
                        }
                    </div>
                </form>

            </SimpleForm>
        </Edit>
    )
}

export default compose(
    connect(undefined, {showNotification}),
)(IntroductionFileEdit);
