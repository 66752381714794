import React, {Component} from 'react';
import {connect} from 'react-redux';
import {change, submit} from 'redux-form';
import {Button, fetchEnd, fetchStart, refreshView} from 'react-admin';
import env from "../../env";
import Download from '@material-ui/icons/FileDownload';
import {downloadFile} from "../../components";

class BcTkHocPhiBtn extends Component {

  handleClick = () => {
    downloadFile(`${env.baseURL}/reports/bcTkHocPhi`, 'bc_tk_hoc_phi.xlsx')
  };


  render() {
    return (
          <Button onClick={this.handleClick} label={'Báo cáo'}>
            <Download/>
          </Button>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  submit,
  refreshView
};

export default connect(mapStateToProps, mapDispatchToProps)(
    BcTkHocPhiBtn
);