import EventIcon from '@material-ui/icons/ViewList';

import EventList from './EventList';
import EventCreate from './EventCreate';
import EventEdit from './EventEdit';

export default {
  list: EventList,
  create: EventCreate,
  edit: EventEdit,
  icon: EventIcon,
};
