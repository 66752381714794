import React from 'react';
import {Grid} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";
import axiosInstance from "../../api/axios";

const styles = theme => ({
    araContainer: {
        width: '100% !important',
    }
});

const AttendanceResultListHeader = (props) => {

    const [values, setValues] = React.useState({
        totalUnits: '',
        presents: null,
        totalLates: '',
        totalOnTimes: '',
        totalOffs: '',
        totalCms: '',
        totalMissingCards: '',
        totalPermittedAbsents: '',
        totalUnpermittedAbsents: ''
    });

    const {date, time, classes} = props;

    React.useEffect(() => {
        getData();
    }, [date, time]);

    function getData() {
        let url = `/api/authorizeAttendances/summaries`;
        if (time || date) {
            url += '?';
            let appendAnd = false;
            if (time) {
                url += `time=` + encodeURIComponent(time);
                appendAnd = true;
            }
            if (date) {
                if (appendAnd) {
                    url += '&';
                }
                url += `date=` + encodeURIComponent(date);
            }
        }
        axiosInstance.get(url).then(rs => {
            setValues({
                totalUnits: rs.data.totalUnits,
                presents: rs.data.presents,
                totalLates: rs.data.totalLates,
                totalOffs: rs.data.totalOffs,
                totalOnTimes: rs.data.totalOnTimes,
                totalCms: rs.data.totalCms,
                totalMissingCards: rs.data.totalMissingCards,
                totalPermittedAbsents: rs.data.totalPermittedAbsents,
                totalUnpermittedAbsents: rs.data.totalUnpermittedAbsents,
            })
        });
    }

    return (
        <div style={{padding: 10}}>
            <Grid className={classes.araContainer} container spacing={1}>
                <Grid container item xs={12} spacing={12} zeroMinWidth>
                    <Grid item xs={6}>
                        <div>Số lớp: <b>{values.totalUnits}</b></div>
                    </Grid>
                    <Grid item xs={6} style={{textAlign: 'right'}}>
                        <div>Đúng giờ (ĐG): <b>{values.totalOnTimes}</b></div>
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={6}>
                    <Grid item xs={6}>
                        <div>Hiện diện:</div>
                    </Grid>

                    <Grid item xs={6} style={{textAlign: 'right'}}>
                        <div>Đi trễ (TR): <b>{values.totalLates}</b></div>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={6}>
                    <Grid item xs={6}>
                        <div><b>{values.presents ? values.presents : '0/0'}</b></div>
                    </Grid>
                    <Grid item xs={6} style={{textAlign: 'right'}}>
                        <div>Có mặt (CM): <b>{values.totalCms}</b></div>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={6}>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6} style={{textAlign: 'right'}}>
                        <div>Không thẻ (KT): <b>{values.totalMissingCards}</b></div>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={6}>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6} style={{textAlign: 'right'}}>
                        <div>Vắng có phép (CP): <b>{values.totalPermittedAbsents}</b></div>
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={6}>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={8} style={{textAlign: 'right'}}>
                        <div>Vắng không phép (KP): <b>{values.totalUnpermittedAbsents}</b></div>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    );
}

export default withStyles(styles)(AttendanceResultListHeader);
