import React, {useEffect, useState} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {Title} from 'react-admin';
import {ReportForm, schoolYears, showErrorNotify} from '../../components';
import env from "../../env/env";
import {downloadFilePost} from "../../components/FileDownload";
import TreeCheckBoxCustomInput from "../../components/TreeCheckBoxCustomInput";
import cachedApis from "../../api/cachedApis";

const DonTuyenSinh = () => {
    const [state, setState] = useState({
        customerIds: []
    });
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getCustomersWithUnits();
    }, []);

    function getCustomersWithUnits() {
        cachedApis.getCustomerUnits(true, schoolYears[0].id).then(rs => {
            setCustomers(rs);
        })
    }

    const pdfReport = async () => {
        setLoading(true)
        if (state.customerIds.length <= 0) {
            showErrorNotify("Vui lòng chọn học sinh")
            return
        }
        await downloadFilePost(`${env.baseURL}/reports/donTuyenSinh`, 'don_tuyen_sinh.pdf', state.customerIds)
        setLoading(false)
    };

    const validate = (values) => {
        const errors = {};
        setState(values);
        return errors;
    };

    function onStudentSelected(value) {
        const studentIds = [];
        const oldEnrollUnitIds = [];
        value.forEach(i => {
            const temp = "" + i;
            let zeroIdx = temp.indexOf("0000");
            let lastZeroIdx = -1;
            while (zeroIdx >= 0) {
                lastZeroIdx = zeroIdx;
                zeroIdx = temp.indexOf("0000", zeroIdx + 1);
            }
            if (lastZeroIdx != -1 && lastZeroIdx < temp.length - 1) {
                let stdId = Number(temp.substr(lastZeroIdx + 4));
                let unitId = Number(temp.substr(0, lastZeroIdx));
                if (!studentIds.includes(stdId)) {
                    studentIds.push(stdId);
                }
                if (!oldEnrollUnitIds.includes(unitId)) {
                    oldEnrollUnitIds.push(unitId);
                }
            }
        });
        setState(
            {...state, customerIds: studentIds, oldUnitIds: oldEnrollUnitIds});
    }

    return (
        <Card>
            <Title title="resources.donTuyenSinh.name"/>
            <CardContent>
                <ReportForm params={state}
                            validate={validate}
                            pdfReport={pdfReport}
                            isLoading={loading}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6} style={{maxHeight: '450px', paddingRight: '16px'}}>
                            <label style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: '1rem'}}>
                                Chọn Học sinh
                            </label>
                            <div style={{maxHeight: '400px', overflow: 'auto'}}>
                                <TreeCheckBoxCustomInput data={customers}
                                                         selected={state.customerIds}
                                                         expandTopLevel={true}
                                                         onChange={onStudentSelected}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default DonTuyenSinh

