import React from 'react';
import {Datagrid, Filter, List, TextField, TextInput} from 'react-admin';

import {DateTimeField} from '../../components'

const AttendanceFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const AttendanceList = props => (
  <List filters={<AttendanceFilter />} bulkActionButtons={false} {...props} exporter={false}
        perPage={25}>
    <Datagrid rowClick={false} >
      <TextField source="id" />
      {/* <DateField source="attendanceTime" showTime /> */}
      <DateTimeField source="attendanceTime" format="kk:mm DD-MM-YYYY" />
      <TextField source="deviceNo" />
      <TextField source="cardNumber" />
      <TextField source="customerId" />
      <TextField source="customerName" />
        <DateTimeField source="createdOn" format="kk:mm DD-MM-YYYY" />
    </Datagrid>
</List>
);

export default AttendanceList
