import React from 'react';
import {downloadFile, ReportForm} from "../../components";
import {Card, CardContent, Grid} from '@material-ui/core';
import {
  DateInput,
  required,
  Title,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import moment from 'moment'
import env from "../../env";

class SemiBoardingMissingReport extends React.Component {

  state = {
    date: moment().format('YYYY-MM-DD'),
    eventId: ''
  }

  excelReport = () => {
    downloadFile(this.getReportUrl(), 'bao_cao_khong_nhan_suat_an_ban_tru.xlsx')
  }

  getReportUrl = () => {
    return `${env.baseURL}/reports/semi-boarding-report?` +
        `&date=` + this.state.date +
        `&eventId=` + this.state.eventId;
  }

  validate = (values) => {
    const errors = {};

    this.setState({
      date: values.date,
      eventId: values.eventId
    })

    return errors;
  }

  onEventChanged(e, value) {
    this.setState({
      eventId: value
    })
  }

  render() {
    return (
        <Card>
          <Title title="Báo cáo không nhận suất ăn bán trú"/>
          <CardContent>
            <ReportForm params={this.state}
                        validate={this.validate}
                        excelReport={this.excelReport}
            >
              <Grid container spacing={8}>
                <Grid item xs={12} sm={6}>
                  <DateInput source="date"
                             validate={required()} {...this.props} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ReferenceInput source="eventId"
                                  validate={required()}
                                  reference="events"
                                  perPage={50}
                                  filter={{filterPath: 'filterSimple', semiBoardingEventOnly: true, accessControlOnly: false}}
                                  onChange={(e, value) => this.onEventChanged(e,
                                      value)}
                                  sort={{field: 'name', order: 'ASC'}}>
                    <SelectInput source="name"/>
                  </ReferenceInput>
                </Grid>
              </Grid>
            </ReportForm>
          </CardContent>
        </Card>
    )
  }
}

export default SemiBoardingMissingReport;
