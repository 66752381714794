import AccessTerminalIcon from '@material-ui/icons/ViewList';

import AccessTerminalList from './AccessTerminalList';
import AccessTerminalCreate from './AccessTerminalCreate';
import AccessTerminalEdit from './AccessTerminalEdit';

export default {
  list: AccessTerminalList,
  create: AccessTerminalCreate,
  edit: AccessTerminalEdit,
  icon: AccessTerminalIcon,
};
