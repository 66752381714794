import React, {useEffect, useState} from 'react';
import compose from 'recompose/compose';
import {Card, CardHeader, Divider} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {Responsive, translate} from 'react-admin';
import axiosInstance from "../../api/axios";
import CardIcon from "../Dashboard/CardIcon";
import InsertEmoticon from '@material-ui/icons/InsertEmoticon';

const style = theme => ({
    root: {
        flex: 1,
    },
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    iconSmall: {
        height: 50,
        width: 50
    },
    cardSmall: {
        padding: '8px 0',
        overflow: 'inherit',
        textAlign: 'right',
    }
});


const SmsUsage = ({translate, classes, onError}) => {
    const [limit, setLimit] = useState(0);
    const [used, setUsed] = useState(0);

    useEffect(() => {
        getSmsUsage();
    }, []);

    function getSmsUsage() {
        const url = `/api/smsDashboard/smsUsage`;
        axiosInstance.get(url).then(rs => {
            if (rs && rs.data) {
                const data = rs.data
                setUsed(data.used)
                setLimit(data.limit)
            }
        }, err => {
            onError(err.message);
        });
    }

    function renderUI() {
        return (
            <div className={classes.main}>
                <Card className={classes.cardSmall}>
                    <CardHeader title={translate('app.smsDashboard.usage')}/>
                    <CardIcon Icon={InsertEmoticon} bgColor="#33cc33"/>
                    <Divider/>
                    <p style={{marginRight: 20}}>{'Sử dụng: ' + used + ' / ' + limit}</p>
                    <p style={{marginRight: 20}}>{'Còn lại: ' + (limit - used)}</p>
                </Card>
            </div>
        )
    }

    return (
        <Responsive
            xsmall={
                renderUI()
            }
            small={
                renderUI()
            }
            medium={
                renderUI()
            }
        />
    )
};

const enhance = compose(
    withStyles(style),
    translate
);

export default enhance(SmsUsage);
