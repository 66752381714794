import React, {useEffect, useState} from 'react';
import {Title} from 'react-admin';
import {Card, CardContent} from '@material-ui/core';
import axios from "../../api/axios";
import {showErrorNotify} from "../../components";

const AdmissionDashboard = () => {
    const [jobs, setJobs] = useState([])
    const [categories, setCategories] = useState([])
    const [total, setTotal] = useState(0)
    const [type, setType] = useState('')

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        try {
            axios.get('/api/admission-dashboard').then((res) => {
                const data = res.data
                setJobs(data.jobs)
                setCategories(data.categories)
                setTotal(data.total)
                setType(data.type)
            })
        } catch (e) {
            showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
        }
    }
    return (
        <Card>
            <Title title="Thống kê tuyển sinh"/>
            <CardContent>
                {type == 'GDTX' &&
                <>
                    <div style={{marginTop: 16, display: 'flex', flexDirection: 'row'}}>
                      <span style={{
                          fontWeight: 'bold',
                          width: '50px'
                      }}>{total}</span><span>{" hồ sơ"}</span>
                    </div>
                    {categories && categories.map((item, index) => (
                        <div style={{marginTop: 16, display: 'flex', flexDirection: 'row'}}>
                            <span style={{
                                fontWeight: 'bold',
                                width: '50px'
                            }}>{item.count}</span><span>{" " + item.name}</span>
                        </div>
                    ))}
                    {jobs && jobs.map((item, index) => (
                        <div style={{marginTop: 16, display: 'flex', flexDirection: 'row'}}>
                            <span style={{
                                fontWeight: 'bold',
                                width: '50px'
                            }}>{item.count}</span><span>{" " + item.name}</span>
                        </div>
                    ))}
                </>
                }
            </CardContent>
        </Card>

    );
};

export default AdmissionDashboard
