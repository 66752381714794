import MessageIcon from '@material-ui/icons/ViewList';

import MessageList from './SmsMessageList';
import MessageCreate from './SmsMessageCreate';
import MessageEdit from './SmsMessageEdit';

export default {
  list: MessageList,
  create: MessageCreate,
  edit: MessageEdit,
  icon: MessageIcon,
};
