import React from 'react';
import {Edit, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import ResidentEnrollmentFields from './ResidentEnrollmentFields'
import ImageUploadWrapper from "../../components/ImageUploadWrapper";

export const ResidentEnrollmentEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Grid container spacing={8}>
        <ResidentEnrollmentFields />
          <Grid item xs={12} sm={6}>
              <ImageUploadWrapper {...props} url={'/api/photo/enrollment/'}/>
          </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
);

export default ResidentEnrollmentEdit
