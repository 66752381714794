import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Icon from '@material-ui/core/Icon';

const classes = {
    root: {
        width: '100%',
        marginTop: 10,
    },
    paper: {
        marginTop: 3,
        width: '100%',
        overflowX: 'auto',
        marginBottom: 2,
    },
    table: {
        minWidth: 650,
    },
    icon: {
        fontSize: 18,
    },
};

class CardApplicationBatchValidation extends Component {

    render() {
        return (
            <div style={classes.root}>
                <Paper style={classes.paper}>
                    <Table style={classes.table} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>STT</TableCell>
                                <TableCell align="right">Hợp Lệ</TableCell>
                                <TableCell align="right">Mô tả lỗi</TableCell>
                                <TableCell align="right">Mã VinaID (nếu trùng)</TableCell>
                                <TableCell align="right">Trùng SĐT</TableCell>
                                <TableCell align="right">Trùng DDCN</TableCell>
                                <TableCell align="right">Họ Tên</TableCell>
                                <TableCell align="right">Ngày sinh</TableCell>
                                <TableCell align="right">GIới tính</TableCell>
                                <TableCell align="right">SDT</TableCell>
                                <TableCell align="right">CMND/ĐDCN</TableCell>
                                {this.props.type === 'TUYEN_SINH' &&
                                <>
                                    <TableCell align="right">Mã HS</TableCell>
                                    <TableCell align="right">Số báo danh</TableCell>
                                    <TableCell align="right">SDT Người giám hộ</TableCell>
                                    <TableCell align="right">Điểm môn Văn</TableCell>
                                    <TableCell align="right">Điểm môn Toán</TableCell>
                                    <TableCell align="right">Điểm môn Anh</TableCell>
                                </>
                                }
                                {this.props.type === 'STUDENT' || this.props.type === 'HS_C1' &&
                                <>
                                    <TableCell align="right">Mã HS (nội bộ trường)</TableCell>
                                    <TableCell align="right">Mã lớp</TableCell>
                                    <TableCell align="right">Lớp</TableCell>
                                    <TableCell align="right">Mã khối</TableCell>
                                    <TableCell align="right">Khối</TableCell>
                                    <TableCell align="right">Họ tên Cha</TableCell>
                                    <TableCell align="right">SDT Cha</TableCell>
                                    <TableCell align="right">Họ tên Mẹ</TableCell>
                                    <TableCell align="right">SDT Mẹ</TableCell>
                                    <TableCell align="right">Họ tên Người giám hộ</TableCell>
                                    <TableCell align="right">SDT Người giám hộ</TableCell>
                                </>
                                }
                                {this.props.type === 'EMPLOYEE' &&
                                <>
                                    <TableCell align="right">Mã phòng ban</TableCell>
                                    <TableCell align="right">Phòng ban</TableCell>
                                    <TableCell align="right">Chức vụ</TableCell>
                                    <TableCell align="right">Ngày làm việc</TableCell>
                                    <TableCell align="right">Mã Nhân viên</TableCell>
                                </>
                                }
                                {this.props.type === 'RESIDENT' &&
                                <>
                                    <TableCell align="right">Mã căn hộ</TableCell>
                                    <TableCell align="right">Căn hộ</TableCell>
                                    <TableCell align="right">Tầng</TableCell>
                                    <TableCell align="right">Khu dân cư</TableCell>
                                    <TableCell align="right">Chức vụ</TableCell>
                                    <TableCell align="right">File hình</TableCell>
                                </>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.data.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell component="th" scope="row">
                                        {row.order}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.valid &&
                                        <Icon style={classes.icon} className='fa fa-check' color="primary"/>
                                        }
                                        {!row.valid &&
                                        <Icon style={classes.icon} className='fa fa-times' color="error"/>
                                        }
                                    </TableCell>
                                    <TableCell align="right">{row.errorMessage}</TableCell>
                                    <TableCell align="right">{row.customerId}</TableCell>
                                    <TableCell align="right">
                                        {row.samePhone &&
                                        <Icon style={classes.icon} className='fa fa-check' color="primary"/>
                                        }
                                        {row.diffPhone &&
                                        <Icon style={classes.icon} className='fa fa-times' color="error"/>
                                        }
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.sameDdcn &&
                                        <Icon style={classes.icon} className='fa fa-check' color="primary"/>
                                        }
                                        {row.diffDdcn &&
                                        <Icon style={classes.icon} className='fa fa-times' color="error"/>
                                        }
                                    </TableCell>
                                    <TableCell align="right">{row.customerName}</TableCell>
                                    <TableCell align="right">{row.dob}</TableCell>
                                    <TableCell align="right">{row.genderDisplay}</TableCell>
                                    <TableCell align="right">{row.phone}</TableCell>
                                    <TableCell align="right">{row.documentNumber}</TableCell>
                                    {this.props.type === 'STUDENT' || this.props.type === 'HS_C1'&&
                                    <>
                                        <TableCell align="right">{row.internalCode}</TableCell>
                                        <TableCell align="right">{row.unitId}</TableCell>
                                        <TableCell align="right">{row.unitName}</TableCell>
                                        <TableCell align="right">{row.parentUnitId}</TableCell>
                                        <TableCell align="right">{row.parentUnitName}</TableCell>

                                        <TableCell align="right">{row.fatherName}</TableCell>
                                        <TableCell align="right">{row.fatherPhone}</TableCell>
                                        <TableCell align="right">{row.motherName}</TableCell>
                                        <TableCell align="right">{row.motherPhone}</TableCell>
                                        <TableCell align="right">{row.guardianName}</TableCell>
                                        <TableCell align="right">{row.guardianPhone}</TableCell>
                                    </>
                                    }
                                    {this.props.type === 'TUYEN_SINH' &&
                                    <>
                                        <TableCell align="right">{row.internalCode}</TableCell>
                                        <TableCell align="right">{row.sbd}</TableCell>
                                        <TableCell align="right">{row.guardianPhone}</TableCell>
                                        <TableCell align="right">{row.monVan}</TableCell>
                                        <TableCell align="right">{row.monToan}</TableCell>
                                        <TableCell align="right">{row.monAnh}</TableCell>
                                    </>
                                    }
                                    {this.props.type === 'EMPLOYEE' &&
                                    <>
                                        <TableCell align="right">{row.unitId}</TableCell>
                                        <TableCell align="right">{row.unitName}</TableCell>
                                        <TableCell align="right">{row.title}</TableCell>
                                        <TableCell align="right">{row.joinDate}</TableCell>
                                        <TableCell align="right">{row.internalCode}</TableCell>
                                    </>
                                    }
                                    {this.props.type === 'RESIDENT' &&
                                    <>
                                        <TableCell align="right">{row.unitId}</TableCell>
                                        <TableCell align="right">{row.apartmentName}</TableCell>
                                        <TableCell align="right">{row.floorName}</TableCell>
                                        <TableCell align="right">{row.blockName}</TableCell>
                                        <TableCell align="right">{row.title}</TableCell>
                                        <TableCell align="right">{row.imageName}</TableCell>
                                    </>
                                    }
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={this.props.total}
                        rowsPerPage={this.props.rowsPerPage}
                        page={this.props.page}
                        backIconButtonprops={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonprops={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.props.handleChangePage}
                        onChangeRowsPerPage={this.props.onChangeRowsPerPage}
                    />
                </Paper>
            </div>
        );
    }
}

export default CardApplicationBatchValidation;
