import React from 'react';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid'

import { CheckboxGroupInput, ReferenceArrayInput, TextInput, required, translate } from 'react-admin';

const styles = {
  checkboxGroup: {
    "& > fieldset > div ": {
      flexDirection: 'column',
    }
  }
}

const CalendarFields = ({classes, ...props}) => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <TextInput source="name" validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
    </Grid>

    <Grid item xs={12} sm={12} className={classes.checkboxGroup} >
      <ReferenceArrayInput source="defaultDeviceIdList" reference="accessDevices" 
            sort={{ field: 'name', order: 'ASC' }} 
            {...props} 
      >
        <CheckboxGroupInput optionText="name" {...props} />
      </ReferenceArrayInput>
    </Grid>
  </React.Fragment>
)

export default compose(
  translate,
  withStyles(styles)
)(CalendarFields)
