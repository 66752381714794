import React, {useCallback, useEffect, useRef, useState} from 'react';
import compose from 'recompose/compose';
import {connect} from 'react-redux'
import axiosInstance from "../../api/axios";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import {withStyles} from "@material-ui/core/styles";
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import env from "../../env";
import {showErrorNotify, showSuccessNotify, showWarningNotify, TreeCheckboxInput} from "../../components";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import BusinessIcon from '@material-ui/icons/Business';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import EventIcon from '@material-ui/icons/Event';
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SimpleForm,
    TextInput
} from "react-admin";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    dialogPaper: {
        width: '90%',
        maxWidth: '90%',
        minHeight: '75vh',
        maxHeight: '75vh',
    },
    suggestDialog: {
        zIndex: '1 !important',
    },
    flex1: {
        flex: 1
    },
    heading: {
        color: '#006EDC',
        fontSize: 20,
        fontWeight: theme.typography.fontWeightRegular,
    },
    blueColor: {
        color: '#006EDC',
    },
    mainInformation: {
        backgroundColor: '#fff',
        maxWidth: '720px',
        width: '100%',
        height: '85vh'
    },
    bigAvatar: {
        border: '3px solid #fff',
        marginTop: 20,
        width: 140,
        height: 140,
    },
    bgGreen: {
        border: '3px solid #59C965',
        // backgroundColor: '#59C965'

    },
    bgRed: {
        border: '3px solid #E90F0E',
        // backgroundColor: '#E90F0E'
    },
    headerCusName: {
        fontSize: 20,
        marginTop: 10,
        // textAlign: 'center',
        textTransform: 'uppercase'
    },
    bigCusName: {
        textAlign: 'center',
        fontSize: 30,
        paddingTop: 10,
        textTransform: 'uppercase'
    },
    backIcon: {
        position: 'absolute',
        top: 20,
        left: 10,
        cursor: 'pointer',
        fontSize: 40
    },
    bigCusPhoto: {
        justifyContent: 'center',
        alightItems: 'center',
        alignSelf: 'center',
        width: '100%',
        maxWidth: 210,
        maxHeight: 280
    },
    bigCusPhotoGreen: {
        border: '5px solid #59C965',
        width: '90%',
        maxWidth: 210,
        maxHeight: 280
    },
    bigCusPhotoRed: {
        border: '5px solid #E90F0E',
        width: '90%',
        maxWidth: 210,
        maxHeight: 280
    },
    headerCusPhone: {
        fontSize: 20,
        color: '#fff',
        marginTop: 5,
    },
    informationItemLabel: {
        marginBottom: 5,
        fontSize: 16,
        color: 'grey'
    },
    informationItemContent: {
        marginBottom: 5,
        fontSize: 18,
        wordWrap: 'break-word'
    },
    orgImg: {
        position: 'absolute'
    },
    informationItemLabel2: {
        paddingLeft: 40,
        fontSize: 18,
        wordBreak: 'break-word'
    },
    acoIcon: {
        fontSize: 30,
        paddingRight: 10
    },
    evtIcon: {
        fontSize: 20,
        color: '#006EDC'
    },
    evtName: {
        fontSize: 18,
        wordWrap: 'break-word'
    },
    evtAddress: {
        flex: 6,
    },
    evtTime: {
        textAlign: 'right',
        fontSize: 18,
        wordWrap: 'break-word'
    },
    evtDiv: {
        backgroundColor: '#006EDC08',
        marginBottom: 10
    },
    padLeft10: {
        marginLeft: 10,
        marginRight: 10,
    },
    rowMinHeight: {
        minHeight: 70,
        marginTop: 10
    },
    rowMinHeightHeader: {
        minHeight: 50
    },
    width100: {
        width: '100%'
    },
    atdRowHeight: {
        height: 35
    },
    headerText: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    normalText: {
        fontSize: 16,
    }
});

const deli = '?q='
const timeOutViewInMl = 15 * 1000

export const ManualAccessControlList = (props) => {
    const {classes} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [showData, setShowData] = useState(false);
    const [inEventTime, setInEventTime] = useState(false)
    const [photoUrl, setPhotoUrl] = useState('')
    const [cusPhone, setCusPhone] = useState('')
    const [cusName, setCusName] = useState('')
    const [address, setAddress] = useState('')
    const [dob, setDob] = useState('')
    const [email, setEmail] = useState('')
    const [gender, setGender] = useState('')
    const [tenantInfo, setTenantInfo] = useState('')
    const [eventWrapper, setEventWrapper] = useState('')
    const [idNo, setIdNo] = useState('')
    const [isGuestIn, setIsGuestIn] = useState(false);
    const [isGuestOut, setIsGuestOut] = useState(false);
    let guestCardRef = useRef('')
    let timeOutView;

    // for guest
    const [guestCardNumber, setGuestCardNumber] = useState('')
    // const [guestName, setGuestName] = useState('')
    // const [guestIdNo, setGuestIdNo] = useState('')
    // const [workDescription, setWorkDescription] = useState('')
    // const [eventId, setEventId] = useState('')
    // const [contactId, setContactId] = useState('')
    const [guestOut, setGuestOut] = useState(null)
    const [guestForm, setGuestForm] = useState({})
    // let params = {
    //     customerId: 0,
    //     clazzName: '',
    //     relationshipName: '',
    //     relationshipAddress: '',
    //     totalPayment: 0,
    //     bills: [],
    //     remainPaymentAmount: 0,
    //     numOfMonth: 1
    // };
    const [guests, setGuests] = useState([])
    const [attendances, setAttendances] = useState([])

    useEffect(() => {
        getAttendancesHistory()
        initSearchInput()
        initKeyDownHandle()
    }, []);

    useEffect(() => {
        const electron = window.electron || (window.require ? window.require("electron") : null)
        if (electron && electron.ipcRenderer) {
            console.log('init cardNumberData listener')
            electron.ipcRenderer.on('cardNumberData', onDataReceive)
        }
        return function () {
            const electron = window.electron || (window.require ? window.require("electron") : null)
            if (electron && electron.ipcRenderer) {
                console.log('remove listener')
                electron.ipcRenderer.removeListener('cardNumberData', onDataReceive)
            }
        };
    }, [])

    const onDataReceive = useCallback((e, cardNumber) => {
        try {
            const searchInput = document.getElementById("searchInput")
            searchInput.value = cardNumber;
        } catch (e) {

        }
        guestCardRef.current = cardNumber
        submitSearchInputFromListener(cardNumber);
    }, []);

    const initKeyDownHandle = () => {
        var barcode = '';
        var interval;
        document.addEventListener('keydown', function (evt) {
            if (interval) {
                clearInterval(interval);
            }
            if (evt.code === 'Enter') {
                if (barcode) {
                    handleBarcode(barcode);
                }
                barcode = '';
                return;
            }
            if (evt.key !== 'Shift') {
                barcode += evt.key;
            }
            interval = setInterval(() => barcode = '', 20);
        });

        function handleBarcode(scanned_barcode) {
            try {
                const searchInput = document.getElementById("searchInput")
                if (searchInput === document.activeElement) {
                } else {
                    searchInput.value = scanned_barcode;
                }
            } catch (e) {

            }
            submitSearchInputFromListener(scanned_barcode)
        }
    }

    const initSearchInput = () => {
        setTimeout(() => {
            try {
                const searchInput = document.getElementById("searchInput")
                searchInput.focus();
                searchInput.removeEventListener('keydown', keyDownSearchInput)
                searchInput.addEventListener('keydown', keyDownSearchInput);
            } catch (e) {

            }

        }, 300)
    };

    function keyDownSearchInput(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            submitSearchInput()
        }
    }

    function submitSearchInput() {
        const searchInput = document.getElementById("searchInput")
        if (!searchInput || searchInput.value.trim().length < 1) {
            showWarningNotify('Vui lòng nhập ô tìm kiếm')
            return;
        }
        const data = searchInput.value.trim()
        if (data.indexOf(deli) > -1) {
            const str = data.split(deli)
            getUserInfo(str[1])
        } else {
            getUserInfo(data)
        }
    }
    function submitSearchInputFromListener(data) {
        if (data.indexOf(deli) > -1) {
            const str = data.split(deli)
            getUserInfo(str[1])
        } else {
            getUserInfo(data)
        }
    }

    const getUserInfo = (code) => {
        setIsLoading(true)
        resetGuestInfo()
        const data = {
            name: code
        }
        axiosInstance.post(`/api/mobile/showInfo`, data)
            .then(res => {
                const searchInput = document.getElementById("searchInput")
                searchInput.value = ''
                searchInput.focus();
                setIsLoading(false)
                const data = res.data
                if (data.guestIn) {
                    setIsGuestIn(true)
                    const guestCardInput = document.getElementById("guestCardNumber")
                    guestCardInput.value = guestCardRef.current
                    setGuestCardNumber(guestCardRef.current)
                    return;
                }
                if (data.cardGuestOut) {
                    setIsGuestOut(true)
                    setGuestOut(data.guestOut)
                    return;
                }

                setShowData(data.valid)
                if (data.valid) {
                    setInEventTime(data.inEventTime)
                    if (data.photoUrl) {
                        setPhotoUrl(env.baseURL + data.photoUrl)
                    }
                    setCusPhone(data.phone)
                    setCusName(data.customerName)
                    setAddress(data.address)
                    setEmail(data.email)
                    setDob(data.dob)
                    setGender(data.gender)
                    setIdNo(data.idNo)
                    setTenantInfo(data.tenant)
                    setEventWrapper(data.eventInfoWrapper)
                    sendManualAttendance(data.customerId, data.cardNumber)
                    timeOutView = setTimeout(() => {
                        handleBackClick()
                    }, timeOutViewInMl)
                } else {
                    showWarningNotify('Dữ liệu không hợp lệ, vui lòng thử lại.')
                }
            }, err => {
                const searchInput = document.getElementById("searchInput")
                searchInput.value = ''
                searchInput.focus();
                setIsLoading(false)
                showErrorNotify(err.message)
            })
    };

    const sendManualAttendance = (customerId, cardNumber) => {
        const dataPost = {
            data: [{
                customerId,
                cardNumber,
                dateTime: moment().format('YYYYMMDDHHmmss')
            }]
        };
        axiosInstance.post('/api/manualAccessControl/bulk-upload', dataPost).then(res => {
            showSuccessNotify('Điểm danh thành công')
        }, err => {
            showErrorNotify(err.message)
        });
    };

    const downLoadGuide = () => {
        window.open(env.baseURL + '/files/templates/huong_dan_app_VinaID.zip')
    }

    const handleBackClick = () => {
        getAttendancesHistory()
        setGuestCardNumber(null)
        guestCardRef.current = ''
        resetGuestInfo()
        initSearchInput()
    }

    const resetGuestInfo = () => {
        if (timeOutView) {
            clearTimeout(timeOutView)
        }

        setGuestForm({})
        setGuestOut(null)
        setIsGuestIn(false)
        setIsGuestOut(false)
        setShowData(false)
        setInEventTime(false)
        setPhotoUrl('')
        setCusPhone('')
        setCusName('')
        setAddress('')
        setDob('')
        setEmail('')
        setGender('')
        setTenantInfo('')
        setEventWrapper('')
    }

    const validate = (values) => {
        const errors = {};
        // Copy input values to params
        // setGuestName(values.guestName)
        // setGuestIdNo(values.guestIdNo)
        // setEventId(values.eventId)
        // setContactId(values.contactId)
        // setWorkDescription(values.workDescription)
        setGuestForm(values)

        return errors;
    };

    const handleSubmitCreateGuest = () => {
        if (!guestForm.guestName || !guestCardNumber) {
            showWarningNotify('Vui lòng nhập đủ thông tin yêu cầu');
            return;
        }
        setIsLoading(true)
        const data = {
            cardNumber: guestCardNumber,
            name: guestForm.guestName,
            idNo: guestForm.guestIdNo,
            workDescription: guestForm.workDescription,
            contactId: guestForm.contactId,
            eventIds: guestForm.eventIds
        };
        const url = `/api/guests`;
        axiosInstance.post(url, data).then(rs => {
            setIsLoading(false)
            if (rs.status === 200) {
                showSuccessNotify("Lưu Khách thành công!");
                handleBackClick()
            } else {
                showErrorNotify("Lưu Khách không thành công!");
            }
        }, err => {
            setIsLoading(false)
            showErrorNotify("Lưu khách không thành công. Vui lòng liên hệ quản trị viên.");
        });
    }

    const handleSubmitRemoveGuest = () => {
        if (guestOut?.cardNumber) {
            setIsLoading(true)
            const url = `/api/guestOuts/removeByCardNumber?cardNumber=` + guestOut.cardNumber;
            axiosInstance.post(url, {}).then(rs => {
                setIsLoading(false)
                if (rs.status === 200) {
                    showSuccessNotify("Trả thẻ khách thành công!");
                    handleBackClick()
                } else {
                    showErrorNotify("Trả thẻ khách không thành công!");
                }
            }, err => {
                setIsLoading(false)
                showErrorNotify("Trả thẻ khách không thành công. Vui lòng liên hệ quản trị viên.");
            });
        } else {
            showWarningNotify('Vui lòng chạm thẻ khách');
        }
    }

    const getAttendancesHistory = () => {
        axiosInstance.get(`/api/attendance/getLastAttendanceInfo`)
            .then(res => {
                if (res?.data) {
                    setAttendances(res.data.attendances)
                    setGuests(res.data.guests)
                }
            })
    }

    return (
        <Dialog
            className={classes.suggestDialog}
            fullWidth={true}
            disableBackdropClick={true}
            open={true}
            classes={{paper: classes.dialogPaper}}
            aria-label="Kiểm soát"
        >
            <DialogTitle>
                {!isGuestIn && !isGuestOut &&
                <Grid container spacing={8}>
                    <Grid item xs={6}>
                        Giám sát vào ra
                    </Grid>
                    <Grid item xs={1}>
                        {isLoading &&
                        <CircularProgress/>
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <Input placeholder={'Scan/Nhập 7 số cuối thẻ hoặc mã QR'} style={{width: '100%'}} type="string"
                               id="searchInput"
                               autoFocus={true}/>
                    </Grid>
                    <Grid item xs={1}>
                        <Button id="myBtn" variant="outlined" color="primary"
                                onClick={submitSearchInput} disabled={isLoading}>Tìm kiếm
                        </Button>
                    </Grid>
                </Grid>
                }
                {isGuestIn &&
                <Grid container spacing={8}>
                    <Grid item xs={6}>
                        Tạo khách
                    </Grid>
                </Grid>
                }
                {isGuestOut &&
                <Grid container spacing={8}>
                    <Grid item xs={6}>
                        Trả thẻ khách
                    </Grid>
                </Grid>
                }
            </DialogTitle>
            <DialogContent>
                {isGuestIn &&
                <SimpleForm
                    form="create-guest-form"
                    toolbar={null}
                    record={guestForm}
                    validate={validate}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={6} sm={6}>
                                <Input placeholder={'Số thẻ *'} id={'guestCardNumber'}
                                       required readOnly className={classes.width100}/>
                                <TextInput source="guestName" label={'Họ tên khách hàng'}
                                           {...props} validate={required()} className={classes.width100}/>
                                <TextInput source="guestIdNo" label={'CMND/CCCD'}
                                           {...props} className={classes.width100}/>
                                <ReferenceInput source="contactId" reference="customers"
                                                className={classes.width100}
                                                label={'Người liên hệ'} allowEmpty {...props} >
                                    <AutocompleteInput optionText="name"/>
                                </ReferenceInput>
                                <TextInput source="workDescription"
                                           label={'Ghi chú'} {...props} className={classes.width100}/>
                            <ReferenceArrayInput
                                label={"resources.events.name"}
                                source="eventIds"
                                reference="events"
                                perPage={999}
                                className={classes.width100} allowEmpty
                                {...props}>
                                <AutocompleteArrayInput/>
                            </ReferenceArrayInput>
                        </Grid>
                    </Grid>
                </SimpleForm>
                }
                {isGuestOut && guestOut &&
                <Grid container spacing={8}>
                        <Grid item xs={4}>
                            <div className={classes.rowMinHeight}>
                                <Typography component="p" className={classes.informationItemLabel}>
                                    Số thẻ</Typography>
                                <Typography component="p"
                                            className={classes.informationItemContent}>{guestOut.cardNumber ?? ''}</Typography>
                            </div>
                            <div className={classes.rowMinHeight}>
                                <Typography component="p" className={classes.informationItemLabel}>
                                    Họ tên khách</Typography>
                                <Typography component="p"
                                            className={classes.informationItemContent}>{guestOut.name ?? ''}</Typography>
                            </div>
                            <div className={classes.rowMinHeight}>
                                <Typography component="p" className={classes.informationItemLabel}>
                                    CMND/CCCD</Typography>
                                <Typography component="p"
                                            className={classes.informationItemContent}>{guestOut.idNo ?? ''}</Typography>
                            </div>
                            <div className={classes.rowMinHeight}>
                                <Typography component="p" className={classes.informationItemLabel}>
                                    Thời gian vào</Typography>
                                <Typography component="p"
                                            className={classes.informationItemContent}>{guestOut.inDateTime ? moment(guestOut.inDateTime).format('HH:mm DD-MM-YYYY') : ''}</Typography>
                            </div>
                            <div className={classes.rowMinHeight}>
                                <Typography component="p" className={classes.informationItemLabel}>
                                    Người liên hệ</Typography>
                                <Typography component="p"
                                            className={classes.informationItemContent}>{guestOut.contactName ?? ''}</Typography>
                            </div>
                            <div className={classes.rowMinHeight}>
                                <Typography component="p" className={classes.informationItemLabel}>
                                    Ghi chú</Typography>
                                <Typography component="p"
                                            className={classes.informationItemContent}>{guestOut.workDescription ?? ''}</Typography>
                            </div>
                        </Grid>
                </Grid>
                }
                {!isGuestIn && !isGuestOut && !showData &&
                <Grid container spacing={8}>
                    <Grid item xs={6}>
                        <Grid item xs={12} className={classes.atdRowHeight}>
                            <span className={classes.headerText}>Lịch sử cư dân</span>
                        </Grid>
                        <Grid container spacing={8}  className={classes.atdRowHeight}>
                            <Grid item xs={4}>
                                <span className={classes.headerText}> Ngày giờ</span>
                            </Grid>
                            <Grid item xs={4}>
                                <span className={classes.headerText}> Họ tên</span>
                            </Grid>
                            <Grid item xs={4}>
                                <span className={classes.headerText}>Thiết bị</span>
                            </Grid>
                        </Grid>
                        {attendances?.map((item, idx) => {
                            return (
                                <Grid container  key={'gus' + idx}  className={classes.atdRowHeight}>
                                    <Grid item xs={4}>
                                        <span className={classes.normalText}>{item.attendanceTime}</span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span className={classes.normalText}>{item.customerName}</span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span className={classes.normalText}>{item.deviceName}</span>
                                    </Grid>
                                </Grid>
                            )
                        })
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <Grid item xs={12} className={classes.atdRowHeight}>
                            <span className={classes.headerText}>Lịch sử khách</span>
                        </Grid>
                        <Grid container spacing={8}  className={classes.atdRowHeight}>
                            <Grid item xs={4}>
                                <span className={classes.headerText}> Ngày giờ</span>
                            </Grid>
                            <Grid item xs={4}>
                                <span className={classes.headerText}> Họ tên</span>
                            </Grid>
                            <Grid item xs={4}>
                                <span className={classes.headerText}>Thiết bị</span>
                            </Grid>
                        </Grid>

                        {guests.map((item, idx) => {
                            return (
                                <Grid container  key={'gus' + idx}  className={classes.atdRowHeight}>
                                    <Grid item xs={4}>
                                        <span className={classes.normalText}>{item.attendanceTime}</span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span className={classes.normalText}>{item.customerName}</span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span className={classes.normalText}>{item.deviceName}</span>
                                    </Grid>
                                </Grid>
                            )
                        })
                        }
                    </Grid>
                </Grid>
                }
                {!isGuestIn && !isGuestOut && showData &&
                <Grid container style={{minHeight: 500}}>
                    <Grid item xs={2} style={{textAlign: 'center'}}>
                        {photoUrl ?
                            <img className={inEventTime ? classes.bigCusPhotoGreen : classes.bigCusPhotoRed}
                                 src={photoUrl}/>
                            :
                            <img className={inEventTime ? classes.bigCusPhotoGreen : classes.bigCusPhotoRed} src={'default_img.png'}/>
                        }
                        <Typography component="p" className={classes.headerCusName}>{cusName}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Grid container className={classes.rowMinHeightHeader}>
                            <AccountBoxIcon className={classes.acoIcon}/>
                            <Typography className={classes.heading}>Thông tin cá nhân</Typography>
                        </Grid>
                        <div className={classes.rowMinHeight}>
                            <Typography component="p" className={classes.informationItemLabel}>
                                Ngày sinh</Typography>
                            <Typography component="p"
                                        className={classes.informationItemContent}>{dob ? dob : ''}</Typography>
                        </div>
                        <div className={classes.rowMinHeight}>
                            <Typography component="p"
                                        className={classes.informationItemLabel}>Giới tính</Typography>
                            <Typography component="p"
                                        className={classes.informationItemContent}>{gender ? gender : ''}</Typography>
                        </div>
                        <div className={classes.rowMinHeight}>
                            <Typography component="p"
                                        className={classes.informationItemLabel}>Điện thoại</Typography>
                            <Typography component="p"
                                        className={classes.informationItemContent}>{cusPhone ? cusPhone : ''}</Typography>
                        </div>
                        <div className={classes.rowMinHeight}>
                            <Typography component="p" className={classes.informationItemLabel}>Số
                                CMND/CCCD/Hộ chiếu</Typography>
                            <Typography component="p"
                                        className={classes.informationItemContent}>{idNo ? idNo : ''}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container className={classes.rowMinHeightHeader}>
                            <BusinessIcon className={classes.acoIcon}/>
                            <Typography className={classes.heading}>Tổ chức</Typography>
                        </Grid>
                        {tenantInfo &&
                        <>
                            <Grid
                                container
                                alignItems="center"
                            >
                                <Avatar src={`${env.baseURL + tenantInfo.organizationImageUrl}`}
                                />
                                <Typography component="p"
                                            className={classes.informationItemLabel2}>{tenantInfo.organizationName}</Typography>
                            </Grid>
                            <div className={classes.rowMinHeight}>
                                <Typography component="p"
                                            className={classes.informationItemLabel}>Địa chỉ</Typography>
                                <Typography component="p"
                                            className={classes.informationItemContent}>{tenantInfo.organizationAddress ? tenantInfo.organizationAddress : ''}</Typography>
                            </div>
                            <div className={classes.rowMinHeight}>
                                <Typography component="p"
                                            className={classes.informationItemLabel}>{tenantInfo.type === 'SCHOOL' ? 'Lớp' : 'Chức danh/Bộ phận'}</Typography>
                                <Typography component="p"
                                            className={classes.informationItemContent}>{tenantInfo.titleInDepartment ? tenantInfo.titleInDepartment : ''}</Typography>
                            </div>
                            <div className={classes.rowMinHeight}>
                                <Typography component="p"
                                            className={classes.informationItemLabel}>Mã số nội bộ</Typography>
                                <Typography component="p"
                                            className={classes.informationItemContent}>{tenantInfo.internalCode ? tenantInfo.internalCode : ''}</Typography>
                            </div>
                        </>
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container className={classes.rowMinHeightHeader}>
                            <EventIcon className={classes.acoIcon}/>
                            <Typography className={classes.heading}>Lịch sự kiện</Typography>
                        </Grid>
                        {eventWrapper &&
                        <div className={classes.evtDiv}>
                            <Typography component="p"
                                        className={classes.informationItemLabel}>{eventWrapper.displayDate}</Typography>
                            {eventWrapper.events.map((evt, idx) => (
                                <>
                                    <Grid key={'rw1' + idx}
                                          container
                                          className={classes.root}
                                          spacing={0}
                                    >
                                        <Grid item xs={1} key={'rw11_' + idx}>
                                            <FiberManualRecordIcon className={classes.evtIcon}/>
                                        </Grid>
                                        <Grid item xs={8} key={'rw12_' + idx}>
                                            <Typography component="span"
                                                        className={classes.evtName}>{evt.name}</Typography>
                                        </Grid>
                                        <Grid item xs={3} key={'rw13_' + idx}>
                                            <Typography component="span"
                                                        className={classes.evtTime}>{evt.startTime ? evt.startTime + ' - ' + evt.endTime : ''}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid key={'rw2' + idx}
                                          container
                                          className={classes.root}
                                          spacing={0}
                                    >
                                        <Grid item xs={1} key={'rw21_' + idx}>
                                        </Grid>
                                        <Grid item xs={8} key={'rw22_' + idx}>
                                            <Typography component="span"
                                                        className={classes.evtAddress}>{evt.address ? evt.address : ''}</Typography>
                                        </Grid>
                                        <Grid item xs={3} key={'rw23_' + idx}>
                                        </Grid>
                                    </Grid>
                                </>
                            ))}
                        </div>
                        }
                    </Grid>
                </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Grid container spacing={8}>
                        <Grid item xs={10}>
                            <Button className={classes.padLeft10} variant="outlined" onClick={handleBackClick}>
                                Làm mới
                            </Button>
                            {isGuestIn &&
                            <Button className={classes.padLeft10} variant="outlined" onClick={handleSubmitCreateGuest}
                                    color="primary">
                                Lưu khách
                            </Button>
                            }
                            {isGuestOut &&
                            <Button className={classes.padLeft10} variant="outlined" onClick={handleSubmitRemoveGuest}
                                    color="secondary">
                                Trả thẻ khách
                            </Button>
                            }
                        </Grid>
                        <Grid item xs={2}>
                            <Button className={classes.padLeft10} variant="outlined" onClick={downLoadGuide}
                                    color="primary">
                                Phần mềm và hướng dẫn
                            </Button>
                        </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
};

export default compose(
    withStyles(styles),
    connect(null, {}),
)(ManualAccessControlList);
