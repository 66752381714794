import DepartmentIcon from '@material-ui/icons/ViewList';

import DisciplinePlanList from './DisciplinePlanList';
import DisciplinePlanCreate from './DisciplinePlanCreate';
import DisciplinePlanEdit from './DisciplinePlanEdit';

export default {
  list: DisciplinePlanList,
  create: DisciplinePlanCreate,
  edit: DisciplinePlanEdit,
  icon: DepartmentIcon,
};
