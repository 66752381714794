import RelationshipMigrationIcon from '@material-ui/icons/ViewList';

import RelationshipMigrationBatchList from './RelationshipMigrationBatchList';
import RelationshipMigrationCreate from './RelationshipMigrationBatchCreate';
import RelationshipMigrationEdit from './RelationshipMigrationBatchEdit';

export default {
  list: RelationshipMigrationBatchList,
  create: RelationshipMigrationCreate,
  edit: RelationshipMigrationEdit,
  icon: RelationshipMigrationIcon,
};
