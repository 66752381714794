import BillBatchIcon from '@material-ui/icons/ViewList';

import BillBatchList from './BillBatchList';
import BillBatchCreate from './BillBatchCreate';
import BillBatchEdit from './BillBatchEdit';

export default {
  list: BillBatchList,
  create: BillBatchCreate,
  edit: BillBatchEdit,
  icon: BillBatchIcon,
};
