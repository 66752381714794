import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, ReferenceInput, required, Title} from 'react-admin';
import {downloadFile, PaymentMethodInput, ReportForm, TreeCheckboxInput} from '../../components';
import env from '../../env';
import moment from 'moment';

class TongHopKhoanThuReport extends React.Component {
  state = {
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    paymentMethod: '',
    productIds: [],
  };

  getReportUrl = () => {
    return `${env.baseURL}/reports/tongHopKhoanThuReport` +
        `?fromDate=` + this.state.fromDate +
        `&toDate=` + this.state.toDate +
        `&paymentMethod=` + this.state.paymentMethod +
        `&productIds=` + this.state.productIds;
  };

  excelReport = () => {
    downloadFile(this.getReportUrl(), 'tong_hop_khoan_thu.xlsx')
  };

  validate = (values) => {
    const errors = {};
    if (values.fromDate && values.toDate && (values.fromDate > values.toDate) ){
      errors.toDate = 'Thời gian không hợp lệ'
    }
    // Copy input values to params
    this.setState(values);
    return errors;
  };

  render() {
    return (
      <Card>
        <Title title="resources.tongHopKhoanThuReport.name"/>
        <CardContent>
          <ReportForm params={this.state}
                      validate={this.validate}
                      excelReport={this.excelReport}
          >
              <Grid container spacing={8}>
                <Grid item xs={12} sm={6} >
                  <DateInput label="resources.common.fromDate" source="fromDate" validate={required()} {...this.props} />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <DateInput label="resources.common.toDate" source="toDate" validate={required()} {...this.props} />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <PaymentMethodInput source="paymentMethod" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label>Danh sách phí *</label>
                  <div style={{maxHeight: '300px', overflow: 'auto'}}>
                    <ReferenceInput source="productIds"
                                    reference="billProducts"
                                    perPage={999}
                                    {...this.props} >
                      <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true} />
                    </ReferenceInput>
                  </div>
                </Grid>
              </Grid>
          </ReportForm>
        </CardContent>
      </Card>
    )
  }
}

export default TongHopKhoanThuReport

