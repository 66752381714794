import React, {useEffect, useState} from 'react';
import {refreshView, showNotification, SimpleForm, translate} from 'react-admin';

import {Grid, showErrorNotify, showInfoNotify, showSuccessNotify} from '../../components'
import MessageFields from './SmsMessageFields'
import axiosInstance from "../../api/axios";
import utils from "../../utils/utils";
import {connect} from 'react-redux';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';

let timeout = null;
export const SmsMessageEdit = ({
                                   translate,
                                   classes,
                                   showToolbar = true,
                                   redirect = "list",
                                   noCancelButton,
                                   event,
                                   ...props
                               }) => {
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({
        id: null,
        category: '',
        freeText: '',
        authStatus: '',
        customerIds: [],
        cuzIds: [],
        isRedirect: false,
        forceSms: true
    });

    useEffect(() => {
        console.log('bbb', props)
        if (props.id) {
            getDetails();
        }
    }, []);

    function getDetails() {
        const url = `/api/smsMessages/` + props.id;
        axiosInstance.get(url).then(rs => {
            const {data} = rs;
            const merged = Object.assign(values, data);
            setValues({...values, merged});
            merged.cuzIds = data.customerIds;
            setValues(merged);
        });
    }

    const handleSelectChange = (prop, e) => {
        if (!e) {
            return
        }
        timeout = setTimeout(() => {
            setValues({...values, [prop]: e});
        }, 500);
    }
    const handleChange = prop => e => {
        if (!e) {
            return
        }
        let value;
        if (['cuzIds'].includes(prop)) {
            value = e;
        } else {
            if (e.target) {
                value = e.target.value ? e.target.value : e.target.checked;
            } else {
                value = e
            }
        }
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            setValues({...values, [prop]: value});
        }, 500);
    };

    function save() {
        if (!validate()) {
            return;
        }
        setLoading(true)
        values.customerIds = utils.extractCustomerIds(values.cuzIds);
        const data = {
            customerIds: values.customerIds,
            category: values.category,
            hocKy: values.hocKy,
            freeText: values.freeText,
            forceSms: values.forceSms
        }

        const url = `/api/smsMessages/` + props.id;

        axiosInstance.put(url, data).then(rs => {
            setLoading(false)
            props.showNotification('Sửa SMS thành công');
            setValues({...values, isRedirect: true});
            props.refreshView()
        }, err => {
            setLoading(false)
            props.showNotification(err.message, 'warning');
        })
    }

    function authorize() {
        if (!validate()) {
            return;
        }
        setLoading(true)
        values.customerIds = utils.extractCustomerIds(values.cuzIds);
        const data = {
            customerIds: values.customerIds,
            category: values.category,
            hocKy: values.hocKy,
            freeText: values.freeText,
            forceSms: values.forceSms,
            authStatus: 'AUTHORIZED'
        }
        const url = `/api/smsMessages/` + props.id;
        showInfoNotify('Đang gửi SMS, vui lòng chờ trong giây lát')
        axiosInstance.put(url, data).then(rs => {
            setLoading(false)
            showSuccessNotify('Gửi tin nhắn thành công')
            setValues({...values, isRedirect: true});
            props.refreshView()
        }, err => {
            setLoading(false)
            showErrorNotify(err.message)
        })
    }

    const validate = () => {
        if (!values.category) {
            props.showNotification('Cần chọn loại sms', 'warning');
            return false;
        }
        if (values.category === 'KQHT' && !values.hocKy) {
            props.showNotification('Cần chọn học kỳ', 'warning');
            return false;
        }
        if (values.category === 'FREE_TEXT' && !values.freeText) {
            props.showNotification('Cần nhập nội dung', 'warning');
            return false;
        }
        if (!values.cuzIds || values.cuzIds.length === 0) {
            props.showNotification('Cần chọn danh sách người nhận', 'warning');
            return false;
        }
        return true
    }

    function onDirectChange(prop, val) {
        setValues({...values, [prop]: val});
    }

    return (
        <SimpleForm
            toolbar={null}
            record={values}
        >
            <Grid container spacing={8} alignItems="flex-start" classes={classes}>
                <MessageFields onChange={handleChange}
                               handleSelectChange={handleSelectChange}
                               defaultValue={values}
                               permissions={props.permissions}
                               files={values.files}
                               save={save}
                               authorize={authorize}
                               onDirectChange={onDirectChange}
                               loading={loading}
                />
            </Grid>
        </SimpleForm>
    )
}

export default compose(
    connect(undefined, {refreshView, showNotification}),
    translate,
    withStyles(null)
)(SmsMessageEdit)
