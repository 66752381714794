import React from 'react';
import {BooleanField, Datagrid, Filter, List, NumberField, TextField, TextInput} from 'react-admin';

const ProductFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const ContributionProductList = props => (
    <List filters={<ProductFilter/>} {...props} perPage={25} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <NumberField source="price" locales="vi-VI" options={{style: 'currency', currency: 'VND'}}/>
            <TextField source="priority"/>
            <BooleanField source="enable"/>
        </Datagrid>
    </List>
);

export default ContributionProductList
