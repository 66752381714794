import React from 'react';
import {Create, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import KiotProductMenuFields from './KiotProductMenuFields'

export const KiotProductMenuCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<CustomToolbar simpleSaveRefresh={true}/>} redirect="list">
            <Grid container spacing={8}>
                <KiotProductMenuFields/>
            </Grid>
        </SimpleForm>
    </Create>
);

export default KiotProductMenuCreate
