import React from 'react';
import { Create, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import ECardTemplateFields from './ECardTemplateFields'

export const ECardTemplateCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="list" >
      <Grid container spacing={8}>
        <ECardTemplateFields />
      </Grid>
    </SimpleForm>
  </Create>
);

export default ECardTemplateCreate
