import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid'
import {AutocompleteInput, ReferenceInput, required, TextInput} from 'react-admin';

import {schoolYears} from '../../components';
import env from '../../env';

const StudentEnrollmentFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="unitId" reference="clazzes"
                            filter={{schoolYear: (schoolYears && schoolYears.length > 0) ? schoolYears[0].id : null}}
                            validate={required()} allowEmpty {...props} >
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
            <ReferenceInput source="customerId" reference="customers" validate={required()} allowEmpty {...props} >
                <AutocompleteInput optionText="displayName"/>
            </ReferenceInput>
        </Grid>

        <Grid item xs={12} sm={6}>
            <TextInput source="vduStudentCode" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="vduAttendanceCode" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            {props.record.photoName &&
            <img src={`${env.baseURL}/cus-photo/${props.record.photoName}`}
                 alt="" style={{maxWidth: '250px'}}
            />
            }
        </Grid>

    </React.Fragment>
)

export default StudentEnrollmentFields
