import SubMenuIcon from '@material-ui/icons/ViewList';

import SubMenuList from './SubMenuList';
import SubMenuCreate from './SubMenuCreate';
import SubMenuEdit from './SubMenuEdit';

export default {
  list: SubMenuList,
  create: SubMenuCreate,
  edit: SubMenuEdit,
  icon: SubMenuIcon,
};
