import ArchiveMessageLogIcon from '@material-ui/icons/ViewList';

import ArchiveMessageLogList from './ArchiveMessageLogList';
import ArchiveMessageLogEdit from './ArchiveMessageLogEdit';

export default {
  list: ArchiveMessageLogList,
  edit: ArchiveMessageLogEdit,
  icon: ArchiveMessageLogIcon,
};
