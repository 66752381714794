import React from 'react';
import {
  AutocompleteInput,
  Datagrid,
  Filter,
  List,
  ReferenceInput,
  TextField,
  TextInput,
  DateInput
} from 'react-admin';
import { schoolYears } from '../../components';

const AuthorizedAttendanceHistoryFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Tìm tên" source="q" alwaysOn />
    <ReferenceInput source="unitId"
                    {...props}
                    label="Tìm lớp"
                    reference="clazzes"
                    filter={{schoolYear: schoolYears[0].id}}
                    allowEmpty
                    sort={{ field: 'name', order: 'ASC' }}
                    perPage={999}
                    alwaysOn >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <DateInput source="date" label="Ngày duyệt" alwaysOn options={{
      mode: "portrait",
      locale: "vn",
      defaultDate: props.date,
    }} />
  </Filter>
);

export const AuthorizedAttendanceHistoryList = props => {
  return (
      <List filters={<AuthorizedAttendanceHistoryFilter date={new Date()}/>}
            bulkActions={false}
            exporter={false}
            perPage={25}
            title={"Cập nhật dữ liệu đã duyệt"}
            {...props}>
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="customerId" />
          <TextField source="customerName" />
          <TextField source="unitName"/>
          <TextField source="eventName"/>
          <TextField source="displaySummaryDate"/>
          <TextField source="displayTimeIn"/>
          <TextField source="displayStatusIn"/>
        </Datagrid>
      </List>
  )
}

export default AuthorizedAttendanceHistoryList
