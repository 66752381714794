import BlockIcon from '@material-ui/icons/ViewList';

import FloorList from './FloorList';
import FloorCreate from './FloorCreate';
import FloorEdit from './FloorEdit';

export default {
  list: FloorList,
  create: FloorCreate,
  edit: FloorEdit,
  icon: BlockIcon,
};
