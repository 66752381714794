import React from 'react';
import {Create, SimpleForm, showNotification} from 'react-admin';
import {connect} from 'react-redux';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {Grid} from '../../components'
import axiosInstance from "../../api/axios";
import utils from "../../utils/utils";
import CustomerProductFields from "./CustomerProductFields";

let timeout = null;
export const CustomerProductCreate = ({redirect="list", event, ...props}) => {
  const [values, setValues] = React.useState({
    name: '',
    productIds: [],
    customerIds: [],
    cuzIds: [],
    tmpProductIds: [],
    isRedirect: false,
    saveDisabled: true
  });

  React.useEffect(() => {
    validate();
  }, []);

  const handleChange = prop => e => {
    if (!e) {
      return
    }
    let value;
    if (['tmpProductIds', 'cuzIds'].includes(prop)) {
      value = e;
    } else {
      value = e.target.value ? e.target.value : e.target.checked;
    }
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setValues({...values, [prop]: value});
    }, 500);
  };

  function onDirectChange(prop, val) {
    setValues({...values, [prop]: val});
  }

  function save() {
    if (!validate( true)) {
      return;
    }
    values.customerIds = utils.extractCustomerIds(values.cuzIds);
    values.productIds = utils.extractCustomerIds(values.tmpProductIds);
    const url = `/api/customerProducts`;
    axiosInstance.post(url, values).then(rs => {
      props.showNotification('Tạo cấu hình học sinh và phí thành công');
      setValues({...values, isRedirect: true});
    }, err => {
      props.showNotification(err.message, 'warning');
    })
  }

  function validate() {
    setValues({...values, saveDisabled: false});
    return true;
  }

  return (
      <Create {...props} location={{ state: { record: values } }}>
        <SimpleForm toolbar={null}>
          <Grid container spacing={8}>
            <CustomerProductFields onChange={handleChange}
                          defaultValue={values}
                          save={save}
                          onDirectChange={onDirectChange}/>
          </Grid>
        </SimpleForm>
      </Create>
  )
};

export default compose(
    connect(null, {showNotification}),
    withStyles(null)
)(CustomerProductCreate)
