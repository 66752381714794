import React from 'react';
import { Create, SimpleForm } from 'react-admin';

import {CustomToolbar, Grid, schoolYears} from '../../components'
import ClazzFields from './ClazzFields'

const defaultValue = {
    clazzType: 'NORMAL',
    schoolYearId: (schoolYears && schoolYears.length > 0) ? schoolYears[0].id : null
}
export const ClazzCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="list" defaultValue={defaultValue}>
      <Grid container spacing={8}>
        <ClazzFields />
      </Grid>
    </SimpleForm>
  </Create>
);

export default ClazzCreate
