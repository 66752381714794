import React from 'react';
import {Datagrid, Filter, FunctionField, List, TextInput} from "react-admin";

const UploadPhotoPermissionFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

const UploadPhotoPermissionList = (props) => {
    return (
        <List {...props} filters={<UploadPhotoPermissionFilter/>} exporter={false}>
            <Datagrid rowClick="edit">
                <FunctionField label={'Tài khoản'} render={({userName, fullName}) => `${userName} (${fullName})`}/>
            </Datagrid>
        </List>
    );
};

export default UploadPhotoPermissionList;
