import React from 'react';
import {Datagrid, Filter, List, ReferenceField, TextField, TextInput} from 'react-admin';

const VduGatewayConfigurationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const VduGatewayConfigurationList = props => (
  <List filters={<VduGatewayConfigurationFilter />} {...props} exporter={false}>
    <Datagrid rowClick="edit" >
      <TextField source="id" />
      <ReferenceField source="tenantId" reference="tenants" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="sn" />
      <TextField source="connectionKey" />
    </Datagrid>
</List>
);

export default VduGatewayConfigurationList
