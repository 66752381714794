import React from 'react';
import {
  Datagrid,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput,
  SelectInput
} from 'react-admin';
import FinalizeButton from "./FinalizeButton";
import {DateTimeField, schoolYears} from "../../components";

const FinalizeClassAttendanceDataFilter = (props) => (
    <Filter {...props}>
      <TextInput label="ra.action.search" source="q" alwaysOn/>
      <SelectInput label="resources.schoolYears.name" source="schoolYear" choices={schoolYears}
                   alwaysOn allowEmpty={false}
      />
    </Filter>
);

export const FinalizeClassAttendanceDataList = props => {

  return (
      <List filters={<FinalizeClassAttendanceDataFilter/>}
            bulkActionButtons={<FinalizeButton />} {...props} exporter={false} filterDefaultValues={{schoolYear: schoolYears[0].id}}>
        <Datagrid rowClick="edit">
          <TextField source="id"/>
          <TextField source="name"/>
          <ReferenceField source="schoolYearId" reference="schoolYears"
                          allowEmpty>
            <TextField source="name"/>
          </ReferenceField>
          <DateTimeField source="finalizeAttendanceDate" format="DD-MM-YYYY HH:mm" />
        </Datagrid>
      </List>
  )
};

export default FinalizeClassAttendanceDataList
