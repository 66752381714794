import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, ReferenceArrayInput, required, Title, AutocompleteArrayInput} from 'react-admin';
import {downloadFile, ReportForm, showErrorNotify} from '../../components';
import env from '../../env';
import moment from "moment";

class ThongKeSoLieuDoNhietDo extends React.Component {
    state = {
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        eventIds: []
    };

    getReportUrl = () => {
        return `${env.baseURL}/reports/thongKeSoLieuDoNhietDo?` +
            `fromDate=` + this.state.fromDate +
            `&toDate=` + this.state.toDate +
            `&eventIds=` + this.state.eventIds
    };


    excelReport = () => {
        if (! this.state.eventIds ||  this.state.eventIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách sự kiện")
            return;
        }
        downloadFile(this.getReportUrl(), 'thong_ke_so_lieu_do_nhiet_do.xlsx')
    };


    validate = (values) => {
        const errors = {};
        if (values.fromDate && values.toDate && (values.fromDate > values.toDate)) {
            errors.toDate = 'Đến ngày phải lớn hơn Từ ngày'
        }
        // Copy input values to params
        this.setState(values)
        return errors;
    };

    render() {
        return (
            <Card>
                <Title title="resources.thongKeSoLieuDoNhietDo.name"/>
                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                excelReport={this.excelReport}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.fromDate" source="fromDate" validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.toDate" source="toDate" validate={required()} {...this.props} />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <ReferenceArrayInput
                                label={"resources.events.name"}
                                source="eventIds"
                                reference="events"
                                perPage={999}
                                filter={{filterPath: 'filterSimple'}}
                                {...this.props}
                                validate={required()}>
                                <AutocompleteArrayInput/>
                            </ReferenceArrayInput>
                        </Grid>
                    </ReportForm>

                </CardContent>
            </Card>
        )
    }
}

export default ThongKeSoLieuDoNhietDo

