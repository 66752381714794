import React from 'react';
import {
    AutocompleteInput,
    CardActions,
    CreateButton,
    Datagrid,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    TextField,
    TextInput
} from 'react-admin';
import PrintQrInfoLinkButton from "../../components/PrintQrInfoLinkButton";
import PrintAllQrInfoLinkButton from "../../components/PrintAllQrInfoLinkButton";

const ResidentEnrollmentFilter = (props) => (
  <Filter {...props}>
      <ReferenceInput source="unitId" reference="apartmentViews"
                      sort={{field: 'displayName', order: 'ASC'}}
                      allowEmpty {...props} alwaysOn>
          <AutocompleteInput optionText="displayName"/>
      </ReferenceInput>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);


const PostActions = ({
                         bulkActions,
                         basePath,
                         displayedFilters,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter,
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        {/* Add your custom actions */}
        <PrintAllQrInfoLinkButton  type={'r'}/>
        <CreateButton basePath={basePath} />
    </CardActions>
);

export const ResidentEnrollmentList = props => (
    <List filters={<ResidentEnrollmentFilter/>} {...props} actions={<PostActions/>} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="customerName"/>
            <TextField source="internalCode"/>
            <TextField source="title"/>
            <ReferenceField source="unitId" reference="apartments" allowEmpty>
                <TextField source="displayName"/>
            </ReferenceField>
            <PrintQrInfoLinkButton type={'r'}/>
        </Datagrid>
    </List>
);

export default ResidentEnrollmentList
