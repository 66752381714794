import React from 'react';
import {SelectField} from 'react-admin';

const authStatuses = [
  {id: 'DRAFT', name: 'Đang chờ'},
  {id: 'ACCEPT', name: 'Xác nhận'},
  {id: 'DECLINE', name: 'Từ chối'},
]

export const StatusField = (props) => (
    <SelectField source={props.source} choices={authStatuses} {...props} />
)
