import React from 'react';
import {Create, showNotification, SimpleForm} from 'react-admin';
import {connect} from 'react-redux';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {Grid, schoolYears} from '../../components'
import moment from 'moment';
import axiosInstance from "../../api/axios";
import DisciplinePlanFields from "./DisciplinePlanFields";

let timeout = null;
export const DisciplinePlanCreate = ({redirect = "list", event, ...props}) => {
    const [values, setValues] = React.useState({
        name: '',
        active: true,
        schoolYearId: schoolYears[0].id,
        startDate: moment().format('YYYY-MM-DD'),
        daysOfWeek: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'],
        endDate: null,
        basePoint: 0,
        unitIds: [],
        isRedirect: false,
        saveDisabled: true
    });

    React.useEffect(() => {
        validate(false);
    }, [values.name, values.startDate, values.endDate]);

    const handleChange = prop => e => {
        if (!e) {
            return
        }
        let value;
        if (['recurringType', 'tmpExcludeUnitIds', 'tmpUnitIds', 'cuzIds'].includes(prop)) {
            value = e;
        } else {
            value = e.target.value ? e.target.value : e.target.checked;
        }
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            setValues({...values, [prop]: value});
        }, 500);
    };

    function onDirectChange(prop, val) {
        setValues({...values, [prop]: val});
    }

    function save() {
        if (!validate(true)) {
            return;
        }
        values.unitIds = values.tmpUnitIds;
        const url = `/api/disciplinePlans`;
        axiosInstance.post(url, values).then(rs => {
            props.showNotification('Tạo kế hoạch thi đua thành công');
            setValues({...values, isRedirect: true});
        }, err => {
            props.showNotification(err.message, 'warning');
        })
    }

    function validate(isShowNotification) {
        if (isShowNotification) {//show details error
            if (values.startDate > values.endDate) {
                props.showNotification('Ngày bắt đầu phải nhỏ hơn ngày kết thúc',
                    'warning');
                return false;
            }
            if (!values.name) {
                props.showNotification('Bắt buộc nhập tên kế hoạch', 'warning');
                return false;
            }
            if (!values.startDate) {
                props.showNotification('Bắt buộc nhập ngày bắt đầu', 'warning');
                return false;
            }
            if (!values.endDate) {
                props.showNotification('Bắt buộc nhập ngày kết thúc', 'warning');
                return false;
            }
        } else {
            if (!values.name || !values.startDate || !values.endDate) {
                setValues({...values, saveDisabled: true});
                return false;
            }
        }
        setValues({...values, saveDisabled: false});
        return true;
    }

    return (
        <Create {...props} location={{state: {record: values}}}>
            <SimpleForm toolbar={null}>
                <Grid container spacing={8}>
                    <DisciplinePlanFields onChange={handleChange}
                                          defaultValue={values}
                                          save={save}
                                          onDirectChange={onDirectChange}/>
                </Grid>
            </SimpleForm>
        </Create>
    )
};

export default compose(
    connect(null, {showNotification}),
    withStyles(null)
)(DisciplinePlanCreate)
