import React from 'react';
import compose from 'recompose/compose';
import {Card, CardHeader, Divider} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {Responsive, translate} from 'react-admin';
import Timeline from '@material-ui/icons/Timeline';
import CardIcon from './CardIcon';
import {Bar} from "react-chartjs-2";
import axiosInstance from "../../api/axios";

const style = theme => ({
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 40,
        width: 1000
    },
    card: {
        padding: '16px 0',
        overflow: 'initial',
        textAlign: 'right',
    },
    mainSmall: {
        flex: '1',
        marginRight: '1em',
        marginTop: 40,
        width: 355
    },
});

const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
        {
            label: 'Số lượt gửi sms',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
    ]
};

class SendReview extends React.Component {
    state = {
        attendanceData: data
    };

    componentDidMount() {
        this.getCardTapHistories();
    }

    getCardTapHistories() {
        const url = `/api/smsDashboard/sendHistory`;
        axiosInstance.get(url).then(rs => {
            data.labels = rs.data.months;
            data.datasets[0].data = rs.data.cardTaps;
            this.setState({attendanceData: data});
        }, err => {
            this.props.onError(err.message);
        });
    }

    renderUI() {
        const {classes, translate} = this.props;
        return (
            <div className={classes.mainSmall}>
                <CardIcon Icon={Timeline} bgColor="#2B78FC"/>
                <Card className={classes.card}>
                    <CardHeader title={translate('app.smsDashboard.sendHistory')}/>
                    <Divider/>
                    <Bar width={10}
                         height={200}
                         options={{maintainAspectRatio: false}} data={this.state.attendanceData}/>
                </Card>
            </div>
        )
    }

    render() {
        return (
            <Responsive
                xsmall={
                    this.renderUI()
                }
                small={
                    this.renderUI()
                }
                medium={
                    this.renderUI()
                }
            />
        );
    }
}


const enhance = compose(
    withStyles(style),
    translate
);

export default enhance(SendReview);
