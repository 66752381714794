import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {AutocompleteInput, DateInput, ReferenceInput, required, Title} from 'react-admin';
import {downloadFile, ReportForm} from '../../components';
import env from '../../env';
import moment from 'moment';

class ChiTraTienReport extends React.Component {
  state = {
    fromMonth: moment().format('YYYY-MM-DD'),
    productId: null,
  };

  getReportUrl = () => {
    return `${env.baseURL}/reports/chiTraTienReport` +
        `?fromMonth=` + this.state.fromMonth +
        `&productId=` + this.state.productId;
  };

  excelReport = () => {
    downloadFile(this.getReportUrl(), 'bao_cao_chi_tra_tien_an.xlsx')
  };

  validate = (values) => {
    const errors = {};
    // Copy input values to params
    this.setState({
      fromMonth: values.fromMonth,
      productId: values.productId
    });
    return errors;
  };

  render() {
    return (
      <Card>
        <Title title="resources.chiTraTienReport.name"/>
        <CardContent>
          <ReportForm params={this.state}
                      validate={this.validate}
                      excelReport={this.excelReport}
          >
              <Grid container spacing={8}>
                <Grid item xs={12} sm={6} >
                  <DateInput label="resources.common.month" source="fromMonth" validate={required()} {...this.props} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ReferenceInput source="productId"
                                  reference="billProducts"
                                  label="resources.products.name"
                                  perPage={999}
                                  validate={required()}
                                  {...this.props} >
                    <AutocompleteInput optionValue="id" optionText="name"/>
                  </ReferenceInput>
                </Grid>
              </Grid>
          </ReportForm>
        </CardContent>
      </Card>
    )
  }
}

export default ChiTraTienReport

