import React from 'react';
import {ReportForm, showErrorNotify, showSuccessNotify} from "../../components";
import {Card, CardContent, Grid} from '@material-ui/core';
import {AutocompleteInput, DateInput, ReferenceInput, required, Title} from 'react-admin';
import moment from 'moment'
import axiosInstance from "../../api/axios";

class CreateAttendance extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            unitIds: [],
            isLoading: false,
            date: moment().format('YYYY-MM-DD'),
        };
    }

    generateData = () => {
        this.setState({
            isLoading: true
        });
        axiosInstance.get(`/api/attendance/generateAttendanceResult?date=${this.state.date}&eventId=${this.state.eventId}`)
            .then(() => {
                this.setState({
                    isLoading: false
                });
                showSuccessNotify("Tạo dữ liệu thành công")
            }).catch((e) => {
            this.setState({
                isLoading: false
            });
            showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!")
        })
    }


    validate = (values) => {
        const errors = {};
        values.eventId = this.state.eventId;
        // Copy input values to params
        this.setState({
            unitIds: values.unitIds,
            date: values.date,
        });
        return errors;
    }


    onEventChanged(e, value) {
        this.setState({
            eventId: value
        })
    }

    render() {
        return (
            <Card>
                <Title title="resources.create-attendance.name"/>
                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                excelReport={this.generateData}
                                isLoading={this.state.isLoading}
                                label={'Tạo dữ liệu'}
                    >

                        <Grid container>

                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.fromDate"
                                           source="date"
                                           inputProps={{
                                               max: moment().format('YYYY-MM-DD'),
                                           }}
                                           {...this.props}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ReferenceInput source="eventId"
                                                reference="events"
                                                label="resources.soDiemDanh.eventId"
                                                perPage={50}
                                                filter={{filterPath: 'filterSimple', type: 'ATTENDANCE'}}
                                                validate={required()}
                                                onChange={(e, value) => this.onEventChanged(e, value)}
                                >
                                    <AutocompleteInput fullWidth source="name"
                                                       optionText={(e) => `${e.name} - ${e.eventTime}`}/>
                                </ReferenceInput>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{color: 'red', fontSize: 12, fontStyle: 'italic'}}>* Lưu ý: chỉ nên tạo dữ
                                    liệu khi đã kết thúc giờ điểm danh và chưa có dữ liệu duyệt điểm danh.
                                </div>
                            </Grid>
                        </Grid>
                    </ReportForm>
                </CardContent>
            </Card>
        )
    }
}

export default CreateAttendance;
