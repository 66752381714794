import React from 'react';
import { Create, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import TenantFields from './TenantFields'
const defaultValue = {
    enablePayment: false,
    enableEdit: false,
    showAdBanner: false
}
export const TenantCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="list" defaultValue={defaultValue} >
      <Grid container spacing={8}>
        <TenantFields />
      </Grid>
    </SimpleForm>
  </Create>
);

export default TenantCreate
