import FoodMenuIcon from '@material-ui/icons/ViewList';

import FoodMenuList from './FoodMenuList';
import FoodMenuCreate from './FoodMenuCreate';
import FoodMenuEdit from './FoodMenuEdit';

export default {
  list: FoodMenuList,
  create: FoodMenuCreate,
  edit: FoodMenuEdit,
  icon: FoodMenuIcon,
};
