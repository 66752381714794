import React, {useEffect, useState} from 'react';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TextField
} from '@material-ui/core';
import {translate} from 'react-admin';
import axiosInstance from "../../api/axios";
import utils from "../../utils/utils";

const styles = {
}

let timeout = null;

const WhiteListDialog = ({translate, classes, ...props}) => {

  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [totalCards, setTotalCards] = useState(0);
  const [searchText, setSearchText] = useState('');
  const {unitIds, excludeUnitIds, customerIds, open, close} = props;

  useEffect(() => {
    if (open) {
      loadWhiteList();
    }
  }, [open, page, size, searchText]);

  function loadWhiteList() {
    let cuzIds = utils.extractCustomerIds(customerIds);
    const url = `/api/events/white-list?page=${page}&size=${size}&unitIds=${unitIds.join(',')}`
    + `&excludeUnitIds=${excludeUnitIds.join(',')}&customerIds=${cuzIds.join(',')}&name=${searchText}`;
    axiosInstance.get(url).then(rs => {
      setCustomers(rs.data.content);
      setTotalCustomers(rs.data.page.totalElements);
      setTotalCards(rs.data.totalCards);
    });
  }

  function handleChangePage(e, newPage) {
    setPage(newPage);
  }

  function onChangeRowsPerPage(event) {
    setSize(event.target.value);
  }
  
  function handleChange(e) {
    const text = e.target.value;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setSearchText(text);
    }, 1500);
  }

  function closeDialog() {
    setSearchText('');
    close();
  }

  return (
      <Dialog open={open}
              maxWidth={'md'}
              scroll={'paper'}
              aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <div>Danh sách tham dự</div>
          <div style={{fontSize: 10, fontWeight: 'bold'}}>Số người: {totalCustomers}/Số thẻ: {totalCards}</div>
          <div style={{fontSize: 10}}>(*) Người dùng CHƯA CÓ thẻ hoặc CÓ thẻ nằm trong danh sách thẻ đen sẽ không được đưa vào danh sách tham dự.</div>
        </DialogTitle>
        <DialogContent>
          <TextField
              id="standard-name"
              label="Tìm kiếm"
              className={classes.textField}
              onChange={(e) => handleChange(e)}
              margin="normal"
          />
          <Paper className={classes.root}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Tên KH</TableCell>
                  <TableCell align="right">Giới tính</TableCell>
                  <TableCell align="right">Ngày sinh</TableCell>
                  <TableCell align="right">Số thẻ</TableCell>
                  <TableCell align="right">Đơn vị</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map(customer => (
                    <TableRow key={customer.id}>
                      <TableCell component="th" scope="row">
                        {customer.name}
                      </TableCell>
                      <TableCell align="right">{customer.gender ? customer.gender : ''}</TableCell>
                      <TableCell align="right">{customer.dob ? customer.dob : ''}</TableCell>
                      <TableCell align="right">{customer.cardNo ? customer.cardNo.split(',').join(', ') : ''}</TableCell>
                      <TableCell align="right">{customer.unitName ? customer.unitName.split(',').join(', ') : ''}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={totalCustomers}
                rowsPerPage={size}
                page={page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default compose(
    translate,
    withStyles(styles)
)(WhiteListDialog)
