import React, {useRef, useState} from 'react';
import {ClickAwayListener, Grow, IconButton, Paper, Popper, Tooltip} from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';

const CircleBox = ({size = 40, icon}) => {
    return <div aria-controls="lock-menu" style={{
        width: size,
        height: size,
        borderRadius: size,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#c5c5c559',
    }}>
        {icon}
    </div>
}

export const LinkingApp = ({name, logo, href}) => {
    const [isHover, setIsHover] = useState(false)
    return <Tooltip title={name}>
        <a
            target={'_blank'}
            href={href}
            onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}
            style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center',
                cursor: 'pointer',
                width: 120,
                boxSizing: 'border-box',
                background: isHover && '#f7f7f7',
                padding: '8px',
                color: 'black',
                textDecoration: 'none'
            }}>
            <CircleBox size={40} icon={<img style={{width: 25, height: 'auto'}} src={logo}/>}/>
            <div style={{
                marginTop: 8,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: '100%',
                textAlign: 'center'
            }}>
                {name}
            </div>
        </a>
    </Tooltip>
}

const LinkingAppToolbar = ({apps = []}) => {
    const [open, setOpen] = useState(false);
    const anchorEl = useRef(null);

    const handleToggle = (event) => {
        anchorEl.current = event.currentTarget
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event) => {
        event.stopPropagation()
        setOpen(false);
    };

    if (apps.length === 0) {
        return <></>
    }

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <React.Fragment>
                <IconButton
                    variant={"fab"}
                    aria-owns={open ? 'menu-list-grow' : null}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    size={'small'}
                    style={open ? {backgroundColor: 'rgba(0, 0, 0, 0.08)'} : {}}
                >
                    <AppsIcon style={{color: 'white'}}/>
                </IconButton>
                <Popper open={open} anchorEl={anchorEl.current} transition style={{zIndex: 1000}} placement={'bottom-end'}>
                    {({TransitionProps}) => (
                        <Grow {...TransitionProps} id="menu-list-grow">
                            <Paper style={{padding: 16, margin: 8}}>
                                <div style={{
                                    width: 360,
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '16px 0',
                                }}>
                                    {apps.map(app => <LinkingApp key={app.id} {...app}/>)}
                                </div>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </React.Fragment>
        </ClickAwayListener>

    )
};

export default LinkingAppToolbar;
