import React from 'react';
import {BooleanField, Datagrid, Filter, List, TextField, TextInput} from 'react-admin';
import {AdmissionAdditionalServiceField} from "../../components/AdmissionAdditionalService";

const MonTuChonFilter = (props) => (
    <Filter {...props} >
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const AdmissionAdditionalServiceList = props => (
    <List filters={<MonTuChonFilter/>} {...props}
          exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <BooleanField source="forced"/>
            <BooleanField source="defaultValue"/>
            <AdmissionAdditionalServiceField source="type"/>
        </Datagrid>
    </List>
);

export default AdmissionAdditionalServiceList
