import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Icon from '@material-ui/core/Icon';
import moment from "moment";

const classes = {
    root: {
        width: '100%',
        marginTop: 10,
    },
    paper: {
        marginTop: 3,
        width: '100%',
        overflowX: 'auto',
        marginBottom: 2,
    },
    table: {
        minWidth: 650,
    },
    icon: {
        fontSize: 18,
    },
};

const DAY_OF_WEEK = {
    MONDAY: 2,
    TUESDAY: 3,
    WEDNESDAY: 4,
    THURSDAY: 5,
    FRIDAY: 6,
    SATURDAY: 7,
    SUNDAY: 'CN'
}

class FullDayCalendarBatchValidation extends Component {
    render() {
        return (
            <div style={classes.root}>
                <Paper style={classes.paper}>
                    <Table style={classes.table} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{padding: '4px 24px'}}>STT</TableCell>
                                <TableCell style={{padding: '4px 24px'}}>Hợp Lệ</TableCell>
                                <TableCell>Mô tả lỗi</TableCell>
                                <TableCell>Mã VinaID</TableCell>
                                <TableCell>Lớp</TableCell>
                                <TableCell>Họ và tên</TableCell>
                                <TableCell>Ngày sinh</TableCell>
                                <TableCell>Giới tính</TableCell>
                                <TableCell>Bán trú có ăn</TableCell>
                                <TableCell>Ngày ăn trong tuần</TableCell>
                                <TableCell>Thời gian ĐK</TableCell>
                                <TableCell>Thời gian huỷ</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.data.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell component="th" scope="row" style={{padding: '0 24px'}}>
                                        {row.order}
                                    </TableCell>
                                    <TableCell align="right" style={{padding: '4px 24px'}}>
                                        {row.status === 'VALID' &&
                                        <Icon style={classes.icon} className='fa fa-check' color="primary"/>
                                        }
                                        {row.status === 'INVALID' &&
                                        <Icon style={classes.icon} className='fa fa-times' color="error"/>
                                        }
                                    </TableCell>
                                    <TableCell>{row.description || '-'}</TableCell>
                                    <TableCell>{row.customerId}</TableCell>
                                    <TableCell>{row.unitName}</TableCell>
                                    <TableCell>{row.customerName}</TableCell>
                                    <TableCell>{row.dob}</TableCell>
                                    <TableCell>{row.gender}</TableCell>
                                    <TableCell>{row.regisEat ? 'x' : ''}</TableCell>
                                    <TableCell>{row.registerDow?.split(" ").map(day => DAY_OF_WEEK[day]).join(" ")}</TableCell>
                                    <TableCell>{moment(row.registerFrom).format("DD/MM/YYYY")}</TableCell>
                                    <TableCell>{moment(row.registerUntil).isValid() ? moment(row.registerUntil).format("DD/MM/YYYY") : '-'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={this.props.total}
                        rowsPerPage={this.props.rowsPerPage}
                        page={this.props.page}
                        backIconButtonprops={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonprops={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={this.props.handleChangePage}
                        onChangeRowsPerPage={this.props.onChangeRowsPerPage}
                    />
                </Paper>
            </div>
        );
    }
}

export default FullDayCalendarBatchValidation;
