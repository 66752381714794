import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reset, change, submit, isSubmitting } from 'redux-form';
import {
    fetchEnd,
    fetchStart,
    refreshView,
    showNotification,
    Button,
    SaveButton,
    SimpleForm,
    CREATE, UPDATE,
    withDataProvider,
} from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withStyles from '@material-ui/core/styles/withStyles';

const CUSTOM_FORM_NAME = 'CUSTOM_FORM_NAME'

const styles = {
  dialog: {
    zIndex: '1 !important'
  }
}

// @see https://marmelab.com/blog/2018/08/27/react-admin-tutorials-custom-forms-related-records.html
// PostQuickCreateButton.js in https://codesandbox.io/s/ypp9ljxqlj
class DetailRecordEditingButton extends React.Component {
  state = {
    error: false,
    showDialog: false,
  };


  handleClick = async () => {
    this.setState({ showDialog: true });

    // Set value for fields
    const { dispatch, record } = this.props;
    await dispatch(reset(CUSTOM_FORM_NAME));
    for(let propertyName in record) {
      const propertyValue = record[propertyName]
      await dispatch(change(CUSTOM_FORM_NAME, propertyName, propertyValue));
    }
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleSaveClick = () => {
    const { submit } = this.props;

    // Trigger a submit of our custom quick create form
    // This is needed because our modal action buttons are oustide the form
    submit(CUSTOM_FORM_NAME);
  };

  handleSubmit = values => {
    const {
      isCreateButton,
      detailResource,
      dataProvider,
      refreshView,
      fetchStart,
      fetchEnd,
      showNotification,
    } = this.props;

    // New record to be posted to server
    const record = Object.assign(this.props.record, values)

    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    // As we want to know when the new data has been created in order to close the modal,
    // we use the dataProvider directly
    dataProvider(isCreateButton ? CREATE : UPDATE, detailResource, { id: record.id, data: record })
    .then(({ data }) => {
      // Refresh the page
      refreshView();

      this.setState({ showDialog: false });
    })
    .catch(error => {
      showNotification(error.message, 'error');
    })
    .finally(() => {
      // Dispatch an action letting react-admin know a API call has ended
      fetchEnd();
    })
  };

  render() {
    const { showDialog } = this.state;
    const {classes, isSubmitting, isCreateButton, detailResource, title, children, customLabel, variant = false, showBtn = true } = this.props;

    return (
      <React.Fragment>
        {showBtn &&
        (
            isCreateButton ?
                variant ?
                    <Button variant="contained" onClick={this.handleClick}
                            label={customLabel ? customLabel : 'ra.action.add'}>
                      <AddIcon/>
                    </Button> :
                    <Button onClick={this.handleClick}
                            label={customLabel ? customLabel : 'ra.action.add'}>
                      <AddIcon/>
                    </Button>
                :
                <Button onClick={this.handleClick}
                        label={customLabel ? customLabel : 'ra.action.edit'}>
                  <EditIcon/>
                </Button>
        )
        }

        <Dialog
          className={classes.dialog}
          fullWidth={true}
          disableBackdropClick={true}
          maxWidth='sm'
          open={showDialog}
          onClose={this.handleCloseClick}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <SimpleForm
              // We override the redux-form name to avoid collision with the react-admin main form
              form={CUSTOM_FORM_NAME}
              resource={detailResource}
              // We override the redux-form onSubmit prop to handle the submission ourselves
              onSubmit={this.handleSubmit}
              // We want no toolbar at all as we have our modal actions
              toolbar={null}
            >
              { children }
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick} >
              <CancelIcon />
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isSubmitting: isSubmitting(CUSTOM_FORM_NAME)(state)
});
const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  refreshView
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
  withDataProvider,
)(DetailRecordEditingButton);
