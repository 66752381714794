import SuperAdminUserIcon from '@material-ui/icons/ViewList';

import SuperAdminUserList from './SuperAdminUserList';
import SuperAdminUserCreate from './SuperAdminUserCreate';
import SuperAdminUserEdit from './SuperAdminUserEdit';

export default {
  list: SuperAdminUserList,
  create: SuperAdminUserCreate,
  edit: SuperAdminUserEdit,
  icon: SuperAdminUserIcon,
};
