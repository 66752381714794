import React, {useEffect, useState} from 'react';
import {BooleanInput, maxLength, required, showNotification, TextInput, translate} from 'react-admin';
import {Redirect} from 'react-router-dom';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import {compose} from "redux";
import {connect} from "react-redux";
import withStyles from '@material-ui/core/styles/withStyles';
import {Button, Grid} from '@material-ui/core'
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const styles = {
    button: {
        margin: 1,
    },
    selectArray: {
        minWidth: '90%',
    },
    width90: {
        width: '90%',
    },
    label: {fontWeight: 'bold', fontSize: 16},
    cancelBtn: {
        marginLeft: 20
    },
    checkboxGroup: {
        "& > div ": {
            flexDirection: 'column',
        }
    },
    customCheckBox: {
        "& > div > label ": {
            width: '100%'
        }
    }
};
const AdmissionResultTemplateFields = props => {
    const [values, setValues] = useState({
        isRedirect: false,
        refreshList: false
    });
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const {
        defaultValue, save
    } = props;

    useEffect(() => {
        if (defaultValue.isRedirect) {
            setValues({isRedirect: true, refreshList: true});
        }
    }, [defaultValue.isRedirect]);
    useEffect(() => {
        if (defaultValue.ready) {
            setValues({...values, ...defaultValue})
            const html = defaultValue?.merged?.description;
            if (html) {
                const contentBlock = htmlToDraft(html);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    setEditorState(EditorState.createWithContent(contentState))
                }
            }
        }
    }, [defaultValue.ready]);

    function cancel() {
        setValues({isRedirect: true, refreshList: false});
    }

    function onEditorStateBlur(event, editorState) {
        props.changeContent(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }

    function onEditorStateChange(value) {
        setEditorState(value)
        props.changeContent(draftToHtml(convertToRaw(value.getCurrentContent())))
    }

    function onSave() {
        save()
    }

    if (values.isRedirect) {
        return <Redirect to={'/admissionResultTemplates?refreshView=' + values.refreshList}/>;
    } else {
        return (
            <React.Fragment>
                <Grid item xs={12} sm={6}>
                    <TextInput source="name" label="resources.admissionResultTemplates.fields.name"
                               onChange={props.onChange('name')} style={styles.width90}
                               validate={[required(), maxLength(50, 'Không quá 50 ký tự')]}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <BooleanInput source="active" label="resources.admissionResultTemplates.fields.active"
                                  onChange={props.onChange('active')}/>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <div>Nội dung</div>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        editorStyle={{background: 'white', minHeight: 250}}
                        onEditorStateChange={onEditorStateChange}
                    />
                    <textarea
                        disabled
                        style={{width: '100%', minHeight: 250, marginTop: 16}}
                        value={draftToHtml(convertToRaw(editorState?.getCurrentContent()))}
                    />
                </Grid>
                <Grid item xs={12} sm={12} style={{marginTop: 15}}>
                    <Button variant="contained"
                            color="primary"
                            style={styles.button}
                            disabled={defaultValue.saveDisabled}
                            onClick={onSave}>
                        <SaveIcon/> Lưu
                    </Button>
                    <Button variant="contained" style={styles.cancelBtn}
                            onClick={cancel}>
                        <CancelIcon/> Hủy
                    </Button>
                </Grid>
            </React.Fragment>
        )
    }
}
export default compose(
    translate, connect(null, {showNotification}),
    withStyles(styles)
)(AdmissionResultTemplateFields)

