import AccessDeviceIcon from '@material-ui/icons/ViewList';

import AccessDeviceList from './AccessDeviceList';
import AccessDeviceCreate from './AccessDeviceCreate';
import AccessDeviceEdit from './AccessDeviceEdit';

export default {
  list: AccessDeviceList,
  create: AccessDeviceCreate,
  edit: AccessDeviceEdit,
  icon: AccessDeviceIcon,
};
