import React from 'react';
import Grid from '@material-ui/core/Grid'
import { DateInput, LongTextInput, TextInput, BooleanInput } from 'react-admin';

const ArchiveMessageLogFields = ({className, ...props}) => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <DateInput source="createdOn" disabled {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <TextInput source="customerName" disabled {...props} />
    </Grid>

    <Grid item xs={12} sm={6} >
      <TextInput source="channel" disabled {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
    </Grid>

    <Grid item xs={12} sm={12} >
      <LongTextInput source="message" disabled {...props} />
    </Grid>
  </React.Fragment>
)

export default ArchiveMessageLogFields
