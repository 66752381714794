import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Button, refreshView} from 'react-admin';
import axiosInstance from "../../api/axios";
import {showErrorNotify, showSuccessNotify} from "../../components";
import Swal from "sweetalert2";

const ActiveDeactiveButton = ({permissions, ...props}) => {
    const lock = () => {
        Swal.fire({
            title: 'Xác nhận',
            text: `Bạn muốn khóa đăng ký tuyển sinh này? Sau khi khóa cần được cấp quyền để mở khóa.`,
            type: "warning",
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
            focusConfirm: false,
        }).then(async (result) => {
            if (result.value) {
                doAction(false)
            }
        })
    }
    const unlock = () => {
        Swal.fire({
            title: 'Xác nhận',
            text: `Bạn muốn mở khóa đăng ký tuyển sinh này?`,
            type: "warning",
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
            focusConfirm: false,
        }).then(async (result) => {
            if (result.value) {
                doAction(true)
            }
        })
    }
    const doAction = (type) => {
        axiosInstance.put(`/api${props.basePath}/changeStatus?active=` + type, props.selectedIds).then(() => {
            showSuccessNotify("Thay đổi trạng thái thành công.");
            setTimeout(() => {
                window.location.reload()
            }, 1500)
        }).catch(() => {
            showErrorNotify("Có lỗi, vui lòng thử lại hoặc liên hệ quản trị viên.");
        })
    }

    return (
        <div>
            <Button variant="outlined" label="Khoá" onClick={lock}/>
            {permissions && permissions.includes('CHECKER') &&
            <Button style={{marginLeft: '10px'}} variant="outlined" label="Mở khoá" onClick={unlock}/>
            }
        </div>
    );
}

export default connect(null, {refreshView})(
    ActiveDeactiveButton
);

