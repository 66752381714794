import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Button, refreshView} from 'react-admin';
import {showErrorNotify, showInfoNotify, showSuccessNotify} from "../../components";
import env from "../../env";
import {post} from "axios";
import axiosInstance from "../../api/axios";
import Swal from "sweetalert2";
import {CircularProgress} from "@material-ui/core";

const AuthorizeCardApplicationButton = ({countCanAddToCart, countInCart, searchParams}) => {
    const [loading, setLoading] = useState(false)

    const authorizeAllItem = () => {
        const url = `${env.baseURL}/api/profileChangeLogReadys/authorizeCardApplication`;
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': token
            }
        }
        return post(url, {}, config)
    }

    const onAdd = (e) => {
        setLoading(true)
        e.stopPropagation();
        showInfoNotify("Đang đồng bộ, vui lòng đợi trong giây lát!");
        authorizeAllItem().then(rs => {
            if (rs.data.success) {
                showSuccessNotify("Đã đồng bộ dữ liệu thành công!");
                setTimeout(() => {
                    window.location.reload()
                }, 3000)
            } else {
                showErrorNotify("Đồng bộ không thành công. Vui lòng liên hệ quản trị viên!");
            }
            setLoading(false)
        }).catch(error => {
            setLoading(false)
            showErrorNotify("Có lỗi khi đồng bộ. Vui lòng liên hệ quản trị viên!");
        });
    }

    const onAddAll = async () => {
        const params = new URLSearchParams(searchParams)
        const url = `/api/profileChangeLogReadys/cart/checkAddAll?${params}`;
        const res = await axiosInstance.get(url)
        if (res.status === 200 && res.data) {
            if (!res.data.countCanAddToCart) {
                showInfoNotify("Không có dữ liệu cần thêm vào lô cấp thẻ!");
                return;
            }
            Swal.fire({
                title: 'Xác nhận',
                text: `Bạn muốn thêm ${res.data.countCanAddToCart} trường hợp vào lô yêu cầu cấp thẻ?`,
                type: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                focusConfirm: false,
            }).then(async (result) => {
                if (result.value) {
                    const url = `/api/profileChangeLogReadys/cart/all?${params}`;
                    const res = await axiosInstance.post(url)
                    if (res.status === 200) {
                        showSuccessNotify("Thêm tất cả vào lô thành công!");
                        setTimeout(() => {
                            window.location.reload()
                        }, 1500)
                    } else {
                        showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!");
                    }
                }
            })
        } else {
            showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!");
        }
    }

    const onRemoveAll = async () => {
        Swal.fire({
            title: 'Xác nhận',
            text: `Bạn muốn loại tất cả ${countInCart} yêu cầu cấp thẻ?`,
            type: "warning",
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
            focusConfirm: false,
        })
            .then(async (result) => {
                if (result.value) {
                    const url = `/api/profileChangeLogReadys/cart/all`;
                    const res = await axiosInstance.delete(url)
                    if (res.status === 200) {
                        showSuccessNotify("Xoá tất cả vào lô thành công!");
                        setTimeout(() => {
                            window.location.reload()
                        }, 1000)
                    } else {
                        showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!");
                    }
                }
            })
    }
    return (
        <div style={{float: 'right', marginTop: 20}}>
            {
                loading && <CircularProgress style={{marginRight: 10}} color="secondary"/>
            }
            <Button color="primary" variant={'outlined'}
                    disabled={loading}
                    onClick={onAdd} label={'Đồng bộ dữ liệu mở thẻ'}
                    style={{marginRight: 8}}
            >
            </Button>
            <Button color="primary" variant={'contained'}
                    style={{marginRight: 8}}
                    disabled={loading || countCanAddToCart === 0}
                    onClick={onAddAll} label={'THÊM TẤT CẢ VÀO LÔ'}>
            </Button>
            <Button color="primary" variant={'contained'}
                    disabled={loading || countInCart === 0}
                    onClick={onRemoveAll} label={'LOẠI TẤT CẢ KHỎI LÔ'}>
            </Button>
        </div>
    )
}


export default connect(undefined, {refreshView})(AuthorizeCardApplicationButton)
