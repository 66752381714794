import AccessDeviceIcon from '@material-ui/icons/ViewList';

import ClazzBatchList from './ClazzBatchList';
import IntroductionFileCreate from './ClazzBatchCreate';

export default {
  list: ClazzBatchList,
  create: IntroductionFileCreate,
  icon: AccessDeviceIcon,
};
