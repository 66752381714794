import React from 'react';
import { Edit, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import ExemptionFields from './ExemptionPolicyFields'

export const ExemptionPolicyEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <Grid container spacing={8}>
        <ExemptionFields />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default ExemptionPolicyEdit
