import React from 'react';
import Grid from '@material-ui/core/Grid'
import {LongTextInput, TextInput, required, ReferenceInput, AutocompleteInput} from 'react-admin';
import env from "../../env";
import {ShortenId} from "../../components";

const CompanyFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="homeroomTeacher" reference="employeeEnrollments" allowEmpty {...props} >
                <AutocompleteInput optionText="customerName"/>
            </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6}>
            <LongTextInput source="address" {...props} />
        </Grid>

        <Grid item xs={12} sm={6}>
            <TextInput source="contactName" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="contactPhone" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="email" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="website" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="internalInformation" {...props} />
        </Grid>
        {props.record.id &&
        <Grid item xs={12} sm={12}>
            <img src={`${env.baseURL}/public/org-logo/${ShortenId.encode(props.record.id)}`}
                 alt="" style={{maxWidth: '250px'}}
            />
        </Grid>
        }

    </React.Fragment>
)

export default CompanyFields
