import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const categories = [
    {id: 'SENT_MANUALLY_BY_USER', name: 'messageCategory.SENT_MANUALLY_BY_USER'},
    {id: 'BAO_BAI', name: 'messageCategory.BAO_BAI'},
    {id: 'HOAT_DONG', name: 'messageCategory.HOAT_DONG'},
    {id: 'ADMISSION_NOTIFICATION', name: 'messageCategory.ADMISSION_NOTIFICATION'},
]

export const MessageCategoryField = (props) => (
    <SelectField source={props.source} choices={categories} {...props} />
)

export const MessageCategoryInput = (props) => (
    <SelectInput source={props.source} choices={categories} {...props} />
)

