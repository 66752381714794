import React, {Component} from 'react';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import env from '../../env';
import './style.css';
import {get} from "axios";

class OnlineAttendanceInfo extends Component {

    total = 0;
    countParticipants = 0;
    countAbsent = 0;
    countMale = 0;
    countFemale = 0;
    eventId = 0;
    stompClient;
    serverUrl = env.wsURL;
    participants = [];
    lastParticipant = {};
    selectedUnitIds = [];

    componentWillMount() {
        let root = document.documentElement;
        const bg = 'url("' + env.baseURL + '/files/background/' + localStorage.getItem('tenantId') + '.jpg")';
        root.style.setProperty('--url-bg', bg )
        this.eventId = this.props.match.params.eventId;

        this.getUnitsOfEvent().then(rs => {
            if (rs.data) {
                for (let i = 0; i < rs.data.length; i++) {
                    const unit = rs.data[i];
                    this.selectedUnitIds.push(unit.id);
                }
            }
            this.openWS();
        });
    }

    getUnitsOfEvent() {
        const url = `${env.baseURL}/api/units/getUnitsOfEvent?eventId=` + this.eventId;
        return get(url, this.getConfig());
    }

    openWS() {
        const token = localStorage.getItem('token');
        const config = this.getConfig();
        const socket = new SockJS(
            `${this.serverUrl}?token=${encodeURIComponent(token)}`
        );
        this.stompClient = Stomp.over(socket);
        this.stompClient.debug = null;
        const that = this;
        this.stompClient.connect({
            name: this.eventId,
            Authorization: token
        }, function (frame) {
            that.stompClient.send('/app/getRecentlyAttendanceInfo', config, JSON.stringify({
                'eventId': that.eventId,
                'unitIds': that.selectedUnitIds
            }));
            that.stompClient.subscribe('/user/queue/returnRecentlyAttendanceInfo', message => {
                that.lastParticipant = null;
                that.forceUpdate();
                if (message.body) {
                    const attendanceInfo = JSON.parse(message.body);
                    const newParticipants = attendanceInfo.newParticipants;
                    if (newParticipants.length > 0) {
                        that.lastParticipant = newParticipants[newParticipants.length - 1];
                    }
                    that.forceUpdate()
                }
            }, {Authorization: token});
        });
    }

    getConfig() {
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': token
            }
        };
        return config;
    }

    validate = (values) => {
        const errors = {};

        // Validate
        if (this.eventId == null) {
            errors.eventId = 'event  id must not be null'
        }

        return errors;
    };

    render() {
        return (
            <div>
                <div className='bodyDiv2'>
                    {/*<span className='welcomeText'>CHÚC MỪNG HỌC SINH</span>*/}
                    <div className='imgContent'>
                        {(this.lastParticipant != null && this.lastParticipant.photoName) ?
                            <img className='imgStyle'
                                 src={`${env.baseURL}/cus-photo/${this.lastParticipant.photoName}`}
                                 alt=""
                            />
                            : ''
                        }
                    </div>
                    <div className='welcomeName2'>
                        <span>{this.lastParticipant != null ? this.lastParticipant.fullName : ''}</span>
                    </div>
                    {/*<div className='welcomeTitle2'>*/}
                    {/*    <span>{this.lastParticipant != null ? this.lastParticipant.title : ''}</span>*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
}

export default OnlineAttendanceInfo

