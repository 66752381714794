import React from 'react';
import {CardActions, CreateButton, Datagrid, Filter, List, TextField, TextInput} from 'react-admin';
import {
    BillAuthStatusField,
    DateTimeField,
    DownloadFileContentButton,
    DownloadTemplateFileButton
} from "../../components";

const CustomerInfoBatchFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

const PostActions = ({
                         bulkActions,
                         basePath,
                         currentSort,
                         displayedFilters,
                         exporter,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter,
                         total
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {/* Add your custom actions */}
        <DownloadTemplateFileButton basePath="/api/clazzBatches/getTemplate"
                                    btnLabel="ra.action.downloadTemplate"/>
        <CreateButton basePath={basePath}/>
    </CardActions>
);
export const ClazzBatchList = props => (
    <List filters={<CustomerInfoBatchFilter/>} {...props} exporter={false} actions={<PostActions/>}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="description"/>
            <BillAuthStatusField source="authStatus"/>
            <TextField source="lastUpdatedBy"/>
            <DateTimeField source="lastUpdatedOn" format="DD/MM/YYYY HH:mm"/>
            <DownloadFileContentButton/>
        </Datagrid>
    </List>
);

export default ClazzBatchList
