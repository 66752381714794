import React from 'react';
import {Redirect} from 'react-router-dom';
import {Create, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import StudentEnrollmentFields from './StudentEnrollmentFields'
import EnrollmentMigration from "./EnrollmentMigration";

class StudentEnrollmentCreate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isRedirect: false
    }
    this.onRedirect = this.onRedirect.bind(this);
  }

  onRedirect() {
    this.setState({
      isRedirect: true
    })
  }

  render() {
    if (this.state.isRedirect) {
      return <Redirect to='/migrate-student'/>;
    }
    return (
        <React.Fragment>
          <Create {...this.props}>
            <SimpleForm toolbar={<CustomToolbar />} redirect="list" >
              <Grid container spacing={8}>
                <StudentEnrollmentFields />
              </Grid>
            </SimpleForm>
          </Create>
        </React.Fragment>
    );
  }

}

export default StudentEnrollmentCreate
