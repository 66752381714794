import React from 'react';
import {BooleanField, Datagrid, Filter, List, TextField, TextInput} from 'react-admin';

const BankStatementTemplateFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const BankStatementTemplateList = props => (
  <List filters={<BankStatementTemplateFilter />} {...props}  exporter={false} >
    <Datagrid rowClick="edit" >
      <TextField source="name" />
      <BooleanField source="active" />
    </Datagrid>
</List>
);

export default BankStatementTemplateList
