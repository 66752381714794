import React, {Component} from 'react';
import {Button} from 'react-admin';
import env from "../env";
import PrintIcon from '@material-ui/icons/Print';
import {showErrorNotify} from './ShowNotify'
import {downloadFile} from "./FileDownload";

class PrintQrInfoLinkButton extends Component {
    state = {
        type: ''
    };
    constructor(props) {
        super(props);
        this.state.type = props.type
        if (props.record) {
        }
    }

    handleClick = (e) => {
        e.stopPropagation()
        const record = this.props.record;
        if (record) {
            downloadFile(`${env.baseURL}/reports/getQrLink?type=${this.state.type}&customerId=${record.customerId}`, 'ma_qr.xlsx')
        } else {
            showErrorNotify("Vui lòng chọn khách hàng")
        }
    };

    render() {
        return (
            <Button onClick={this.handleClick} label="ra.action.printQr">
                <PrintIcon/>
            </Button>
        );
    }
}

export default PrintQrInfoLinkButton;
