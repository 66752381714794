import React from 'react';
import { connect } from 'react-redux'
import { change } from 'redux-form'

import AuthorizeIcon from '@material-ui/icons/Done';
import { SaveButton, REDUX_FORM_NAME  } from 'react-admin';

class AuthorizeButton extends React.Component {
  handleClick = async () => {
    const { dispatch, handleSubmitWithRedirect, redirect } = this.props;

    await dispatch(change(REDUX_FORM_NAME, 'authStatus', 'AUTHORIZED'));
    handleSubmitWithRedirect(redirect)()
  }

  render() {
    const { handleSubmitWithRedirect, ...props } = this.props;

    return (
      <SaveButton
        icon={<AuthorizeIcon />}
        label='ra.action.authorize'
        onClick={this.handleClick}
        {...props}
      />
    )
  }
}

export default connect(null, null)(AuthorizeButton)
