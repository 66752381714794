import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {BooleanInput, DateInput, LongTextInput, required, Title, translate} from 'react-admin';
import {NotifyForm, showErrorNotify, showInfoNotify, showSuccessNotify} from '../../components';
import env from '../../env';
import axios from "axios";
import moment from "moment";
import TreeCheckBoxCustomInput from "../../components/TreeCheckBoxCustomInput";
import cachedApis from "../../api/cachedApis";
import utils from "../../utils/utils";

class ThongBaoHocPhi extends React.Component {
    state = {
        displayDueDate: true,
        displayDept: true,
        isLoading: false,
        billMonth: moment().format('YYYY-MM-DD'),
        customers: [],
        customerIds: [],
        params: {
            note: '',
        }
    };

    componentDidMount() {
        cachedApis.getCustomerUnits(false).then(rs => {
            this.setState({customers: rs})
        })
    }

    notifyAllStudentsUrl = () => {
        if (!this.state.params.note) {
            this.state.params.note = '';
        }
        this.setState({isLoading: true})
        showInfoNotify("Đang gửi thông báo. Vui lòng đợi trong giây lát!");
        const data = {
            note: this.state.params.note,
            displayDueDate: this.state.params.displayDueDate,
            displayDept: this.state.params.displayDept,
            billMonthStr: this.state.params.billMonth,
            customerIds: this.state.customerIds
        }
        const url = `${env.baseURL}/api/billBatches/send_notify`
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': token
            }
        };
        return axios.post(url, data, config)
    };

    sendNotify = () => {
        if (!this.state.customerIds || this.state.customerIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách người nhận báo thông báo")
            return;
        }

        this.notifyAllStudentsUrl().then(rs => {
            this.setState({isLoading: false})
            if (rs.data) {
                showSuccessNotify("Gửi thông báo thành công!");
                this.state.showBtn = false;
                this.forceUpdate()
            } else {
                showErrorNotify("Gửi thông báo không thành công. Vui lòng kiểm tra hoặc liên hệ quản trị viên!");
            }
        }, err => {
            this.setState({isLoading: false})
            showErrorNotify(err.message);
        });
    };

    validate = (values) => {
        const errors = {};

        // Copy input values to params
        this.setState({params: values})

        return errors;
    }

    render() {
        return (
          <Card>
              <Title title="resources.thongBaoHocPhi.name" />
              <CardContent>
                  <NotifyForm validate={this.validate}
                              allClazzes={this.sendNotify}
                              isLoading={this.state.isLoading}
                              label={this.props.translate('resources.thongBaoHocPhi.sendNotify')}
                              params={this.state}
                  >
                      <Grid container spacing={8}>
                          <Grid item xs={12} sm={6} >
                              <DateInput label="resources.common.month" source="billMonth" validate={required()} {...this.props} />
                          </Grid>
                          <Grid item xs={12} sm={6} >
                          </Grid>
                      </Grid>
                      <Grid container spacing={8}>
                          <Grid item xs={12} sm={6} >
                              <BooleanInput label="resources.thongBaoHocPhi.displayDueDate" source="displayDueDate" {...this.props}  />
                          </Grid>
                          <Grid item xs={12} sm={6} >
                              <BooleanInput label="resources.thongBaoHocPhi.displayDept" source="displayDept" {...this.props} />
                          </Grid>
                      </Grid>
                      <Grid container spacing={8}>
                          <Grid item xs={12} sm={6}>
                              <Grid item xs={12} sm={6} >
                                  <LongTextInput label="resources.thongBaoHocPhi.note"
                                                 rows={5}
                                                 style={{maxWidth: '90%'}}
                                                 multiline
                                                 source="note" {...this.props} />
                              </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <label><b>Danh sách người nhận *</b></label>
                              <div style={{maxHeight: '300px', overflow: 'auto'}}>
                                  <TreeCheckBoxCustomInput data={this.state.customers}
                                                           expandTopLevel={true}
                                                           onChange={(e) => this.setState({customerIds: utils.extractCustomerIdsV2(e)})}
                                  />
                              </div>
                          </Grid>
                      </Grid>
                  </NotifyForm>
              </CardContent>
          </Card>
        )
    }
}

export default translate(ThongBaoHocPhi)

