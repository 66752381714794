import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const authStatuses = [
    {id: 'NORMAL', name: 'clazzType.NORMAL'},
    {id: 'FULLDAY', name: 'clazzType.FULLDAY'},
    {id: 'TERM', name: 'clazzType.TERM'},
    {id: 'EXTRA', name: 'clazzType.EXTRA'},
    {id: 'GROUP', name: 'clazzType.GROUP'},
    {id: 'TEMP', name: 'clazzType.TEMP'},
]

export const ClazzTypeField = (props) => (
    <SelectField source={props.source} choices={authStatuses} {...props} />
)

export const ClazzTypeInput = (props) => (
    <SelectInput source={props.source} choices={authStatuses} {...props} />
)
