import React, {Component} from 'react';
import {connect} from 'react-redux';
import {CircularProgress, Grid} from '@material-ui/core'
import {Edit, required, SimpleForm, TextInput} from 'react-admin';
import {CustomToolbar, FileUploadComponent, showInfoNotify, showSuccessNotify} from "../../components";
import {Redirect} from 'react-router-dom';
import {downloadFilePost} from "../../components/FileDownload";
import env from "../../env/env";

export class CustomerCertificateBatchEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            batchId: props.id,
            isLoading: false,
            showUploadFile: true
        };
        this.onFileUpload = this.onFileUpload.bind(this);
    }

    onFileUpload(e) {
        e.preventDefault(); // Stop form submit
        if (this.state.file == null) {
            return;
        }
        this.setState({
            isLoading: true
        });
        showInfoNotify("Đang xử lí file, vui lòng chờ trong giây lát!");
        const formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('id', this.state.batchId);
        downloadFilePost(env.baseURL + '/api/customerCertificateBatchUpload/validate-item', 'ket_qua.xlsx', formData).then(() => {
            showSuccessNotify("Đã xử lý thành công, vui lòng kiểm tra file kết quá");
            this.setState({
                isLoading: false,
                isRedirect: true
            });
        })

    }

    cancel = (e) => {
        this.setState({
            isRedirect: true
        })
    };


    render() {
        if (this.state.isRedirect) {
            return <Redirect to='/customerCertificateBatches'/>;
        }
        return (
            <Edit {...this.props}>
                <SimpleForm toolbar={<CustomToolbar/>}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <TextInput label="Mô tả" source="description" validate={required()}/>
                        </Grid>
                    </Grid>
                    <div style={{width: '100%'}}>
                        Lưu ý:
                        <p>
                            Sử dụng cấu trúc thư mục theo "Mẫu lô thư mục chứng chỉ"
                        </p>
                        <p>
                            Các file chứng chỉ vui lòng đặt tên không dấu, chỉ chấp nhận các file có định dạng
                            pdf, png, jpg, jpeg
                        </p>
                    </div>
                    <Grid container spacing={8}>

                        <FileUploadComponent onFileChanged={(e) => this.setState({file: e.target.files[0]})}
                                             onFileUpload={this.onFileUpload}
                        />
                        {
                            this.state.isLoading && <CircularProgress color="secondary"/>
                        }
                    </Grid>
                </SimpleForm>
            </Edit>
        )
    }
}

export default connect(null, null)(CustomerCertificateBatchEdit);
