import React from 'react';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid'

import {BooleanInput, DateInput, required, TextInput, translate} from 'react-admin';

const styles = {
  checkboxGroup: {
    "& > fieldset > div ": {
      flexDirection: 'column',
    }
  }
}

const BillBatchFields = ({classes, ...props}) => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <TextInput style={{width: '50%'}} source="description" validate={required()} {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <TextInput style={{width: '50%'}} source="fullDescription" {...props} />
    </Grid>
    <Grid item xs={12} sm={6} >
      <DateInput source="month" validate={required()} {...props} />
    </Grid>
      <Grid item xs={12} sm={6}>
          <DateInput source="dueDate" validate={required()} {...props} />
      </Grid>

      <Grid item xs={12} sm={6}>
          <BooleanInput source="active"  {...props} />
      </Grid>
      <Grid item xs={12} sm={6}>
          <TextInput type="number" source="priority" {...props} />
      </Grid>
      {/*<Grid item xs={12} sm={6} >*/}
      {/*    <ReferenceInput style={{width: '50%'}}*/}
      {/*        source="tenantBankAccountId"*/}
      {/*                    reference="tenantBankAccounts"*/}
      {/*                    perPage={20}*/}
      {/*                    {...props} >*/}
      {/*        <SelectInput optionText="displayName"/>*/}
      {/*    </ReferenceInput>*/}
      {/*</Grid>*/}
  </React.Fragment>
)

export default compose(
  translate,
  withStyles(styles)
)(BillBatchFields)
