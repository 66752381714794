import React from 'react';
import {
    CardActions,
    CreateButton,
    Datagrid,
    Filter,
    List,
    ReferenceField,
    SelectField,
    TextField,
    TextInput
} from 'react-admin';
import {
    DeleteButtonWithAuthStatus,
    DownloadFileContentButton,
    DownloadTemplateFileButton,
    EditButtonWithAuthStatus
} from "../../components";
import {CardApplicationTypeField} from "../../components/CardApplicationType";

const CardApplicationBatchFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);


const PostActions = ({
                         bulkActions,
                         basePath,
                         currentSort,
                         displayedFilters,
                         exporter,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter,
                         total
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {/* Add your custom actions */}
        <DownloadTemplateFileButton basePath="/api/cardApplicationBatches/template/EMPLOYEE"
                                    btnLabel="ra.action.downloadTemplateEmpployeeCardApplication"/>
        <DownloadTemplateFileButton basePath="/api/cardApplicationBatches/template/TUYEN_SINH"
                                    btnLabel="ra.action.downloadTemplateTuyenSinhCardApplication"/>
        <DownloadTemplateFileButton basePath="/api/cardApplicationBatches/template/HS_C1"
                                    btnLabel="ra.action.downloadTemplateCardApplication"/>
        <DownloadTemplateFileButton basePath="/api/cardApplicationBatches/template/STUDENT"
                                    btnLabel="ra.action.downloadTemplateStudentCardApplication"/>
        <CreateButton basePath={basePath}/>
    </CardActions>
);

export const CardApplicationBatchList = props => (
    <List filters={<CardApplicationBatchFilter/>} {...props} actions={<PostActions/>} bulkActionButtons={false} exporter={false}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="name"/>
            {localStorage.getItem("tenantType") === 'SCHOOL' &&
            <ReferenceField source="schoolYearId" reference="schoolYears">
                <TextField source="name"/>
            </ReferenceField>
            }
            <CardApplicationTypeField source="type"/>
            <SelectField source="authStatus" choices={[
                {id: 'DRAFT', name: 'Bản nháp'},
                {id: 'REVIEW', name: 'Đang duyệt'},
                {id: 'SYNCING', name: 'Chờ đồng bộ'},
                {id: 'AUTHORIZED', name: 'Đã duyệt'},
            ]}/>
            <DownloadFileContentButton/>
            <EditButtonWithAuthStatus/>
            <DeleteButtonWithAuthStatus/>
        </Datagrid>
    </List>
);

export default CardApplicationBatchList
