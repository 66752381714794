import CompanyIcon from '@material-ui/icons/ViewList';

import CustomerHealthList from './CustomerHealthList';
import CustomerHealthCreate from './CustomerHealthCreate';
import CustomerHealthEdit from './CustomerHealthEdit';

export default {
  list: CustomerHealthList,
  create: CustomerHealthCreate,
  edit: CustomerHealthEdit,
  icon: CompanyIcon,
};
