import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid'
import {AutocompleteInput, Button, ReferenceInput, required, TextInput} from 'react-admin';
import env from '../../env';
import EmployeeUploadFileDialog from "./EmployeeUploadFileDialog";
import axiosInstance from "../../api/axios";
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';

const CustomerCertificate = ({data, onDelete}) => {

    const handleDelete = (fileId) => {
        onDelete(fileId)
        axiosInstance.delete(`/api/customer-certificate/${fileId}`)
    }

    if (data.length === 0)
        return <></>

    return <Table>
        <TableHead>
            <TableRow>
                <TableCell>STT</TableCell>
                <TableCell>Mô tả</TableCell>
                <TableCell>File</TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {data.map((item, idx) => <TableRow key={item.ggFileId}>
                <TableCell>{(idx + 1)}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>
                    <a href={item.link} target='_blank'>
                        <Button label='XEM' style={{padding: 0}}>
                            <VisibilityIcon/>
                        </Button>
                    </a>
                </TableCell>
                <TableCell>
                    <Button style={{color: 'red', display: 'flex', alignItems: 'flex-end'}} label='XOÁ'
                            onClick={() => handleDelete(item.ggFileId)}>
                        <div style={{display: 'flex'}}>
                            <DeleteIcon/>
                        </div>
                    </Button>
                </TableCell>
            </TableRow>)}
        </TableBody>
    </Table>
}

const EmployeeEnrollmentFields = props => {
    const [data, setData] = useState([])
    useEffect(() => {
        axiosInstance
            .get(`/api/customer-certificate/${props.record.customerId}`)
            .then(res => setData(res.data))
    }, [])

    const onDelete = async (fileId) => {
        setData(items => items.filter(item => item.ggFileId !== fileId))
    }

    const onAdd = async (item) => {
        setData(items => [...items, {...item}])
    }


    return (
        <React.Fragment>
            <Grid item xs={12} sm={6}>
                <ReferenceInput source="unitId" reference="departments"
                                validate={required()} allowEmpty {...props} >
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
            </Grid>

            <Grid item xs={12} sm={6}>
                <ReferenceInput source="customerId" reference="customers" validate={required()} allowEmpty {...props} >
                    <AutocompleteInput optionText="displayName"/>
                </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput source="title" {...props} />
            </Grid>

            <Grid item xs={12} sm={6}>
                {props.record.photoName &&
                <img src={`${env.baseURL}/cus-photo/${props.record.photoName}`}
                     alt="" style={{maxWidth: '250px'}}
                />
                }
            </Grid>

            <Grid item xs={12} sm={8}>
                <EmployeeUploadFileDialog customerId={props.record.customerId} onAdd={onAdd}/>
            </Grid>

            <Grid item xs={12} sm={12}>
                <CustomerCertificate data={data} onDelete={onDelete}/>
            </Grid>

        </React.Fragment>
    )
}

export default EmployeeEnrollmentFields
