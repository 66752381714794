import React, {useEffect, useRef, useState} from 'react';
import {CheckboxGroupInput, SelectInput, showNotification, Title} from "react-admin";
import {Card, CardContent, CircularProgress} from '@material-ui/core';
import TreeCheckBoxCustomInput from "../../components/TreeCheckBoxCustomInput";
import axiosInstance from "../../api/axios";
import {ReportForm, schoolYears, showErrorNotify, showSuccessNotify} from "../../components";
import {compose} from "redux";
import {connect} from "react-redux";
import utils from "../../utils/utils";

const FaceRemove = () => {
    const [customers, setCustomers] = useState([])
    const [devices, setDevices] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingData, setIsLoadingData] = useState(false)
    const ref = useRef({
        deviceNos: [],
        customerIds: [],
    })
    useEffect(() => {
        getCustomers(schoolYears[0]?.id)
        getDevices()
    }, [])

    const getDevices = () => {
        axiosInstance.get('/api/accessDevices/search/findByType?type=FACE').then(res => {
            if (res.status === 200 && res.data.content[0]?.id) {
                setDevices(res.data.content)
            }
        })
    }

    const getCustomers = (schoolYearId = '') => {
        setIsLoadingData(true)
        ref.current.customerIds = []
        axiosInstance
            .get(`/api/enrollmentForFaceId?schoolYearId=${schoolYearId}`)
            .then((res) => {
                const content = res.data?.content || []
                setCustomers(content)
            })
            .finally(() => setIsLoadingData(false))
    }

    const save = () => {
        const {customerIds, deviceNos} = ref.current
        setIsLoading(true)
        axiosInstance.post(`/api/face/delete-by-customerId?&deviceNos=${deviceNos}`, customerIds)
            .then(res => {
                showSuccessNotify('Xoá thành công')
            }, err => {
                showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên")
            })
            .finally(() => setIsLoading(false))
    }

    const onSchoolYearChange = (_, schoolYearId) => {
        getCustomers(schoolYearId)
    }
    return (
        <Card>
            <Title title="Xoá người dùng khỏi thiết bị"/>
            <CardContent>
                <ReportForm params={{schoolYear: schoolYears[0]?.id}}
                            htmlReport={save} label='Xoá khỏi thiết bị' isLoading={isLoading} color='error'>
                    {
                        devices?.length > 0 && <CheckboxGroupInput label='Thiết bị' source="deviceNos"
                                                                   choices={devices}
                                                                   optionText='name'
                                                                   optionValue='deviceNo'
                                                                   onChange={(_, values) => ref.current.deviceNos = values}
                        />
                    }

                    <SelectInput label="resources.schoolYears.name" source="schoolYear"
                                 choices={schoolYears}
                                 alwaysOn
                                 allowEmpty={false}
                                 onChange={onSchoolYearChange}
                    />
                    <label><b>Danh sách người tham dự</b></label>
                    <div style={{width: '50%', minHeight: 60, maxHeight: '300px', overflow: 'auto', marginTop: 10}}>
                        {isLoadingData
                            ? <CircularProgress color="secondary"/>
                            : <TreeCheckBoxCustomInput data={customers}
                                                  expandTopLevel={true}
                                                  onChange={values => ref.current.customerIds = utils.extractCustomerIdsV2(values)}/>
                        }
                    </div>


                </ReportForm>
            </CardContent>
        </Card>
    );
};

export default compose(
    connect(null, {showNotification}),
)(FaceRemove)
