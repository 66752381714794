import TenantBankAccountIcon from '@material-ui/icons/ViewList';

import TenantBankAccountList from './TenantBankAccountList';
import TenantBankAccountCreate from './TenantBankAccountCreate';
import TenantBankAccountEdit from './TenantBankAccountEdit';

export default {
  list: TenantBankAccountList,
  create: TenantBankAccountCreate,
  edit: TenantBankAccountEdit,
  icon: TenantBankAccountIcon,
};
