import RoleIcon from '@material-ui/icons/ViewList';

import RoleList from './RoleList';
import RoleCreate from './RoleCreate';

export default {
  list: RoleList,
  create: RoleCreate,
  edit: RoleCreate,
  icon: RoleIcon,
};
