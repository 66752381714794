import React from 'react';
import Grid from '@material-ui/core/Grid'
import { ReferenceInput, SelectInput, required } from 'react-admin';

const EventUnitFields = props => (
  <React.Fragment>
    <Grid item xs={12} sm={6} >
      <ReferenceInput source="eventId" reference="events" allowEmpty validate={required()} {...props} >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Grid>
    <Grid item xs={12} sm={6} >
      <ReferenceInput source="unitId" reference="units" allowEmpty validate={required()} {...props} >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Grid>
  </React.Fragment>
)

export default EventUnitFields
