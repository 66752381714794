import React from 'react';
import {BooleanField, Datagrid, Filter, List, NumberField, SelectField, TextField, TextInput} from 'react-admin';

const ProductFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const BillProductList = props => (
    <List filters={<ProductFilter/>} {...props} perPage={25} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name" style={{width: 300}}/>
            <TextField source="maskName"/>
            <NumberField source="price" locales="vi-VI" options={{style: 'currency', currency: 'VND'}}/>
            <SelectField source="subscriptionType" choices={[
                {
                    id: 'PER_YEAR',
                    name: 'Theo năm'
                }, {
                    id: 'PER_PERIOD',
                    name: 'Theo kỳ'
                }, {
                    id: 'PER_MONTH',
                    name: 'Theo tháng'
                }, {
                    id: 'PER_DAY',
                    name: 'Theo ngày'
                }]}/>
            <TextField source="priority"/>
            <TextField source="limitCreated"/>
            <SelectField source="printType" choices={[
                {
                    id: 'HDDT',
                    name: 'Hoá đơn điện tử'
                },
                {
                    id: 'BIEN_LAI',
                    name: 'Biên lai (C45BB)'
                },
                {
                    id: 'BIEN_NHAN',
                    name: 'Biên nhận'
                }]}/>
            <BooleanField source="flexible"/>
            <BooleanField source="enable"/>
        </Datagrid>
    </List>
);

export default BillProductList
