import React from 'react';
import compose from 'recompose/compose';

import {SaveButton, Toolbar, translate} from 'react-admin';
import CancelButton from './CancelButton'
import AuthorizeButton from './AuthorizeButton'
import Button from '@material-ui/core/Button';
import AuthorizeIcon from '@material-ui/icons/Done';

const CustomToolbar = ({
                           viewOnly,
                           simpleSaveRefresh,
                           noCancelButton,
                           basePath,
                           record,
                           resource,
                           undoable,
                           translate,
                           redirect,
                           permissions,
                           refresh,
                           cancelLabel,
                           ...props
                       }) => (
    <Toolbar record={record} {...props}>
        {!viewOnly && <SaveButton redirect={redirect || "list"}/>}
        {simpleSaveRefresh &&
        <SaveButton redirect={''}
                    label="Lưu và làm mới"
                    style={{marginLeft: '16px'}} variant="contained" color={'primary'} component="span">
        </SaveButton>}
        {
            !viewOnly && permissions && permissions.includes('CHECKER') &&
            <AuthorizeButton {...props} style={{marginLeft: '16px'}}/>
        }
        {refresh &&
        <Button icon={<AuthorizeIcon/>}
                style={{marginLeft: '16px'}} variant="contained" color={'primary'} component="span"
                onClick={props.onSubmitRefresh} {...props}>
            Lưu và làm mới
        </Button>
        }

        {!noCancelButton && <CancelButton label={cancelLabel ? cancelLabel : 'Huỷ bỏ'}/>}

        {/*
    {record && typeof record.id !== 'undefined' && (
      <DeleteButton
        basePath={basePath}
        record={record}
        resource={resource}
        undoable={undoable}
      />
    )} 
    */}

    </Toolbar>
);

export default compose(
    translate,
)(CustomToolbar);
