import React, {useEffect, useState} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {downloadFile, ReportForm, schoolYears, showWarningNotify} from "../../components";
import {DateInput, required, Title} from 'react-admin';
import env from "../../env/env";
import moment from "moment";
import cachedApis from "../../api/cachedApis";
import TreeCheckBoxUnitInput from "../../pages/StudentEnrollment/TreeCheckBoxUnitInput";


const BcNeNepTheoLop = (props) => {
    const [state, setState] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        unitIds: [],
        schoolYearId: schoolYears[0].id
    });
    const [loading, setLoading] = useState(false);
    const [units, setUnis] = useState([]);

    useEffect(() => {
        getCustomersWithUnits();
    }, []);

    function getCustomersWithUnits() {
        cachedApis.getUnits(true, state.schoolYearId).then(rs => {
            setUnis(rs);
        })
    }

    const validate = (values) => {
        const errors = {};
        setState(values);
        return errors;
    };
    const excelReport = async () => {
        if (!state.unitIds || state.unitIds.length === 0) {
            showWarningNotify("Vui lòng chọn lớp")
            return;
        }
        setLoading(true)
        try {
            await downloadFile(`${env.baseURL}/reports/bcNeNepTheoLop?fromDate=${moment(state.fromDate).format('YYYY-MM-DD')}&toDate=${moment(state.toDate).format('YYYY-MM-DD')}&unitIds=${state.unitIds}`, 'bc_ne_nep_theo_lop.xlsx')
        } catch (e) {

        }
        setLoading(false)
    };
    return (
        <Card>
            <Title title="resources.bcNeNepTheoLop.name"/>
            <CardContent>
                <ReportForm params={state}
                            validate={validate}
                            excelReport={excelReport}
                            isLoading={loading}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="fromDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.toDate" source="toDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{maxHeight: '450px', paddingRight: '16px'}}>
                            <label style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: '1rem'}}>
                                Chọn Lớp
                            </label>
                            <div style={{maxHeight: '400px', overflow: 'auto'}}>
                                <TreeCheckBoxUnitInput data={units}
                                                       expandTopLevel={true}
                                                       selected={state.tmpUnitIds}
                                                       onChange={(e) => {
                                                           console.log('e', e)
                                                           setState({...state, unitIds: e})
                                                       }}

                                />
                            </div>
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default BcNeNepTheoLop

