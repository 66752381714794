import AttendanceDetailsChangedHistoryIcon from '@material-ui/icons/ViewList';

import AttendanceDetailsChangedHistoryList from './AttendanceDetailsChangedHistoryList';
import AttendanceDetailsChangedHistoryEdit from './AttendanceDetailsChangedHistoryEdit';

export default {
  list: AttendanceDetailsChangedHistoryList,
  // edit: AttendanceDetailsChangedHistoryEdit,
  icon: AttendanceDetailsChangedHistoryIcon,
};
