import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid'
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    DateInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    TextInput
} from 'react-admin';

const KiotProductMenuFields = props => {
    const [kiotConfigId, setKiotConfigId] = useState(null)
    return (
        <React.Fragment>
            <Grid item xs={12} sm={6}>
                <DateInput source="sellDate" validate={required()} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput type="time" source="deliveryTime" {...props} validate={required()}
                           defaultValue={"11:00"}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <DateInput source="orderBeforeDate" validate={required()} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput type="time" source="orderBeforeTime" {...props} validate={required()}
                           defaultValue={"17:00"}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <DateInput source="cancelBeforeDate" validate={required()} {...props}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput type="time" source="cancelBeforeTime" {...props} validate={required()}
                           defaultValue={"08:00"}/>
            </Grid>

            <Grid item xs={12} sm={6}>
                <ReferenceInput source="kiotConfigId"
                                validate={required()}
                                {...props}
                                reference="kiotConfigs"
                                allowEmpty
                                sort={{field: 'name', order: 'ASC'}}
                                onChange={(e, v) => setKiotConfigId(v)}
                                perPage={999}>
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
            </Grid>

            <Grid item xs={12} sm={6}>
                <ReferenceInput source="primaryProductId"
                                validate={required()}
                                {...props}
                                reference="kiotProducts"
                                allowEmpty
                                sort={{field: 'name', order: 'ASC'}}
                                filter={{kiotConfigId: kiotConfigId}}>
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={6}>
                <ReferenceArrayInput source="productIds" reference="kiotProducts" {...props}
                                     filter={{kiotConfigId: kiotConfigId}}>
                    <AutocompleteArrayInput/>
                </ReferenceArrayInput>
            </Grid>


        </React.Fragment>
    )
}

export default KiotProductMenuFields
