import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {AutocompleteInput, DateInput, ReferenceInput, required, SelectInput, TextInput, Title} from 'react-admin';
import {downloadFile, ReportForm, schoolYears, showErrorNotify, TreeCheckboxInput} from '../../components';
import env from '../../env';

const imageTypes = [
    { id: '34', name: 'Hình thẻ 3x4' },
    { id: '44',   name: 'Hình thẻ 4x4' },
]

class ECardReport extends React.Component {
    state = {
        htmlReportUrl: '',
        unitIds: [],
        schoolYearId: schoolYears[0].id,
        effectiveDate: '',
        nienKhoa: schoolYears[0].name.substr(0, 4),
        templateId: '',
        donViQuanLy: '',
        signTitle: '',
        imageType: '',
        signName: ''
    };

    getReportUrl = () => {
        return `${env.baseURL}/reports/DSTheDienTu?` +
            `signName=` + encodeURIComponent(this.state.signName) +
            `&signTitle=` + encodeURIComponent(this.state.signTitle) +
            `&imageType=` + encodeURIComponent(this.state.imageType) +
            `&donViQuanLy=` + encodeURIComponent(this.state.donViQuanLy) +
            `&nienKhoa=` + encodeURIComponent(this.state.nienKhoa) +
            `&effectiveDate=` + encodeURIComponent(this.state.effectiveDate) +
            `&templateId=` + encodeURIComponent(this.state.templateId) +
            `&unitIds=` + this.state.unitIds;
    }

    zipReport = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp/phòng ban")
            return;
        }
        downloadFile(this.getReportUrl(), 'the_dien_tu.zip')
    };

    validate = (values) => {
        const errors = {};
        // Copy input values to params
        this.setState({
            type: values.type,
            nienKhoa: values.nienKhoa,
            effectiveDate: values.effectiveDate,
            donViQuanLy: values.donViQuanLy,
            signTitle: values.signTitle,
            signName: values.signName,
            imageType: values.imageType,
            unitIds: values.unitIds
        });
        return errors;
    };

    onTemplateChange(e, value) {
        this.setState({
            templateId: value,
        });
    }

    render() {
        return (
            <Card>
                <Title title="resources.DSTheDienTu.name"/>
                <CardContent>
                    <ReportForm params={this.state}
                                validate={this.validate}
                                zipReport={this.zipReport}
                    >
                        <Grid container spacing={8}>
                            <ReferenceInput source="templateId"
                                            reference="eCardTemplates"
                                            label="resources.eCardTemplates.name"
                                            perPage={10}
                                            validate={required()}
                                            onChange={(e, value) => this.onTemplateChange(e, value)}
                                            {...this.props} >
                                <AutocompleteInput source="name"/>
                            </ReferenceInput>
                            <Grid item xs={12} sm={6}>
                                <SelectInput source="imageType" choices={imageTypes} label="resources.DSTheDienTu.imageType"   validate={required()} {...this.props} />
                            </Grid>

                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.DSTheDienTu.effectiveDate" source="effectiveDate"
                                           validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput source="donViQuanLy" label={"resources.DSTheDienTu.donViQuanLy"} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <TextInput source="signTitle" label={"resources.DSTheDienTu.signTitle"}
                                           validate={required()}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextInput source="signName" label={"resources.DSTheDienTu.signName"}
                                           validate={required()}/>
                            </Grid>
                        </Grid>

                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <TextInput source="nienKhoa" label={"resources.DSTheDienTu.nienKhoa"}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label>Danh sách *</label>
                                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                                    <ReferenceInput source="unitIds"
                                                    reference="units"
                                                    perPage={999}
                                                    filter={{
                                                        clazzWithParents: true,
                                                        schoolYear: this.state.schoolYearId
                                                    }}
                                                    {...this.props} >
                                        <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                                    </ReferenceInput>
                                </div>
                            </Grid>
                        </Grid>
                    </ReportForm>
                </CardContent>
            </Card>
        )
    }
}

export default ECardReport;

