import MessageIcon from '@material-ui/icons/ViewList';

import VinaIDMessageList from './VinaIDMessageList';
import VinaIDMessageCreate from './VinaIDMessageCreate';
import VinaIDMessageEdit from './VinaIDMessageEdit';

export default {
  list: VinaIDMessageList,
  create: VinaIDMessageCreate,
  edit: VinaIDMessageEdit,
  icon: MessageIcon,
};
