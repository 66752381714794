import React, {useState} from 'react';
import compose from 'recompose/compose';
import {
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {Responsive, translate} from 'react-admin';
import InsertEmoticon from '@material-ui/icons/InsertEmoticon';
import SentimentDissatisfied from '@material-ui/icons/SentimentDissatisfied';
import CardIcon from './CardIcon';
import axiosInstance from "../../api/axios";

const style = theme => ({
  root: {
    flex: 1,
  },
  main: {
    flex: '1',
    marginRight: '1em',
    marginTop: 20,
  },
  card: {
    padding: '16px 0',
    overflow: 'inherit',
    textAlign: 'right',
  },
  iconSmall: {
    height: 50,
    width: 50
  },
  cardSmall: {
    padding: '8px 0',
    overflow: 'inherit',
    textAlign: 'right',
  }
});

const TopClazzesReview = ({ positive, translate, classes, onError }) => {
  const [clazzes, setClazzes] = useState([]);
  const [month, setMonth] = useState('');

  React.useEffect(() => {
    getTopDeligenceClazz();
  }, []);

  function getTopDeligenceClazz() {
    const url = `/api/dashboard/diligenceClasses?limit=5`;
    axiosInstance.get(url).then(rs => {
      if (positive) {
        setClazzes(rs.data.onTimeClazzes);
        if (rs.data.onTimeClazzes.length > 0) {
          setMonth(rs.data.onTimeClazzes[0].monthOfYear);
        }
      } else {
        setClazzes(rs.data.lateClazzes);
        if (rs.data.lateClazzes.length > 0) {
          setMonth(rs.data.lateClazzes[0].monthOfYear);
        }
      }
    }, err => {
      onError(err.message);
    });
  }

  return (
      <Responsive
          xsmall={
            <div className={classes.main}>
              <Card className={classes.cardSmall}>
                {positive ?
                    <CardIcon Icon={InsertEmoticon} bgColor="#33cc33" />
                    :
                    <CardIcon Icon={SentimentDissatisfied} bgColor="#ffcc00" />
                }
                {positive ?
                    <CardHeader title={translate('app.dashboard.onTimeClasses') + ' ' + month} />
                    :
                    <CardHeader title={translate('app.dashboard.lateClasses') + ' ' + month} />
                }
                <Divider />
                <List dense={true}>
                  {clazzes.map(record => (
                      <ListItem
                          key={record.clazzId}
                          button
                      >
                        <ListItemText
                            primary={record.clazzName + ' - ' + record.totalMembers}
                            secondary={'Gvcn: ' + record.homeroomTeacherName}
                        />
                        <ListItemSecondaryAction>
                          <span className={classes.cost}>{record.attendancePoint}</span>
                        </ListItemSecondaryAction>
                      </ListItem>
                  ))}
                </List>
              </Card>
            </div>
          }
          small={
            <div>small</div>
          }
          medium={
            <div className={classes.main}>
              {positive ?
                  <CardIcon Icon={InsertEmoticon} bgColor="#33cc33" />
                  :
                  <CardIcon Icon={SentimentDissatisfied} bgColor="#ffcc00" />
              }
              <Card className={classes.card}>
                {positive ?
                    <CardHeader title={translate('app.dashboard.onTimeClasses') + ' ' + month} />
                    :
                    <CardHeader title={translate('app.dashboard.lateClasses') + ' ' + month} />
                }
                <Divider />
                <List dense={true}>
                  {clazzes.map(record => (
                      <ListItem
                          key={record.clazzId}
                          button
                      >
                        <ListItemText
                            primary={record.clazzName + ' - ' + record.totalMembers}
                            secondary={'Gvcn: ' + record.homeroomTeacherName}
                        />
                        <ListItemSecondaryAction>
                          <span className={classes.cost}>{record.attendancePoint}</span>
                        </ListItemSecondaryAction>
                      </ListItem>
                  ))}
                </List>
              </Card>
            </div>
          }
      />
  )
};

const enhance = compose(
    withStyles(style),
    translate
);

export default enhance(TopClazzesReview);
