import React from 'react';
import { Create, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import ContributionBillFields from './ContributionBillFields'
const defaultValue = {
    paymentMethod: 'BANK_TRANSFER',
    contributionDate: new Date(),
    status: 'PAID'
}
export const ContributionBillCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="list" defaultValue={defaultValue}>
      <Grid container spacing={8}>
        <ContributionBillFields />
      </Grid>
    </SimpleForm>
  </Create>
);

export default ContributionBillCreate
