import DayOffIcon from '@material-ui/icons/ViewList';

import DayOffList from './DayOffList';
import DayOffCreate from './DayOffCreate';
import DayOffEdit from './DayOffEdit';

export default {
  list: DayOffList,
  create: DayOffCreate,
  edit: DayOffEdit,
  icon: DayOffIcon,
};
