import React, {Fragment} from 'react';
import {BooleanField, CardActions, Datagrid, EditButton, Filter, List, TextField, TextInput} from 'react-admin';
import {DateTimeField, schoolYears} from "../../components";
import DownloadFileButton from "../../components/DownloadFileButton";
import PrintFileButton from "../../components/PrintFileButton";
import ActiveDeactiveButton from "./ActiveDeactiveButton";
import {connect} from "react-redux";
import DeleteButton from "./DeleteButton";

const iframeStyle = {
    width: '1px', height: '1px',
    visibility: 'hidden',
}
const AdmissionFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);
const PostActions = () => (
    <CardActions>
        <iframe id="pdf-frame" style={iframeStyle}></iframe>
    </CardActions>
);
const PostBulkActionButtons = ({permissions, ...props}) => (
    <Fragment>
        <ActiveDeactiveButton permissions={permissions} {...props} />
        <DeleteButton  {...props} />
    </Fragment>
);
export const AdmissionList = ({permissions, ...props}) => (
    <List filters={<AdmissionFilter/>} {...props} exporter={false}
          bulkActionButtons={<PostBulkActionButtons permissions={permissions}/>} actions={<PostActions/>}
          filterDefaultValues={{schoolYearId: (schoolYears && schoolYears.length > 0) ? schoolYears[0].id : null}}>
        <Datagrid>
            <TextField source="stt"/>
            <TextField source="sbd"/>
            <TextField source="customerId"/>
            <TextField source="customerName"/>
            <DateTimeField source="dob" format="DD/MM/YYYY"/>
            <BooleanField source="active"/>
            <DateTimeField source="lastUpdatedOn" format="DD-MM-YYYY HH:mm" width={'7%'}/>
            <TextField source="lastUpdatedBy"/>
            <EditButton {...props} />
            <DownloadFileButton width={'20px'} extension={".pdf"}/>
            <PrintFileButton width={'20px'}/>
        </Datagrid>
    </List>
);

export default connect(null, null)(AdmissionList);
