import React from 'react';

import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {AutocompleteArrayInput, DateInput, ReferenceArrayInput, required, Title} from 'react-admin';
import {downloadFile, IFrameHtmlReport, ReportForm, schoolYears, showErrorNotify} from '../components';
import env from '../env';
import TreeCheckBoxUnitInput from "../pages/StudentEnrollment/TreeCheckBoxUnitInput";
import axiosInstance from "../api/axios";

class SchoolAttendanceReport extends React.Component {
    state = {
        openHtmlReport: false,
        htmlReportUrl: '',
        params: {
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
            unitIds: [],
            eventIds: []
        },
        units: [],
        events: [],
        tmpUnitIds: [],
        tmpEventIds: []
    };

    componentDidMount() {
        this.getUnits()
    }


    getUnits = () => {
        const url = `/api/units/search/filter?clazzWithParents=false&page=0&size=999&sort=id%2CASC&schoolYear=${schoolYears[0].id}`;
        return axiosInstance.get(url).then(rs => {
            this.setState({units: rs.data.content})
        }, err => {
            this.props.showNotification(err.message, 'warning');
        });
    }

    unitChanged = (values) => {
        this.setState(prevState => {
            return {params: {...prevState.params, unitIds: values}};
        });
    }

    onEventChanged(e, value) {
        this.setState({
            eventIds: value
        })
    }

    getReportUrl = (type) => {
        const token = localStorage.getItem('token');
        return `${env.baseURL}/reports/school_attendance` +
            `?type=` + type +
            `&pUnitIds=` + this.state.params.unitIds.join(',') +
            `&eventIds=` + this.state.params.eventIds.join(',') +
            `&pFromDate=` + this.state.params.fromDate +
            `&pToDate=` + this.state.params.toDate +
            `&token=` + token
    }

    getReportUrlToDownload = (type) => {
        return `${env.baseURL}/reports/school_attendance` +
            `?type=` + type +
            `&pUnitIds=` + this.state.params.unitIds.join(',') +
            `&eventIds=` + this.state.params.eventIds.join(',') +
            `&pFromDate=` + this.state.params.fromDate +
            `&pToDate=` + this.state.params.toDate;
    }

    htmlReport = () => {
        if (!this.validateData()) {
            return;
        }
        const htmlReportUrl = this.getReportUrl('HTML')
        this.setState({openHtmlReport: true, htmlReportUrl})
    }

    pdfReport = () => {
        if (!this.validateData()) {
            return;
        }
        const url = this.getReportUrlToDownload('PDF');
        downloadFile(url, 'bao_cao_diem_danh_truong.pdf')
    }

    excelReport = () => {
        if (!this.validateData()) {
            return;
        }
        downloadFile(this.getReportUrlToDownload('XLS'), 'bao_cao_diem_danh_truong.xlsx')
    }

    validateData = () => {
        if (!this.state.params.eventIds || this.state.params.eventIds.length == 0) {
            showErrorNotify("Vui lòng chọn sự kiện")
            return false;
        }
        if (!this.state.params.unitIds || this.state.params.unitIds.length == 0) {
            showErrorNotify("Vui lòng chọn lớp")
            return false;
        }
        return true;
    }

    validate = (values) => {
        const errors = {};

        if (values.fromDate && values.toDate && (values.fromDate > values.toDate)) {
            errors.toDate = 'To date must be greater than From date'
        }

        this.setState({params: values})

        return errors;
    }

    render() {
        return (
            <Card>
                <Title title="Báo cáo điểm danh trường"/>
                <CardContent>
                    <p>
                        * Báo cáo xuất dữ liệu dựa trên các sự kiện điểm danh được chọn trong ngày (VD: nếu chọn 2 sự
                        kiện thì tối đa là gấp đôi sĩ số)
                    </p>
                    <ReportForm params={this.state.params}
                                validate={this.validate}
                                htmlReport={this.htmlReport}
                                pdfReport={this.pdfReport}
                                excelReport={this.excelReport}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput source="fromDate" validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateInput source="toDate" validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ReferenceArrayInput source="eventIds"
                                                     reference="events"
                                                     label={'Sự kiện'}
                                                     validate={required()}
                                                     filter={{type: 'ATTENDANCE', enabled: true}}
                                                     perPage={999}
                                                     style={{
                                                         minWidth: 200,
                                                     }}
                                                     onChange={(e, value) => this.onEventChanged(e, value)}
                                                     sort={{field: 'name', order: 'ASC'}}>
                                    <AutocompleteArrayInput optionText="name"
                                                            onChange={(e) => this.props.onChange(e)}/>
                                </ReferenceArrayInput>
                            </Grid>
                            <Grid item xs={12} sm={6} style={{maxHeight: '300px', marginTop: 10}}>
                                <label>Đơn vị</label>
                                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                                    <TreeCheckBoxUnitInput data={this.state.units}
                                                           expandTopLevel={true}
                                                           selected={this.state.tmpUnitIds}
                                                           onChange={(e) => this.unitChanged(e)}/>
                                </div>
                            </Grid>
                        </Grid>
                    </ReportForm>

                    {
                        this.state.openHtmlReport &&
                        <IFrameHtmlReport src={this.state.htmlReportUrl}/>
                    }
                </CardContent>
            </Card>
        )
    }
}

export default SchoolAttendanceReport

