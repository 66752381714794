import React from 'react';
import {
    AutocompleteInput,
    Datagrid,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    TextField,
    TextInput
} from 'react-admin';
import {DateTimeField, schoolYears} from "../../components";

const SubjectFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
        <ReferenceInput source="unitId" reference="baseUnits" alwaysOn allowEmpty
                        {...props}
                        filter={{schoolYearId: schoolYears[0].id, clazzOnly: true}}
                        sort={{field: 'name', order: 'ASC'}}
                        perPage={999}
        >
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
    </Filter>
);

export const DebtReminderBatchList = props => (
    <List filters={<SubjectFilter/>} {...props} bulkActionButtons={false} exporter={false}>
        <Datagrid>
            <DateTimeField source="reminderMonth" format="YYYY-MM"/>
            <ReferenceField source="unitId" reference="baseUnits" allowEmpty>
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="reminderTime"/>
            <DateTimeField source="sentDate" format="DD-MM-YYYY HH:mm"/>
            <TextField source="lastUpdatedBy"/>
        </Datagrid>
    </List>
);

export default DebtReminderBatchList
