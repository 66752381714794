import TuyenSinhInfoIcon from '@material-ui/icons/ViewList';

import AdmissionChangeLogList from './AdmissionChangeLogList';
import AdmissionChangeLogShow from './AdmissionChangeLogShow';

export default {
  list: AdmissionChangeLogList,
  show: AdmissionChangeLogShow,
  icon: TuyenSinhInfoIcon,
};
