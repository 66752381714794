import React, {useEffect, useState} from 'react';
import {Button, fetchEnd, fetchStart, refreshView} from 'react-admin';
import env from "../env";
import PrintIcon from '@material-ui/icons/Print';
import {printContent, showErrorNotify,} from '../components'
import {connect} from 'react-redux';
import {compose} from 'redux';
import {change, submit} from "redux-form";
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {};

const PrintBienNhanButton = (props) => {
    const {record, basePath} = props
    const [showBtn, setShowBtn] = useState(false)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (record) {
            setShowBtn(record.haveBienNhan)
        }
    }, [record])

    const handleClick = () => {
        if (record) {
            setLoading(true)
            printContent(`${env.baseURL}/api` + basePath + `/print-bienNhan?paymentId=` + record.id)
                .then(() => {
                    props.refreshView();
                    setLoading(false)
                })
                .catch(() => {
                    props.refreshView();
                    setLoading(false)
                })
        } else {
            showErrorNotify("Vui lòng chọn biên nhận")
        }
    };

    return (
        showBtn &&
        <Button disabled={loading} onClick={handleClick} label="ra.action.printBienNhan" style={{fontSize: '0.7rem'}}>
            <PrintIcon/>
        </Button>
    );
}


const mapStateToProps = state => ({});

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    submit,
    refreshView
};
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(
    PrintBienNhanButton
);
