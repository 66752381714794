import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Button, EditButton, refreshView} from 'react-admin';
import {compose} from 'redux';
import axiosInstance from "../../api/axios";
import {showErrorNotify, showSuccessNotify} from "../../components";
import Swal from "sweetalert2";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    dialogPaper: {
        width: '75%',
        maxWidth: '76%',
        minHeight: '90vh',
        maxHeight: '90vh',
    },
    suggestDialog: {
        zIndex: '1 !important',
    },
    pContent: {
        minHeight: 21
    },
    input: {
        padding: 4
    }
});

const dobFormat = 'DD/MM/YYYY'
const CardApplicationActions = (props) => {
    const {classes} = props;
    const [id, setId] = useState(null);
    const [showBtn, setShowBtn] = useState(false);
    const [showBtnSendReview, setShowBtnSendReview] = useState(false);
    const [showBtnDelete, setShowBtnDelete] = useState(false);

    useEffect(() => {
        const {status, id, existCustomerId} = props.record
        setId(id)
        setShowBtn(status === 'B_TEMP_READY' || status === 'A_NOT_READY' || status === 'B_READY' || status === 'L_REVIEW_REJECTED');
        setShowBtnSendReview(status === 'B_TEMP_READY' || status === 'B_READY' || status === 'L_REVIEW_REJECTED');
        setShowBtnDelete(status === 'B_TEMP_READY' || status === 'A_NOT_READY' || status === 'B_READY' || status === 'L_REVIEW_REJECTED');
    }, [props.record])

    function handleClick(e) {
        e.stopPropagation();
        if (id) {
            const url = `/api/cardApplication/sendReview`;
            axiosInstance.post(url, [id]).then(rs => {
                if (rs.data.success) {
                    showSuccessNotify("Gửi duyệt dữ liệu thành công");
                    setShowBtn(false)
                    props.refreshView()
                }
            }, err => {
                showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
            });
        }
    }

    function handleDelete(e) {
        e.stopPropagation();
        if (id) {
            Swal.fire({
                title: "Bạn chắc chắn muốn xoá thông tin này?",
                text: "Sau khi xoá, không thể khôi phục.",
                type: 'warning',
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                focusConfirm: false,
            })
                .then((result) => {
                    if (result.value) {
                        const url = `/api/cardApplication?id=${id}`;
                        axiosInstance.delete(url).then(rs => {
                            if (rs.data.success) {
                                showSuccessNotify("Xoá thành công");
                                setShowBtnDelete(false)
                                props.refreshView()
                            }
                        }, err => {
                            showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
                        });
                    }
                });
        }
    }

    return (
        <div>
            {showBtn &&
            <EditButton label={""} variant="outlined" {...props} />
            }
            {showBtnSendReview &&
            <Button style={{marginLeft: '5px'}} variant="outlined" label="Gửi duyệt" onClick={handleClick}/>
            }
            {showBtnDelete &&
            <Button style={{marginLeft: '5px', color: 'red'}} variant="outlined" label="Xoá" onClick={handleDelete}/>
            }
        </div>
    )
}
const mapStateToProps = state => ({});
const mapDispatchToProps = {
    refreshView
};
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps),)(CardApplicationActions);
