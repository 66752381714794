import React, {Component} from 'react';
import {Button} from 'react-admin';
import env from "../env";
import PrintIcon from '@material-ui/icons/Print';
import {downloadFile} from "./FileDownload";

class PrintAllQrInfoLinkButton extends Component {
    state = {
        type: ''
    };
    constructor(props) {
        super(props);
        this.state.type = props.type
    }

    handleClick = () => {
        downloadFile(`${env.baseURL}/reports/getAllQrLinks?type=${this.state.type}`, 'ma_qr.xlsx')
    };

    render() {
        return (
            <Button onClick={this.handleClick} label="ra.action.printAllQr">
                <PrintIcon/>
            </Button>
        );
    }
}

export default PrintAllQrInfoLinkButton;
