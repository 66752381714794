import axiosInstance from "./axios";

const cachedApis = {

}
cachedApis.geSmsCustomerUnits = () => {
  let url = `/api/smsMessages/smsCustomers`;

  const cachedData = JSON.parse(localStorage.getItem(url));
  let now = new Date().getTime();
  if (cachedData && now - cachedData.requestDate < 24 * 60 * 60 * 1000) {// only cache data in 1 day
    return Promise.resolve(cachedData.content);
  } else {
    return axiosInstance.get(url).then(rs => {
      rs.data.requestDate = new Date().getTime();
      localStorage.setItem(url, JSON.stringify(rs.data));
      return Promise.resolve(rs.data.content);
    });
  }
}
cachedApis.getCustomerUnits = (studentOnly, schoolYearId) => {
  let url = `/api/customersByUnits?studentOnly=${studentOnly}`;
  if (schoolYearId) {
    url += `&schoolYearId=${schoolYearId}`;
  }

  const cachedData = JSON.parse(localStorage.getItem(url));
  let now = new Date().getTime();
  if (cachedData && now - cachedData.requestDate < 24*60*60*1000) {// only cache data in 1 day
    return Promise.resolve(cachedData.content);
  } else {
    return axiosInstance.get(url).then(rs => {
      rs.data.requestDate = new Date().getTime();
      localStorage.setItem(url, JSON.stringify(rs.data));
      return Promise.resolve(rs.data.content);
    });
  }
}

cachedApis.getEmployeeUnits = (type) => {
  let url = `/api/customersByUnitsV2?type=${type}`;

  const cachedData = JSON.parse(localStorage.getItem(url));
  let now = new Date().getTime();
  if (cachedData && now - cachedData.requestDate < 24*60*60*1000) {// only cache data in 1 day
    return Promise.resolve(cachedData.content);
  } else {
    return axiosInstance.get(url).then(rs => {
      rs.data.requestDate = new Date().getTime();
      localStorage.setItem(url, JSON.stringify(rs.data));
      return Promise.resolve(rs.data.content);
    });
  }
}
cachedApis.getProducts = () => {
  let url = `/api/products/search/filter?size=999&page=0&&sort=id%2CASC`;
  const cachedData = JSON.parse(localStorage.getItem(url));
  let now = new Date().getTime();
  if (cachedData && now - cachedData.requestDate < 24*60*60*1000) {// only cache data in 1 day
    return Promise.resolve(cachedData.content);
  } else {
    return axiosInstance.get(url).then(rs => {
      rs.data.requestDate = new Date().getTime();
      localStorage.setItem(url, JSON.stringify(rs.data));
      return Promise.resolve(rs.data.content);
    });
  }
}

cachedApis.getUnits = (clazzWithParents, schoolYearId) => {
  const url = `/api/units/search/filter?clazzWithParents=${clazzWithParents}&page=0&size=999&sort=id%2CASC&schoolYear=${schoolYearId}`;
  const cachedData = JSON.parse(localStorage.getItem(url));
  let now = new Date().getTime();
  if (cachedData && now - cachedData.requestDate < 24*60*60*1000) {// only cache data in 1 day
    return Promise.resolve(cachedData.content);
  } else {
    return axiosInstance.get(url).then(rs => {
      rs.data.requestDate = new Date().getTime();
      localStorage.setItem(url, JSON.stringify(rs.data));
      return Promise.resolve(rs.data.content);
    });
  }
}

/**
 * CustomerUnits tree must be updated when enrollments changed
 */
cachedApis.removeCachedCustomerUnits = () => {
  let url = `/api/customersByUnits`;
  for (let i in localStorage) {
    if (i.match(url)) {
      localStorage.removeItem(i);
    }
  }
}

export default cachedApis;
