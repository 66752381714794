import React, {useEffect, useState} from "react";
import axiosInstance from "../../api/axios";
import {showErrorNotify, showSuccessNotify} from "../../components";
import {connect} from 'react-redux';
import {Button, fetchEnd, fetchStart, refreshView} from 'react-admin';
import {compose} from 'redux';
import Swal from "sweetalert2";
import {change, submit} from "redux-form";

const AutoConsolidateBtn = () => {
    const [autoConsolidate, setAutoConsolidate] = useState(false);
    const [showBtn, setShowBtn] = useState(false);

    useEffect(() => {
        checkAutoOn()
    }, [])

    const checkAutoOn = () => {
        const url = `/api/bankTransferConsolidates/checkAutoConsolidate`;
        axiosInstance.get(url).then((res) => {
            if (res?.data) {
                const response = res.data
                setShowBtn(response.display)
                setAutoConsolidate(response.autoConsolidate)
            }

        })
    }
    const handleClick = (e) => {
        e.stopPropagation();
        Swal.fire({
            title: autoConsolidate ? "Bạn chắc chắn muỗn tắt tự động xác nhận?" : "Bạn chắc chắn muỗn bật tự động xác nhận?",
            text: autoConsolidate ? "Sau khi tắt, các giao dịch chờ đối soát sẽ cần xác nhận thủ công." : "Sau khi bật, các giao dịch chờ đối soát sẽ được xác nhận tự động nếu thông tin hợp lệ sau 11h15 hàng ngày.",
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
            focusConfirm: false,
        })
            .then((result) => {
                if (result.value) {
                    const url = `/api/bankTransferConsolidates/changeAutoConsolidate?status=${!autoConsolidate}`;
                    axiosInstance.post(url)
                        .then((res) => {
                            if (res.data && res.data.success) {
                                setAutoConsolidate(!autoConsolidate)
                                showSuccessNotify(autoConsolidate ? "Tắt tự động xác nhận thành công" : "Bật tự động xác nhận thành công.");
                            }
                        }).catch(err => {
                        showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
                    })
                }
            })
    };

    return (
        <>
            {showBtn &&
            <Button style={{width: 150, color: autoConsolidate ? "red" : "blue"}}
                    onClick={handleClick} label={autoConsolidate ? 'Tắt tự động xác nhận' : 'Bật tự động xác nhận'}>
            </Button>
            }
        </>
    )
}


const mapStateToProps = state => ({});

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    submit,
    refreshView
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    AutoConsolidateBtn
);
