import React from 'react';
import {ReferenceInput, SelectInput, TextInput, required} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import UserChannelRoles from "./UserChannelRoles";

const UserChannelFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="channelName" disabled={true} validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput source="displayName" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <SelectInput source="channelRole" choices={UserChannelRoles} {...props} />
        </Grid>
    </React.Fragment>
)

export default UserChannelFields
