import React from 'react';
import { Create, SimpleForm } from 'react-admin';

import { CustomToolbar, Grid } from '../../components'
import SchoolYearFields from './SchoolYearFields'

export const SchoolYearCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="list" >
      <Grid container spacing={8}>
        <SchoolYearFields />
      </Grid>
    </SimpleForm>
  </Create>
);

export default SchoolYearCreate
