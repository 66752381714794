import React, {useEffect, useState} from 'react';
import {
    CardActions,
    CreateButton,
    Datagrid,
    Filter,
    List,
    ReferenceField,
    TextField,
    TextInput,
    translate
} from 'react-admin';
import {
    BillAuthStatusField,
    DateTimeField,
    DownloadFileContentButton,
    EditButtonWithAuthStatus
} from "../../components";
import DownloadTemplateFileButtonV2 from "../../components/DownloadTemplateFileButtonV2";
import {CalendarTypeField} from "../../components/CalendarType";
import DeleteRecordButton from "../../components/DeleteRecordButton";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    CircularProgress
} from '@material-ui/core'
import PreviewIcon from '@material-ui/icons/RemoveRedEye'
import axiosInstance from "../../api/axios";
import env from "../../env";
import _ from 'lodash'

const DAY_OF_WEEK = {
    MONDAY: 'T2',
    TUESDAY: 'T3',
    WEDNESDAY: 'T4',
    THURSDAY: 'T5',
    FRIDAY: 'T6',
    SATURDAY: 'T7',
    SUNDAY: 'CN'
}

const IntroductionFileFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

const PostActions = ({
                         bulkActions,
                         basePath,
                         displayedFilters,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {/* Add your custom actions */}
        <DownloadTemplateFileButtonV2 basePath="/files/templates/mau_tkb.xlsx"
                                      btnLabel="resources.calendarBatches.mauTkb"/>
        <DownloadTemplateFileButtonV2 basePath="/files/templates/mau_lich_thi.xlsx"
                                      btnLabel="resources.calendarBatches.mauLichThi"/>
        <CreateButton basePath={basePath}/>
    </CardActions>
);

const ImageCalendar = ({images = []}) => {
    return <div style={{display: 'flex', flexDirection: 'column'}}>
        {images.map((url, index) => <img style={{width: '100%'}} key={index} src={`${env.baseURL}${url}`}/>)}
    </div>
}

const ExamCalendar = ({displayTimes: headers = [], items = []}) => {

    const calendarByDate = _.groupBy(items, 'displayDate')

    return <Table padding='none'>
        <TableHead>
            <TableRow>
                <TableCell width={100} align={'center'} style={{border: `1px solid`, textAlign: 'center'}}>
                  Thời gian
                </TableCell>
                {headers.map(header => <TableCell style={{border: `1px solid`, textAlign: 'center'}} key={header}>
                    {header}
                </TableCell>)}
            </TableRow>
        </TableHead>
        <TableBody>
            {Object.keys(calendarByDate).map(date => (
                <TableRow key={date}>
                    <TableCell component="th" scope="row" style={{border: `1px solid`, textAlign: 'center'}}>
                        {date}
                    </TableCell>
                    {headers.map(header => (
                            <TableCell key={header} style={{border: `1px solid`, textAlign: 'center'}}>
                                <span style={{color: 'red'}}>
                                    {calendarByDate[date].find(item => item.displayTime === header)?.description}
                                </span>
                            </TableCell>
                        ))}
                </TableRow>
            ))}
        </TableBody>
    </Table>
}

const ScheduleCalendar = ({headers: dayOfWeek = [], titles: headers = [], items = []}) => {
    const calendarByDate = _.groupBy(items, 'displayDate')

    return <div style={{border: '1px solid'}}>
        <div style={{display: 'flex', flexDirection: 'row', backgroundColor: '#d3d3d3', fontWeight: 'bold'}}>
            <div style={{width: 100, textAlign: 'center', padding: 8}}>Thứ</div>
            <div style={{width: 100, textAlign: 'center',borderLeft: '1px solid', padding: 8}}>Tiết</div>
            {headers.map(header => <div key={header} style={{flex: 1, borderLeft: '1px solid', textAlign: 'center', padding: 8}}>
                {header}
            </div>)}

        </div>

        <div>
            {dayOfWeek.map(day => <div key={day} style={{display: "flex", flexDirection: 'row',}}>
                <div style={{width: 100,
                    borderTop: '1px solid',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent:'center',
                    padding: 8
                }}>
                    {DAY_OF_WEEK[day]}
                </div>
                <div style={{flex: 1, borderLeft: '1px solid'}}>
                    {calendarByDate[day].map(item => <div key={item.displayTime}
                                                          style={{
                                                              display: 'flex',
                                                              flexDirection: 'row',
                                                              borderTop: '1px solid'
                                                          }}>
                        <div style={{textAlign: 'center', width: 100, borderLeft: 0, padding: 8}}>
                            {item.displayTime}
                        </div>
                        {headers.map(header => <div key={header}
                                                    style={{
                                                        flex: 1,
                                                        textAlign: 'center',
                                                        borderLeft: '1px solid',
                                                        padding: 8,
                                                    }}>
                            <span
                                style={{color: 'red'}}>{item.items.find(({title}) => title === header)?.description || '-'}
                            </span>
                        </div>)}
                    </div>)}
                </div>

            </div>)}
        </div>
    </div>
}

const PreviewCalendar = translate(({translate, record}) => {

    const [open, setOpen] = useState(false)
    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (open) {
            setLoading(true)
            axiosInstance
                .get(`/api/calendarBatches/${record.id}/preview`)
                .then(rs => setData(rs.data))
                .finally(() =>setLoading(false))
        }
    }, [open])

    return <div>
        <Button color='primary' onClick={() => setOpen(true)}>
            <div style={{display: "flex", alignItems: 'center', gap: 4}}>
                <PreviewIcon/>
                <span>{translate('resources.calendarBatches.fields.previewCalendar')}</span>
            </div>
        </Button>

        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            fullWidth
            maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title">
                {record.name}
            </DialogTitle>
            {loading && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <CircularProgress/>
            </div>}
            <DialogContent>
                {data?.useImage && <ImageCalendar images={data.fileUris}/>}
                {!data?.useImage && data?.type === 'EXAM' && <ExamCalendar {...data}/>}
                {!data?.useImage && data?.type === 'SCHEDULE' && <ScheduleCalendar {...data}/>}
            </DialogContent>
        </Dialog>
    </div>
})
export const CalendarBatchList = props => (
    <List filters={<IntroductionFileFilter/>} {...props} exporter={false} actions={<PostActions/>}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="name"/>
            <CalendarTypeField source="type" style={{width: '5% !important'}}/>
            <ReferenceField source="schoolYearId" reference="schoolYears" allowEmpty>
                <TextField source="name"/>
            </ReferenceField>
            <DateTimeField source="startDate" format="DD-MM-YYYY" style={{width: '20% !important'}}/>
            <DateTimeField source="endDate" format="DD-MM-YYYY" style={{width: '20% !important'}}/>
            <BillAuthStatusField source="authStatus"/>
            <PreviewCalendar/>
            <DownloadFileContentButton/>
            <EditButtonWithAuthStatus/>
            <DeleteRecordButton/>
        </Datagrid>
    </List>
);

export default CalendarBatchList
