import React from 'react';
import {ReportForm, IFrameHtmlReport, downloadFile} from "../../components";
import {Grid, Card, CardContent} from '@material-ui/core';
import {ReferenceInput, required, AutocompleteInput, Title} from 'react-admin';
import env from "../../env";

class AbsentReport extends React.Component {
    state = {
        openHtmlReport: false,
        htmlReportUrl: '',
        params: {}
    };

    constructor(props) {
        super(props);
        this.getReportUrl = this.getReportUrl.bind(this);
        this.validate = this.validate.bind(this);
    }

    htmlReport = () => {
        const htmlReportUrl = this.getReportUrl('HTML')

        this.setState({openHtmlReport: true, htmlReportUrl})
    };

    pdfReport = () => {
        downloadFile(this.getReportUrlToDownload('PDF'), 'bao_cao_diem_danh_vang.pdf')
    };

    excelReport = () => {
        downloadFile(this.getReportUrlToDownload('XLS'), 'bao_cao_diem_danh_vang.xlsx')
    };

    getReportUrl = (type) => {
        const token = localStorage.getItem('token');
        return `${env.baseURL}/reports/absent_report?type=${type}&pEventId=${this.state.params.eventId}&token=${token}`
    }

    getReportUrlToDownload = (type) => {
        return `${env.baseURL}/reports/absent_report?type=${type}&pEventId=${this.state.params.eventId}`
    }

    validate = (values) => {
        const errors = {};

        this.setState({params: values})

        return errors;
    };

    render() {
        return (
            <Card>
                <Title title="Báo cáo điểm danh vắng" />
                <CardContent>
                    <ReportForm params={this.state.params}
                                validate={this.validate}
                                htmlReport={this.htmlReport}
                                pdfReport={this.pdfReport}
                                excelReport={this.excelReport}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <ReferenceInput source="eventId" reference="events" validate={required()} perPage={50}
                                                filter={{filterPath: 'filterSimple'}}>
                                    <AutocompleteInput source="name"/>
                                </ReferenceInput>
                            </Grid>
                        </Grid>
                    </ReportForm>
                    {/* The iframe to show HTML report */}
                    {
                        this.state.openHtmlReport &&
                        <IFrameHtmlReport src={this.state.htmlReportUrl} />
                    }
                </CardContent>
            </Card>
        )
    }
}

export default AbsentReport;
