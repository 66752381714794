import EventBatchIcon from '@material-ui/icons/ViewList';

import EventBatchList from './EventBatchList';
import EventBatchCreate from './EventBatchCreate';
import EventBatchEdit from './EventlBatchEdit';

export default {
  list: EventBatchList,
  create: EventBatchCreate,
  edit: EventBatchEdit,
  icon: EventBatchIcon,
};
