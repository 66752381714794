import EventLocationIcon from '@material-ui/icons/ViewList';

import EventLocationList from './EventLocationList';
import EventLocationCreate from './EventLocationCreate';
import EventLocationEdit from './EventLocationEdit';

export default {
  list: EventLocationList,
  create: EventLocationCreate,
  edit: EventLocationEdit,
  icon: EventLocationIcon,
};
