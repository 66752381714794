import React from 'react';
import {connect} from 'react-redux';
import {Button, refreshView} from 'react-admin';
import axiosInstance from "../../api/axios";
import {showErrorNotify, showSuccessNotify} from "../../components";
import Swal from "sweetalert2";

const DeleteButton = ({...props}) => {
    const lock = () => {
        Swal.fire({
            title: 'Xác nhận',
            text: `Bạn muốn xoá đăng ký tuyển sinh này? Sau khi xoá sẽ không thể khôi phục.`,
            type: "warning",
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
            focusConfirm: false,
        }).then(async (result) => {
            if (result.value) {
                doAction()
            }
        })
    }
    const doAction = () => {
        axiosInstance.put(`/api${props.basePath}/remove`, props.selectedIds).then(() => {
            showSuccessNotify("Xoá thành công.");
            setTimeout(() => {
                window.location.reload()
            }, 1500)
        }).catch(() => {
            showErrorNotify("Có lỗi, vui lòng thử lại hoặc liên hệ quản trị viên.");
        })
    }

    return (
            <Button style={{marginLeft: '10px', color: 'red'}} variant="outlined" label="Xoá" onClick={lock}/>
    );
}

export default connect(null, {refreshView})(DeleteButton);

