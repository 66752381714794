import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const categories = [
    {id: 'APP_REGISTER', name: 'smsCategory.APP_REGISTER'},
    {id: 'KQHT', name: 'smsCategory.KQHT'},
    {id: 'FREE_TEXT', name: 'smsCategory.FREE_TEXT'},
    {id: 'TUYEN_SINH', name: 'smsCategory.TUYEN_SINH'},
]

export const SmsMessageCategoryField = (props) => (
    <SelectField source={props.source} choices={categories} {...props} />
)

export const SmsMessageCategoryInput = (props) => (
    <SelectInput source={props.source} choices={categories} {...props} />
)

const channels = [
    {id: 'SMS_BRANDNAME', name: 'smsChannel.SMS_BRANDNAME'},
    {id: 'APP', name: 'smsChannel.APP'},
]

export const SmsChannelField = (props) => (
    <SelectField source={props.source} choices={channels} {...props} />
)
const statuses = [
    {id: 'PROCESSING', name: 'smsStatus.PROCESSING'},
    {id: 'SUCCESS', name: 'smsStatus.SUCCESS'},
    {id: 'FAIL', name: 'smsStatus.FAIL'},
]

export const SmsStatusField = (props) => (
    <SelectField source={props.source} choices={statuses} {...props} />
)
