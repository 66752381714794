import React from 'react';
import {Datagrid, Filter, FunctionField, List, TextField, TextInput, translate} from "react-admin";
import {DateTimeField} from "../../components";


const HistoryFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

const BillItemHistoryList = (props) => {
    return <List filters={<HistoryFilter/>}
                 bulkActions={false}
                 exporter={false}
                 {...props}>
        <Datagrid>
            <TextField source='billNo' />
            <TextField source='customerId' />
            <TextField source='customerName' />
            <TextField source='unitName' />
            <DateTimeField source='billMonth' format='MM/YYYY'/>

            <FunctionField label={'resources.bill-item-histories.fields.oldValue'}
                           render={({oldValue}) => <div>{JSON.parse(oldValue)?.subtotal || 0}</div>}/>
            <FunctionField label={'resources.bill-item-histories.fields.newValue'}
                           render={({newValue}) => <div>{JSON.parse(newValue)?.subtotal || 0}</div>}/>
            <DateTimeField source={'lastUpdatedOn'} format="DD/MM/YYYY HH:mm"/>
            <TextField source={'lastUpdatedBy'} style={{width: 150}}/>
        </Datagrid>
    </List>
};

export default translate(BillItemHistoryList);
