import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {Card, CardContent, Grid, TextField} from '@material-ui/core'
import {BooleanInput, showNotification, SimpleForm, TextInput} from 'react-admin';

import RoleCustomToolbar from "../Role/RoleCustomToolbar";
import axiosInstance from "../../api/axios";

export class IntroductionFileCreate extends React.Component {

    state = {
        description: '',
        priority: 10,
        enable: true,
        isRedirect: false,
        file: null
    }

    changeDescription = (val) => {
        this.setState({description: val});
    }
    changePriority = (val) => {
        try {
            if (val) {
                this.setState({priority: parseInt(val)});
            } else {
                this.setState({priority: ''});
            }
        } catch (e) {
            this.setState({priority: ''});
        }
    }
    changeFile = (e) => {
        this.setState({
            file: e.target.files[0]
        })
    }

    save = () => {
        const {enable, description, file, priority} = this.state;
        if (!priority) {
            this.props.showNotification('Vui lòng nhâp độ ưu tiên', 'warning');
            return;
        }
        if (!description) {
            this.props.showNotification('Vui lòng nhâp mô tả', 'warning');
            return;
        }
        const formData = new FormData();
        formData.append('file', file)
        formData.append('enable', enable)
        formData.append('priority', priority)
        formData.append('description', description)

        const url = `/api/introductionFiles`;
        return axiosInstance.post(url, formData).then(rs => {
            this.cancel();
        }, err => {
            this.props.showNotification(err.message, 'warning');
        });
    };

    cancel = (e) => {
        this.setState({
            isRedirect: true
        })
    };

    render() {
        if (this.state.isRedirect) {
            return <Redirect to='/introductionFiles'/>;
        }
        const {description, priority} = this.state;
        return (
            <React.Fragment>
                <Card>
                    <CardContent>
                        <SimpleForm toolbar={<RoleCustomToolbar save={this.save} cancel={this.cancel}/>}
                                    redirect="list">
                            <Grid container spacing={8}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="standard-required"
                                        label="Mô tả"
                                        margin="normal"
                                        required={true}
                                        value={description}
                                        onChange={(e) => this.changeDescription(e.target.value)}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <BooleanInput onChange={(e) => this.setState({enable: e.target.value})}
                                                  label="Kích hoạt" defaultValue={this.state.enable}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="standard-required"
                                        label="Độ ưu tiên xuất hiện"
                                        margin="normal"
                                        type={'number'}
                                        required={true}
                                        value={priority}
                                        onChange={(e) => this.changePriority(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <form style={{width: '100%'}}>
                                <div style={{flexDirection: 'row'}}>
                                    <h4>Chọn tệp</h4>
                                    <input id="contained-button-file" type="file" accept="video/*,image/*"
                                           onChange={(e) => this.changeFile(e)}/>
                                </div>
                            </form>
                        </SimpleForm>
                    </CardContent>
                </Card>
            </React.Fragment>
        )
    }
}

export default compose(
    connect(undefined, {showNotification}),
)(IntroductionFileCreate);
