import PaymentGatewayFeesIcon from '@material-ui/icons/ViewList';

import PaymentGatewayFeesList from './PaymentGatewayFeesList';
import PaymentGatewayFeesCreate from './PaymentGatewayFeesCreate';
import PaymentGatewayFeesEdit from './PaymentGatewayFeesEdit';

export default {
  list: PaymentGatewayFeesList,
  create: PaymentGatewayFeesCreate,
  edit: PaymentGatewayFeesEdit,
  icon: PaymentGatewayFeesIcon,
};
