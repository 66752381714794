import React, {useState} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {DateInput, required, TextInput, Title} from 'react-admin';
import {downloadFile, ReportForm} from '../../components';
import env from '../../env';
import moment from "moment";

const ChamTheKhongHopLe = (props) => {
    const [state, setState] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        fromTime: '11:00',
        toTime: '11:30',
    });

    const getReportUrl = () => {
        return `${env.baseURL}/reports/kiot/chamTheKhongHopLe?fromTime=${moment(state.fromTime).format('HH:mm')}&toTime=${moment(state.toTime).format('HH:mm')}&fromDate=${moment(state.fromDate).format('YYYY-MM-DD')}&toDate=${moment(state.toDate).format('YYYY-MM-DD')}`;
    };

    const excelReport = () => {
        downloadFile(getReportUrl(), 'cham_the_khong_hop_le.xlsx')
    };

    const validate = (values) => {
        const errors = {};
        setState(values);
        return errors;
    };

    return (
        <Card>
            <Title title="resources.chamTheKhongHopLe.name"/>
            <CardContent>
                <ReportForm params={state}
                            validate={validate}
                            excelReport={excelReport}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="fromDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.toDate" source="toDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextInput type="time" label="resources.chamTheKhongHopLe.fromTime"
                                       source="fromTime" {...props} validate={required()}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextInput type="time" label="resources.chamTheKhongHopLe.toTime" source="toTime" {...props}
                                       validate={required()}/>
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default ChamTheKhongHopLe

