import MonTuChonIcon from '@material-ui/icons/ViewList';

import AdmissionSubjectList from './AdmissionSubjectList';
import AdmissionSubjectCreate from './AdmissionSubjectCreate';
import AdmissionSubjectEdit from './AdmissionSubjectEdit';

export default {
  list: AdmissionSubjectList,
  create: AdmissionSubjectCreate,
  edit: AdmissionSubjectEdit,
  icon: MonTuChonIcon,
};
