import React from 'react';
import Grid from '@material-ui/core/Grid'
import {BooleanInput, NumberInput, required, TextInput} from 'react-admin';
import {DisciplineTypeInput} from "./DisciplineType";
import {AttendanceStatusInput} from "./AttendanceStatus";

const DisciplineCategoryFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <DisciplineTypeInput source="type" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <NumberInput source="minusCompetePoint" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <NumberInput source="plusCompetePoint" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <NumberInput source="minusConductPoint" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <NumberInput source="plusConductPoint" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <AttendanceStatusInput source="attendanceStatus" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="active"  {...props} />
        </Grid>
    </React.Fragment>
)

export default DisciplineCategoryFields
