import React from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import withStyles from '@material-ui/core/styles/withStyles';
import {CircularProgress} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {showNotification, SimpleForm, Toolbar, translate} from 'react-admin';

const buttonStyle = {
    margin: '0 8px',
}

const styles = {
    form: {
        // "& > div > div > div ": {
        //   width: '100%',
        // }
    },
};

const noop = () => {
}

class NotifyButton extends React.Component {
    handleClick = e => {
        const {
            handleSubmitWithRedirect,
            invalid,
            redirect,
            showNotification,
            onClick,

        } = this.props;
        if (invalid) {
            showNotification('ra.message.invalid_form', 'warning');
        }

        if (e) {
            e.preventDefault();
        }
        const errors = handleSubmitWithRedirect(redirect)();
        if (!errors) {
            if (typeof onClick === 'function') {
                onClick();
            }
        }
    }

    render() {
        const {label, translate, disabled} = this.props
        return (
            <Button disabled={disabled} color="secondary" variant="raised" style={buttonStyle}
                    onClick={this.handleClick}>
                {label && translate(label, {_: label})}
            </Button>
        )
    }
}

const NotifyToolbar = ({allClazzes, fullDayClazzes, saveBtn, notFullDayClazzes, isLoading, ...props}) => (
    <Toolbar {...props} >
        {allClazzes &&
        <NotifyButton
            disabled={isLoading}
            label='app.label.viewAll'
            onClick={allClazzes}
            {...props}
        />
        }

        {fullDayClazzes &&
        <NotifyButton
            disabled={isLoading}
            label='app.label.fullClazzOnly'
            onClick={fullDayClazzes}
            {...props}
        />
        }

        {notFullDayClazzes &&
        <NotifyButton
            disabled={isLoading}
            label='app.label.notFullDayClazzes'
            onClick={notFullDayClazzes}
            {...props}
        />
        }
        {saveBtn &&
        <NotifyButton
            disabled={isLoading}
            label='app.label.save'
            onClick={saveBtn}
            {...props}
        />
        }
        {
            isLoading && <CircularProgress style={{marginLeft: 10}} color="secondary"/>
        }
    </Toolbar>
)

const NotifyForm = ({params, validate, children, classes, ...props}) => (
    <SimpleForm
        toolbar={<NotifyToolbar {...props} />}
        record={params}
        validate={validate}
        save={noop}
        className={classes.form}
        {...props}
    >
        {children}
    </SimpleForm>
)

export default compose(
    translate,
    connect(undefined, {showNotification}),
    withStyles(styles),
)(NotifyForm);
