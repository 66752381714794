import React from 'react';
import {Edit, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import AdmissionReportTemplateFields from './AdmissionReportTemplateFields'

export const AdmissionReportTemplateEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <Grid container spacing={8}>
                <AdmissionReportTemplateFields/>
            </Grid>
        </SimpleForm>
    </Edit>
);

export default AdmissionReportTemplateEdit
