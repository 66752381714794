import React from 'react';

import moment from 'moment'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {AutocompleteInput, DateInput, ReferenceInput, required, Title} from 'react-admin';
import {PaymentMethodInput, ReportForm, schoolYears, showErrorNotify, TreeCheckboxInput} from '../../components';
import env from '../../env';
import {downloadFilePost} from "../../components/FileDownload";
import axiosInstance from "../../api/axios";
import CheckboxTreeInput from "../../components/CheckboxTreeInput";

class HsDaDongTienTheoDanhMuc extends React.Component {
    state = {
        products: [],
        changeSchoolYear: false,
        payload: {
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
            productNames: [],
            paymentMethod: '',
            unitIds: [],
            schoolYearId: schoolYears[0].id,
        },
    };

    componentDidMount() {
        this.getProductNames()
    }

    getProductNames = () => {
        const url = `/reports/hsDaDongTienTheoDanhMuc/getInfo`
        axiosInstance.get(url, {
            newBaseUrl: env.reportURL
        }).then(rs => {
            const productNames = rs.data
            if (productNames && productNames.length > 0) {
                this.setState(
                    {
                        products: productNames.map(item => ({value: item, label: item})),
                    })
            }
        });
    }

    excelReport = () => {
        const {payload} = this.state
        if (!payload.productNames || payload.productNames.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách phí")
            return;
        }
        if (!payload.unitIds || payload.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        const data = {
            fromDate: payload.fromDate,
            toDate: payload.toDate,
            paymentMethod: payload.paymentMethod,
            unitIds: payload.unitIds,
            schoolYearId: payload.schoolYearId,
            productNames: payload.productNames,
        }
        downloadFilePost(`${env.reportURL}/reports/hsDaDongTienTheoDanhMuc`, 'bang_ke_danh_muc_theo_hs.xlsx', data)
    };

    validate = (values) => {
        const errors = {};
        const {fromDate, toDate, productNames, paymentMethod} = values
        // Validate
        if (fromDate && toDate && (fromDate > toDate)) {
            errors.toDate = 'Thời gian không hợp lệ'
        }

        if (!productNames || productNames.length === 0) {
            errors.products = 'Vui lòng chọn danh mục phí'
        }
        if (this.state.changeSchoolYear) {
            this.setState({
                changeSchoolYear: false,
                payload: {
                    ...values,
                    unitIds: []
                }
            })
        } else {
            this.setState({payload: {...values}})
        }
        return errors;
    };

    onSchoolYearChanged(e, value) {
        const {payload} = this.state
        this.setState({
            payload: {
                ...payload,
                schoolYearId: value
            },
            changeSchoolYear: true
        });
    }

    render() {
        return (
            <Card>
                <Title title="resources.hsDaDongTienTheoDanhMuc.name"/>
                <CardContent>
                    <div>Báo cáo danh sách học sinh đã đóng tiền theo trong khoảng thời gian thanh toán và danh mục phí
                        được chọn
                    </div>
                    <ReportForm params={this.state.payload}
                                validate={this.validate}
                                excelReport={this.excelReport}
                                isLoading={this.state.isLoading}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.fromDate" source="fromDate"
                                           validate={required()} {...this.props} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateInput label="resources.common.toDate" source="toDate"
                                           validate={required()} {...this.props} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <PaymentMethodInput source="paymentMethod"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CheckboxTreeInput
                                    label='Danh sách phí *'
                                    source='productNames'
                                    nodes={this.state.products}
                                    showNodeIcon={false}
                                />
                            </Grid>

                        </Grid>
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <ReferenceInput source="schoolYearId"
                                                reference="schoolYears"
                                                label="resources.schoolYears.name"
                                                perPage={50}
                                                validate={required()}
                                                onChange={(e, value) => this.onSchoolYearChanged(e, value)}
                                                {...this.props} >
                                    <AutocompleteInput source="name"/>
                                </ReferenceInput>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label>Danh sách lớp *</label>
                                <div style={{maxHeight: '300px', overflow: 'auto'}}>
                                    <ReferenceInput source="unitIds"
                                                    reference="units"
                                                    perPage={999}
                                                    filter={{
                                                        clazzWithParents: true,
                                                        schoolYear: this.state.payload.schoolYearId
                                                    }}
                                                    {...this.props} >
                                        <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                                    </ReferenceInput>
                                </div>
                            </Grid>
                        </Grid>
                    </ReportForm>
                </CardContent>
            </Card>
        )
    }
}

export default HsDaDongTienTheoDanhMuc;

