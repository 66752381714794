import CompanyIcon from '@material-ui/icons/ViewList';

import CompanyList from './CompanyList';
import CompanyCreate from './CompanyCreate';
import CompanyEdit from './CompanyEdit';

export default {
  list: CompanyList,
  create: CompanyCreate,
  edit: CompanyEdit,
  icon: CompanyIcon,
};
