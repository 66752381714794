import StudentEnrollmentBatchIcon from '@material-ui/icons/ViewList';

import PupilEnrollmentBatchV2List from './PupilEnrollmentBatchV2List';
import PupilEnrollmentBatchCreate from './PupilEnrollmentBatchV2Create';
import PupilEnrollmentBatchEdit from './PupilEnrollmentBatchV2Edit';

export default {
    list: PupilEnrollmentBatchV2List,
    create: PupilEnrollmentBatchCreate,
    edit: PupilEnrollmentBatchEdit,
    icon: StudentEnrollmentBatchIcon,
};
