import React from 'react';

import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import CancelIcon from '@material-ui/icons/Cancel';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles';

import { 
  Button, EditButton, Title, ReferenceInput, SelectInput, SimpleForm, SaveButton,
  translate, withDataProvider, GET_LIST, GET_ONE 
} from 'react-admin';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'

import { CloseButton } from '../../components'
import EventCreate_old from '../Event/EventCreate_old'
import EventEdit from '../Event/EventEdit'

const localizer = momentLocalizer(moment)
const views=['month', 'week', 'day', 'agenda']
// const views = ['week']

const styles = {
  form: {
    "& > div:first-child ": {
      paddingTop: '0 !important',
    },
  },
  editButton: {
    textAlign: 'right',
  },
  calendar: {
    height: '1200px',
  },
  dialog: {
    zIndex: '2',
    "& > div:nth-child(2) ": {
      maxWidth: '700px',
    }
  },
};

class CalendarShow extends React.Component {
  state = {
    id: this.props.id,
    events: [],
    showDialog: false,
    event: {},
  };
  

  componentDidMount() {
    const start = moment().startOf('week').toDate()
    const end = moment().endOf('week').toDate()
    this.loadEvents(this.props.id, start, end)
  }

  createEvent = ({ start, end }) => {
    const event = {
      startDate: start,
      endDate: end,
      startTime: moment(start).format('kk:mm'),
      endTime: moment(end).format('kk:mm'),
      calendarId: parseInt(this.state.id),
      deviceIds: this.state.defaultDeviceIdList,
    }
    this.setState({ showDialog: true, createEvent: true, event });
  }

  editEvent = (event) => {
    this.setState({ showDialog: true, createEvent: false, event });
  }

  handleCloseClick = () => {
    this.setState({ showDialog: false, event: null });
  };

  onRangeChange = (range) => {
    if (range.start && range.end){
      this.loadEvents(this.state.id, range.start, range.end)
    } else {
      const start = range[0]
      const end = range[range.length-1]
      this.loadEvents(this.state.id, start, end)
    }
  }

  onRedirect = (basePath, id, data) => {
    this.setState({ showDialog: false });
    this.loadEvents(this.state.id, this.state.start, this.state.end)
  }

  onSave = (e) => {
    const { submit } = this.props;
    submit('event-form')
  }

  onCalendarChange = (id) => {
    this.loadEvents(id, this.state.start, this.state.end)
  }

  loadEvents = (id, start, end) => {
    const { dataProvider } = this.props;
    this.setState({ start, end })

    const options = {
      pagination: { page: 1, perPage: 100 },
      sort: {field: 'id', order: 'DESC'},
      filter: {},
      others: 'start=' + moment(start).format('YYYY-MM-DD') + '&end=' + moment(end).format('YYYY-MM-DD'),
    }

    dataProvider(GET_ONE, 'calendars', { id })
    .then((response) => {
      console.log('x', response.data.defaultDeviceIdList)
      this.setState({ defaultDeviceIdList: response.data.defaultDeviceIdList })
    })

    dataProvider(GET_LIST, `calendars/${id}/events`, options)
    .then((response) => {
      const events = response.data.map(event => ({
        id: event.id,
        title: event.name,
        start: new Date(event.start),
        end: new Date(event.end),
      }))
      this.setState({ id, events })
    })
  }

  render() {
    const { showDialog, event } = this.state;
    const { translate, location, classes, basePath, id } = this.props;

    const defaultValue = {
      calendarId: parseInt(id)
    }
    const minTime = new Date();
    minTime.setHours(5, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(21, 0, 0);

    const messages = {
      today: translate('resources.calendars.messages.today'), 
      previous: translate('resources.calendars.messages.previous'), 
      next: translate('resources.calendars.messages.next'), 
    }

    return (
      <React.Fragment>
        {/* Page title shown in the left AppBar */}
        <Title title={translate('resources.calendars.name')} />

        <Card>
          <SimpleForm toolbar={null} className={classes.form} defaultValue={defaultValue} >
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6} >
                <ReferenceInput source="calendarId" reference="calendars" 
                    onChange={(event, id) => this.onCalendarChange(id) } >
                  <SelectInput source="name" />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.editButton} >
                <EditButton basePath={basePath} record={{id}} />
                <CloseButton />
              </Grid>
            </Grid>
          </SimpleForm>

          <CardContent className={classes.calendar}>
            <Calendar
              selectable
              step={15}
              timeslots={4}
              min={minTime}
              max={maxTime}
              messages={messages}
              localizer={localizer}
              events={this.state.events}
              views={views}
              defaultView={Views.WEEK}
              scrollToTime={new Date()}
              defaultDate={new Date()}
              onSelectSlot={this.createEvent}
              onSelectEvent={this.editEvent}
              onRangeChange={this.onRangeChange}
            />
          </CardContent>
        </Card>

        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          className={classes.dialog}
        >
          <DialogTitle>
            { this.state.createEvent ? 
              translate('resources.calendars.actions.createEvent') 
              :
              translate('resources.calendars.actions.editEvent') 
            }
          </DialogTitle>
          <DialogContent >
            { !showDialog ? null :
              this.state.createEvent ? 
              <EventCreate_old 
                basePath="calendars" 
                location={location}
                resource="events"
                form="event-form"

                event={event}
                noCancelButton={true}
                redirect={this.onRedirect}
              /> 
            :
              <EventEdit 
                basePath="calendars" 
                location={location}
                resource="events"

                noCancelButton={true}
                redirect={this.onRedirect}
                id={event != null ? event.id : null}
                record={event} 
              />
            }
          </DialogContent>
          <DialogActions>
            {/* TODO */}
            {/* <SaveButton
              onClick={this.onSave}
            /> */}
            <Button
              label="ra.action.cancel"
              onClick={this.handleCloseClick}
            >
              <CancelIcon />
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = {
  submit
};

export default compose(
  connect(null, mapDispatchToProps), 
  translate,
  withRouter,
  withDataProvider,
  withStyles(styles)
)(CalendarShow);
