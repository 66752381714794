import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const values = [
    {id: 'PROGRAM', name: 'admissionAdditionalService.PROGRAM'},
    {id: 'SERVICE', name: 'admissionAdditionalService.SERVICE'},
]

export const AdmissionAdditionalServiceField = (props) => (
    <SelectField source={props.source} choices={values} {...props} />
)

export const AdmissionAdditionalServiceInput = (props) => (
    <SelectInput source={props.source} choices={values} {...props}/>
)
