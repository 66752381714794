import BankStatementTemplateIcon from '@material-ui/icons/ViewList';

import BankStatementTemplateList from './BankStatementTemplateList';
import BankStatementTemplateCreate from './BankStatementTemplateCreate';
import BankStatementTemplateEdit from './BankStatementTemplateEdit';

export default {
  list: BankStatementTemplateList,
  create: BankStatementTemplateCreate,
  edit: BankStatementTemplateEdit,
  icon: BankStatementTemplateIcon,
};
