import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

import {ReferenceInput, Title} from 'react-admin';
import {downloadFile, NotifyForm, schoolYears, showErrorNotify, TreeCheckboxInput} from '../../components';
import env from '../../env';

class RelationshipMigrationBatchTemplate extends React.Component {
    state = {
        unitIds: []
    };

    getReportUrl = () => {
        downloadFile(`${env.baseURL}/api/relationshipMigrationBatchTemplate?` +
            `unitIds=` + this.state.unitIds, 'mau_cap_nhat_quan_he.xlsx')
    }

    getTemplateFile = () => {
        if (!this.state.unitIds || this.state.unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách phòng ban")
            return;
        }
        this.getReportUrl()
    }

    validate = (values) => {
        const errors = {};
        this.setState({
            unitIds: values.unitIds
        })
        return errors;
    }

    render() {
        return (
            <Card>
                <Title title="resources.relationshipMigrationBatchTemplate.name"/>
                <CardContent>
                    <NotifyForm params={this.state}
                                validate={this.validate}
                                allClazzes={this.getTemplateFile}
                    >
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <label>Danh sách lớp *</label>
                                <div style={{maxHeight: '400px', overflow: 'auto'}}>
                                    <ReferenceInput source="unitIds"
                                                    reference="baseUnits"
                                                    perPage={999}
                                                    filter={{schoolYearId: schoolYears[0].id, clazzOnly: true}}
                                                    {...this.props} >
                                        <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                                    </ReferenceInput>
                                </div>
                            </Grid>
                        </Grid>
                    </NotifyForm>

                </CardContent>
            </Card>
        )
    }
}

export default RelationshipMigrationBatchTemplate

