import MonTuChonIcon from '@material-ui/icons/ViewList';

import AdmissionReportTemplateList from './AdmissionReportTemplateList';
import AdmissionReportTemplateCreate from './AdmissionReportTemplateCreate';
import AdmissionReportTemplateEdit from './AdmissionReportTemplateEdit';

export default {
  list: AdmissionReportTemplateList,
  create: AdmissionReportTemplateCreate,
  edit: AdmissionReportTemplateEdit,
  icon: MonTuChonIcon,
};
