import React from 'react';
import {BooleanField, Datagrid, Filter, List, NumberField, TextField, TextInput} from 'react-admin';

const SettingFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const SettingList = props => (
    <List filters={<SettingFilter/>} {...props} exporter={false} actions={<></>} bulkActions={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"  style={{width: 50}}/>
            <TextField source="category" style={{width: 120}}/>
            <TextField source="name"  style={{width: 120}}/>
            <TextField source="description"  style={{width: 300}}/>
            <TextField source="stringValue"/>
            <NumberField source="intValue"/>
            <BooleanField source="boolValue"/>
        </Datagrid>
    </List>
);

export default SettingList
