import React from 'react';
import {Edit, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import AdmissionAdditionalServiceFields from './AdmissionAdditionalServiceFields'

export const AdmissionAdditionalServiceEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <Grid container spacing={8}>
                <AdmissionAdditionalServiceFields/>
            </Grid>
        </SimpleForm>
    </Edit>
);

export default AdmissionAdditionalServiceEdit
