import React from 'react';
import {CardActions, CreateButton, Datagrid, Filter, List, TextField, TextInput} from 'react-admin';
import {
    BillAuthStatusField,
    DownloadFileContentButton,
    DownloadTemplateFileButton,
    EditButtonWithAuthStatus
} from "../../components";
import DeleteBillBatchtButton from "../../components/DeleteBillBatchButton";


const EventBatchFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

const PostActions = ({
                         bulkActions,
                         basePath,
                         displayedFilters,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter,
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {/* Add your custom actions */}
        <DownloadTemplateFileButton basePath="/api/teacherAssignmentBatches/getTemplate"
                                    btnLabel="ra.action.downloadTemplateTeacherAssignment"/>
        <CreateButton basePath={basePath}/>
    </CardActions>
);

export const TeacherAssignmentBatchList = props => (
    <List filters={<EventBatchFilter/>} {...props} actions={<PostActions/>} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="name"/>
            <BillAuthStatusField source="authStatus"/>
            <DownloadFileContentButton/>
            <EditButtonWithAuthStatus/>
            <DeleteBillBatchtButton title={"Bạn chắc chắn muốn huỷ lô phân công?"}
                                    text={"Không thể hoàn tác sau khi huỷ."}
                                    successMessage={"Huỷ lô phân công thành công!"}/>
        </Datagrid>
    </List>
);

export default TeacherAssignmentBatchList
