import CustomerSubjectResultBatchIcon from '@material-ui/icons/ViewList';

import CustomerSubjectResultBatchList from './CustomerSubjectResultBatchList';
import CustomerSubjectResultBatchCreate from './CustomerSubjectResultBatchCreate';
import CustomerSubjectResultBatchEdit from './CustomerSubjectResultBatchEdit';

export default {
  list: CustomerSubjectResultBatchList,
  create: CustomerSubjectResultBatchCreate,
  edit: CustomerSubjectResultBatchEdit,
  icon: CustomerSubjectResultBatchIcon,
};
