import env from './env'

export const common = {
  // TODO: common env variables here
}

export default {
  ...common,
  ...env
}
