import UploadPhotoPermissionList from "./UploadPhotoPermissionList";
import UploadPhotoPermissionCreate from "./UploadPhotoPermissionCreate";
import UploadPhotoPermissionEdit from "./UploadPhotoPermissionEdit";
import ViewListIcon from '@material-ui/icons/ViewList';

export default {
    list: UploadPhotoPermissionList,
    create: UploadPhotoPermissionCreate,
    edit: UploadPhotoPermissionEdit,
    icon: ViewListIcon,
};
