import ExemptionPolicyDetailIcon from '@material-ui/icons/ViewList';

import ExemptionPolicyDetailList from './ExemptionPolicyDetailList';
import ExemptionPolicyDetailCreate from './ExemptionPolicyDetailCreate';
import ExemptionPolicyEdit from './ExemptionPolicyDetailEdit';

export default {
  list: ExemptionPolicyDetailList,
  create: ExemptionPolicyDetailCreate,
  edit: ExemptionPolicyEdit,
  icon: ExemptionPolicyDetailIcon,
};
