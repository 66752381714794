import React from 'react';
import compose from 'recompose/compose';

import {Toolbar, translate} from 'react-admin';
import CancelButton from './CancelButton'
import Button from '@material-ui/core/Button';
import AuthorizeIcon from '@material-ui/icons/Done';

const GuestCustomToolbar = ({
                                basePath,
                                record,
                                resource,
                                undoable,
                                translate,
                                redirect,
                                permissions,
                                refresh,
                                cancelLabel,
                                saveLabel,
                                onClickCancel,
                                ...props
                            }) => (
    <Toolbar record={record} {...props}>

        <Button icon={<AuthorizeIcon/>}
                style={{marginLeft: '16px'}} variant="contained" color={'primary'} component="span"
                onClick={props.onSubmitRefresh} {...props}>
            {saveLabel ? saveLabel : 'Lưu'}
        </Button>

        <CancelButton onClick={onClickCancel} label={cancelLabel ? cancelLabel : 'Huỷ bỏ'}/>

    </Toolbar>
);

export default compose(
    translate,
)(GuestCustomToolbar);
