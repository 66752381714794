import React from 'react';
import { loadCSS } from 'fg-loadcss';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Icon from '@material-ui/core/Icon';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';

const classes = {
  root: {
    width: '100%',
    marginTop: 10,
  },
  paper: {
    marginTop: 3,
    width: '100%',
    overflowX: 'auto',
    marginBottom: 2,
  },
  table: {
    minWidth: 650,
  },
  tableCell: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  icon: {
    fontSize: 10,
  }
}

const CustomerFeeValidation = (props) => {

  React.useEffect(() => {
    loadCSS(
        'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
        document.querySelector('#font-awesome-css'),
    );
  }, []);

  return (
      <div style={classes.root}>
        <Paper style={classes.paper}>
          <Table style={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="right"
                           className={props.classes.tableCell}
                           >STT</TableCell>
                <TableCell className={props.classes.tableCell}
                           align="right">Lớp</TableCell>
                <TableCell className={props.classes.tableCell}
                           align="right">Họ Tên</TableCell>
                <TableCell className={props.classes.tableCell}
                           align="right">SSC ID</TableCell>
                <TableCell className={props.classes.tableCell}
                           align="right">Khoản Phí</TableCell>
                <TableCell className={props.classes.tableCell}
                           align="right">Giảm trừ</TableCell>
                <TableCell className={props.classes.tableCell}
                           align="right">Kỳ</TableCell>
                <TableCell className={props.classes.tableCell}
                           align="right">Số Tiền</TableCell>
                <TableCell className={props.classes.tableCell}
                           align="right">Trạng Thái</TableCell>
                <TableCell className={props.classes.tableCell}
                           align="right">Hợp Lệ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell component="th"
                               align="right"
                               className={props.classes.tableCell}>
                      {row.order}
                    </TableCell>
                    <TableCell className={props.classes.tableCell}
                               align="right">{row.clazzName}</TableCell>
                    <TableCell className={props.classes.tableCell}
                               align="right">{row.fullName}</TableCell>
                    <TableCell className={props.classes.tableCell}
                               align="right">{row.sscId}</TableCell>
                    <TableCell className={props.classes.tableCell}
                               align="right">{row.feeName}</TableCell>
                    <TableCell className={props.classes.tableCell}
                               align="right">{row.discountFeeName}</TableCell>
                    <TableCell className={props.classes.tableCell}
                               align="right">{row.feeTime}</TableCell>
                    <TableCell className={props.classes.tableCell}
                               align="right">{row.amount}</TableCell>
                    <TableCell className={props.classes.tableCell}
                               align="right">{row.payStatus}</TableCell>
                    <TableCell className={props.classes.tableCell}
                               align="right">
                      {row.valid &&
                        <Icon style={classes.icon} className={clsx('fas fa-check')} color="primary" />
                      }
                      {!row.valid &&
                        <Icon style={classes.icon} className={clsx('fa fa-times')} color="error" />
                      }
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={props.total}
              rowsPerPage={props.rowsPerPage}
              page={props.page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={props.handleChangePage}
              onChangeRowsPerPage={props.onChangeRowsPerPage}
          />
        </Paper>
      </div>
  );
}

export default compose(
    withStyles(classes)
)(CustomerFeeValidation)
