import React from 'react';
import {Edit, SimpleForm} from 'react-admin';

import {Grid} from '../../components'
import AuthorizedAttendanceHistoryFields
  from './AuthorizedAttendanceHistoryFields'
import SimpleToolbar from "../../components/SimpleToolbar";

export const AuthorizedAttendanceHistoryEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<SimpleToolbar {...props} />}>
      <Grid container spacing={8}>
        <AuthorizedAttendanceHistoryFields />
      </Grid>
    </SimpleForm>
  </Edit>
);

export default AuthorizedAttendanceHistoryEdit
