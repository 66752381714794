import React from "react";
import {connect} from 'react-redux';
import {Button, fetchEnd, fetchStart, NumberInput, refreshView, SaveButton, SimpleForm,} from 'react-admin';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconCancel from '@material-ui/icons/Cancel';
import {change, isSubmitting, submit} from "redux-form";
import axiosInstance from "../../api/axios";
import {showErrorNotify, showSuccessNotify, showWarningNotify} from "../../components";

const styles = {
    suggestDialog: {
        zIndex: '1 !important',
    },
    width100: {
        width: '100%',
    }
};

class EditPriorityBtn extends React.Component {

    state = {
        id: 0,
        priority: 100,
        showDialog: false,
    }

    constructor(props) {
        super(props);
        if (props.record) {
            this.state.id = props.record.id
            this.state.priority = props.record.priority
        }
    }

    handleClick = (e) => {
        e.stopPropagation();
        this.setState({showDialog: true})
    };

    handleCloseClick = () => {
        this.setState({showDialog: false})
    };

    handleSaveClick = () => {
        const {submit} = this.props;
        submit('priority-quick-create');
    };
    handleSubmit = () => {
        if (this.state.id) {
            const url = `/api/billBatch/changePriority?id=${this.state.id}&priority=${this.state.priority}`;
            axiosInstance.put(url)
                .then((res) => {
                    if (res.data && res.data.success) {
                        showSuccessNotify("Đã cập nhật ưu tiên");
                        this.handleCloseClick()
                    } else {
                        showWarningNotify("Cập nhật ưu tiên không thành công.");
                    }
                }).catch(err => {
                console.log(err)
                showErrorNotify("Có lỗi xảy ra, vui lòng thử lại hoặc liên hệ quản trị viên");
            })
        }
    }

    validate = (values) => {
        const errors = {};
        // Validate
        if (values.priority === undefined || values.priority < 0) {
            errors.priority = 'Vui lòng nhập độ ưu tiên'
        }
        // Copy input values to params
        this.state.priority = values.priority;

        return errors;
    };

    render() {
        const {
            isSubmitting,
            classes,
        } = this.props;
        const {showDialog} = this.state;
        return (
            <>
                <Button color="primary"
                        onClick={this.handleClick} label={this.state.priority}>
                </Button>
                <Dialog
                    className={classes.suggestDialog}
                    fullWidth={true}
                    maxWidth='sm'
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Nhập độ ưu tiên"
                >
                    <DialogTitle>Nhập độ ưu tiên</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            form="priority-quick-create"
                            onSubmit={this.handleSubmit}
                            toolbar={null}
                            validate={this.validate}
                        >
                            <NumberInput label="resources.billBatches.fields.priority" source="priority"
                            defaultValue={this.state.priority}/>
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        />
                        <Button
                            label="ra.action.cancel"
                            onClick={this.handleCloseClick}
                        >
                            <IconCancel/>
                        </Button>
                    </DialogActions>
                </Dialog>
            </>

        )
    }
}


const mapStateToProps = state => ({
    isSubmitting: isSubmitting('priority-quick-create')(state)
});

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    submit,
    refreshView
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(
    EditPriorityBtn
);
