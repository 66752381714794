import React, {useState} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import {downloadFile, ReportForm} from "../../components";
import {AutocompleteInput, DateInput, ReferenceInput, required, Title} from 'react-admin';
import env from "../../env/env";
import moment from "moment";

const BcXhThiDua = (props) => {
    const [state, setState] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        planId: []
    });
    const [loading, setLoading] = useState(false);

    const validate = (values) => {
        const errors = {};
        setState(values);
        return errors;
    };
    const excelReport = async () => {
        setLoading(true)
        try {
            await downloadFile(`${env.baseURL}/reports/bcXhThiDua?fromDate=${moment(state.fromDate).format('YYYY-MM-DD')}&toDate=${moment(state.toDate).format('YYYY-MM-DD')}&planId=${state.planId}`, 'bc_xh_thi_dua.xlsx')
        } catch (e) {

        }
        setLoading(false)
    };
    return (
        <Card>
            <Title title="resources.bcXhThiDua.name"/>
            <CardContent>
                <ReportForm params={state}
                            validate={validate}
                            excelReport={excelReport}
                            isLoading={loading}
                >
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.fromDate" source="fromDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput label="resources.common.toDate" source="toDate"
                                       validate={required()} {...props} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ReferenceInput source="planId" reference="disciplinePlans"
                                            label="resources.disciplinePlans.name" validate={required()}
                                            allowEmpty {...props} >
                                <AutocompleteInput optionText="name"/>
                            </ReferenceInput>
                        </Grid>
                    </Grid>
                </ReportForm>
            </CardContent>
        </Card>
    )
}

export default BcXhThiDua

