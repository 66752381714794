import React, {useState} from 'react';
import {refreshView, showNotification, SimpleForm, translate} from 'react-admin';

import {Grid} from '../../components'
import AdmissionResultTemplateFields from './AdmissionResultTemplateFields'
import {compose} from "redux";
import {connect} from "react-redux";
import withStyles from '@material-ui/core/styles/withStyles';
import axiosInstance from "../../api/axios";

const styles = {
    item: {
        "& > table ": {
            width: '95%',
        },
        "& > table td:nth-child(2)": {
            textAlign: 'right',
        },
        "& > table thead": {    // hide the header
            display: 'none',
        },
    },
    select: {
        marginTop: '20px',
    },
    button: {
        // marginTop: '-20px',
        marginLeft: '10px'
    },
}
let timeout = null;
export const AdmissionResultTemplateCreate = ({
                                                  translate,
                                                  classes,
                                                  showToolbar = true,
                                                  redirect = "list",
                                                  noCancelButton,
                                                  event,
                                                  ...props
                                              }) => {
    const [values, setValues] = useState({
        id: null,
        name: '',
        content: '',
        isRedirect: false,
        saveDisabled: false,
        isFistTimeCheck: true,
        ready: false
    });
    const handleChange = prop => e => {
        if (!e) {
            return
        }
        const value = e.target.value ? e.target.value : e.target.checked ? e.target.checked : ''

        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            setValues({...values, [prop]: value});
        }, 100);
    };
    const changeContent = val => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            setValues({...values, description: val});
        }, 100);
    };

    function validate() {
        return true;
    }

    const save = () => {
        if (!validate()) {
            return;
        }
        const data = Object.assign({}, values);
        setValues({
            ...values, isRedirect: true
        });
        const url = `/api/admissionResultTemplates`;
        axiosInstance.post(url, data).then(rs => {
            props.showNotification('Tạo thành công');
        }, err => {
            props.showNotification(err.message, 'warning');
        })
    }
    return (
        <SimpleForm
            toolbar={null}
            record={values}
        >
            <Grid container spacing={8}>
                <AdmissionResultTemplateFields save={save} defaultValue={values} onChange={handleChange}
                                               changeContent={changeContent}/>
            </Grid>
        </SimpleForm>
    )
}

export default compose(
    connect(undefined, {refreshView, showNotification}),
    translate,
    withStyles(styles)
)(AdmissionResultTemplateCreate)

