import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

const genders = [
  { id: 'MALE', name: 'gender.MALE' },
  { id: 'FEMALE',   name: 'gender.FEMALE' },
]

export const GenderField = (props) => (
  <SelectField source={props.source} choices={genders} {...props} />
)

export const GenderInput = (props) => (
    <SelectInput source={props.source} choices={genders} {...props} label="resources.gender.name"/>
)
