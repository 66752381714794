import React from 'react';
import Grid from '@material-ui/core/Grid'
import {BooleanInput, maxLength, ReferenceInput, required, SelectInput, TextInput} from 'react-admin';
import {AdmissionAdditionalServiceInput} from "../../components/AdmissionAdditionalService";

const AdmissionAdditionalServiceFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={[required(), maxLength(100, 'Không quá 100 ký tự')]} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <AdmissionAdditionalServiceInput source="type" defaultValue={'PROGRAM'}
                                             validate={[required()]} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="defaultValue" defaultValue={true} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="forced" defaultValue={false} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="admissionSubjectId" reference="admissionSubjects" allowEmpty {...props} >
                <SelectInput optionText="name"/>
            </ReferenceInput>
        </Grid>
    </React.Fragment>
)

export default AdmissionAdditionalServiceFields
